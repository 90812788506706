import { store, mainPanelAction, localStorageActions } from "~src/redux";
import { createEmailId } from "~src/utils";

export const createLVIdentifier = (): lvIdentifier => {
  return createEmailId();
};

export const initializeLVIdentifier = (lvIdentifier: lvIdentifier) => {
  store.dispatch(mainPanelAction.initialize(lvIdentifier));
  //INFO: if the extension is restarted from login then open the panel showing the login view
  if (store.getState().localStorage.restartExtension) {
    store.dispatch(localStorageActions.setRestartExtension(false));
    store.dispatch(mainPanelAction.open(lvIdentifier));
  }
};

export const createAndInitLVIdentifier = (): lvIdentifier => {
  const lvIdentifier = createLVIdentifier();
  initializeLVIdentifier(lvIdentifier);
  return lvIdentifier;
};
