// Disables irrelevant warning: 'default' import from 'axios' is restricted. Use typed function `networkRequest` instead
// eslint-disable-next-line @typescript-eslint/no-restricted-imports, import-x/no-named-as-default
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { URLS } from ".";
import {
  appendContentTypeHeadersToPost,
  blockGetRequestWithChromeID,
} from "./securityInterceptors";
import {
  loadRequestFromCacheIfPresentAndNotStale,
  storeCacheableResponse,
  useCachedResponse,
} from "./cachingInterceptors";

type AxiosInstanceWithCustomGetPostRequests = Omit<
  AxiosInstance,
  "post" | "get"
> & {
  post: <T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: URLS,
    data?: D,
    config?: AxiosRequestConfig<D>
  ) => Promise<R>;
  get: <T = unknown, R = AxiosResponse<T>>(
    url: URLS,
    config?: AxiosRequestConfig
  ) => Promise<R>;
};

// Defines custom Axios request with URL type safety and automatic Content-Type header
export const networkRequest: AxiosInstanceWithCustomGetPostRequests =
  axios.create({
    baseURL: process.env.BACKEND_API,
  });

networkRequest.interceptors.request.use(appendContentTypeHeadersToPost);
networkRequest.interceptors.request.use(blockGetRequestWithChromeID);
networkRequest.interceptors.request.use(
  loadRequestFromCacheIfPresentAndNotStale
);
networkRequest.interceptors.response.use(
  storeCacheableResponse,
  useCachedResponse
);
