import styled from "styled-components";
import { Flex } from "antd";

import { Text } from "@lavender-ai/lav-components";

import { useLvIdentifier } from "~src/customHooks";
import { selectEmailAnalysis, useAppSelector } from "~src/redux";

import { getScoreHeaderMessage, useScoreColorGroup } from "../utils";

export const ScoreSection = () => {
  const lvIdentifier = useLvIdentifier();
  const {
    analysis: { dynamic_ceq: ceq },
  } = useAppSelector(selectEmailAnalysis(lvIdentifier));
  const { isSuccess, numberColor, rocketImage } = useScoreColorGroup();
  const scoreHeaderMessage = getScoreHeaderMessage(ceq);

  return (
    <ScoreSectionBox>
      <ScoreInfoBox>
        <Text
          size="header3"
          style={{
            color: numberColor,
            fontSize: 84,
            lineHeight: "98px",
          }}
        >
          {Math.max(ceq, 0)}
        </Text>
        {isSuccess ? (
          <Text color="white">
            <>
              {scoreHeaderMessage.split(",")[0]},
              <strong>{scoreHeaderMessage.split(",")[1]}</strong>
            </>
          </Text>
        ) : (
          <Text
            style={{
              color: "var(--color-neutral-purple-900)",
            }}
          >
            {scoreHeaderMessage}
          </Text>
        )}
      </ScoreInfoBox>
      <RocketImg src={rocketImage} />
    </ScoreSectionBox>
  );
};

const ScoreSectionBox = styled(Flex)`
  display: flex;
  height: 106px;
  padding: 0px var(--size-32, 32px) 0px var(--size-24, 24px);
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

const ScoreInfoBox = styled(Flex)`
  display: flex;
  width: 179px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

const RocketImg = styled.img`
  position: absolute;
  left: 189px;
  top: 54px;
`;
