import { QueryStatus } from "@reduxjs/toolkit/query";

import {
  APIResponseStatus,
  FeatureFlagEnum,
  StartMyEmailCart,
  StartMyEmailData,
} from "~src/api/typings";
import {
  useStartMyEmailMutationContext,
  useStartMyEmailWebsocketContext,
} from "~src/contexts";
import { icebreakerCount } from "~src/utils";

import { useFeatureFlag, useStartMyEmail } from "./";

interface IUseStartMyEmailGeneration {
  generatedEmailsData: StartMyEmailData | string[] | undefined;
  generatedEmailsError: string | undefined;
  generatedEmailsStatus: QueryStatus;
  generatingEmailsData: StartMyEmailCart | undefined;
  generatingEmailsStatus: APIResponseStatus | undefined;
  generationCount: number;
  // HTTP state
  isEmailGenerationRejected: boolean;
  // HTTP state
  isEmailGenerationSuccess: boolean;
  // Feature flag to generate 2 emails in SME instead of 1
  isStartMyEmail2On: boolean;
}

export const useStartMyEmailGeneration = (): IUseStartMyEmailGeneration => {
  const isStartMyEmail2On = useFeatureFlag(FeatureFlagEnum.sme_2);
  const {
    currentPersonalization: { cart },
  } = useStartMyEmail();
  const { generatedEmailsData, generatedEmailsError, generatedEmailsStatus } =
    useStartMyEmailMutationContext();
  const { generatingEmailsData, generatingEmailsStatus } =
    useStartMyEmailWebsocketContext();

  const cartCount = icebreakerCount(cart);
  const emailCount = isStartMyEmail2On ? Math.max(2, cartCount) : cartCount;

  //*Handle fail from the http response regardless of the websockets flag to handle dropped connections
  const isEmailGenerationRejected =
    generatedEmailsStatus === QueryStatus.rejected;
  //*Handle success from the http response regardless of the websockets flag to handle dropped connections
  const isEmailGenerationSuccess =
    generatedEmailsStatus === QueryStatus.fulfilled;

  return {
    generationCount: emailCount,
    generatedEmailsData,
    generatedEmailsError,
    generatedEmailsStatus,
    generatingEmailsData,
    generatingEmailsStatus,
    isEmailGenerationRejected,
    isEmailGenerationSuccess,
    isStartMyEmail2On,
  };
};
