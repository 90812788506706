import { Recommendation } from "~src/redux/typings";
import { extractBodySubject } from ".";
import { SUBJECT_EMAIL_TRANSFORMERS } from "~src/constants";

export const getPromptName = (rec: Recommendation) => {
  if (rec.prompt_name) {
    return rec.prompt_name;
  }

  if (rec.text) {
    if (rec.rectype === "Long Word") {
      return "";
    } else if (rec.rectype === "Formal Sentence") {
      return "formal_to_informal";
    } else if (rec.rectype === "Casual Sentence") {
      return "informal_to_formal";
    } else {
      return "";
    }
  }
  return "";
};

export const getEmailTransformerChatRecText = (
  recommendation: Recommendation | undefined
) => {
  if (!recommendation) {
    return "";
  }
  if (recommendation.prompt_name) {
    if (
      recommendation.rectype.includes("Too Formal") ||
      recommendation.rectype.includes("Too Casual") ||
      isSubjectRecommendation(recommendation)
    ) {
      return recommendation.gpt_rec;
    }

    const recommendationText = recommendation.recommendation
      .replace("Your", "This")
      .replace(" Your", "This");
    if (
      recommendation.rectype.includes("Too Complex") ||
      recommendation.rectype.includes("Too Simple")
    ) {
      return recommendationText.replace(" Reduce", "Reduce");
    }
    if (recommendation.rectype === "Shorten Email") {
      return recommendationText.replace("message", "email");
    }
    return recommendationText;
  }
  return recommendation.rectype;
};

export const isSubjectRecommendation = (
  recommendation: Recommendation | undefined
) =>
  recommendation?.prompt_name
    ? SUBJECT_EMAIL_TRANSFORMERS.includes(recommendation.prompt_name)
    : false;

// getEmailAndSubjectFromContentString takes a string and parses a subject and body from it
// if there is a subject, then it puts the subject in bold and adds a line break before the body.
export const getEmailAndSubjectFromContentString = (content: string) => {
  let parsedContent = "";
  const replacedContent = content.replace(/<br>/g, "\n");
  const { subject, body } = extractBodySubject(replacedContent);
  if (subject) {
    parsedContent += `<b>${subject}</b>`;
    if (body) {
      parsedContent += `<br><br>`;
      parsedContent += body.replace(/\n/g, "<br>");
    }
  } else {
    parsedContent = content;
  }
  return parsedContent;
};
