import { Button, Icon, Text } from "@lavender-ai/lav-components";
import { Flex } from "antd";
import DOMPurify from "dompurify";
import styled from "styled-components";

import { NavigationWrapper } from "../Views/NavigationWrapper";

import { unixToTimeStrDynamic } from "~src/utils";
import { LavenderIcon } from "../../../typings";
import { SecondaryText } from "../../../Styled";
import { ModalType, useCarouselContext } from "../CarouselContextProvider";

export const PodcastsModal = () => {
  const { modalType, data } = useCarouselContext();
  const podcast = data;
  if (!podcast || modalType !== ModalType.PodcastDetails) {
    return null;
  }
  const prettyDate = unixToTimeStrDynamic(podcast.pub_date_ms || 0);
  return (
    <NavigationWrapper>
      <br />
      <StyledPodcastsModal gap="middle" vertical>
        <img
          style={{ width: "100%" }}
          src={podcast.thumbnail}
          alt={"pod thumbnail"}
        />
        <SecondaryText $light size={"body1"}>
          {prettyDate}
        </SecondaryText>
        <Text size={"subtext3"}>{podcast.title_original}</Text>
        <Text
          size={"body1"}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(podcast.description_original),
          }}
        />
        <StyledButton
          size="medium"
          variant={"default"}
          fullWidth={true}
          href={podcast.link}
          target="_blank"
        >
          Watch Podcast
          <Icon variant={LavenderIcon.IconArrowUpRight} size={"mini"} />
        </StyledButton>
      </StyledPodcastsModal>
    </NavigationWrapper>
  );
};

const StyledPodcastsModal = styled(Flex)`
  background: white;
  border-radius: var(--radius-lrg, 16px);
  text-align: left;
  margin: 0px 16px;
  padding: 20px 16px;
`;

const StyledButton = styled(Button)`
  margin: 10px 0px;
  width: -webkit-fill-available;
`;
