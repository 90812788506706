import { useMemo, useState } from "react";
import { Flex } from "antd";
import clsx from "clsx";
import styled from "styled-components";

import { Text } from "@lavender-ai/lav-components";

import { IconFrame } from "~src/component/Atoms";
import { useTimeout } from "~src/customHooks";

import { PANEL_HIGHLIGHT_MIXIN } from "~src/component/Styled";
import { LavenderIcon } from "~src/component/typings";

const StyledIconAndTextRow = styled(Flex)`
  text-align: left;
`;

const IconAndTextGap = styled(Flex)`
  background: var(--lv-panel-background-color);
  display: block !important;
  height: 1px;
  width: 100%;
  margin: 16px 0;
`;

const StyledBodyCard = styled(Flex)`
  background: var(--color-base-White);
  border-radius: var(--radius-md-lrg);
  border: 1px solid transparent;
  padding: 20px 16px;
  transition:
    border 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  &.lv-panel-highlight {
    ${PANEL_HIGHLIGHT_MIXIN}
  }
`;

interface Step {
  icon: LavenderIcon;
  text: string;
}

interface Props {
  header: string;
  subheader: string;
  steps: Step[];
}

export const Steps = ({ header, subheader, steps }: Props) => {
  const [hasHighlight, setHasHighlight] = useState(false);
  useTimeout(
    () => {
      if (!hasHighlight) {
        setHasHighlight(true);
      }
    },
    !hasHighlight ? 2000 : null
  );
  const renderSteps = useMemo(() => {
    return steps.map((step, idx) => (
      <div key={step.icon}>
        <StyledIconAndTextRow gap="middle">
          <IconFrame iconSize="mini" variant={step.icon} />
          <Text
            size="body1"
            dangerouslySetInnerHTML={{
              __html: step.text,
            }}
          />
        </StyledIconAndTextRow>
        {idx !== steps.length - 1 && <IconAndTextGap>{null}</IconAndTextGap>}
      </div>
    ));
  }, [steps]);

  return (
    <>
      <Text size="subtext4">{header}</Text>
      <StyledBodyCard
        className={clsx({ "lv-panel-highlight": hasHighlight })}
        vertical
      >
        <Text size="subtext2" style={{ marginBottom: "24px" }}>
          {subheader}
        </Text>
        {renderSteps}
      </StyledBodyCard>
    </>
  );
};
