import { ArgTypes, Parameters } from "@storybook/react";

import { AvatarProps, IconProps } from "@lavender-ai/lav-components";

import {
  ComponentVariant,
  ComponentSize,
} from "@lavender-ai/lav-components/dist/esm/types/src/types";

import { LavenderIcon } from "~src/component/typings";

import extractedUnionTypes from "../../.storybook/extractedUnionTypes.json";

export const arrayToTableType = (array: string[]): string => {
  return array.join(" | ");
};
export const unionToOptions = (union: string): string[] => {
  return union.split(" | ").map((item) => item.trim());
};

export const makeStoryLevelDocs = (descriptiont: string): Parameters => {
  return {
    docs: {
      description: {
        story: descriptiont,
      },
    },
  };
};

export const makeComponentLevelDocs = (descriptiont: string): Parameters => {
  return {
    docs: {
      description: {
        component: descriptiont,
      },
    },
  };
};

export const makeComponentLibraryColorArgType = (key: string): ArgTypes => {
  return {
    [key]: {
      description: `Color. The default from lav-components is default (white).`,
      options: extractedUnionTypes.ComponentBrandColor,
      control: {
        defaultValue: "default",
        type: "select",
      },
      table: {
        defaultValue: { summary: "default" },
      },
    },
  };
};

export const makeComponentLibraryFontSizeType = (
  key: string,
  defaultValue: string = "body2"
): ArgTypes => {
  return {
    [key]: {
      description: `Font size. The default from lav-components is ${defaultValue}.`,
      options: extractedUnionTypes.FontSize,
      control: {
        defaultValue,
        type: "select",
      },
      table: {
        defaultValue: { summary: defaultValue },
        type: { summary: arrayToTableType(extractedUnionTypes.FontSize) },
      },
    },
  };
};

export const makeComponentLibraryIconVariantArgType = (
  defaultValue?: IconProps["variant"]
): ArgTypes => {
  const options = [
    ...Object.values(LavenderIcon),
    ...extractedUnionTypes.CustomIconsType,
    undefined,
  ];
  return {
    variant: {
      description:
        "Icon variant from the component library. Icons used in the extension are narrowed to LavenderIcon.",
      options,
      control: {
        defaultValue,
        type: "select",
      },
      table: {
        defaultValue: defaultValue ? { summary: defaultValue } : undefined,
        type: {
          summary: arrayToTableType(options.map((option) => `${option}`)),
        },
      },
    },
  };
};

export const makeComponentLibraryAvatarSizeArgType = (
  defaultValue: AvatarProps["size"] = "medium"
): ArgTypes => {
  const options = "extra-small | small | medium | large | extra-large";
  return {
    size: {
      description: "The default from the component library is medium (40px)",
      options: unionToOptions(options),
      control: {
        defaultValue,
        type: "select",
      },
      table: {
        defaultValue: defaultValue ? { summary: defaultValue } : undefined,
        type: {
          detail:
            "extra-small (24px); small (32px); medium (40px); large (48px); extra-large (64px);",
          summary: options,
        },
      },
    },
  };
};

export const makeComponentLibraryIconSizeArgType = (
  defaultValue: IconProps["size"] = "small"
): ArgTypes => {
  const options = "mini | extra-small | small | medium | large";
  return {
    size: {
      description: "The default from the component library is small (20px)",
      options: unionToOptions(options),
      control: {
        defaultValue,
        type: "select",
      },
      table: {
        defaultValue: defaultValue ? { summary: defaultValue } : undefined,
        type: {
          detail:
            "mini (14px); extra-small (16px); small (20px); medium (24px); large (32px);",
          summary: options,
        },
      },
    },
  };
};

export const makeComponentLibraryVariantArgType = (
  defaultValue: ComponentVariant = "default"
): ArgTypes => {
  return {
    variant: {
      description: `Color. ${defaultValue === "default" ? "The default from lav-components is default (white)." : ""}`,
      options: extractedUnionTypes.ComponentVariant,
      control: {
        defaultValue,
        type: "select",
      },
      table: {
        defaultValue: { summary: defaultValue },
        type: {
          summary: arrayToTableType(extractedUnionTypes.ComponentVariant),
          detail: "default (white); primary ($color-lavender-500); ...",
        },
      },
    },
  };
};

export const makeComponentLibrarySizeArgType = (
  defaultSize: ComponentSize = "medium"
): ArgTypes => {
  return {
    size: {
      description: `Height. ${defaultSize === "medium" ? "The default from lav-components is medium (32px)." : ""}`,
      options: extractedUnionTypes.ComponentSize,
      control: {
        defaultValue: defaultSize,
        type: "select",
      },
      table: {
        defaultValue: { summary: defaultSize },
        type: {
          summary: arrayToTableType(extractedUnionTypes.ComponentSize),
          detail: "small (24px); medium (32px); large (40px)",
        },
      },
    },
  };
};

export function mockUseArgs<T>(
  initialArgs: T
): [T, (newArgs: Partial<T>) => void] {
  let args = initialArgs;
  const setArgs = (newArgs: Partial<T>) => {
    args = { ...args, ...newArgs };
  };
  return [args, setArgs];
}
