import { Root } from "react-dom/client";
import { emailAction, store } from "~src/redux";
import { ComposeContainerMetadata } from "~src/redux/typings";

// Hidees the Lv menu when compose minimize button is clicked
export const modifyGroove = (
  emailContainer: HTMLElement,
  composeContainerMetadata: ComposeContainerMetadata,
  lvIdentifier: lvIdentifier,
  root: Root
) => {
  // This is for nested iframe flow action
  if (
    composeContainerMetadata.container.iframeContainerSelector !== undefined
  ) {
    const iframeDocument = emailContainer.querySelector(
      composeContainerMetadata?.container?.iframeContainerSelector
    ) as HTMLIFrameElement;

    if (iframeDocument !== null) {
      const collapseButton = iframeDocument.contentDocument?.querySelector(
        "[class *= 'webapp-PaneControls-minimizeButton']"
      );

      if (collapseButton != null) {
        collapseButton.addEventListener("click", () => {
          const lvMenu = emailContainer.querySelector(".lv-panel-root");
          if (lvMenu?.classList.contains("hidden")) {
            lvMenu?.classList.remove("hidden");
          } else {
            lvMenu?.classList.add("hidden");
          }
        });
      }

      const closeButton = iframeDocument.contentDocument?.querySelector(
        "[class *= 'webapp-PaneControls-button']:not([class *= 'webapp-PaneControls-minimizeButton'])"
      );

      if (closeButton != null) {
        closeButton.addEventListener("click", () => {
          store.dispatch(emailAction.deleteEmailData(lvIdentifier));
          [...emailContainer.children].forEach((e) => {
            if (e.classList.contains("lv-panel-root")) {
              e.remove();
            }
          });
          emailContainer.classList.remove("lv-comp-container");
          root.unmount();
          store.dispatch(emailAction.deleteEmailData(lvIdentifier));
        });
      }
    }
  }

  //This is for uglier Flow action
  if (composeContainerMetadata?.header?.headerCSSLavClass !== undefined) {
    const headerCollapseButton = emailContainer?.querySelector(
      composeContainerMetadata?.header?.headerCSSLavClass +
        ", [class *= 'webapp-t-menuBar']"
    );
    if (headerCollapseButton !== null) {
      headerCollapseButton.addEventListener("click", () => {
        const lvMenu = emailContainer.querySelector(".lv-panel-root");
        if (lvMenu?.classList.contains("hidden")) {
          lvMenu?.classList.remove("hidden");
        } else {
          lvMenu?.classList.add("hidden");
        }
      });
    }
  }
};
