import { NetworkStatus } from "./networkStatus";

export type ReadTheRoomStateEmailSummary = Record<
  lvIdentifier,
  {
    emailAnalysis: ReadTheRoomAnalysis | Record<string, unknown>;
    summary: string;
    isOpen: boolean;
    analyticsStatus: NetworkStatus;
    analyticsError: string;
    emailSummaryStatus: string;
    emailSummaryError: string;
  }
>;

export interface EmailIdentifier {
  lvIdentifier: lvIdentifier;
}
export interface ReadTheRoomErrorMessage {
  lvIdentifier: lvIdentifier;
  error: string;
}
export interface ReadTheRoomAnalysis {
  first_word: string;
  subject: {
    word_count: number;
    has_punctuation: boolean;
    needs_work: boolean;
  };
  grade: string;
  ceq: number;
  ceq_dynamic: number;
  formality: number;
  tones: {
    analytical: number;
    angry: number;
    confident: number;
    friendly: number;
    happy: number;
    sad: number;
    scared: number;
    unsure: number;
  };
  tone_sentences: Record<string, number>;
  reading_time: number;
  body_length: number;
  sentence_count: number;
  long_sentences: number;
  sentiment: string;
  top_tones: string[];
  hedge_words: number;
  adverbs: number;
  i_we_you_ratio: number;
  you_count: number;
  i_count: number;
  spam_arr: string[];
  deidentified_body: string;
  deidentified_subject: string;
  body_exclamation_count: number;
}
