import { PersonalizationEvent, WorkHistory } from "~src/redux/typings";

import { FeedEntityAttribute } from "./additionalCompanyData";
import { CompanyFeed } from "./personalization";
import { RankedFeedExperience } from "./rankedFeed";

export type EventItem = CompanyFeed<FeedEntityAttribute>;
export type WorkHistoryItem =
  | WorkHistory
  | PersonalizationEvent
  | RankedFeedExperience;
export type RankedItem = PersonalizationEvent;
