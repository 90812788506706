import { useRef } from "react";

export const useDraggable = (elementRef: React.RefObject<HTMLElement>) => {
  const lastMousePosition = useRef<{ x: number; y: number } | null>(null);

  const handleMouseDown = (e: MouseEvent) => {
    lastMousePosition.current = { x: e.clientX, y: e.clientY };
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    if (!lastMousePosition.current || !elementRef.current) return;

    const dx = e.clientX - lastMousePosition.current.x;
    const dy = e.clientY - lastMousePosition.current.y;

    const styles = window.getComputedStyle(elementRef.current);
    const left = parseInt(styles.left, 10);
    const top = parseInt(styles.top, 10);

    elementRef.current.style.left = `${left + dx}px`;
    elementRef.current.style.top = `${top + dy}px`;

    lastMousePosition.current = { x: e.clientX, y: e.clientY };
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    lastMousePosition.current = null;
  };

  return {
    initDraggable: () => {
      if (!elementRef.current) return;
      elementRef.current.addEventListener(
        "mousedown",
        handleMouseDown as EventListener
      );
    },
    removeDraggable: () => {
      if (!elementRef.current) return;
      elementRef.current.removeEventListener(
        "mousedown",
        handleMouseDown as EventListener
      );
    },
  };
};
