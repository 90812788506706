import { Icon, Tag, Text } from "@lavender-ai/lav-components";

import { PlanDetailsRow as StyledPlanDetailsRow } from "./SelectUpgradePlanModal.styled";

interface Props {
  missing?: boolean;
  text: string;
}

export const PlanDetailsRow = ({ missing, text }: Props) => (
  <StyledPlanDetailsRow>
    <Icon
      variant={missing ? "IconCircleXFilled" : "IconCircleCheckFilled"}
      color={missing ? "tart" : "lavender"}
      size="small"
      style={{ flexShrink: 0 }}
    />
    <Text
      size="body1"
      style={missing ? { color: "var(--color-neutral-purple-600)" } : undefined}
    >
      {text}
    </Text>
    {missing && (
      <Tag color="lavender" style={{ marginLeft: "auto" }}>
        Pro
      </Tag>
    )}
  </StyledPlanDetailsRow>
);
