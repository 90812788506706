export interface TechStackResponse {
  tech_aggregate: TechAggregateData;
}

export interface TechAggregateData {
  accounting_and_finance: TechAggregateDataAttribute[];
  analytics: TechAggregateDataAttribute[];
  builtwith: TechAggregateDataAttribute[];
  cloud: TechAggregateDataAttribute[];
  design: TechAggregateDataAttribute[];
  email: TechAggregateDataAttribute[];
  frameworks: TechAggregateDataAttribute[];
  mobile: TechAggregateDataAttribute[];
  sales: TechAggregateDataAttribute[];
  security: TechAggregateDataAttribute[];
}

export interface TechAggregateDataAttribute {
  attributes: TechAggregateDataAttributeAttributes;
  id: string;
  type: string;
}

interface TechAggregateDataAttributeAttributes {
  first_seen_at: string;
  last_seen_at: string;
  url: string;
  title: string;
  description: string;
}
