import { Flex } from "antd";

import { Text } from "@lavender-ai/lav-components";

import { type CompanyInvestment } from "~src/api/typings";
import { TagList } from "~src/component/Atoms";
import { SecondaryText } from "~src/component/Styled";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
} from "~src/component/Utilities/Loading";
import { PanelListItem } from "~src/layout/MainPanel/PanelBlock";
import { cleanNum, unixToTimeStrDynamic } from "~src/utils";

interface Props {
  isLoading?: boolean;
  investments: CompanyInvestment[];
}

export const InvestmentList = ({
  isLoading,
  investments,
}: Props): JSX.Element => {
  if (isLoading) {
    return (
      <>
        <PanelListItem>
          <CustomSkeleton variant={CustomSkeletonVariant.simpleListItem} />
        </PanelListItem>
        <PanelListItem>
          <CustomSkeleton variant={CustomSkeletonVariant.simpleListItem} />
        </PanelListItem>
      </>
    );
  }

  return (
    <div className="lv-panel-list-item-wrapper" role="presentation">
      {investments.map((investment, currentItemIdx) => (
        <PanelListItem
          className="lv-panel-list-item __card"
          dense={true}
          key={currentItemIdx}
          role="listitem"
        >
          <div className="lv-panel-list-item-inner-container">
            <Flex gap="middle" style={{ width: "100%" }} vertical>
              <SecondaryText $light size="body1">
                {unixToTimeStrDynamic(investment.date)}
              </SecondaryText>
              <Flex justify="space-between">
                <Text color="dark" size="subtext3">
                  {investment.series}
                </Text>
                {investment.amount.value > 0 && (
                  <Text color="dark" size="subtext3">
                    {cleanNum(investment.amount.value)}
                  </Text>
                )}
              </Flex>
              {investment.investors && (
                <TagList
                  tags={investment.investors.map(({ name }) => name)}
                  colored
                  coloredIdx={currentItemIdx}
                />
              )}
            </Flex>
          </div>
        </PanelListItem>
      ))}
    </div>
  );
};
