import { gmail } from "~src/platform/gmail";

/*
 * This file contains platform based global JS.
 * */
export const runCustomJS = (platform: string): void => {
  switch (platform) {
    case "gmail":
      gmail();
  }
};
