import { StartMyEmailWSResponse, ValuePropWSResponse } from "~src/api/typings";

export const isStartMyEmailWebsocketJSONMessage = (
  message: unknown
): message is StartMyEmailWSResponse => {
  if (
    message === null ||
    typeof message !== "object" ||
    "type" in message === false
  ) {
    return false;
  }
  return message.type === "sme";
};

export const isValuePropWebsocketJSONMessage = (
  message: unknown
): message is ValuePropWSResponse => {
  if (
    message === null ||
    typeof message !== "object" ||
    "type" in message === false
  ) {
    return false;
  }
  return (
    message.type === "value_prop_generation"
    // || message.type === "value_prop_analysis"
  );
};
