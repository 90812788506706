import { Button, type IconProps } from "@lavender-ai/lav-components";

import { CustomSkeleton, CustomSkeletonVariant } from "../Utilities/Loading";

interface LinkButtonProps {
  href: string | undefined;
  loading?: boolean;
  variant: IconProps["variant"];
}

export const LinkButton = ({ href, loading, variant }: LinkButtonProps) => {
  return (
    <CustomSkeleton loading={loading} variant={CustomSkeletonVariant.avatar}>
      <Button
        icon={variant}
        state={!href ? "disabled" : "initial"}
        size="small"
        radius="md"
        href={href}
        target="_blank"
        variant="plain"
      />
    </CustomSkeleton>
  );
};
