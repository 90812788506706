import { FC, useState } from "react";
import { Collapse } from "antd";
import styled from "styled-components";
import { Icon } from "@lavender-ai/lav-components";

interface OutlineAccordionItem {
  key: string;
  label: string;
  time: string;
  children: JSX.Element;
}

interface OutlineAccordionProps {
  items: OutlineAccordionItem[];
}

interface PanelPropsType {
  isActive?: boolean;
}

const AccordianCollapse = styled(Collapse)`
  border: 0 !important;
  border-bottom: 0 !important;
  background: transparent !important;
  overflow: visible !important;
`;

const AccordianPanel = styled(Collapse.Panel)`
  margin-bottom: 8px !important;
  border-radius: var(--radius-md, 8px) !important;
  border: 0 !important;
  background: var(--lv-panel-background-color) !important;
  overflow: visible !important;
`;

const PanelHeaderOpenTitle = styled.div`
  color: var(--color-neutral-purple-900, #18253d);
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 150% */
  letter-spacing: -0.1px;
  padding-bottom: 8px;
`;
const PanelHeaderOpenTime = styled.div`
  color: var(--color-neutral-purple-600, #6d727d);
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: -0.25px;
`;

const PanelHeaderClosed = styled.div`
  color: var(--color-neutral-purple-900, #18253d);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 150% */
  letter-spacing: -0.25px;
`;

const chevronDown = (
  <Icon color="dark" size="extra-small" variant="IconChevronDown" />
);
const chevronUp = (
  <Icon color="dark" size="extra-small" variant="IconChevronUp" />
);

export const OutlineAccordion: FC<OutlineAccordionProps> = ({ items }) => {
  const [activeKey, setActiveKey] = useState<string | string[]>([items[0].key]);

  const handleChange = (key: string | string[]) => {
    setActiveKey(key);
  };

  const customExpandIcon = (panelProps: PanelPropsType) => {
    return panelProps.isActive ? chevronUp : chevronDown;
  };

  const openHeader = (item: OutlineAccordionItem) => (
    <div>
      <PanelHeaderOpenTitle>{item.label}</PanelHeaderOpenTitle>
      <PanelHeaderOpenTime>{item.time}</PanelHeaderOpenTime>
    </div>
  );

  const closedHeader = (item: OutlineAccordionItem) => (
    <PanelHeaderClosed>{item.label}</PanelHeaderClosed>
  );

  return (
    <AccordianCollapse
      className="custom-collapse"
      accordion
      defaultActiveKey={[items[0].key]}
      onChange={handleChange}
      expandIconPosition="end"
      expandIcon={customExpandIcon}
    >
      {items.map((item) => (
        <AccordianPanel
          header={
            String(activeKey) === String(item.key)
              ? openHeader(item)
              : closedHeader(item)
          }
          key={item.key}
        >
          {item.children}
        </AccordianPanel>
      ))}
    </AccordianCollapse>
  );
};
