export interface GetLinkedInPostsPayload {
  linkedinUrl: string;
  email: emailAddress;
  chromeId: chromeId | undefined;
  isCompany?: string;
  isName?: string;
  firstName?: string;
  lastName?: string;
}

export type GetLinkedInPostsData = LinkedInPost[] | [];

export interface LinkedInPostPage {
  displayUrl: string;
  snippet: string;
  url: string;
}

//*Not sure as afar as API details what is actually required
export interface LinkedInPost {
  post_content: string;
  original_post_content: string;
  author: string;
  original_author: string;
  author_image: string;
  original_author_image: string;
  reaction: string;
  comment: string;
  time: string;
  original_post_time: string;
  time_real: string;
  original_post_time_real: string;
  link: string;
  images: string[];
  shared: boolean;
  profile_url: string;
  // dont see this in the API response but will include it here for now
  reposted: boolean;
}

interface LinkedInPosts {
  id: string;
  last_checked: string;
  email: emailAddress;
  content: string;
  _type: string;
  queryContext: {
    originalQuery: string;
  };
  webPages: {
    webSearchUrl: string;
    totalEstimatedMatches: number;
    value: LinkedInPostPage[];
  };
  rankingResponse: object;
  linkedin_posts: LinkedInPost[];
}

export interface GetLinkedInPostsResponse {
  posts: LinkedInPosts | null;
}
