import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { APIEndpoints } from "~src/api";
import { REFRESH_INTERVAL } from "~src/constants";
import { ErrorIdentifiers, parseAndLogError } from "~src/logger";

import {
  GetLinkedInPostsData,
  GetLinkedInPostsPayload,
  GetLinkedInPostsResponse,
} from "./typings";

export const linkedInPostsApiSlice = createApi({
  reducerPath: "linkedInPostsApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.BACKEND_API}/api/` }),
  endpoints: (builder) => ({
    getLinkedInPosts: builder.query<
      GetLinkedInPostsData,
      GetLinkedInPostsPayload
    >({
      query: ({
        linkedinUrl,
        email = "",
        chromeId = "",
        isCompany = "",
        isName = "",
        firstName = "",
        lastName = "",
      }) => {
        return {
          url: APIEndpoints.linkedInPosts,
          params: {
            email,
            chrome_id: chromeId,
            linkedinUrl,
            isCompany,
            isName,
            firstName,
            lastName,
          },
        };
      },
      transformErrorResponse: (response, _meta, arg) => {
        parseAndLogError(response, ErrorIdentifiers.API_ERROR, {
          email: arg.email,
          request: APIEndpoints.linkedInPosts,
        });
        return {
          data: [],
        };
      },
      transformResponse: (response: GetLinkedInPostsResponse, _meta) => {
        if (!response.posts) {
          return [];
        }
        return response.posts.linkedin_posts ?? [];
      },
      keepUnusedDataFor: REFRESH_INTERVAL.FEED,
    }),
  }),
});

export const linkedInPostsApiReducer = linkedInPostsApiSlice.reducer;
export const { useGetLinkedInPostsQuery } = linkedInPostsApiSlice;
