import { ITechStack } from "~src/redux/typings";

export const newPendingTechStack = (): ITechStack => ({
  techAggregate: [],
  loading: true,
});

export const newRejectedTechStack = (): ITechStack => ({
  techAggregate: [],
  loading: false,
});
