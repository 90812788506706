import { InputSettings } from "~src/component/typings";

export const MAX_ICEBREAKERS = 3;

export const MIN_COMPANY_NAME_LENGTH = 3;
export const MIN_COMPANY_BIO_LENGTH = 15;
export const MIN_COMPANY_WEBSITE_LENGTH = 6;
export const MIN_JOB_TITLE_LENGTH = 2;

export const COMPANY_BIO_INPUT: InputSettings = {
  name: "companyBio",
  label: "Company Description",
  rules: [{ required: true, min: MIN_COMPANY_BIO_LENGTH }],
  validator: (value: string | undefined) => {
    if (!value) {
      return "Company bio is required";
    }
    if (value.length < MIN_COMPANY_BIO_LENGTH) {
      return "Company bio must be at least 15 characters long";
    }
    return true;
  },
};
export const COMPANY_NAME_INPUT: InputSettings = {
  name: "companyName",
  label: "Company",
  rules: [{ required: true, min: MIN_COMPANY_NAME_LENGTH }],
  validator: (value: string | undefined) => {
    if (!value) {
      return "Company name is required";
    }
    if (value.length < MIN_COMPANY_NAME_LENGTH) {
      return "Company name must be at least 3 characters long";
    }
    return true;
  },
};
export const COMPANY_WEBSITE_INPUT: InputSettings = {
  name: "companyWebsite",
  label: "Company Website",
  rules: [
    { required: true },
    { type: "url", warningOnly: true },
    { type: "string", min: MIN_COMPANY_WEBSITE_LENGTH },
  ],
  validator: (value: string | undefined) => {
    if (!value) {
      return "Company website is required";
    }
    if (value.length < MIN_COMPANY_WEBSITE_LENGTH) {
      return `Company website must be at least ${MIN_COMPANY_WEBSITE_LENGTH} characters long`;
    }
    return true;
  },
};
export const JOB_TITLE_INPUT: InputSettings = {
  name: "jobTitle",
  label: "Job Title",
  rules: [{ required: true, min: MIN_JOB_TITLE_LENGTH }],
  validator: (value: string | undefined) => {
    if (!value) {
      return "Job title is required";
    }
    if (value.length < MIN_JOB_TITLE_LENGTH) {
      return "Job title must be at least 2 characters long";
    }
    return true;
  },
};
