import React, { useEffect, useRef, useState } from "react";
import { Flex, Pagination, notification } from "antd";

import { Search, Shimmer, Tooltip } from "@lavender-ai/lav-components";

import { getTrendingGifs, getGifs } from "../../api";
import { styled } from "styled-components";
import {
  SegmentEventLocations,
  SegmentEventNames,
  segmentEvent,
} from "~src/utils";
import { emailAction, useAppDispatch } from "~src/redux";
import { useLvIdentifier } from "~src/customHooks";

const headerStyle: React.CSSProperties = {
  textAlign: "left",
  flexDirection: "column",
  width: "100%",
  position: "sticky",
  backgroundColor: "#F6F8FC",
};

const titleStyle: React.CSSProperties = {
  width: "100%",
  paddingTop: "20px",
  paddingBottom: "10px",
  paddingLeft: "20px",
};

const searchBarStyle: React.CSSProperties = {
  width: "100%",
  // INFO: The search bar component bounces the whole panel when the search bar is hovered.
  //This is due to the change in border width from 1px to 2px, this height will stop whole panel from bouncing
  height: "44px",
};

const contentStyle: React.CSSProperties = {
  display: "flex",
  overflow: "auto",
  width: "100%",
  height: "100%",
  marginTop: "20px",
  padding: "0px 20px 20px 20px",
  backgroundColor: "#F6F8FC",
};

const columnStyle: React.CSSProperties = {
  // INFO: This will stop the illusion of the page shrinking when the panel is sliding
  minWidth: "159px",
  width: "50%",
};

const gifStyle = (loading: boolean) => ({
  display: loading ? "none" : "block",
  borderRadius: "8px",
  width: "100%",
  // INFO: This will stop the illusion of the page shrinking when the panel is sliding
  minWidth: "159px",
  marginBottom: "10px",
  cursor: "pointer",
});

const shimmerStyle: React.CSSProperties = {
  borderRadius: "8px",
  width: "100%",
  height: "150px",
  marginBottom: "10px",
};

const paginationStyle: React.CSSProperties = {
  marginTop: "10px",
  marginBottom: "10px",
  backgroundColor: "#F6F8FC",
  justifyContent: "center",
};

function copyImageOnClick(imageElementId: string): void {
  const imageElement = document.getElementById(
    imageElementId
  ) as HTMLImageElement;

  const tempElement = document.createElement("div");
  tempElement.appendChild(imageElement.cloneNode(true));

  tempElement.style.position = "absolute";
  tempElement.style.left = "-9999px";

  document.body.appendChild(tempElement);

  const range = document.createRange();
  range.selectNodeContents(tempElement);
  const selection = window.getSelection();
  selection?.removeAllRanges();
  selection?.addRange(range);

  document.execCommand("copy");

  document.body.removeChild(tempElement);
}

interface GifLoaderProps {
  gif: Gif;
}

const GifLoader = ({ gif }: GifLoaderProps) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (tooltipOpen) {
      const timer = setTimeout(() => {
        setTooltipOpen(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [tooltipOpen]);

  return (
    <Flex className="lv-gif-image-container">
      <Tooltip
        hideArrow
        title="Copied!"
        open={tooltipOpen}
        getPopupContainer={(trigger: HTMLElement) => {
          return trigger;
        }}
      >
        <img
          key={gif.id}
          id={gif.id}
          style={gifStyle(loading)}
          src={gif.downsized_url}
          onLoad={() => {
            setLoading(false);
          }}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async () => {
            // eslint-disable-next-line @typescript-eslint/await-thenable, @typescript-eslint/no-confusing-void-expression
            await copyImageOnClick(gif.id);
            setTooltipOpen(true);
          }}
        />
        {loading ? <Shimmer style={shimmerStyle} /> : <></>}
      </Tooltip>
    </Flex>
  );
};

export const GifPanel = () => {
  const RESULTS_PER_PAGE = 20;

  const dispatch = useAppDispatch();
  const lvIdentifier = useLvIdentifier();
  const [gifs, setGifs] = useState<Gif[]>([]);
  const [searchQ, setSearchQ] = useState<string>("");
  const [gifColumns, setGifColumns] = useState<Gif[][]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(20);

  const [, contextHolder] = notification.useNotification();

  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(emailAction.tagGifsUsed({ id: lvIdentifier }));
  }, [dispatch, lvIdentifier]);

  useEffect(() => {
    setGifs([]);
    const fetchData = async () => {
      let response;
      if (searchQ === "") {
        response = await getTrendingGifs(currentPage - 1, RESULTS_PER_PAGE);
      } else {
        response = await getGifs(searchQ, currentPage - 1, RESULTS_PER_PAGE);
      }
      setGifs(response.data.gifs);
      setTotalCount(response.data.total_count);
      setCurrentPage(1);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQ]);

  useEffect(() => {
    const columns: Gif[][] = [];
    const columnCount = 2;
    if (gifs?.length) {
      for (let i = 0; i < columnCount; i++) {
        columns.push([]);
      }
      for (let i = 0; i < gifs.length; i++) {
        if (columns[i % columnCount]?.length === 0) {
          columns[i % columnCount] = [];
        }
        columns[i % columnCount].push(gifs[i]);
      }
    }

    setGifColumns(columns);
  }, [gifs]);

  useEffect(() => {
    const fetchData = async () => {
      let response;
      if (searchQ === "") {
        response = await getTrendingGifs(currentPage - 1, RESULTS_PER_PAGE);
      } else {
        response = await getGifs(searchQ, currentPage - 1, RESULTS_PER_PAGE);
      }
      setGifs(response.data.gifs);
      setTotalCount(response.data.total_count);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getTrendingGifs();
      setGifs(response.data.gifs);
      setTotalCount(response.data.total_count);
    };

    fetchData();
  }, []);

  const scrollToTop = () => {
    titleRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="lv-gif-container">
      <Flex style={headerStyle} ref={titleRef}>
        <Flex className="lv-gif-title" style={titleStyle}>
          <b>GIFs</b>
        </Flex>
        <Flex style={searchBarStyle}>
          <StyledSearch
            className="lv-gif-search-bar"
            placeholder="Search for GIFs..."
            size="medium"
            state="initial"
            onSearch={(e) => {
              segmentEvent(
                SegmentEventNames.GifsSearched,
                SegmentEventLocations.Gifs
              );
              setSearchQ(e);
            }}
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex style={contentStyle} gap="middle">
          {contextHolder}
          {gifColumns.length > 0 &&
            gifColumns.map((column, i) => (
              <Flex key={`lav-gifcol-${i}`} style={columnStyle} vertical={true}>
                {column.map((gif) => (
                  <GifLoader key={gif.id} gif={gif} />
                ))}
              </Flex>
            ))}
        </Flex>
      </Flex>
      <Flex style={paginationStyle}>
        <StyledPagination
          size={"small"}
          current={currentPage}
          total={totalCount}
          defaultPageSize={RESULTS_PER_PAGE}
          showSizeChanger={false}
          onChange={(p) => {
            setCurrentPage(p);
            scrollToTop();
          }}
        />
      </Flex>
    </div>
  );
};
const StyledSearch = styled(Search)`
  // INFO: wrapper with the input field and the search icon
  &.ant-input-group-wrapper.lav-components-search
    span.ant-input-wrapper.ant-input-group {
    border-radius: var(--radius-xl, 32px);
    .ant-input-affix-wrapper {
      border-bottom-left-radius: var(--radius-xl, 32px);
      border-top-left-radius: var(--radius-xl, 32px);
    }
  }
  // INFO: for the search button icon in the search bar
  &.ant-input-group-wrapper.lav-components-search
    span.ant-input-wrapper
    span.ant-input-group-addon
    button.ant-input-search-button {
    border-top-right-radius: var(--radius-xl, 32px);
    border-bottom-right-radius: var(--radius-xl, 32px);
  }
`;
const StyledPagination = styled(Pagination)`
  // INFO: the active page button
  & .ant-pagination-item.ant-pagination-item-active {
    border: unset;
    border-radius: var(--radius-xl, 32px);
    background: var(--color-lavender-100, #dbc5f6);
    padding: 0px 12px;
    a {
      padding-top: 1px;
    }
  }
  //INFO the number inside the pagination button
  & .ant-pagination-item a {
    padding: 0;
    font-weight: normal;
    font-family: "Inter";
  }
  // INFO: left and right arrow controls
  & .anticon-left svg,
  & .anticon-right svg {
    fill: var(--color-neutral-purple-500, #b4b1bc);
  }

  // INFO: the dots in the pagination ellipsis, extra class needed to override the default antd styles
  &.ant-pagination
    .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: var(--color-primary-dark-lavender, #230a54);
  }
`;
