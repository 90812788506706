import { Flex } from "antd";
import { FC } from "react";

import { Button, Icon, Text } from "@lavender-ai/lav-components";

import { SecondaryText } from "~src/component/Styled";
import { FRONTEND_URLS } from "~src/constants";
import { viewState } from "~src/redux/typings";
import { useUpgradePlanModalContext } from "~src/contexts";
import {
  mainPanelAction,
  selectFeatures,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";
import { useLvIdentifier } from "~src/customHooks";

const viewDescriptions: Record<viewState, string> = {
  emailCoach: "Email Coaching",
  profile: "Profile Lookups",
  chatGPT: "ChatGPT",
  startMyEmail: "Start My Email",
  mobilePreview: "Mobile Previews",
  intercom: "Intercom", // unused
  gif: "GIFs", // unused
  frameworks: "Frameworks", // unused
  settings: "Settings", // unused
  default: "Features", // unused
  upgrade: "Upgrade", // unused
};

export const PaywallModal: FC<{ view: viewState }> = ({ view }) => {
  const dispatch = useAppDispatch();
  const lvIdentifier = useLvIdentifier();
  const { openModal: openUpgradeModal } = useUpgradePlanModalContext();
  const { in_app_plan_upgrade_modal: showUpgradeModal } =
    useAppSelector(selectFeatures);

  const upgradeAction = () => {
    if (showUpgradeModal) {
      if (view === "mobilePreview") {
        dispatch(mainPanelAction.open(lvIdentifier));
        dispatch(mainPanelAction.setView({ lvIdentifier, view: "upgrade" }));
      } else {
        openUpgradeModal();
      }
    } else {
      window.open(`${FRONTEND_URLS.dashboard}/app/settings/billing`, "_blank");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        position: "absolute",
        backgroundColor: "#0D14213D",
        backdropFilter: "blur(10px)",
        zIndex: 100000,
      }}
    >
      <div
        style={{
          display: "flex",
          width: 319,
          minWidth: 267,
          minHeight: 252,
          boxSizing: "border-box",
          padding:
            "var(--size-32, 32px) var(--size-24, 24px) var(--size-24, 24px) var(--size-24, 24px)",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 16,
          borderRadius: "var(--radius-lrg, 16px)",
          border: "2px solid var(--color-base-White, #FFF)",
          background: "var(--color-base-White, #FFF)",
          boxShadow:
            "0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
          position: "relative",
        }}
      >
        <div
          style={{
            top: -2,
            width: 319,
            height: 93,
            position: "absolute",
            fill: "linear-gradient(172deg, #3D0DA1 5.99%, #6A2BE9 54.66%, #A770ED 110.67%)",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="319"
            height="93"
            viewBox="0 0 319 93"
            fill="none"
            style={{
              borderRadius: "16px 16px 0 0",
            }}
          >
            <path
              d="M0 0H319V86.0916C319 86.0916 273 65.8371 160 86.0916C73.1773 101.654 0 86.0916 0 86.0916V0Z"
              fill="url(#paint0_linear_86_5152)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_86_5152"
                x1="159"
                y1="-1.03297e-06"
                x2="163.398"
                y2="110.479"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#3D0DA1" />
                <stop offset="0.465" stopColor="#6A2BE9" />
                <stop offset="1" stopColor="#A770ED" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div
          style={{
            position: "absolute",
            left: 191,
            top: 22,
            zIndex: 1,
          }}
        >
          <Icon variant="IconUnion" style={{}} />
        </div>
        <EnvelopeImg />
        <Flex vertical gap={16} style={{ textAlign: "center" }}>
          <Text size="subtext4">
            Upgrade to Unlock Unlimited {viewDescriptions[view]}!
          </Text>
          <SecondaryText $light size="body1">
            Get access to features like unlimited AI recommendations,
            integrations & more!
          </SecondaryText>
          <Button
            prefix="IconSparkles"
            prefixColor="white"
            fullWidth={true}
            variant="primary"
            style={{ marginTop: 12 }}
            onClick={upgradeAction}
          >
            See Plans
          </Button>
        </Flex>
      </div>
    </div>
  );
};

const EnvelopeImg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="89"
    height="98"
    viewBox="0 0 89 98"
    fill="none"
    style={{ position: "relative" }}
  >
    <g filter="url(#filter0_dd_86_5775)">
      <path
        d="M8.42505 30.3459L9.29477 31.4294L8.42505 30.3459C7.41891 31.1534 6.83352 32.3739 6.83352 33.6641V81.9287C6.83352 84.2786 8.73847 86.1836 11.0883 86.1836H77.9087C80.2586 86.1836 82.1635 84.2786 82.1635 81.9287V33.6641C82.1635 32.3739 81.5781 31.1534 80.572 30.3459L75.9588 26.6431V19.881C75.9588 18.0583 74.4812 16.5807 72.6585 16.5807H63.422L48.9543 4.96843C46.3512 2.87905 42.6458 2.87905 40.0427 4.96843L25.575 16.5807H16.3385C14.5158 16.5807 13.0383 18.0583 13.0383 19.881V26.6431L8.42505 30.3459Z"
        stroke="white"
        strokeWidth="2.78218"
      />
    </g>
    <path
      d="M9.03542 30.6102C8.04269 31.407 7.46511 32.6112 7.46511 33.8842V81.1928C7.46511 83.5113 9.34466 85.3909 11.6632 85.3909H77.1599C79.4785 85.3909 81.358 83.5113 81.358 81.1928V33.8842C81.358 32.6112 80.7805 31.407 79.7877 30.6102L75.2762 26.9891V20.3741C75.2762 18.5723 73.8156 17.1116 72.0138 17.1116H62.9699L48.7964 5.73545C46.2347 3.67936 42.5884 3.67936 40.0268 5.73545L25.8532 17.1116H16.8094C15.0076 17.1116 13.547 18.5723 13.547 20.3741V26.9891L9.03542 30.6102Z"
      stroke="white"
      strokeWidth="2.78218"
    />
    <path
      d="M9.73381 33.8844C9.73381 33.2726 10.0114 32.6939 10.4885 32.3109L41.4798 7.43621C43.2441 6.02014 45.7553 6.02014 47.5196 7.43621L78.5109 32.3109C78.988 32.6939 79.2656 33.2726 79.2656 33.8844V81.193C79.2656 82.3072 78.3623 83.2105 77.2481 83.2105H11.7513C10.6371 83.2105 9.73381 82.3072 9.73381 81.193V33.8844Z"
      fill="#371A86"
      stroke="black"
      strokeWidth="1.57895"
    />
    <path
      d="M15.8163 22.4828C15.8163 21.5219 15.818 20.8806 15.8821 20.4028C15.9432 19.9478 16.0477 19.7604 16.1688 19.6387C16.2899 19.517 16.4767 19.4116 16.9315 19.3483C17.4089 19.2818 18.0502 19.277 19.0111 19.2723L70.437 19.0198C71.4133 19.015 72.0656 19.0135 72.5511 19.0767C73.0138 19.1369 73.2032 19.2428 73.3256 19.3656C73.4481 19.4884 73.5535 19.6781 73.6124 20.141C73.6742 20.6266 73.6708 21.279 73.6632 22.2552L73.2152 80.0244C73.2078 80.9827 73.2012 81.6223 73.1336 82.0984C73.0693 82.5517 72.9637 82.738 72.842 82.8588C72.7203 82.9795 72.5332 83.0836 72.0794 83.1445C71.6028 83.2083 70.9632 83.21 70.0048 83.21H19.0268C18.0617 83.21 17.4173 83.2083 16.9375 83.1438C16.4804 83.0824 16.2926 82.9772 16.1709 82.8554C16.0491 82.7337 15.944 82.5459 15.8825 82.0888C15.818 81.609 15.8163 80.9646 15.8163 79.9995V22.4828Z"
      fill="#C4FF31"
      stroke="black"
      strokeWidth="1.57895"
    />
    <path
      d="M42.5615 30.7939C42.8324 32.7217 41.2591 30.9769 39.6526 31.2027C38.0461 31.4284 37.0147 33.5393 36.7437 31.6115C36.4728 29.6837 37.5555 27.9378 39.162 27.712C40.7685 27.4863 42.2905 28.866 42.5615 30.7939Z"
      fill="#415F0C"
    />
    <path
      d="M54.3317 31.611C54.0606 33.5395 53.0289 31.4279 51.4218 31.2021C49.8147 30.9762 48.2409 32.7216 48.5119 30.7931C48.783 28.8646 50.3055 27.4844 51.9126 27.7102C53.5196 27.9361 54.6027 29.6825 54.3317 31.611Z"
      fill="#415F0C"
    />
    <path
      d="M35.5366 37.411C35.5366 36.4465 36.3944 35.7078 37.3482 35.8508L40.2955 36.2929C44.1167 36.8661 48.0042 36.8346 51.8156 36.1996L53.6344 35.8965C54.6317 35.7304 55.5395 36.4995 55.5395 37.5105C55.5395 37.6261 55.528 37.7392 55.4981 37.8509C55.2056 38.9443 53.2497 45.0528 46.0629 45.0528C39.0409 45.0528 36.182 39.2212 35.6364 37.9338C35.5658 37.7673 35.5366 37.5919 35.5366 37.411Z"
      fill="#415F0C"
    />
    <ellipse
      cx="44.5007"
      cy="62.3853"
      rx="10.258"
      ry="10.2581"
      fill="black"
      fillOpacity="0.1"
    />
    <mask id="path-9-inside-1_86_5775" fill="white">
      <path d="M44.4982 61.8786L11.3379 37.6865C10.0985 36.7823 9.24374 35.4458 8.94287 33.9414V81.1928C8.94287 82.7431 10.1996 83.9998 11.7499 83.9998H12.6855V80.2571L44.4982 61.8786Z" />
    </mask>
    <path
      d="M44.4982 61.8786L11.3379 37.6865C10.0985 36.7823 9.24374 35.4458 8.94287 33.9414V81.1928C8.94287 82.7431 10.1996 83.9998 11.7499 83.9998H12.6855V80.2571L44.4982 61.8786Z"
      fill="#572AD6"
    />
    <path
      d="M44.4982 61.8786L45.2881 63.2458L47.3882 62.0325L45.4288 60.603L44.4982 61.8786ZM12.6855 80.2571L11.8957 78.8899L11.1066 79.3458V80.2571H12.6855ZM12.6855 83.9998V85.5788H14.2645V83.9998H12.6855ZM10.5218 81.1928V33.9414H7.36392V81.1928H10.5218ZM43.7084 60.5114L11.8957 78.8899L13.4754 81.6243L45.2881 63.2458L43.7084 60.5114ZM11.1066 80.2571V83.9998H14.2645V80.2571H11.1066ZM12.6855 82.4209H11.7499V85.5788H12.6855V82.4209ZM10.4073 38.9621L43.5677 63.1542L45.4288 60.603L12.2685 36.411L10.4073 38.9621ZM7.39459 34.2511C7.77306 36.1434 8.84823 37.8247 10.4073 38.9621L12.2685 36.411C11.3487 35.74 10.7144 34.7481 10.4912 33.6318L7.39459 34.2511ZM7.36392 81.1928C7.36392 83.6151 9.32759 85.5788 11.7499 85.5788V82.4209C11.0716 82.4209 10.5218 81.871 10.5218 81.1928H7.36392Z"
      fill="black"
      mask="url(#path-9-inside-1_86_5775)"
    />
    <mask id="path-11-inside-2_86_5775" fill="white">
      <path d="M44.5001 61.8791L77.6604 37.687C78.8998 36.7828 79.7545 35.4463 80.0554 33.9419V81.1933C80.0554 82.7436 78.7987 84.0003 77.2484 84.0003H76.3127V80.2576L44.5001 61.8791Z" />
    </mask>
    <path
      d="M44.5001 61.8791L77.6604 37.687C78.8998 36.7828 79.7545 35.4463 80.0554 33.9419V81.1933C80.0554 82.7436 78.7987 84.0003 77.2484 84.0003H76.3127V80.2576L44.5001 61.8791Z"
      fill="#572AD6"
    />
    <path
      d="M44.5001 61.8791L43.7102 63.2463L41.6101 62.033L43.5695 60.6035L44.5001 61.8791ZM76.3127 80.2576L77.1026 78.8904L77.8917 79.3463V80.2576H76.3127ZM76.3127 84.0003V85.5793H74.7338V84.0003H76.3127ZM78.4765 81.1933V33.9419H81.6344V81.1933H78.4765ZM45.2899 60.5119L77.1026 78.8904L75.5229 81.6248L43.7102 63.2463L45.2899 60.5119ZM77.8917 80.2576V84.0003H74.7338V80.2576H77.8917ZM76.3127 82.4214H77.2484V85.5793H76.3127V82.4214ZM78.591 38.9626L45.4306 63.1547L43.5695 60.6035L76.7298 36.4115L78.591 38.9626ZM81.6037 34.2516C81.2252 36.1439 80.1501 37.8252 78.591 38.9626L76.7298 36.4115C77.6496 35.7405 78.2839 34.7486 78.5071 33.6322L81.6037 34.2516ZM81.6344 81.1933C81.6344 83.6156 79.6707 85.5793 77.2484 85.5793V82.4214C77.9267 82.4214 78.4765 81.8715 78.4765 81.1933H81.6344Z"
      fill="black"
      mask="url(#path-11-inside-2_86_5775)"
    />
    <path
      d="M10.5632 79.598L40.7175 57.9515C42.977 56.3295 46.0194 56.3295 48.279 57.9515L78.4333 79.598C78.9548 79.9724 79.2641 80.5751 79.2641 81.2171C79.2641 82.318 78.3718 83.2103 77.2709 83.2103H11.7255C10.6247 83.2103 9.73234 82.318 9.73234 81.2171C9.73234 80.5751 10.0416 79.9724 10.5632 79.598Z"
      fill="#5E2DE9"
      stroke="black"
      strokeWidth="1.57895"
    />
    <path
      d="M14.5037 40.7227L11.0908 37.746C10.0014 36.7958 9.25698 35.512 8.97349 34.0945L8.94287 33.9414V81.1928C8.94287 82.7431 10.1996 83.9998 11.7499 83.9998H14.5037V80.2571V40.7227Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M38.4773 58.936L14.8127 75.8472L33.7135 54.5715L38.4773 58.936Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M50.446 58.936L74.1106 75.8472L55.2098 54.5715L50.446 58.936Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M20.9534 18.9062L16.8478 20.9332L15.3926 24.1748V40.0396C15.3926 40.9535 15.8374 41.8101 16.5849 42.3358L20.9534 45.4077V18.9062Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M37.1155 37.1582V37.1582C42.636 38.6073 48.4371 38.6073 53.9576 37.1582V37.1582"
      stroke="#D7FB5B"
      strokeWidth="1.05263"
      strokeLinecap="round"
    />
    <path
      d="M43.5911 77.8466C42.3106 77.2173 41.4715 75.3565 41.4018 73.5234C41.3321 71.6904 41.7634 70.4656 43.1069 69.5576C43.5101 69.2852 43.7502 68.7271 44.5237 68.9464C45.2973 69.1657 46.2365 70.1308 46.6363 71.7227C47.0362 73.3146 46.7115 74.875 46.2751 75.8918C45.8387 76.9086 44.8717 78.476 43.5911 77.8466Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M41.0617 75.4197C40.6801 74.2051 40.6651 72.7413 40.854 72.1326C40.8808 72.062 40.854 71.9937 40.7738 71.9796C40.613 71.9359 40.3524 71.8795 39.9238 71.8341C39.2781 71.7657 37.813 71.5705 37.1183 72.3878C36.4237 73.205 36.665 73.9464 37.7602 75.9833C38.8554 78.0201 39.6454 78.6066 40.5236 78.6337C41.1119 78.6518 41.7502 78.2248 42.1075 77.941C42.2775 77.8059 42.3025 77.5657 42.1829 77.3844C41.8452 76.8726 41.202 75.8662 41.0617 75.4197Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M36.6078 69.8623C36.6795 70.6638 36.9588 71.3158 37.0597 71.5297C37.079 71.5705 37.1289 71.5851 37.169 71.5644C37.3632 71.4645 37.9381 71.2084 38.7862 71.1757C39.8434 71.1348 40.4784 71.4794 40.8968 71.4455C41.0259 71.435 41.08 71.0332 41.0199 70.826C40.9597 70.6188 40.2515 69.6708 39.5989 69.0832C38.8494 68.4084 38.0706 67.6365 37.4057 67.7197C36.7408 67.8029 36.5198 68.8789 36.6078 69.8623Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M44.2692 68.2665C43.723 68.2931 43.3753 68.4369 43.1469 68.6647C43.0999 68.7117 43.0021 68.6696 43.0121 68.6038C43.1562 67.6592 43.381 66.4843 44.0996 66.1633C45.0985 65.7169 45.523 65.8955 46.529 65.514C47.5021 65.1449 47.9098 65.8662 47.7977 67.0963C47.7123 68.0331 47.123 69.3487 46.7301 70.1166C46.7067 70.1625 46.6409 70.1607 46.6174 70.1148C46.0674 69.0408 44.9638 68.2327 44.2692 68.2665Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M46.8992 78.4703C46.6489 78.4933 46.1508 78.3433 45.936 78.0799C45.8262 77.9453 45.8499 77.7541 45.9236 77.5968C46.1629 77.0863 46.7199 76.517 46.9214 76.0117C47.3277 74.9932 47.465 74.0969 47.4029 73.5234C47.3152 72.7131 47.8424 72.2719 48.9729 71.7903C50.5105 71.1353 51.5518 72.6182 51.3247 73.4512C51.069 74.3892 51.0436 75.4445 50.0318 76.6522C49.2431 77.5935 47.6356 78.4025 46.8992 78.4703Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M50.2941 69.3773C51.5556 69.4005 52.3104 70.0298 51.1615 71.4665C51.1301 71.5058 51.0722 71.5132 51.0316 71.4835C50.6585 71.2111 50.0383 70.8676 49.1876 71.0819C48.5803 71.2349 47.8546 71.6528 47.4029 72.1516C47.3799 72.177 47.3364 72.1641 47.3317 72.1302C47.3055 71.9374 47.2326 71.4734 47.1001 71.1242C47.0672 71.0373 47.0647 70.9397 47.1074 70.8572C47.9029 69.3213 49.5206 69.3631 50.2941 69.3773Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M51.7762 66.2619C51.9216 67.3972 51.1042 68.1321 50.4522 68.3459C49.8003 68.5598 48.8353 68.4365 48.7881 67.9432C48.7409 67.4499 49.7108 66.4227 50.103 65.516C50.4951 64.6094 51.6308 65.1267 51.7762 66.2619Z"
      fill="white"
      fillOpacity="0.5"
    />
    <defs>
      <filter
        id="filter0_dd_86_5775"
        x="0.179226"
        y="0.957622"
        width="88.6386"
        height="96.0908"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1.05263"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_86_5775"
        />
        <feOffset dy="2.10526" />
        <feGaussianBlur stdDeviation="2.10526" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_86_5775"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1.05263"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_86_5775"
        />
        <feOffset dy="4.21053" />
        <feGaussianBlur stdDeviation="3.15789" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_86_5775"
          result="effect2_dropShadow_86_5775"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_86_5775"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
