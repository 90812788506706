import { createRoot } from "react-dom/client";
import { store } from "~src/redux/store";
import { openEmailTrackingAction, readTheRoomEmailSummaryAction, emailAction } from "~src/redux";
import { Provider } from "react-redux";
import { LvIdentifierContext, AuthenticationContext } from "~src/customHooks";
import { isAuthenticated, createEmailId } from "~src/utils";
import { OpenEmailCount, EmailSummary } from "./components";
import { Flex } from "antd";

export const CreateEmailThreadRoot = (
  emailContainerDom: HTMLElement,
  containerDom: HTMLElement,
  pixelId: pixelId
) => {
  const reactApp = document.createElement("div");
  reactApp.classList.add("lv-email-thread-root");
  const root = createRoot(reactApp);

  const validPixelId = pixelId || createEmailId();
  //INFO: we don't open email count if we don't have a pixelId
  if (pixelId) {
    store.dispatch(readTheRoomEmailSummaryAction.initialState({ lvIdentifier: pixelId }));
    store.dispatch(openEmailTrackingAction.initialState({ lvIdentifier: pixelId }));
    ensureEmailDataInitialized(pixelId);
  } else {
    // INFO: if pixelid is not found, we create a new one to store the email analytics
    // this is to allow you to read the room from a user's reply email who does not have the extension installed
    store.dispatch(readTheRoomEmailSummaryAction.initialState({ lvIdentifier: validPixelId }));
    store.dispatch(openEmailTrackingAction.initialState({ lvIdentifier: validPixelId }));
    ensureEmailDataInitialized(validPixelId);
  }

  root.render(
    <Provider store={store}>
      <LvIdentifierContext.Provider value={validPixelId}>
        <AuthenticationContext.Provider value={isAuthenticated()}>
          <Flex className="lv-email-thread" justify="space-around" align="center">
            {/* INFO: we don't show the open tracking if does not have a valid pixelId, the email may not be from our extension*/}
            {!!pixelId && <OpenEmailCount />}
            <EmailSummary container={emailContainerDom} />
          </Flex>
        </AuthenticationContext.Provider>
      </LvIdentifierContext.Provider>
    </Provider>
  );

  if (containerDom !== null) {
    const state = store.getState();
    // INFO: determine where to place the react app
    const emailThreadChildOrder = state.config.settings?.selectors?.emailThreadChildOrder;
    if (emailThreadChildOrder !== undefined && emailThreadChildOrder >= 0) {
      const targetElement = containerDom.children[emailThreadChildOrder];
      if (targetElement !== null) {
        containerDom.insertBefore(reactApp, targetElement);
      } else {
        containerDom.prepend(reactApp);
      }
    } else {
      containerDom.appendChild(reactApp);
    }
  }

  return root;
};

// INFO :check if the emailData reducer has initialized if not we initialize it.
// it can some times be initialized by the thread compose email from Gmail opening.
const ensureEmailDataInitialized = (lvIdentifier: lvIdentifier) => {
  const state = store.getState();
  if (state.emailData[lvIdentifier] === undefined) {
    store.dispatch(emailAction.initialize({ lvIdentifier }));
  }
};
