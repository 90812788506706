import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { ITechStack } from "~src/redux/typings";

interface Props {
  children: React.ReactNode;
}

export interface ITechStackContext {
  currentTechStack: ITechStack;
  resetTechStackContext: () => void;
}

const initialState: ITechStack = {
  techAggregate: [],
  loading: false,
};

const initTechStackContext: ITechStackContext = {
  currentTechStack: initialState,
  resetTechStackContext: () => {
    /* empty */
  },
};

const TechStackContext = createContext(initTechStackContext);

export const TechStackContextProvider = ({ children }: Props) => {
  const [techStack, setTechStack] = useState<ITechStack>(initialState);

  const resetTechStackContext = useCallback(() => {
    setTechStack(initialState);
  }, []);

  const techStackContext: ITechStackContext = useMemo(
    () => ({
      currentTechStack: techStack,
      resetTechStackContext,
    }),
    [resetTechStackContext, techStack]
  );

  return (
    <TechStackContext.Provider value={techStackContext}>
      {children}
    </TechStackContext.Provider>
  );
};

export const useTechStackContext = () => useContext(TechStackContext);
