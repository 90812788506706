import { EditEmailResponse, EditEmailSuccessResponse } from "~src/api/typings";

import { NetworkStatus, Recommendations } from ".";

export type BoostScoreState = Record<
  lvIdentifier,
  {
    abort: () => void;
    recommendations: Recommendations;
    boostScoreSuggestion: BoostScoreSuggestion;
    boostScoreStatus: NetworkStatus;
    boostScoreError: string;
    promptName?: string;
    stage: string;
  }
>;

export interface BoostScoreSuggestionPayload {
  controller: AbortController;
  lvIdentifier: lvIdentifier;
  chromeId: chromeId;
  body: string;
  promptName?: string; // email transformers
  recommendations: Recommendations;
  replyFlag?: boolean;
  stage: string;
  subject: string;
}

export interface BoostScoreSuggestionData {
  data: EditEmailResponse;
  lvIdentifier: lvIdentifier;
}

export type BoostScoreSuggestion = Required<
  Pick<EditEmailSuccessResponse, "subject" | "email">
> &
  Pick<EditEmailSuccessResponse, "bypassed_ai">;
