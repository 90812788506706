import { PaywallModal } from "~src/component/Molecules";
import { useShouldDisplayPaywall } from "~src/customHooks";

import { EmailCoach, EmailCoachCarousel } from "./components";
import { HighlightContextProvider } from "./contexts";

export const EmailCoachPanel = () => (
  <HighlightContextProvider>
    <EmailCoachCarousel>
      {useShouldDisplayPaywall("emailCoach") && (
        <PaywallModal view="emailCoach" />
      )}
      <EmailCoach />
    </EmailCoachCarousel>
  </HighlightContextProvider>
);
