import { useCallback, useMemo, useState } from "react";

interface IUseLoadMore<T> {
  currentItems: T[];
  loadMore: () => void;
  hasMore: boolean;
  loadMoreCount: number;
  resetCount: () => void;
}

export const useLoadMore = <T>(
  data: T[],
  initialCount = 5,
  loadMoreCount = 5
): IUseLoadMore<T> => {
  const [visibleCount, setVisibleCount] = useState(initialCount);
  const dataCount = data.length;

  const loadMore = useCallback(() => {
    setVisibleCount((currentCount) =>
      Math.min(currentCount + loadMoreCount, dataCount)
    );
  }, [dataCount, loadMoreCount]);

  const resetCount = useCallback(() => {
    setVisibleCount(initialCount);
  }, [dataCount, loadMoreCount]);

  const currentItems = useMemo(
    () => data.slice(0, visibleCount),
    [data, visibleCount]
  );

  return {
    currentItems,
    loadMore,
    hasMore: visibleCount < dataCount,
    loadMoreCount: Math.min(loadMoreCount, dataCount - visibleCount),
    resetCount,
  };
};
