import { store } from "~src/redux/store";
import { EmailWritingData } from "~src/redux/typings";

export const isEmailReplyOrFollowup = (
  props: EmailWritingData | undefined
): boolean => {
  if (!props) {
    return false;
  }

  const { subject, body } = props;
  if (!subject || !body) {
    return false;
  }
  if (
    subject.includes("RE:") ||
    subject.includes("Re:") ||
    subject.includes("re:")
  ) {
    return true;
  }

  const emailReplySelector =
    store.getState().config.settings?.selectors?.emailReplySelector;
  if (emailReplySelector) {
    const emailReply = new DOMParser()
      .parseFromString(body, "text/html")
      .body.querySelector(emailReplySelector);

    return !!emailReply;
  }

  return false;
};
