import { parse } from "tldts";

export const ensureFullUrl = (url: string) => {
  if (!url || url === "https://") {
    // Data bug
    return "";
  }

  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return "https://" + url;
  }

  return url;
};

export const formatUrl = (url: string) => {
  if (!url) return "";
  const newUrl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  return newUrl.endsWith("/") ? newUrl.slice(0, -1) : newUrl;
};


export const getDomainUrl = (url: string) => {
  if (!url) {
    return url;
  }
  let split = url.split("/");
  if (split.length > 1) {
    url = split[2];
  }
  split = url.split("?");
  if (split.length > 0) {
    return split[0];
  }
  return url;
};

export const isValidUrl = (url: string) => {
  const fullUrl = ensureFullUrl(url);
  const { hostname, domain, publicSuffix, isIcann } = parse(fullUrl);
  return !!hostname && !!domain && !!publicSuffix && isIcann;
};
