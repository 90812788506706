import {
  createSlice,
  createAsyncThunk,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { postReadTheRoom, postEmailSummary } from "../api";
import {
  EmailIdentifier,
  ReadTheRoomErrorMessage,
  ReadTheRoomStateEmailSummary,
  NetworkStatus,
} from "./typings";

const initialState: ReadTheRoomStateEmailSummary =
  {} as ReadTheRoomStateEmailSummary;
export const fetchReadTheRoom = createAsyncThunk(
  "readTheRoomEmailSummary/fetchReadTheRoom",
  async ({
    lvIdentifier,
    chromeId,
    body,
    subject,
    author_email,
    author_name,
    time_sent,
    recipients,
    emails_before,
  }: {
    lvIdentifier: lvIdentifier;
    chromeId: chromeId;
    body: string;
    subject: string;
    author_email: string;
    author_name: string;
    time_sent: number;
    recipients: string;
    emails_before: string;
  }) => {
    const response = await postReadTheRoom(
      chromeId,
      body,
      subject,
      author_email,
      author_name,
      time_sent,
      recipients,
      emails_before
    );
    return {
      lvIdentifier,
      data: response.data.analysis,
      error: response.data.error,
    };
  }
);

export const fetchEmailSummary = createAsyncThunk(
  "readTheRoomEmailSummary/fetchEmailSummary",
  async ({
    lvIdentifier,
    chromeId,
    body,
  }: {
    lvIdentifier: lvIdentifier;
    chromeId: chromeId;
    body: string;
  }) => {
    const response = await postEmailSummary(chromeId, body);
    return {
      lvIdentifier,
      data: response.data,
    };
  }
);
const readTheRoomEmailSummarySlice = createSlice({
  name: "readTheRoomEmailSummary",
  initialState,
  reducers: {
    initialState: (state, action: PayloadAction<EmailIdentifier>) => {
      if (action.payload.lvIdentifier) {
        state[action.payload.lvIdentifier] = {
          emailAnalysis: {},
          summary: "",
          isOpen: false,
          analyticsStatus: NetworkStatus.idle,
          analyticsError: "",
          emailSummaryStatus: NetworkStatus.idle,
          emailSummaryError: "",
        };
      }
    },
    close: (state, action: PayloadAction<EmailIdentifier>) => {
      if (action.payload.lvIdentifier) {
        state[action.payload.lvIdentifier].isOpen = false;
      }
    },
    open: (state, action: PayloadAction<EmailIdentifier>) => {
      if (action.payload.lvIdentifier) {
        state[action.payload.lvIdentifier].isOpen = true;
      }
    },
    toggle: (state, action: PayloadAction<EmailIdentifier>) => {
      if (action.payload.lvIdentifier) {
        state[action.payload.lvIdentifier].isOpen =
          !state[action.payload.lvIdentifier].isOpen;
      }
    },
    setAnalyticsErrorMessage: (
      state,
      action: PayloadAction<ReadTheRoomErrorMessage>
    ) => {
      if (action.payload.lvIdentifier) {
        state[action.payload.lvIdentifier].analyticsError =
          action.payload.error;
      }
    },
  },
  extraReducers(builder) {
    builder
      // INFO: read the room
      .addCase(fetchReadTheRoom.pending, (state, action) => {
        state[action.meta.arg.lvIdentifier].analyticsStatus =
          NetworkStatus.loading;
      })
      .addCase(fetchReadTheRoom.fulfilled, (state, action) => {
        state[action.payload.lvIdentifier].emailAnalysis = action.payload.data;
        state[action.payload.lvIdentifier].analyticsError =
          action.payload.error;
        state[action.payload.lvIdentifier].analyticsStatus =
          NetworkStatus.success;
      })
      .addCase(fetchReadTheRoom.rejected, (state, action) => {
        state[action.meta.arg.lvIdentifier].analyticsStatus =
          NetworkStatus.failed;
        state[action.meta.arg.lvIdentifier].analyticsError =
          action.error.message ?? "";
      })
      // INFO: email summary
      .addCase(fetchEmailSummary.pending, (state, action) => {
        state[action.meta.arg.lvIdentifier].emailSummaryStatus =
          NetworkStatus.loading;
      })
      .addCase(fetchEmailSummary.fulfilled, (state, action) => {
        state[action.payload.lvIdentifier].summary = action.payload.data;
        state[action.payload.lvIdentifier].emailSummaryStatus =
          NetworkStatus.success;
      })
      .addCase(fetchEmailSummary.rejected, (state, action) => {
        state[action.meta.arg.lvIdentifier].emailSummaryStatus =
          NetworkStatus.failed;
        state[action.meta.arg.lvIdentifier].emailSummaryError =
          action.error.message ?? "";
      });
  },
});

export const readTheRoomEmailSummaryReducer =
  readTheRoomEmailSummarySlice.reducer;
export const readTheRoomEmailSummaryAction =
  readTheRoomEmailSummarySlice.actions;
