import styled from "styled-components";

export const SearchModalSuggestionsContainer = styled.div`
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const SuggestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const SuggestionsHeader = styled.div`
  display: flex;
  padding: 0px 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  height: 21px;
`;

export const SuggestionsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const SuggestionRowContainer = styled.div`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;

  &:hover {
    background-color: var(--color-neutral-purple-50, #f6f8fc);
  }
`;

export const SuggestionRowTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const QuickActionsContainer = styled.div`
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-top: 1px solid var(--color-neutral-purple-100, #e9ebf2);
`;

export const QuickActionsHeader = styled.div`
  height: 21px;
  display: flex;
  padding: 0px 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
