import { useAppSelector } from "~src/redux";
import { makeSelectUserAccountSetting } from "~src/redux/userAccount";
import { User } from "~src/redux/typings";

const selectUserAccountSetting = makeSelectUserAccountSetting();

export const useUserSetting = <K extends keyof User["settings"]>(
  setting: K
): User["settings"][K] => {
  return useAppSelector((state) => selectUserAccountSetting(state, setting));
};
