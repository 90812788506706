import { useEffect, useMemo } from "react";
import { Divider, Flex } from "antd";
import { styled } from "styled-components";

import { Icon, Text } from "@lavender-ai/lav-components";

import { useFeatureFlag, useLvIdentifier } from "~src/customHooks";

import { LavenderIcon } from "~src/component/typings";
import { BOOST_SCORE_CUTOFF_SCORE, MAILCLIENT } from "~src/constants";
import {
  EmailCoachView,
  useEmailCoachCarouselContext,
  useMobileEditor,
} from "~src/contexts";
import {
  getBoostScoreSuggestion,
  selectChromeId,
  selectBoostScoreAbortBySessionId,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";
import { InsertType, Recommendation } from "~src/redux/typings";
import {
  SegmentEventLocations,
  SegmentEventNames,
  getPromptName,
  segmentEvent,
} from "~src/utils";

import {
  OpenLavenderAnywhereIframe,
  OpenLavenderAnywherePopup,
} from "../../../../../../components/EmailCoach/components/EditorMode/Activate";
import { useHighlight } from "../../../../../../contexts";
import { Suggestion } from "../Suggestion/Suggestion";
import { Removal } from "../Removal/Removal";
import { getWritingData } from "~src/layout/MainPanel/EmailCoachPanel/utils";
import { isContentField } from "~src/constants/contentFields";

interface Props {
  recommendation: Recommendation;
  score: number | undefined;
  type: string;
  toggleOn: () => void;
  toggleOff: () => void;
}

export const CategoryItem = ({
  recommendation,
  score,
  type,
  toggleOn,
  toggleOff,
}: Props) => {
  const dispatch = useAppDispatch();
  const { next } = useEmailCoachCarouselContext();
  const { selected, suggestion, setHighlighted, regenerate } = useHighlight();

  const showEffectOnScore = useFeatureFlag("effect_on_score");

  const chromeId = useAppSelector(selectChromeId);
  const lvIdentifier = useLvIdentifier();
  const analysisData = useAppSelector(
    (state) => state.emailAnalysis[lvIdentifier]
  );
  const emailData = useAppSelector((state) => state.emailData[lvIdentifier]);
  const abortBoostScore = useAppSelector((state) =>
    selectBoostScoreAbortBySessionId(state, lvIdentifier)
  );
  const writingData = getWritingData(lvIdentifier);
  const body = writingData.body;
  const subject = writingData.subject;
  const stage = analysisData.stage;

  const canShowFixItAllModalForItem = !!(
    score && score < BOOST_SCORE_CUTOFF_SCORE
  );

  const isSelected = selected?.id === recommendation.id;

  const { showMobileEditor } = useMobileEditor();

  const contentField: boolean = useMemo(
    () => isContentField(recommendation.field),
    [recommendation.field]
  );

  const getSuggestions = () => {
    if (!recommendation?.text) {
      return;
    }
    if (selected?.id === recommendation.id) {
      clearSuggestions();
    } else if (selected !== undefined) {
      clearSuggestions();
      toggleOn();
      setTimeout(() => {
        setHighlighted(recommendation, chromeId, lvIdentifier);
      }, 100);
    } else if (selected === undefined) {
      setHighlighted(recommendation, chromeId, lvIdentifier);
    }
  };

  const clearSuggestions = () => {
    setHighlighted(undefined);
    toggleOff();
  };

  let chevron = LavenderIcon.IconChevronRight;
  if (!recommendation?.text && isSelected) {
    chevron = LavenderIcon.IconChevronDown;
  } else if (!recommendation?.text && !isSelected) {
    chevron = LavenderIcon.IconChevronUp;
  }

  const expandSelected = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (isSelected) {
      setHighlighted(undefined);
    } else {
      if (recommendation.field === "mobile_optimized") {
        showMobileEditor(e);
      }
      setHighlighted(recommendation, chromeId, lvIdentifier);
    }
  };
  const { platform, insertType } =
    useAppSelector((state) => state.config.settings) || {};

  useEffect(() => {
    const highlightData = window.email_data?.highlightData;
    let timer: NodeJS.Timeout | undefined;

    if (
      highlightData &&
      recommendation.text === highlightData.text &&
      recommendation.rectype === highlightData.rectype
    ) {
      toggleOn();
      timer = setTimeout(() => {
        getSuggestions();
      }, 1000);
      window.email_data = undefined; //do once
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return (
    <>
      {recommendation?.text && !contentField && isSelected && (
        <Suggestion
          recommendation={recommendation}
          suggestions={suggestion}
          clear={clearSuggestions}
          regenerate={() => {
            regenerate(recommendation, chromeId);
          }}
        />
      )}
      {recommendation?.text && contentField && isSelected && (
        <Removal
          recommendation={recommendation}
          suggestions={suggestion}
          clear={clearSuggestions}
        />
      )}
      <CategoryContainer
        className={`lv-email-category-item lv-${type}`}
        onClick={(e) => {
          segmentEvent(
            SegmentEventNames.SuggestionSelected,
            SegmentEventLocations.EmailCoach
          );
          const promptName = getPromptName(recommendation);
          if (
            recommendation.text &&
            recommendation.rectype !== "SPAM Detected"
          ) {
            if (insertType === InsertType.Copy) {
              const highlightData = recommendation;
              if (platform === MAILCLIENT.OUTLOOK_NATIVE)
                OpenLavenderAnywherePopup(
                  chromeId,
                  emailData,
                  analysisData,
                  highlightData
                );
              else
                OpenLavenderAnywhereIframe(
                  chromeId,
                  emailData,
                  analysisData,
                  highlightData
                );
              return;
            }
            getSuggestions();
          } else if (canShowFixItAllModalForItem && promptName) {
            abortBoostScore();
            const controller = new AbortController();
            void dispatch(
              getBoostScoreSuggestion({
                controller,
                lvIdentifier,
                chromeId,
                body,
                subject,
                recommendations: [recommendation],
                promptName,
                stage,
              })
            );
            next(EmailCoachView.fixItAll, { fixItRec: recommendation });
          } else if (recommendation.rectype !== "SPAM Detected") {
            expandSelected(e);
          }
        }}
        onMouseEnter={!selected ? toggleOn : undefined}
        onMouseLeave={!selected ? toggleOff : undefined}
      >
        <CategoryHeader>
          <VerticalBar className="lv-vertical-bar" />
          <div style={{ flex: "1" }}>
            <Text color="dark" size="body2">
              {recommendation?.rectype}
            </Text>
          </div>
          <Arrow
            className={`lv-ec-arrow ${
              isSelected ? "lc-ec-arrow-active" : null
            }`}
          >
            <Icon
              variant={chevron}
              size="extra-small"
              style={{
                color: "var(--color-neutral-purple-900)",
              }}
            />
          </Arrow>
          {showEffectOnScore && (
            <Text color="dark">+{recommendation?.effectonscore}</Text>
          )}
        </CategoryHeader>
        {isSelected && !recommendation?.text && (
          <>
            <Divider style={{ margin: 0 }} />
            <Text
              size="body1"
              style={{
                color: "var(--color-neutral-purple-900)",
                padding: "12px",
              }}
              className="lv-dropdown-txt"
              dangerouslySetInnerHTML={{
                __html: "⚠️ &nbsp;" + recommendation?.recommendation,
              }}
            />
          </>
        )}
      </CategoryContainer>
    </>
  );
};

const VerticalBar = styled.div`
  width: 4px;
  min-width: 4px;
  height: 20px;
  border-radius: 11px;
  background: var(--color-neutral-purple-100, #ececee);
`;
const Arrow = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0px 12px;
  height: 100%;
  border-radius: var(--radius-none, 0px) var(--radius-md, 8px)
    var(--radius-md, 8px) var(--radius-none, 0px);
  background: linear-gradient(
    90deg,
    rgba(248, 247, 248, 0) 0%,
    rgba(248, 247, 248, 0.9) 21.99%,
    #f8f7f8 51.02%
  );
`;

const CategoryContainer = styled.div`
  border-radius: 8px;
  border: 1px solid var(--color-neutral-purple-100, #ececee);
  background: var(--color-base-White, #fff);
  margin-bottom: 8px;
  cursor: pointer;
  position: relative;
  &:hover {
    background: var(--lv-panel-background-color);
  }
  &:hover .lv-ec-arrow,
  .lv-ec-arrow.lc-ec-arrow-active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lv-dropdown-txt > span {
    font-weight: bold;
  }
  &.lv-warning .lv-vertical-bar {
    background: var(--color-utility-Warning, #f6a40a);
  }
  &.lv-danger .lv-vertical-bar {
    background: var(--color-utility-Negative, #f05656);
  }
`;

const CategoryHeader = styled(Flex)`
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 1;
  padding: 12px;
`;
