import { Icon, IconProps, Text } from "@lavender-ai/lav-components";
import {
  SuggestionRowContainer,
  SuggestionRowTitleContainer,
} from "./SearchModalSuggestions.styled";
import { Avatar } from "antd";

interface Props {
  title: string;
  icon: IconProps["variant"];
  targetString: "Company" | "Recipient";
  onClick: () => void;
}

export const SuggestionRow = ({
  icon,
  title,
  targetString,
  onClick,
}: Props) => (
  <SuggestionRowContainer onClick={onClick}>
    <SuggestionRowTitleContainer>
      <Avatar
        size="small"
        icon={
          <Icon
            variant={icon}
            size="extra-small"
            style={{ alignSelf: "center" }}
          />
        }
        style={{
          display: "flex",
          justifyContent: "center",
          borderRadius: "var(--radius-md)",
          background: "var(--color-neutral-purple-100, #E9EBF2)",
        }}
      />
      <Text size="body2" color="dark">
        {title}
      </Text>
    </SuggestionRowTitleContainer>
    <Text
      size="body2"
      style={{
        color: "var(--color-neutral-purple-500, #B3B6BD)",
      }}
    >
      {targetString}
    </Text>
  </SuggestionRowContainer>
);
