import { Layout } from "antd";
import { forwardRef } from "react";
import { styled } from "styled-components";

import { PropsForStyling } from "~src/component/typings";

interface StyledPanelLayoutProps {
  isFullHeight?: boolean;
}

export const StyledPanelLayout = styled(Layout)<
  PropsForStyling<StyledPanelLayoutProps>
>`
  justify-content: space-between;
  text-align: center;

  ${({ $isFullHeight: isFullHeight }) => isFullHeight && `min-height: 100%;`}
`;

interface Props {
  children: React.ReactNode;
  className?: string;
  rootClassName?: string;
  isFullHeight?: boolean;
  style?: React.CSSProperties;
}

export const PanelLayout = forwardRef<HTMLDivElement, Props>(
  ({ isFullHeight, ...props }, ref) => (
    <StyledPanelLayout ref={ref} {...props} $isFullHeight={isFullHeight} />
  )
);
PanelLayout.displayName = "PanelLayout";
