import { FC, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Search } from "@lavender-ai/lav-components";
import { CallRow } from "./CallRow";
import { useGongData } from "../../../../../../customHooks";

const ComponentFrame = styled.div`
  border-radius: var(--radius-lrg, 16px);
  background: var(--color-base-White, #fff);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  text-align: left;
  padding: 0px;
`;

const TitleFrame = styled.div`
  display: flex;
  padding: 20px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: var(--color-neutral-purple-900, #18253d);
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 11px;
  letter-spacing: -0.1px;
`;

const CallRowFrame = styled.div`
  display: flex;
  padding: 0px 8px 8px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const ShowMoreButton = styled(Button)`
  margin: 12px 20px;
  width: calc(100% - 40px); // Subtract double the margin size
`;

export const GongData: FC = () => {
  const gongCalls = useGongData();
  const [searchText, setSearchText] = useState("");
  const [displayAmount, setDisplayAmount] = useState(20);

  const displayedGongCalls = useMemo(
    () =>
      gongCalls.filter(
        ({ title, participants }) =>
          title.toLowerCase().includes(searchText.toLowerCase()) ||
          participants.some((participant) =>
            participant.toLowerCase().includes(searchText.toLowerCase())
          )
      ),
    [gongCalls, searchText]
  );

  const showMoreClick = () => {
    setDisplayAmount(displayedGongCalls.length);
  };

  return (
    <ComponentFrame>
      <TitleFrame>Gong Data</TitleFrame>
      <Search
        style={{ padding: "0 12px 12px" }}
        placeholder={"Search calls..."}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      <CallRowFrame>
        {displayedGongCalls.slice(0, displayAmount).map((call, index) => (
          <CallRow key={index} call={call} />
        ))}
        {displayedGongCalls.length > displayAmount && (
          <ShowMoreButton onClick={showMoreClick}>
            + Show {displayedGongCalls.length - displayAmount} More
          </ShowMoreButton>
        )}
      </CallRowFrame>
    </ComponentFrame>
  );
};
