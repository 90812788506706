import { isAxiosError } from "axios";

import { networkRequest, URLS } from "~src/api";

import { UserAccountErrorResponse, UserAccountResponse } from "./typings";

export const getUserAccount = async (
  chromeId: chromeId
): Promise<UserAccountResponse> => {
  try {
    const { data } = await networkRequest.post<UserAccountResponse>(URLS.user, {
      chrome_id: chromeId,
    });
    if (data.user) {
      void window.userpilot?.identify?.(data.user?.id, {
        name: `${data.user.first_name} ${data.user.last_name}`,
        ...data.user,
      });
    }
    return data;
  } catch (error) {
    if (isAxiosError<UserAccountErrorResponse>(error) && error.response) {
      return error.response.data;
    } else {
      return { message: "An unknown error occurred", status: "error" };
    }
  }
};
