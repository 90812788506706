import { useCallback } from "react";
import { Switch, Tooltip } from "@lavender-ai/lav-components";

import {
  emailAction,
  selectEmailData,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";
import { useLvIdentifier } from "~src/customHooks";

export const AnalyticsToggle = () => {
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const emailData = useAppSelector(selectEmailData(lvIdentifier));
  const toggleIncludeInAnalytics = useCallback(
    (checked: boolean) => {
      void dispatch(
        emailAction.toggleAnalytics({
          id: lvIdentifier,
          includeInAnalytics: checked,
        })
      );
    },
    [dispatch, lvIdentifier]
  );

  return (
    <Tooltip
      hideArrow
      placement="right"
      title={
        <>
          <div style={{ fontWeight: "bold", fontSize: "14px" }}>
            Toggle Analytics
          </div>
          <div style={{ fontSize: "12px" }}>
            Toggle if this email should be included in your Lavender reporting &
            analytics.
          </div>
        </>
      }
    >
      <Switch
        size="small"
        defaultChecked
        checked={emailData?.includeInAnalytics ?? true}
        onChange={toggleIncludeInAnalytics}
      />
    </Tooltip>
  );
};
