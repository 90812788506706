import { networkRequest, URLS } from "~src/api";
import { CRMResponse } from "./typings";

export const getCRMData = async (
  email: string,
  chromeId: chromeId
): Promise<CRMResponse> => {
  const { data } = await networkRequest.post<CRMResponse>(URLS.crmData, {
    email,
    chrome_id: chromeId,
  });

  return data;
};
