import styled from "styled-components";
import { Button } from "@lavender-ai/lav-components";

import { MAILCLIENT } from "~src/constants";
import { useLvIdentifier } from "~src/customHooks";
import {
  selectChromeId,
  selectEmailAnalysis,
  selectEmailData,
  useAppSelector,
} from "~src/redux";
import { InsertType } from "~src/redux/typings";

import {
  OpenLavenderAnywhereIframe,
  OpenLavenderAnywherePopup,
} from "../../EditorMode/Activate";

export const EditorModeButton = () => {
  const lvIdentifier = useLvIdentifier();
  const chromeId = useAppSelector(selectChromeId);
  const analysisData = useAppSelector(selectEmailAnalysis(lvIdentifier));
  const emailData = useAppSelector(selectEmailData(lvIdentifier));
  const { platform, insertType } = useAppSelector(
    (state) => state.config.settings ?? { platform: "", insertType: "" }
  );

  return (
    InsertType[insertType] === InsertType.Copy && (
      <StyledButton
        variant={"primary"}
        size={"medium"}
        state={"initial"}
        suffix={"IconExternalLink"}
        onClick={() => {
          platform === MAILCLIENT.OUTLOOK_NATIVE
            ? OpenLavenderAnywherePopup(chromeId, emailData, analysisData)
            : OpenLavenderAnywhereIframe(chromeId, emailData, analysisData);
        }}
      >
        Open Editor Mode
      </StyledButton>
    )
  );
};

const StyledButton = styled(Button)`
  margin: 8px;
  width: calc(100% - 16px);
  .lav-components-icon {
    stroke: var(--color-lavender-200, #f5f7ff);
  }
`;
