import { createContext, PropsWithChildren, useContext, useMemo } from "react";

const LOCAL_STORAGE_KEY = "lv-hasNewStreakLevel";

interface IStreakNotificationContext {
  hasNewStreakLevel: () => boolean;
  setHasNewStreakLevel: () => void;
  clearHasNewStreakLevel: () => void;
}

const StreakNotificationContext = createContext<IStreakNotificationContext>({
  hasNewStreakLevel: () => false,
  setHasNewStreakLevel: () => {},
  clearHasNewStreakLevel: () => {},
});

export const useStreakNotificationContext = () => {
  const context = useContext(StreakNotificationContext);
  if (context === undefined) {
    throw new Error(
      "useStreakNotificationContext must be used within a StreakNotificationContextProvider"
    );
  }
  return context;
};

// use localstorage so other lv contexts can access this value
const hasNewStreakLevel = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY) === "true";
};
const setHasNewStreakLevel = () => {
  localStorage.setItem(LOCAL_STORAGE_KEY, "true");
};
const clearHasNewStreakLevel = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};

export const StreakNotificationContextProvider = ({
  children,
}: PropsWithChildren) => {
  const contextValue = useMemo<IStreakNotificationContext>(
    () => ({
      hasNewStreakLevel,
      setHasNewStreakLevel,
      clearHasNewStreakLevel,
    }),
    []
  );

  return (
    <StreakNotificationContext.Provider value={contextValue}>
      {children}
    </StreakNotificationContext.Provider>
  );
};
