export const cleanNum = (val2: number): string => {
  if (val2 >= 1000000000) {
    // billion
    val2 = val2 / 1000000000;
    return `$${val2.toFixed(1)}B+`;
  } else if (val2 >= 2000000) {
    // multi-million
    val2 = val2 / 1000000;
    return `$${val2.toFixed(1)}MM+`;
  } else if (val2 >= 1000000) {
    // million
    val2 = val2 / 1000000;
    return `$${val2.toFixed(1)}M+`;
  }
  return "";
};
