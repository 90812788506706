import { getTechStack } from "~src/api";
import {
  RecipientTechStackMap,
  TechStackData,
  NetworkStatus,
} from "~src/redux/typings";
import { createFeedAsyncThunk, parseTechStackResponse } from "../helpers";
import { newPendingTechStack, newRejectedTechStack } from "~src/utils";
import { updateTechStacks } from "../actions";

export const loadTechStack = createFeedAsyncThunk<
  TechStackData,
  { toAddresses: emailAddress[] }
>(
  "feed/techStack",
  async ({ toAddresses }, { dispatch, getState }): Promise<TechStackData> => {
    const {
      config: { chromeId },
      feed: { techStacks: techStackData },
      userAccount: {
        settings: { user },
      },
    } = getState();

    if (!chromeId || user === undefined) {
      return { toAddresses, techStackData: {} };
    }

    if (!toAddresses.length) {
      return { toAddresses, techStackData };
    }

    const techStackDataFetchEmails = toAddresses.filter(
      (email) => !techStackData[email]
    );

    const pendingTechStackData =
      techStackDataFetchEmails.reduce<RecipientTechStackMap>(
        (emails, email) => {
          emails[email] = newPendingTechStack();
          return emails;
        },
        {}
      );

    dispatch(
      updateTechStacks({
        toAddresses,
        techStackData: pendingTechStackData,
      })
    );

    const resolvedTechStacks = await Promise.all(
      techStackDataFetchEmails.map(async (email) => {
        try {
          const techStackDataResponse = await getTechStack(email, chromeId);
          return {
            status: NetworkStatus.success,
            email,
            data: parseTechStackResponse(techStackDataResponse),
          };
        } catch {
          return {
            status: NetworkStatus.failed,
            email,
            data: newRejectedTechStack(),
          };
        }
      })
    );

    const parsedFullfilledTechStacks =
      resolvedTechStacks.reduce<RecipientTechStackMap>(
        (acc, { email, data }) => {
          acc[email] = data;
          return acc;
        },
        {}
      );

    dispatch(
      updateTechStacks({
        toAddresses,
        techStackData: parsedFullfilledTechStacks,
      })
    );

    return {
      toAddresses,
      techStackData: parsedFullfilledTechStacks,
    };
  }
);
