/* Should match the events defined here https://docs.google.com/spreadsheets/d/1u7OjaPeHTUqVfvpr3BTR4MtZwhPakD4DqiQci5FA7Ck/edit#gid=1154402133 */

import { viewState } from "~src/redux/typings";
import { PERSONALIZATION_SUB_TAB } from "~src/strings";

export enum SegmentEventLocations {
  PersonalizationAssistant = "Personalization Assistant",
  EmailCoach = "Email Coach",
  ChatGPT = "ChatGPT",
  MobileEditor = "Mobile Editor",
  Frameworks = "Frameworks",
  StartMyEmail = "Start My Email",
  Gifs = "Gifs",
  Streak = "Streak",
  ExtensionMainMenu = "Extension main menu",
  ExtensionMainPage = "Extension main page",
  SettingsInExtension = "Settings in Extension",
  BoostScorePopup = "Boost Score Popup",
}

export enum SegmentEventNames {
  PaywallDisplayed = "Paywall Displayed",
  PersonalizationAssistantClicked = "Personalization Assistant Clicked",
  BackButtonClicked = "Back Button Clicked",
  MoreInfoClicked = "More Info Clicked",
  LessInfoClicked = "Less Info Clicked",
  ProfileSocialFeedClicked = "Person Social Feed Icon Clicked",
  PodcastsClicked = "Podcast Icon Clicked",
  NewsEventsClicked = "News and events Icon Clicked",
  SalesforceClicked = "Salesforce Icon Clicked",
  HubspotClicked = "Hubspot Icon Clicked",
  CompanySocialFeedClicked = " Company Social Feed Icon Clicked",
  TwitterXIconClicked = "Twitter/X Icon Clicked",
  AboutProfileClicked = "About Profile Clicked",
  PersonalityClicked = "Personality Clicked",
  WorkHistoryClicked = "Work History Clicked",
  RecentActivityClicked = "Recent Activity Clicked",
  GongDataClicked = "Gong Data Clicked",
  AboutCompanyClicked = "About Company Clicked",
  JobOpeningsClicked = "Job Openings Clicked",
  TechStackClicked = "Tech Stack Clicked",
  FundingClicked = "Funding Clicked",
  SearchClicked = "Search Clicked",
  CompanyChevronClicked = "Company Chevron Clicked",
  EmailCoachClickedOpen = "Email Coach Clicked Open",
  EmailCoachClickedClosed = "Email Coach Clicked Closed",
  WritingTimeHovered = "Writing Time Hovered",
  ReadingTimeHovered = "Reading Time Hovered",
  ColdEmailSelected = "Cold Email Selected",
  ReplySelected = "Reply Selected",
  FollowUpSelected = "Follow Up Selected",
  PersonalizedSelected = "Personalized Selected",
  BestPracticesSelected = "Best Practices Selected",
  ChatGPTHovered = "ChatGPT Hovered",
  ChatGPTClicked = "ChatGPT Clicked",
  BrowseToggledOff = "Browse Toggled Off",
  BrowseToggledOn = "Browse Toggled On",
  MobileEditorClicked = "Mobile Editor Clicked",
  MobileEditorDragged = "Mobile Editor Dragged",
  DarkModeClicked = "Dark Mode Clicked",
  LightModeClicked = "Light Mode Clicked",
  AppleWatchClicked = "Apple Watch Clicked",
  MobilePhoneClicked = "Phone Clicked",
  FrameworksClicked = "Frameworks Clicked",
  StartMyEmailClicked = "Start My Email Clicked",
  FeedClicked = "Feed Clicked",
  CompanyClicked = "Company Clicked",
  ProfileClicked = "Profile Clicked",
  IcebreakerClicked = "Icebreaker Clicked",
  GenerateClicked = "Generate Clicked",
  GenerateEmailsClicked = "Generate Emails Clicked",
  GenerateValuePropsClicked = "Generate Value Props Clicked",
  CheckboxClicked = "Checkbox Clicked",
  ContextAdded = "Context Added",
  CopyButtonClicked = "Copy Button Clicked",
  GifsClicked = "Gifs Clicked",
  GifsSearched = "Gifs Searched",
  GifsDragged = "Gifs Dragged",
  SettingsClicked = "Settings Clicked",
  SupportClicked = "Support Clicked",
  ProductTourClicked = "Product Tour Clicked",
  EmailAndExtensionClicked = "Email and Extension Clicked",
  AccountAndPersonalizationClicked = "Account and Personalization Clicked",
  IntegrationsClicked = "Integrations Clicked",
  PlansAndBillingClicked = "Plans and Billing Clicked",
  EmailSignatureClicked = "Email Signature Clicked",
  SecurityClicked = "Security Clicked",
  SentAEmail = "Sent A Email",
  SentBEmail = "Sent B Email",
  SentCEmail = "Sent C Email",
  RecInserted = "Rec Inserted",
  SuggestionSelected = "Suggestion Selected",
  SendAnywayClicked = "Send Anyway Clicked",
  BoostScoreClicked = "Boost Score Clicked",
  ShareToLinkedinClicked = "Share to Linkedin Clicked",
  StreakClicked = "Streak Clicked",
  StreakIncreased = "Streak Increased",
}

export const SegmentEventDescriptions: Record<SegmentEventNames, string> = {
  [SegmentEventNames.PaywallDisplayed]:
    "User was shown a paywall for a feature",
  [SegmentEventNames.BackButtonClicked]: "User clicked the back button",
  [SegmentEventNames.PersonalizationAssistantClicked]:
    "User clicked personalization assistant icon from compose window",
  [SegmentEventNames.MoreInfoClicked]:
    "User clicked more info within the Personalization Assistant, revealing data",
  [SegmentEventNames.LessInfoClicked]:
    "User clicked less info within the Personalization Assistant, hiding data",
  [SegmentEventNames.ProfileSocialFeedClicked]:
    "User clicked person social feed icon within the Personalization Assistant",
  [SegmentEventNames.PodcastsClicked]:
    "User clicked podcast icon within the Personalization Assistant",
  [SegmentEventNames.NewsEventsClicked]:
    "User clicked news and events icon within the Personalization Assistant",
  [SegmentEventNames.SalesforceClicked]:
    "User clicked salesforce icon within the Personalization Assistant",
  [SegmentEventNames.HubspotClicked]:
    "User clicked hubspot icon within the Personalization Assistant",
  [SegmentEventNames.CompanySocialFeedClicked]:
    "User clicked company social feed icon within the Personalization Assistant",
  [SegmentEventNames.TwitterXIconClicked]:
    "User clicked Twitter icon within the Personalization Assistant",
  [SegmentEventNames.AboutProfileClicked]:
    "User clicked the about page within profile section of the Personalization Assistant",
  [SegmentEventNames.PersonalityClicked]:
    "User clicked the personality page within the Personalization Assistant",
  [SegmentEventNames.WorkHistoryClicked]:
    "User clicked the work history page within the Personalization Assistant",
  [SegmentEventNames.RecentActivityClicked]:
    "User clicked the recent activity page within the Personalization Assistant",
  [SegmentEventNames.GongDataClicked]:
    "User clicked the gong data page within the Personalization Assistant",
  [SegmentEventNames.AboutCompanyClicked]:
    "User clicked the about page within the company section of the Personalization Assistant",
  [SegmentEventNames.JobOpeningsClicked]:
    "User clicked the job openings page within the Personalization Assistant",
  [SegmentEventNames.TechStackClicked]:
    "User clicked the tech stack page within the Personalization Assistant",
  [SegmentEventNames.FundingClicked]:
    "User clicked the funding page within the Personalization Assistant",
  [SegmentEventNames.SearchClicked]:
    "User clicked the search bar/icon under News and Events/Tech Stack/Job Openings",
  [SegmentEventNames.CompanyChevronClicked]:
    "User clicked the chevron on the feed to take them to the company page",
  [SegmentEventNames.EmailCoachClickedOpen]:
    "User clicked the email coach icon from compose window",
  [SegmentEventNames.EmailCoachClickedClosed]:
    "User clicked the email coach icon from compose window, hiding the suggestions.",
  [SegmentEventNames.WritingTimeHovered]:
    "User hovered over the writing time within the Email Coach",
  [SegmentEventNames.ReadingTimeHovered]:
    "User hovered over the reading time within the Email Coach",
  [SegmentEventNames.ColdEmailSelected]:
    "User selected cold email from the dropdown within the email coach",
  [SegmentEventNames.ReplySelected]:
    "User selected reply from the dropdown within the email coach",
  [SegmentEventNames.FollowUpSelected]:
    "User selected follow up from the dropdown within the email coach",
  [SegmentEventNames.ChatGPTHovered]: "User hovered over the ChatGPT icon",
  [SegmentEventNames.ChatGPTClicked]: "User clicked ChatGPT icon",
  [SegmentEventNames.BrowseToggledOff]: "User toggled off browse with google",
  [SegmentEventNames.BrowseToggledOn]: "User toggled on browse with google",
  [SegmentEventNames.MobileEditorClicked]:
    "User clicked mobile editor icon from compose window",
  [SegmentEventNames.MobileEditorDragged]:
    "User dragged the mobile editor using the drag state",
  [SegmentEventNames.DarkModeClicked]:
    "User clicked dark mode within the mobile editor",
  [SegmentEventNames.LightModeClicked]:
    "User clicked light mode within the mobile editor",
  [SegmentEventNames.AppleWatchClicked]:
    "User clicked apple watch icon within the mobile editor",
  [SegmentEventNames.MobilePhoneClicked]:
    "User clicked phone icon within the mobile editor",
  [SegmentEventNames.FrameworksClicked]: "User clicks on the frameworks icon.",
  [SegmentEventNames.GifsClicked]: "User clicked gifs icon.",
  [SegmentEventNames.GifsSearched]: "User searched gifs library.",
  [SegmentEventNames.GifsDragged]: "User dragged a gif from the library.",
  [SegmentEventNames.SettingsClicked]:
    "User clicked settings within the Lavender extension",
  [SegmentEventNames.SupportClicked]:
    "User clicked support within the Lavender extension settings",
  [SegmentEventNames.ProductTourClicked]:
    "User clicked product tour within the Lavender extension settings",
  [SegmentEventNames.EmailAndExtensionClicked]:
    "User clicked email and extension within the extension settings",
  [SegmentEventNames.AccountAndPersonalizationClicked]:
    "User clicked account and personalization within the extension settings",
  [SegmentEventNames.IntegrationsClicked]:
    "User clicked integrations within the extension",
  [SegmentEventNames.PlansAndBillingClicked]:
    "User clicked plans and billing within the extension",
  [SegmentEventNames.EmailSignatureClicked]:
    "User clicked email signature within the extension",
  [SegmentEventNames.SecurityClicked]:
    "User clicked security within the extension",
  [SegmentEventNames.SentAEmail]: "User sent an A email from the extension",
  [SegmentEventNames.SentBEmail]: "User sent a B email from the extension",
  [SegmentEventNames.SentCEmail]: "User sent a C email from the extension",
  [SegmentEventNames.RecInserted]: "User inserted a recommendation",
  [SegmentEventNames.SuggestionSelected]: "User selected a suggestion",
  // Start My Email
  [SegmentEventNames.CheckboxClicked]:
    "User unchecks or checks the box where they should be adding context for SME",
  [SegmentEventNames.ContextAdded]: "User adds context in the SME flow",
  [SegmentEventNames.CopyButtonClicked]:
    "User copies over AI generated content",
  [SegmentEventNames.CompanyClicked]:
    "User clicked the company within the Start My Email homepage",
  [SegmentEventNames.FeedClicked]:
    "User clicked Feed/Recent Updates within the Start My Email homepage",
  [SegmentEventNames.GenerateClicked]:
    "User clicked generate from the carousel view where they removed all the icebreakers to begin generating value props",
  [SegmentEventNames.GenerateEmailsClicked]:
    "User clicked generate emails to begin writing an AI generated email",
  [SegmentEventNames.GenerateValuePropsClicked]:
    "User clicked generate value props from the Start My Email homepage to begin generating value props",
  [SegmentEventNames.IcebreakerClicked]: "User clicked on a new icebreaker",
  [SegmentEventNames.ProfileClicked]:
    "User clicked the profile within the Start My Email homepage",
  [SegmentEventNames.StartMyEmailClicked]:
    "User clicked start my email icon from compose window",
  [SegmentEventNames.PersonalizedSelected]:
    "User clicked personalized from score dropdown",
  [SegmentEventNames.BestPracticesSelected]:
    "User clicked best practices from score dropdown",
  [SegmentEventNames.SendAnywayClicked]:
    "User clicked send anyway from the boost score popup",
  [SegmentEventNames.BoostScoreClicked]:
    "User clicked boost score from the boost score popup",
  [SegmentEventNames.ShareToLinkedinClicked]:
    "User clicked share to linkedin from the email coach",
  [SegmentEventNames.StreakClicked]: "User clicked streak icon",
  [SegmentEventNames.StreakIncreased]: "User increased their streak",
};

export const VIEW_STATE_TO_SEGMENT_LOCATION: Record<
  viewState,
  SegmentEventLocations
> = {
  emailCoach: SegmentEventLocations.EmailCoach,
  profile: SegmentEventLocations.PersonalizationAssistant,
  chatGPT: SegmentEventLocations.ChatGPT,
  default: SegmentEventLocations.ExtensionMainPage,
  frameworks: SegmentEventLocations.Frameworks,
  gif: SegmentEventLocations.Gifs,
  intercom: SegmentEventLocations.ExtensionMainPage,
  settings: SegmentEventLocations.SettingsInExtension,
  mobilePreview: SegmentEventLocations.MobileEditor,
  startMyEmail: SegmentEventLocations.StartMyEmail,
  upgrade: SegmentEventLocations.SettingsInExtension,
  streak: SegmentEventLocations.Streak,
};

export const PERSONALIZATION_TAB_SEGMENT_EVENT_MAP: {
  [key in (typeof PERSONALIZATION_SUB_TAB)[keyof typeof PERSONALIZATION_SUB_TAB]]?: SegmentEventNames;
} = {
  [PERSONALIZATION_SUB_TAB.ABOUT]: SegmentEventNames.AboutProfileClicked,
  [PERSONALIZATION_SUB_TAB.NEWS]: SegmentEventNames.NewsEventsClicked,
  [PERSONALIZATION_SUB_TAB.JOBS]: SegmentEventNames.JobOpeningsClicked,
  [PERSONALIZATION_SUB_TAB.TECH_STACK]: SegmentEventNames.TechStackClicked,
  [PERSONALIZATION_SUB_TAB.FUNDING]: SegmentEventNames.FundingClicked,
  [PERSONALIZATION_SUB_TAB.EXPERIENCE]: SegmentEventNames.WorkHistoryClicked,
  [PERSONALIZATION_SUB_TAB.SALESFORCE]: SegmentEventNames.SalesforceClicked,
  [PERSONALIZATION_SUB_TAB.HUBSPOT]: SegmentEventNames.HubspotClicked,
  [PERSONALIZATION_SUB_TAB.ACTIVITY]: SegmentEventNames.RecentActivityClicked,
  [PERSONALIZATION_SUB_TAB.SOCIAL_FEED]:
    SegmentEventNames.ProfileSocialFeedClicked,
  [PERSONALIZATION_SUB_TAB.GONG]: SegmentEventNames.GongDataClicked,
  [PERSONALIZATION_SUB_TAB.PODCASTS]: SegmentEventNames.GongDataClicked,
  [PERSONALIZATION_SUB_TAB.PERSONALITY]: SegmentEventNames.PersonalityClicked,
};
