import { forwardRef } from "react";
import { CollapseProps, Flex } from "antd";

import { Text } from "@lavender-ai/lav-components";

import {
  IconFrame,
  ItemCollapse,
  ItemCollapseHandle,
} from "~src/component/Atoms";
import { LoadableText } from "~src/component/Utilities";
import { LavenderIcon } from "~src/component/typings";

import { SME_COLLAPSE } from "~src/strings";

interface Props extends Pick<CollapseProps, "className"> {
  children: JSX.Element;
  isCollapsible: boolean;
  isInitialCollapsed?: boolean;
  isLoading?: boolean;
}

export const CustomContextItemCollapse = forwardRef<ItemCollapseHandle, Props>(
  (
    { className, children, isCollapsible, isInitialCollapsed, isLoading },
    ref
  ) => {
    const item = {
      key: isCollapsible ? "1" : "2",
      label: (
        <Flex align={isLoading ? "flex-end" : "center"} gap={12}>
          <IconFrame
            color="pear"
            variant={LavenderIcon.IconWand}
            isLoading={isLoading}
          />
          <Text color="dark" size="subtext3">
            <LoadableText
              height="8px"
              loading={isLoading}
              text={SME_COLLAPSE.ADD_CONTEXT}
            />
          </Text>
        </Flex>
      ),
      children,
      forceRender: true,
    };

    return (
      <ItemCollapse
        className={className}
        collapsible={isCollapsible}
        initialCollapsed={isInitialCollapsed}
        item={item}
        size="large"
        ref={ref}
      />
    );
  }
);

CustomContextItemCollapse.displayName = "CustomContextItemCollapse";
