export const SUBJECT_EMAIL_TRANSFORMERS = [
  "improve_subject_length",
  "improve_subject_format",
];
export const TONE_CONVERT = {
  confident: "confident",
  angry: "angry",
  sad: "sadness",
  scared: "fear",
  unsure: "tentative",
  analytical: "analytical",
  happy: "joy",
  friendly: "friendly",
};
