import { useAppSelector } from "~src/redux";
import { makeSelectUserAccountSetting } from "~src/redux/userAccount";
import { User } from "~src/redux/typings";
import { useMemo } from "react";

export const useUserSetting = <K extends keyof User["settings"]>(
  setting: K
): User["settings"][K] => {
  const selectUserAccountSetting = useMemo(makeSelectUserAccountSetting, []);
  return useAppSelector((state) => selectUserAccountSetting(state, setting));
};
