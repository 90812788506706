import { Flex } from "antd";
import DOMPurify from "dompurify";
import { styled } from "styled-components";

import { Text } from "@lavender-ai/lav-components";

import { HubspotNote } from "~src/api/typings";
import { formatDate } from "~src/utils/datetime";

interface NoteProps {
  name?: string;
  date?: string;
  content?: string;
}

const Note = ({ date, content }: NoteProps) => {
  return (
    <NoteContainer vertical={true} gap={12}>
      <Flex gap={6} justify="flex-start">
        <Text color="black" size="body1">
          {/* @ts-expect-error - needs fix */}
          {formatDate(date)}
        </Text>
      </Flex>
      <Flex>
        <Text
          color="black"
          size="body2"
          dangerouslySetInnerHTML={{
            /* @ts-expect-error - needs fix */
            __html: DOMPurify.sanitize(content),
          }}
        />
      </Flex>
    </NoteContainer>
  );
};

export const Notes = ({ notes }: { notes: HubspotNote[] }) => {
  return (
    <NoteList vertical={true}>
      {notes.map(({ timestamp, body }, i) => (
        <Note
          key={`crm-note-${i}`}
          date={timestamp as string}
          content={body as string}
        />
      ))}
    </NoteList>
  );
};

const NoteList = styled(Flex)`
  padding: 0 20px 8px 20px;
  > div {
    border-bottom: 1px solid var(--color-neutral-purple-100, #e9ebf2);
    &:last-child {
      border-bottom: 0;
    }
  }
`;

const NoteContainer = styled(Flex)`
  padding: 16px 0;
  .lav-components-text-size-subtext2 {
    color: var(--color-neutral-purple-900, #0d1421);
  }
  .lav-components-text-size-body1 {
    color: var(--color-neutral-purple-600, #7f848f);
  }
  .lav-components-text-size-body2 {
    text-align: left;
    color: var(--color-neutral-purple-600, #7f848f);
  }
`;
