import { useCallback, useEffect, useMemo } from "react";
import { useLvIdentifier } from "~src/customHooks";
import {
  getBoostScoreSuggestion,
  highlightsAction,
  selectBoostScoreAbortBySessionId,
  selectChromeId,
  selectEmailAnalysis,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";
import { CATEGORY_INFO } from "./constants";
import { Props } from "./Category";
import { EmailCoachView, useEmailCoachCarouselContext } from "~src/contexts";
import { getWritingData } from "~src/layout/MainPanel/EmailCoachPanel/utils";

export const useClearHighlightsOnUnmount = () => {
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(
        highlightsAction.setHighlights({
          lvIdentifier,
          highlights: [],
        })
      );
    };
  }, [dispatch, lvIdentifier]);
};

export const useGetFilteredRecommendations = (type: Props["type"]) => {
  const lvIdentifier = useLvIdentifier();
  const { recommendations } = useAppSelector(selectEmailAnalysis(lvIdentifier));

  return useMemo(() => {
    const { thresholdMax, thresholdMin } = CATEGORY_INFO[type];

    return (recommendations || []).filter(
      ({ effectonscore }) =>
        effectonscore >= thresholdMin && effectonscore < thresholdMax
    );
  }, [recommendations, type]);
};

export const useOnClickBoostScoreCB = () => {
  const lvIdentifier = useLvIdentifier();
  const { next } = useEmailCoachCarouselContext();
  const { subject, body } = getWritingData(lvIdentifier);
  const chromeId = useAppSelector(selectChromeId);
  const { stage } = useAppSelector(selectEmailAnalysis(lvIdentifier));
  const dispatch = useAppDispatch();
  const filtered = useGetFilteredRecommendations("danger");
  const abortBoostScore = useAppSelector((state) =>
    selectBoostScoreAbortBySessionId(state, lvIdentifier)
  );

  return useCallback(() => {
    abortBoostScore();
    next(EmailCoachView.fixItAll);
    void dispatch(
      getBoostScoreSuggestion({
        controller: new AbortController(),
        lvIdentifier,
        chromeId,
        body,
        subject,
        recommendations: filtered,
        stage,
      })
    );
  }, [
    abortBoostScore,
    body,
    chromeId,
    dispatch,
    filtered,
    lvIdentifier,
    next,
    stage,
    subject,
  ]);
};
