import { store } from "~src/redux/store";

//INFO Legacy logic from original extension, EmailCoach.js line 603 function name getAuthorEmailOverwrite
// this is only use for hybridFinancial
export const getAuthorEmailOverwrite = (containerDom: HTMLElement) => {
  const platform = store.getState().config.settings?.platform;
  if (platform !== "hybridFinancial") {
    return "";
  }
  const authorEmailOverwriteSelector =
    store.getState().config.settings?.selectors?.authorEmailOverwriteSelector;
  if (!authorEmailOverwriteSelector) {
    return "";
  } else {
    const authorEmailOverwrite = containerDom.querySelector(
      authorEmailOverwriteSelector
    );
    if (authorEmailOverwrite) {
      return authorEmailOverwrite.textContent?.trim() ?? "";
    }
  }

  return "";
};
