import { AISuggestion, Recommendation } from "~src/redux/typings";

import { URLS, networkRequest } from ".";

export const getAISuggestion = async (
  chromeId: chromeId,
  body: string,
  recommendation: Recommendation,
  ignoreCache: boolean,
  promptName?: string
) => {
  return await networkRequest.post<
    AISuggestion & {
      message: Error;
    }
  >(URLS.aiSuggestion, {
    chrome_id: chromeId,
    input: recommendation?.text || body,
    prompt_name: promptName || "make_concise",
    ignore_cache: ignoreCache,
  });
};
