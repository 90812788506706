import { CallOutlineItem, Collaboration } from "~src/api/typings";

export interface GongCall {
  id: string;
  title: string;
  url: string;
  callTime: string;
  callTimeFormatted: string;
  participants: string[];
  participantsText: string;
  duration: number;
  durationFormatted: string;
  nextSteps: string[];
  collaboration: Collaboration;
  callBrief: string;
  outline: CallOutlineItem[] | undefined;
}
