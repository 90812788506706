import { store } from "~src/redux/store";
import { configActions } from "../redux";
import { detectEmailAddress } from "../utils";

export const isExtensionBlocked = (): boolean => {
  const state = store.getState();
  const platform = state.config.settings?.platform || "";
  let userEmailAddress = state.config.emailAddress;
  if (userEmailAddress === "") {
    userEmailAddress = detectEmailAddress(platform);
    if (userEmailAddress !== "") {
      store.dispatch(configActions.setEmailAddress(userEmailAddress));
    }
  }
  const ignoreList = state.localStorage.ignoreList;
  return ignoreList.includes(userEmailAddress) || ignoreList.includes(platform);
};
