export const BADGE = {
  OPTIONAL: "Optional",
};

export const CAPTION = {
  AUTOFILL_VALUE_PROPS: "Auto-fill Value Props",
  CUSTOM_TOPICS:
    "Custom topics, context or instructions, will help focus the emails that will be generated.",
  SELECTED: (n: number) => `${n} selected`,
  LEARN_MORE: (name: string, company: string) => {
    let learnMore = `Learn more about ${name}`;
    if (company) {
      learnMore += ` & ${company}`;
    }
    return learnMore;
  },
  YOU_MAY_LEAVE:
    "You may leave this window & we’ll summon you when our spell is complete.",
  WE_COULDNT_FIND:
    "We couldn't find any relevant icebreakers for the recipient.",
};

export const COLLAPSE = {
  CONTEXT_FROM_YOUR_PREVIOUS_CALL:
    "Context from a previous call to help focus your generated emails.",
  FROM_GONG: "From Gong",
};

export const CTA = {
  GENERATE_EMAILS: "Generate emails",
  MORE_SUGGESTIONS: "More suggestions",
  SEE_SOURCE: "See source",
  SUMMARY: "Description",
  UPDATE: "Update",
};

export const DROPDOWN = {
  GENERATE: "Generate",
  INTRO: "Intro",
  Email: "Email",
  GENERATE_3_INITIAL: "Generates 3 initial greetings of the email.",
  GENERATE_3_FULL: "Generates 3 full email options.",
};

export const ERROR = {
  SORRY: `Sorry!<br>Lavender's wizard potion needs stirring. Please try again.`,
};

export const HEADER = {
  FOUND_TO_KICKOFF: "found to kick-off your conversation!",
  RELEVANT_ICEBREAKERS: (n: number) =>
    `${n} relevant ${n === 1 ? "icebreaker" : "icebreakers"}`,
  USE_THE_PERSONALIZATION: "Use the Personalization Assistant to research",
  PERSONALIZATION_HAS_NEVER_BEEN:
    "Personalization has never been easier — it's like magic!",
  UNLOCKING: "Unlocking our Wizardly Secrets",
};

export const POPOVER = {
  DISABLED: "Disabled on Multiple Recipients",
  START_MY_EMAIL_WORKS_BEST:
    "Start my email works best when emailing 1 recipient at a time.",
};

export const SUBHEADER = {
  CRAFTING: "Crafting a spell to magically generate value props...",
  ICEBREAKERS: "Icebreakers",
  INSTRUCTIONS: "Instructions",
  PERSONALIZATION_ASSISTANT: "Personalization Assistant",
  HOW_TO_USE: "How to use",
};

export const SUB_TAB = {
  FEED: "Feed / Recent Updates",
};

export const LOADING_STEPS = {
  IDENTIFYING_COMPANY_GOALS: "Identifying Company Goals",
  VALIDATING: "Validating Prospect Objectives",
  IDENTIFYING_ROADBLOCKS: "Identifying Roadblocks",
  CONNECTING: "Connecting Value Props",
};

export const TOOLTIP = {
  ADD_INSTRUCTIONS: "Add instructions to begin generating",
  SELECT_ICEBREAKERS: "Add icebreakers or instructions",
  THIS_MAY_TAKE_A_FEW_SECONDS: "This may take a few seconds to complete.",
  UPDATE_INTERESTS: "Update interests",
};
