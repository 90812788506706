import { store } from "~src/redux/store";

//INFO: legacy logic from original extension, EmailCoach.js line 460 function name isEmailReply FectchComposeAnalysisNew
// This only applies to salesloft and outreach
export const getSippetFlag = () => {
  const snippetSelector =
    store.getState().config.settings?.selectors?.snippetSelector;

  if (snippetSelector) {
    const snippet = document.querySelector(snippetSelector);
    if (snippet && window.location.pathname.includes("snippet")) {
      return true;
    }
  }
  return false;
};
