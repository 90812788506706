import { Avatar, Icon, Text } from "@lavender-ai/lav-components";
import { styled } from "styled-components";

import { useAppSelector } from "~src/redux";
import { selectStreakThreshold } from "~src/redux/config";

import { useStreakInfo } from "./useStreakInfo";

const useComputeStreaks = () => {
  const {
    workweekMap: { Monday, Tuesday, Wednesday, Thursday, Friday },
  } = useStreakInfo();

  const days = [
    { name: "M", filled: Monday, filledStreak: 0 },
    { name: "T", filled: Tuesday, filledStreak: 0 },
    { name: "W", filled: Wednesday, filledStreak: 0 },
    { name: "T", filled: Thursday, filledStreak: 0 },
    { name: "F", filled: Friday, filledStreak: 0 },
  ];

  const result: typeof days = [];
  let i = 0;
  const n = days.length;

  while (i < n) {
    const day = days[i];
    if (!day.filled) {
      // If the day is not filled, its filledStreak is 0
      result.push({ ...day, filledStreak: 0 });
      i++;
      continue;
    }

    // Start of a new streak
    let streakLength = 0;
    let j = i;

    // Count consecutive filled days starting from the current day
    while (j < n && days[j].filled) {
      streakLength++;
      j++;
    }

    // Assign filledStreak to the starting day of the streak
    result.push({ ...days[i], filledStreak: streakLength });

    // For the rest of the days in the streak, set filledStreak to 0
    for (let k = i + 1; k < j; k++) {
      result.push({ ...days[k], filledStreak: 0 });
    }

    // Move to the next day after the streak
    i = j;
  }

  return result;
};

export const DailyStreakCard = () => {
  const streakThreshold = useAppSelector(selectStreakThreshold);
  const days = useComputeStreaks();

  return (
    <Wrapper>
      <Container>
        <Header>
          <Text size="subtext1" color="white">
            Daily Streak
          </Text>
        </Header>
        <StreakContainer>
          <StreakBarContainer>
            <WeekdayContainer>
              {days.map(({ name }, index) => (
                <Text
                  key={name + index}
                  size="body1"
                  color="white"
                  style={{ width: 11 }}
                >
                  {name}
                </Text>
              ))}
            </WeekdayContainer>
            <StreakBar>
              {days.map(({ filled, filledStreak }, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <Day filled={filled} />
                  {!!filledStreak && <ConnectorBar $length={filledStreak} />}
                </div>
              ))}
            </StreakBar>
          </StreakBarContainer>
          <StreakDescription>
            <Text size="body1" color="white">
              A <span style={{ color: "#FDD74C" }}>streak</span> counts when you
              send {streakThreshold} A email{streakThreshold === 1 ? " " : "s "}
              daily using Lavender.
            </Text>
          </StreakDescription>
        </StreakContainer>
      </Container>
    </Wrapper>
  );
};

const Day = ({ filled }: { filled: boolean }) =>
  filled ? <FilledDay /> : <EmptyDay />;

const EmptyDay = () => (
  <Avatar
    size="medium"
    type="icon"
    style={{ background: "none", outline: "2px solid white" }}
  />
);

const FilledDay = () => (
  <Avatar
    size="medium"
    type="icon"
    icon={<Icon variant="IconCheck" size="small" color="white" />}
    style={{ background: "none", zIndex: 2 }}
  />
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const Container = styled.div`
  display: flex;
  padding: var(--size-6, 6px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-6, 6px);
  align-self: stretch;
  border-radius: 24px;
  background: linear-gradient(
    180deg,
    rgba(131, 155, 237, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  backdrop-filter: blur(2px);
`;

const Header = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const StreakContainer = styled.div`
  display: flex;
  padding: var(--size-24, 24px) 16px 16px 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 19px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
`;

const StreakBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

const WeekdayContainer = styled.div`
  display: flex;
  padding: 0px 14px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const StreakBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const StreakDescription = styled.div`
  display: flex;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const ConnectorBar = styled.div<{ $length: number }>`
  top: 0;
  position: absolute;
  border-radius: 20px;
  height: 40px;
  z-index: 1;
  background: ${({ $length }) =>
    $length === 5 ? "rgba(0, 0, 0, 0.2)" : "rgba(255, 255, 255, 0.2)"};
  border: ${({ $length }) =>
    $length === 5 ? "none" : "1px solid rgba(255, 255, 255, 0.2)"};
  width: ${({ $length }) =>
    // If only one day long, just fill wide enough for that day's Avatar
    // Otherwise, fill 40px for each day and 27px for each gap between days
    $length === 1 ? 40 : $length * 40 + ($length - 1) * 27}px;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.05) inset;
`;
