import { Button, Dropdown, Icon, Text } from "@lavender-ai/lav-components";
import { LavenderIcon } from "~src/component/typings";
import { Flex } from "antd";
import { useShowPanel } from "./utils";

export const OutlookMenuDropdown = () => {
  const showPanel = useShowPanel();

  return (
    <section>
      <Dropdown
        menuProps={{
          items: [
            {
              children: (
                <Flex gap={4} align={"center"}>
                  <Icon color="black" size="extra-small" variant="IconBox" />
                  <Text>Frameworks</Text>
                </Flex>
              ),
              // icon: "IconBox",
              key: "1",
              onClick: (e) => {
                showPanel(undefined, "frameworks");
              },
            },
            {
              children: (
                <Flex gap={4} align={"center"}>
                  <Icon color="black" size="extra-small" variant="IconGif" />
                  <Text>GIFs</Text>
                </Flex>
              ),
              // icon: LavenderIcon.IconGif,
              key: "2",
              onClick: (e) => {
                showPanel(undefined, "gif");
              },
            },
            {
              children: (
                <Flex gap={4} align={"center"}>
                  <Icon
                    color="black"
                    size="extra-small"
                    variant="IconSettings"
                  />
                  <Text>Settings</Text>
                </Flex>
              ),
              // icon: LavenderIcon.IconSettings,
              key: "3",
              onClick: (e) => {
                showPanel(undefined, "settings");
              },
            },
          ],
        }}
        arrow={false}
        size="large"
        variant="plain"
        placement={"bottomLeft"}
      >
        <Button
          aria-label="Dots"
          radius="md"
          size="large"
          state={"initial"}
          icon={LavenderIcon.IconDots}
          variant="plain"
        />
      </Dropdown>
    </section>
  );
};
