import { networkRequest, URLS } from "~src/api";

import type { SetNoteResponse, SingleNote } from "./typings";

export const setNotes = async (
  email: string,
  chromeId: chromeId,
  notes: SingleNote[]
): Promise<SetNoteResponse> => {
  const { data } = await networkRequest.post<SetNoteResponse>(URLS.setNotes, {
    email,
    chrome_id: chromeId,
    notes: JSON.stringify(notes),
  });

  return data;
};
