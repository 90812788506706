import { BoostScoreSuggestion } from "~src/redux/typings";

export const BOOST_SCORE_BUTTON_CLASSNAME = "lv-fix-all-button";

export const DEFAULT_BOOST_SCORE_SUGGESTION: BoostScoreSuggestion = {
  email: "",
  subject: "",
};
// the score at which a user can no longer use the fix it all features
export const BOOST_SCORE_CUTOFF_SCORE = 90;
