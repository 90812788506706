import { useMemo, useCallback, useEffect, useState } from "react";
import { Flex, Popover } from "antd";
import { styled } from "styled-components";

import {
  Button,
  ButtonProps,
  Icon,
  Text,
  Tooltip,
} from "@lavender-ai/lav-components";

import { ConditionalWrapper } from "../Utilities";
import {
  useLvIdentifier,
  usePersonalization,
  useNews,
  useCRM,
  useTimeout,
  useTweets,
  useTechStacks,
  useRankedFeedApi,
  useRankedFeedApiDependencies,
} from "~src/customHooks";

import { useAppSelector } from "../../redux/hooks";
import { SME_POPOVER } from "../../strings";
import { SecondaryText } from "../Styled";
import { LavenderIcon } from "../typings";

import { selectUserAccountEmail, store } from "~src/redux";
import {
  newPendingPersonalization,
  newPendingTechStack,
  newPendingTweets,
  newPendingNews,
  newPendingCRM,
} from "~src/utils";
import { viewState } from "~src/redux/typings";

const TextWrapper = styled(Flex)`
  text-align: left;
  gap: 12px;
  padding-left: 8px;
`;

const StyledFlex = styled(Flex)`
  svg {
    flex-shrink: 0;
  }
`;

interface PopoverContentProps {
  alert: string;
  recipientCount: number;
  firstRecipientEmail: string;
  currentUserEmail: emailAddress;
}

const PopoverContent = ({
  alert,
  recipientCount,
  firstRecipientEmail,
  currentUserEmail,
}: PopoverContentProps) => {
  if (recipientCount > 1) {
    return (
      <div style={{ width: "200px" }}>
        <SecondaryText $light size="body1">
          {alert}
        </SecondaryText>
      </div>
    );
  }
  return (
    <div
      style={{
        width: currentUserEmail === firstRecipientEmail ? "214px" : "254px",
      }}
    >
      <StyledFlex>
        <Icon variant={LavenderIcon.IconAlertTriangleFilled} color="warning" />
        <TextWrapper vertical>
          <Text color="black" size="body2">
            {alert}
          </Text>
        </TextWrapper>
      </StyledFlex>
    </div>
  );
};

interface Props {
  showPanel: (
    e: React.MouseEvent<HTMLElement> | undefined,
    newView: viewState
  ) => void;
}

export const StartMyEmailMenuButton = ({ showPanel }: Props) => {
  const lvIdentifier = useLvIdentifier();
  const isOpen = useAppSelector(
    ({ mainPanel }) => mainPanel[lvIdentifier].isOpen
  );
  const view = useAppSelector(({ mainPanel }) => mainPanel[lvIdentifier].view);
  const {
    currentPersonalization: {
      profile: { email: currentEmail },
      cart: {
        events: { items: eventItems },
      },
    },
    recipients,
    recipientVerifications,
    personalizations,
    setPersonalizationContext,
  } = usePersonalization();
  const { crms, setCRMContext } = useCRM();
  const { news, setNewsContext } = useNews();
  const { tweets, setTweetsContext } = useTweets();
  const { techStacks, setTechStackContext } = useTechStacks();
  const recipientsArray = useMemo(
    () => Object.entries(recipientVerifications),
    [recipientVerifications]
  );

  const currentUserEmail = useAppSelector((state) =>
    selectUserAccountEmail(state)
  );
  const recipient = recipients[0];
  const { areDependenciesLoading } = useRankedFeedApiDependencies(recipient);

  const {
    data: { alert },
  } = useRankedFeedApi(recipient, areDependenciesLoading);
  const currentPersonalizationFeedAlertMessage = alert;
  const recipientCount = recipients.length;
  const firstRecipientEmail = recipients[0];

  const getButtonState = useMemo(() => {
    if (recipientCount > 1) {
      return "disabled";
    }
    return view === "startMyEmail" && isOpen === "open" ? "focus" : "initial";
  }, [recipientCount, view]);
  const getFeedAlertMessage = useMemo(() => {
    if (recipientCount > 1) {
      return SME_POPOVER.START_MY_EMAIL_WORKS_BEST;
    }
    if (recipientCount !== 1) {
      return "";
    }
    return currentPersonalizationFeedAlertMessage;
  }, [recipientCount, currentPersonalizationFeedAlertMessage]);
  const [buttonState, setButtonState] =
    useState<ButtonProps["state"]>(getButtonState);
  const [feedAlertMessage, setFeedAlertMessage] =
    useState<string>(getFeedAlertMessage);
  const panelNeedsClose =
    view === "startMyEmail" &&
    isOpen === "open" &&
    (buttonState === "disabled" || !!currentPersonalizationFeedAlertMessage);

  const showStartMyEmailPanel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, email?: string) => {
      const smeCount = eventItems.filter(
        (item) => item.showCart === true
      ).length;

      if (email && smeCount <= 0) {
        const personalization = personalizations[email];
        setPersonalizationContext(
          personalization?.profile?.email
            ? personalization
            : newPendingPersonalization(email)
        );

        const newsItem = news[email || currentEmail];
        setNewsContext(newsItem || newPendingNews());

        const crm = crms[email || currentEmail];
        setCRMContext(crm || newPendingCRM());

        const tweetsItem = tweets[email || currentEmail];
        setTweetsContext(tweetsItem || newPendingTweets());

        const techStackItem = techStacks[email || currentEmail];
        setTechStackContext(techStackItem || newPendingTechStack());
      }
      showPanel(e, "startMyEmail");
    },
    [
      crms,
      currentEmail,
      eventItems,
      news,
      personalizations,
      setCRMContext,
      setNewsContext,
      setPersonalizationContext,
      setTechStackContext,
      setTweetsContext,
      showPanel,
      techStacks,
      tweets,
    ]
  );

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  useEffect(() => {
    const msg =
      recipientCount > 1
        ? SME_POPOVER.START_MY_EMAIL_WORKS_BEST
        : currentPersonalizationFeedAlertMessage;
    setFeedAlertMessage(msg);
    if (msg !== "") {
      setButtonState("disabled");
    } else {
      setButtonState(
        view === "startMyEmail" && isOpen === "open" ? "focus" : "initial"
      );
    }
  }, [currentPersonalizationFeedAlertMessage, recipientCount, view, isOpen]);

  useEffect(() => {
    if (panelNeedsClose && isOpen === "open" && view === "startMyEmail") {
      showPanel(undefined, "startMyEmail");
      setTooltipOpen(true);
    }
  }, [isOpen, panelNeedsClose, showPanel, view]);

  useTimeout(
    () => {
      setTooltipOpen(false);
    },
    tooltipOpen ? 3000 : null
  );
  const state = store.getState();
  const platform = state.config.settings?.platform;
  return (
    <ConditionalWrapper
      condition
      wrapper={(children) =>
        buttonState === "disabled" ? (
          <Popover
            arrow={false}
            open={tooltipOpen || undefined}
            content={
              <PopoverContent
                alert={feedAlertMessage}
                firstRecipientEmail={firstRecipientEmail}
                recipientCount={recipientCount}
                currentUserEmail={currentUserEmail}
              />
            }
            placement={platform === "outlookNative" ? "bottom" : "right"}
            title={
              recipients.length > 1 ? (
                <div style={{ marginBottom: "12px" }}>
                  <Text color="dark" size="subtext2">
                    {SME_POPOVER.DISABLED}
                  </Text>
                </div>
              ) : undefined
            }
          >
            {children}
          </Popover>
        ) : (
          <Tooltip
            hideArrow
            placement={platform === "outlookNative" ? "bottom" : "right"}
            title="Start My Email"
          >
            {children}
          </Tooltip>
        )
      }
    >
      <Button
        aria-label="Start My Email"
        onClick={(e) => {
          const email = recipientsArray[0]?.[0];
          showStartMyEmailPanel(e, email);
        }}
        radius="md"
        size="large"
        state={buttonState}
        icon={
          isOpen === "open" &&
          view === "startMyEmail" &&
          platform !== "outlookNative"
            ? LavenderIcon.IconChevronsRight
            : LavenderIcon.IconMailBolt
        }
        variant="plain"
      />
    </ConditionalWrapper>
  );
};
