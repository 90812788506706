import { Flex } from "antd";
import React, { useEffect, useRef } from "react";
import { Text, Button } from "@lavender-ai/lav-components";
import { useDraggable } from "~src/customHooks";
import { store } from "~src/redux/store";
import { Provider } from "react-redux";
import { styled } from "styled-components";

export const EditorMode = ({
  emailData,
  analysisData,
  highlightData,
  chromeId,
}) => {
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const draggableRef = useRef<HTMLDivElement>(null);
  const { initDraggable, removeDraggable } = useDraggable(draggableRef);

  useEffect(() => {
    if (draggableRef.current) {
      initDraggable();
    }

    return () => {
      removeDraggable();
    };
  }, [initDraggable, removeDraggable]);

  return (
    <Provider store={store}>
      <EditorModeContainer
        align={"center"}
        justify={"center"}
        ref={draggableRef}
      >
        <Flex
          vertical={true}
          justify={"center"}
          style={{ borderRadius: "8px", overflow: "hidden" }}
        >
          <Flex
            justify={"space-between"}
            align={"center"}
            style={{
              background: "var(--color-primary-lavender, #6A2BE9)",
              cursor: "drag",
            }}
          >
            <Text style={{ marginLeft: 8 }} color={"white"}>
              Editor Mode
            </Text>
            <Button
              icon={"IconX"}
              size={"small"}
              style={{ margin: 4 }}
              onClick={() => {
                document.getElementById("editor-mode-root")?.remove();
              }}
            />
          </Flex>
          <iframe
            style={{ border: 0 }}
            src={
              process.env.BACKEND_API + "/website-demo"
            }
            ref={iframeRef}
            width="850"
            height="650"
            name="lavender-anywhere"
            className="lavender-anywhere-iframe"
            onLoad={() => {
              const message = {
                subject: emailData?.subject || "",
                body: emailData?.body || "",
                to: emailData?.toAddress || [],
                time: new Date(),
                analysisData,
                lavender_anywhere_event: true,
                highlightData,
                writingStartTime: emailData?.writingStartTime || 0,
                chromeId: chromeId,
              };
              iframeRef.current?.contentWindow?.postMessage(
                message,
                process.env.BACKEND_API || ""
              );
            }}
          />
        </Flex>
      </EditorModeContainer>
    </Provider>
  );
};
const EditorModeContainer = styled(Flex)`
  position: absolute;
  left: calc(50vw - 425px);
  top: calc(50vh - 341px);
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
  border-radius: 8px;
`;
