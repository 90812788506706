export interface SalesforceData {
  lead: SalesforceLead;
  contact: SalesforceContact;
  company: SalesforceCompany;
  opportunity: SalesforceOpportunity | undefined;
}

export interface SalesforceLead {
  name?: string;
  email?: string;
  phone?: string;
  stage?: string;
}

export interface SalesforceContact {
  name?: string;
  email?: string;
  phone?: string;
}

export interface SalesforceCompany {
  name?: string;
  domain?: string;
  phone?: string;
  industry?: string;
}

export interface SalesforceOpportunity {
  attributes: {
    type: string;
    url: string;
  };
  AccountId: string;
  Amount: number;
  CampaignId: string;
  CloseDate: string;
  ContactId: string;
  Description: string;
  Fiscal: string;
  FiscalQuarter: number;
  FiscalYear: number;
  ForecastCategory: string;
  ForecastCategoryName: string;
  HasOpenActivity: boolean;
  HasOpportunityLineItem: boolean;
  HasOverdueTask: boolean;
  Id: string;
  IsClosed: boolean;
  IsDeleted: boolean;
  IsWon: boolean;
  LastActivityDate: string;
  LastReferencedDate: string;
  LastStageChangeDate: string;
  LastViewedDate: string;
  LeadSource: string;
  Name: string;
  NextStep: string;
  OwnerId: string;
  StageName: string;
}

export interface SalesforceResponse {
  contact_records?: {
    FirstName?: string;
    LastName?: string;
    Email?: string;
    Phone?: string;
  };
  lead_record?: {
    FirstName?: string;
    LastName?: string;
    Email?: string;
    Phone?: string;
    Status?: string;
  };
  account_record?: {
    Name?: string;
    Website?: string;
    Phone?: string;
    Industry?: string;
  };
  opportunity_record?: SalesforceOpportunity;
}
