import { useCallback } from "react";

import { useLvIdentifier } from "~src/customHooks";
import { useAppSelector } from "~src/redux";
import {
  segmentEvent,
  SegmentEventLocations,
  SegmentEventNames,
} from "~src/utils";

import {
  formatDurationWithZeroPadding,
  getScoreHeaderMessage,
} from "../../utils";
import { getMessageForScore, getTemplateId } from "./utils";
import {
  BANNERBEAR_API_KEY,
  BANNERBEAR_API_URL,
  LINKEDIN_SHARE_URL,
} from "./constants";

interface BannerBearResponse {
  image_url: string;
}

export const useShareToLinkedInCB = () => {
  const lvIdentifier = useLvIdentifier();
  const {
    analysis: {
      dynamic_ceq: score,
      reading_time: readingTime,
      writing_time: writingTime,
    },
    stage,
  } = useAppSelector(({ emailAnalysis }) => emailAnalysis[lvIdentifier]);

  return useCallback(async () => {
    let successfulRequest = false;

    const payload = {
      template: getTemplateId(score),
      modifications: [
        { name: "Score-number", text: score.toString() },
        {
          name: "Reading time",
          text: formatDurationWithZeroPadding({
            hours: Math.floor(readingTime / 3600),
            minutes: Math.floor((readingTime % 3600) / 60),
            seconds: readingTime % 60,
          }),
        },
        {
          name: "Writing Time",
          text: formatDurationWithZeroPadding({
            hours: Math.floor(writingTime / 3600),
            minutes: Math.floor((writingTime % 3600) / 60),
            seconds: writingTime % 60,
          }),
        },
        { name: "Email Type", text: stage[0].toUpperCase() + stage.slice(1) },
        { name: "Score description", text: getScoreHeaderMessage(score) },
        { name: "Title text", text: getMessageForScore(score) },
      ],
    };

    try {
      const response = await fetch(BANNERBEAR_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BANNERBEAR_API_KEY}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const { image_url } = (await response.json()) as BannerBearResponse;
        window.open(
          `${LINKEDIN_SHARE_URL}?url=${encodeURIComponent(image_url)}`,
          "_blank"
        );
        successfulRequest = true;
      }
    } catch (e) {}

    segmentEvent(
      SegmentEventNames.ShareToLinkedinClicked,
      SegmentEventLocations.EmailCoach,
      {
        score,
        successfulRequest,
      }
    );
  }, [score, readingTime, writingTime, stage]);
};
