export enum LavenderTagColorMap {
  sapphire,
  lavender,
  pear,
  marigold,
  peony,
  tart,
  white,
}

export enum LavenderTextColorMap {
  sapphire,
  lavender,
  pear,
  marigold,
  peony,
  surf,
  tart,
  warning,
  positive,
}

export enum UnassignedTriggerColorMap {
  marigold,
  surf,
  tart,
  warning,
  positive,
}
