import { styled } from "styled-components";

import { TonesDetected } from "./TonesDetected";
import { Button } from "@lavender-ai/lav-components";
import { useScoreColorGroup } from "../utils";
import { useOnClickBoostScoreCB } from "../../Category/utils";
import { selectEmailAnalysis, useAppSelector } from "~src/redux";
import { useLvIdentifier } from "~src/customHooks";

export const TonesAndBoostButton = () => {
  const lvIdentifier = useLvIdentifier();
  const { isSuccess } = useScoreColorGroup();
  const boostScore = useOnClickBoostScoreCB();
  const {
    analysis: { top_tones: tones },
  } = useAppSelector(selectEmailAnalysis(lvIdentifier));

  return (
    <TonesAndBoostBox $dark={isSuccess} $hidden={!tones?.length && isSuccess}>
      <TonesDetected />
      {!isSuccess && (
        <Button
          size="medium"
          variant={isSuccess ? undefined : "primary"}
          prefix="IconRocket"
          prefixColor={isSuccess ? "dark" : "white"}
          fullWidth={true}
          onClick={boostScore}
          style={{ marginTop: "auto", marginBottom: 8 }}
        >
          Boost Score
        </Button>
      )}
    </TonesAndBoostBox>
  );
};

const TonesAndBoostBox = styled.div<{ $dark: boolean; $hidden: boolean }>`
  ${({ $hidden }) => `display: ${$hidden ? "none" : "flex"};`}
  height: 100%;
  ${({ $dark }) =>
    $dark
      ? `background: var(--opacity-white-opacity-02, rgba(255, 255, 255, 0.08));`
      : "border-top: 1px solid var(--color-neutral-purple-50, #F8F7F8)"};
  padding: 8px 8px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  margin-top: 8px;
`;
