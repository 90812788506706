import { useState, useEffect, useCallback } from "react";

// Hook providing search functionality for a list of items by a list of fields.
// If the item is an array, it will search the fields of the second item in the array (for compatbility
// with aggregated data). If the item is an object, it will do the previous array search, finding the array at the path.

export const useSearch = <T>(
  items: T[],
  searchFields: string[],
  path?: string
) => {
  const isDisabled = items.length < 2;
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState<T[]>(items);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  const handleSearch = useCallback(
    (text: string) => {
      setSearchTerm(text);
      if (!text) {
        setFilteredItems(items);
        return;
      }

      const itemFilterReducer = (filteredItemsArray: T[], item: T): T[] => {
        // Check if 'path' is defined and 'item[path]' is an array
        if (path && Array.isArray(item[path])) {
          // Filter the array at 'item[path]' to include only those objects where any field matches the search term
          const filteredArray = (item[path] as object[]).filter((attr) =>
            searchFields.some((field) =>
              `${attr[field]}`.toLowerCase().includes(text.toLowerCase())
            )
          );

          // If the filtered array has any items, include this modified item in the result
          // Otherwise, return the current filtered items array
          return filteredArray.length
            ? [...filteredItemsArray, { ...item, [path]: filteredArray }]
            : filteredItemsArray;

          // Check if 'item' is an array (compatibility for aggregated data)
        } else if (Array.isArray(item)) {
          // Check if any field in the array matches the search term
          const matchesSearch = searchFields.some((field) =>
            (item[1] as object[]).some((attr) =>
              `${attr[field]}`.toLowerCase().includes(text.toLowerCase())
            )
          );

          // If any field matches, include the item in the result
          // Otherwise, return the current filtered items array
          return matchesSearch
            ? [...filteredItemsArray, item]
            : filteredItemsArray;

          // Default case for when 'item' is an object
        } else {
          // Check if any field in the object matches the search term
          const matchesSearch = searchFields.some((field) =>
            `${item[field]}`.toLowerCase().includes(text.toLowerCase())
          );

          // If any field matches, include the item in the result
          // Otherwise, return the current filtered items array
          return matchesSearch
            ? [...filteredItemsArray, item]
            : filteredItemsArray;
        }
      };

      setFilteredItems(items.reduce(itemFilterReducer, []));
    },
    // Intentionally omitting 'filteredItems' from the dependencies to prevent infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, path, searchFields]
  );

  return {
    isDisabled,
    searchTerm,
    filteredItems,
    handleSearch,
  };
};
