import { Flex } from "antd";
import { capitalCase } from "change-case";
import { styled } from "styled-components";

import { Icon, Tag, Text, TextProps } from "@lavender-ai/lav-components";

import { ImageFallbackAvatar } from "~src/component/Molecules";
import { SecondaryText } from "~src/component/Styled";
import { PanelListItem } from "~src/layout/MainPanel/PanelBlock";
import { PersonalizationEvent } from "~src/redux/typings";
import { prettyDate, calcTimeUntil } from "~src/utils";

interface Props {
  avatar: Avatar;
  topLine?: TopLine;
  primaryLine: PrimaryLine;
  secondaryLine?: SecondaryLine;
  tertiaryLine?: TertiaryLine;
  location?: Location;
  data: EventMetadata;
  clickAction?: () => void;
}

interface Avatar {
  src?: string;
  fallbackLogo?: string;
  iconNode?: React.ReactNode;
}

interface TopLine {
  enable?: boolean;
  size?: TextProps["size"];
  text?: string;
}

interface PrimaryLine {
  enable?: boolean;
  size?: TextProps["size"];
  title: string;
  timestampTop?: boolean;
}

interface SecondaryLine {
  enable?: boolean;
  size?: TextProps["size"];
  text: string;
}

interface TertiaryLine {
  enable?: boolean;
  size?: TextProps["size"];
  text: string;
}

interface EventMetadata {
  id: string;
  dense?: boolean;
  hover?: boolean;
  isCartView?: boolean;
  showTags?: boolean;
  tags?: string[];
  showSource?: boolean;
  source?: string;
  showTimestamp?: boolean;
  timestampLocation?: "top" | "primaryLine" | "bottom";
  timestamp?: string;
  showLocation?: boolean;
  location?: string;
  showCartAction?: boolean;
  isInCart?: boolean;
  removeItemFromCartContext?: (event: PersonalizationEvent) => void;
  addItemToCartContext?: (event: PersonalizationEvent) => void;
}

export const EventPanel = ({
  avatar,
  topLine,
  primaryLine,
  secondaryLine,
  tertiaryLine,
  data,
  clickAction,
}: Props & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <EventWrapper
      key={data?.id}
      hover={data?.hover}
      dense={data?.dense}
      isCartView={data?.isCartView}
      onClick={clickAction}
    >
      <Flex>
        <Flex align="start" style={{ minHeight: "32px" }}>
          {avatar?.iconNode !== undefined ? (
            <>{avatar?.iconNode}</>
          ) : (
            <ImageFallbackAvatar
              src={avatar.src || ""}
              alt="event-avatar"
              size="small"
              fallback={avatar.fallbackLogo || ""}
              fallbackLetter={primaryLine.title[0]}
            />
          )}
        </Flex>

        <ContentField gap="middle" justify="center" vertical>
          {topLine?.enable && (
            <SecondaryText $light size={topLine?.size || "body1"}>
              {topLine?.text}
              {data?.showTimestamp && data.timestampLocation === "top" && (
                <>{prettyDate(data.timestamp ?? "")}</>
              )}
            </SecondaryText>
          )}
          {primaryLine?.enable && (
            <Text color="dark" size={primaryLine?.size || "subtext2"}>
              {primaryLine.title}
              {data?.showTimestamp &&
                data.timestampLocation === "primaryLine" && (
                  <SecondaryText $light size="body1">
                    • {calcTimeUntil(data.timestamp ?? "")}
                  </SecondaryText>
                )}
            </Text>
          )}
          {secondaryLine?.enable && (
            <SecondaryLineBlock>
              <Text size={secondaryLine?.size || "body1"}>
                {secondaryLine?.text}
              </Text>
            </SecondaryLineBlock>
          )}
          {tertiaryLine?.enable &&
            (tertiaryLine?.text || data.timestamp || data.source) && (
              <Flex gap={4}>
                <SecondaryText size={tertiaryLine?.size || "body1"}>
                  {tertiaryLine?.text}
                  {data.showTimestamp &&
                    data.timestampLocation === "bottom" &&
                    data.timestamp && (
                      <>
                        {tertiaryLine?.text && <> • </>}
                        {prettyDate(data.timestamp)}
                      </>
                    )}
                  {data.showSource && data.source && (
                    <>
                      {(tertiaryLine?.text ||
                        (data.showTimestamp &&
                          data.timestampLocation === "bottom")) && <> • </>}
                      {data.source}
                    </>
                  )}
                </SecondaryText>
              </Flex>
            )}
          <Flex gap="small">
            {data?.showTags && (
              <>
                {data.tags?.map((tag: string, idx: number) => (
                  <Tag key={idx}>{capitalCase(tag)}</Tag>
                ))}
              </>
            )}
            {data?.showLocation && (
              <SecondaryText>
                <Icon variant={"IconMapPin"} size={"mini"} />
                {data.location}
              </SecondaryText>
            )}
          </Flex>
        </ContentField>
      </Flex>
    </EventWrapper>
  );
};

interface StylingProps {
  hover?: boolean;
  dense?: boolean;
  isCartView?: boolean;
}

const EventWrapper = styled(PanelListItem)<StylingProps>`
  ${({ dense }) => (dense ? "padding: 16px 0" : "padding: 20px 0")};
  transition: background-color 0.2s;

  ${({ hover }) =>
    hover &&
    `
    &:hover {
      border-radius: var(--radius-md, 8px);
      background: var(--lv-panel-background-color);
      cursor: pointer;
    }
  `}

  .lv-panel-list-item-inner-container {
    display: flex;
    gap: 12px;
    ${({ isCartView }) => isCartView && "align-items: center"};
    ${({ dense, isCartView }) =>
      dense || isCartView ? "padding: 16px 0" : "padding: 20px 0"};
    border-radius: var(--radius-md, 8px);
  }
`;

const ContentField = styled(Flex)`
  padding: 6px;
  margin: auto 0;
`;

const SecondaryLineBlock = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 1rem;
`;
