import styled from "styled-components";
import { LavenderLogo } from "~src/component/Atoms";
import { useStreakNotificationContext } from "~src/contexts";
import FireSvg from "~src/assets/images/StreakPanel/Fire.svg";
import { useStreakInfo } from "~src/layout/MainPanel/StreakPanel/useStreakInfo";

export const LiveLogo = () => {
  const { hasNewStreakLevel } = useStreakNotificationContext();
  useStreakInfo(); // triggers hasNewStreakLevel to calculate

  return (
    <Clickable>
      {hasNewStreakLevel() ? (
        <StreakImage src={FireSvg} alt="Streak Notification" />
      ) : (
        <LavenderLogo size={36} />
      )}
    </Clickable>
  );
};

const Clickable = styled.div`
  cursor: pointer;
`;

const StreakImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 63.31px;
  border: 0.5px solid var(--color-neutral-purple-200, #dee1e8);
  background: var(--color-neutral-purple-200, #dee1e8);
`;
