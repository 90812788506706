import { useMemo } from "react";

import { useMainLoopState } from ".";
import { configActions, store } from "~src/redux";
import {
  checkExistingEmailClientAccount,
  detectEmailAddress,
  isExtensionBlocked,
  isValidEmail,
  removeExtensionElements,
} from "~src/utils";

export const useIsExtensionBlockedCheck = ({
  chromeId,
}: Pick<ReturnType<typeof useMainLoopState>, "chromeId">) => {
  const state = store.getState();
  const [isTeam, platform, { emailAddress, mainPollingInterval }] = useMemo(
    () => [
      state.config.settings?.isTeam,
      state.config.settings?.platform,
      state.config,
    ],
    [state.config]
  );

  return useMemo(() => {
    // INFO: for email box like Gmail and Outlook, not teams account, find the email address of the user on the page.
    if (emailAddress) return false;
    // INFO : For SPA webpages the Dom that contains the user's email Address may not be loaded on first page load.
    // Currently the existing extension sets the user email if is not a CRM Team
    // TODO : Find a better way to handle this edge case for outlookNative
    if (isTeam === false && chromeId) {
      const userEmailAddress = detectEmailAddress(platform);
      if (isValidEmail(userEmailAddress)) {
        store.dispatch(configActions.setEmailAddress(userEmailAddress));
        // INFO: This is where we set chrome id in the store.
        // It's based on the user's email address or "CRM User"
        // INFO: Outlook Native has a POP UP SSO for login so we don't need to check this if user is on outlookNative
        if (window.location.origin !== process.env.OUTLOOK_URL) {
          checkExistingEmailClientAccount(userEmailAddress, chromeId)
            .then(() => {
              if (isExtensionBlocked()) {
                clearInterval(mainPollingInterval);
                removeExtensionElements();
              }
            })
            .catch(console.error);
        }
      }
    } else if (!chromeId) {
      const userEmailAddress = detectEmailAddress(platform);
      if (isValidEmail(userEmailAddress)) {
        store.dispatch(configActions.setEmailAddress(userEmailAddress));
      }
    }
    return isExtensionBlocked();
  }, [chromeId, isTeam, mainPollingInterval, platform, emailAddress]);
};
