import * as companyDataApi from "../../../api/getAdditionalCompanyData";

import { CompanyDataType } from "../../../api/typings";
import { parseJobAggregateData, getJobData } from "../../../utils";
import {
  AddJobsToPersonalizationsData,
  AddDataToPersonalizationsArgs,
} from "../../typings";
import { createFeedAsyncThunk } from "../helpers";

export const addCompanyJobs = createFeedAsyncThunk<
  AddJobsToPersonalizationsData,
  AddDataToPersonalizationsArgs
>(
  "feed/addCompanyJobs",
  async ({ email, page = -1 }, { getState, rejectWithValue }) => {
    const {
      config: { chromeId },
      feed: { personalizations },
    } = getState();
    const personalization = personalizations[email]!;
    if (
      personalization?.company?.jobs !== undefined &&
      personalization?.company.jobs.jobs === undefined
    ) {
      const data = await companyDataApi.getAdditionalCompanyData(
        CompanyDataType.jobs,
        email,
        chromeId,
        page
      );
      if (companyDataApi.isError(data)) {
        return rejectWithValue(data);
      }

      const {
        data: {
          jobs: { data: jobData, meta },
        },
        jobs_aggregate,
      } = data;
      return jobData === null
        ? { jobs: [], aggregate: parseJobAggregateData(jobs_aggregate, 0) }
        : {
            jobs: getJobData(jobData),
            aggregate: parseJobAggregateData(jobs_aggregate, meta.count),
          };
    }

    return personalization.company.jobs;
  }
);
