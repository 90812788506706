import { WorkHistoryItem } from "~src/api/typings";
import { PersonalizationEvent, WorkHistory } from "~src/redux/typings";

export const isRankedFeedExperience = (
  item: WorkHistoryItem
): item is PersonalizationEvent => {
  return "score" in item;
};
export const areExperienceItems = (
  items: PersonalizationEvent[] | WorkHistory[]
): items is WorkHistory[] => {
  return "type" in items[0] && items[0].type === "experience";
};
