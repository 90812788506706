import { useCallback, useMemo, useRef, useState } from "react";
import {
  UserSettings,
  BillingPanel,
  ExtensionSettings,
  CarouselContext,
} from ".";
import { CarouselRef } from "antd/lib/carousel";
import { SlideChild, SlideContainer } from "~src/component/Atoms";

export const SettingsCarousel = () => {
  const carouselRef = useRef<CarouselRef>(null);
  const [view, setView] = useState("home");
  const next = useCallback((page) => {
    carouselRef.current?.next();
    setView(page);
  }, []);
  const prev = useCallback(() => {
    carouselRef?.current?.prev();
    setView("home");
  }, []);
  const providerValues = useMemo(() => ({ next, prev }), [next, prev]);
  return (
    <CarouselContext.Provider value={providerValues}>
      <SlideContainer>
        <SlideChild noSlide stage={view === "home" ? "active" : "previous"}>
          <UserSettings />
        </SlideChild>
        <SlideChild stage={view === "extension_settings" ? "active" : "next"}>
          <ExtensionSettings />
        </SlideChild>
        <SlideChild stage={view === "billing" ? "active" : "next"}>
          <BillingPanel />
        </SlideChild>
      </SlideContainer>
    </CarouselContext.Provider>
  );
};
