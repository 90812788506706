import { useEffect, useState } from "react";

import { useGetHumanticsQuery } from "../redux/api";
import { HumanticStatus } from "../api/typings";
import { extractPersonalityAnalysis } from "../utils/personality";
import { AddPersonalityToPersonalizationsData } from "../redux/typings";
import { DEFAULT_PERSONALITY } from "../constants";

const HUMANTIC_POLLING_INTERVAL = 5000;

export const useHumanticsData = (chromeId: string, email: string) => {
  const [data, setData] = useState<AddPersonalityToPersonalizationsData>({
    personality: DEFAULT_PERSONALITY,
  });
  const {
    data: humanticsData,
    refetch,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetHumanticsQuery({
    chromeId,
    email,
  });

  useEffect(() => {
    let tries = 0;
    const maxTries = 12;
    const intervalId = setInterval(() => {
      tries++;

      if (
        (humanticsData?.status !== undefined &&
          [HumanticStatus.FOUND, HumanticStatus.NOT_FOUND].includes(
            humanticsData?.status
          )) ||
        tries >= maxTries
      ) {
        const {
          data: {
            progress,
            sorter_segment,
            results: {
              persona: {
                sales: {
                  communication_advice: {
                    description,
                    adjectives,
                    what_to_avoid,
                    what_to_say,
                  },
                },
              },
              personality_analysis,
            },
          },
        } = humanticsData!;

        clearInterval(intervalId);
        setData({
          personality: {
            ...DEFAULT_PERSONALITY,
            status: tries >= maxTries ? HumanticStatus.NOT_FOUND : progress,
            sorter_segment,
            communication_advice: {
              description,
              adjectives,
              what_to_avoid,
              what_to_say,
            },
            ...extractPersonalityAnalysis(personality_analysis),
          },
        });
      } else if (!isError) {
        void refetch();
      } else {
        clearInterval(intervalId);
        setData({
          personality: {
            ...DEFAULT_PERSONALITY,
            status: HumanticStatus.NOT_FOUND,
          },
        });
      }
    }, HUMANTIC_POLLING_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [humanticsData, isError, refetch]);

  useEffect(() => {
    if (humanticsData) {
      const {
        data: {
          progress,
          sorter_segment,
          results: {
            persona: {
              sales: {
                communication_advice: {
                  description,
                  adjectives,
                  what_to_avoid,
                  what_to_say,
                },
              },
            },
            personality_analysis,
          },
        },
      } = humanticsData;

      setData({
        personality: {
          ...DEFAULT_PERSONALITY,
          loading: false,
          status: progress,
          sorter_segment,
          communication_advice: {
            description,
            adjectives,
            what_to_avoid,
            what_to_say,
          },
          ...extractPersonalityAnalysis(personality_analysis),
        },
      });
    }
  }, [humanticsData]);

  return {
    data,
    isLoading:
      isLoading || humanticsData?.status === HumanticStatus.IN_PROGRESS,
    isSuccess,
    isError,
    error,
  };
};
