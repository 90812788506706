import styled from "styled-components";
import { Button } from "@lavender-ai/lav-components";

import { LavenderIcon } from "~src/component/typings";
import { mainPanelAction, store } from "~src/redux";
import { useLvIdentifier } from "~src/customHooks";
import { useAppDispatch, useAppSelector } from "~src/redux/hooks";

import { SlidingPanel } from "../SlidingPanel";
import { viewState } from "~src/redux/typings";

const CloseButton = styled(Button)`
  position: absolute;
  top: 34px;
  right: 12px !important;
`;

export const IntercomPanel = () => {
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.mainPanel[lvIdentifier].isOpen
  );
  const view: viewState = useAppSelector(
    (state) => state.mainPanel[lvIdentifier].view
  );

  const showSettings = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(mainPanelAction.setView({ lvIdentifier, view: "settings" }));
    if (isOpen !== "open") {
      dispatch(mainPanelAction.open(lvIdentifier));
    } else if (view === "settings") {
      dispatch(mainPanelAction.close(lvIdentifier));
    }
  };
  const { chromeId } = store.getState().config;
  const platform = store.getState().config.settings?.platform;
  const params = new URLSearchParams();
  params.append("chrome_id", chromeId);
  if (platform) {
    params.append("crm", platform);
  }
  // INFO: this won't work with localhost on iframe on some websites due to parent frame security policy
  const intercomURL = `${process.env.INTERCOM_URL}?${params.toString()}`;

  return (
    <SlidingPanel style={{ height: "100%" }}>
      <IFrame src={intercomURL}></IFrame>
      <CloseButton
        size="large"
        icon={LavenderIcon.IconX}
        onClick={showSettings}
      ></CloseButton>
    </SlidingPanel>
  );
};

// INFO: iframe height attribute is not being respected by the browser,using this to set the height of the iframe
const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;
