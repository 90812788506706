import { Flex, Form } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "styled-components";

import { Text } from "@lavender-ai/lav-components";

import { AssistantAvatar } from "~src/component/Atoms";
import { PaywallModal } from "~src/component/Molecules";
import { PanelLayout } from "~src/layout/MainPanel/Panel/PanelLayout/PanelLayout";

import { AssistantForm, FieldType } from "./AssistantForm";
import { Conversation } from "~src/component/Organisms/ChatGPT/Conversation";
import {
  useChat,
  useLvIdentifier,
  useShouldDisplayPaywall,
} from "~src/customHooks";

import { CHAT_GPT_PANEL } from "~src/strings";
import {
  SilverSecondaryButton,
  PanelBackgroundFlex,
  SecondaryText,
} from "~src/component/Styled";
import { emailAction, useAppDispatch } from "~src/redux";
import { run } from "~src/redux/chat";
import { ChatTool, Recommendations } from "~src/redux/typings";

const { COMBINE_CHAT_GPT, SUGGESTIONS, TITLE } = CHAT_GPT_PANEL;

const FOOTER_HEIGHT = 97;

const StyledFlex = styled(PanelBackgroundFlex)`
  width: var(--lv-expanded-panel-size);
  padding: var(--size-56, 56px) 0px var(--size-32, 32px) 0px;
`;

interface InitialSuggestionsProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  suggestions: string[];
}
const getRandomSuggestions = (suggestions: string[], count: number) => {
  const randomIndices = new Set<number>();
  while (randomIndices.size < count) {
    const randomIndex = Math.floor(Math.random() * suggestions.length);
    randomIndices.add(randomIndex);
  }
  return Array.from(randomIndices).map((index) => suggestions[index]);
};

const InitialSuggestions = ({
  onClick,
  suggestions,
}: InitialSuggestionsProps) => {
  return (
    <Flex
      gap="middle"
      align="center"
      style={{
        padding: "24px 20px",
        // INFO: this is to stop the component from shrinking when the panel is collapsed
        width: "var(--lv-expanded-panel-size)",
      }}
      vertical
    >
      <SecondaryText size="body1">{SUGGESTIONS}</SecondaryText>
      <Flex gap="small" style={{ alignSelf: "stretch" }} vertical>
        {suggestions.map((suggestion, index) => (
          <SilverSecondaryButton
            key={index}
            fullWidth
            variant="secondary"
            onClick={onClick}
          >
            <Text style={{ alignItems: "center" }} size="subtext2">
              {suggestion}
            </Text>
          </SilverSecondaryButton>
        ))}
      </Flex>
    </Flex>
  );
};

export const ChatGPTPanel = () => {
  const {
    initialized,
    user: { location, timezone },
  } = useChat();
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState("");
  const [fixItAllContent, setFixItAllContent] = useState<{
    content: string;
    recs: Recommendations;
  }>({ content: "", recs: null });
  const [isAutoSuggestion, setIsAutoSuggestion] = useState(false);
  const randomSuggestions = useMemo(() => {
    return getRandomSuggestions(CHAT_GPT_PANEL.SUGGESTION_LIST, 3);
  }, []);
  const [form] = Form.useForm<FieldType>();
  const shouldShowPaywall = useShouldDisplayPaywall("chatGPT");

  useEffect(() => {
    if (shouldShowPaywall) {
      return;
    }

    dispatch(emailAction.tagChatGPTUsed({ id: lvIdentifier }));
  }, [dispatch, lvIdentifier, shouldShowPaywall]);

  const handleSuggestionClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      let txt = e.currentTarget.textContent ?? "";
      txt = txt.replaceAll("...", "");
      setInputValue(txt || "");
    },
    []
  );

  const handleAnalyzeChatSuggestionClick = useCallback(
    (text: string) => {
      setInputValue(text);
      setIsAutoSuggestion(true);
      if (!shouldShowPaywall) form.submit();
    },
    [form, shouldShowPaywall]
  );

  const handleFixAllChatSuggestionClick = useCallback(
    (text: string, content: string, recs: Recommendations) => {
      setInputValue(text);
      setFixItAllContent({ content, recs });
      if (!shouldShowPaywall) form.submit();
    },
    [form, shouldShowPaywall]
  );

  const onFinish = useCallback(
    (values: FieldType) => {
      void (async () => {
        if (!values.message || shouldShowPaywall) return;

        form.resetFields(["message"]);

        try {
          await dispatch(
            run({
              autoSuggestion: isAutoSuggestion,
              question: values.message,
              lvIdentifier,
              location,
              timezone,
              searchWeb: values[ChatTool.GoogleWebSearch],
              ...fixItAllContent,
            })
          );
        } catch (error) {
          console.error(error);
        } finally {
          setIsAutoSuggestion(false);
          setFixItAllContent({ content: "", recs: null });
        }
      })();
    },
    [
      dispatch,
      fixItAllContent,
      form,
      isAutoSuggestion,
      location,
      lvIdentifier,
      timezone,
      shouldShowPaywall,
    ]
  );

  return (
    <StyledPanelLayout rootClassName="lv-chat-box">
      {shouldShowPaywall && <PaywallModal view="chatGPT" />}
      <Flex
        justify="space-between"
        style={{
          minHeight: `calc(100% - ${FOOTER_HEIGHT}px)`,
          overflowY: "auto",
          overflowX: "hidden",
        }}
        vertical
      >
        <StyledFlex align="center" gap={24} vertical>
          <AssistantAvatar size="large" />
          <Flex
            align="center"
            justify="center"
            gap={20}
            style={{
              padding: "0 48px",
            }}
            vertical
          >
            <Text size="subtext5">{TITLE}</Text>
            <Text size="body2">{COMBINE_CHAT_GPT}</Text>
          </Flex>
        </StyledFlex>
        <PanelBackgroundFlex vertical gap={0}>
          {!initialized && (
            <InitialSuggestions
              onClick={handleSuggestionClick}
              suggestions={randomSuggestions}
            />
          )}
          {initialized && (
            <Conversation
              onClick={handleAnalyzeChatSuggestionClick}
              onBoostScoreClick={handleFixAllChatSuggestionClick}
            />
          )}
        </PanelBackgroundFlex>
      </Flex>
      <PanelBackgroundFlex
        align="flex-start"
        gap="small"
        vertical
        style={{
          padding: "0px 16px 16px 16px",
          position: !initialized ? "absolute" : "sticky",
          bottom: 0,
          // INFO: this is to stop the component from shrinking when the panel is collapsed
          width: "var(--lv-expanded-panel-size)",
          zIndex: 1071,
        }}
      >
        <AssistantForm
          inputValue={inputValue}
          form={form}
          onFinish={onFinish}
        />
      </PanelBackgroundFlex>
    </StyledPanelLayout>
  );
};
const StyledPanelLayout = styled(PanelLayout)`
  height: 100%;
`;
