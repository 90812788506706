import { FC } from "react";
import { Button } from "@lavender-ai/lav-components";

import { FRONTEND_URLS } from "~src/constants";

export const UpgradePayment: FC = () => (
  <Button
    variant="primary"
    size="medium"
    fullWidth={true}
    style={{ marginTop: "16px" }}
    onClick={() => {
      window.open(`${FRONTEND_URLS.dashboard}/app/settings/billing`, "_blank");
    }}
  >
    Upgrade account
  </Button>
);
