import { css, styled } from "styled-components";
import { SKELETON_GRADIENT_MIXIN } from "~src/component/Molecules/Message/styles";
import { AvatarProps, TagProps } from "@lavender-ai/lav-components";
const iconColorMap = {
  lavender: "var(--color-lavender-600)",
  marigold: "var(--color-marigold-700)",
  outline: "var(--color-neutral-purple-600)",
  pear: "var(--color-pear-700)",
  peony: "var(--color-peony-600)",
  tart: "var(--color-tart-600)",
};

interface StyledIconFrameProps<T> {
  color: AvatarProps["color"] | TagProps["color"];
  isLoading?: boolean;
  T?: T;
}

export function createStyledIconFrame<T>(BaseComponent) {
  return styled(BaseComponent)<StyledIconFrameProps<T>>`
    &.lav-components-avatar {
      flex-shrink: 0;

      ${({ $isLoading: isLoading }) =>
        isLoading &&
        css`
          background-color: transparent !important;
          border-color: transparent;
          border-radius: 15px;
          display: inline-block;
          pointer-events: none;
          ${SKELETON_GRADIENT_MIXIN}
        `};
    }

    &.lav-components-tag {
      display: flex;
      align-items: center;
      white-space: nowrap;
      gap: 4px;

      ${({ $isLoading: isLoading }) =>
        isLoading &&
        css`
          background-color: transparent !important;
          border-color: transparent;
          pointer-events: none;
          ${SKELETON_GRADIENT_MIXIN}
        `};

      ${({ color, $isLoading: isLoading }) =>
        color === "outline" &&
        !isLoading &&
        `
      border: 1px solid var(--color-neutral-purple-200) !important;
      background-color: var(--color-base-White) !important;
    `}
    }

    svg.lav-components-icon {
      ${({ color, $isLoading: isLoading }) =>
        (isLoading || (color && iconColorMap[color])) &&
        `
      color: ${isLoading ? "transparent" : iconColorMap[color!]};
      stroke: ${isLoading ? "transparent" : iconColorMap[color!]};
    `}
    }

    .lv-icon-frame-text {
      ${({ color, $isLoading: isLoading }) =>
        (isLoading || (color && iconColorMap[color])) &&
        `
      color: ${isLoading ? "transparent" : iconColorMap[color!]};
    `}
    }
  `;
}
