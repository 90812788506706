import { Badge, Card, Flex, Space } from "antd";
import { Avatar, Icon, Text, IconProps } from "@lavender-ai/lav-components";
import { useContext } from "react";
import frameworks from "./frameworks.json";
import { FrameworkContext } from "./FrameworkContext";
import styled from "styled-components";

export const ListFrameworks = () => {
  const { next } = useContext(FrameworkContext);
  return (
    <Flex
      vertical={true}
      justify="space-between"
      align="center"
      style={{ minWidth: "100%", width: 375, backgroundColor: "#F6F8FC" }}
    >
      <Flex
        vertical={true}
        justify="space-between"
        align="center"
        style={{ padding: "48px 40px 40px 40px" }}
      >
        <Space direction={"vertical"} size={24}>
          <Avatar
            alt="Box"
            icon={<Icon color="white" variant={"IconBox"} />}
            shape="circle"
            size="large"
            style={{
              background: "var(--color-neutral-blue-900, #18253D)",
              margin: "0px auto",
            }}
          />
          <Space
            direction={"vertical"}
            align="center"
            style={{ textAlign: "center" }}
            size={20}
          >
            <Text color="black" size="subtext5">
              Frameworks
            </Text>
            <Text color="black" size="body2">
              Frameworks give you inspiration and an outline for how to get more
              replies faster. Edit them to make them your own.
            </Text>
          </Space>
        </Space>
      </Flex>
      <Space direction={"vertical"} style={{ width: 327 }} size={16}>
        {frameworks.map((category, index) => {
          return (
            <StyledCard
              key={index}
              title={
                <Space size={12}>
                  <Avatar
                    icon={
                      <Icon
                        size={"extra-small"}
                        variant={category.icon as IconProps["variant"]}
                        color={category.iconColor as IconProps["color"]}
                      />
                    }
                    size="extra-small"
                    shape={"square"}
                    style={{
                      background: category.iconBackground,
                    }}
                  />
                  <Text size={"subtext2"}>{category.title}</Text>
                </Space>
              }
              style={{ width: 335 }}
            >
              <Space direction={"vertical"} style={{ width: "100%" }} size={0}>
                {category.useCases.map((useCase, index) => {
                  return (
                    <Flex
                      style={{ height: 40, cursor: "pointer" }}
                      key={index}
                      align={"center"}
                      justify={"space-between"}
                      onClick={() => {
                        next(useCase);
                      }}
                    >
                      <Text>{useCase.name}</Text>
                      <Space direction={"horizontal"} size={8}>
                        <Badge
                          color={"#F6F8FC"}
                          count={useCase.frameworks.length}
                          style={{
                            color: "var(--color-neutral-purple-900, #0D1421)",
                            fontFamily: "Bricolage Grotesque",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "18px",
                          }}
                        />
                        <Icon
                          color="dark"
                          size="extra-small"
                          variant="IconChevronRight"
                          style={{ display: "flex" }}
                        />
                      </Space>
                    </Flex>
                  );
                })}
              </Space>
            </StyledCard>
          );
        })}
      </Space>
    </Flex>
  );
};

const StyledCard = styled(Card)`
  & .ant-card-head {
    padding: 16px 16px 12px 16px;
  }
  & .ant-card-body {
    padding: 8px;
    // INFO: each Item wrapper in Space component
    & .ant-space-item {
      :hover {
        border-radius: 8px;
        background: var(--lv-panel-background-color);
        // INFO: chevron icon color when hover on wrapper
        .lav-components-icon-color-dark {
          color: var(--color-neutral-purple-900, #0d1421);
        }
      }
      // INFO: container of the text, button and chevron
      .ant-flex {
        padding: 10px 8px 10px 12px;
        height: 20px !important;
      }
      // INFO: chevron icon
      .lav-components-icon-color-dark {
        color: var(--color-neutral-purple-400, #c8cbd0);
      }
    }
  }
`;
