import { memo } from "react";
import { Flex } from "antd";
import { styled } from "styled-components";

import { Avatar, Icon, Text } from "@lavender-ai/lav-components";

import { ProgressBar } from "~src/component/Atoms";
import { Celebratory, Frowning, Happy } from "~src/component/Atoms/SVGHeader";
import { CENTERED_SVG_MIXIN, SecondaryText } from "~src/component/Styled";
import { LavenderIcon, PropsForStyling } from "~src/component/typings";
import { Spinner } from "~src/component/Utilities/Loading/Spinner/Spinner";

const StyledListItemAvatar = styled(Avatar)`
  span.ant-avatar.lav-components-avatar-color-white& {
    border-radius: 50%;
    border: 2px #dee1e8 solid;
    span.lav-components-text-color-black.lav-components-text {
      color: var(--color-neutral-purple-400);
      margin: 0;
    }
  }
`;

const StyledListItemInactiveText = styled(Text)`
  .lav-components-text& {
    color: var(--color-neutral-purple-500);
  }
`;

const AbsoluteWrapper = styled(Flex)<
  PropsForStyling<{
    isDense?: boolean;
  }>
>`
  ${CENTERED_SVG_MIXIN}
  margin-top: 33px; // (Stepper height - FadeStepper height) / 2
  text-align: center;
  z-index: 10;
  ${({ $isDense: isDense }) => isDense && `scale: 0.9`}
`;

// Container holds the Wizard and the Stepper itself
const Container = styled(Flex)`
  position: relative;

  > svg {
    bottom: 53px; // (Stepper height / 2) - 33px
    position: absolute;
  }
`;

const StyledStepper = styled(Flex)`
  ${CENTERED_SVG_MIXIN}
  background-color: var(--color-base-White);
  border-radius: var(--radius-lg);
  min-width: 200px;
  padding: 20px;
  width: max-content;
`;

interface StyledStepProps {
  step?: number;
}

const StyledStep = styled(Flex)<PropsForStyling<StyledStepProps>>`
  ${({ $step: step }) => step === 5 && `margin-top: 0.6px`}

  .lv-step-spinner-container {
    margin-top: 1.4px;
  }

  svg:not(.lav-components-icon) {
    scale: 1.6;
  }

  .lv-step-spinner-avatar {
    flex-shrink: 0;
  }
`;

const ProgressDetailsContainer = styled(Flex)<
  PropsForStyling<{
    isDense?: boolean;
  }>
>`
  bottom: ${({ $isDense: isDense }) => (isDense ? "10px" : "35px")};
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translate(-50%, 0px);
  z-index: 20;

  .ant-progress-line {
    left: 50%;
    margin: 0;
    transform: translate(-50%, 0px);
    width: 184px;
  }
`;

const DetailsText = styled(SecondaryText)<
  PropsForStyling<{ isHidden?: boolean }>
>`
  width: 282px;

  ${({ $isHidden: isHidden }) => isHidden && `visibility: hidden`}
`;

interface StepProps {
  step: number;
  index: number;
  isActive: boolean;
  text: string;
}

const Step = ({ index, isActive, text, step }: StepProps) => {
  return (
    <StyledStep align="center" gap={10}>
      {isActive && step !== 0 && step <= index && (
        <div className="lv-step-spinner-container">
          <Spinner size={24} />
        </div>
      )}
      {!isActive && (step === 0 || step < index) && (
        <StyledListItemAvatar
          className="lv-step-spinner-avatar"
          color="white"
          size="extra-small"
          type="soft"
        >
          <Text size="body1">{index}</Text>
        </StyledListItemAvatar>
      )}
      {!isActive && step !== 0 && step > index && (
        <Avatar
          color="pear"
          icon={<Icon size="extra-small" variant={LavenderIcon.IconCheck} />}
          size="extra-small"
          type="soft"
        />
      )}
      {isActive || step === 0 ? (
        <Text size="subtext1" color="dark">
          {text}
        </Text>
      ) : (
        <StyledListItemInactiveText size="subtext1">
          {text}
        </StyledListItemInactiveText>
      )}
    </StyledStep>
  );
};

interface WizardProps {
  step: number;
  totalSteps: number;
}

const Wizard = ({ step, totalSteps }: WizardProps) => {
  if (step === 0 || step === 1) return <Frowning />;
  if (step === totalSteps + 1) return <Celebratory />;
  return <Happy />;
};

interface StepsProps {
  step: number;
  steps: string[];
}

const Steps = memo(function Steps({ step, steps }: StepsProps) {
  return steps.map((text, index) => (
    <Step
      key={index}
      index={index + 1}
      isActive={step === index + 1}
      text={text}
      step={step}
    />
  ));
});

interface Props {
  additionalDetailsText: string;
  isDense?: boolean;
  step: number;
  steps: string[];
}

export const Stepper = ({
  additionalDetailsText,
  isDense,
  step,
  steps,
}: Props) => {
  return (
    <>
      <AbsoluteWrapper align="center" vertical $isDense={isDense}>
        <Container justify="center">
          <Wizard step={step} totalSteps={steps.length} />
          <StyledStepper align="flex-start" gap={10} vertical>
            <Steps step={step} steps={steps} />
          </StyledStepper>
        </Container>
      </AbsoluteWrapper>
      <ProgressDetailsContainer gap="middle" vertical $isDense={isDense}>
        <DetailsText
          color="lavender"
          size="body1"
          $isHidden={!additionalDetailsText}
        >
          {additionalDetailsText}
        </DetailsText>
        <ProgressBar
          step={step === 0 ? step : step + step - 1}
          totalSteps={8}
        />
      </ProgressDetailsContainer>
    </>
  );
};
