import { css, styled } from "styled-components";

import { Button, ButtonProps } from "@lavender-ai/lav-components";

import { SKELETON_GRADIENT_MIXIN } from "../../Molecules/Message/styles";
import { PropsForStyling } from "../../typings";

interface StyledExtensionButtonProps {
  loading?: boolean;
  mode: ExtensionButtonMode;
}

export const StyledExtensionButton = styled(Button)<
  PropsForStyling<StyledExtensionButtonProps>
>`
  a.lav-components-button&,
  button.ant-btn.lav-components-button& {
    ${({ $loading: loading }) =>
      loading &&
      css`
        border-color: transparent;
        pointer-events: none;
        ${SKELETON_GRADIENT_MIXIN}
      `};
    ${({ $mode: mode }) =>
      mode === ExtensionButtonMode.chip &&
      css`
        max-width: fit-content;
      `};

    span.lav-components-text.lav-components-text-color-black {
      color: ${({ $loading: loading, variant }) => {
        if (loading) {
          return "transparent !important";
        }
        if (!variant || variant === "default") {
          return "var(--color-neutral-purple-900) !important";
        }
      }};
    }

    svg.lav-components-icon {
      stroke: ${({ state, $loading: loading, $mode: mode, variant }) => {
        if (loading) return "transparent !important";
        if (variant === "primary") {
          if (mode === ExtensionButtonMode.cta) {
            return `${state === "disabled" ? "var(--color-base-White)" : "var(--color-lavender-200)"} !important`;
          }
          return "var(--color-base-White) !important";
        }
        if (variant === "danger") {
          return "var(--color-peony-600) !important";
        }
        if (!variant || ["default", "secondary"].includes(variant)) {
          return "var(--color-neutral-purple-600) !important";
        }
      }};
    }
  }
`;

export enum ExtensionButtonMode {
  chip = "chip",
  cta = "cta",
}

export interface Props
  extends Pick<
    ButtonProps,
    | "className"
    | "fullWidth"
    | "htmlType"
    | "href"
    | "onClick"
    | "prefix"
    | "size"
    | "suffix"
    | "variant"
    | "state"
  > {
  isActive?: boolean;
  disabled?: boolean;
  mode?: ExtensionButtonMode;
  loading?: boolean;
  text: string;
}

export const ExtensionButton = ({
  disabled,
  isActive,
  loading,
  mode = ExtensionButtonMode.cta,
  variant = "default",
  text,
  ...buttonProps
}: Props) => {
  let size: ButtonProps["size"];
  let fullWidth: ButtonProps["fullWidth"];

  switch (mode) {
    case ExtensionButtonMode.chip:
      size = buttonProps.size || "small";
      break;
    case ExtensionButtonMode.cta:
      fullWidth = true;
      size = buttonProps.size || "large";
      break;
  }

  return (
    <StyledExtensionButton
      $loading={loading}
      $mode={mode}
      fullWidth={fullWidth}
      size={size}
      state={disabled && !loading ? "disabled" : isActive ? "focus" : "initial"}
      variant={loading ? "default" : variant}
      {...(buttonProps.href
        ? { target: "_blank", rel: "noopener noreferrer" }
        : {})}
      {...buttonProps}
    >
      {text}
    </StyledExtensionButton>
  );
};
