import { ISourceOptions } from "@tsparticles/engine";
import { Particles } from "@tsparticles/react";

import FireSvg from "~src/assets/images/StreakPanel/Fire.svg";
import PartyPopperSvg from "~src/assets/images/StreakPanel/PartyPopper.svg";
import HeartSvg from "~src/assets/images/StreakPanel/Heart.svg";
import { useEffect, useState } from "react";

export const Confetti = () => {
  const [hasFired, setHasFired] = useState(false);

  // need to despawn after firing to prevent blocking scrolling
  useEffect(() => {
    setTimeout(() => {
      setHasFired(true);
    }, 7000);
  });

  return !hasFired && <Particles id="tsparticles" options={options} />;
};

const options: ISourceOptions = {
  fullScreen: { enable: false },
  fpsLimit: 30,
  particles: {
    number: { value: 0 },
    shape: {
      type: "image",
      options: {
        image: [
          {
            src: FireSvg,
            width: 32,
            height: 32,
          },
          {
            src: PartyPopperSvg,
            width: 32,
            height: 32,
          },
          {
            src: HeartSvg,
            width: 32,
            height: 32,
          },
        ],
      },
    },
    opacity: {
      value: 1,
      animation: {
        enable: false,
      },
    },
    size: { value: { min: 16, max: 32 } },
    life: {
      duration: {
        sync: true,
        value: 7,
      },
      count: 1,
    },
    move: {
      enable: true,
      gravity: {
        enable: true,
        acceleration: 9.81,
      },
      speed: { min: 0, max: 15 },
      direction: "none",
      random: false,
      straight: false,
      outModes: {
        default: "destroy",
        top: "none",
      },
    },
    rotate: { value: 0, animation: { enable: false } },
    tilt: { enable: false },
    roll: { enable: false },
    wobble: { enable: false },
  },
  detectRetina: true,
  emitters: {
    position: {
      x: 50,
      y: -5,
    },
    direction: "top",
    life: {
      count: 1,
      duration: 1,
      delay: 1,
    },
    rate: {
      delay: 1,
      quantity: 100,
    },
    size: {
      width: 0,
      height: 0,
    },
  },
};
