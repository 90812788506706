import { Flex, Skeleton } from "antd";

import { Avatar, Button, Icon, Text } from "@lavender-ai/lav-components";

import { usePersonalization } from "~src/customHooks";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
} from "../../../Utilities/Loading";
import { useCarouselContext } from "../CarouselContextProvider";

import { usePanelTabContext } from "~src/contexts";
import { PERSONALIZATION_CTA, PERSONALIZATION_DROPDOWN } from "~src/strings";
import {
  PersonalizationTab,
  type PersonalizationSubTab,
  LavenderIcon,
} from "../../../typings";
import { GlobalSearchModal } from "~src/component/Organisms";

const { FEED } = PERSONALIZATION_DROPDOWN;
const { NOTES } = PERSONALIZATION_CTA;

export const PersonalizationHeader = () => {
  const {
    currentPersonalization: {
      profile: { loading },
    },
  } = usePersonalization();
  const { tab, setPanelTabContext } = usePanelTabContext<
    PersonalizationTab,
    PersonalizationSubTab
  >();
  const { next } = useCarouselContext();

  return (
    <Flex
      align="center"
      justify="space-between"
      wrap="wrap"
      style={{
        padding: "16px 20px 8px 20px",
      }}
    >
      <CustomSkeleton
        loading={loading}
        titleWidth="34px"
        variant={CustomSkeletonVariant.avatarTitle}
      >
        {tab !== PersonalizationTab.FEED ? (
          <Button
            htmlType="button"
            prefix={LavenderIcon.IconChevronLeft}
            prefixColor="dark"
            onClick={() => {
              setPanelTabContext({ tab: PersonalizationTab.FEED });
            }}
          >
            Back
          </Button>
        ) : (
          <Flex gap={12} align={"center"}>
            <Avatar
              icon={<Icon variant={LavenderIcon.IconList} size="extra-small" />}
              color="neutral-100"
              shape="circle"
              size="extra-small"
            />
            <Text size="subtext3">{FEED}</Text>
          </Flex>
        )}
        <Button
          size="small"
          variant="secondary"
          onClick={() => {
            next("Notes", {});
          }}
        >
          {NOTES}
        </Button>
        <GlobalSearchModal />
      </CustomSkeleton>
      {loading && (
        <Skeleton.Button
          style={{ position: "absolute", right: 24, top: 24 }}
          active={true}
          shape="round"
          size="small"
        />
      )}
    </Flex>
  );
};
