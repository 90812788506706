import { Button, Icon, Text, Tooltip } from "@lavender-ai/lav-components";
import { Divider, Flex, Menu } from "antd";
import styled from "styled-components";
import { useState } from "react";

import { SelectableMenuItem } from "~src/component/Molecules/Dropdown/ScoreDropdown/SelectableMenuItem";
import {
  emailAction,
  selectEmailAnalysis,
  selectEmailData,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";
import { useLvIdentifier } from "~src/customHooks";
import { EmailAnalysisStage as Stage } from "~src/redux/typings";

const StyledFlexPopup = styled(Flex)`
  background: white;
  padding: 8px 6px;
  border-radius: 8px;
  border: 1px solid var(--color-neutral-purple-100, #e9ebf2);
`;

export const ScoreDropdown = () => {
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const analysisData = useAppSelector(selectEmailAnalysis(lvIdentifier));
  const { hasPersonalizedScore, stage } = analysisData;
  const { scoringModelOverwrite } = useAppSelector(
    selectEmailData(lvIdentifier)
  );
  const [currentStage, setCurrentStage] = useState<Stage>(stage);
  const [currentScoringModel, setCurrentScoringModel] = useState<
    "personalized" | "best practices" | undefined
  >(scoringModelOverwrite || analysisData.scoringModel);

  return (
    <StyledFlexPopup
      vertical={true}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Flex vertical={true} gap={8} style={{ paddingTop: 8 }}>
        <Text
          size={"subtext1"}
          style={{
            paddingLeft: 12,
            color: "var(--color-neutral-purple-600, #6d727d)",
          }}
        >
          Stage
        </Text>
        <Menu>
          <SelectableMenuItem
            onClick={() => {
              setCurrentStage(Stage.cold);
            }}
            isSelected={currentStage === Stage.cold}
          >
            <Text size={"body1"}>Cold</Text>
          </SelectableMenuItem>
          <SelectableMenuItem
            onClick={() => {
              setCurrentStage(Stage.followUp);
            }}
            isSelected={currentStage === Stage.followUp}
          >
            <Text size={"body1"}>Follow Up</Text>
          </SelectableMenuItem>
          <SelectableMenuItem
            onClick={() => {
              setCurrentStage(Stage.reply);
            }}
            isSelected={currentStage === Stage.reply}
          >
            <Text size={"body1"}>Reply</Text>
          </SelectableMenuItem>
        </Menu>
      </Flex>
      <Divider style={{ margin: 0, padding: 0 }} />
      <Flex
        vertical={true}
        gap={8}
        style={{ marginTop: 16 }}
        justify={"space-between"}
      >
        <Text
          size={"subtext1"}
          style={{
            paddingLeft: 12,
            color: "var(--color-neutral-purple-600, #6d727d)",
          }}
        >
          Algorithm
        </Text>
        <Menu>
          {hasPersonalizedScore ? (
            <SelectableMenuItem
              onClick={() => {
                setCurrentScoringModel("personalized");
              }}
              isSelected={currentScoringModel === "personalized"}
            >
              <Text size={"body1"}>Personalized</Text>
            </SelectableMenuItem>
          ) : (
            <SelectableMenuItem
              onClick={() => {}}
              isSelected={currentScoringModel === "personalized"}
            >
              <Tooltip
                placement={"left"}
                hideArrow={true}
                title={
                  <Flex align={"center"} gap={8}>
                    <Icon
                      variant="IconAlertTriangle"
                      size="large"
                      color={"warning"}
                    />
                    <Text size={"body1"} color={"white"}>
                      Personalized scoring is only available for Teams plans
                      with ~10k emails
                    </Text>
                  </Flex>
                }
              >
                <Text size={"body1"}>Personalized</Text>
              </Tooltip>
            </SelectableMenuItem>
          )}
          <SelectableMenuItem
            onClick={() => {
              setCurrentScoringModel("best practices");
            }}
            isSelected={currentScoringModel === "best practices"}
          >
            <Text size={"body1"}>Best Practices</Text>
          </SelectableMenuItem>
        </Menu>
      </Flex>
      <div style={{ marginTop: 8 }}>
        <Button
          variant="primary"
          size={"small"}
          fullWidth={true}
          onClick={() => {
            dispatch(
              emailAction.updateScoringModel({
                id: lvIdentifier,
                scoringModelOverwrite: currentScoringModel,
              })
            );
            dispatch(
              emailAction.updateStage({
                id: lvIdentifier,
                stageOverwrite: currentStage,
              })
            );
          }}
        >
          Confirm
        </Button>
      </div>
    </StyledFlexPopup>
  );
};
