import { Flex } from "antd";
import styled from "styled-components";

import { Animation, Text } from "@lavender-ai/lav-components";

import { SecondaryText } from "~src/component/Styled";

const FlexWrapper = styled(Flex)`
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, -54%);
  top: 50%;
  margin: 0 auto;
  text-align: center;
  width: 200px;

  @keyframes resize {
    from {
      transform: scale(0.72);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .lav-particles-animation-variant-brand-loading-purple-bg.lav-particles-animation:has(
      .lav-particles-animation-variant-brand-loading-purple-bg.lav-particles-animation
    ) {
    animation: resize 0.5s ease-in-out forwards;
    animation-delay: 1s;
    transform: scale(0.72);
  }

  .lav-components-text {
    animation: appear 0.5s ease-in-out forwards;
    animation-delay: 1s;
    opacity: 0;
  }
`;

interface Props {
  header: string;
  subheader: string;
}

export const ExpandingProgress = ({ header, subheader }: Props) => {
  return (
    <FlexWrapper align="center" gap={10} vertical>
      <Text size="subtext5" color="white">
        {header}
      </Text>
      <Animation variant="brand-loading-purple-bg" />
      <SecondaryText size="body1" color="lavender">
        {subheader}
      </SecondaryText>
    </FlexWrapper>
  );
};
