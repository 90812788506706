import { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import root from "react-shadow/styled-components";
import { styled } from "styled-components";
import { Flex } from "antd";

import { Recommendation, AISuggestion } from "~src/redux/typings";
import { useDomNodes } from "~src/customHooks";
import { Icon, Text } from "@lavender-ai/lav-components";
import { removeText } from "~src/utils/dom";

export const Removal = ({
  recommendation,
  suggestions,
  clear,
}: {
  recommendation: Recommendation;
  suggestions: AISuggestion | undefined;
  clear: () => void;
}) => {
  const measuredRef = useRef<HTMLElement>(null);

  const { panelParent, emailBody } = useDomNodes();

  const scrollElement = panelParent?.querySelector(".qz.aiL");
  const parentScrollElement = panelParent?.querySelector(".Tm.aeJ .aeF");

  useEffect(() => {
    const adjustHeight = () => {
      if (!emailBody || !recommendation?.id) {
        return;
      }
      const elementWindow = emailBody?.ownerDocument.defaultView;
      const item = emailBody?.ownerDocument.getElementById(recommendation?.id);

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!item || !measuredRef.current) return;
      const location = item.getBoundingClientRect();
      // const rightLocation = container.getBoundingClientRect();

      // Determin top or bottom (try bottom first)
      const innerHeight = elementWindow?.innerHeight ?? 0;
      const bottomSpace = innerHeight - (location.y + location.height);
      const windowHeight = measuredRef.current?.offsetHeight;
      if (windowHeight < bottomSpace) {
        measuredRef.current.style.bottom = "auto";
        measuredRef.current.style.top = location.y + location.height + 6 + "px";
      } else {
        measuredRef.current.style.top = "auto";
        measuredRef.current.style.bottom =
          innerHeight - (location?.top || 0) + 6 + "px";
      }

      measuredRef.current.style.width = "fit-content";
      measuredRef.current.style.left = location?.left + "px";
      measuredRef.current.style.position = "fixed";
    };

    if (!panelParent || !recommendation?.id) {
      return;
    }
    const heightObserver = new ResizeObserver(adjustHeight);
    heightObserver.observe(panelParent);
    scrollElement?.addEventListener("scroll", adjustHeight);
    parentScrollElement?.addEventListener("scroll", adjustHeight);

    // Scroll into view?
    const item = document.getElementById(recommendation?.id);
    const topPos = item?.offsetTop || 0;
    if (scrollElement && item) {
      scrollElement.scrollTop = topPos;
    }
    if (parentScrollElement && item) {
      parentScrollElement.scrollTop = parentScrollElement.scrollHeight;
    }

    return () => {
      heightObserver.unobserve(panelParent);
      scrollElement?.removeEventListener("scroll", adjustHeight);
      parentScrollElement?.removeEventListener("scroll", adjustHeight);
    };
  }, [
    emailBody,
    panelParent,
    parentScrollElement,
    recommendation?.id,
    scrollElement,
  ]);

  const clearPage = () => {
    clear();
  };

  const removeAction = useCallback(
    (recommendationId?: string) => {
      if (!emailBody || !recommendationId) {
        return;
      }

      removeText(emailBody, recommendationId);
      clear();
    },
    [emailBody, clear]
  );

  const isLoading = suggestions === undefined;

  if (!emailBody?.ownerDocument.body) {
    console.error(
      "Email Body Document not found",
      emailBody,
      emailBody?.ownerDocument.body
    );
    return null;
  }

  return createPortal(
    <ShadowDOM id={`pop-${recommendation.id}`} ref={measuredRef}>
      <style>
        @import {`"${chrome.runtime.getURL?.("react/bundle.css")}"`}
      </style>
      <Popup className={isLoading ? "lv-popup-loading" : ""}>
        <PopupOption onClick={clearPage}>
          <Icon variant="IconBan" size="extra-small" />
          <Text size="body2">Ignore suggestion</Text>
        </PopupOption>
        <PopupOption
          onClick={() => {
            removeAction(recommendation?.id);
          }}
        >
          <Icon variant="IconTrash" size="extra-small" />
          <Text size="body2">Remove text</Text>
        </PopupOption>
      </Popup>
    </ShadowDOM>,
    emailBody?.ownerDocument.body
  );
};

const ShadowDOM = styled(root.div)`
  height: auto;
  width: 100px;
  position: fixed;
  z-index: 9999999;
`;
const Popup = styled(Flex)`
  border-radius: var(--radius-md-lrg, 12px);
  background: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 6px;
  gap: 4px;
  &.lv-popup-loading .lv-regenerate {
    cursor: not-allowed;
  }
`;
const PopupOption = styled(Flex)`
  display: flex;
  min-height: 32px;
  padding: 0 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--radius-md, 8px);
  cursor: pointer;
  &:hover {
    background: var(--color-neutral-purple-100, #e9ebf2);
  }
`;
