import { XOR } from "ts-xor";

import { Analysis, Highlights, Recommendations } from "~src/redux/typings";

export interface Payload {
  chromeId: chromeId;
  response: string;
  source?: string;
}

interface ChatAnalysis extends Omit<Analysis, "subject" | "writing_time"> {
  subject: {
    word_count: 2;
    title_case: true;
  };
}

export interface AnalyzeChatSuccessResponse {
  analysis: ChatAnalysis;
  highlights: Highlights;
  recs: Recommendations;
  score: number;
}

export interface AnalyzeChatErrorResponse {
  error: string;
}

export type AnalyzeChatResponse = XOR<
  AnalyzeChatSuccessResponse,
  AnalyzeChatErrorResponse
>;
