import { Flex } from "antd";
import { styled } from "styled-components";

export const EmailCoachLoading = styled(Flex)`
  height: 100%;
  padding: 0 20px;
  min-width: 335px;

  .lv-email-coach-loading-animation {
    position: relative;
    height: 100px;
    width: 100px;
  }
`;
export const EmailCoachBody = styled(Flex)`
  padding: 20px 20px 0;
  gap: 12px;
  min-width: 335px;
  overflow: hidden;
`;
