import { memo } from "react";
import { Flex } from "antd";
import { styled } from "styled-components";

import { Tag, TagProps } from "@lavender-ai/lav-components";

import { useLoadMore } from "~src/customHooks";
import { BUTTON_TEXT } from "~src/strings";

import { LavenderIcon, LavenderTagColorMap } from "../typings";
import { TagColorCount } from "../constants";
import { FullWidthFlex } from "../Styled";
import { LoadMoreButton } from "./";

const StyledFlex = styled(FullWidthFlex)`
  padding-top: 12px;
  div {
    width: 100%;
  }
`;

interface Props {
  colored?: boolean;
  coloredIdx?: number;
  size?: "small" | "medium" | "large";
  tags: string[];
}

const TagListInternal = ({
  tags,
  size = "small",
  coloredIdx,
  colored,
}: Props) => {
  const DEFAULT_MAX_TAGS_SHOWN = 3;
  const { currentItems, loadMore, resetCount } = useLoadMore(
    tags,
    DEFAULT_MAX_TAGS_SHOWN,
    100 // 100 to show all tags
  );
  const tagItems = currentItems.map((tag, idx) => (
    <Tag
      key={idx}
      size={size}
      color={
        colored
          ? (LavenderTagColorMap[
              (coloredIdx ?? idx) % TagColorCount
            ] as TagProps["color"])
          : "gray"
      }
    >
      {tag}
    </Tag>
  ));

  return (
    <Flex wrap="wrap" gap="small">
      {tagItems}
      {tags.length > currentItems.length && (
        <Tag
          size={"small"}
          // @ts-expect-error - needs fix
          color={"white"}
          onClick={() => {
            loadMore();
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <Flex justify="center" align="center">
            +{tags.length - currentItems.length} more
          </Flex>
        </Tag>
      )}
      {tags.length > DEFAULT_MAX_TAGS_SHOWN &&
        tags.length === currentItems.length && (
          <StyledFlex>
            <LoadMoreButton
              onClick={resetCount}
              buttonText={BUTTON_TEXT.SEE_LESS}
              prefix={LavenderIcon.IconMinus}
              size="small"
            />
          </StyledFlex>
        )}
    </Flex>
  );
};

export const TagList = memo(TagListInternal);
