import { useState, useEffect } from "react";
import { URLS, networkRequest } from "~src/api";
import { store, configActions, fetchConfig } from "~src/redux";
import { getChromeId } from "~src/utils";

export const useMainLoopState = () => {
  const [chromeId, setChromeId] = useState<string>();
  const [isFetchingChromeId, setIsFetchingChromeId] = useState(false);

  const [isLegacyInitDefined, setIsLegacyInitDefined] = useState<boolean>(
    !!window.LegacyInit
  );

  const [isNewExtensionEnabled, setIsNewExtensionEnabled] = useState<boolean>();
  const [isFetchingNewExtensionSetting, setIsFetchingNewExtensionSetting] =
    useState(false);

  const [userExperience, setUserExperience] = useState<ExtensionExperience>();
  const [isFetchingUserExperience, setIsFetchingUserExperience] =
    useState(false);
  const [hasFetchedUserExperience, setHasFetchedUserExperience] =
    useState(false);

  const [doesUserNeedLogin, setDoesUserNeedLogin] = useState(false);
  const { platform } = store.getState().config.settings ?? {};
  // Listen for LegacyInit definition effect - mutations to window fields are not detected by React
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.LegacyInit) {
        setIsLegacyInitDefined(true);
        clearInterval(interval);
      }
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Get chromeId effect
  useEffect(() => {
    // Get chromeId if it hasn't been fetched yet
    if (!chromeId && !isFetchingChromeId && hasFetchedUserExperience) {
      setIsFetchingChromeId(true);
      void getChromeId(userExperience, platform).then((id) => {
        setChromeId(id);
        store.dispatch(configActions.setChromeId(id));
        if (id === "") {
          setDoesUserNeedLogin(true);
        }
      });
    }
  }, [
    chromeId,
    setChromeId,
    isFetchingChromeId,
    setIsFetchingChromeId,
    userExperience,
    platform,
    isFetchingUserExperience,
    hasFetchedUserExperience,
  ]);

  // Get NewExtensionSetting effect
  useEffect(() => {
    // Get new extension setting if chromeId is defined and hasn't been fetched yet
    if (
      chromeId &&
      isNewExtensionEnabled === undefined &&
      !isFetchingNewExtensionSetting
    ) {
      setIsFetchingNewExtensionSetting(true);
      void fetchNewExtensionSetting(chromeId).then(setIsNewExtensionEnabled);
    }
  }, [
    chromeId,
    setIsNewExtensionEnabled,
    isNewExtensionEnabled,
    isFetchingNewExtensionSetting,
  ]);

  // Determine extension experience effect
  useEffect(() => {
    void (async () => {
      if (!userExperience && !isFetchingUserExperience) {
        setIsFetchingUserExperience(true);
        // INFO : Fetch config and store it in redux
        try {
          await store.dispatch(fetchConfig()).unwrap();
        } catch (error) {
          // 403 doesn't need to be handled, will set fetched true so API key can be scraped
          setHasFetchedUserExperience(true);
          return;
        }
        const state = store.getState();
        const { isTeam, platform } = state.config.settings ?? {};

        if (isTeam) {
          setUserExperience("crmTeams");
        } else if (platform === "lavenderAnywhere") {
          setUserExperience("lavenderAnywhere");
        } else if (platform === "lavenderAnywhereIframe") {
          setUserExperience("lavenderAnywhereIframe");
        } else {
          setUserExperience("mailClient");
        }
        setHasFetchedUserExperience(true);
      }
    })();
    // Run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    chromeId,
    isLegacyInitDefined,
    isNewExtensionEnabled,
    userExperience,
    doesUserNeedLogin,
  };
};

const fetchNewExtensionSetting = async (chromeId: string) => {
  if (chromeId.includes("|")) {
    chromeId = chromeId.split("|")[0];
  }
  const KEY = "NewExtension";
  const data = await networkRequest.post<{ [KEY]: boolean }>(URLS.getSetting, {
    chrome_id: chromeId,
    key: KEY,
  });
  return data?.data?.[KEY];
};
