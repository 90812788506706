import React from "react";
import { Icon, MenuItem } from "@lavender-ai/lav-components";
import { Flex } from "antd";
import { styled } from "styled-components";

interface SelectableMenuItemProps {
  isSelected: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const StyledMenuItem = styled(MenuItem)`
  &:hover {
    background-color: rgb(236, 236, 238) !important;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;

export const SelectableMenuItem: React.FC<SelectableMenuItemProps> = ({
  isSelected,
  onClick,
  children,
}) => (
  <StyledMenuItem
    onClick={onClick}
    style={{
      backgroundColor: isSelected ? "rgb(236, 226, 249)" : "transparent",
    }}
  >
    <Flex
      gap={16}
      justify={"space-between"}
      align={"center"}
      style={{ width: "100%" }}
    >
      {children}
      {isSelected ? <Icon variant={"IconCheck"} size={"mini"} /> : null}
    </Flex>
  </StyledMenuItem>
);
