import { useEffect } from "react";

import { usePersonalization, useVerification } from "~src/customHooks";
import { ProfileSummary } from "../../../ProfileSummary";
import { PanelBlockContainer } from "../../Shared";
import { Company } from "./Company";

import { useAppDispatch } from "~src/redux/hooks";
import { verificationsAction } from "~src/redux/verification";
import { NewsAndEvents } from "../Company";

export const PersonalizationFeed = () => {
  const {
    currentPersonalization: {
      profile: { email, loading },
    },
  } = usePersonalization();
  const { isLoading, isInvalid, isUnverified, isSeen, isValid } =
    useVerification();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (
      !isSeen(email) &&
      !isLoading(email) &&
      (isUnverified(email) || isInvalid(email) || (isValid(email) && !loading))
    ) {
      dispatch(verificationsAction.setSeen({ email }));
    }
  }, [
    dispatch,
    email,
    isSeen,
    isLoading,
    isUnverified,
    isValid,
    loading,
    isInvalid,
  ]);

  return (
    <PanelBlockContainer>
      <ProfileSummary />
      <Company />
      <NewsAndEvents />
    </PanelBlockContainer>
  );
};
