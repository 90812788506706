export const shouldShow = (text?: string, search?: string) => {
  return !!(
    !search ||
    (search.length > 0 && text?.toLowerCase().includes(search.toLowerCase()))
  );
};

export const fieldExists = (fieldObj) => {
  let exists = false;
  Object.keys(fieldObj).forEach((key) => {
    if (fieldObj[key]?.trim().length > 0) exists = true;
  });
  return exists;
};

export const dataExists = (dataObj: object | undefined) => {
  if (!dataObj) return false;

  let exists = false;
  Object.keys(dataObj).forEach((key) => {
    const dataCategory = dataObj[key];
    if (!dataCategory) return;
    Object.keys(dataCategory).forEach((key) => {
      const dataField = dataCategory[key];
      if (dataField?.trim().length > 0) exists = true;
    });
  });
  return exists;
};
