import { forwardRef, useEffect, useRef, useState } from "react";

import { StyledSlideChild, StyledSlideContainer } from "../Styled";

type Stage = "reset" | "active" | "next" | "previous" | undefined;

interface SlideContainerProps {
  children: React.ReactNode;
}

type Ref = HTMLDivElement;

export const SlideContainer = forwardRef<Ref, SlideContainerProps>(
  ({ children }, ref) => {
    return (
      <StyledSlideContainer className="lv-slide-container" ref={ref}>
        {children}
      </StyledSlideContainer>
    );
  }
);

SlideContainer.displayName = "SlideContainer";

interface SlideChildProps extends SlideContainerProps {
  stage: "active" | "next" | "previous" | "reset";
  noSlide?: boolean;
}

export const SlideChild = forwardRef<Ref, SlideChildProps>(
  ({ children, stage, noSlide }, ref) => {
    const [visible, setVisible] = useState(false);
    const [slidingIn, setSlidingIn] = useState(false);
    const [slidingOut, setSlidingOut] = useState(false);
    const [layer, setLayer] = useState("bottom");

    const prevActiveRef = useRef<Stage>();
    useEffect(() => {
      prevActiveRef.current = stage;
    });
    const prevStage = prevActiveRef.current;

    useEffect(() => {
      if (
        (prevStage === "next" ||
          prevStage === undefined ||
          prevStage === "reset") &&
        stage === "active"
      ) {
        //Active <- Next
        // SLIDE IN
        setVisible(true);
        setLayer("top");
        setSlidingIn(true);
        setSlidingOut(false);
      } else if (prevStage === "active" && stage === "next") {
        //Active -> Next
        setSlidingOut(false);
        setLayer("top");
        setSlidingIn(false);
        setTimeout(() => {
          //hide after animation
          setVisible(false);
        }, 420);
      } else if (prevStage === "active" && stage === "previous") {
        //Previous <- Active
        // SLIDE OUT but don't slide back
        setSlidingOut(true);
        setLayer("bottom");
        setSlidingIn(false);
      } else if (prevStage === "previous" && stage === "active") {
        //Previous -> Active
        // SLIDE back in
        setVisible(true);
        setLayer("bottom");
        setSlidingOut(false);
        setSlidingIn(true);
      } else if (prevStage === "active" && stage === "reset") {
        //Active -> Next
        // SLIDE OUT the secretly move to next
        setSlidingOut(true);
        setLayer("bottom");
        setSlidingIn(false);
        setTimeout(() => {
          //hide after animation
          setVisible(false);
          setSlidingOut(false);
        }, 420);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stage]);

    useEffect(() => {
      // @ts-expect-error needs fix
      if (prevStage === undefined && stage === true) {
        setVisible(true);
        setSlidingIn(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const classList = ["lv-slide-default"];
    // @ts-expect-error needs fix
    if (prevStage === undefined && stage === true) {
      classList.push("lv-slide-in");
    }
    if (slidingIn) {
      classList.push("lv-slide-in");
    }
    if (slidingOut) {
      classList.push("lv-slide-out");
    }
    if (noSlide) {
      classList.push("no-slide");
    }
    classList.push(layer);
    return (
      <StyledSlideChild className={classList.join(" ")} ref={ref}>
        {visible ? children : null}
      </StyledSlideChild>
    );
  }
);

SlideChild.displayName = "SlideChild";
