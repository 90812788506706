import { Dropdown, DropdownProps } from "@lavender-ai/lav-components";
import { EmailAnalysisStage as Stage } from "~src/redux/typings";
import { StyledDropdownContainer } from "~src/component/Styled";

const STAGE_ITEMS = [
  {
    children: "Cold",
    key: Stage.cold,
  },
  {
    children: "Follow Up",
    key: Stage.followUp,
  },
  {
    children: "Reply",
    key: Stage.reply,
  },
];

interface Props extends Pick<DropdownProps, "placement" | "variant"> {
  stage: Stage;
  isDisabled?: boolean;
  onSelect: ({ key }: { key: string }) => void;
}

export const StageDropdown = ({
  stage,
  isDisabled,
  onSelect,
  placement,
  variant,
}: Props) => {
  const currentObject =
    STAGE_ITEMS.find((s) => s.key === Stage[stage]) || STAGE_ITEMS[0];
  return (
    <StyledDropdownContainer>
      <Dropdown
        disabled={isDisabled}
        placement={placement}
        menuProps={{
          selectable: true,
          selectedKeys: [stage],
          onSelect,
          items: STAGE_ITEMS,
          style: {
            width: 100,
          },
        }}
        size="small"
        variant={variant}
      >
        {currentObject?.children}
      </Dropdown>
    </StyledDropdownContainer>
  );
};
