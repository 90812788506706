import { DEFAULT_CONTAINER_CLASS } from "~src/constants";
import { ComposeContainerMetadata, selectors } from "~src/redux/typings";

// isRootComposeContainer determines if the compose container is an initialized root container, differenetiating it from
// emailContainer items inside it if it is a thread.
export const isRootComposeContainer = (
  emailContainer: HTMLElement | HTMLIFrameElement,
  composeContainerMetadata: ComposeContainerMetadata
) => {
  const rootClass = getRootComposeContainerLavClass(composeContainerMetadata);
  return (
    emailContainer.classList.contains(rootClass) &&
    emailContainer.querySelector(".lv-menu") !== null
  );
};

// getRootComposeContainerLavClass selects the expected class of the root compose container.
const getRootComposeContainerLavClass = (composeContainerMetadata: ComposeContainerMetadata) => {
  return composeContainerMetadata.container.containerCSSLavClass ?? DEFAULT_CONTAINER_CLASS;
};

export const matchComposeContainerMetadata = (
  emailContainer: HTMLElement | HTMLIFrameElement,
  allSelectors: selectors
): ComposeContainerMetadata | undefined => {
  return allSelectors.composeContainers.find((map) =>
    emailContainer.matches(map.container.containerSelector)
  );
};
