import { memo, useState, useCallback, useMemo, useEffect } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import {
  usePersonalization,
  useRankedFeedApi,
  useRankedFeedApiDependencies,
} from "~src/customHooks";
import { PanelListContainer } from "~src/layout/MainPanel/PanelBlock";

import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import { MAX_ICEBREAKERS } from "~src/constants";
import { PersonalizationEvent } from "~src/redux/typings";
import { CART_HEADER } from "~src/strings";
import {
  getAllItems,
  icebreakerCount,
  segmentEvent,
  SegmentEventNames,
  SegmentEventLocations,
  isItemInCart,
} from "~src/utils";

import { StyledStartMyEmailModalItem } from "../Styled";
import { CartItem } from "./CartItem";

interface Props {
  hasFormItem?: boolean;
}

export const Cart = memo(function Cart({ hasFormItem }: Props) {
  const {
    addItemToCartContext,
    currentPersonalization: {
      cart,
      profile: { email },
    },
    removeItemFromCartContext,
    resetCartContext,
  } = usePersonalization();
  const {
    data: { openedBy },
    modalType,
    next,
    reset,
  } = useCarouselContext();
  const [expanded, setExpanded] = useState<string>("");

  const { areDependenciesLoading } = useRankedFeedApiDependencies(email);

  const {
    data: { rankedFeed },
  } = useRankedFeedApi(email, areDependenciesLoading);
  const feedCount = icebreakerCount(rankedFeed);

  useEffect(() => {
    if (
      [ModalType.Cart, ModalType.CartEmptied].includes(ModalType[modalType]) &&
      (!openedBy || openedBy !== email)
    ) {
      reset();
      resetCartContext();
    }
  }, [openedBy, email, feedCount, modalType, resetCartContext, reset]);

  const cartCount = icebreakerCount(cart);
  const onSelect = useCallback(
    (e: CheckboxChangeEvent, item: PersonalizationEvent) => {
      e.stopPropagation();
      const { checked } = e.target;
      if (checked) {
        addItemToCartContext(item);
      } else {
        removeItemFromCartContext(item);
        if (cartCount === 1) {
          next(ModalType.CartEmptied, {
            openedBy: email,
            phase: CART_HEADER.VALUE_PROPS,
          });
        }
      }
      segmentEvent(
        SegmentEventNames.CheckboxClicked,
        SegmentEventLocations.StartMyEmail
      );
    },
    [addItemToCartContext, removeItemFromCartContext, cartCount, next, email]
  );

  const entity = modalType === ModalType.CartEmptied ? rankedFeed : cart;
  const allItems = useMemo(() => getAllItems(entity), [entity]);
  const onExpand = useCallback((id: string) => {
    setExpanded((prev) => (prev === id ? "" : id));
  }, []);

  const renderItems = useMemo(
    () =>
      allItems.map((item, i) => {
        const isExpanded = expanded === item.id;
        const isSelected = isItemInCart(cart, item);
        const isDisabled = cartCount >= MAX_ICEBREAKERS && !isSelected;
        return (
          <StyledStartMyEmailModalItem
            key={item.id}
            $padding={hasFormItem ? "16px 12px 12px" : "16px 12px"}
          >
            <PanelListContainer>
              <CartItem
                hasFormItem={hasFormItem}
                index={i}
                item={item}
                isDisabled={isDisabled}
                isExpanded={isExpanded}
                isSelected={isSelected}
                onExpand={onExpand}
                onSelect={onSelect}
              />
            </PanelListContainer>
          </StyledStartMyEmailModalItem>
        );
      }),
    [allItems, cart, cartCount, expanded, hasFormItem, onExpand, onSelect]
  );

  return renderItems;
});
