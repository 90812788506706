import { Root } from "react-dom/client";
import { CreateEmailThreadRoot } from "~src/layout";
import { store } from "~src/redux";

const processedElements = new Map<Element, Root | undefined>();

export const listenForNewEmailThreads = () => {
  const {
    openEmailThreadSelector,
    openEmailThreadParentSelector,
    openEmailPixelSelector,
    emailThreadContainer,
  } = store.getState().config.settings?.selectors ?? {};

  if (
    !openEmailThreadSelector ||
    !openEmailThreadParentSelector ||
    !openEmailPixelSelector ||
    !emailThreadContainer
  ) {
    return;
  }

  const openThreadEmailList = document.querySelectorAll(openEmailThreadSelector);
  if (openThreadEmailList.length < 1) {
    return;
  }

  const parentEmailContainers = new Map<Element, Element | null>();
  const emailThreadContainers = new Map<Element, Element | null>();
  openThreadEmailList.forEach((openThreadEmail) => {
    // Skip already processed elements
    if (processedElements.has(openThreadEmail)) {
      return;
    }

    // Cache and retrieve the parent email container
    let parentEmailContainer = parentEmailContainers.get(openThreadEmail);
    if (!parentEmailContainer) {
      parentEmailContainer = openThreadEmail.closest(openEmailThreadParentSelector);
      if (!parentEmailContainer) {
        return;
      }
      parentEmailContainers.set(openThreadEmail, parentEmailContainer);
    }

    // Cache and retrieve the container DOM element
    let containerDom = emailThreadContainers.get(parentEmailContainer);
    if (!containerDom) {
      containerDom = parentEmailContainer.querySelector<HTMLElement>(emailThreadContainer);
      if (!containerDom) {
        return;
      }
      emailThreadContainers.set(parentEmailContainer, containerDom);
    }

    // Get the pixel ID
    let pixelId = "";
    //cloned because we dont want to delete from DOM
    const openThreadEmailClone = openThreadEmail.cloneNode(true) as Element;
    RemoveReplyFWDNodes(openThreadEmailClone);
    const trackingPixelDom = openThreadEmailClone.querySelector(openEmailPixelSelector);
    if (trackingPixelDom !== null) {
      //todo: what about multiple in thread?
      const attribute = trackingPixelDom.getAttribute("alt");
      if (attribute !== null) {
        const split = attribute.split("ltp|");
        if (split.length > 1) {
          pixelId = split[1];
        }
      }
    }

    const root = CreateEmailThreadRoot(
      parentEmailContainer as HTMLElement,
      containerDom as HTMLElement,
      pixelId
    );

    // Mark this element as processed
    processedElements.set(openThreadEmail, root);
  });

  const platform = store.getState().config.settings?.platform;
  // Call root.unmount on any panels that are no longer in the DOM
  if (platform === "outlookNative") {
    return;
  }
  [...processedElements.entries()].forEach(([emailContainer, reactRoot]) => {
    if (emailContainer.checkVisibility && !emailContainer.checkVisibility()) {
      reactRoot?.unmount();
      processedElements.delete(emailContainer);
    }
  });
};

export const RemoveReplyFWDNodes = (clonedContainer: Element) => {
  const replyFwdSelectors = [
    "div.gmail_quote",
    ".x_gmail_quote",
    "#divRplyFwdMsg",
    ".gmail_extra",
    "div.gmail_quote ~ div[dir='ltr']",
    ".sl-reply-body",
    ".outreach-quote",
  ];
  const replyFwdSelector = replyFwdSelectors.join(", ");
  const replyFwdContainer = clonedContainer.querySelectorAll(replyFwdSelector);
  if (replyFwdContainer.length > 0) {
    replyFwdContainer.forEach((element) => {
      element.remove();
    });
  }
};
