import { useMemo } from "react";
import { Flex } from "antd";
import SkeletonInput from "antd/lib/skeleton/Input";

import { Search } from "@lavender-ai/lav-components";
import { SecondaryText } from "../../Styled";
import { LoadableText } from "../../Utilities/Loading/LoadableText";

interface CountConfig {
  count: number;
  showZeroCount?: boolean;
  formatter: (count: number) => string;
}

interface Props {
  countConfig?: CountConfig;
  disabled?: boolean;
  handleSearch: (searchTerm: string) => void;
  loading?: boolean;
  placeholder: string;
  searchTerm: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
}

export const SearchInput = ({
  countConfig,
  disabled,
  handleSearch,
  loading,
  placeholder,
  searchTerm,
  style,
  fullWidth,
}: Props) => {
  const [flexStyle, searchStyle] = useMemo(() => {
    return fullWidth
      ? [
          { width: "100%" },
          {
            width: "calc(100% + 24px)",
            margin: "8px 0 0 -12px",
          },
        ]
      : [{}, {}];
  }, [fullWidth]);

  return (
    <Flex vertical gap="middle" style={flexStyle}>
      {loading ? (
        <SkeletonInput
          active
          block
          className="lav-panel-search"
          style={{
            borderRadius: "8px",
            ...flexStyle,
            ...style,
          }}
        />
      ) : (
        <Search
          className="lav-panel-search"
          disabled={disabled}
          placeholder={placeholder}
          size="medium"
          value={searchTerm}
          style={{
            ...searchStyle,
            ...style,
          }}
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
        />
      )}
      {countConfig !== undefined &&
        (loading || countConfig.count > 0 || countConfig.showZeroCount) && (
          <div className="lv-panel-list-count">
            <SecondaryText size="subtext1">
              <LoadableText
                height={"10.5px"}
                loading={loading}
                text={countConfig.formatter(countConfig.count)}
              />
            </SecondaryText>
          </div>
        )}
    </Flex>
  );
};
