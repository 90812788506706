import { PayloadAction } from "@reduxjs/toolkit";

import { emailAction, updateAndVerifyRecipients } from "~src/redux";
import { store, type RootState, type AppDispatch } from "~src/redux/store";
import { UpdateBody, UpdateSubject, UpdateToAddress } from "~src/redux/typings";
import {
  getElementListValue,
  getElementValue,
  isValidEmailAddressList,
  isValidSubject,
  isValidBody,
  getBodyElement,
} from ".";

export const getUserEmailData = (
  container: HTMLElement,
  lvIdentifier: lvIdentifier
) => {
  const state = store.getState();

  const { addressToInterval, subjectInterval, bodyInterval } =
    state.config.settings?.timing ?? {};
  const {
    addressToSelector,
    addressToAttrName,
    subjectSelector,
    subjectAttrName,
    bodyIframe,
    bodySelector,
    bodyAttrName,
  } = state.config.settings?.selectors ?? {};

  const addressToConfig: SelectorConfig = {
    childSelector: addressToSelector ?? "",
    attrName: addressToAttrName ?? "",
    delay:
      typeof addressToInterval === "number"
        ? addressToInterval
        : parseInt(addressToInterval ?? "0", 10),
    isValid: isValidEmailAddressList,
    storeFieldName: "toAddress",
    action: saveEmailToAddress,
    validInput: validInputToAddress,
    isList: true,
    parseValue: parseEmailAddress,
  };
  watchEmailField(container, lvIdentifier, addressToConfig);

  const subjectConfig: SelectorConfig = {
    childSelector: subjectSelector ?? "",
    attrName: subjectAttrName ?? "",
    delay:
      typeof subjectInterval === "number"
        ? subjectInterval
        : parseInt(subjectInterval ?? "0", 10),
    isValid: isValidSubject,
    storeFieldName: "subject",
    action: saveEmailSubject,
    validInput: validInputString,
    isList: false,
  };
  watchEmailField(container, lvIdentifier, subjectConfig);

  const bodyConfig: SelectorConfig = {
    iframeSelector: bodyIframe,
    childSelector: bodySelector ?? "",
    attrName: bodyAttrName ?? "",
    delay:
      typeof bodyInterval === "number"
        ? bodyInterval
        : parseInt(bodyInterval ?? "0", 10),
    isValid: isValidBody,
    storeFieldName: "body",
    action: saveEmailBody,
    validInput: validInputString,
    isList: false,
    iframeChildSelector: state.config.settings?.selectors?.iframeChildSelector,
  };
  watchEmailField(container, lvIdentifier, bodyConfig);
};

// INFO : SalesLoft use full names in email address feild,
// we need to extract them. For example "Casey Corvino <casey@sorter.com>" but Gmail will have just "casey@sorter.com"
const parseEmailAddress = (rawInput: string): string => {
  const regex = /<([^>]+)>|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
  const match = rawInput.match(regex);

  if (match) {
    // Use match[1] if email is in <>, else use match[2]
    return match[1] || match[2];
  }
  return "";
};

// INFO: For email subject and body we store a single string value
const validInputString = (storedValue: string, userInput: string): boolean => {
  return !(storedValue === userInput);
};

// INFO: Compare the stored list of email address to the list of email address in the email field
const validInputToAddress = (
  storedValueList: string[] = [],
  userInputArray: string[]
): boolean => {
  // INFO: there is no stored value and polling is grabbing an empty value then don't update the store
  if (storedValueList.length === 0 && userInputArray.length === 0) {
    return false;
  }
  // INFO: If the user has not entered a value yet or the user has cleared the value then it is valid
  if (storedValueList.length === 0 || userInputArray.length === 0) {
    return true;
  }
  // INFO: If the user has entered a value and the value is not the same as the stored value then it is valid
  return !compareList(storedValueList, userInputArray);
};
const saveEmailToAddress =
  (id: string, emailToAddress: string[]) => async (dispatch: AppDispatch) => {
    return await dispatch(
      updateAndVerifyRecipients({
        id,
        toAddress: emailToAddress,
      })
    );
  };
const saveEmailSubject = (id: string, subjectString: string) => {
  return emailAction.updateSubjectString({
    id,
    subject: subjectString,
  });
};
const saveEmailBody = (id: string, bodyString: string) => {
  return emailAction.updateBodyString({
    id,
    body: bodyString,
  });
};
interface SelectorConfig {
  iframeSelector?: string;
  iframeChildSelector?: string;
  childSelector: string;
  attrName: string;
  delay: number;
  isValid;
  storeFieldName: string;
  action;
  validInput;
  isList: boolean;
  parseValue?;
}
export const watchEmailField = (
  container: HTMLElement,
  id: lvIdentifier,
  config: SelectorConfig
) => {
  let state = store.getState();
  // INFO : Polling for To Email Field
  const intervalId = setInterval(() => {
    // INFO: In Gmail when the user close an email dialog to cancel the email whe need to stop polling and clean up the store.
    // this can also happen if the website is a SPA and goes to a new url route via react router etc..
    // TODO: Think about how to clear all the components like Main Panel and others Outside of this setInterval
    if (!container.isConnected && state.emailData[id] !== undefined) {
      store.dispatch(emailAction.deleteEmailData(id));
      state = store.getState();
      clearInterval(intervalId);
    } else {
      if (config.isList) {
        onUpdateArrayValues(container, config, id);
      } else {
        onUpdateStringValue(container, config, id);
      }
      checkSubjectAndBodyPause(id);
    }
  }, config.delay);
};

// INFO: If the user has paused typing in the email subject or body then we update the store
const checkSubjectAndBodyPause = (id: string) => {
  let state = store.getState();
  const subjectPauseWaitTime =
    state.config.settings?.timing.subjectPauseWaitTime;
  const bodyPauseWaitTime = state.config.settings?.timing.bodyPauseWaitTime;
  const emailData = state.emailData[id];
  if (!emailData) return;
  const {
    subjectLastUpdated,
    bodyLastUpdated,
    hasSubjectPaused,
    hasBodyPaused,
  } = emailData;
  const currentTime = Date.now();
  const totalSubjectPauseTime = currentTime - subjectLastUpdated;
  const totalBodyPauseTime = currentTime - bodyLastUpdated;

  // INFO: Check if the user has paused typing in the email subject.
  if (subjectPauseWaitTime) {
    if (totalSubjectPauseTime >= subjectPauseWaitTime) {
      if (!hasSubjectPaused) {
        store.dispatch(emailAction.pauseSubject(id));
        state = store.getState();
      }
    }
  }
  // INFO: Check if the user has paused typing in the email body.
  if (bodyPauseWaitTime) {
    if (totalBodyPauseTime >= bodyPauseWaitTime) {
      if (!hasBodyPaused) {
        store.dispatch(emailAction.pauseBody(id));
        state = store.getState();
      }
    }
  }
};

/**
 * Tracks recipient bar
 */
const onUpdateArrayValues = (
  container: HTMLElement,
  config: SelectorConfig,
  id: lvIdentifier
) => {
  let state = store.getState();
  const {
    childSelector,
    attrName,
    isValid,
    storeFieldName,
    action,
    validInput,
    parseValue,
  } = config;
  const nodeList = container.querySelectorAll(childSelector);
  // INFO :  If email field HTMLElement exist after a user input a value or HTMLElement exist at page load
  if (nodeList.length > 0) {
    // INFO : Determine on how the website stores the email value
    const elementList = Array.from(nodeList) as HTMLElement[];
    let valueList;
    if (
      state.emailData[id]?.[storeFieldName].length === 0 &&
      attrName.includes("hovertext")
    ) {
      // prevent multiple hovers.  Will run until storeFieldName is filled when hover is present
      // only hubspot and outlook web
      valueList = getElementListValue(elementList, attrName);
    } else {
      //will continuously run and grab recipient data
      valueList = getElementListValue(elementList, attrName);
    }
    if (parseValue) {
      valueList = valueList.map((value) => parseValue(value));
    }
    // INFO: don't store the same email field data with the same email id
    if (
      isValid(valueList) &&
      validInput(state.emailData[id]?.[storeFieldName], valueList)
    ) {
      store.dispatch(action(id, valueList));
      state = store.getState();
    }
    // INFO : If the email field HTMLElement does not exist in the email container then we remove the data from the store
  } else {
    // INFO: don't update if the store value is already empty from initial state
    if (state.emailData[id]?.[storeFieldName]?.length) {
      clearEmailData(state, id, action, []);
    }
  }
};
const onUpdateStringValue = (
  container: HTMLElement,
  config: SelectorConfig,
  id: lvIdentifier
) => {
  let state = store.getState();
  const {
    childSelector,
    attrName,
    isValid,
    storeFieldName,
    action,
    validInput,
    parseValue,
    iframeSelector,
    iframeChildSelector,
  } = config;

  const element =
    storeFieldName === "body"
      ? getBodyElement(
          iframeSelector,
          childSelector,
          iframeChildSelector,
          container
        )
      : container.querySelector<HTMLElement>(childSelector);

  if (element !== null) {
    let value = getElementValue(element, attrName);
    if (parseValue) {
      value = parseValue(value);
    }
    // INFO: don't store the same email field data with the same email id
    if (
      isValid(value) &&
      validInput(state.emailData[id]?.[storeFieldName], value)
    ) {
      store.dispatch(action(id, value));
      state = store.getState();
    }
    // INFO : If the email field HTMLElement does not exist in the email container then we remove the data from the store
  } else {
    // INFO: don't update if the store value is already empty from initial state
    if (state.emailData[id]?.[storeFieldName] !== "") {
      clearEmailData(state, id, action, "");
    }
  }
};

const clearEmailData = (
  state: RootState,
  id: lvIdentifier,
  action: (
    id: lvIdentifier,
    clearValue: string | string[]
  ) => PayloadAction<UpdateBody | UpdateSubject | UpdateToAddress>,
  clearValue: string | string[]
) => {
  store.dispatch(action(id, clearValue));
  state = store.getState();
};

const compareList = (list1: string[], list2: string[]): boolean => {
  if (list1.length !== list2.length) {
    return false;
  }

  const sortedList1 = list1.slice().sort();
  const sortedList2 = list2.slice().sort();

  for (let i = 0; i < sortedList1.length; i++) {
    if (sortedList1[i] !== sortedList2[i]) {
      return false;
    }
  }

  return true;
};
