import { EventsList } from "~src/component/Organisms/Events";
import { WorkHistoryList } from "~src/component/Organisms/WorkHistory/WorkHistoryList";
import { PanelListContainer } from "~src/layout/MainPanel/PanelBlock";

import { PersonalizationEvent, WorkHistory } from "~src/redux/typings";
import { areExperienceItems } from "~src/typeGuards";

interface BaseProps {
  isLoading?: boolean;
}
interface NewsProps extends BaseProps {
  items: PersonalizationEvent[];
}

const News = ({ isLoading, items }: NewsProps) => {
  return (
    <PanelListContainer>
      <EventsList
        dense
        events={items}
        loading={isLoading}
        showCartAction
        showTags
      />
    </PanelListContainer>
  );
};

interface ExperienceProps extends BaseProps {
  items: WorkHistory[];
}

const Experience = ({ isLoading, items }: ExperienceProps) => {
  return (
    <PanelListContainer>
      <WorkHistoryList loading={isLoading} showCartAction workHistory={items} />
    </PanelListContainer>
  );
};

export const getComponentByType = (
  type: string,
  items: PersonalizationEvent[]
) => {
  switch (type) {
    case "news":
    case "tech_stack":
    case "job_openings":
    case "funding":
      return <News key={type} items={items} />;
    case "experience":
      return (
        <Experience key={type} items={areExperienceItems(items) ? items : []} />
      );
    default:
      return null;
  }
};
