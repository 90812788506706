import { Flex } from "antd";
import { styled } from "styled-components";

export const StyledDropdownContainer = styled(Flex)`
  padding: 0;
  flex: 1;
  white-space: nowrap;
  flex-direction: column;
  gap: 4px;
  > span {
    margin-right: 6px;
    display: none !important;
  }
  &:hover > span {
    display: flex !important;
  }
  li.ant-menu-item.lav-components-menu-item .ant-menu-title-content {
    white-space: nowrap;
    text-wrap: nowrap !important;
  }
`;
