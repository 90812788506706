export function filterUniqueByKey<T>(records: T[], key: keyof T) {
  const seen = new Set();
  return records.filter((rec) => {
    if (!seen.has(rec[key])) {
      seen.add(rec[key]);
      return true;
    }
    return false;
  });
}

export const EMPTY_ARRAY = [];

export const fallbackToEmptyArray = <T>(array: T[] | undefined | null) => {
  if (!Array.isArray(array)) {
    return EMPTY_ARRAY;
  }
  return array.length === 0 ? EMPTY_ARRAY : array;
};
