import { PersonalizationTechAggregateData } from "./personalization";

export interface ITechStack {
  techAggregate: PersonalizationTechAggregateData[];
  loading: boolean;
}

export type RecipientTechStackMap = Record<
  emailAddress,
  ITechStack | undefined
>;

export interface TechStackData {
  toAddresses: emailAddress[];
  techStackData: RecipientTechStackMap;
}
