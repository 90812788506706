import { createRoot } from "react-dom/client";
import { EditorMode } from "~src/layout/MainPanel/EmailCoachPanel/components/EmailCoach/components/EditorMode/EditorMode";
import { Recommendation } from "~src/redux/typings";

export const OpenLavenderAnywhereIframe = (
  chromeId: string,
  emailData: any,
  analysisData: any,
  highlightData?: Recommendation
) => {
  const root = document.createElement("div");
  root.id = "editor-mode-root";
  document.body.appendChild(root);
  createRoot(root).render(
    <EditorMode
      emailData={emailData}
      analysisData={analysisData}
      highlightData={highlightData}
      chromeId={chromeId}
    />
  );
};

export const OpenLavenderAnywherePopup = (
  chromeId: string,
  emailData: any,
  analysisData: any,
  highlightData?: Recommendation
) => {
  const width = 850;
  const height = 650;
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;
  const windowFeatures = `width=${width},height=${height},menubar=no,toolbar=no,location=no,status=no,left=${left},top=${top}`;
  const newWindow = window.open(
    process.env.BACKEND_API + "/website-demo?insert_js=true",
    "",
    windowFeatures
  );
  if (newWindow) {
    let i = 0;
    const interval = setInterval(() => {
      // not sure why window.onload failed
      const message = {
        subject: emailData?.subject || "",
        body: emailData?.body || "",
        to: emailData?.toAddress || [],
        writingStartTime: emailData?.writingStartTime || 0,
        time: new Date(),
        analysisData,
        lavender_anywhere_event: true,
        highlightData,
        chromeId,
      };

      newWindow.postMessage(message, process.env.BACKEND_API || "");
      i++;
      if (i > 20) {
        clearInterval(interval);
      }
    }, 200); // up to 4 seconds
    newWindow.focus();
    window.addEventListener("unload", function () {
      newWindow.close();
    });
    // Add an event listener to the main window to close the new window when the main window is closed
  } else {
    alert("Popup blocked. Please allow popups for Editor Mode.");
  }
};
