import { useMemo } from "react";

import { useMainLoopState } from ".";

export const useDecideExtensionToInit = ({
  chromeId,
  isNewExtensionEnabled,
  doesUserNeedLogin,
}: Pick<
  ReturnType<typeof useMainLoopState>,
  "chromeId" | "isNewExtensionEnabled" | "doesUserNeedLogin"
>) => {
  return useMemo<"legacy" | "new" | null>(() => {
    if (isNewExtensionEnabled === undefined && doesUserNeedLogin === undefined)
      return null;

    // if user account needs to log in return "legacy"
    if (doesUserNeedLogin) {
      return "legacy";
    }
    // If new extension is enabled and chromeId is present, return "new"
    else if (isNewExtensionEnabled && chromeId) {
      return "new";
    }
    // If new extension is disabled, return "legacy"
    else if (isNewExtensionEnabled === false) {
      return "legacy";
    }

    return null;
  }, [chromeId, isNewExtensionEnabled, doesUserNeedLogin]);
};
