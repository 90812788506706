import { Flex, FlexProps } from "antd";
import { css, styled } from "styled-components";

import { VISUALLY_HIDDEN_MIXIN } from "../../../component/Styled/mixin";
import { PropsForStyling } from "../../../component/typings";

const variantStyles = (variant: PanelBlockVariant | undefined) =>
  variant !== undefined &&
  {
    [PanelBlockVariant.grid]: css<BaseProps>`
      .lav-panel-grid-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, max-content));
        padding: 16px 20px 0 20px;
        justify-content: space-between;
        width: 100%;

        &:not(.--unverified) {
          gap: 8px;
        }

        &.--unverified {
          gap: 24px;
        }
      }
    `,
    [PanelBlockVariant.list]: css<BaseProps>`
      :has(.ant-empty) {
        min-height: 200px;
      }

      .lv-panel-list-load-more-button-container,
      .lv-panel-list-container {
        width: 100%;
      }

      .lv-panel-list-load-more-button-container {
        padding: 12px 20px;
      }

      .lv-panel-list-container {
        padding: 0 8px 8px 8px;

        .lv-panel-list-item-inner-container {
          border-bottom: 1px solid var(--lv-panel-background-color);
        }

        > .lv-panel-list-item {
          flex-direction: column;

          &.__card {
            border-radius: var(--radius-md);
            border: 1px solid var(--color-neutral-blue-100);
          }
        }
      }
    `,
    [PanelBlockVariant.table]: css<BaseProps>`
      .lv-panel-table-container {
        padding: 0 16px;
        width: 100%;

        &.--hidden {
          ${VISUALLY_HIDDEN_MIXIN}
        }

        .ant-table-tbody > tr:last-of-type td,
        .ant-table-tbody:has(.--last) > tr:last-of-type td {
          border-bottom: 1px solid transparent !important;
        }

        .ant-table-wrapper {
          .ant-table-tbody {
            > tr.ant-table-row {
              height: 40px;

              &:has(+ tr.ant-table-expanded-row) {
                > td.ant-table-cell {
                  border-bottom: 1px solid var(--color-neutral-blue-100);
                }
              }

              &:has(+ tr.ant-table-expanded-row .--expanded),
              &:has(+ tr.ant-table-expanded-row .--last) {
                > td.ant-table-cell {
                  border-bottom: 1px solid transparent !important;
                }
              }

              > td.ant-table-cell {
                &:first-child {
                  padding: 0 16px 0 8px;
                }
                &:not(:first-child):not(:last-child) {
                  padding: 0 16px 0 0;
                }
                &:last-child {
                  padding: 0 8px 0 0;
                }
              }
            }

            > tr.ant-table-expanded-row {
              > td.ant-table-cell {
                padding: 8px;
                .ant-row {
                  height: 24px;
                }
              }
            }
          }
        }
      }
    `,
  }[variant];

const variantProps = ({ variant, loading, ...props }: Props) =>
  variant === PanelBlockVariant.list
    ? {
        [PanelBlockVariant.list]: {
          justify: loading ? "flex-start" : "space-between",
          $variant: variant,
          ...props,
        },
      }[variant]
    : {
        $variant: variant,
        ...props,
      };

export const StyledPanelBlock = styled(Flex)
  .withConfig({
    shouldForwardProp: (prop) => !["loading", "variant"].includes(prop),
  })
  .attrs<Props>((props) => ({
    ...variantProps(props),
  }))<PropsForStyling<BaseProps>>`
  align-self: stretch;
  background: var(--color-base-White);
  border-radius: 16px;
  ${({ $variant }) => variantStyles($variant)}
  div:has(.lav-panel-search) {
    width: 100%;

    .lav-panel-search {
      padding: 0 12px;
    }
  }
  .lv-panel-list-count {
    padding: 0 12px 8px 20px;
  }
`;

export enum PanelBlockVariant {
  grid = "grid",
  list = "list",
  table = "table",
}

interface BaseProps {
  loading?: boolean;
  variant?: PanelBlockVariant;
}

export interface Props extends BaseProps, FlexProps {}

export const PanelBlock = ({
  className,
  loading,
  variant,
  ...flexProps
}: Props) => {
  return (
    <StyledPanelBlock
      align="flex-start"
      className={className}
      $loading={loading}
      $variant={variant}
      vertical
      {...flexProps}
    />
  );
};
