import { debugLogVariables } from "./debug";

export const profile = (
  label: string,
  variables?: object
): ((newVariables: object | undefined) => void) => {
  const start = performance.now();
  const logFunc = variables ? debugLogVariables : console.debug;
  logFunc(`Profiling started for ${label}`, variables);

  return (newVariables: object | undefined = variables) => {
    const end = performance.now();
    const duration = end - start;
    logFunc(
      `Profiling ended for ${label}: ${duration.toFixed(2)}ms`,
      newVariables
    );
  };
};
