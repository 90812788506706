import { useEffect, useState } from "react";

import { useUpgradePlanModalContext } from "~src/contexts";
import { useLvIdentifier } from "~src/customHooks";
import { mainPanelAction, useAppDispatch } from "~src/redux";

export const UpgradePanel = () => {
  const dispatch = useAppDispatch();
  const lvIdentifier = useLvIdentifier();
  const [hasModalOpened, setHasModalOpened] = useState(false);
  const { isModalOpen, openModal } = useUpgradePlanModalContext();

  // Open the modal on mount
  useEffect(openModal, [openModal]);

  // Track if the modal has been opened yet
  useEffect(() => {
    if (!hasModalOpened && isModalOpen) {
      setHasModalOpened(true);
    }
  }, [hasModalOpened, isModalOpen]);

  // Close the panel when the modal is closed
  useEffect(() => {
    if (!isModalOpen && hasModalOpened) {
      dispatch(mainPanelAction.close(lvIdentifier));
    }
  }, [dispatch, hasModalOpened, isModalOpen, lvIdentifier]);

  return (
    <div
      style={{
        backgroundColor: "#F6F8FC",
        padding: 24,
        width: "calc(375px - 48px)",
      }}
    />
  );
};
