import { useMemo } from "react";
import { Flex } from "antd";
import { styled } from "styled-components";

import { Avatar, Icon, Text } from "@lavender-ai/lav-components";

import { CENTERED_SVG_MIXIN } from "~src/component/Styled";
import { LavenderIcon } from "~src/component/typings/icon";

const FlexWrapper = styled(Flex)`
  ${CENTERED_SVG_MIXIN}
  z-index: 10;
  text-align: center;
`;

const StyledFadeStepperAvatar = styled(Avatar)`
  span.ant-avatar.lav-components-avatar-size-medium& {
    margin-top: -10px;
  }
`;

const StyledListItemAvatar = styled(Avatar)`
  span.ant-avatar.lav-components-avatar-size-medium& {
    height: 16px;
    width: 16px;
  }
`;

const StyledListItemText = styled(Text)`
  .lav-components-text& {
    font-size: 8.66px;
    line-height: 11.49px;
  }
`;

const StyledInitialStepper = styled(Flex)`
  background-color: var(--color-lavender-200);
  border-radius: 10.22px;
  padding: 12.7px;
  opacity: 0.3;
  width: max-content;
`;

const StyledStep = styled(Flex)`
  .lv-step-spinner-avatar {
    flex-shrink: 0;
  }
`;

interface StepProps {
  text: string;
}

const Step = ({ text }: StepProps) => (
  <StyledStep align="center" gap={7.39}>
    <StyledListItemAvatar
      color="pear"
      className="lv-step-spinner-avatar"
      icon={<Icon size="mini" variant={LavenderIcon.IconCheck} />}
      type="soft"
    />
    <StyledListItemText size="body1">{text}</StyledListItemText>
  </StyledStep>
);

const FadeStepperIcon = () => {
  return (
    <StyledFadeStepperAvatar
      color="pear"
      size="medium"
      icon={<Icon size="medium" variant={LavenderIcon.IconCheck} />}
    />
  );
};

interface Props {
  steps: string[];
}

export const FadeStepper = ({ steps }: Props) => {
  const renderSteps = useMemo(
    () => steps.map((text, index) => <Step key={index} text={text} />),
    [steps]
  );

  return (
    <FlexWrapper align="center" vertical>
      <StyledInitialStepper align="flex-start" gap={6.39} vertical>
        {renderSteps}
      </StyledInitialStepper>
      <FadeStepperIcon />
    </FlexWrapper>
  );
};
