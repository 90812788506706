import { css, styled } from "styled-components";
import { Badge, BadgeProps } from "@lavender-ai/lav-components";

import { SKELETON_GRADIENT_MIXIN } from "~src/component/Molecules/Message/styles";
import { PropsForStyling } from "~src/component/typings";

interface StyledLoadableTextProps {
  color?: string;
  loading?: boolean;
}

const StyledLoadableBadge = styled(Badge)<
  PropsForStyling<StyledLoadableTextProps>
>`
  span.ant-tag.lav-components-badge.lav-components-badge-variant-dark&,
  span.ant-tag.lav-components-badge.lav-components-badge-variant-accent& {
    ${({ $loading: loading }) =>
      loading &&
      css`
        background-color: transparent !important;
        border-color: transparent;
        border-radius: 15px;
        color: transparent;
        display: inline-block;
        pointer-events: none;
        ${SKELETON_GRADIENT_MIXIN}
      `};

    span {
        ${({ $loading: loading }) =>
          loading &&
          css`
            color: transparent !important;
          `}
  }
`;

interface Props extends BadgeProps {
  isLoading?: boolean;
  text: string;
}

export const LoadableBadge = ({ isLoading, text, ...badgeProps }: Props) => (
  <StyledLoadableBadge {...badgeProps} $loading={isLoading}>
    {text}
  </StyledLoadableBadge>
);
