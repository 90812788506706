import { useAppSelector } from "~src/redux";
import { useState } from "react";
import { LAVENDER_ANYWHERE_URL } from "~src/constants";

export const MainPanelWrapper = () => {
  const isOpen = useAppSelector((state) => state.lavenderAnywhere.isOpen);
  const [hasOpened, setHasOpened] = useState(false);

  if (isOpen && !hasOpened) {
    // only loads the extension on first load.
    setHasOpened(true);
  }

  return (
    <iframe
      width={isOpen ? 850 : 0}
      style={{
        height: "100%",
        transition: "width 300ms ease-in-out",
        background: "#f1f2f6",
        border: "none",
      }}
      src={hasOpened ? LAVENDER_ANYWHERE_URL : ""}
    />
  );
};
