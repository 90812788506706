import { Flex } from "antd";
import { Button, PaywallBanner, Text } from "@lavender-ai/lav-components";

import {
  mainPanelAction,
  selectFeatures,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";
import { useLvIdentifier } from "~src/customHooks";
import { FRONTEND_URLS } from "~src/constants";

export const WatchPaywall = () => {
  const dispatch = useAppDispatch();
  const lvIdentifier = useLvIdentifier();
  const { in_app_plan_upgrade_modal: showUpgradeModal } =
    useAppSelector(selectFeatures);

  const upgradeAction = () => {
    if (showUpgradeModal) {
      dispatch(mainPanelAction.open(lvIdentifier));
      dispatch(mainPanelAction.setView({ lvIdentifier, view: "upgrade" }));
    } else {
      window.open(`${FRONTEND_URLS.dashboard}/app/settings/billing`, "_blank");
    }
  };

  return (
    <PaywallBanner
      variant="pear"
      style={{
        gap: 10,
        flexDirection: "column",
        padding: 8,
        alignItems: "start",
      }}
    >
      <Flex justify="center" align="center">
        <Text size="subtext2">
          Unlock more personalized data, in an instant!
        </Text>
      </Flex>
      <Button
        prefix="IconLockOpen"
        prefixColor="white"
        fullWidth={false}
        size="small"
        variant="primary"
        onClick={upgradeAction}
      >
        <div style={{ marginLeft: -4 }}>Upgrade</div>
      </Button>
    </PaywallBanner>
  );
};
