import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { ICRM } from "~src/redux/typings";

interface Props {
  children: React.ReactNode;
}

export interface ICRMContext {
  currentCRM: ICRM;
  resetCRMContext: () => void;
  setCRMContext: (newCRM: ICRM) => void;
}

const initialState: ICRM = {
  hasGongAccess: false,
  hasSalesforceAccess: false,
  hasHubspotAccess: false,
  gong_data: [],
  hubspot_data: undefined,
  salesforce_data: undefined,
  loading: false,
};

const initCRMContext: ICRMContext = {
  currentCRM: initialState,
  resetCRMContext: () => {
    /* empty */
  },
  setCRMContext: (_newCRM: ICRM) => {
    /* empty */
  },
};

const CRMContext = createContext(initCRMContext);

export const CRMContextProvider = ({ children }: Props) => {
  const [crm, setCRM] = useState<ICRM>(initialState);

  const resetCRMContext = useCallback(() => {
    setCRM(initialState);
  }, []);

  const setCRMContext = useCallback((newCRM: ICRM) => {
    setCRM(newCRM);
  }, []);

  const crmContext: ICRMContext = useMemo(
    () => ({
      currentCRM: crm,
      resetCRMContext,
      setCRMContext,
    }),
    [crm, resetCRMContext, setCRMContext]
  );

  return (
    <CRMContext.Provider value={crmContext}>{children}</CRMContext.Provider>
  );
};

export const useCRMContext = () => useContext(CRMContext);
