import {
  LavenderTagColorMap,
  LavenderTextColorMap,
  UnassignedTriggerColorMap,
} from "~src/component/typings";

// Assigning these colors in order, randomly
export const TagColorCount = Object.keys(LavenderTagColorMap).length / 2;
export const TextColorCount = Object.keys(LavenderTextColorMap).length / 2;
export const UnassignedTriggerColorCount =
  Object.keys(UnassignedTriggerColorMap).length / 2;
