import { useState } from "react";

import {
  Button,
  Icon,
  SegmentedControl,
  Text,
} from "@lavender-ai/lav-components";

import { SELECTABLE_PLANS, useUpgradePlanModalContext } from "~src/contexts";
import { BILLING_CYCLE } from "~src/constants";

import {
  LargePane,
  Container,
  Body,
  Header,
  HeaderContent,
  HeaderGradient,
  HeaderTextContent,
  PlanPicker,
  PlanTermContainer,
  PlanTable,
  PlanDetailsContainer,
  PlanDetailsTable,
  CTAContainer,
  EnvelopeImage,
  CloseButton,
  Teams10Features,
} from "./SelectUpgradePlanModal.styled";
import { PLAN_FEATURES } from "./constants";
import { PlanRow } from "./PlanRow";
import { PlanDetailsRow } from "./PlanDetailsRow";

export const SelectUpgradePlanModal = () => {
  const { closeModal, setSelectedPlan: setContextPlan } =
    useUpgradePlanModalContext();
  const [selectedBillingCycle, setSelectedBillingCycle] =
    useState<BILLING_CYCLE>(BILLING_CYCLE.monthly);
  const [selectedPlan, setSelectedPlan] = useState<SELECTABLE_PLANS>();

  const startUpgradeFlow =
    selectedPlan === SELECTABLE_PLANS.Teams
      ? () => window.open("https://lavender.ai/trial/team", "_blank")
      : setContextPlan;

  return (
    <Container>
      <LargePane>
        <Header>
          <HeaderGradient
            xmlns="http://www.w3.org/2000/svg"
            width="327"
            height="91"
            viewBox="0 0 327 91"
            fill="none"
          >
            <path
              d="M0 16C0 7.16345 7.16344 0 16 0H311C319.837 0 327 7.16344 327 16V85.0316C327 88.4534 323.642 90.9247 320.35 89.9929C301.931 84.7794 246.192 71.2115 164.964 71.2115C83.2511 71.2115 25.3982 84.9421 6.59351 90.0854C3.32108 90.9805 0 88.5252 0 85.1326V16Z"
              fill="url(#paint0_radial_13453_58940)"
            />
            <defs>
              <radialGradient
                id="paint0_radial_13453_58940"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(210.842 23) rotate(120.065) scale(93.525 147.786)"
              >
                <stop stopColor="#CDAFF3" />
                <stop offset="1" stopColor="#5E2DE9" />
              </radialGradient>
            </defs>
          </HeaderGradient>
          <CloseButton icon="IconX" size="small" onClick={closeModal} />
          <HeaderContent>
            <EnvelopeImage />
            <div style={{ position: "absolute", right: 71 }}>
              <Icon variant="IconUnion" fill="#FFEC8E" />
            </div>
            <HeaderTextContent>
              <Text
                size="subtext5"
                style={{ textAlign: "center", marginTop: 14 }}
              >
                Pro users are{" "}
                <span style={{ color: "var(--color-primary-lavender)" }}>
                  4x more
                </span>{" "}
                likely to hit 100 on Email Score!
              </Text>
            </HeaderTextContent>
          </HeaderContent>
        </Header>
        <Body>
          <PlanPicker>
            <PlanTermContainer>
              <Text size="subtext3">Choose Plan</Text>
              <SegmentedControl
                options={["Monthly", "Yearly"]}
                size="small"
                type="basic"
                value={
                  selectedBillingCycle === BILLING_CYCLE.annual
                    ? "Yearly"
                    : "Monthly"
                }
                onChange={(cycle) => {
                  setSelectedBillingCycle(
                    BILLING_CYCLE[cycle === "Yearly" ? "annual" : "monthly"]
                  );
                }}
              />
            </PlanTermContainer>
            <PlanTable>
              <PlanRow
                plan={SELECTABLE_PLANS.Starter}
                {...{ selectedBillingCycle, selectedPlan, setSelectedPlan }}
              />
              <PlanRow
                plan={SELECTABLE_PLANS.Pro}
                {...{ selectedBillingCycle, selectedPlan, setSelectedPlan }}
              />
              <PlanRow
                plan={SELECTABLE_PLANS.Teams}
                {...{ selectedBillingCycle, selectedPlan, setSelectedPlan }}
              />
            </PlanTable>
          </PlanPicker>
          {selectedPlan !== undefined && (
            <PlanDetailsContainer>
              <Text size="subtext3">Included in {selectedPlan} Plan</Text>
              <PlanDetailsTable>
                {PLAN_FEATURES[selectedPlan].map((feature) => (
                  <PlanDetailsRow key={feature} text={feature} />
                ))}
                {selectedPlan === SELECTABLE_PLANS.Starter &&
                  PLAN_FEATURES[SELECTABLE_PLANS.Pro]
                    .slice(PLAN_FEATURES.Starter.length)
                    .map((feature) => (
                      <PlanDetailsRow
                        key={feature}
                        text={feature}
                        missing={true}
                      />
                    ))}
                {selectedPlan === SELECTABLE_PLANS.Teams && (
                  <Teams10Features>
                    <Text
                      size="subtext2"
                      color="lavender"
                      style={{ marginBottom: -2 }}
                    >
                      Exclusive benefits for teams of 10+
                    </Text>
                    {PLAN_FEATURES["Teams10+"].map((feature) => (
                      <PlanDetailsRow key={feature} text={feature} />
                    ))}
                  </Teams10Features>
                )}
              </PlanDetailsTable>
            </PlanDetailsContainer>
          )}
        </Body>
      </LargePane>
      {selectedPlan !== undefined && (
        <CTAContainer>
          <Button
            fullWidth={true}
            prefix="IconSparkles"
            prefixColor="white"
            size="large"
            variant="primary"
            onClick={() => {
              startUpgradeFlow({
                cycle: selectedBillingCycle,
                plan: selectedPlan,
              });
            }}
          >
            {selectedPlan === SELECTABLE_PLANS.Teams
              ? "Contact Sales"
              : `Upgrade to ${selectedPlan}`}
          </Button>
        </CTAContainer>
      )}
    </Container>
  );
};
