import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { ErrorResponse } from "~src/api/typings";

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof error.message === "string"
  );
}

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return (
    typeof error === "object" &&
    error !== null &&
    "status" in error &&
    ("data" in error || "error" in error)
  );
}

export function isLavenderAPIError(error: unknown): error is ErrorResponse {
  return isErrorWithMessage(error) && "error" in error;
}
