import qs from "qs";

import { networkRequest, URLS } from "~src/api";

import type { EmailVerificationResponse } from "./typings";

export const emailVerification = async (
  emails: string[],
  chromeId: chromeId
): Promise<EmailVerificationResponse> => {
  const { data } = await networkRequest.post<EmailVerificationResponse>(
    URLS.emailVerification,
    {
      chrome_id: chromeId,
      "email[]": emails,
    },
    {
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    }
  );
  return data;
};
