import { Text } from "@lavender-ai/lav-components";
import styled from "styled-components";

import { useStreakInfo } from "./useStreakInfo";

export const StreakActivityCard = () => {
  const { currentStreakLength, emailsSent, longestStreak } = useStreakInfo();

  return (
    <Wrapper>
      <Container>
        <Header>
          <Text size="subtext1" color="white">
            Your Activity
          </Text>
        </Header>
        <ActivityContainer>
          <ActivityDataBar>
            <ActivityData data={emailsSent} header={"Emails Sent"} />
            <ActivityDivider />
            <ActivityData data={currentStreakLength} header={"Days Active"} />
            <ActivityDivider />
            <ActivityData data={longestStreak} header={"Longest Streak"} />
          </ActivityDataBar>
        </ActivityContainer>
      </Container>
    </Wrapper>
  );
};

const ActivityData = ({ data, header }: { data: number; header: string }) => {
  return (
    <StyledActivityData>
      <Text size="body1" style={{ color: "var(--color-lavender-50)" }}>
        {header}
      </Text>
      <Text size="header1" color="white">
        {data}
      </Text>
    </StyledActivityData>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-bottom: 74px;
`;

const Container = styled.div`
  display: flex;
  padding: var(--size-6, 6px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-6, 6px);
  align-self: stretch;
  border-radius: 24px;
  background: linear-gradient(
    180deg,
    rgba(131, 155, 237, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  backdrop-filter: blur(2px);
`;

const Header = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const ActivityContainer = styled.div`
  display: flex;
  padding: var(--size-24, 24px) 8px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: var(--size-24, 24px);
  align-self: stretch;
  border-radius: 19px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
`;

const ActivityDataBar = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
`;

const StyledActivityData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
`;

const ActivityDivider = styled.div`
  width: 1px;
  height: 27px;
  border-radius: radius/sm;
  opacity: 0.16;
  background: var(--color-base-Black, #000);
`;
