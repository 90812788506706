import styled from "styled-components";
import { Text } from "@lavender-ai/lav-components";

import { ConditionalSwitch } from "~src/component/Utilities";
import { useLvIdentifier } from "~src/customHooks";
import { selectEmailAnalysis, useAppSelector } from "~src/redux";

import { useScoreColorGroup, useSelectTones } from "../utils";

export const TonesDetected = () => {
  const { isSuccess } = useScoreColorGroup();
  const lvIdentifier = useLvIdentifier();
  const selectTones = useSelectTones();
  const {
    analysis: { top_tones: tones },
  } = useAppSelector(selectEmailAnalysis(lvIdentifier));

  return (
    !!tones?.length && (
      <TonesContainer $isSuccess={isSuccess}>
        <Text
          size="body1"
          style={{
            color: isSuccess ? "white" : "var(--color-neutral-purple-900)",
            textAlign: "center",
          }}
        >
          Tones detected:{" "}
          <ConditionalSwitch>
            {tones.length === 1 && (
              <ToneButton
                onClick={() => {
                  selectTones(tones[0]);
                }}
              >
                {tones[0]}
              </ToneButton>
            )}
            {tones.length === 2 && (
              <>
                <ToneButton
                  onClick={() => {
                    selectTones(tones[0]);
                  }}
                >
                  {tones[0]}
                </ToneButton>{" "}
                &{" "}
                <ToneButton
                  onClick={() => {
                    selectTones(tones[1]);
                  }}
                >
                  {tones[1]}
                </ToneButton>
              </>
            )}
            {tones.length > 2 && (
              // join together like analysisData.tones.join(", ") but with the last one being "&"
              <>
                {tones
                  .slice(0, -1)
                  .map((tone) => (
                    <ToneButton
                      onClick={() => {
                        selectTones(tone);
                      }}
                      key={tone}
                    >
                      {tone}
                    </ToneButton>
                  ))
                  .reduce((prev, curr) => (
                    <>
                      {prev}, {curr}
                    </>
                  ))}
                {" & "}
                <ToneButton
                  onClick={() => {
                    selectTones(tones[tones.length - 1]);
                  }}
                >
                  {tones[tones.length - 1]}
                </ToneButton>
              </>
            )}
          </ConditionalSwitch>
        </Text>
      </TonesContainer>
    )
  );
};

const TonesContainer = styled.div<{ $isSuccess: boolean }>`
  display: flex;
  ${({ $isSuccess }) => ($isSuccess ? "margin-bottom: 8px;" : "")}
  margin-top: 2px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const ToneButton = styled.span`
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
`;
