import { useEffect, useState } from "react";
import { Icon, Tooltip } from "@lavender-ai/lav-components";

import { LavenderIcon } from "~src/component/typings";
import { selectFeatures, selectPlatform, useAppSelector } from "~src/redux";
import { LiveLogo } from "~src/component/Molecules";
import { useStreakNotificationContext } from "~src/contexts";

import { useShowPanel } from "../utils";

export const LogoButton = () => {
  const platform = useAppSelector(selectPlatform);
  const { clearHasNewStreakLevel } = useStreakNotificationContext();
  const { streaks: streaksFF } = useAppSelector(selectFeatures);
  const showPanel = useShowPanel();

  useEffect(
    function resizeListener() {
      if (platform === "outlookNative") {
        return;
      }
      const handleResize = () => {
        setIsWindowSmall(window.innerWidth < 750);
      };

      window.addEventListener("resize", handleResize);

      // Cleanup
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    },
    [platform]
  );

  const [isWindowSmall, setIsWindowSmall] = useState(
    platform === "outlookNative" ? false : window.innerWidth < 750
  );

  return isWindowSmall ? (
    <Tooltip
      hideArrow
      title="Expand the window for a better experience"
      placement={"right"}
    >
      <Icon
        size={"large"}
        color={"warning"}
        variant={LavenderIcon.IconAlertTriangleFilled}
      />
    </Tooltip>
  ) : (
    <div
      onClick={() => {
        if (streaksFF) {
          showPanel(undefined, "streak");
          clearHasNewStreakLevel();
        }
      }}
    >
      <Tooltip hideArrow placement="right" title="Lavender.AI">
        <LiveLogo />
      </Tooltip>
    </div>
  );
};
