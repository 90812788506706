import { usePersonalization, useNews } from "~src/customHooks";
import { Events } from "../../../Events";

import { PERSONALIZATION_HEADER } from "~src/strings";

const { NEWS } = PERSONALIZATION_HEADER;

export const NewsAndEvents = () => {
  const { currentPersonalization } = usePersonalization();
  const { news } = useNews();
  const currentNews = news[currentPersonalization.profile.email];
  if (!currentNews) {
    return null;
  }
  return (
    <Events
      currentPersonalization={currentPersonalization}
      currentNews={currentNews}
      dense
      headerText={NEWS}
      showSource
    />
  );
};
