import { ModalType } from "~src/component/Organisms/Personalization/CarouselContextProvider";
import {
  GetPodcastsData,
  GongCall,
  JobEntityAttributeData,
  PersonalizationEvent,
  PersonalizationTechAggregateData,
} from "~src/redux/typings";
import { CompanyInvestment } from "~src/api/typings";
import { CompanySubTab } from "~src/component/typings";

import { GlobalSearchItem } from "./types";

export const convertTechStackToSearchItems = (
  techAggregate: PersonalizationTechAggregateData[]
): GlobalSearchItem[] =>
  techAggregate.reduce<GlobalSearchItem[]>(
    (items, { category, tech }) => [
      ...items,
      ...tech.map<GlobalSearchItem>((tech) => ({
        breadcrumbString: `Tech Stack > ${category.split("_").join(" ")}`,
        icon: "IconStack",
        title: tech.title,
        searchableTerms: [tech.title, tech.url],
        location: ModalType["Tech Stack"],
        data: tech,
      })),
    ],
    []
  );

export const convertNewsToSearchItems = (
  feed: PersonalizationEvent[],
  logo: string
): GlobalSearchItem[] =>
  feed.reduce<GlobalSearchItem[]>(
    (items, news) => [
      ...items,
      {
        breadcrumbString: `News`,
        icon: "IconNews",
        title: news.formattedTitle,
        searchableTerms: [news.formattedTitle, news.summary],
        location: ModalType.NewsEventsDetails,
        data: { ...news, fallbackLogo: logo },
      },
    ],
    []
  );

export const convertInvestmentsToSearchItems = (
  investments: CompanyInvestment[]
): GlobalSearchItem[] =>
  investments.reduce<GlobalSearchItem[]>(
    (items, { amount, series }) => [
      ...items,
      {
        breadcrumbString: `Funding`,
        icon: "IconReportMoney",
        title: series,
        searchableTerms: [amount.value ? `${amount.value}` : ""],
        location: CompanySubTab.FUNDING,
      },
    ],
    []
  );

export const convertJobsToSearchItems = (
  jobs: JobEntityAttributeData[]
): GlobalSearchItem[] =>
  jobs.reduce<GlobalSearchItem[]>(
    (items, job) => [
      ...items,
      {
        breadcrumbString: `Job openings`,
        icon: "IconWorldDollar",
        title: job.title,
        searchableTerms: [job.title, ...(job.additional_data.tags || [])],
        location: ModalType["Job Openings"],
        data: job,
      },
    ],
    []
  );

export const convertGongDataToSearchItems = (
  gongCalls: GongCall[]
): GlobalSearchItem[] =>
  gongCalls.reduce<GlobalSearchItem[]>(
    (items, call) => [
      ...items,
      {
        breadcrumbString: `Gong`,
        icon: "IconGongLogo",
        title: call.title,
        searchableTerms: [call.title, ...call.participants],
        location: ModalType.GongCallPanel,
        data: { call },
      },
    ],
    []
  );

export const convertPodcastsToSearchItems = (
  podcasts: GetPodcastsData
): GlobalSearchItem[] =>
  podcasts.reduce<GlobalSearchItem[]>(
    (items, podcast) => [
      ...items,
      {
        breadcrumbString: `Podcasts`,
        icon: "IconBroadcast",
        title: podcast.title_original,
        searchableTerms: [
          podcast.podcast.publisher_original,
          podcast.title_original,
        ],
        location: ModalType.PodcastDetails,
        data: podcast,
      },
    ],
    []
  );
