import { Flex } from "antd";

import { Tag, TagProps, Text } from "@lavender-ai/lav-components";

import { PanelListItem } from "~src/layout/MainPanel/PanelBlock";
import { IconAndText } from "~src/component/Atoms";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
} from "~src/component/Utilities/Loading";
import { useCarouselContext } from "../../../CarouselContextProvider";

import { getJobLocation, prettyDate } from "~src/utils";
import { JobEntityAttributeData } from "~src/redux/typings";
import { SecondaryText } from "~src/component/Styled";
import { LavenderTagColorMap, LavenderIcon } from "~src/component/typings";

const COLOR_MAP = {
  Engineering: LavenderTagColorMap[0],
  Sales: LavenderTagColorMap[1],
  Marketing: LavenderTagColorMap[2],
  Operations: LavenderTagColorMap[3],
  "Human Resources": LavenderTagColorMap[4],
  Design: LavenderTagColorMap[5],
  Finance: LavenderTagColorMap[0],
  Support: LavenderTagColorMap[1],
};

interface JobListProps {
  loading: boolean;
  jobs: JobEntityAttributeData[];
}

export const JobList = ({ jobs, loading }: JobListProps) => {
  const { next } = useCarouselContext();
  if (loading) {
    return (
      <>
        <PanelListItem>
          <CustomSkeleton variant={CustomSkeletonVariant.simpleListItem} />
        </PanelListItem>
        <PanelListItem>
          <CustomSkeleton variant={CustomSkeletonVariant.simpleListItem} />
        </PanelListItem>
      </>
    );
  }

  return (
    <>
      {jobs.map((job, idx) => {
        const location = getJobLocation(job);

        return (
          <div
            className="lv-panel-list-item-wrapper"
            key={idx}
            onClick={() => {
              next("Job Openings", job);
            }}
          >
            <PanelListItem dense hover>
              <div className="lv-panel-list-item-inner-container">
                <Flex vertical gap="middle">
                  <SecondaryText size="body1" $light>
                    {prettyDate(job.first_seen_at)}
                  </SecondaryText>
                  <Text size="body2">{job.title}</Text>
                  <Flex gap="small" align="center">
                    {job.category && (
                      <Tag
                        color={COLOR_MAP[job.category] as TagProps["color"]}
                        size="small"
                        variant={
                          ["Finance", "Support"].includes(job.category)
                            ? "1"
                            : "base"
                        }
                      >
                        {job.category}
                      </Tag>
                    )}
                    {location && (
                      <IconAndText
                        gap={2}
                        icon={LavenderIcon.IconMapPin}
                        iconSize="mini"
                        text={location}
                      />
                    )}
                  </Flex>
                </Flex>
              </div>
            </PanelListItem>
          </div>
        );
      })}
    </>
  );
};
