import { Text } from "@lavender-ai/lav-components";
import { styled } from "styled-components";
import { useAppSelector } from "~src/redux";
import { selectStreakThreshold } from "~src/redux/config";
import { useStreakInfo } from "./useStreakInfo";

export const StreakDescription = () => {
  const { workweekStreakLength } = useStreakInfo();

  return (
    <Container>
      <Text size="subtext5" color="white">
        {workweekStreakLength} Day Streak
      </Text>
      <Text
        size="body1"
        style={{
          color: "rgba(255, 255, 255, 0.80)",
        }}
      >
        <StreakSubtext />
      </Text>
    </Container>
  );
};

const StreakSubtext = () => {
  const { todayEmailsSent, workweekStreakLength } = useStreakInfo();
  const streakThreshold = useAppSelector(selectStreakThreshold);

  const emailsLeftForStreak = streakThreshold - todayEmailsSent;
  //if they have 4 days already sent this week, then it's Friday and they're looking to end their week streak
  const streakType = workweekStreakLength === 4 ? "weekly" : "daily";

  return emailsLeftForStreak < 1
    ? "You've hit your streak today! Keep it up!"
    : `You’re just ${emailsLeftForStreak} email${emailsLeftForStreak === 1 ? "" : "s"} away from hitting your ${streakType} streak, you’ve got this!!`;
};

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  width: 250px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;
