import { ErrorIdentifiers, parseAndLogError } from "~src/logger";
import { isStartMyEmailWebsocketJSONMessage } from "~src/typeGuards";

export const filterStartMyEmailMessages = (message: MessageEvent<string>) => {
  try {
    const parsedMessage = JSON.parse(message.data);
    return isStartMyEmailWebsocketJSONMessage(parsedMessage);
  } catch (error) {
    parseAndLogError(error, ErrorIdentifiers.WS_JSON_PARSE_ERROR, {
      eventData: message.data,
    });
    return false;
  }
};
