import { AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { isDev } from "~src/utils";

import { URLS } from "./constants";

// an Enum to define the URLs that should be cached. Only needs to be POSTs, as GETs can be HTTP cached by the browser
enum INTERCEPTED_POSTS {
  MAILBOX = URLS.mailboxUser,
  USER = URLS.user,
  FEATURE_FLAG = URLS.featureFlag,
  PROFILE = URLS.profile,
}

const INTERCEPTED_POST_TTLS: Record<INTERCEPTED_POSTS, number> = {
  [INTERCEPTED_POSTS.USER]: 60 * 1000, // 1 minute
  [INTERCEPTED_POSTS.FEATURE_FLAG]: 5 * 60 * 1000, // 5 minutes
  [INTERCEPTED_POSTS.MAILBOX]: 5 * 60 * 1000, // 30 minutes
  [INTERCEPTED_POSTS.PROFILE]: 30 * 60 * 1000, // 30 minutes
};

interface CachedResponse {
  cachedResponse: boolean;
}

export const clearCache = (lookupString: string, lookupExpires: string) => {
  localStorage.removeItem(lookupString);
  localStorage.removeItem(lookupExpires);
};

// Request interceptor to check for POST requests and matching URLs
export const loadRequestFromCacheIfPresentAndNotStale = async (
  config: InternalAxiosRequestConfig<unknown>
) => {
  const url = config.url as INTERCEPTED_POSTS;
  const { lookupExpires, lookupString } = constructLookupStrings(
    url,
    config.data ?? {}
  );
  if (config.fetchOptions?.forceRefetch) {
    clearCache(lookupString, lookupExpires);
    return config;
  }
  if (isDev || !Object.values(INTERCEPTED_POSTS).includes(url)) {
    return config;
  }

  // Check for cached response in localstorage
  let cachedResponse: object;
  let cachedExpires: number;

  // If the cached object is malformed, clear it and continue with original request
  try {
    cachedResponse = JSON.parse(
      localStorage.getItem(lookupString) ?? ""
    ) as object;
    cachedExpires = +(localStorage.getItem(lookupExpires) || 0);

    // If the cached object is empty or expired, clear it and continue with original request
    if (
      !Object.keys(cachedResponse).length ||
      !cachedExpires ||
      isNaN(cachedExpires) ||
      Date.now() > cachedExpires
    ) {
      throw new Error("Cache expired");
    }
  } catch (e) {
    clearCache(lookupString, lookupExpires);
    return config;
  }

  // Return a fake rejected response with the cached data to bypass the network request
  // eslint-disable-next-line prefer-promise-reject-errors
  return await Promise.reject({
    cachedResponse: true,
    ...cachedResponse,
  });
};

// Response interceptor to handle cached responses and store new ones
export const storeCacheableResponse = (
  response: AxiosResponse<unknown, unknown>
) => {
  const url = response.config.url as INTERCEPTED_POSTS;
  const { lookupString, lookupExpires } = constructLookupStrings(
    url,
    response.config.data ?? {}
  );

  // Store the response in localStorage if the request URL matches
  if (Object.values(INTERCEPTED_POSTS).includes(url)) {
    localStorage.setItem(lookupString, JSON.stringify(response));
    localStorage.setItem(
      lookupExpires,
      `${Date.now() + INTERCEPTED_POST_TTLS[url]}`
    );
  }

  return response;
};

// Handle cached responses or fallback to the error
export const useCachedResponse = async (error: CachedResponse) => {
  // Return the cached response if it was found in the request interceptor
  return error.cachedResponse
    ? await Promise.resolve(error)
    : await Promise.reject(error);
};

export const constructLookupStrings = (url: string, data: unknown) => {
  const origin = window.location.hostname;
  const payloadValues = [
    ...new URLSearchParams(
      typeof data === "string" ? data : Object.entries(data ?? {})
    ).values(),
  ].join();
  const lookupString = `${origin}->${url}?${payloadValues}`;
  const lookupExpires = `${lookupString}.Expires`;

  return { lookupString, lookupExpires };
};
