import { Flex, Form as AntdForm, FormInstance } from "antd";
import { useCallback, useEffect } from "react";

import {
  Switch,
  Tooltip,
  TextArea,
  Text,
  Button,
} from "@lavender-ai/lav-components";

import { useChat, useLvIdentifier } from "~src/customHooks";
import { Form } from "~src/component/Organisms/ChatGPT/Form";

import { CHAT_GPT_PANEL, CHAT_GPT_TOOLTIP } from "~src/strings";
import { toggleSetting } from "~src/redux";
import { useAppDispatch } from "~src/redux/hooks";
import { chatsAction } from "~src/redux/chat";
import { ChatTool, NetworkStatus } from "~src/redux/typings";
import {
  handleTextAreaEnterKeyDown,
  SegmentEventLocations,
  SegmentEventNames,
  segmentEvent,
} from "~src/utils";
import { FormSubmitButton } from "~src/component/Styled";
import { LavenderIcon } from "~src/component/typings";

const { BROWSE, MESSAGE_CHAT_GPT, SEND } = CHAT_GPT_PANEL;

interface Props {
  inputValue: string;
  form: FormInstance<FieldType>;
  onFinish: (values: FieldType) => void;
}

export interface FieldType {
  [ChatTool.GoogleWebSearch]?: boolean;
  message: string;
}

export const AssistantForm = ({ inputValue, form, onFinish }: Props) => {
  const dispatch = useAppDispatch();
  const lvIdentifier = useLvIdentifier();
  const {
    chat,
    initialized,
    tools,
    user: { settings },
  } = useChat();
  const isGoogleWebSearchOn =
    tools[ChatTool.GoogleWebSearch] && settings[ChatTool.GoogleWebSearch];
  const chatStatus = chat?.status;

  useEffect(() => {
    const currentIsGoogleWebSearchOn = form.getFieldValue(
      ChatTool.GoogleWebSearch
    ) as boolean;
    if (currentIsGoogleWebSearchOn !== isGoogleWebSearchOn) {
      form.setFieldsValue({
        [ChatTool.GoogleWebSearch]: isGoogleWebSearchOn,
      });
    }
  }, [form, isGoogleWebSearchOn]);

  useEffect(() => {
    const currentMessageValue = form.getFieldValue("message") as string;
    if (currentMessageValue !== inputValue) {
      form.setFieldsValue({
        message: inputValue,
      });
    }
  }, [form, inputValue]);

  const toggleGoogleWebSearch = useCallback(
    (checked: boolean) => {
      if (checked) {
        segmentEvent(
          SegmentEventNames.BrowseToggledOn,
          SegmentEventLocations.ChatGPT
        );
      } else {
        segmentEvent(
          SegmentEventNames.BrowseToggledOff,
          SegmentEventLocations.ChatGPT
        );
      }
      void (async () => {
        await dispatch(
          toggleSetting({ on: checked, setting: ChatTool.GoogleWebSearch })
        );
      })();
    },
    [dispatch]
  );

  const messageValue = AntdForm.useWatch("message", form);
  const isSubmitDisabled = useCallback(() => {
    return !messageValue || chatStatus === NetworkStatus.loading;
  }, [chatStatus, messageValue]);

  const reset = useCallback(() => {
    dispatch(chatsAction.resetChat({ lvIdentifier }));
  }, [dispatch, lvIdentifier]);

  return (
    <Form
      autoComplete="off"
      form={form}
      onFinish={onFinish}
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "8px",
        width: "100%",
      }}
    >
      <AntdForm.Item<FieldType> name="message" noStyle>
        <TextArea
          onPressEnter={(e) => {
            handleTextAreaEnterKeyDown(e, form.submit);
          }}
          autoSize={{ minRows: 1, maxRows: 6 }}
          placeholder={MESSAGE_CHAT_GPT}
          state={
            chat?.status === NetworkStatus.loading ? "disabled" : "initial"
          }
        />
      </AntdForm.Item>
      <Flex align="center" justify="space-between">
        <Tooltip placement="top" title={initialized ? "New Chat" : undefined}>
          <Button
            state={initialized ? "initial" : "disabled"}
            icon={LavenderIcon.IconRefresh}
            onClick={reset}
            radius="md"
            variant="plain"
          />
        </Tooltip>
        <div>
          {tools[ChatTool.GoogleWebSearch] && (
            <Flex align="center" gap="small">
              <AntdForm.Item<FieldType>
                label={BROWSE}
                noStyle
                name={ChatTool.GoogleWebSearch}
                valuePropName="checked"
              >
                <Tooltip
                  hideArrow
                  title={initialized ? "" : CHAT_GPT_TOOLTIP.CHATGPT_WILL_USE}
                  placement="topLeft"
                >
                  <Switch
                    checked={isGoogleWebSearchOn}
                    defaultChecked={isGoogleWebSearchOn}
                    onChange={toggleGoogleWebSearch}
                    disabled={initialized}
                    size="small"
                  ></Switch>
                </Tooltip>
              </AntdForm.Item>
              <Text size="body1">{BROWSE}</Text>
            </Flex>
          )}
        </div>
        <div>
          <AntdForm.Item noStyle label={SEND}>
            <FormSubmitButton
              state={isSubmitDisabled() ? "disabled" : "initial"}
              variant="primary"
              htmlType="submit"
            >
              {SEND}
            </FormSubmitButton>
          </AntdForm.Item>
        </div>
      </Flex>
    </Form>
  );
};
