export const isDuplicateRenderCycle = (): boolean => {
  const isOutreachEverywhere = window.location.href.includes(
    "https://sidebar.outreach.io"
  );
  if (isOutreachEverywhere) {
    return false;
  }
  const isLavenderOutlookIframe =
    window.location.href.includes("outlook-native");
  const isLavenderOutlookIframe2 =
    window.location.href.includes("outlook-lavender");
  if (isLavenderOutlookIframe || isLavenderOutlookIframe2) {
    return false;
  }

  // Add all cases that should prevent the extension from running here
  const isGmailInternalIframe = window.location.ancestorOrigins.contains(
    "https://mail.google.com"
  );
  const isOutlookOfficeInternalIframe =
    window.location.ancestorOrigins.contains("https://outlook.office.com");
  const isOutlookOffice365InternalIframe =
    window.location.ancestorOrigins.contains("https://outlook.office365.com");
  const isOutlookLiveInternalIframe = window.location.ancestorOrigins.contains(
    "https://outlook.live.com"
  );

  const doesPageHaveExtensionElements =
    document.getElementById("lv-root") !== null;
  // const isOtherIgnoredRenderCase = ...;

  return (
    isGmailInternalIframe ||
    isOutlookOfficeInternalIframe ||
    isOutlookOffice365InternalIframe ||
    isOutlookLiveInternalIframe ||
    doesPageHaveExtensionElements ||
    // isOtherIgnoredRenderCase ||
    false
  );
};
