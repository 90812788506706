import { Flex } from "antd";
import styled from "styled-components";

import { Avatar, Icon, Text } from "@lavender-ai/lav-components";

import { CENTERED_SVG_MIXIN } from "~src/component/Styled";
import { LavenderIcon } from "~src/component/typings";

const ELLIPSE_SIZE = 101;
const TEXT_HEIGHT = 16;

const FlexdWrapper = styled(Flex)`
  ${CENTERED_SVG_MIXIN}
  z-index: 10;

  div:has(
      span:has(
          .lav-components-text-color-white.lav-components-text-size-subtext5.lav-components-text-underline-undefined.lav-components-text
        )
    ) {
    height: ${TEXT_HEIGHT}px;
  }
  span:has(
      .lav-components-text-color-white.lav-components-text-size-subtext5.lav-components-text-underline-undefined.lav-components-text
    ) {
    position: absolute;
    z-index: 10;
    transform: translate(-50%, -50%);
    left: 50%;
    top: calc(50% + (${ELLIPSE_SIZE}px / 2) + 20px);
  }

  span.ant-avatar.lav-components-avatar {
    ${CENTERED_SVG_MIXIN}
  }
`;

const StyledEllipseAvatarWrapper = styled(Avatar)`
  span.ant-avatar.lav-components-avatar& {
    background-color: rgba(119, 61, 236, 1);
    height: ${ELLIPSE_SIZE}px;
    width: ${ELLIPSE_SIZE}px;
  }
`;

const StyledEllipseAvatarSubWrapper = styled(Avatar)`
  span.ant-avatar.lav-components-avatar& {
    background-color: rgba(146, 97, 236, 0.66);
    height: 83px;
    width: 83px;
  }
`;

const StyledText = styled(Text)`
  height: ${TEXT_HEIGHT}px;
`;

export const Finished = () => {
  return (
    <FlexdWrapper align="center" gap={10} vertical>
      <StyledEllipseAvatarWrapper size="extra-large" />
      <StyledEllipseAvatarSubWrapper size="extra-large" />
      <Avatar
        color="pear"
        icon={
          <Icon size="large" color="dark" variant={LavenderIcon.IconCheck} />
        }
        size="extra-large"
      />
      <StyledText size="subtext5" color="white">
        Finished!
      </StyledText>
    </FlexdWrapper>
  );
};
