import { FC, useState } from "react";
import { styled } from "styled-components";

import { Search, Icon, Tabs } from "@lavender-ai/lav-components";

import { SubTabListContainer } from "../../Shared";

import { PERSONALIZATION_HEADER } from "../../../../../strings";
import { HubspotPersonal, HubspotCompany } from "~src/api/typings";
import { Notes, Section } from "./components";
import { shouldShow } from "./utils";
import { Empty } from "~src/component/Molecules/Empty/Empty";
import { useCRM, usePersonalization } from "~src/customHooks";

interface Props {
  personal: HubspotPersonal | undefined;
  company: HubspotCompany | undefined;
  searchText: string | undefined;
}

const Details: FC<Props> = ({ personal, company, searchText }) => {
  const searchPersonal = shouldShow(JSON.stringify(personal), searchText);
  const searchCompany = shouldShow(JSON.stringify(company), searchText);

  const foundSomething = searchPersonal || searchCompany;

  return !foundSomething ? (
    <Empty title="Nothing found for that search term." />
  ) : (
    <SectionDetails>
      {personal && searchPersonal && (
        <Section
          icon={<Icon variant="IconUser" size="extra-small" />}
          title="Personal"
          items={[
            {
              title: "Name",
              description: personal.name || "",
              visible: shouldShow(personal.name, searchText),
            },
            {
              title: "Phone",
              description: personal.phone || "",
              visible: shouldShow(personal.phone, searchText),
            },
            {
              title: "Stage",
              description: personal.stage || "",
              visible: shouldShow(personal.stage, searchText),
            },
          ]}
        />
      )}
      {company && searchCompany && (
        <Section
          icon={<Icon variant="IconBuilding" size="extra-small" />}
          title="Company"
          items={[
            {
              title: "Name",
              description: company.name || "",
              visible: shouldShow(company.name, searchText),
            },
            {
              title: "Domain",
              description: company.domain || "",
              visible: shouldShow(company.domain, searchText),
            },
            {
              title: "Stage",
              description: company.stage || "",
              visible: shouldShow(company.stage, searchText),
            },
          ]}
        />
      )}
    </SectionDetails>
  );
};

export const HubspotCRM: FC = () => {
  const [searchText, setSearchText] = useState("");
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = usePersonalization();
  const { crms } = useCRM();
  const { hubspot_data } = crms[email] || {};
  const hubspotDataExists = hubspot_data !== undefined;

  return (
    <CRMContainer>
      <SubTabListContainer count={1} header={PERSONALIZATION_HEADER.HUBSPOT}>
        {!hubspotDataExists ? (
          <Empty title="We couldn’t find any HubSpot Data." />
        ) : (
          <>
            <Search
              placeholder="Search data..."
              size="medium"
              state="initial"
              style={{ marginBottom: 20 }}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <SectionTabs
              items={[
                {
                  children: (
                    <Details
                      personal={hubspot_data?.personal}
                      company={hubspot_data?.company}
                      searchText={searchText}
                    />
                  ),
                  key: "1",
                  label: "Details",
                },
                {
                  children: <Notes notes={hubspot_data?.notes ?? []} />,
                  key: "2",
                  label: "Notes",
                },
              ]}
              size="small"
              variant="underlined"
            />
          </>
        )}
      </SubTabListContainer>
    </CRMContainer>
  );
};

const CRMContainer = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;

const SectionTabs = styled(Tabs)`
  padding: 0 16px;
  width: 100%;
  > div {
    border-bottom: 1px solid var(--color-neutral-purple-100, #e9ebf2);
    &:last-child {
      border-bottom: 0;
    }
  }
`;

const SectionDetails = styled.div``;
