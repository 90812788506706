import { Button, Tooltip } from "@lavender-ai/lav-components";

import { LavenderIcon } from "~src/component/typings";
import {
  selectPlatform,
  useAppSelector,
  useMainPanelView,
  usePanelIsOpen,
} from "~src/redux";

import { FlexMenuItem } from "../Menu.styled";
import { useShowPanel } from "../utils";

export const GifsButton = () => {
  const platform = useAppSelector(selectPlatform);
  const view = useMainPanelView();
  const showPanel = useShowPanel();
  const isOpen = usePanelIsOpen();

  return (
    <FlexMenuItem>
      <Tooltip
        hideArrow
        placement={platform === "outlookNative" ? "bottom" : "right"}
        title="GIFs"
      >
        <Button
          aria-label="GIFs"
          onClick={(e) => {
            showPanel(e, "gif");
          }}
          radius="md"
          size="large"
          state={view === "gif" && isOpen === "open" ? "focus" : "initial"}
          icon={
            view === "gif" && isOpen === "open"
              ? LavenderIcon.IconChevronsRight
              : LavenderIcon.IconGif
          }
          variant="plain"
        />
      </Tooltip>
    </FlexMenuItem>
  );
};
