import { FC } from "react";

import {
  useMainLoopState,
  useRenderLegacyExtension,
  useRenderNewExtension,
} from "./helpers";

// Simple wrapper to fire the Legacy or New extension render (not duplicated as they call useDecideExtensionToInit hook)
export const MainLoopComponent: FC = () => {
  const mainLoopState = useMainLoopState();

  useRenderLegacyExtension(mainLoopState);
  useRenderNewExtension(mainLoopState);

  return null;
};
