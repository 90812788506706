import { useCallback, useEffect, useMemo, useState } from "react";
import { differenceInSeconds } from "date-fns";

import {
  emailAction,
  highlightsAction,
  selectChromeId,
  selectEmailAnalysis,
  selectEmailData,
  store,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";
import {
  Analysis,
  EmailAnalysis,
  EmailAnalysisStage,
  EmailWritingData,
  NetworkStatus,
  Recommendation,
} from "~src/redux/typings";
import { useLvIdentifier } from "~src/customHooks";
import {
  segmentEvent,
  SegmentEventLocations,
  SegmentEventNames,
} from "~src/utils";
import { MAILCLIENT, TONE_CONVERT } from "~src/constants";

import {
  OpenLavenderAnywhereIframe,
  OpenLavenderAnywherePopup,
} from "../EditorMode/Activate";
import {
  HIGH_SCORE_HEADER,
  MIDDLE_SCORE_HEADER,
  LOW_SCORE_HEADER,
} from "./constants";

import RocketSuccess from "~src/assets/images/rocket-success.svg";
import RocketDanger from "~src/assets/images/rocket-danger.svg";
import RocketWarning from "~src/assets/images/rocket-warning.svg";

export const getScoreHeaderMessage = (score: number) => {
  if (score >= 90) {
    return HIGH_SCORE_HEADER;
  } else if (score >= 70) {
    return MIDDLE_SCORE_HEADER;
  } else {
    return LOW_SCORE_HEADER;
  }
};

export const formatDurationWithZeroPadding = (duration: {
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  const hours = duration.hours > 0 ? `${duration.hours}:` : "";
  const minutes = String(duration.minutes).padStart(2, "0");
  const seconds = String(duration.seconds).padStart(2, "0");

  if (Object.values(duration).some(isNaN)) {
    return "00:00";
  } else {
    return `${hours}${minutes}:${seconds}`;
  }
};

export const useTrackWritingTime = (writingStartTime: number) => {
  const [diffInSeconds, setDiffInSeconds] = useState<number>(
    differenceInSeconds(Date.now(), writingStartTime)
  );
  const [isOver5Minutes, setIsOver5Minutes] = useState<boolean>(false);

  useEffect(() => {
    const updateValue = () => {
      setDiffInSeconds((prevValue) => {
        if (prevValue >= 299) {
          setIsOver5Minutes(true);
        }
        return prevValue + 1;
      });
    };
    const intervalId = setInterval(updateValue, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return {
    writingDuration: formatDurationWithZeroPadding({
      hours: Math.floor(diffInSeconds / 3600),
      minutes: Math.floor((diffInSeconds % 3600) / 60),
      seconds: diffInSeconds % 60,
    }),
    isOver5Minutes,
  };
};

const STAGE_TO_SEGMENT_NAME: Record<EmailAnalysisStage, SegmentEventNames> = {
  cold: SegmentEventNames.ColdEmailSelected,
  follow_up: SegmentEventNames.FollowUpSelected,
  reply: SegmentEventNames.ReplySelected,
};
export const useUpdateStageCB = () => {
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();

  return useCallback(
    (stage: EmailAnalysisStage) => {
      segmentEvent(
        STAGE_TO_SEGMENT_NAME[stage],
        SegmentEventLocations.EmailCoach
      );

      dispatch(
        emailAction.updateStage({
          id: lvIdentifier,
          stageOverwrite: stage,
        })
      );
    },
    [dispatch, lvIdentifier]
  );
};

const SCORE_TIER_TO_COLOR_INFO: Record<
  "success" | "warning" | "danger",
  { numberColor: string; rocketImage: string; isSuccess: boolean }
> = {
  success: {
    numberColor: "var(--color-secondary-pear)",
    rocketImage: RocketSuccess,
    isSuccess: true,
  },
  warning: {
    numberColor: "var(--color-utility-Warning)",
    rocketImage: RocketWarning,
    isSuccess: false,
  },
  danger: {
    numberColor: "var(--color-utility-Negative)",
    rocketImage: RocketDanger,
    isSuccess: false,
  },
};
export const useScoreColorGroup = () => {
  const lvIdentifier = useLvIdentifier();
  const analysisData = useAppSelector(selectEmailAnalysis(lvIdentifier));
  const {
    analysis: { dynamic_ceq: score },
  } = analysisData;

  // const [x, setx] = useState(0);
  // useEffect(() => {
  //   setInterval(() => {
  //     setx((x) => (x + 1) % 3);
  //   }, 5000);
  // }, []);

  return useMemo(() => {
    // return x === 0
    //   ? SCORE_TIER_TO_COLOR_INFO.success
    //   : x === 1
    //     ? SCORE_TIER_TO_COLOR_INFO.warning
    //     : SCORE_TIER_TO_COLOR_INFO.danger;
    if (score >= 90) {
      return SCORE_TIER_TO_COLOR_INFO.success;
    } else if (score >= 70) {
      return SCORE_TIER_TO_COLOR_INFO.warning;
    } else {
      return SCORE_TIER_TO_COLOR_INFO.danger;
    }
  }, [score]);
};

export const useClearAnalysisStageOnFail = () => {
  const lvIdentifier = useLvIdentifier();
  const { stage, status } = useAppSelector(selectEmailAnalysis(lvIdentifier));
  const { stageOverwrite } = useAppSelector(selectEmailData(lvIdentifier));

  useEffect(() => {
    if (
      stageOverwrite !== undefined &&
      stage !== stageOverwrite &&
      status === NetworkStatus.failed
    ) {
      store.dispatch(
        emailAction.updateStage({
          id: lvIdentifier,
          stageOverwrite: stage,
        })
      );
    }
  }, [stage, status, stageOverwrite, lvIdentifier]);
};

export const useSelectTones = () => {
  const lvIdentifier = useLvIdentifier();
  const chromeId = useAppSelector(selectChromeId);
  const currentHighlights = useAppSelector(
    (state) => state.highlights[lvIdentifier]
  );
  const emailData = useAppSelector(selectEmailData(lvIdentifier));
  const { platform, insertType } = useAppSelector((state) => {
    if (state.config.settings) {
      return state.config.settings;
    }
    return { platform: "", insertType: "" };
  });
  const analysisData = useAppSelector(selectEmailAnalysis(lvIdentifier));

  const dispatch = useAppDispatch();

  return useCallback(
    (tone: Analysis["top_tones"][0]) => {
      const convertedTone = TONE_CONVERT[tone];
      // if recommendationId === tone, then remove highlight
      dispatch(
        highlightsAction.setHighlights({
          lvIdentifier,
          highlights: [],
          recommendationId: "",
        })
      );

      if (currentHighlights.recommendationId === convertedTone) {
        return;
      }

      // else add highlight
      const highlights: Array<string> = [];
      for (const sentence of analysisData.highlights.ToneSentences) {
        if (sentence.tones) {
          for (const t of sentence.tones) {
            if (t.tone_id === convertedTone) {
              highlights.push(sentence.text);
            }
            if (
              convertedTone === "friendly" &&
              (t.tone_id === "joy" || t.tone_id === "confident")
            ) {
              highlights.push(sentence.text);
            }
          }
        }
      }

      if (insertType === "copy") {
        copyAction(
          convertedTone,
          highlights,
          platform,
          chromeId,
          emailData,
          analysisData
        );
      } else {
        dispatch(
          highlightsAction.setHighlights({
            lvIdentifier,
            highlightClass: "lv-highlight-yellow",
            highlights,
            recommendationId: convertedTone,
          })
        );
      }
    },
    [
      analysisData,
      chromeId,
      currentHighlights,
      dispatch,
      emailData,
      insertType,
      lvIdentifier,
      platform,
    ]
  );
};

const copyAction = (
  convertedTone: string,
  highlights: string[],
  platform: string,
  chromeId: chromeId,
  emailData: EmailWritingData,
  analysisData: EmailAnalysis
) => {
  const highlightData: Recommendation = {
    current_value: 0,
    effectonscore: 0,
    gpt_rec: "",
    ideal: 0,
    linkedbtn: "",
    lower_bound: 0,
    prompt_name: "",
    recommendation: "",
    rectype: "",
    upper_bound: 0,
    id: convertedTone,
    field: convertedTone,
    text: highlights.join("|"),
  };

  if (platform === MAILCLIENT.OUTLOOK_NATIVE) {
    OpenLavenderAnywherePopup(chromeId, emailData, analysisData, highlightData);
  } else {
    OpenLavenderAnywhereIframe(
      chromeId,
      emailData,
      analysisData,
      highlightData
    );
  }
};
