import { FC } from "react";
import styled from "styled-components";
import { Button } from "@lavender-ai/lav-components";

import { FRONTEND_URLS } from "~src/constants";

const Wrapper = styled.div`
  margin: 12px 20px;
`;
const SecondaryTitle = styled.div`
  margin: 12px 0px;
  color: var(--color-base-Black, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.1px;
`;

export const BillingHistory: FC = () => {
  return (
    <Wrapper>
      <SecondaryTitle>Billing History</SecondaryTitle>
      <Button
        variant="primary"
        size="medium"
        fullWidth={true}
        onClick={() => {
          window.open(
            `${FRONTEND_URLS.dashboard}/app/settings/billing`,
            "_blank"
          );
        }}
      >
        View Billing History
      </Button>
    </Wrapper>
  );
};
