import { AvatarProps } from "@lavender-ai/lav-components";

export const numericSize = (size: AvatarProps["size"]) => {
  switch (size) {
    case "extra-small":
      return 24;
    case "small":
      return 32;
    case "medium":
      return 40;
    case "large":
      return 48;
    case "extra-large":
      return 64;
  }
};
