import { NetworkStatus } from "./networkStatus";

export type OpenEmailTrackingState = {
  [key in Exclude<string, "status">]?: {
    openTotal: number | undefined;
    status: NetworkStatus;
    error: string;
    isOpen: boolean;
    rows: OpenEmailType[];
  };
} & {
  status: string;
};
export interface OpenEmailType {
  time: string;
  device: string;
}
export interface OpenEmailAPIResponse {
  opens: any[];
  status: string;
  total_opens: number;
}
