import { useEffect } from "react";
import { Form } from "antd";

import { TextArea } from "@lavender-ai/lav-components";

import { useStartMyEmail } from "~src/customHooks";
import { useAppSelector } from "~src/redux";
import { CUSTOM_FIELDS_FORM } from "~src/strings";

const NAME = ["categories", "custom", "main"];

interface Props {
  isDisabled?: boolean;
}

export const CustomField = ({ isDisabled }: Props) => {
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = useStartMyEmail();
  const customMainValueProp = useAppSelector(
    ({ valueProps }) =>
      valueProps.customValueProps[email]?.categories?.custom?.main || ""
  );
  const initialValue = customMainValueProp;
  const form = Form.useFormInstance();
  const initialFormValue = form.getFieldValue(NAME);

  useEffect(() => {
    if (initialFormValue !== initialValue && !form.isFieldTouched(NAME)) {
      form.setFields([
        {
          name: NAME,
          value: initialValue,
          touched: false,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NAME, form]);

  return (
    <Form.Item name={NAME} noStyle>
      <TextArea
        autoSize={{ minRows: 1, maxRows: 6 }}
        disabled={isDisabled}
        maxLength={250}
        placeholder={CUSTOM_FIELDS_FORM.INPUTS.OUR_BENEFITS}
      />
    </Form.Item>
  );
};
