import { networkRequest, URLS } from "~src/api";

import * as askChatApi from "./typings/askChat";

export const askChat = async (
  payload: askChatApi.AskChatPayload
): Promise<askChatApi.SuccessResponse> => {
  const { data } = await networkRequest.post<askChatApi.SuccessResponse>(
    URLS.askChat,
    {
      auto_suggestion: payload.autoSuggestion,
      chat_thread_id: payload.chatThreadId,
      chrome_id: payload.chromeId,
      messages: JSON.stringify(payload.messages),
      search_web: payload.searchWeb,
      tz: payload.timezone,
      location: payload.location,
    }
  );
  return data;
};
