import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  clearCache,
  constructLookupStrings,
  getUserAccount,
  toggleUserSetting,
  URLS,
} from "../api";
import { EmailEvaluation } from "../api/typings/emailVerification";
import { fetchFeatureFlags } from "./feature";
import { updateVerifications } from "./verification";
import { loadProfile } from "./feed";
import { RootState, AppDispatch } from "./store";
import { NetworkStatus } from "./typings/networkStatus";
import {
  UserAccount,
  ToggleUserSettingData,
  ToggleUserSettingArgs,
  UserAccountState,
} from "./typings/userAccount";
import {
  UserAccountErrorResponse,
  UserAccountResponse,
  isError,
} from "../api/typings/getUserAccount";
import {
  resolveProfileCompanyBio,
  resolveProfileCompanyName,
  resolveProfileCompanyWebsite,
  resolveProfileJobTitle,
} from "~src/utils";

import { createAppSelector } from "./selectors";

export const INIT_USER_ACCOUNT_STATE: UserAccountState = {
  settings: {} as UserAccount,
  status: NetworkStatus.idle,
  error: "",
};

const createUserAccountAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();

const rejectUserAccount = createAction<UserAccountErrorResponse>(
  "userAccount/rejectUserAccount"
);

const updateUserAccount = createAction<UserAccount>(
  "userAccount/updateUserAccount"
);

export const fetchUserAccount = createUserAccountAsyncThunk<
  undefined,
  { chromeId: chromeId }
>(
  "userAccount/fetchUserAccount",
  async ({ chromeId }, { dispatch }): Promise<undefined> => {
    const data = await getUserAccount(chromeId);
    if (isError(data)) {
      dispatch(rejectUserAccount(data));
    } else if (data.user) {
      dispatch(updateUserAccount(data));

      void dispatch(fetchFeatureFlags(chromeId));

      const verification = {
        [data.user.email]: {
          loading: false,
          evaluation: EmailEvaluation.VALID,
        },
      };
      void dispatch(updateVerifications(verification));

      void dispatch(loadProfile({ toAddresses: [data.user.email] }));
    }
  }
);

export const refreshUserAccount = createUserAccountAsyncThunk<
  UserAccountResponse,
  { chromeId: chromeId }
>(
  "userAccount/refreshUserAccount",
  async ({ chromeId }, { dispatch }): Promise<UserAccountResponse> => {
    const { lookupExpires, lookupString } = constructLookupStrings(URLS.user, {
      chrome_id: chromeId,
    });
    clearCache(lookupString, lookupExpires);

    const data = await getUserAccount(chromeId);
    if (isError(data)) {
      dispatch(rejectUserAccount(data));
    } else {
      dispatch(updateUserAccount(data));
    }
    return data;
  }
);

export const toggleSetting = createUserAccountAsyncThunk<
  ToggleUserSettingData,
  ToggleUserSettingArgs
>(
  "userAccount/toggleSetting",
  async ({ setting, on }, { getState }): Promise<ToggleUserSettingData> => {
    const {
      config: { chromeId },
    } = getState();
    await toggleUserSetting(chromeId, `settings.${setting}`, on);
    return { key: setting, value: on };
  }
);

export const userAccountSlice = createSlice({
  name: "userAccount",
  initialState: INIT_USER_ACCOUNT_STATE,
  reducers: {},
  extraReducers(builder) {
    builder
      // INFO: fetchUserAccount
      .addCase(fetchUserAccount.pending, (state, _action) => {
        state.status = NetworkStatus.loading;
      })
      .addCase(rejectUserAccount, (state, { payload }) => {
        state.status = NetworkStatus.failed;
        state.error = payload.message || "";
      })
      .addCase(updateUserAccount, (state, { payload }) => {
        state.status = NetworkStatus.success;
        state.settings = { ...payload };
      })
      .addCase(toggleSetting.fulfilled, (state, { payload }) => {
        state.status = NetworkStatus.success;
        if (!state.settings.user) return;
        const settings = state.settings.user.settings;
        const { key, value } = payload;
        if (Object.keys(settings).length) {
          state.settings.user.settings = { ...settings, [key]: value };
        }
      })
      .addCase(toggleSetting.rejected, (state, { error }) => {
        state.status = NetworkStatus.failed;
        state.error = error.message || "";
      });
  },
});

export const userAccountReducer = userAccountSlice.reducer;
export const userAccountActions = userAccountSlice.actions;

export const selectUserAccountEmail = ({ userAccount }: RootState) =>
  userAccount.settings.user?.email || "";
export const selectUserAccountProfileCompany = ({ userAccount }: RootState) =>
  resolveProfileCompanyName(userAccount.settings.user?.person.company);
export const selectUserAccountProfileCompanyBio = ({
  userAccount,
}: RootState) =>
  resolveProfileCompanyBio(userAccount.settings.user?.person.company);
export const selectUserAccountProfileCompanyWebsite = ({
  userAccount,
}: RootState) =>
  resolveProfileCompanyWebsite(userAccount.settings.user?.person.company);
export const selectUserAccountJobTitle = ({ userAccount }: RootState) =>
  resolveProfileJobTitle(userAccount.settings.user?.person);

const selectUserAccountSettings = ({ userAccount }: RootState) =>
  userAccount.settings.user?.settings;
export const makeSelectUserAccountSetting = () => {
  const selectUserAccountSetting = createAppSelector(
    [
      selectUserAccountSettings,
      (_state: RootState, setting: string) => setting,
    ],
    (settings, setting: string) => settings?.[setting]
  );
  return selectUserAccountSetting;
};
