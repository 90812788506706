import styled from "styled-components";
import { Avatar, Button, Icon } from "@lavender-ai/lav-components";

import { FRONTEND_URLS } from "~src/constants";

const SignIn = styled.div`
  display: flex;
  padding: var(--size-32, 32px) 20px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: var(--radius-lrg, 16px);
  background: var(--color-base-White, #fff);
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
`;

const SignInToCRM = styled.div`
  color: var(--color-neutral-purple-900, #18253d);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.1px;
  padding-bottom: 20px;
`;

const Subtext = styled.div`
  color: var(--color-neutral-purple-600, #6d727d);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: -0.25px;
  padding-bottom: 20px;
`;

export const GongSignIn = () => (
  <SignIn>
    <AvatarWrapper>
      <Avatar
        color="lavender"
        size="large"
        icon={<Icon color="white" size="large" variant="IconGongLogo" />}
      />
    </AvatarWrapper>
    <SignInToCRM>Sign in to Gong</SignInToCRM>
    <Subtext>
      Once you’ve signed in, Lavender will activate in your Gong acccount.
    </Subtext>
    <Button
      prefix="IconGongLogo"
      fullWidth={false}
      size="medium"
      variant="default"
      onClick={() => window.open(FRONTEND_URLS.teamsIntegration, "_blank")}
    >
      Connect Integration
    </Button>
  </SignIn>
);
