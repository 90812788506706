import { networkRequest, URLS } from "~src/api";
import { GetMailBoxUserResponse } from "~src/redux/typings";

export const getMailBoxUser = async (
  emailAddress: string,
  chromeId: chromeId
) => {
  return await networkRequest.post<GetMailBoxUserResponse>(URLS.mailboxUser, {
    email: emailAddress,
    chrome_ids: chromeId,
  });
};
