import { ITechStackContext, useTechStackContext } from "~src/contexts";
import { NetworkStatus, RecipientTechStackMap } from "~src/redux/typings";
import { useAppSelector } from "~src/redux";

interface IUseTechStacks extends Omit<ITechStackContext, "currentTechStack"> {
  loading: boolean;
  status: NetworkStatus;
  lastUpdated: number | null;
  techStacks: RecipientTechStackMap;
}

export const useTechStacks = (): IUseTechStacks => {
  const {
    techStacks,
    status = NetworkStatus.idle,
    lastUpdated,
  } = useAppSelector(({ feed }) => feed);

  const { resetTechStackContext, setTechStackContext } =
    useTechStackContext();

  return {
    loading: status === NetworkStatus.loading,
    status,
    lastUpdated,
    techStacks,
    resetTechStackContext,
    setTechStackContext,
  };
};
