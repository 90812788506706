import { FRONTEND_URLS } from "~src/constants";

export const flagExtensionIsInstalledToDashboard = () => {
  if (
    window.location.href.split(window.location.search)[0] !==
    FRONTEND_URLS.login
  ) {
    return;
  }

  // flag to signal to dashboard login that extension is installed
  document.body.appendChild(
    Object.assign(document.createElement("div"), { id: "lv-extension-flag" })
  );
};
