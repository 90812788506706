export const theme = {
  cssVar: true,
  hashed: false,
  components: {
    Alert: {
      borderRadiusLG: 12,
      colorInfo: "var(--color-lavender-400)",
      colorInfoBorder: "var(--color-lavender-400)",
      colorInfoBg: "var(--color-lavender-50)",
      colorError: "var(--color-utility-Negative)",
      colorErrorBg: "var(--color-tart-50)",
      colorWarning: "var(--color-utility-Warning)",
      colorWarningBg: "var(--color-marigold-50)",
      defaultPadding: 10,
    },
    Input: {
      activeBorderColor: "var(--color-lavender-400)",
      activeShadow: "0px 0px 0px 2px var(--color-lavender-100)",
      addonBg: "var(--color-base-White)",
      hoverBorderColor: "var(--color-neutral-purple-300)",
    },
    Layout: {
      bodyBg: "var(--lv-panel-background-color)",
    },
    List: {
      avatarMarginRight: 4,
      descriptionFontSize: 12,
    },
    Message: {
      borderRadiusLG: 12,
      colorError: "var(--color-utility-Negative)",
      colorSuccess: "var(--color-utility-Positive)",
      colorText: "var(--color-base-Black)",
      contentBg: "var(--color-base-White)",
    },
    Skeleton: {
      borderRadiusSM: 16,
      colorFillContent: "#E5E9F1",
      controlHeightXS: 8,
    },
    Table: {
      borderColor: "var(--color-neutral-blue-100)",
      rowHoverBg: "var(--color-base-White)",
      rowExpandedBg: "var(--color-base-White)",
    },
  },
  token: {
    colorFillContent:
      "linear-gradient(270deg, rgba(229, 233, 241, 0.05) 0%, #E5E9F1 50%)",
    colorActive: "var(--color-lavender-300)",
    colorLink: "var(--color-lavender-300)",
    colorLinkActive: "var(--color-lavender-400)",
    colorPrimary: "var(--color-lavender-300)",
    colorPrimaryHover: "var(--color-lavender-300)",
    colorPrimaryActive: "var(--color-lavender-300)",
    colorPrimaryBg: "var(--color-lavender-300)",
    colorPrimaryBgHover: "var(--color-lavender-300)",
    colorPrimaryBorder: "var(--color-lavender-400)",
    colorPrimaryBorderHover: "var(--color-lavender-400)",
    colorWarningBg: "var(--color-primary-white-shore)",
  },
};
