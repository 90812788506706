import { useMemo } from "react";
import { BILLING_PLANS } from "~src/constants";
import { useAppSelector } from "../redux/hooks";
import {
  SegmentEventNames,
  VIEW_STATE_TO_SEGMENT_LOCATION,
  segmentEvent,
} from "~src/utils";
import { viewState } from "~src/redux/typings";
import { useUpgradePlanModalContext } from "~src/contexts";

/* A hook to determine if the paywall should be displayed for a given view
 * Should fire in these cases:
 * - User is on Basic plan and has no credits for profile lookup
 * - User is on Basic plan and has no email credits for email coach
 * - User is not on Pro/Teams plan and is using Lavender in an SEP
 */
export const useShouldDisplayPaywall = (view: viewState): boolean => {
  const { user } = useAppSelector(({ userAccount }) => userAccount.settings);
  const configSettings = useAppSelector(({ config }) => config.settings);
  const { isModalOpen: isUpgradeModalOpen } = useUpgradePlanModalContext();

  const {
    billing_plan: billingPlan,
    credits,
    email_credits: emailCredits,
  } = user ?? {
    billing_plan: BILLING_PLANS.Basic,
    credits: 0,
    email_credits: 0,
  };

  const isTeam = configSettings?.isTeam ?? false;

  return useMemo(() => {
    let shouldDisplayPaywall = false;
    // Mobile paywall opens a separate upgrade pane, keep displaying paywall over content
    if (isUpgradeModalOpen && view !== "mobilePreview") {
      return false;
    }
    if (billingPlan === BILLING_PLANS.Basic) {
      if (view === "emailCoach" && emailCredits < 1) {
        shouldDisplayPaywall = true;
      } else if (view === "profile" && credits < 1) {
        shouldDisplayPaywall = true;
      } else {
        shouldDisplayPaywall = true;
      }
    }

    if (
      ![BILLING_PLANS.Trial, BILLING_PLANS.Pro, BILLING_PLANS.Teams].includes(
        billingPlan
      )
    ) {
      shouldDisplayPaywall = shouldDisplayPaywall || isTeam;
    }

    if (shouldDisplayPaywall) {
      segmentEvent(
        SegmentEventNames.PaywallDisplayed,
        VIEW_STATE_TO_SEGMENT_LOCATION[view]
      );
    }

    return shouldDisplayPaywall;
  }, [isUpgradeModalOpen, billingPlan, view, emailCredits, credits, isTeam]);
};
