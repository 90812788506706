import { BillingPlansPrices } from "~src/redux/typings";

export enum BILLING_PLANS {
  Basic = "Basic",
  Trial = "Trial",
  Starter = "Starter",
  Pro = "Pro",
  Teams = "Teams",
}

export enum BILLING_CYCLE {
  monthly = "monthly",
  annual = "annual",
}

export const DEFAULT_BILLING_PLANS: BillingPlansPrices = {
  Basic: { monthly: 0, annual: 0 },
  Trial: { monthly: 0, annual: 0 },
  Starter: { monthly: 29, annual: 319 },
  Pro: { monthly: 49, annual: 539 },
  Teams: { monthly: 69, annual: 759 },
};
