import { Divider, Empty, Flex, Popover } from "antd";
import { styled } from "styled-components";

import { Tag, Text } from "@lavender-ai/lav-components";

import { usePersonalization, useHumanticsData } from "~src/customHooks";
import { IconFrame, TagList, UlList } from "~src/component/Atoms";
import { SubTabListContainer } from "../../Shared";
import { BuildingPersonalityProfile } from "./BuildingPersonalityProfile";

import { HumanticStatus, type SorterSegment } from "~src/api/typings";
import { PERSONALIZATION_HEADER } from "~src/strings";
import {
  capitalizeFirstLetter,
  getDiscAssessmentStr,
  getOceanAssessmentStr,
  getSorterSegmentRec,
} from "~src/utils";
import { SUB_TAB_TEXT } from "~src/strings/personalization";
import { LavenderIcon } from "~src/component/typings";
import { useAppSelector } from "~src/redux/hooks";
import { PersonalizationProfilePersonalityData } from "~src/redux/typings";
import { SecondaryText } from "~src/component/Styled";

const { PERSONALITY } = PERSONALIZATION_HEADER;
const { DO_THIS, DONT_DO_THIS, WHEN_COMMUNICATING, OCEAN, DISC } = SUB_TAB_TEXT;

const PersonalityTypeText = styled.div`
  width: 100%;
  text-align: left;
  color: var(--color-neutral-purple-900);
  font-family: "Bricolage Grotesque";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.1px;
  padding-bottom: 16px;
`;

interface PersonalityContentProps {
  personality: PersonalizationProfilePersonalityData;
}

const PersonalityContent = ({ personality }: PersonalityContentProps) => {
  const {
    communication_advice,
    disc_assessment,
    ocean_assessment,
    sorter_segment,
  } = personality;

  return (
    <>
      <Flex
        align="center"
        justify="left"
        style={{ paddingBottom: "20px" }}
        vertical={true}
      >
        <PersonalityTypeText>
          {`The ${capitalizeFirstLetter(sorter_segment as SorterSegment)}`}
        </PersonalityTypeText>
        <Flex style={{ width: "100%" }}>
          <Popover
            arrow={false}
            content={
              <div style={{ width: "180px" }}>
                <SecondaryText $light size="body1">
                  {getOceanAssessmentStr(ocean_assessment)}
                </SecondaryText>
              </div>
            }
            getPopupContainer={(trigger: HTMLElement) => {
              return trigger;
            }}
            title={
              <div style={{ marginBottom: "12px" }}>
                <Text color="dark" size="subtext2">
                  {OCEAN}
                </Text>
              </div>
            }
          >
            <Tag style={{ cursor: "pointer" }} color="sapphire" size="small">
              {OCEAN}
            </Tag>
          </Popover>
          <Popover
            arrow={false}
            content={
              <div style={{ width: "180px" }}>
                <SecondaryText $light size="body1">
                  {getDiscAssessmentStr(disc_assessment)}
                </SecondaryText>
              </div>
            }
            getPopupContainer={(trigger: HTMLElement) => {
              return trigger;
            }}
            placement="topRight"
            title={
              <div style={{ marginBottom: "12px" }}>
                <Text color="dark" size="subtext2">
                  {DISC}
                </Text>
              </div>
            }
          >
            <Tag style={{ cursor: "pointer" }} color="marigold" size="small">
              {DISC}
            </Tag>
          </Popover>
        </Flex>
      </Flex>
      <Flex
        style={{
          textAlign: "left",
          paddingTop: "4px",
        }}
        vertical
      >
        <Text size="body2">
          {getSorterSegmentRec(sorter_segment as SorterSegment)}
        </Text>
        {Array.isArray(communication_advice.description) &&
          communication_advice.description.length > 0 && (
            <>
              <br />
              <Text size="body2">
                {communication_advice.description.reduce(
                  (acc, curr) => `${acc} ${curr}`
                )}
              </Text>
            </>
          )}
      </Flex>
      <Flex gap={12} style={{ textAlign: "left", paddingTop: "20px" }} vertical>
        <TagList tags={communication_advice.adjectives ?? []} size="medium" />
      </Flex>
    </>
  );
};

export const Personality = () => {
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = usePersonalization();
  const chromeId = useAppSelector((state) => state.config.chromeId);
  const {
    data,
    isError,
    isLoading: loading,
  } = useHumanticsData(chromeId, email);
  const { personality } = data;
  const { communication_advice, status } = personality;
  const isCommunicationAdviceEmpty =
    communication_advice.what_to_say === null &&
    communication_advice.what_to_avoid === null;
  const oceanAssessmentValues = Object.values(personality.ocean_assessment);
  const isOceanAssessmentEmpty =
    oceanAssessmentValues.every(
      ({ score, level }) => score === 0 && level === ""
    ) || oceanAssessmentValues.every((val) => val === "");

  return (
    <>
      {loading && <BuildingPersonalityProfile />}
      <SubTabListContainer count={1} header={PERSONALITY} loading={loading}>
        <div style={{ padding: "8px 20px 20px", width: "100%" }}>
          {status === HumanticStatus.NOT_FOUND ||
          isOceanAssessmentEmpty ||
          isError ? (
            <div style={{ margin: "auto" }}>
              <Empty description={false} />
            </div>
          ) : (
            <PersonalityContent personality={personality} />
          )}
        </div>
      </SubTabListContainer>
      <SubTabListContainer
        count={1}
        header={WHEN_COMMUNICATING}
        loading={loading}
      >
        <div style={{ padding: "20px 20px 0", width: "100%" }}>
          {status === HumanticStatus.NOT_FOUND ||
          isCommunicationAdviceEmpty ||
          isError ? (
            <div style={{ margin: "auto" }}>
              <Empty description={false} />
            </div>
          ) : (
            <>
              {communication_advice.what_to_say !== null && (
                <>
                  <Flex align="center" gap={12}>
                    <IconFrame
                      avatarShape="circle"
                      color="pear"
                      variant={LavenderIcon.IconCheck}
                    />
                    <Text size="subtext3">{DO_THIS}</Text>
                  </Flex>
                  <UlList items={communication_advice.what_to_say} />
                </>
              )}
              <Divider style={{ marginTop: 0, marginBottom: "16px" }} />
              {communication_advice.what_to_avoid !== null && (
                <>
                  <Flex align="center" gap={12}>
                    <IconFrame
                      avatarShape="circle"
                      color="peony"
                      variant={LavenderIcon.IconX}
                    />
                    <Text size="subtext3">{DONT_DO_THIS}</Text>
                  </Flex>
                  <UlList items={communication_advice.what_to_avoid} />
                </>
              )}
            </>
          )}
        </div>
      </SubTabListContainer>
    </>
  );
};
