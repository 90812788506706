import { Progress } from "antd";

interface Props {
  step: number;
  totalSteps: number;
}

const getPercentFromStep = (step: number, totalSteps: number) => {
  return (step / totalSteps) * 100;
};

export const ProgressBar = ({ step, totalSteps }: Props) => {
  return (
    <Progress
      showInfo={false}
      strokeColor="var(--color-base-White)"
      percent={getPercentFromStep(step, totalSteps)}
      trailColor="var(--color-lavender-400)"
    />
  );
};
