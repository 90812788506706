import { Flex } from "antd";

import { IconButton, Text } from "@lavender-ai/lav-components";

import { usePersonalization } from "~src/customHooks";

import { StyledCompanyBlockHeader } from "~src/component/Organisms/Personalization/Views/Feed/Company";
import { useCarouselContext } from "~src/component/Organisms/Personalization/CarouselContextProvider";
import { LavenderIcon } from "~src/component/typings";
import { SecondaryText } from "~src/component/Styled";
import { CONFIRM_INFO_FORM } from "~src/strings";
import { extractFirstName } from "~src/utils";

interface Props {
  isSaving: boolean;
}

export const Header = ({ isSaving }: Props) => {
  const { data, reset } = useCarouselContext();
  const { cta } = data;
  const {
    currentPersonalization: {
      profile: { email, name },
    },
  } = usePersonalization();
  const displayName = extractFirstName(name || "") || email || "";

  return (
    <StyledCompanyBlockHeader
      align="flex-start"
      gap={4}
      vertical
      $loading={false}
      style={{
        padding: 0,
      }}
    >
      <Flex
        align="center"
        justify="flex-start"
        gap={12}
        style={{
          padding: "16px 0",
        }}
      >
        <IconButton
          state={isSaving ? "disabled" : "initial"}
          icon={LavenderIcon.IconChevronLeft}
          variant="secondary"
          onClick={reset}
        />
        <Text color="dark" size="subtext3">
          {cta}
        </Text>
        {cta === CONFIRM_INFO_FORM.HEADER && (
          <SecondaryText $light size="body2" style={{ marginLeft: -8 }}>
            {`/ ${displayName}`}
          </SecondaryText>
        )}
      </Flex>
    </StyledCompanyBlockHeader>
  );
};
