import { useMemo, useState } from "react";
import { useStreakNotificationContext } from "~src/contexts";
import { useAppSelector, selectUserStreak } from "~src/redux";
import { selectStreakThreshold } from "~src/redux/config";
import Badge0Png from "~src/assets/images/StreakPanel/Badge0.png";
import Badge1Png from "~src/assets/images/StreakPanel/Badge1.png";
import Badge2Png from "~src/assets/images/StreakPanel/Badge2.png";
import Badge3Png from "~src/assets/images/StreakPanel/Badge3.png";
import Badge4Png from "~src/assets/images/StreakPanel/Badge4.png";
import Badge5Png from "~src/assets/images/StreakPanel/Badge5.png";
import {
  segmentEvent,
  SegmentEventLocations,
  SegmentEventNames,
} from "~src/utils";

const BadgeLookup = {
  0: Badge0Png,
  1: Badge1Png,
  2: Badge2Png,
  3: Badge3Png,
  4: Badge4Png,
  5: Badge5Png,
};

enum WorkweekDays {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
}

const workweekDaysArray = [
  WorkweekDays.Monday,
  WorkweekDays.Tuesday,
  WorkweekDays.Wednesday,
  WorkweekDays.Thursday,
  WorkweekDays.Friday,
];

// Helper function to get the weekday index (1 = Monday, ..., 5 = Friday)
const getWeekdayIndex = (dateString: string): number => {
  const date = new Date(`${dateString}T00:00:00`);
  const dayIndex = date.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
  if (dayIndex === 0 || dayIndex === 6) {
    // Return -1 for weekends
    return -1;
  }
  return dayIndex; // Return 1-5 for Monday-Friday
};

// Helper function to get the date of the most recent weekend (Saturday or Sunday)
const getMostRecentWeekendDate = (): Date => {
  const today = new Date();
  const dayIndex = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
  let daysToSubtract = 0;

  if (dayIndex === 0) {
    // Today is Sunday
    daysToSubtract = 1; // Go back to Saturday
  } else if (dayIndex === 6) {
    // Today is Saturday
    daysToSubtract = 0; // Today is the weekend
  } else {
    // Today is a weekday; find the last Saturday
    daysToSubtract = dayIndex + 1; // Go back to Saturday
  }

  const mostRecentWeekend = new Date();
  mostRecentWeekend.setDate(mostRecentWeekend.getDate() - daysToSubtract);
  mostRecentWeekend.setHours(0, 0, 0, 0); // Set to midnight

  return mostRecentWeekend;
};

const createDateString = (dateAdjust?: number) => {
  const date = new Date();
  date.setHours(0, 0, 0, 0); // Set time to midnight
  if (dateAdjust) {
    date.setDate(date.getDate() + dateAdjust);
  }
  return date.toISOString().split("T")[0];
};

export const useStreakInfo = () => {
  const {
    streak,
    emails_sent: emailsSent,
    longest_streak: longestStreak,
  } = useAppSelector(selectUserStreak);
  const streakThreshold = useAppSelector(selectStreakThreshold);
  const { setHasNewStreakLevel } = useStreakNotificationContext();
  const [previousCurrentStreakLength, setPreviousCurrentStreakLength] =
    useState<number>();

  const streakInfo = useMemo(() => {
    // Initialize the workweek map with all days set to false
    const workweekMap: Record<WorkweekDays, boolean> = {
      [WorkweekDays.Monday]: false,
      [WorkweekDays.Tuesday]: false,
      [WorkweekDays.Wednesday]: false,
      [WorkweekDays.Thursday]: false,
      [WorkweekDays.Friday]: false,
    };

    let currentStreakLength = 0;
    let workweekStreakLength = 0;
    let todayEmailsSent = 0;

    const todayDate = new Date();
    const todayDateString = createDateString();
    const yesterdayDateString = createDateString(-1);

    // Get the date of the most recent weekend (Saturday or Sunday)
    const mostRecentWeekendDate = getMostRecentWeekendDate();

    let i = 0;
    // Loop through the streak array
    for (i; i < streak.length; i++) {
      const { day, emails_sent: emailsSent } = streak[i];
      const date = new Date(`${day}T00:00:00`);
      date.setHours(0, 0, 0, 0); // Ensure time is set to midnight

      // Check if this is today
      if (day === todayDateString) {
        todayEmailsSent = emailsSent;
      }

      // Update current streak length (consecutive days meeting the threshold)
      if (emailsSent >= streakThreshold) {
        // Check if the day is consecutive
        const expectedDate = new Date();
        expectedDate.setDate(todayDate.getDate() - currentStreakLength);
        expectedDate.setHours(0, 0, 0, 0);

        if (
          date.getTime() === expectedDate.getTime() ||
          (day === yesterdayDateString && i === 0) // if they haven't met their streak today, still increment if streak goes till yesterday
        ) {
          currentStreakLength++;
        } else {
          break; // Streak broken
        }
      } else {
        break; // Streak broken
      }
      const weekdayIndex = getWeekdayIndex(day);
      // Skip if not a weekday or prior to the most recent weekend for workweek calculations
      if (weekdayIndex === -1 || date < mostRecentWeekendDate) {
        break;
      }

      const weekdayName = workweekDaysArray[weekdayIndex - 1];

      // Update workweek map
      workweekMap[weekdayName] = emailsSent >= streakThreshold;

      // Update workweek streak length
      if (emailsSent >= streakThreshold) {
        workweekStreakLength++;
      }
    }
    // run weekday calculations on rest of loop in case streak was broken but there are prior days with streaks
    for (i; i < streak.length; i++) {
      const { day, emails_sent: emailsSent } = streak[i];
      const weekdayIndex = getWeekdayIndex(day);
      const date = new Date(`${day}T00:00:00`);
      date.setHours(0, 0, 0, 0); // Ensure time is set to midnight
      // Skip if not a weekday or prior to the most recent weekend for workweek calculations
      if (weekdayIndex === -1 || date < mostRecentWeekendDate) {
        break;
      }

      // Update workweek map
      workweekMap[workweekDaysArray[weekdayIndex - 1]] =
        emailsSent >= streakThreshold;
    }

    // Check if all days in the workweek map are true
    const allWorkweekDaysTrue = workweekDaysArray.every(
      (day) => workweekMap[day]
    );

    const streakBadge = BadgeLookup[
      Math.min(workweekStreakLength, 5)
    ] as string;

    if (previousCurrentStreakLength === undefined) {
      setPreviousCurrentStreakLength(currentStreakLength);
    } else if (currentStreakLength > previousCurrentStreakLength) {
      if (streak[0]?.day === todayDateString) {
        segmentEvent(
          SegmentEventNames.StreakIncreased,
          SegmentEventLocations.Streak,
          { currentStreakLength, emailsSent, todayEmailsSent }
        );
        setHasNewStreakLevel();
      }
      setPreviousCurrentStreakLength(currentStreakLength);
    }

    return {
      emailsSent,
      longestStreak,
      workweekMap,
      workweekStreakLength,
      allWorkweekDaysTrue,
      currentStreakLength,
      todayEmailsSent,
      streakBadge,
    };
  }, [
    emailsSent,
    longestStreak,
    previousCurrentStreakLength,
    setHasNewStreakLevel,
    streak,
    streakThreshold,
  ]);

  return streakInfo;
};
