import styled, { css } from "styled-components";

import { Text, TextProps } from "@lavender-ai/lav-components";

import { PropsForStyling } from "../../../../typings";

export const StyledListHeader = styled(Text)<PropsForStyling<TextProps>>`
  ${({ size }) => {
    if (size === "subtext2") {
      return css`
        padding: 0 0 12px 0;
      `;
    } else {
      return css`
        padding: 20px 16px;
      `;
    }
  }}
`;
