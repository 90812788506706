import { ErrorResponse } from "./api";

export interface UpdateSettingsPayload {
  chromeId: chromeId;
  companyDescription: string;
  companyName: string;
  companyUrl: string;
  email: emailAddress;
  position: string;
}

export interface UpdateSettingsSuccessResponse {
  status: "success";
}
export type UpdateSettingsResponse =
  | ErrorResponse
  | UpdateSettingsSuccessResponse;
