import {
  fetchEmailSummary,
  fetchReadTheRoom,
  emailAction,
  readTheRoomEmailSummaryAction,
} from "~src/redux";
import { store } from "~src/redux/store";
import { MIN_EMAIL_BODY_WORD_COUNT, EmailBodyError } from "~src/constants";
import { EmailBefore } from "~src/redux/typings";

export const scrapeEmailData = async (
  containerDom: HTMLElement,
  chromeId: chromeId,
  lvIdentifier: lvIdentifier
) => {
  const {
    emailThreadBodyContent,
    emailThreadBodyContentFilterOutElements,
    emailThreadReply,
    emailThreadSubject,
    emailThreadTimeSend,
    emailThreadTimeSendField,
    emailThreadRecipient,
    emailThreadRecipientField,
    emailThreadEmailBefore,
    emailThreadInnerReplyEmail,
    emailThreadInnerReplyEmailTimeSentPrefix,
    emailThreadInnerReplyEmailBody,
    emailThreadInnerReplyEmailBodyFilterOutElements,
    emailThreadAuthorEmail,
    emailThreadAuthorEmailField,
    emailThreadAuthorName,
    emailThreadAuthorNameField,
  } = store.getState().config.settings?.selectors || {};

  // INFO: get the email body
  if (
    emailThreadBodyContent &&
    emailThreadReply &&
    emailThreadBodyContentFilterOutElements
  ) {
    await setBody(
      containerDom,
      lvIdentifier,
      chromeId,
      emailThreadBodyContent,
      emailThreadBodyContentFilterOutElements
    );
  }

  // INFO: get the email subject
  if (emailThreadSubject) {
    setSubject(emailThreadSubject, lvIdentifier);
  }

  // INFO: get time sent
  if (emailThreadTimeSend && emailThreadTimeSendField) {
    setTimeSent(
      containerDom,
      lvIdentifier,
      emailThreadTimeSend,
      emailThreadTimeSendField
    );
  }

  // INFO: get the recipients
  if (emailThreadRecipient && emailThreadRecipientField) {
    const emailRecipientList = getRecipients(
      containerDom,
      emailThreadRecipient,
      emailThreadRecipientField
    );
    if (emailRecipientList?.length) {
      store.dispatch(
        emailAction.updateRecipientEmailList({
          id: lvIdentifier,
          recipientEmailList: emailRecipientList,
        })
      );
    }
  }
  // INFO: get the nested threaded emails before
  if (
    emailThreadEmailBefore &&
    emailThreadInnerReplyEmail &&
    emailThreadInnerReplyEmailTimeSentPrefix &&
    emailThreadInnerReplyEmailBody
  ) {
    const emailsList = getEmailsBefore(
      containerDom,
      emailThreadEmailBefore,
      emailThreadInnerReplyEmail,
      emailThreadInnerReplyEmailTimeSentPrefix,
      emailThreadInnerReplyEmailBody,
      emailThreadInnerReplyEmailBodyFilterOutElements ?? "" // INFO: this is optional on some platforms
    );
    if (emailsList?.length) {
      store.dispatch(
        emailAction.updateEmailsBefore({
          id: lvIdentifier,
          emailsBefore: emailsList,
        })
      );
    }
  }
  // INFO: get Author Name
  if (emailThreadAuthorName && emailThreadAuthorNameField) {
    setAuthorName(
      containerDom,
      lvIdentifier,
      emailThreadAuthorName,
      emailThreadAuthorNameField
    );
  }
  // INFO: get Author Email
  if (emailThreadAuthorEmail) {
    setAuthorEmail(
      containerDom,
      lvIdentifier,
      emailThreadAuthorEmail,
      emailThreadAuthorEmailField || ""
    );
  }

  // INFO: make the email analysis api call,
  await getAllData(chromeId, lvIdentifier);

  console.debug("state after all data = ", store.getState());
};
const setAuthorEmail = (
  containerDom: HTMLElement,
  lvIdentifier: lvIdentifier,
  emailThreadAuthorEmail: string,
  emailThreadAuthorEmailField: string
) => {
  const emailAuthorEmail = containerDom.querySelector(emailThreadAuthorEmail)!;
  if (emailAuthorEmail !== null) {
    let authorEmail = "";
    if (emailThreadAuthorEmailField) {
      authorEmail =
        emailAuthorEmail.getAttribute(emailThreadAuthorEmailField) || "";
    } else {
      authorEmail = (emailAuthorEmail as HTMLElement).innerText;
    }

    if (authorEmail !== null) {
      store.dispatch(
        emailAction.updateAuthorEmail({ id: lvIdentifier, authorEmail })
      );
    }
  }
};
const setAuthorName = (
  containerDom: HTMLElement,
  lvIdentifier: lvIdentifier,
  emailThreadAuthorName: string,
  emailThreadAuthorNameField: string
) => {
  const emailAuthorName = containerDom.querySelector<HTMLElement>(
    emailThreadAuthorName
  );
  if (emailAuthorName !== null) {
    const authorName = emailAuthorName.getAttribute(emailThreadAuthorNameField);
    if (authorName !== null) {
      store.dispatch(
        emailAction.updateAuthorName({ id: lvIdentifier, authorName })
      );
    }
  }
  const emailAuthorEmail = containerDom.querySelector<HTMLElement>(
    emailThreadAuthorName
  );
  if (emailAuthorEmail !== null) {
    const authorEmail = emailAuthorEmail.getAttribute(
      emailThreadAuthorNameField
    );
    if (authorEmail !== null) {
      store.dispatch(
        emailAction.updateAuthorEmail({ id: lvIdentifier, authorEmail })
      );
    }
  }
};
// INFO: get the nested threaded emails before, this is use for email analysis
export const getEmailsBefore = (
  containerDom: HTMLElement,
  emailThreadEmailBefore: string,
  emailThreadInnerReplyEmail: string,
  emailThreadInnerReplyEmailTimeSentPrefix: string,
  emailThreadInnerReplyEmailBody: string,
  emailThreadInnerReplyEmailBodyFilterOutElements: string
): EmailBefore[] => {
  const cloneContainerDom = containerDom.cloneNode(true) as HTMLElement;
  const emailsBeforeList = cloneContainerDom.querySelectorAll(
    emailThreadEmailBefore
  );
  if (emailsBeforeList.length) {
    const emailsList: EmailBefore[] = [];
    // INFO: for each email, remove the inner threaded emails
    emailsBeforeList.forEach((emailBefore) => {
      const timeNameEmailDom = emailBefore.querySelector<HTMLElement>(
        emailThreadInnerReplyEmail
      );
      const authorEmail = getAuthorEmailAddresses(timeNameEmailDom?.innerText);

      const authorName = getAuthorName(timeNameEmailDom?.innerText);
      const timeSent = getTimeSent(
        timeNameEmailDom?.innerText,
        emailThreadInnerReplyEmailTimeSentPrefix
      );
      const emailBody = getBody(
        emailBefore as HTMLElement,
        emailThreadInnerReplyEmailBody,
        emailThreadInnerReplyEmailBodyFilterOutElements
      );

      emailsList.push({
        authorEmail,
        authorName,
        timeSent,
        recipients: [],
        emailBody,
      } as never);
    });
    return emailsList;
  }
  return [];
};
const setTimeSent = (
  containerDom,
  lvIdentifier,
  emailThreadTimeSend,
  emailThreadTimeSendField
) => {
  const emailTimeSent = containerDom.querySelector(
    emailThreadTimeSend
  ) as HTMLElement;
  if (emailTimeSent !== null) {
    const timeSent = emailTimeSent.getAttribute(emailThreadTimeSendField);
    if (timeSent !== null) {
      const sentDate = new Date(timeSent);
      store.dispatch(
        emailAction.updateTimeSent({
          id: lvIdentifier,
          timeSent: sentDate.getTime(),
        })
      );
    }
  }
};
const getTimeSent = (rawString, prefix) => {
  const upperCaseRawString = rawString.toUpperCase();
  // INFO: get the index of the prefix plus the length of the prefix and 1 space
  const prefixIndex =
    upperCaseRawString.indexOf(prefix.toUpperCase()) + prefix.length;
  // INFO: get the index of the am or pm plus 2 for the space
  const amPmIndex = getAmPmIndex(rawString) + 2;
  const timeSent = rawString.substring(prefixIndex, amPmIndex).trim();
  const date = parseDateString(timeSent);
  return date.getTime();
};
const parseDateString = (dateString) => {
  // Define month abbreviations
  const months = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  // Split the input string into parts
  const parts = dateString.split(/[\s,]+/);
  // Extract date and time components
  const day = parseInt(parts[2], 10);
  const month = months[parts[1]];
  const year = parseInt(parts[3], 10);
  const timeParts = parts[5].split(":");
  let hour = parseInt(timeParts[0], 10);
  const minute = parseInt(timeParts[1], 10);

  // Determine AM/PM
  const isPM = parts[6].toLowerCase() === "pm";
  if (isPM && hour !== 12) {
    hour += 12;
  } else if (!isPM && hour === 12) {
    hour = 0;
  }

  // Create a JavaScript Date object
  const date = new Date(year, month, day, hour, minute);

  return date;
};

const getAmPmIndex = (rawString) => {
  const upperCaseRawString = rawString.toUpperCase();
  const amPmIndex =
    (upperCaseRawString.indexOf("AM") > -1 &&
      upperCaseRawString.indexOf("AM")) ||
    (upperCaseRawString.indexOf("PM") > -1 && upperCaseRawString.indexOf("PM"));
  return amPmIndex;
};
const getAuthorEmailAddresses = (rawString) => {
  const emailRe = /\b\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,13})+\b/g;
  const emailAddressMatchList = rawString.match(emailRe);

  if (emailAddressMatchList !== null) {
    return emailAddressMatchList[0];
  }
  return "";
};

const getAuthorName = (rawString) => {
  const amPmIndex = getAmPmIndex(rawString);
  const indexOfStartOfEmail = rawString.indexOf("<");
  return rawString.substring(amPmIndex + 2, indexOfStartOfEmail).trim();
};

export const getRecipients = (
  containerDom: HTMLElement,
  emailThreadRecipient: string,
  emailThreadRecipientField: string
): string[] => {
  const emailRecipientToList =
    containerDom.querySelectorAll(emailThreadRecipient);
  if (emailRecipientToList.length) {
    const emailList = [];

    emailRecipientToList.forEach((emailRecipientTo) => {
      const emailAddress = emailRecipientTo.getAttribute(
        emailThreadRecipientField
      );
      if (emailAddress !== null) {
        // INFO : never for typescript to not complain
        emailList.push(emailAddress as never);
      }
    });
    return emailList;
  }
  return [];
};

const setBody = async (
  containerDom: HTMLElement,
  lvIdentifier: lvIdentifier,
  chromeId: chromeId,
  emailThreadBodyContent: string,
  emailThreadBodyContentFilterOutElements: string
) => {
  const emailBodyString = getBody(
    containerDom,
    emailThreadBodyContent,
    emailThreadBodyContentFilterOutElements
  );
  if (emailBodyString) {
    // INFO: store the email body
    store.dispatch(
      emailAction.updateBodyString({ id: lvIdentifier, body: emailBodyString })
    );
    const emailBodyLength = emailBodyString.split(" ").length;

    if (emailBodyLength >= MIN_EMAIL_BODY_WORD_COUNT) {
      // INFO: get the email summary, now that we have the email body
      await callEmailSummary(lvIdentifier, chromeId, emailBodyString);
    } else if (emailBodyLength < MIN_EMAIL_BODY_WORD_COUNT) {
      store.dispatch(
        readTheRoomEmailSummaryAction.setAnalyticsErrorMessage({
          lvIdentifier,
          error: EmailBodyError,
        })
      );
    }
  }
};
const getBody = (
  containerDom: HTMLElement,
  emailThreadBodyContent: string,
  emailThreadBodyContentFilterOutElements: string
) => {
  const cloneContainerDom = containerDom.cloneNode(true) as HTMLElement;
  const emailBody = cloneContainerDom.querySelector<HTMLElement>(
    emailThreadBodyContent
  );

  if (emailThreadBodyContentFilterOutElements) {
    // INFO: filter out the elements from the email body like quoted emails
    const filterElements = cloneContainerDom.querySelectorAll(
      emailThreadBodyContentFilterOutElements
    );

    if (filterElements !== null) {
      filterElements.forEach((element) => {
        if (element?.parentElement !== null) {
          element.parentElement.removeChild(element);
        }
      });
    }
  }

  // INFO: get the email body string but none of the quoted emails
  let emailBodyString = "";
  if (emailBody !== null) {
    emailBodyString = emailBody.outerHTML;
    return emailBodyString;
  }
};
const setSubject = (emailThreadSubject, lvIdentifier) => {
  const emailSubject = window.top?.document.querySelector(
    emailThreadSubject
  ) as HTMLElement;
  if (emailSubject !== null) {
    const subject =
      emailSubject.innerText || (emailSubject as HTMLInputElement).value;
    store.dispatch(
      emailAction.updateSubjectString({ id: lvIdentifier, subject })
    );
  }
};
const callEmailSummary = async (
  lvIdentifier: lvIdentifier,
  chromeId: chromeId,
  body: string
) => {
  await store
    .dispatch(fetchEmailSummary({ lvIdentifier, chromeId, body }))
    .unwrap();
};

const getAllData = async (chromeId: chromeId, lvIdentifier: lvIdentifier) => {
  const state = store.getState();
  const {
    authorName,
    authorEmail,
    subject,
    body,
    timeSent,
    emailsBefore,
    recipientEmailList,
  } = state.emailData[lvIdentifier];
  const bodyLength = body ? body.split(" ").length : 0;
  // INFO: authorName is optional
  if (
    lvIdentifier &&
    chromeId &&
    body &&
    subject &&
    timeSent &&
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    recipientEmailList &&
    // INFO: if the body is too short, don't call the api
    bodyLength >= MIN_EMAIL_BODY_WORD_COUNT
  ) {
    // INFO: fetch the read the room and email summary
    await store
      .dispatch(
        fetchReadTheRoom({
          lvIdentifier,
          chromeId,
          body,
          subject,
          author_email: authorEmail,
          author_name: authorName,
          time_sent: timeSent,
          recipients: recipientEmailList.toString(),
          emails_before: JSON.stringify(emailsBefore),
        })
      )
      .unwrap();
  } else if (bodyLength < MIN_EMAIL_BODY_WORD_COUNT) {
    store.dispatch(
      readTheRoomEmailSummaryAction.setAnalyticsErrorMessage({
        lvIdentifier,
        error: EmailBodyError,
      })
    );
  }
};
