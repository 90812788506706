import { useCallback, useEffect, useState } from "react";

import { store } from "~src/redux/store";
import { configActions, fetchCRMUser, fetchUserAccount } from "~src/redux";
import {
  authenticateTeamsAccount,
  injectCustomCSS,
  isExtensionBlocked,
  isIframe,
} from "~src/utils";
import { CreateLavenderAnywhere } from "~src/layout";
import {
  LAVENDER_ANYWHERE,
  LAVENDER_ANYWHERE_URL,
} from "~src/constants/general";
import { runCustomJS } from "~src/platform";
import {
  useMainLoopState,
  useCheckForNewPanelRenderCallback,
  setupUserpilotOnboarding,
} from ".";
import { listenForNewEmailThreads } from "../emailThreads";

export const useRenderNewExtension = () => {
  const { chromeId, userExperience, isFetchingChromeId } = useMainLoopState();
  const [hasRenderedExtension, setHasRenderedExtension] = useState(false);
  const runRenderThread = useRunRenderThread();

  // Launch new extension effect
  useEffect(() => {
    void (async () => {
      const platform = store.getState().config.settings?.platform;
      if (
        hasRenderedExtension ||
        !platform ||
        isFetchingChromeId !== "hasFetched"
      ) {
        return;
      }

      setHasRenderedExtension(true);
      const state = store.getState();

      injectCustomCSS();
      runCustomJS(platform);
      void setupUserpilotOnboarding();

      if (userExperience === LAVENDER_ANYWHERE) {
        // Prevent running Lavender Anywhere in page's iframes
        if (isIframe()) return;

        console.debug("Lavender Anywhere");
        let isAnywhereEnabled = false;
        if (chromeId) {
          console.debug("Lavender Anywhere getting user account");
          await store.dispatch(fetchUserAccount({ chromeId })).unwrap();
          const state = store.getState(); //refresh state after user load
          isAnywhereEnabled =
            state.userAccount?.settings?.user?.settings.lavender_everywhere ??
            false;
        }
        // INFO: if the user the window pop out from the popup.html then we need to enable Lavender Anywhere
        if (window.location.href === LAVENDER_ANYWHERE_URL) {
          isAnywhereEnabled = true;
        }
        if (isAnywhereEnabled) {
          CreateLavenderAnywhere();
        }
        return;
      } else if (userExperience === "crmTeams") {
        // INFO: update the chromeId if user is on a CRM site
        if (chromeId && !Object.keys(state.config.crmUser ?? {}).length) {
          void store
            .dispatch(fetchCRMUser({ platform, chromeId }))
            .unwrap()
            .then(authenticateTeamsAccount);
        }
        //INFO: if Teams website is ignored then remove the extension elements and stop the extension from running
        if (isExtensionBlocked()) {
          return;
        }
      }

      if (chromeId) {
        void store.dispatch(fetchUserAccount({ chromeId }));
      }
      store.dispatch(configActions.setMainPollingInterval(1));
      runRenderThread();
    })();
  }, [
    userExperience,
    chromeId,
    hasRenderedExtension,
    runRenderThread,
    isFetchingChromeId,
  ]);

  return null;
};

const useRunRenderThread = () => {
  const [checkForNewPanels, unmountRemovedPanels] =
    useCheckForNewPanelRenderCallback();

  return useCallback(() => {
    const runRenderThread = () => {
      // prevent running if polling interval has been reset
      if (store.getState().config.mainPollingInterval !== -1) {
        setTimeout(() => {
          checkForNewPanels();
          window.requestAnimationFrame(runRenderThread);
        }, 67); // for snappiness aiming for 15fps
      }
    };

    const runCleanupThread = () => {
      if (store.getState().config.mainPollingInterval !== -1) {
        setTimeout(() => {
          unmountRemovedPanels();
          window.requestAnimationFrame(runCleanupThread);
        }, 2000); // not urgent, run cleanup every 2s
      }
    };

    const runEmailThreadCheck = () => {
      if (store.getState().config.mainPollingInterval !== -1) {
        setTimeout(() => {
          listenForNewEmailThreads();
          window.requestAnimationFrame(runEmailThreadCheck);
        }, 500); // less urgent and heavy request, run every 500ms
      }
    };

    window.requestAnimationFrame(runRenderThread);
    window.requestAnimationFrame(runCleanupThread);
    window.requestAnimationFrame(runEmailThreadCheck);
  }, [checkForNewPanels, unmountRemovedPanels]);
};
