import { PropsWithChildren } from "react";
import { Form as AntdForm, FormProps } from "antd";

import "./form.css";

export const Form = ({ children, ...props }: PropsWithChildren<FormProps>) => {
  return (
    <AntdForm className="lv-form" {...props}>
      {children}
    </AntdForm>
  );
};
