export const getBodyElement = (
  iframeSelector: string | undefined,
  childSelector: string,
  iframeChildSelector: string | undefined,
  container: HTMLElement
): HTMLElement | null => {
  let iframeDocument: Document | null = null;
  if (iframeSelector) {
    const iframe = container.querySelector<HTMLIFrameElement>(iframeSelector);
    iframeDocument =
      iframe?.contentDocument || iframe?.contentWindow?.document || null;
  }
  if (iframeDocument && iframeChildSelector) {
    return iframeDocument.querySelector<HTMLElement>(iframeChildSelector);
  }
  if (iframeDocument && !iframeChildSelector) {
    return iframeDocument.querySelector<HTMLElement>(childSelector);
  }
  return container.querySelector<HTMLElement>(childSelector);
};
