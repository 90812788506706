import { styled } from "styled-components";
import { Icon } from "@lavender-ai/lav-components";

import { useAppSelector } from "~src/redux/hooks";
import { LavenderIcon } from "~src/component/typings";

const DaysRemaining = styled.div`
  display: flex;
  width: 100%;
  padding: var(--size-10, 10px);
  margin: 16px 0 0;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--radius-md-lrg, 12px);
  background: var(--color-marigold-50, #fffae5);
`;

const ExtendedDaysRemaining = styled.div`
  display: flex;
  width: 100%;
  padding: var(--size-10, 10px);
  margin: 16px 0 0;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--radius-md-lrg, 12px);
  background: var(--color-lavender-50, #ece2f9);
`;

const calculateTrialDaysLeft = (trialEnd: Date) => {
  const today = new Date();
  const timeDiff = trialEnd.getTime() - today.getTime();
  const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return daysLeft;
};

export const TrialDaysRemaining = () => {
  const user = useAppSelector((state) => state.userAccount.settings.user);
  if (!user) return null;
  const daysLeftInTrial = calculateTrialDaysLeft(new Date(user.trial_end));

  const trialDaysRemaining = !user.has_stripe ? (
    <DaysRemaining>
      <Icon
        color="warning"
        variant={LavenderIcon.IconAlertTriangleFilled}
        size="small"
        style={{ marginRight: 8 }}
      />
      {daysLeftInTrial} days left on your free trial.
    </DaysRemaining>
  ) : (
    <ExtendedDaysRemaining>
      <Icon
        color="lavender"
        variant={LavenderIcon.IconInfoSquareRoundedFilled}
        size="small"
        style={{ marginRight: 8 }}
      />
      {daysLeftInTrial} days left on your free trial.
    </ExtendedDaysRemaining>
  );

  return trialDaysRemaining;
};
