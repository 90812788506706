import { StartMyEmailCart, StartMyEmailCartPayload } from "~src/api/typings";
import { ErrorIdentifiers, parseAndLogError } from "~src/logger";
import { isValuePropWebsocketJSONMessage } from "~src/typeGuards";

import { typedEntries } from "./types";

export const needsRefreshValueProps = (
  generated: StartMyEmailCart | null | undefined,
  cart: StartMyEmailCartPayload
): boolean => {
  if (!generated) {
    return Object.keys(cart.sections).length > 0;
  }
  for (const [section, cartSectionItems] of typedEntries(cart.sections)) {
    const generatedSection = generated[section];
    if (generatedSection === undefined || generatedSection === null) {
      return true;
    }
    const vpSectionItems = Object.values(generatedSection);
    for (const item of cartSectionItems ?? []) {
      const found = vpSectionItems.find((vpItem) => vpItem.id === item.id);
      if (!found?.value) {
        return true;
      }
    }
  }
  return false;
};

export const filterValuePropsMessages = (message: MessageEvent<string>) => {
  try {
    const parsedMessage = JSON.parse(message.data);
    return isValuePropWebsocketJSONMessage(parsedMessage);
  } catch (error) {
    parseAndLogError(error, ErrorIdentifiers.WS_JSON_PARSE_ERROR, {
      eventData: message.data,
    });
    return false;
  }
};
