import { useCallback } from "react";
import { ConfigProvider, Flex } from "antd";
import { styled } from "styled-components";

import { Avatar, Text } from "@lavender-ai/lav-components";

import {
  useStartMyEmail,
  useRankedFeedApi,
  useRankedFeedApiDependencies,
  useFeatureFlag,
} from "~src/customHooks";
import { ExtensionButton } from "~src/component/Atoms";
import {
  StageDropdown,
  UserDataAvatar,
  UserDataEntity,
} from "~src/component/Molecules";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
} from "~src/component/Utilities/Loading";

import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import {
  FullWidthFlex,
  SecondaryText,
  StyledDropdownContainer,
} from "~src/component/Styled";
import { PropsForStyling } from "~src/component/typings";
import { CLEARBIT_LOGO_URL } from "~src/constants";
import { SME_CAPTION, SME_HEADER } from "~src/strings";
import { icebreakerCount } from "~src/utils";
import { FeatureFlagEnum } from "~src/api/typings";
import { EmailAnalysisStage as Stage } from "~src/redux/typings";

interface HeaderTextProps {
  count: number;
  displayName: string;
  loading: boolean;
  onClick?: () => void;
}

const HeaderText = ({
  count,
  displayName,
  loading,
  onClick,
}: HeaderTextProps) => {
  const {
    currentPersonalization: {
      profile: { email },
      company: { name: companyName },
    },
  } = useStartMyEmail();
  const { areDependenciesLoading } = useRankedFeedApiDependencies(email);
  const {
    data: { header, cta, caption },
  } = useRankedFeedApi(email, areDependenciesLoading);
  const errorMessage =
    header || SME_CAPTION.WE_COULDNT_FIND(displayName, companyName);
  const errorCaption = caption || SME_HEADER.USE_THE_PERSONALIZATION;

  return (
    <div className="lv-icebreakers-header-text">
      <ConfigProvider
        theme={{
          components: {
            Skeleton: {
              borderRadiusXS: 30,
              controlHeightXS: 10,
            },
          },
        }}
      >
        <CustomSkeleton
          loading={loading}
          variant={CustomSkeletonVariant.simpleListItem}
        >
          <Flex gap="middle" vertical>
            {count > 0 ? (
              <>
                <Text size="subtext5">
                  <span style={{ color: "var(--color-lavender-400)" }}>
                    {SME_HEADER.RELEVANT_ICEBREAKERS(count)}{" "}
                  </span>
                  <span>{SME_HEADER.FOUND_TO_KICKOFF}</span>
                  <span style={{ color: "var(--color-lavender-400)" }}>
                    {` ${displayName}!`}
                  </span>
                </Text>
                <SecondaryText size="body1">
                  {SME_CAPTION.SELECT_A_FEW}
                </SecondaryText>
              </>
            ) : (
              <>
                <Text size="subtext5">{errorMessage}</Text>
                <SecondaryText size="body1">{errorCaption}</SecondaryText>
                {cta && (
                  <ExtensionButton
                    onClick={onClick}
                    size="medium"
                    text={cta}
                    variant="primary"
                  />
                )}
              </>
            )}
          </Flex>
        </CustomSkeleton>
      </ConfigProvider>
    </div>
  );
};

const StyledHeaderWrapper = styled(Flex)`
  .lv-icebreakers-header-text {
    padding: 0 32px 32px 32px;
    text-align: left;
    word-break: break-word;
  }
`;

interface StyledHeaderProps {
  isLoading: boolean;
}

const StyledHeader = styled(Flex)<PropsForStyling<StyledHeaderProps>>`
  padding: 40px 32px 28px 32px;

  ${StyledDropdownContainer} {
    align-items: center;
    justify-content: center;
  }
`;

export interface Props {
  displayName: string;
  stage: Stage;
  setStage: (stage: Stage) => void;
}

export const Header = ({ displayName, stage, setStage }: Props) => {
  const smeReplyOn = useFeatureFlag(FeatureFlagEnum.sme_reply);
  const {
    currentPersonalization: {
      company: { domain, logo },
      profile: { avatar, loading: profileLoading, email },
    },
  } = useStartMyEmail();

  const { areDependenciesLoading } = useRankedFeedApiDependencies(email);

  const {
    data: { alert, cta, rankedFeed },
    isLoading,
  } = useRankedFeedApi(email, areDependenciesLoading);
  const { next } = useCarouselContext();

  const isRankedFeedLoading = areDependenciesLoading || isLoading || !!alert;
  const count = icebreakerCount(rankedFeed);

  const onClick = useCallback(() => {
    next(ModalType.ProfileForm, {
      openedBy: email,
      cta,
    });
  }, [cta, email, next]);

  const onStageSelect = useCallback(
    ({ key }: { key: string }) => {
      setStage(Stage[key]);
    },
    [setStage]
  );

  return (
    <StyledHeaderWrapper gap={0} vertical>
      <StyledHeader align="center" gap="small">
        <FullWidthFlex>
          <Avatar.Group>
            <UserDataAvatar
              loading={profileLoading}
              size="large"
              src={avatar}
              usePlaceholderFallback
            />
            <UserDataAvatar
              entity={UserDataEntity.company}
              loading={profileLoading}
              fallbackSrc={`${CLEARBIT_LOGO_URL}${domain}`}
              size="large"
              src={logo}
              usePlaceholderFallback
            />
          </Avatar.Group>
          {!cta && smeReplyOn && (
            <StageDropdown
              stage={stage}
              onSelect={onStageSelect}
              placement="bottomLeft"
              variant="plain"
            />
          )}
        </FullWidthFlex>
      </StyledHeader>
      {(cta || stage === Stage.cold) && (
        <HeaderText
          count={count}
          displayName={displayName}
          onClick={onClick}
          loading={isRankedFeedLoading}
        />
      )}
    </StyledHeaderWrapper>
  );
};
