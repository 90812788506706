import { PersonalizationEvent } from "./personalization";

export interface INews {
  feed: PersonalizationEvent[];
  loading: boolean;
}

export type RecipientNewsMap = Record<emailAddress, INews | undefined>;

export interface NewsData {
  toAddresses: emailAddress[];
  newsData: RecipientNewsMap;
}
