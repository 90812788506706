import { useCallback } from "react";
import { QueryStatus } from "@reduxjs/toolkit/query";

import {
  APIResponseStatus,
  FeatureFlagEnum,
  CartItem,
  StartMyEmailCart,
  ValuePropWSResponse,
} from "~src/api/typings";
import { useValuePropsMutationContext } from "~src/contexts";
import {
  selectChromeId,
  selectUserValuePropsGenerationByRecipient,
  selectValuePropsGenerationByRecipient,
  selectValuePropsStatusByRecipient,
  useAppDispatch,
  useAppSelector,
  useUpsertValuePropsMutation,
  valuePropsActions,
} from "~src/redux";
import { isLavenderAPIError } from "~src/typeGuards";

import { useFeatureFlag, useStartMyEmail, useUserSetting } from "./";

interface IUseValuePropGeneration {
  currentGeneration: StartMyEmailCart | null;
  currentStatus: APIResponseStatus;
  getValuePropGenerationValue: (section: string, id: string) => string;
  updateValueProps: (
    newCart: StartMyEmailCart,
    cartItemUpdates: CartItem[],
    email: emailAddress
  ) => Promise<string | undefined>;
  // User setting to generate value props instead of user entering them in
  // Requires isValuePropGenerationOn to be true
  isUserValuePropGenerationOn: boolean;
  // Websocket state
  isValuePropGenerationStreaming: boolean;
  // HTTP state
  isValuePropGenerationSuccess: boolean;
  // Feature flag to generate value props instead of user entering them in
  isValuePropGenerationOn: boolean;
}

export const useValuePropGeneration = (): IUseValuePropGeneration => {
  const dispatch = useAppDispatch();
  const chromeId = useAppSelector(selectChromeId);
  const isValuePropGenerationOn = useFeatureFlag(
    FeatureFlagEnum.sme_value_props_generation
  );
  const isUserSettingValuePropGenerationOn = Boolean(
    useUserSetting("value_prop_generation")
  );
  const isUserValuePropGenerationOn =
    isValuePropGenerationOn && isUserSettingValuePropGenerationOn;
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = useStartMyEmail();

  const [upsertValueProps] = useUpsertValuePropsMutation();
  const { generatedValuePropsData, generatedValuePropsStatus } =
    useValuePropsMutationContext();

  const isValuePropGenerationStreaming = false;

  //*Handle success from the http response regardless of the websockets flag to handle dropped connections
  const isValuePropGenerationSuccess =
    generatedValuePropsStatus === QueryStatus.fulfilled &&
    generatedValuePropsData?.status === APIResponseStatus.success &&
    generatedValuePropsData?.generated !== undefined;

  const currentGeneration = useAppSelector((state) =>
    selectValuePropsGenerationByRecipient(state, email)
  );
  const currentUserGeneration = useAppSelector((state) =>
    selectUserValuePropsGenerationByRecipient(state, email)
  );
  const currentStatus = useAppSelector((state) =>
    selectValuePropsStatusByRecipient(state, email)
  );

  const getValuePropGenerationValue = useCallback(
    (section: string, id: string) => {
      if (isUserValuePropGenerationOn) {
        return currentGeneration?.[section]?.[id]?.value || "";
      } else {
        return currentUserGeneration?.[section]?.[id]?.user_value || "";
      }
    },
    [currentGeneration, currentUserGeneration, isUserValuePropGenerationOn]
  );

  const updateValueProps = useCallback(
    async (
      newCart: StartMyEmailCart,
      cartItemUpdates: CartItem[],
      email: emailAddress
    ) => {
      const newValuePropGeneration: ValuePropWSResponse = {
        generated: newCart,
        status: APIResponseStatus.success,
        type: "value_prop_generation",
      };

      const result = await upsertValueProps({
        cart: cartItemUpdates,
        chromeId,
        email,
      });

      if ("error" in result && isLavenderAPIError(result.error)) {
        return result.error.message;
      }

      if (isUserValuePropGenerationOn) {
        dispatch(
          valuePropsActions.setCurrentValueProps({
            ...newValuePropGeneration,
            email,
          })
        );
      } else {
        dispatch(
          valuePropsActions.setCurrentUserValueProps({
            ...newValuePropGeneration,
            email,
          })
        );
      }
    },
    [upsertValueProps, chromeId, isUserValuePropGenerationOn, dispatch]
  );

  return {
    currentGeneration,
    currentStatus,
    getValuePropGenerationValue,
    isUserValuePropGenerationOn,
    isValuePropGenerationOn,
    isValuePropGenerationStreaming,
    isValuePropGenerationSuccess,
    updateValueProps,
  };
};
