import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { APIEndpoints } from "~src/api";
import { REFRESH_INTERVAL } from "~src/constants";
import { ErrorIdentifiers, parseAndLogError } from "~src/logger";

import {
  GetPodcastsData,
  GetPodcastsPayload,
  GetPodcastsResponse,
} from "./typings";

export const podcastsApiSlice = createApi({
  reducerPath: "podcastsApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.BACKEND_API}/api/` }),
  endpoints: (builder) => ({
    getPodcasts: builder.query<GetPodcastsData, GetPodcastsPayload>({
      query: ({ email, chromeId = "" }) => ({
        url: APIEndpoints.podcasts,
        params: { email, chrome_id: chromeId },
      }),
      transformErrorResponse: (response, _meta, arg) => {
        parseAndLogError(response, ErrorIdentifiers.API_ERROR, {
          email: arg.email,
          request: APIEndpoints.podcasts,
        });
        return {
          data: [],
        };
      },
      transformResponse: (response: GetPodcastsResponse, _meta) =>
        response?.results?.results ?? [],
      keepUnusedDataFor: REFRESH_INTERVAL.FEED,
    }),
  }),
});

export const podcastsApiReducer = podcastsApiSlice.reducer;
export const { useGetPodcastsQuery } = podcastsApiSlice;
