/**
 * RTK Query stores fetched data in the Redux store as a cache to return instead of making the API request. Cache
 * remains while any subscribed components are mounted and a default of 60 seconds after they all unmount. Add custom
 * times to REFRESH_INTERVAL below to keep the cache for longer.
 */
export const REFRESH_INTERVAL = {
  FEED: 1000 * 60 * 60 * 7, // 7 hours
};

export const WS_PREFIX = (process.env.BACKEND_API || "").replace("http", "ws");
export const WS_URL = `${WS_PREFIX}/ws`;

export const FRONTEND_URLS = {
  // Root
  dashboard: `${process.env.DASHBOARD_URL}`,
  outlook: `${process.env.OUTLOOK_URL}`,
  // Sign-in
  login: `${process.env.DASHBOARD_URL}/sign-in`,
  teamsIntegration: `${process.env.DASHBOARD_URL}/app/settings/integrations`,
  // Settings
  billing: `${process.env.DASHBOARD_URL}/app/settings/billing`,
  extensionSettings: `${process.env.DASHBOARD_URL}/app/settings/extension-preferences`,
};
