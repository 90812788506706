import { ITweetsContext, useTweetsContext } from "~src/contexts";
import { NetworkStatus, RecipientTweetsMap } from "~src/redux/typings";
import { useAppSelector } from "~src/redux";
import { useMemo } from "react";

interface IUseTweets extends ITweetsContext {
  loading: boolean;
  status: NetworkStatus;
  lastUpdated: number | null;
  tweets: RecipientTweetsMap;
}

export const useTweets = (): IUseTweets => {
  const {
    tweets: tweetsObj,
    status = NetworkStatus.idle,
    lastUpdated,
  } = useAppSelector(({ feed }) => feed);

  const tweets = useMemo(() => tweetsObj, [tweetsObj]);

  const { currentTweets, resetTweetsContext, setTweetsContext } =
    useTweetsContext();

  return {
    loading: status === NetworkStatus.loading,
    status,
    lastUpdated,
    tweets,
    currentTweets,
    resetTweetsContext,
    setTweetsContext,
  };
};
