import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import {
  Avatar,
  Button,
  Icon,
  Text,
  Tooltip,
} from "@lavender-ai/lav-components";

import {
  useDomNodes,
  useFeatureFlag,
  useLvIdentifier,
  useUserSetting,
} from "~src/customHooks";
import {
  mainPanelAction,
  selectSelectors,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";
import {
  segmentEvent,
  SegmentEventLocations,
  SegmentEventNames,
} from "~src/utils";
import { BOOST_SCORE_BUTTON_CLASSNAME, FRONTEND_URLS } from "~src/constants";
import { SUGGESTIONS } from "~src/strings";

import { createOverlayElement } from "./createOverlayElement";
import {
  Container,
  TextContent,
  TextPrefix,
  TextContainer,
  TextDescriptionContainer,
  CTAContainer,
  CTAButtonRow,
} from "./BoostScorePopup.styled";
import { FeatureFlagEnum } from "~src/api/typings";

export const BoostScorePopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [hasAttachedToSendButton, setHasAttachedToSendButton] = useState(false);
  const { panelParent } = useDomNodes();
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const { sendButton = "" } = useAppSelector(selectSelectors) ?? {};
  const showBoostSuggestionSetting = !useUserSetting(
    "hide_boost_non_a_email_suggestion"
  );
  const suggestBoostEmailFF = useFeatureFlag(
    FeatureFlagEnum.suggest_boost_non_a_emails
  );
  const {
    analysis: { dynamic_ceq },
    stage,
  } = useAppSelector(({ emailAnalysis }) => emailAnalysis[lvIdentifier]);

  // Enable popup if it's a cold email, the feature flag is on, and the score is lower than an A (but also defined != -1)
  const enablePopup = useMemo(
    () =>
      suggestBoostEmailFF &&
      showBoostSuggestionSetting &&
      dynamic_ceq < 90 &&
      dynamic_ceq >= 0 &&
      stage === "cold",
    [dynamic_ceq, showBoostSuggestionSetting, stage, suggestBoostEmailFF]
  );

  const sendButtonElement = useMemo(
    () =>
      sendButton
        ? panelParent?.querySelector<HTMLButtonElement>(sendButton)
        : undefined,
    [panelParent, sendButton]
  );

  // Insert an invisible overlay over the send button to block first click
  useEffect(() => {
    if (
      hasAttachedToSendButton ||
      !enablePopup ||
      !panelParent ||
      !sendButtonElement
    ) {
      return;
    }

    setHasAttachedToSendButton(true);
    const overlay = createOverlayElement(sendButtonElement, setShowPopup);
    sendButtonElement.offsetParent?.appendChild(overlay);
  }, [sendButtonElement, enablePopup, hasAttachedToSendButton, panelParent]);

  // Open email coach and click the fix it all button
  const boostScoreCB = useCallback(() => {
    setShowPopup(false);
    segmentEvent(
      SegmentEventNames.BoostScoreClicked,
      SegmentEventLocations.BoostScorePopup
    );
    dispatch(mainPanelAction.setView({ lvIdentifier, view: "emailCoach" }));
    dispatch(mainPanelAction.open(lvIdentifier));
    setTimeout(
      () =>
        (
          document.getElementsByClassName(BOOST_SCORE_BUTTON_CLASSNAME)[0] as
            | HTMLButtonElement
            | undefined
        )?.click(),
      250
    );
  }, [dispatch, lvIdentifier]);

  // Just click the send button and dismiss the popup
  const sendAnywayCB = useCallback(() => {
    segmentEvent(
      SegmentEventNames.SendAnywayClicked,
      SegmentEventLocations.BoostScorePopup
    );
    sendButtonElement?.click();
    setShowPopup(false);
  }, [sendButtonElement]);

  if (!enablePopup || !sendButtonElement?.parentElement) {
    return null;
  }

  return createPortal(
    <Container
      style={{
        inset: `auto auto ${sendButtonElement.offsetTop + 56}px ${sendButtonElement.offsetLeft}px`,
        visibility: showPopup ? "visible" : "hidden",
      }}
    >
      <TextContent>
        <TextPrefix>
          <Avatar
            icon={<Icon variant="IconRocket" color="lavender" />}
            color="lavender"
            type="icon"
            shape="square"
            size="extra-small"
          />
        </TextPrefix>
        <TextContainer>
          <Text size="subtext2">Boost Score Before Sending!</Text>
          <TextDescriptionContainer>
            <Text
              size="body1"
              style={{
                color: "var(--color-neutral-purple-700, #525760)",
              }}
            >
              Your email score is a bit low. A boost in your score will increase
              your chances of getting a reply!
            </Text>
          </TextDescriptionContainer>
        </TextContainer>
      </TextContent>
      <CTAContainer>
        <CTAButtonRow>
          <Tooltip
            title={
              <span style={{ fontWeight: 500 }}>
                You can disable this popup in your{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  href={FRONTEND_URLS.extensionSettings}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  extension settings page
                </a>
              </span>
            }
          >
            <Button size="small" fullWidth={true} onClick={sendAnywayCB}>
              Send Anyway
            </Button>
          </Tooltip>
          <Button
            variant="primary"
            size="small"
            prefix="IconRocket"
            fullWidth={true}
            prefixColor="white"
            onClick={boostScoreCB}
          >
            {SUGGESTIONS.FIX_IT_ALL}
          </Button>
        </CTAButtonRow>
      </CTAContainer>
    </Container>,
    sendButtonElement.parentElement
  );
};
