import { FC } from "react";

import { PanelBlock } from "~src/layout/MainPanel/PanelBlock";
import { usePanelTabContext } from "~src/contexts";
import {
  useCRM,
  usePersonalization,
  useShouldDisplayPaywall,
} from "~src/customHooks";
import { ConditionalSwitch } from "~src/component/Utilities";

import { ProfileSummary } from "../../../ProfileSummary";
import { PanelBlockContainer } from "../../Shared";
import { SocialFeed, Podcasts } from "../Company";
import { Gong } from "./Gong";
import { CRMPaywall, CRMSignIn, HubspotCRM, SalesforceCRM } from "../CRM";
import { Personality } from "./Personality";
import { WorkHistory } from "../../../WorkHistory";
import { PersonalizationTab, ProfileSubTab } from "~src/component/typings";
import { RecentActivity } from "./RecentActivity";
import { useCarouselContext } from "../../CarouselContextProvider";
import { GongSignIn } from "../CRM/GongSignIn";

export const PersonalizationProfile: FC = () => {
  const { modalType } = useCarouselContext();
  const { subTab, tab } = usePanelTabContext<
    PersonalizationTab,
    ProfileSubTab
  >();
  const shouldDisplayPaywall = useShouldDisplayPaywall("profile");
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = usePersonalization();
  const { crms } = useCRM();
  const { hasGongAccess } = crms[email] || {};

  return (
    <PanelBlockContainer>
      <PanelBlock>
        <ProfileSummary />
      </PanelBlock>
      {subTab === ProfileSubTab.SOCIAL_FEED && (
        <SocialFeed source={"individual"} />
      )}
      {subTab === ProfileSubTab.EXPERIENCE && <WorkHistory />}
      {subTab === ProfileSubTab.CONNECT_CRM && <CRMSignIn />}
      {subTab === ProfileSubTab.ACTIVITY && <RecentActivity />}
      {subTab === ProfileSubTab.PERSONALITY && <Personality />}
      {subTab === ProfileSubTab.HUBSPOT && <HubspotCRM />}
      {subTab === ProfileSubTab.SALESFORCE && <SalesforceCRM />}
      {subTab === ProfileSubTab.GONG && (
        <ConditionalSwitch>
          {shouldDisplayPaywall && <CRMPaywall crmName="Gong" />}
          {hasGongAccess && <Gong />}
          <GongSignIn />
        </ConditionalSwitch>
      )}
      {tab === PersonalizationTab.PROFILE &&
        subTab === ProfileSubTab.PODCASTS &&
        modalType === "Empty" && <Podcasts />}
    </PanelBlockContainer>
  );
};
