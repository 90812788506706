import { FC } from "react";
import { Flex } from "antd";
import { styled } from "styled-components";

import { Button, Icon, Tabs } from "@lavender-ai/lav-components";

import { CallDetail } from "./CallDetail";
import { Outline } from "./Outline";
import { Highlights } from "./Highlights";
import { Comments } from "./Comments";
import { GongCall } from "../../../../../../redux/typings";
import { useCarouselContext } from "../../../CarouselContextProvider";

const TabFrame = styled.div`
  display: flex;
  margin: 8px 20px;
  width: calc(100% - 40px);
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--radius-lrg, 16px);
  background: var(--color-base-White, #fff);
  text-align: left;
`;

const TabLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
`;

const TabIcon = styled(Icon)`
  margin-right: 8px;
`;

const highlightsTabLabel = (
  <TabLabel>
    <TabIcon color="dark" size="extra-small" variant="IconEye" />
    <Flex>Highlights</Flex>
  </TabLabel>
);

const outlineTabLabel = (
  <TabLabel>
    <TabIcon color="dark" size="extra-small" variant="IconArticle" />
    <Flex>Outline</Flex>
  </TabLabel>
);

const commentTabLabel = (
  <TabLabel>
    <TabIcon color="dark" size="extra-small" variant="IconMessageCircle2" />
    <Flex>Comments</Flex>
  </TabLabel>
);

export const GongCallPanel: FC = () => {
  const { data, prev, prevModal } = useCarouselContext();
  const { call } = data as { call: GongCall };

  if (typeof call === "undefined") {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => {
          prev(prevModal);
        }}
        radius="xl"
        variant="secondary"
        icon="IconX"
        style={{ position: "absolute", top: "28px", right: "28px" }}
      />
      <CallDetail call={call} />
      <TabFrame>
        <Tabs
          items={[
            {
              children: <Highlights call={call} />,
              key: "highlights",
              label: highlightsTabLabel,
            },
            {
              children: <Outline call={call} />,
              key: "outline",
              label: outlineTabLabel,
            },
            {
              children: <Comments call={call} />,
              key: "comments",
              label: commentTabLabel,
            },
          ]}
          size="small"
          style={{
            width: "100%",
          }}
          variant="underlined"
          color="lavender"
          defaultActiveKey="highlights"
        />
      </TabFrame>
    </>
  );
};
