export const SUGGESTIONS = {
  GREAT_JOB:
    "Great job! Emails that score 90+ have the best chance of getting a reply.",
  LETS_IMPROVE: "The best emails score a 90+. Let's improve it!",
  TO_OPTIMIZE: "To optimize for replies:",
  ASK_A_QUESTION: "Ask a question",
  ASK_FEWER_QUESTIONS: "Ask fewer questions",
  FIX_IT_ALL: "Boost Score",
  REMOVE_OR_SHORTEN_SENTENCES:
    "Remove or shorten sentences with more than 20 syllables",
  SHORTEN_THE_EMAIL: "Shorten the email to under 50 words",
  USE_FEWER_WORDS: "Use fewer words in your subject line",
  USE_SHORTER_PARAGRAPHS: "Use shorter paragraphs",
};

export const ERRORS = {
  FALLBACK_ASK_CHAT: `Sorry!<br>Lavender is temporarily unable to connect to ChatGPT’s servers. Please try again.`,
  FALLBACK_ANALYZE_CHAT:
    "A disruption occurred with ChatGPT analysis. Please try again later.",
};

export const TOOLTIP = {
  CHATGPT_WILL_USE: "ChatGPT will use Google to find relevant information.",
};

export const PANEL = {
  ANALYZING_AND_SCORING: "Analyzing & grading your email...",
  BROWSE: "Browse with Google",
  COMBINE_CHAT_GPT:
    "We combine Lavender’s Email Intelligence engine with ChatGPT to make your research, personalization, and email drafting even more powerful.",
  MESSAGE_CHAT_GPT: "Message ChatGPT...",
  SUGGESTIONS: "Suggestions to get started:",
  SEND: "Send",
  TITLE: "ChatGPT",
  SUGGESTION_LIST: [
    "What are some initiatives for...",
    "What are some pain points for...",
    "Write an email about...",
    "Create a customer profile for ...",
    "What are some market changes in ...",
    "Identify emerging trends in...",
    "Analyze the competitive landscape of...",
    "Develop a strategic plan for entering...",
    "Map out the customer journey for...",
    "Key drivers of customer loyalty in...",
    "Forecast the future demand for...",
    "Outline the challenges and opportunities of...",
    "Profile the key market segments of...",
    "Examine the effectiveness of ...",
    "Conduct a SWOT analysis for...",
    "Explore the ethical considerations in...",
  ],
};
