import { useEffect, useMemo } from "react";

import { MIN_EMAIL_BODY_WORD_COUNT } from "~src/constants";
import {
  emailAction,
  selectEmailAnalysis,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";
import { NetworkStatus } from "~src/redux/typings";
import { useLvIdentifier, useShouldDisplayPaywall } from "~src/customHooks";

import { getBodyLength, getWritingData } from "../../utils";
import { formatDurationWithZeroPadding } from "./components/EmailCoachHeader/utils";

export const useTriggerCoachUsed = () => {
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const shouldDisplayPaywall = useShouldDisplayPaywall("emailCoach");

  useEffect(() => {
    if (shouldDisplayPaywall) {
      return;
    }
    // at this point, the coach has been "used" (used == opened during email writing)
    dispatch(emailAction.tagCoachUsed({ id: lvIdentifier }));
  }, [dispatch, lvIdentifier, shouldDisplayPaywall]);
};

export const useEmailCoachDisplayType = () => {
  const lvIdentifier = useLvIdentifier();
  const analyticsData = useAppSelector(selectEmailAnalysis(lvIdentifier));
  const shouldDisplayPaywall = useShouldDisplayPaywall("emailCoach");
  const apiStatus = useAppSelector(
    (state) => state.emailAnalysis[lvIdentifier].status
  );

  return useMemo<"empty" | "loading" | "coach">(() => {
    const requestFailed =
      apiStatus === NetworkStatus.failed &&
      analyticsData.analysis.dynamic_ceq < 0;

    const emailTooShort =
      getBodyLength(getWritingData(lvIdentifier).body) <
      MIN_EMAIL_BODY_WORD_COUNT;

    if (shouldDisplayPaywall || requestFailed || emailTooShort) {
      return "empty";
    } else if (
      apiStatus === NetworkStatus.idle ||
      analyticsData.analysis.dynamic_ceq < 0
    ) {
      return "loading";
    } else {
      return "coach";
    }
  }, [
    analyticsData.analysis.dynamic_ceq,
    apiStatus,
    lvIdentifier,
    shouldDisplayPaywall,
  ]);
};

export const useReadTimeDuration = () => {
  const lvIdentifier = useLvIdentifier();
  const analyticsData = useAppSelector(selectEmailAnalysis(lvIdentifier));
  return formatDurationWithZeroPadding({
    hours: Math.floor(analyticsData?.analysis?.reading_time / 3600),
    minutes: Math.floor((analyticsData?.analysis?.reading_time % 3600) / 60),
    seconds: analyticsData?.analysis?.reading_time % 60,
  });
};
