import { FC } from "react";
import { styled } from "styled-components";
import { Flex } from "antd";
import { Icon } from "@lavender-ai/lav-components";

import { CallOutlineItem, CallOutlineItemText } from "~src/api/typings";

import { OutlineAccordion } from "./OutlineAccordian";
import { GongCall } from "../../../../../../redux/typings";
import { formatDuration } from "../../../../../../utils/datetime";
import { trimStringWithEllipsis } from "../../../../../../utils/";

import "./outline.css";

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding-bottom: 16px;
  color: var(--color-neutral-purple-900, #18253d);
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.1px;
`;

const Time = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-neutral-purple-600, #6d727d);
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.25px;
`;

const ClockIcon = styled(Icon)`
  margin-right: 4px;
`;

const OutlineUl = styled.ul`
  margin-left: -23px;
`;

const OutlineLi = styled.li`
  padding-bottom: 12px;
`;

interface Props {
  call: GongCall;
}

interface OutlineItem {
  key: string;
  label: string;
  time: string;
  children: JSX.Element;
}

export const Outline: FC<Props> = (props) => {
  const { call } = props;
  const outline = call.outline;

  if (call.outline === undefined || call.outline.length === 0) {
    return null;
  }

  const outlineItemsList = (items: CallOutlineItemText[]) => {
    return (
      <OutlineUl>
        {items.map((item: CallOutlineItemText, i) => (
          <OutlineLi key={i}>{item.text}</OutlineLi>
        ))}
      </OutlineUl>
    );
  };

  const bulletPoints: OutlineItem[] = (outline || []).map(
    (item: CallOutlineItem) => ({
      key: item.section,
      label: trimStringWithEllipsis(item.section, 30),
      time: formatDuration(item.duration),
      children: outlineItemsList(item.items),
    })
  );

  return (
    <>
      <Title>
        <Flex>Contents</Flex>
        <Time>
          <ClockIcon color="dark" size="mini" variant="IconClock" />
          {call.durationFormatted}
        </Time>
      </Title>
      <OutlineAccordion items={bulletPoints} />
    </>
  );
};
