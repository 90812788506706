import { Button, Icon, Text } from "@lavender-ai/lav-components";
import { ComponentVariant } from "@lavender-ai/lav-components/dist/esm/types/src/types";
import { Flex } from "antd";
import { MouseEventHandler } from "react";

interface SocialPostsButtonProps {
  state: "focus" | "disabled" | "initial" | "loading" | undefined;
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
  variant: ComponentVariant;
}
export const SocialPostsButton = ({
  state,
  onClick,
  variant,
}: SocialPostsButtonProps) => {
  return (
    <Button size="small" state={state} onClick={onClick} variant={variant}>
      <Flex align="center" gap={8}>
        <Flex align={"center"} gap={2}>
          <Icon variant={"IconBrandLinkedin"} size="mini" />
          <div
            style={{
              height: 10,
              width: 1,
              background: "var(--color-neutral-purple-400, #CBC8D0)",
            }}
          />
          <Icon variant={"IconBrandX"} size="mini" />
        </Flex>
        <Text color="black" size="subtext1">
          Posts
        </Text>
      </Flex>
    </Button>
  );
};
