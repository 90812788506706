import { capitalCase, trainCase } from "change-case";

import {
  AdditionalCompanyJobItem,
  JobTitleSeniority,
  JobsAggregateData,
  JobsAggregateDataAttribute,
} from "~src/api/typings/additionalCompanyData";
import {
  CLEARBIT_LOGO_URL,
  DEFAULT_APOLLO_LOGO_URL,
  DIFFBOT_IMG_DOMAIN,
  JOB_CATEGORIES,
} from "~src/constants";
import {
  Job,
  JobEntityAttributeData,
  PersonalizationJobAggregateData,
  WorkHistory,
} from "~src/redux/typings";

import { unixToTimeStrDynamic } from "./date";

export const getJobsDatesString = (jobs: WorkHistory[]) => {
  let start = Number.MAX_SAFE_INTEGER;
  let end = Number.MIN_SAFE_INTEGER;
  jobs.forEach((job) => {
    if (job.start && job.start < start) {
      start = job.start;
    }
    if (job.end && job.end > end) {
      end = job.end;
    }
  });
  const str = `${start ? unixToTimeStrDynamic(start) : ""}`;
  if (jobs[jobs.length - 1].is_current) {
    return `${str}${str ? " - Present" : "Present"}`;
  }
  if (end) {
    const formattedEnd = unixToTimeStrDynamic(end);
    if (formattedEnd && str) {
      return `${str} - ${formattedEnd} \u2022 ${formatYearsAndMonths(start, end)}`;
    }
  }
  return "";
};

export const getJobDatesString = (job: WorkHistory) => {
  const str = `${job.start ? unixToTimeStrDynamic(job.start) : ""}`;
  if (job.is_current) {
    return `${str}${str ? " - Present" : "Present"}`;
  }
  if (job.end) {
    const formattedEnd = unixToTimeStrDynamic(job.end);
    if (formattedEnd && str) {
      return `${str} - ${formattedEnd} \u2022 ${formatYearsAndMonths(job.start, job.end)}`;
    }
  }
  return str;
};

function formatYearsAndMonths(startDate: number, endDate: number) {
  const start = new Date(startDate * 1000);
  const end = new Date(endDate * 1000);

  let months = (end.getFullYear() - start.getFullYear()) * 12;
  months -= start.getMonth();
  months += end.getMonth();

  const years = Math.floor(months / 12);
  months %= 12;

  return `${years} yrs ${months} mos`;
}

export const getJobString = (job: Job) => {
  let jobStr = "";
  if (!job?.title && !job?.company) {
    return jobStr;
  }
  if (job.title) {
    jobStr += job.title;
  }
  if (job.company) {
    jobStr += job.title ? `, ${job.company}` : job.company;
  }
  return jobStr;
};

export const getJobLocation = (job: JobEntityAttributeData) =>
  job.additional_data.location_data?.state || job.location;

export const getJobData = (
  jobs: AdditionalCompanyJobItem[]
): JobEntityAttributeData[] =>
  jobs.map((job: AdditionalCompanyJobItem) => {
    const categories = job.attributes.categories ?? [];
    return {
      ...job.attributes,
      category: capitalCase(
        categories.find((category) => JOB_CATEGORIES.includes(category)) || ""
      ),
      categories: categories.map((category) => capitalCase(category)),
    };
  });

export const categoryFromJobAggregateDataKey = (key: string) => {
  if (key === "product_management") {
    return "product";
  }
  return key;
};

const executiveRoles = (value: JobsAggregateDataAttribute) => {
  const { owner, founder, c_level, partner, president, director } = value;

  return (
    (owner ?? 0) +
    (founder ?? 0) +
    (c_level ?? 0) +
    (partner ?? 0) +
    (president ?? 0) +
    (director ?? 0)
  );
};

const sortedBreakdown = (breakdown: Record<string, number>) => {
  return Object.entries(breakdown)
    .sort((a, b) => b[1] - a[1])
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
};

export const parseJobAggregateData = (
  data: JobsAggregateData | null,
  total: number
): PersonalizationJobAggregateData[] => {
  const aggregateArray: [
    keyof JobsAggregateData,
    JobsAggregateDataAttribute,
  ][] = [];

  JOB_CATEGORIES.forEach((category) => {
    aggregateArray.push([
      category as keyof JobsAggregateData,
      data?.[category] || {},
    ]);
  });

  return aggregateArray
    .map(([key, value]) => {
      const {
        manager,
        non_manager,
        head,
        vice_president,
        owner,
        founder,
        c_level,
        partner,
        president,
        director,
      } = value;
      const roles =
        total === 0
          ? 0
          : (manager ?? 0) +
            (non_manager ?? 0) +
            (head ?? 0) +
            (vice_president ?? 0) +
            (owner ?? 0) +
            (founder ?? 0) +
            (c_level ?? 0) +
            (partner ?? 0) +
            (president ?? 0) +
            (director ?? 0);

      const breakdown: Record<string, number> = {};
      const executiveCount = executiveRoles(value);
      if (executiveCount) {
        breakdown.Executive = executiveCount;
      }
      if (vice_president) {
        breakdown[capitalCase(JobTitleSeniority.VicePresident)] =
          vice_president;
      }

      if (head) {
        breakdown[capitalCase(JobTitleSeniority.Head)] = head;
      }

      if (non_manager) {
        breakdown[trainCase(JobTitleSeniority.NonManager)] = non_manager;
      }

      if (manager) {
        breakdown[capitalCase(JobTitleSeniority.Manager)] = manager;
      }

      return {
        category: capitalCase(key),
        percent: total === 0 ? "0" : ((roles / total) * 100).toFixed(0),
        roles,
        breakdown: sortedBreakdown(breakdown),
        key,
      };
    })
    .sort((a, b) => b.roles - a.roles);
};

export const parseWorkHistory = (work_history: WorkHistory[] | null) => {
  return (work_history ?? []).reduce<WorkHistory[]>((acc, job) => {
    if (job.title) {
      acc.push({
        ...job,
        logo: resolveWorkHistoryLogo(job),
      });
    }
    return acc;
  }, []);
};

const resolveWorkHistoryLogo = (workHistory: WorkHistory) => {
  const { employer, logo } = workHistory;
  if (
    !logo ||
    logo.includes(DIFFBOT_IMG_DOMAIN) ||
    logo.includes(DEFAULT_APOLLO_LOGO_URL)
  ) {
    return employer ? `${CLEARBIT_LOGO_URL}${employer}.com` : "";
  }
  return "";
};
