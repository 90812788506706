import { Flex } from "antd";

import { IconButton, Text } from "@lavender-ai/lav-components";

import { usePersonalization } from "~src/customHooks";

import { SecondaryText } from "~src/component/Styled";
import { LavenderIcon } from "~src/component/typings";
import { CART_HEADER } from "~src/strings";
import { icebreakerCount } from "~src/utils";

import { StyledStickyCartHeader } from "../../../Shared/Styled";
import { useCarouselContext } from "~src/component/Organisms/Personalization/CarouselContextProvider";

export const Header = () => {
  const { reset } = useCarouselContext();
  const {
    currentPersonalization: { cart },
  } = usePersonalization();

  const cartCount = icebreakerCount(cart);

  return (
    <StyledStickyCartHeader align="flex-start" gap={4} vertical>
      <Flex
        align="center"
        className="lv-cart-header"
        justify="flex-start"
        gap={12}
      >
        <IconButton
          icon={LavenderIcon.IconChevronLeft}
          variant="secondary"
          onClick={reset}
        />
        <>
          <Text color="dark" size="subtext3">
            {CART_HEADER.VALUE_PROPS}
          </Text>
          <SecondaryText
            className="lv-cart-header-secondary-text"
            $light
            size="body2"
          >
            {CART_HEADER.SLASH_SELECTED(cartCount)}
          </SecondaryText>
        </>
      </Flex>
    </StyledStickyCartHeader>
  );
};
