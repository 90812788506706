import { useCallback, useState } from "react";
import { Flex } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { Text, TextProps } from "@lavender-ai/lav-components";

import {
  ExtensionButton,
  ExtensionButtonMode,
  ExtensionCheckbox,
  ExtensionCheckboxVariant,
} from "~src/component/Atoms";
import {
  PanelAlert,
  PanelAlertVariant,
} from "~src/component/Atoms/PanelAlert/PanelAlert";
import { UserDataAvatar, UserDataEntity } from "~src/component/Molecules";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
} from "~src/component/Utilities/Loading";
import { usePersonalization } from "~src/customHooks";
import { PanelListItem } from "~src/layout/MainPanel/PanelBlock";

import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import { LavenderIcon } from "~src/component/typings";
import { MAX_ICEBREAKERS } from "~src/constants";
import { SME_CTA } from "~src/strings";
import {
  PersonalizationEvent,
} from "~src/redux/typings";
import {
  eventAvatarUrl,
  icebreakerCount,
  prettyDate,
  segmentEvent,
  SegmentEventLocations,
  SegmentEventNames,
  triggerColor,
} from "../../../utils";
import { DateTriggerDivider, SecondaryText } from "../../Styled";

interface Props {
  dense?: boolean;
  events: PersonalizationEvent[];
  fallbackLogo?: string;
  loading?: boolean;
  showCartAction?: boolean;
  showDate?: boolean;
  showSource?: boolean;
  showTags?: boolean;
}

export const EventsList = ({
  dense,
  events,
  fallbackLogo = "",
  loading,
  showCartAction,
  showDate,
  showSource,
  showTags,
}: Props) => {
  const {
    addItemToCartContext,
    currentPersonalization: { cart: cartContext },
    removeItemFromCartContext,
  } = usePersonalization();
  const { next } = useCarouselContext();

  const [selected, setSelected] = useState<string>("");

  const count = icebreakerCount(cartContext);

  const onIcebreakerSelect = useCallback(
    (e: CheckboxChangeEvent, item: PersonalizationEvent) => {
      e.stopPropagation();
      const { checked } = e.target;
      if (checked) {
        addItemToCartContext(item);
      } else {
        removeItemFromCartContext(item);
      }
      segmentEvent(
        SegmentEventNames.IcebreakerClicked,
        SegmentEventLocations.StartMyEmail
      );
    },
    [addItemToCartContext, removeItemFromCartContext]
  );

  if (loading && !showCartAction) {
    return (
      <>
        <PanelListItem>
          <CustomSkeleton variant={CustomSkeletonVariant.avatarListItem} />
        </PanelListItem>
        <PanelListItem>
          <CustomSkeleton variant={CustomSkeletonVariant.avatarListItem} />
        </PanelListItem>
      </>
    );
  }

  return (
    <>
      {events.map((event, idx) => {
        const isInCart = cartContext[event.type].items.some(
          (item) => item.id === event.id
        );
        const shouldShowTags = showTags && event.triggers.length > 0;
        const shouldShowSource = showSource && !!event.source;
        const trigger =
          event.triggers.length > 0 ? event.triggers[0] : undefined;

        return (
          <div
            className="lv-panel-list-item-wrapper"
            key={event.id}
            {...(!showCartAction && {
              onClick: () => {
                next(ModalType.NewsEventsDetails, {
                  ...event,
                  fallbackLogo,
                  trigger: trigger?.name,
                });
              },
            })}
          >
            <PanelListItem
              dense={showTags ? false : dense}
              hover={!showCartAction}
            >
              <div
                style={{
                  borderBottom:
                    showTags && events.length - 1 !== idx
                      ? "1px solid var(--lv-panel-background-color)"
                      : "unset",
                  padding: showTags && dense ? "16px 0" : "0",
                }}
              >
                <Flex gap={showCartAction ? 16 : 20} vertical>
                  <div
                    className="lv-panel-list-item-inner-container"
                    style={{
                      borderBottom: showTags
                        ? "unset"
                        : "1px solid var(--lv-panel-background-color)",
                    }}
                  >
                    {showCartAction ? (
                      <ExtensionCheckbox
                        isChecked={isInCart}
                        isDisabled={!isInCart && count >= MAX_ICEBREAKERS}
                        onChange={(e) => {
                          onIcebreakerSelect(e, event);
                        }}
                        variant={ExtensionCheckboxVariant.default}
                      />
                    ) : (
                      <div>
                        <UserDataAvatar
                          entity={UserDataEntity.company}
                          fallbackSrc={fallbackLogo}
                          size="small"
                          src={eventAvatarUrl(event)}
                          style={{
                            border: "1px solid var(--color-neutral-blue-200)",
                          }}
                          usePlaceholderFallback
                        />
                      </div>
                    )}
                    <Flex gap={showCartAction ? 16 : 12} vertical>
                      <div>
                        <Text color="dark" size="subtext2">
                          {showCartAction ? event.formattedTitle : event.title}
                        </Text>
                      </div>
                      {showCartAction && shouldShowTags && (
                        <Flex gap="middle" vertical>
                          <Flex align="center" gap={6}>
                            {event.date && (
                              <SecondaryText $light size="body1">
                                {prettyDate(event.date)}
                              </SecondaryText>
                            )}
                            {event.date && trigger && (
                              <DateTriggerDivider size="body1" />
                            )}
                            <Text
                              color={
                                triggerColor(
                                  trigger?.name || "",
                                  idx
                                ) as TextProps["color"]
                              }
                              size="body1"
                            >
                              {trigger?.formattedName}
                            </Text>
                          </Flex>
                          <ExtensionButton
                            isActive={selected === event.id}
                            mode={ExtensionButtonMode.chip}
                            onClick={() => {
                              setSelected(
                                selected === event.id ? "" : event.id
                              );
                            }}
                            suffix={LavenderIcon.IconChevronDown}
                            text={SME_CTA.SUMMARY}
                            variant="secondary"
                          />
                        </Flex>
                      )}
                      {shouldShowSource && (
                        <Flex align="baseline" gap={4}>
                          {showDate && event.date && (
                            <SecondaryText $light size="body1">
                              {prettyDate(event.date)}
                            </SecondaryText>
                          )}
                          {showDate && event.date && event.source && (
                            <SecondaryText $light size="body1">
                              •
                            </SecondaryText>
                          )}
                          {event.source && (
                            <SecondaryText $light size="body1">
                              {event.source}
                            </SecondaryText>
                          )}
                        </Flex>
                      )}
                      {!shouldShowSource && showDate && event.date && (
                        <SecondaryText $light size="body1">
                          {prettyDate(event.date)}
                        </SecondaryText>
                      )}
                    </Flex>
                  </div>
                  {showCartAction &&
                    shouldShowTags &&
                    selected === event.id && (
                      <Flex gap={12} vertical>
                        <PanelAlert
                          message={event.summary}
                          variant={PanelAlertVariant.summary}
                        />
                        {event.url && (
                          <ExtensionButton
                            mode={ExtensionButtonMode.cta}
                            href={event.url}
                            size="small"
                            suffix={LavenderIcon.IconArrowUpRight}
                            text={SME_CTA.SEE_SOURCE}
                          />
                        )}
                      </Flex>
                    )}
                </Flex>
              </div>
            </PanelListItem>
          </div>
        );
      })}
    </>
  );
};
