import { Button, Tooltip } from "@lavender-ai/lav-components";

import { LavenderIcon } from "~src/component/typings";
import {
  selectPlatform,
  selectUserAccountEmail,
  useAppSelector,
  useMainPanelView,
  usePanelIsOpen,
} from "~src/redux";
import {
  segmentEvent,
  SegmentEventNames,
  SegmentEventLocations,
} from "~src/utils";

import { FlexMenuItem } from "../Menu.styled";
import { useShowPanel } from "../utils";

export const ChatGPTButton = () => {
  const platform = useAppSelector(selectPlatform);
  const view = useMainPanelView();
  const showPanel = useShowPanel();
  const isOpen = usePanelIsOpen();
  const userAccountEmail = useAppSelector(selectUserAccountEmail);

  if (userAccountEmail.endsWith("ibm.com")) {
    return null;
  }

  return (
    <FlexMenuItem>
      <Tooltip
        hideArrow
        placement={platform === "outlookNative" ? "bottom" : "right"}
        title="ChatGPT"
      >
        <div
          onMouseEnter={() => {
            segmentEvent(
              SegmentEventNames.ChatGPTHovered,
              SegmentEventLocations.ChatGPT
            );
          }}
        >
          <Button
            aria-label="ChatGPT"
            onClick={(e) => {
              showPanel(e, "chatGPT");
            }}
            radius="md"
            size="large"
            state={
              view === "chatGPT" && isOpen === "open" ? "focus" : "initial"
            }
            icon={
              view === "chatGPT" &&
              isOpen === "open" &&
              platform !== "outlookNative"
                ? LavenderIcon.IconChevronsRight
                : LavenderIcon.IconBrandOpenAI
            }
            variant="plain"
          />
        </div>
      </Tooltip>
    </FlexMenuItem>
  );
};
