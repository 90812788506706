[
  {
    "title": "Initial Outreach",
    "icon": "IconFlame",
    "iconColor": "lavender",
    "iconBackground": "var(--color-lavender-100, #D5CBF2)",
    "useCases": [
      {
        "name": "First Touch ",
        "icon": "IconFlame",
        "iconColor": "lavender",
        "iconBackground": "var(--color-lavender-100, #D5CBF2)",
        "frameworks": [
          {
            "name": "Vanilla Ice Cream",
            "useCaseTags": "First Touch",
            "steps": [
              "Make an observation",
              "Share a problem or insight",
              "Build credibility",
              "Share a solution",
              "Call-to-conversation (CTC)"
            ],
            "example": "George,\n I saw you're hiring SDRs.\nI imagine you're thinking about how they'll ramp.\nUsually, our customers focus on cold calls.\nBut their email results lag.\nWe're helping reps at Sendoso ramp faster.\nThey continue to improve each quarter with our in-inbox coach.\nWorth a chat? \nWill",
            "whyItWorks": "Creates logic and clarity for reaching out, building credibility."
          },
          {
            "name": "The Mouse Trap",
            "useCaseTags": "First Touch, Direct Message (social), Follow-up",
            "steps": [
              "Observation - Context for why you're showing up in their inbox",
              "Value-prop drive - Meaning the value you add.\nIt focuses on a challenge and implies your product or service has the solution"
            ],
            "example": "\u201cWill, looks like you're hiring reps.\nWould it be helpful to get a more granular look at how they're ramping up on email?\u201d",
            "whyItWorks": "The mouse trap drives an impulsive reply based on curiosity.\nIt\u2019s a priority check.\nIf what you\u2019re mentioning is a priority for your prospect, you\u2019re likely to get a quick response.\nIt\u2019s a helpful framework to use when you have high-intent signals or are in demand capture mode."
          },
          {
            "name": "The Mouse Trap + Context",
            "useCaseTags": "First Touch, Direct Message (social), Follow-up",
            "steps": [
              "Observation - Context for why you\u2019re showing up in their inbox.",
              "Value-prop drive - Meaning the value you add.\nIt focuses on a challenge and implies your product or service has the solution.",
              "Context - Hit on an implied solution to the challenge.\nYou\u2019re providing more background information for why you\u2019re reaching out."
            ],
            "example": "\u201cWill, looks like you're hiring reps.\nWould it be helpful to get a more granular look at how they're ramping on email? Our in-inbox email coach, Lavender, helps teams ramp faster.\u201d",
            "whyItWorks": "You\u2019re taking The Mouse Trap and adding how you can help your reader if the suggested problem is present.\nThis added detail can bring them closer to understanding the purpose of your question.\nIt\u2019s still short and direct, which is why it also works as a direct message (DM) or as a bump (a follow-up email)."
          },
          {
            "name": "The Competitor",
            "useCaseTags": "First Touch ",
            "steps": [
              "Acknowledge the competitor",
              "Ask if they're happy with it",
              "Compliment the competitor",
              "Highlight the competitor's shortcomings",
              "Open up a dialogue with a question related to #4"
            ],
            "example": "\"Hey Will, Saw you're using Drift for chat.\nHow are you liking it? I know their product is killer for lead gen.\nHowever, most of their customers switch to us because they need more functionality for customer support.\nCould you see chat playing a factor in reducing churn?\"",
            "whyItWorks": "Reframes the reader\u2019s point of view by discussing the competitor's shortcomings."
          },
          {
            "name": "The Customer Mirror",
            "useCaseTags": "First Touch ",
            "steps": [
              "Start with an observation",
              "Tie the observation to a similar situation",
              "Offer a challenge faced by that customer",
              "Explain how the customer approached the challenge",
              "Ask if they\u2019re seeing a similar situation"
            ],
            "example": "Your team is off to the races, Will.\nCurious if this sounds familiar.\nWith the round, and your time at [company], it reminds me of our customer [name].\nBig round.\nNew faces.\nTeam knew personalization was working in outbound.\nBut scale was tricky.\nThey lost sight of what was working.\nPersonalization is big at [company name], no? Curious if this is a challenge for you too?",
            "whyItWorks": "Shares a story the buyer can relate to, without a direct pitch, showing understanding and building a connection through shared experiences."
          },
          {
            "name": "Toe Dip",
            "useCaseTags": "First Touch",
            "steps": [
              "Observation",
              "Interest-based CTC (call-to-conversation)"
            ],
            "example": "Looks like you're hiring reps, Will.\nSales leaders with growing teams usually lean on templates to coach email writing.\nDoes that sound similar to what you're doing at Lavender?",
            "whyItWorks": "Simple, brief way to start a conversation, focusing on planting a seed and building rapport."
          }
        ]
      },
      {
        "name": "Direct Message (social)",
        "icon": "IconFlame",
        "iconColor": "lavender",
        "iconBackground": "var(--color-lavender-100, #D5CBF2)",
        "frameworks": [
          {
            "name": "The DM",
            "useCaseTags": "Direct Message (social)",
            "steps": [
              "Give context on what prompted you to reach out",
              "Tie that observation back to an insight or challenge they have",
              "Ask a question that ties the original context to the question or the challenge to a different observation"
            ],
            "example": "Hey Emily, Great to see the traction on your hiring post.\nFor SDR ramp, I\u2019m seeing a lot of sales leaders leaning on phone training and email templates.\nDo you have a playbook from your time with Salesforce?",
            "whyItWorks": "Applies best practices from email to social, initiating a controlled dialogue."
          },
          {
            "name": "The Mouse Trap",
            "useCaseTags": "First Touch, Direct Message (social), Follow-up",
            "steps": [
              "Observation - Context for why you're showing up in their inbox",
              "Value-prop drive - Meaning the value you add.\nIt focuses on a challenge and implies your product or service has the solution"
            ],
            "example": "\u201cWill, looks like you're hiring reps.\nWould it be helpful to get a more granular look at how they're ramping up on email?\u201d",
            "whyItWorks": "The mouse trap drives an impulsive reply based on curiosity.\nIt\u2019s a priority check.\nIf what you\u2019re mentioning is a priority for your prospect, you\u2019re likely to get a quick response.\nIt\u2019s a helpful framework to use when you have high-intent signals or are in demand capture mode."
          },
          {
            "name": "The Mouse Trap + Context",
            "useCaseTags": "First Touch, Direct Message (social), Follow-up",
            "steps": [
              "Observation - Context for why you\u2019re showing up in their inbox.",
              "Value-prop drive - Meaning the value you add.\nIt focuses on a challenge and implies your product or service has the solution.",
              "Context - Hit on an implied solution to the challenge.\nYou\u2019re providing more background information for why you\u2019re reaching out."
            ],
            "example": "\u201cWill, looks like you're hiring reps.\nWould it be helpful to get a more granular look at how they're ramping on email? Our in-inbox email coach, Lavender, helps teams ramp faster.\u201d",
            "whyItWorks": "You\u2019re taking The Mouse Trap and adding how you can help your reader if the suggested problem is present.\nThis added detail can bring them closer to understanding the purpose of your question.\nIt\u2019s still short and direct, which is why it also works as a direct message (DM) or as a bump (a follow-up email)."
          }
        ]
      },
      {
        "name": "Founder-led sales",
        "icon": "IconFlame",
        "iconColor": "lavender",
        "iconBackground": "var(--color-lavender-100, #D5CBF2)",
        "frameworks": [
          {
            "name": "Founder Card",
            "useCaseTags": "Founder-led sales, Entering a new market, Launching a new product",
            "steps": [
              "Start with a problem and a benign goal",
              "State that you\u2019re looking for feedback",
              "Say what you do and establish credibility",
              "Share what you want feedback on and ask if they're open to the conversation"
            ],
            "example": "Will, is anything frustrating you with your email analytics? I ask because I'm looking for feedback.\nI run Lavender.\nIt's a real-time sales email coach.\nWe help sellers at orgs like Sendoso, Twilio, Clari, etc.\nWe're currently building out cadences and personalization analytics.\nAre you open to sharing how we could build it to solve your problems?",
            "whyItWorks": "Sets up to get feedback and find product market fit by showing a clear reason for the feedback."
          }
        ]
      },
      {
        "name": "Entering a new market",
        "icon": "IconFlame",
        "iconColor": "lavender",
        "iconBackground": "var(--color-lavender-100, #D5CBF2)",
        "frameworks": [
          {
            "name": "Founder Card",
            "useCaseTags": "Founder-led sales, Entering a new market, Launching a new product",
            "steps": [
              "Start with a problem and a benign goal",
              "State that you\u2019re looking for feedback",
              "Say what you do and establish credibility",
              "Share what you want feedback on and ask if they're open to the conversation"
            ],
            "example": "Will, is anything frustrating you with your email analytics? I ask because I'm looking for feedback.\nI run Lavender.\nIt's a real-time sales email coach.\nWe help sellers at orgs like Sendoso, Twilio, Clari, etc.\nWe're currently building out cadences and personalization analytics.\nAre you open to sharing how we could build it to solve your problems?",
            "whyItWorks": "Sets up to get feedback and find product market fit by showing a clear reason for the feedback."
          }
        ]
      },
      {
        "name": "Launching a new product",
        "icon": "IconFlame",
        "iconColor": "lavender",
        "iconBackground": "var(--color-lavender-100, #D5CBF2)",
        "frameworks": [
          {
            "name": "Founder Card",
            "useCaseTags": "Founder-led sales, Entering a new market, Launching a new product",
            "steps": [
              "Start with a problem and a benign goal",
              "State that you\u2019re looking for feedback",
              "Say what you do and establish credibility",
              "Share what you want feedback on and ask if they're open to the conversation"
            ],
            "example": "Will, is anything frustrating you with your email analytics? I ask because I'm looking for feedback.\nI run Lavender.\nIt's a real-time sales email coach.\nWe help sellers at orgs like Sendoso, Twilio, Clari, etc.\nWe're currently building out cadences and personalization analytics.\nAre you open to sharing how we could build it to solve your problems?",
            "whyItWorks": "Sets up to get feedback and find product market fit by showing a clear reason for the feedback."
          }
        ]
      }
    ]
  },
  {
    "title": "Follow-up & Nurture",
    "icon": "IconRotate2",
    "iconColor": "sapphire",
    "iconBackground": "var(--color-indigo-200, #D2DBF8)",
    "useCases": [
      {
        "name": "Follow-up",
        "icon": "IconRotate2",
        "iconColor": "sapphire",
        "iconBackground": "var(--color-indigo-200, #D2DBF8)",
        "frameworks": [
          {
            "name": "Neutral Insights",
            "steps": [
              "Reference a third-party resource",
              "Explain why they should read it",
              "Explain why you\u2019re sharing it",
              "Optional: Make a soft ask and nod back to the original CTA or, ideally, CTC"
            ],
            "example": "George,\ndo you read Outreach\u2019s blog? Given you're likely ramping reps, I thought you'd find it interesting...",
            "whyItWorks": "Builds trust on common, neutral ground by breaking the pattern of sending branded content."
          },
          {
            "name": "The Thoughtful Bump",
            "steps": ["Context + Bump"],
            "example": "Hey George,\nGiven that you're growing the sales team, I thought this would be worth discussing.\nDid you have any feedback on my note? Will",
            "whyItWorks": "Provides context and shows consideration for the recipient\u2019s time, encouraging a response."
          },
          {
            "name": "The Clarification",
            "steps": [
              "Restate the context for why you\u2019re reaching out",
              "Seek to clarify part 1: \"What we do\"",
              "Seek to clarify part 2: \"Why that's relevant\"",
              "Call-to-conversation (CTC)"
            ],
            "example": "Hey George,\nGiven you're growing the sales team, I thought coaching might be top of mind.\nTo clarify, we've built a sales email coach...",
            "whyItWorks": "Provides an opportunity to clarify the solution\u2019s relevance to the recipient's challenge."
          },
          {
            "name": "The Well-Researched Referral",
            "steps": [
              "Start with who (namedrop early)",
              "Ask if they're a better person for the topic",
              "Explain why they might be a better person",
              "Explain why you reached out in the first place"
            ],
            "example": "Hey Will, Would Jen be a better person to talk to about this webinar? Realize she manages community activity...",
            "whyItWorks": "Demonstrates research and thoughtfulness, potentially leading to a referral."
          }
        ]
      },
      {
        "name": "Prospecting (never first touch)",
        "icon": "IconRotate2",
        "iconColor": "sapphire",
        "iconBackground": "var(--color-indigo-200, #D2DBF8)",
        "frameworks": [
          {
            "name": "The Video",
            "steps": [
              "Observation (to give context)",
              "Tie the observation to their challenge"
            ],
            "example": "Hey Will, Given your team is growing, I thought you'd find this interesting.\nGrowth typically either leads to one of two things...",
            "whyItWorks": "Engages the recipient with a personalized and relevant video, aiming for a video play."
          }
        ]
      },
      {
        "name": "Re-engage old accounts",
        "icon": "IconRotate2",
        "iconColor": "sapphire",
        "iconBackground": "var(--color-indigo-200, #D2DBF8)",
        "frameworks": [
          {
            "name": "Re-Engage 3 Ways",
            "steps": [
              "One sentence re-engagement email",
              "The \u201cWe've got something new\u201d angle",
              "New updates on their end"
            ],
            "example": "Hey Meagan, \nLast we spoke, personalization was a big focus for your reps.\nNot sure if that's still the case, but we made some great updates...",
            "whyItWorks": "Tailors the approach based on previous interactions, leveraging context for re-engagement."
          }
        ]
      }
    ]
  },
  {
    "title": "Handling Objections",
    "icon": "IconBan",
    "iconColor": "peony",
    "iconBackground": "var(--color-apricot-100, #FFEAC4)",
    "useCases": [
      {
        "name": "Objection",
        "icon": "IconBan",
        "iconColor": "peony",
        "iconBackground": "var(--color-apricot-100, #FFEAC4)",
        "frameworks": [
          {
            "name": "The Breakup",
            "steps": [
              "State that you've reached out",
              "Explain why you did",
              "Assume the timing is off",
              "Ask them to correct you",
              "Clarify it's the last email"
            ],
            "example": "Hey George,\nI've reached out a few times because you\u2019re hiring so many new reps.\nI thought our email coach could help.\nI'm going to chalk it up to my timing being off.\nLet me know if I'm wrong, but I'll stop my outreach for now.\nDo you think this will be a priority in the next two quarters?",
            "whyItWorks": "It's a \u201cbye for now\u201d rather than a forever goodbye.\nYou\u2019re inviting them to correct you, which people often like to do."
          }
        ]
      },
      {
        "name": "Breakup/final email",
        "icon": "IconBan",
        "iconColor": "peony",
        "iconBackground": "var(--color-apricot-100, #FFEAC4)",
        "frameworks": [
          {
            "name": "The Breakup",
            "steps": [
              "State that you've reached out",
              "Explain why you did",
              "Assume the timing is off",
              "Ask them to correct you",
              "Clarify it's the last email"
            ],
            "example": "Hey George,\nI've reached out a few times because you\u2019re hiring so many new reps.\nI thought our email coach could help.\nI'm going to chalk it up to my timing being off.\nLet me know if I'm wrong, but I'll stop my outreach for now.\nDo you think this will be a priority in the next two quarters?",
            "whyItWorks": "It's a \u201cbye for now\u201d rather than a forever goodbye.\nYou\u2019re inviting them to correct you, which people often like to do."
          }
        ]
      }
    ]
  },
  {
    "title": "Closing & Ghosted",
    "icon": "IconGhost2",
    "iconColor": "negative",
    "iconBackground": "var(--color-watermelon-100, #FDCBD3)",
    "useCases": [
      {
        "name": "Late-stage deal",
        "icon": "IconGhost2",
        "iconColor": "negative",
        "iconBackground": "var(--color-watermelon-100, #FDCBD3)",
        "frameworks": [
          {
            "name": "Exec-to-Exec",
            "steps": [
              "Explain why you\u2019re showing up in their inbox.",
              "Close by offering your support."
            ],
            "example": "Jen,\nI understand you\u2019ve been working with Ashley on our team in hopes Lavender can solve your challenge with diving reply rates.\nIs there anything we can do to assist you here? Cheers, Will",
            "whyItWorks": "Sometimes, all it takes is someone with a similar title as your decision-maker to help move the conversation.\nIt's about creating a connection with another stakeholder with zero pressure."
          }
        ]
      },
      {
        "name": "Ghosted",
        "icon": "IconGhost2",
        "iconColor": "negative",
        "iconBackground": "var(--color-watermelon-100, #FDCBD3)",
        "frameworks": [
          {
            "name": "Exec-to-Exec",
            "steps": [
              "Explain why you\u2019re showing up in their inbox.",
              "Reinforce that your team is aligned on the problem you aim to solve for the recipient.",
              "Close by offering your support."
            ],
            "example": "Jen,\nI understand you\u2019ve been working with Ashley on our team in hopes Lavender can solve your challenge with diving reply rates.\nIs there anything we can do to assist you here? Cheers, Will",
            "whyItWorks": "Sometimes, all it takes is someone with a similar title as your decision-maker to help move the conversation.\nIt's about creating a connection with another stakeholder with zero pressure."
          }
        ]
      }
    ]
  }
]
