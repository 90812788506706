import {
  useLvIdentifier,
  usePersonalizationPanel,
  useShouldDisplayPaywall,
} from "~src/customHooks";

import { PersonalizationPanelContainer } from "~src/component/Organisms";
import { PanelBackgroundFlex } from "~src/component/Styled";
import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";

import { IceBreakers, NoRecipients } from "./Views";
import { useEffect } from "react";
import { emailAction, useAppDispatch } from "~src/redux";

export const StartMyEmail = () => {
  const dispatch = useAppDispatch();
  const lvIdentifier = useLvIdentifier();
  const { recipientsArray } = usePersonalizationPanel();
  const { modalType } = useCarouselContext();
  const shouldDisplayPaywall = useShouldDisplayPaywall("startMyEmail");
  const recipientsCount = recipientsArray.length;

  useEffect(() => {
    if (
      shouldDisplayPaywall ||
      recipientsCount === 0 ||
      ModalType[modalType] !== ModalType.Empty
    ) {
      return;
    }

    dispatch(emailAction.tagStartMyEmailUsed({ id: lvIdentifier }));
  }, [
    dispatch,
    lvIdentifier,
    modalType,
    recipientsCount,
    shouldDisplayPaywall,
  ]);

  if (recipientsCount === 0) {
    return (
      <PersonalizationPanelContainer align="center" gap={12} vertical>
        <NoRecipients />
      </PersonalizationPanelContainer>
    );
  }

  return (
    <PanelBackgroundFlex align="center" gap={12} vertical $height="100%">
      {ModalType[modalType] === ModalType.Empty ? <IceBreakers /> : null}
    </PanelBackgroundFlex>
  );
};
