import { useCallback, useMemo } from "react";
import {
  CSSTransition,
  TransitionGroup,
  type TransitionStatus,
} from "react-transition-group";
import type { MenuItemType } from "antd/lib/menu/hooks/useItems";
import styled from "styled-components";

import {
  Avatar,
  BadgeWrapper,
  Button,
  Tooltip,
} from "@lavender-ai/lav-components";

import { AvatarDropdown } from "./AvatarDropdown";
import { UserDataAvatar } from "~src/component/Molecules";
import { UnverifiedPopup } from "~src/component/Molecules/UnverifiedPopup";

import { extractAvatarFallback, newPendingPersonalization } from "~src/utils";
import {
  useLvIdentifier,
  usePersonalization,
  useVerification,
} from "~src/customHooks";
import { mainPanelAction, store } from "~src/redux";
import {
  LavenderIcon,
  type PersonalizationSubTab,
  type PersonalizationTab,
} from "~src/component/typings";
import { usePanelTabContext } from "~src/contexts";
import {
  INIT_PERSONALIZATION_TAB,
  INIT_PERSONALIZATION_TAB_CONTEXT,
} from "~src/constants";
import { useAppDispatch, useAppSelector } from "~src/redux/hooks";
import { EmailVerificationData } from "~src/redux/typings";

type TransitionState = Exclude<TransitionStatus, "unmounted">;

const transitionStyles: Record<TransitionState, React.CSSProperties> = {
  entering: { opacity: 0, transform: "scale(0.95)" },
  entered: { opacity: 1, transform: "scale(1)" },
  exiting: { opacity: 0, transform: "scale(1.05)" },
  exited: { opacity: 0, transform: "scale(1.05)" },
};

const StyledAvatarGroup = styled(Avatar.Group)`
  align-items: baseline;
  flex-direction: column;
  gap: 8px;
`;

const StyledAvatarDropdownContainer = styled.div`
  width: 32px;
  margin: 8px auto;

  .lav-components-menu-wrapper {
    z-index: 1000;
  }
`;

export const RecipientMenuGroup = () => {
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const state = store.getState();
  const platform = state.config.settings?.platform;
  const isOpen = useAppSelector(
    ({ mainPanel }) => mainPanel[lvIdentifier].isOpen
  );
  const view = useAppSelector(({ mainPanel }) => mainPanel[lvIdentifier].view);
  const { tab, subTab, setPanelTabContext } = usePanelTabContext<
    PersonalizationTab,
    PersonalizationSubTab
  >();
  const {
    currentPersonalization,
    recipientVerifications,
    personalizations,
    setPersonalizationContext,
  } = usePersonalization();
  const { isInvalid, isUnverified, isSeen, isLoading, isValid } =
    useVerification();
  const recipientsArray = useMemo(
    () => Object.entries(recipientVerifications),
    [recipientVerifications]
  );

  const showPersonalizationAssistant = useCallback(
    (
      e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
      email?: string
    ) => {
      e?.stopPropagation();

      dispatch(mainPanelAction.setView({ lvIdentifier, view: "profile" }));
      if (isOpen !== "open") {
        dispatch(mainPanelAction.open(lvIdentifier));
      } else if (
        view === "profile" &&
        (!email || currentPersonalization?.profile?.email === email)
      ) {
        dispatch(mainPanelAction.close(lvIdentifier));
      }
      if (email) {
        const { tab: initTab, subTab: initSubtab } =
          INIT_PERSONALIZATION_TAB_CONTEXT[INIT_PERSONALIZATION_TAB];
        if (tab !== initTab || subTab !== initSubtab) {
          setPanelTabContext({ tab: initTab, subTab: initSubtab });
        }

        const newPersonalization = personalizations[email];
        setPersonalizationContext(
          newPersonalization || newPendingPersonalization(email)
        );
      }
    },
    [
      currentPersonalization?.profile?.email,
      dispatch,
      isOpen,
      lvIdentifier,
      personalizations,
      setPanelTabContext,
      setPersonalizationContext,
      subTab,
      tab,
      view,
    ]
  );

  let visibleUsers: Array<[string, EmailVerificationData | undefined]> =
    recipientsArray.slice(0, 2);
  let overflowUsers: Array<[string, EmailVerificationData | undefined]> =
    recipientsArray.slice(2);
  if (recipientsArray.length >= 3) {
    visibleUsers = recipientsArray.slice(0, 1);
    overflowUsers = recipientsArray.slice(1);
  }

  const menuItems = useMemo(
    () =>
      overflowUsers.map((recipient) => ({
        children: personalizations[recipient[0]]?.profile?.name
          ? recipient[0]
          : undefined,
        title: personalizations[recipient[0]]?.profile?.name || recipient[0],
        key: recipient[0],
        icon: (
          <UserDataAvatar
            size="extra-small"
            src={personalizations[recipient[0]]?.profile?.avatar}
            fallback={extractAvatarFallback(
              personalizations[recipient[0]]?.profile?.name,
              recipient[0]
            )}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
        ),
      })),
    [overflowUsers, personalizations]
  );

  const handleSelect = useCallback(
    ({ key }: MenuItemType) => {
      const selectKey =
        menuItems.length === 1 ? menuItems[key as number].key : key;
      showPersonalizationAssistant(undefined, selectKey as string);
    },
    [menuItems, showPersonalizationAssistant]
  );

  return (
    <>
      {visibleUsers.length > 0 && (
        <StyledAvatarGroup
          maxCount={2}
          maxPopoverPlacement="bottom"
          className={"styled-avatar-group-overwrite-css"}
        >
          <TransitionGroup component={null}>
            {visibleUsers.map((recipient, index) => {
              const email = recipient[0];
              const foundPersonalization = personalizations[email];
              const shouldDisplayDot =
                !isSeen(email) &&
                !isLoading(email) &&
                (isInvalid(email) || isUnverified(email) || isValid(email));
              const dotColor = isInvalid(email)
                ? "negative"
                : isUnverified(email)
                  ? "warning"
                  : "lavender";

              return (
                <CSSTransition key={index} timeout={300} classNames="item">
                  {(state) => (
                    <UnverifiedPopup email={email}>
                      <BadgeWrapper
                        color={dotColor}
                        dot={shouldDisplayDot}
                        offset={[-4, 4]}
                      >
                        <UserDataAvatar
                          key={index}
                          src={foundPersonalization?.profile?.avatar}
                          fallback={extractAvatarFallback(
                            foundPersonalization?.profile?.name,
                            email
                          )}
                          style={{
                            cursor: "pointer",
                            ...transitionStyles[state as TransitionState],
                            transition:
                              "opacity 300ms ease-in-out, transform 300ms ease-in-out",
                          }}
                          onClick={(e) => {
                            showPersonalizationAssistant(e, email);
                          }}
                          tooltip={
                            !(isUnverified(email) || isInvalid(email))
                              ? foundPersonalization?.profile?.name || email
                              : undefined
                          }
                        />
                      </BadgeWrapper>
                    </UnverifiedPopup>
                  )}
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </StyledAvatarGroup>
      )}
      {overflowUsers.length > 0 && (
        <StyledAvatarDropdownContainer
          className={"styled-avatar-dropdown-overwrite-css"}
        >
          <AvatarDropdown
            selected={currentPersonalization?.profile?.email}
            onSelect={handleSelect}
            items={menuItems}
            content={`+${overflowUsers.length}`}
          />
        </StyledAvatarDropdownContainer>
      )}
      {recipientsArray.length === 0 && (
        <Tooltip
          hideArrow
          placement={platform === "outlookNative" ? "bottom" : "right"}
          title="Personalization Assistant"
        >
          <Button
            aria-label="Personalization Assistant"
            onClick={(e) => {
              showPersonalizationAssistant(e);
            }}
            radius="md"
            size="large"
            state={
              view === "profile" && isOpen === "open" ? "focus" : "initial"
            }
            icon={
              view === "profile" &&
              isOpen === "open" &&
              platform !== "outlookNative"
                ? LavenderIcon.IconChevronsRight
                : LavenderIcon.IconUserSearch
            }
            variant="plain"
          />
        </Tooltip>
      )}
    </>
  );
};
