import {
  RankedItem,
  StartMyEmailSection,
  WorkHistoryItem,
} from "~src/api/typings";
import { PersonalizationEvent, WorkHistory } from "~src/redux/typings";

export const isRankedFeedExperience = (
  item: WorkHistoryItem
): item is PersonalizationEvent => {
  return "score" in item;
};
export const areWorkHistoryItems = (
  items: RankedItem[] | WorkHistory[]
): items is WorkHistory[] => {
  return "type" in items[0] && items[0].type === "work_history";
};
export const isStartMyEmailSection = (
  section: StartMyEmailSection | undefined
): section is StartMyEmailSection => {
  return section !== undefined && typeof section === "object";
};

// Cart list items
export const arePersonalizationEventItems = (
  items: RankedItem[]
): items is PersonalizationEvent[] => {
  return ["events", "job_openings", "tech_stack"].includes(items[0].type);
};
