import { useMemo } from "react";

import { useStartMyEmail, useNews, useTechStacks } from "./";

export const useRankedFeedApiDependencies = (email: emailAddress) => {
  const {
    currentPersonalization: {
      company: {
        jobs: { loading: jobsLoading },
      },
      profile: { loading: profileLoading },
    },
  } = useStartMyEmail();
  const { news } = useNews();
  const { techStacks } = useTechStacks();
  const areDependenciesLoading =
    profileLoading ||
    jobsLoading ||
    !news[email] ||
    news[email]?.loading ||
    !techStacks[email] ||
    techStacks[email]?.loading;

  return useMemo(() => ({ areDependenciesLoading }), [areDependenciesLoading]);
};
