export interface GetPodcastsResponse {
  results: {
    count: number;
    next_offset: number;
    results: Result[];
    took: number;
    total: number;
  };
}

export type GetPodcastsData = Result[] | [];

export interface GetPodcastsPayload {
  email: emailAddress;
  chromeId: chromeId | undefined;
}

export interface Podcast {
  genre_ids: number[];
  id: string;
  image: string;
  listen_score: number;
  listen_score_global_rank: string;
  listennotes_url: string;
  publisher_highlighted: string;
  publisher_original: string;
  thumbnail: string;
  title_highlighted: string;
  title_original: string;
}

export interface Result {
  audio: string;
  audio_length_sec: number;
  description_highlighted: string;
  description_original: string;
  explicit_content: boolean;
  guid_from_rss: string;
  id: string;
  image: string;
  itunes_id: number;
  link: string;
  listennotes_url: string;
  podcast: Podcast;
  pub_date_ms: number;
  rss: string;
  thumbnail: string;
  title_highlighted: string;
  title_original: string;
  transcripts_highlighted: any[];
}
