import DOMPurify from "dompurify";

import {
  AnalyzeChatErrorResponse,
  AnalyzeChatSuccessResponse,
  Payload,
} from "./typings";
import { networkRequest, URLS } from ".";

export const isError = (
  response: object
): response is AnalyzeChatErrorResponse => "error" in response;

export const analyzeChat = async (
  payload: Payload
): Promise<AnalyzeChatSuccessResponse> => {
  const { data } = await networkRequest.post<AnalyzeChatSuccessResponse>(
    URLS.analyzeChat,
    {
      chrome_id: payload.chromeId,
      response: DOMPurify.sanitize(payload.response, {
        USE_PROFILES: { html: true },
      }),
    }
  );
  return data;
};
