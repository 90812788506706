import { networkRequest, URLS } from "~src/api";
import { NewsResponse } from "./typings";

export const getNews = async (
  email: string,
  chromeId: chromeId
): Promise<NewsResponse> => {
  const { data } = await networkRequest.post<NewsResponse>(URLS.news, {
    email,
    chrome_id: chromeId,
  });

  return data;
};
