import styled from "styled-components";
import { Avatar, Icon, Text } from "@lavender-ai/lav-components";

import {
  CarouselContextType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import {
  CompanySubTab,
  PersonalizationSubTab,
  PersonalizationTab,
  ProfileSubTab,
} from "~src/component/typings";
import { useGlobalSearchContext, usePanelTabContext } from "~src/contexts";

type ModalType = CarouselContextType["modalType"];

const getLinkTarget = (
  location: CompanySubTab | ModalType
): PersonalizationTab | "Deep" => {
  switch (location) {
    case CompanySubTab.FUNDING:
    case CompanySubTab.ABOUT:
      return PersonalizationTab.COMPANY;
    default:
      return "Deep";
  }
};

export const ResultsList = () => {
  const { results, closeModal } = useGlobalSearchContext();
  const { setPanelTabContext } = usePanelTabContext<
    PersonalizationTab,
    PersonalizationSubTab
  >();
  const { next } = useCarouselContext();
  const handleResultClick = (
    location: ModalType | CompanySubTab,
    data: CarouselContextType["data"]
  ) => {
    const target = getLinkTarget(location);
    if (target === PersonalizationTab.COMPANY) {
      setPanelTabContext({
        tab: PersonalizationTab.COMPANY,
        subTab: location as CompanySubTab,
      });
      closeModal();
    } else if (target === PersonalizationTab.PROFILE) {
      setPanelTabContext({
        tab: PersonalizationTab.PROFILE,
        subTab: location as ProfileSubTab,
      });
      closeModal();
    } else {
      next(location as ModalType, data);
    }
  };

  return results.map(
    ({ title, breadcrumbString, icon, location, data }, index) => (
      <SearchModalResultsRowWrapper
        key={title + index}
        onClick={handleResultClick.bind(null, location, data)}
      >
        <SearchModalResultsRow>
          <Avatar
            size="extra-small"
            color="neutral-100"
            icon={<Icon variant={icon} size="small" color="dark" />}
            style={{ borderRadius: 8 }}
          />
          <SearchModalResultsRowContentWrapper>
            <HighlightedTitle title={title} />
            <Text
              size="body1"
              style={{
                color: "var(--color-neutral-600)",
                textTransform: "capitalize",
              }}
            >
              {breadcrumbString}
            </Text>
          </SearchModalResultsRowContentWrapper>
        </SearchModalResultsRow>
      </SearchModalResultsRowWrapper>
    )
  );
};

const HighlightedTitle = ({ title }: { title: string }) => {
  const { searchTerm } = useGlobalSearchContext();

  const regex = new RegExp(`(${searchTerm})`, "gi");
  const parts = title.split(regex);

  return (
    <Text
      size="body2"
      style={{ textAlign: "left", maxHeight: 41, overflow: "hidden" }}
    >
      {parts.map((part, index) =>
        regex.test(part) ? (
          <span key={index} style={{ color: "#6a2be9" }}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </Text>
  );
};

const SearchModalResultsRowWrapper = styled.div`
  display: flex;
  padding: 0px 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const SearchModalResultsRow = styled.div`
  display: flex;
  padding: 16px 0px;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  border-bottom: 1px solid var(--color-neutral-purple-50, #f6f8fc);
  cursor: pointer;

  &:hover {
    background-color: var(--color-neutral-purple-50, #f6f8fc);
  }
`;

const SearchModalResultsRowContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
`;
