import { Empty, Flex } from "antd";

import { Text } from "@lavender-ai/lav-components";

import { useSearch, useLoadMore } from "~src/customHooks";
import { BUTTON_TEXT, SME_CTA } from "~src/strings";
import { LoadMoreButton } from "~src/component/Atoms";
import { NoDataDisplay, SearchInput } from "~src/component/Molecules";
import type {
  INews,
  IPersonalization,
  PersonalizationEvent,
} from "~src/redux/typings";
import { LavenderIcon } from "~src/component/typings";
import {
  PanelBlock,
  PanelBlockVariant,
  PanelBlockHeader,
  PanelListContainer,
} from "~src/layout/MainPanel/PanelBlock";

import { EventsList } from ".";

interface Props {
  currentPersonalization: IPersonalization;
  currentNews: INews;
  isCartView?: boolean;
  dense?: boolean;
  headerText: string;
  subHeaderText?: string;
  showCartAction?: boolean;
  showDate?: boolean;
  showSource?: boolean;
  showTags?: boolean;
}

export const Events = ({
  currentPersonalization: {
    cart: cartContext,
    company: { logo },
    profile: { loading: profileLoading },
  },
  currentNews: { feed, loading },
  isCartView,
  dense,
  headerText,
  subHeaderText,
  showCartAction,
  showDate = true,
  showSource,
  showTags,
}: Props) => {
  let events: PersonalizationEvent[] = [];
  if (isCartView) {
    events = cartContext.events.items;
  } else {
    events = feed;
  }

  const newsLoading = profileLoading || loading;

  const { searchTerm, filteredItems, handleSearch } = useSearch(events, [
    "title",
  ]);
  const { currentItems, loadMore, loadMoreCount, hasMore } =
    useLoadMore(filteredItems);

  const empty = !newsLoading && feed?.length === 0;
  const countConfig = makeCountConfig(filteredItems.length);

  return empty ? (
    <NoDataDisplay mainText="We couldn't find any News & Events." />
  ) : (
    <PanelBlock loading={newsLoading} variant={PanelBlockVariant.list}>
      <PanelBlockHeader
        headerText={headerText}
        loading={newsLoading}
        subHeaderText={subHeaderText}
      />
      <SearchInput
        countConfig={countConfig}
        handleSearch={handleSearch}
        loading={newsLoading}
        placeholder="Search news & events..."
        searchTerm={searchTerm}
      />
      {!newsLoading && filteredItems.length === 0 && (
        <div style={{ width: "100%", padding: dense ? "16px 0" : "20px 0" }}>
          <Flex justify="center">
            <Empty
              description={
                <Text
                  size="body1"
                  style={{
                    color: "var(--ant-color-text-quaternary)",
                    margin: "0 auto",
                  }}
                >
                  No results
                </Text>
              }
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          </Flex>
        </div>
      )}
      <>
        <PanelListContainer>
          <EventsList
            dense={dense}
            events={currentItems}
            fallbackLogo={logo}
            loading={newsLoading}
            showCartAction={showCartAction}
            showDate={showDate}
            showSource={showSource}
            showTags={showTags}
          />
        </PanelListContainer>
        {hasMore && !newsLoading && (
          <div className="lv-panel-list-load-more-button-container">
            <LoadMoreButton
              buttonText={
                showCartAction
                  ? SME_CTA.MORE_SUGGESTIONS
                  : BUTTON_TEXT.SEE_MORE(loadMoreCount)
              }
              onClick={loadMore}
              prefix={showCartAction ? undefined : LavenderIcon.IconPlus}
            />
          </div>
        )}
      </>
    </PanelBlock>
  );
};

const makeCountConfig = (count: number) => ({
  count,
  formatter: (count: number) =>
    `${count} ${count === 1 ? "article" : "articles"}`,
});
