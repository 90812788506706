import { useCallback, useState } from "react";
import { Flex } from "antd";
import { styled } from "styled-components";

import { IconButton } from "@lavender-ai/lav-components";

import { LavenderIcon } from "~src/component/typings";
import { useCarouselContext } from "~src/component/Organisms/Personalization/CarouselContextProvider";
import { SPINNER_TIMEOUTS } from "~src/constants";
import { useStartMyEmailWebsocketContext } from "~src/contexts";
import { useTimeout, useValuePropGeneration } from "~src/customHooks";

import { StyledCartHeader } from "../../../Shared/Styled";

const StyledOverlayModalHeader = styled(StyledCartHeader)`
  .lv-cart-header {
    position: absolute;
    z-index: 10;
  }
`;

export const Header = () => {
  const { reset } = useCarouselContext();
  const { resetLastStartMyEmailMessage } = useStartMyEmailWebsocketContext();
  const { isUserValuePropGenerationOn } = useValuePropGeneration();

  const [isVisible, setIsVisible] = useState(false);
  const onBack = useCallback(() => {
    resetLastStartMyEmailMessage();
    reset();
  }, []);

  // Alow exit after Loading States gets to the Stepper. Don't show before that to avoid click abuse
  useTimeout(() => {
    isUserValuePropGenerationOn && setIsVisible(true);
  }, SPINNER_TIMEOUTS[1] + SPINNER_TIMEOUTS[2]);

  return (
    <StyledOverlayModalHeader align="flex-start" gap={4} vertical>
      <Flex
        align="center"
        className="lv-cart-header"
        justify="flex-start"
        gap={12}
      >
        {isVisible && (
          <IconButton
            icon={LavenderIcon.IconChevronLeft}
            variant="secondary"
            onClick={onBack}
          />
        )}
      </Flex>
    </StyledOverlayModalHeader>
  );
};
