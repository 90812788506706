import { StartMyEmail } from "~src/component/Organisms/StartMyEmail/StartMyEmail";
import {
  StartMyEmailMutationContextProvider,
  StartMyEmailWebsocketContextProvider,
  ValuePropsMutationContextProvider,
} from "~src/contexts";
import { PanelLayout } from "~src/layout/MainPanel/Panel/PanelLayout/PanelLayout";

import { PaywallModal } from "~src/component/Molecules";
import { CarouselContextProvider } from "~src/component/Organisms/Personalization/CarouselContextProvider";
import { useShouldDisplayPaywall } from "~src/customHooks";

export const StartMyEmailPanel = () => {
  const shouldDisplayPaywall = useShouldDisplayPaywall("startMyEmail");

  return (
    <PanelLayout isFullHeight rootClassName="lv-start-my-email-box">
      {shouldDisplayPaywall && <PaywallModal view="startMyEmail" />}
      <ValuePropsMutationContextProvider>
        <StartMyEmailMutationContextProvider>
          <StartMyEmailWebsocketContextProvider>
            <CarouselContextProvider>
              <StartMyEmail />
            </CarouselContextProvider>
          </StartMyEmailWebsocketContextProvider>
        </StartMyEmailMutationContextProvider>
      </ValuePropsMutationContextProvider>
    </PanelLayout>
  );
};
