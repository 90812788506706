import { decodeHtmlEscaping, isHtml } from "./html";
import { htmlToText } from "./htmlToText";

export const copyToClip = async (str: string) => {
  const listener = (e: ClipboardEvent) => {
    e.clipboardData?.setData("text/html", str);
    if (isHtml(str)) {
      e.clipboardData?.setData("text/plain", htmlToText(str));
    } else {
      e.clipboardData?.setData("text/plain", str);
    }
    e.preventDefault();
  };
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
  let success = false;

  try {
    if (navigator?.clipboard?.writeText !== undefined) {
      str = decodeHtmlEscaping(str);
      if (isHtml(str)) {
        str = htmlToText(str);
      }
      await navigator.clipboard.writeText(str).then(() => {});
      success = true;
    }
  } catch (e) {
    console.error("Error copying to clipboard using Clipboard API", e);
  }

  if (!success) {
    try {
      const tempElement = document.createElement("div");
      tempElement.textContent = str;
      const range = document.createRange();
      range.selectNodeContents(tempElement);
      const selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);

      document.execCommand("copy");
    } catch (e) {
      console.error("Error copying to clipboard using execCommand", e);
    }
  }
};

export const insertToTextfield = (
  container: HTMLElement,
  textToInsert: string,
  elementId: string
) => {
  try {
    const highlightedText = container.querySelector(`#${elementId}`);
    if (highlightedText) {
      highlightedText.textContent = textToInsert;
    }
  } catch (e) {
    console.error("Error inserting text", e);
  }
};

export const removeText = (container: HTMLElement, elementId: string) => {
  try {
    const highlightedText = container.querySelector(`#${elementId}`);
    if (highlightedText) {
      highlightedText.textContent = "";
    }
  } catch (e) {
    console.error("Error removing text", e);
  }
};
