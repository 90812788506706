export const HEADER = {
  CRAFT_BETTER_EMAILS: "Craft better emails with higher reply rates!",
};
export const SUB_HEADER = {
  HOW_IT_WORKS: "How Lavender Works",
};
export const MESSAGE = {
  BOOST_SCORE: "Boost score, according to Lavender’s email best practice.",
};
export const RECOMMENDATIONS = {
  SUGGESTION: "Suggestion",
};
