import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getCRMUser, getConfig, getMailBoxUser } from "../api";
import {
  ConfigState,
  ConfigApiResponse,
  GetMailBoxUserResponse,
  AccountStatus,
  GetCRMUserResponse,
  NetworkStatus,
} from "./typings";
import { DEFAULT_BILLING_PLANS } from "~src/constants";

import { RootState } from "./store";

export const INIT_CONFIG_STATE: ConfigState = {
  settings: {} as ConfigApiResponse,
  status: NetworkStatus.idle,
  error: "",
  chromeId: "",
  emailAddress: "",
  mailBox: {} as GetMailBoxUserResponse,
  crmUser: {} as GetCRMUserResponse,
  accountStatus: "" as AccountStatus,
  mainPollingInterval: -1,
  billingPlans: DEFAULT_BILLING_PLANS,
  reactRoot: null,
};

export const fetchConfig = createAsyncThunk("config/fetchConfig", async () => {
  const response = await getConfig();
  return response.data;
});

export const fetchMailBoxUser = createAsyncThunk(
  "config/fetchMailBoxUser",
  async ({
    emailAddress,
    chromeId,
  }: {
    emailAddress: string;
    chromeId: chromeId;
  }) => {
    const response = await getMailBoxUser(emailAddress, chromeId);
    return response.data;
  }
);
export const fetchCRMUser = createAsyncThunk(
  "config/fetchCRMUser",
  async ({ platform, chromeId }: { platform: string; chromeId: chromeId }) => {
    const response = await getCRMUser(platform, chromeId);
    return response.data;
  }
);
export const configSlice = createSlice({
  name: "config",
  initialState: INIT_CONFIG_STATE,
  reducers: {
    testAction: (state, action) => {
      return { ...state, ...action };
    },
    setChromeId: (state, { payload: chromeId }) => {
      // INFO: For Outlook Native PC Edge, this console is needed so Edge can grab latest chromeId, this cause some memory flushing issues
      if (state.settings?.platform === "outlookNative") {
        console.debug("update %o", chromeId);
      }
      return { ...state, chromeId };
    },
    setEmailAddress: (state, { payload: emailAddress }) => {
      return { ...state, emailAddress };
    },
    setAccountStatus: (state, { payload: accountStatus }) => {
      return { ...state, accountStatus };
    },
    setMainPollingInterval: (state, { payload: mainPollingInterval }) => {
      return { ...state, mainPollingInterval };
    },
    clearMainPollingInterval: (state) => {
      return { ...state, mainPollingInterval: -1 };
    },
  },
  extraReducers(builder) {
    builder
      // INFO : fetch config
      .addCase(fetchConfig.pending, (state, _action) => {
        state.status = NetworkStatus.loading;
      })
      .addCase(fetchConfig.fulfilled, (state, { payload }) => {
        state.status = NetworkStatus.success;
        state.settings = { ...payload };
      })
      .addCase(fetchConfig.rejected, (state, action) => {
        state.status = NetworkStatus.failed;
        state.error = action.error.message || "";
      })
      // INFO: fetchMailBoxUser
      .addCase(fetchMailBoxUser.pending, (state, _action) => {
        state.status = NetworkStatus.loading;
      })
      .addCase(fetchMailBoxUser.fulfilled, (state, { payload }) => {
        state.status = NetworkStatus.success;
        state.mailBox = { ...payload };
      })
      .addCase(fetchMailBoxUser.rejected, (state, action) => {
        state.status = NetworkStatus.failed;
        state.error = action.error.message || "";
      })
      // INFO: fetchCRMUser
      .addCase(fetchCRMUser.pending, (state, _action) => {
        state.status = NetworkStatus.loading;
      })
      .addCase(fetchCRMUser.fulfilled, (state, { payload }) => {
        state.status = NetworkStatus.success;
        state.crmUser = { ...payload };
      })
      .addCase(fetchCRMUser.rejected, (state, action) => {
        state.status = NetworkStatus.failed;
        state.error = action.error.message || "";
      });
  },
});

export const configReducer = configSlice.reducer;
export const configActions = configSlice.actions;

export const selectChromeId = ({ config }: RootState) => config.chromeId || "";
export const selectPlatform = ({ config }: RootState) =>
  config.settings?.platform || "";
export const selectSelectors = ({ config }: RootState) =>
  config.settings?.selectors;
export const selectBillingPlans = ({ config }: RootState) =>
  config.settings?.billingPlans ?? DEFAULT_BILLING_PLANS;
export const selectStreakThreshold = ({ config }: RootState) =>
  config.settings?.streakThreshold ?? 1;
