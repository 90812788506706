import { Streak } from "~src/redux/typings";
import { ErrorResponse } from "./api";

export interface IncrementStreakPayload {
  chromeId: chromeId;
  day: string;
}

export interface IncrementStreakSuccessResponse {
  status: "success";
  streak: Streak;
}
export type IncrementStreakResponse =
  | ErrorResponse
  | IncrementStreakSuccessResponse;
