import { Flex } from "antd";
import { FC } from "react";
import styled from "styled-components";
import { Text } from "@lavender-ai/lav-components";

import { useAppSelector } from "~src/redux";
import { BILLING_PLANS } from "~src/constants";

const PlanName = styled.div`
  color: var(--color-base-Black, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  letter-spacing: -0.1px;
`;

export const PlanInfo: FC = () => {
  const user = useAppSelector((state) => state.userAccount.settings.user);
  if (!user) return null;

  const { billing_plan: billingPlan, billing_cycle: billingCycle } = user;

  const capitalizedBillingCycle =
    billingCycle?.charAt(0).toUpperCase() + billingCycle?.slice(1);

  return (
    <Flex vertical={false} justify="space-between" style={{ width: "100%" }}>
      <PlanName>{billingPlan}</PlanName>
      <Text style={{ alignSelf: "center" }}>
        {billingPlan !== BILLING_PLANS.Teams && capitalizedBillingCycle}
      </Text>
    </Flex>
  );
};
