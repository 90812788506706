import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { snakeCase as snakeCaseKeys } from "change-case/keys";

import { APIEndpoints } from "~src/api";
import {
  APIResponseStatus,
  ErrorResponse,
  HumanticParmams,
  HumanticResponse,
  StartMyEmailData,
  StartMyEmailPayload,
  StartMyEmailSuccessResponse,
  UpdateSettingsPayload,
  UpdateSettingsResponse,
} from "~src/api/typings";
import { normalizeCart } from "~src/utils";
import { ErrorIdentifiers, parseAndLogError } from "~src/logger";
import { SME_ERROR, UPDATE_PROFILE_FORM } from "~src/strings";
import { isLavenderAPIError } from "~src/typeGuards";

import { REFRESH_INTERVAL } from "../constants";
import { feedActions } from "./feed";
import { refreshUserAccount } from "./userAccount";

export const apiSlice = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.BACKEND_API}/api/` }),
  endpoints: (builder) => ({
    getHumantics: builder.query<HumanticResponse, HumanticParmams>({
      query: ({ chromeId, email }) => ({
        url: APIEndpoints.humantics,
        params: {
          chrome_id: chromeId,
          email,
        },
      }),
      keepUnusedDataFor: REFRESH_INTERVAL.FEED,
    }),
    updateSettings: builder.mutation<
      UpdateSettingsResponse,
      UpdateSettingsPayload
    >({
      query: (payload) => {
        const transformRequestBody = (
          originalPayload: UpdateSettingsPayload
        ) => {
          return snakeCaseKeys(originalPayload);
        };

        const transformedPayload = transformRequestBody(payload);
        return {
          url: APIEndpoints.userSettings,
          method: "POST",
          body: transformedPayload,
        };
      },
      transformErrorResponse: (response, meta, arg): UpdateSettingsResponse => {
        parseAndLogError(response, ErrorIdentifiers.API_ERROR, {
          email: arg.email,
          method: meta?.request.method,
          url: meta?.request.url,
        });
        return isLavenderAPIError(response?.data)
          ? response?.data
          : {
              message: UPDATE_PROFILE_FORM.FALLBACK_ERROR,
              status: APIResponseStatus.error,
              error: "500",
            };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        void dispatch(refreshUserAccount({ chromeId: arg.chromeId }));
        dispatch(feedActions.updateProfileSettings(arg));
      },
    }),
    startMyEmail: builder.mutation<
      StartMyEmailData | string[],
      StartMyEmailPayload
    >({
      query: ({ signal, ...payload }) => {
        const transformRequestBody = (originalPayload: StartMyEmailPayload) => {
          return JSON.stringify(snakeCaseKeys(originalPayload, 4));
        };

        const transformedPayload = transformRequestBody(payload);

        return {
          url: APIEndpoints.startMyEmail,
          method: "POST",
          body: transformedPayload,
          signal,
        };
      },
      transformResponse: (
        response: StartMyEmailSuccessResponse,
        _meta,
        _arg
      ): StartMyEmailData | string[] => {
        if (response !== null) {
          return Array.isArray(response?.generated)
            ? response?.generated
            : normalizeCart(response?.generated);
        }
        return response;
      },
      transformErrorResponse: (response, meta, arg): ErrorResponse => {
        parseAndLogError(response, ErrorIdentifiers.API_ERROR, {
          email: arg.email,
          method: meta?.request.method,
          url: meta?.request.url,
        });
        return isLavenderAPIError(response?.data)
          ? response?.data
          : {
              message: SME_ERROR.SORRY,
              status: APIResponseStatus.error,
              error: "500",
            };
      },
    }),
  }),
});

export const {
  useGetHumanticsQuery,
  useStartMyEmailMutation,
  useUpdateSettingsMutation,
} = apiSlice;

export const apiReducer = apiSlice.reducer;
