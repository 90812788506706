import { CompanyResponse, RecentActivity } from "~src/api/typings";
import {
  DEFAULT_COMPANY,
  DEFAULT_RECENT_ACTIVITY,
  CLEARBIT_LOGO_URL,
  DEFAULT_APOLLO_AVATAR_URL,
  DEFAULT_APOLLO_LOGO_URL,
  DIFFBOT_IMG_DOMAIN,
  DEFAULT_PROFILE_COMPANY,
} from "~src/constants";
import type {
  IPersonalization,
  Person,
  PersonalizationEvent,
  PersonalizationProfileData,
  ProfileCompany,
  ProfileUser,
  User,
} from "~src/redux/typings";

export const resolveCompanyLogo = (company: CompanyResponse) => {
  const { domain, logo } = company;
  if (
    domain &&
    (!logo ||
      logo.includes(DIFFBOT_IMG_DOMAIN) ||
      logo.includes(DEFAULT_APOLLO_LOGO_URL))
  ) {
    return `${CLEARBIT_LOGO_URL}${domain}`;
  }
  return logo || "";
};

export const resolveProfileAvatar = (profile: Person) => {
  const { avatar } = profile;
  return !avatar || avatar.includes(DEFAULT_APOLLO_AVATAR_URL) ? "" : avatar;
};
export const resolveProfileJobTitle = (profile: Person | undefined) => {
  return profile ? profile.job.title || "" : "";
};
export const resolveProfileCompanyBio = (
  profileCompany: ProfileCompany | undefined
) => {
  return profileCompany?.company_bio || "";
};
export const resolveProfileCompanyName = (
  profileCompany: ProfileCompany | undefined
) => {
  return profileCompany?.company_name || "";
};
export const resolveProfileCompanyWebsite = (
  profileCompany: ProfileCompany | undefined
) => {
  return profileCompany?.url || "";
};

export const newProfileUser = (): ProfileUser => ({
  avatar: "",
  displayName: "",
  email: "",
});

export const newPendingPersonalization = (email: string): IPersonalization => ({
  profile: newPendingProfile(email),
  recent_activity: DEFAULT_RECENT_ACTIVITY as RecentActivity,
  company: DEFAULT_COMPANY,
  notes: [],
  cart: undefined,
});

export const newRejectedPersonalization = (
  email: string
): IPersonalization => ({
  profile: {
    ...newPendingProfile(email),
    loading: false,
  },
  recent_activity: DEFAULT_RECENT_ACTIVITY as RecentActivity,
  company: DEFAULT_COMPANY,
  notes: [],
  cart: undefined,
});

export const newPendingProfile = (
  email: string
): PersonalizationProfileData => ({
  avatar: "",
  company: DEFAULT_PROFILE_COMPANY,
  bio: "",
  education: "",
  job: {
    company: "",
    title: "",
  },
  name: "",
  location: "",
  timezone: "",
  workHistory: [],
  email,
  loading: true,
});

export const extractInitials = (displayName: string): string => {
  const names = displayName.split(" ");
  if (names.length === 1) {
    return names[0].charAt(0);
  }
  return names[0].charAt(0) + names[names.length - 1].charAt(0);
};

export const extractFirstName = (name: string): string => name.split(" ")[0];

export const extractUserDataForProfile = (user: User | undefined) =>
  user
    ? (({ avatar, full_name, email }) => ({
        avatar,
        email,
        displayName: full_name,
      }))(user.person)
    : newProfileUser();

export const extractAvatarFallback = (name: string | undefined, email = "") =>
  extractInitials(name || email.split("@")[0].toUpperCase());

export const eventAvatarUrl = (event: PersonalizationEvent) =>
  CLEARBIT_LOGO_URL + event.source;
