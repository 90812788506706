import { Button, Tooltip } from "@lavender-ai/lav-components";

import { LavenderIcon } from "~src/component/typings";
import {
  selectPlatform,
  useAppSelector,
  useMainPanelView,
  usePanelIsOpen,
} from "~src/redux";

import { FlexMenuItem } from "../Menu.styled";
import { useShowPanel } from "../utils";

export const FrameworksButton = () => {
  const platform = useAppSelector(selectPlatform);
  const view = useMainPanelView();
  const showPanel = useShowPanel();
  const isOpen = usePanelIsOpen();

  return (
    <FlexMenuItem>
      <Tooltip
        hideArrow
        placement={platform === "outlookNative" ? "bottom" : "right"}
        title="Frameworks"
      >
        <Button
          aria-label="Frameworks"
          icon={
            view === "frameworks" &&
            isOpen === "open" &&
            platform !== "outlookNative"
              ? LavenderIcon.IconChevronsRight
              : "IconBox"
          }
          radius="md"
          size="large"
          state={
            view === "frameworks" && isOpen === "open" ? "focus" : "initial"
          }
          onClick={(e) => {
            showPanel(e, "frameworks");
          }}
          variant="plain"
        />
      </Tooltip>
    </FlexMenuItem>
  );
};
