import { Search } from "@lavender-ai/lav-components";

import { useGlobalSearchContext } from "~src/contexts";

import {
  MenuSearchWithToggleCloseButton,
  SearchModalResults,
  SearchModalSuggestions,
} from "./components";
import {
  SearchModalOverlayContainer,
  SearchModalOverlay,
  SearchModal,
} from "./GlobalSearchModal.styled";

export const GlobalSearchModal = () => {
  const { handleSearch, searchTerm, isModalOpen, closeModal, openModal } =
    useGlobalSearchContext();

  return isModalOpen ? (
    <SearchModalOverlayContainer>
      <SearchModalOverlay onClick={closeModal} />
      <SearchModal>
        <MenuSearchWithToggleCloseButton
          closeModal={() => {
            handleSearch("");
            closeModal();
          }}
        />
        {searchTerm ? <SearchModalResults /> : <SearchModalSuggestions />}
      </SearchModal>
    </SearchModalOverlayContainer>
  ) : (
    <Search
      placeholder="Search"
      rounded={true}
      value={searchTerm}
      onFocus={openModal}
      onSearch={openModal}
      onChange={(e) => {
        if (e.target.value === "") {
          handleSearch("");
        }
      }}
      style={{
        display: "flex",
        height: 34,
        alignItems: "center",
        margin: "8px 0 4px",
      }}
    />
  );
};
