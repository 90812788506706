import { styled } from "styled-components";
import { PanelLayout } from "~src/layout/MainPanel/Panel/PanelLayout/PanelLayout";
import StreakCloudBackgroundPng from "~src/assets/images/StreakPanel/StreakCloudBackground.png";
import StarsPng from "~src/assets/images/StreakPanel/Stars.png";
import { StreakDescription } from "./StreakDescription";
import { StreakCards } from "./StreakCards";
import { StreakBadge } from "./StreakBadge";

export const StreakPanel = () => (
  <PanelLayout>
    <Background>
      <StarsBackgroundImage />
      <CloudBackgroundImage />
      <BackgroundFadeout />
    </Background>
    <Foreground>
      <StreakBadge />
      <StreakDescription />
      <StreakCards />
    </Foreground>
  </PanelLayout>
);

const Foreground = styled.div`
  z-index: 1;
`;
const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    164deg,
    #3d0da1 32.83%,
    #6a2be9 55.16%,
    #a770ed 105.99%
  );
`;

const CloudBackgroundImage = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 343px;
  background:
    linear-gradient(164deg, #3d0da1 32.83%, #6a2be9 55.16%, #a770ed 105.99%),
    url(${StreakCloudBackgroundPng}) lightgray -35.958px -8.349px / 118.717%
      115.107% no-repeat;
  background-blend-mode: multiply;
`;

const StarsBackgroundImage = styled.div`
  position: absolute;
  width: calc(100% - 10px);
  height: 366px;
  top: 28px;
  left: 5px;
  background: url(${StarsPng}) no-repeat;
`;

const BackgroundFadeout = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 173,
        left: 0,
        width: "100%",
        height: 0,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="375"
        height="304"
        fill="none"
      >
        <g filter="url(#filter0_f_13275_28461)">
          <path
            d="M-34 64C-34 64 19.4256 64 190.097 64C360.769 64 409 64 409 64V240H-34V64Z"
            fill="#6528E0"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_13275_28461"
            x="-98"
            y="0"
            width="571"
            height="304"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="32"
              result="effect1_foregroundBlur_13275_28461"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
