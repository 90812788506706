export interface SetNoteResponse {
  note: SingleNote[];
}

export interface Note {
  _id: string;
  user_id: string;
  fc_id: string;
  cb_id: string;
  email: string[];
  text?: string;
  notes?: SingleNote[];
  member?: string[]; // deprecated
}

export interface SingleNote {
  Text: string;
  Created: string; // use as unique id for removing/editing
  Pinned?: boolean;
}
