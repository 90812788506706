import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { INews } from "~src/redux/typings";

interface Props {
  children: React.ReactNode;
}

export interface INewsContext {
  currentNews: INews;
  resetNewsContext: () => void;
  setNewsContext: (newNews: INews) => void;
}

const initialState: INews = {
  feed: [],
  loading: false,
};

const initNewsContext: INewsContext = {
  currentNews: initialState,
  resetNewsContext: () => {
    /* empty */
  },
  setNewsContext: (_newNews: INews) => {
    /* empty */
  },
};

const NewsContext = createContext(initNewsContext);

export const NewsContextProvider = ({ children }: Props) => {
  const [news, setNews] = useState<INews>(initialState);

  const resetNewsContext = useCallback(() => {
    setNews(initialState);
  }, []);

  const setNewsContext = useCallback((newNews: INews) => {
    setNews(newNews);
  }, []);

  const newsContext: INewsContext = useMemo(
    () => ({
      currentNews: news,
      resetNewsContext,
      setNewsContext,
    }),
    [news, resetNewsContext, setNewsContext]
  );

  return (
    <NewsContext.Provider value={newsContext}>{children}</NewsContext.Provider>
  );
};

export const useNewsContext = () => useContext(NewsContext);
