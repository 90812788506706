import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { PanelTabContextProvider } from ".";
import { RankedItem, RecentActivity } from "~src/api/typings";
import {
  DEFAULT_CART,
  DEFAULT_COMPANY,
  DEFAULT_PROFILE_COMPANY,
  DEFAULT_RECENT_ACTIVITY,
  INIT_PERSONALIZATION_TAB,
  INIT_PERSONALIZATION_TAB_CONTEXT,
  RANKED_FEED_TYPES,
} from "~src/constants";
import type {
  IPersonalization,
  PersonalizationProfileData,
} from "~src/redux/typings";

interface Props {
  children: React.ReactNode;
}

export interface IPersonalizationContext {
  addItemToCartContext: (item: RankedItem) => void;
  removeItemFromCartContext: (item: RankedItem) => void;
  resetCartContext: () => void;
  currentPersonalization: IPersonalization;
  resetPersonalizationContext: () => void;
  setPersonalizationContext: (newPersonalization: IPersonalization) => void;
}

const initialState: IPersonalization = {
  cart: DEFAULT_CART,
  company: DEFAULT_COMPANY,
  profile: {
    avatar: "",
    bio: "",
    company: DEFAULT_PROFILE_COMPANY,
    education: "",
    job: {
      company: "",
      title: "",
    },
    name: "",
    location: "",
    timezone: "",
    workHistory: [],
  } as unknown as PersonalizationProfileData,
  recent_activity: DEFAULT_RECENT_ACTIVITY as RecentActivity,
  notes: [],
};

const initPersonalizationContext: IPersonalizationContext = {
  addItemToCartContext: (_item: RankedItem) => {
    /* empty */
  },
  removeItemFromCartContext: (_item: RankedItem) => {
    /* empty */
  },
  resetCartContext: () => {
    /* empty */
  },
  resetPersonalizationContext: () => {
    /* empty */
  },
  currentPersonalization: initialState,
  setPersonalizationContext: (_newPersonalization: IPersonalization) => {
    /* empty */
  },
};

const PersonalizationContext = createContext(initPersonalizationContext);

export const PersonalizationContextProvider = ({ children }: Props) => {
  const [personalization, setPersonalization] =
    useState<IPersonalization>(initialState);
  const setPersonalizationContext = useCallback(
    (newPersonalization: IPersonalization) => {
      setPersonalization(newPersonalization);
    },
    []
  );
  const addItemToCartContext = useCallback(
    (item: RankedItem) => {
      setPersonalization({
        ...personalization,
        cart: {
          ...personalization.cart,
          ...RANKED_FEED_TYPES.reduce(
            (acc, type) => ({
              ...acc,
              [type]: {
                ...personalization.cart[type],
                items:
                  item.type === type &&
                  !personalization.cart[type].items.find(
                    (rankedItem) => rankedItem.id === item.id
                  )
                    ? [...personalization.cart[type].items, item]
                    : personalization.cart[type].items,
              },
            }),
            {}
          ),
        },
      });
    },
    [personalization]
  );
  const removeItemFromCartContext = useCallback(
    (item: RankedItem) => {
      setPersonalization({
        ...personalization,
        cart: {
          ...personalization.cart,
          ...RANKED_FEED_TYPES.reduce(
            (acc, type) => ({
              ...acc,
              [type]: {
                ...personalization.cart[type],
                items:
                  item.type === type
                    ? personalization.cart[type].items.filter(
                        (rankedItem) => rankedItem.id !== item.id
                      )
                    : personalization.cart[type].items,
              },
            }),
            {}
          ),
        },
      });
    },
    [personalization]
  );
  const resetCartContext = useCallback(() => {
    setPersonalization({
      ...personalization,
      cart: DEFAULT_CART,
    });
  }, [personalization]);
  const resetPersonalizationContext = useCallback(() => {
    setPersonalization(initialState);
  }, []);
  const personalizationContext: IPersonalizationContext = useMemo(
    () => ({
      addItemToCartContext,
      removeItemFromCartContext,
      resetCartContext,
      setPersonalizationContext,
      resetPersonalizationContext,
      currentPersonalization: personalization,
    }),
    [
      addItemToCartContext,
      personalization,
      removeItemFromCartContext,
      resetCartContext,
      resetPersonalizationContext,
      setPersonalizationContext,
    ]
  );
  return (
    <PersonalizationContext.Provider value={personalizationContext}>
      <PanelTabContextProvider
        initialPanelContext={
          INIT_PERSONALIZATION_TAB_CONTEXT[INIT_PERSONALIZATION_TAB]
        }
      >
        {children}
      </PanelTabContextProvider>
    </PersonalizationContext.Provider>
  );
};

export const usePersonalizationContext = () =>
  useContext(PersonalizationContext);
