import { SetStateAction } from "react";

// Create an invisible overlay element
export const createOverlayElement = (
  buttonElement: HTMLButtonElement,
  setShowPopup: React.Dispatch<SetStateAction<boolean>>
) => {
  const overlay = document.createElement("div");
  overlay.style.position = "absolute";
  overlay.style.top = `${buttonElement.offsetTop}px`;
  overlay.style.left = `${buttonElement.offsetLeft}px`;
  overlay.style.width = `${buttonElement.offsetWidth}px`;
  overlay.style.height = `${buttonElement.offsetHeight}px`;
  overlay.style.borderRadius =
    window.getComputedStyle(buttonElement).borderRadius;
  overlay.style.cursor = "pointer";
  overlay.style.zIndex = "9999"; // Ensure it's above the button
  overlay.style.backgroundColor = "transparent"; // Invisible

  // Add event listener to the overlay
  overlay.addEventListener("click", (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();

    setShowPopup(true);
    overlay.remove();
  });

  return overlay;
};
