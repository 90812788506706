import { useEffect, useState } from "react";

import { useDecideExtensionToInit, useMainLoopState } from ".";

export const useRenderLegacyExtension = ({
  chromeId,
  isLegacyInitDefined,
  isNewExtensionEnabled,
  doesUserNeedLogin,
}: Pick<
  ReturnType<typeof useMainLoopState>,
  | "chromeId"
  | "isLegacyInitDefined"
  | "isNewExtensionEnabled"
  | "doesUserNeedLogin"
>) => {
  const [hasRenderedExtension, setHasRenderedExtension] = useState(false);
  const extensionToInit = useDecideExtensionToInit({
    chromeId,
    isNewExtensionEnabled,
    doesUserNeedLogin,
  });

  // Launch legacy extension effect
  useEffect(() => {
    if (extensionToInit !== "legacy" || hasRenderedExtension) return;
    // If init fn is undefined hold off on running legacy extension
    if (!isLegacyInitDefined) return;
    console.debug("Running legacy extension");
    setHasRenderedExtension(true);
    window.LegacyInit?.();
  }, [
    chromeId,
    extensionToInit,
    hasRenderedExtension,
    isLegacyInitDefined,
    isNewExtensionEnabled,
  ]);
};
