import { useCallback, useState } from "react";
import { styled } from "styled-components";

import { Loading } from "~src/component/Atoms/SVGBackground";
import {
  ExpandingProgress,
  FadeStepper,
  Finished,
  Stepper,
} from "~src/component/Molecules/LoadingStates";
import { useTimeout, useValuePropGeneration } from "~src/customHooks";

import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import {
  FINISHED_TIMEOUT,
  MAILCLIENT,
  SPINNER_TIMEOUTS,
  STEPPER_TIMEOUTS,
  STEPS,
} from "~src/constants";
import { StyledPanelLayout } from "~src/layout/MainPanel/Panel/PanelLayout";
import { debugLogVariables } from "~src/logger";
import { CART_HEADER, SME_HEADER, SME_SUBHEADER } from "~src/strings";
import { selectPlatform, useAppSelector } from "~src/redux";

const StyledOverlay = styled.div`
  position: relative;
  width: var(--lv-expanded-panel-size);
  height: 100%;

  &:has(.lv-loading-states-background) {
    ${StyledPanelLayout} {
      max-height: 100%;
      overflow: hidden;
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--lv-expanded-panel-size);
  height: 100%;
  overflow: hidden;

  svg {
    flex: 1;
    height: 100%;
    width: 100%;
  }
`;

/**
 * Loader loading states will occur differently based on the state of the value prop analysis & generation
 * 1. ExpandingProgress (1s) - this happens if the value prop generation is not in the Redux store
 * 2. ExpandingProgress (3s) - this happens if we didn't get an API response for a cached value prop generation in the
 *    1s. Typically we won't with the cache speed at the current moment
 * 3. StepperContainer - this happens if there is not a cached API response (by now the API should have sent a response)
 * 4. FadeStepper (2s-3s) - by this time we expect the value prop analysis to be finalizing, usually its done by the end of this step
 * 5. Finished view (2-4s)
 *
 * If websockets are on, we skip the last 2 steps once we start streaming the value prop generation
 *
 * Note for time ranges: smetimes latency skyrockets, and since we're relying on 2 separate AI calls to finish
 * sequentially, this will be a more significant delay. We handle this by adding 1 sec polling intervals after the
 * animation was up for its expected time
 **/

const Loader = () => {
  const {
    data: { openedBy, rankedFeedCount },
    next,
  } = useCarouselContext();
  const {
    isUserValuePropGenerationOn,
    isValuePropGenerationStreaming,
    isValuePropGenerationSuccess,
  } = useValuePropGeneration();
  const [loadingState, setLoadingState] = useState(1);
  const logLoadingState = (count: number) => {
    debugLogVariables(`RUNNING TIMEOUT ${count}: LOADING STATE`, {
      loadingState,
      isValuePropGenerationStreaming,
      isValuePropGenerationSuccess,
      isUserValuePropGenerationOn,
    });
  };
  const unMount = () => {
    next(ModalType.Cart, {
      rankedFeedCount,
      openedBy,
      from: ModalType.Overlay,
      phase: CART_HEADER.VALUE_PROPS,
    });
  };

  useTimeout(
    () => {
      logLoadingState(1);
      if (loadingState === 1) {
        if (isValuePropGenerationSuccess) {
          unMount();
        } else {
          setLoadingState(2);
        }
      }
    },
    loadingState === 1 ? SPINNER_TIMEOUTS[loadingState] : null
  );
  useTimeout(
    () => {
      logLoadingState(2);
      if (loadingState === 2) {
        if (isValuePropGenerationSuccess || !isUserValuePropGenerationOn) {
          unMount();
        } else {
          setLoadingState(3);
        }
      }
    },
    loadingState === 2 ? SPINNER_TIMEOUTS[loadingState] : null
  );

  if (loadingState === 1 || loadingState === 2) {
    return (
      <ExpandingProgress
        header={isUserValuePropGenerationOn ? SME_HEADER.UNLOCKING : ""}
        subheader={isUserValuePropGenerationOn ? SME_SUBHEADER.CRAFTING : ""}
      />
    );
  }
  if (loadingState === 3) {
    return <StepperContainer />;
  }
};

const StepperContainer = () => {
  const {
    data: { rankedFeedCount, openedBy },
    next,
  } = useCarouselContext();
  const platform = useAppSelector(selectPlatform);
  const { isValuePropGenerationSuccess } = useValuePropGeneration();
  const [step, setStep] = useState<number>(0);
  const unMount = useCallback(() => {
    next(ModalType.Cart, {
      rankedFeedCount,
      openedBy,
      from: ModalType.Overlay,
      phase: CART_HEADER.VALUE_PROPS,
    });
  }, [openedBy, next]);

  const useTimeoutCallback = useCallback(() => {
    const nextStep = step + 1;
    if (nextStep < 6) {
      setStep(nextStep);
    } else if (nextStep === 11) {
      unMount();
    } else {
      isValuePropGenerationSuccess ? unMount() : setStep(nextStep);
    }
  }, [step, isValuePropGenerationSuccess, unMount]);

  useTimeout(useTimeoutCallback, step === 0 ? STEPPER_TIMEOUTS[step] : null);
  useTimeout(useTimeoutCallback, step === 1 ? STEPPER_TIMEOUTS[step] : null);
  useTimeout(useTimeoutCallback, step === 2 ? STEPPER_TIMEOUTS[step] : null);
  useTimeout(useTimeoutCallback, step === 3 ? STEPPER_TIMEOUTS[step] : null);
  useTimeout(useTimeoutCallback, step === 4 ? STEPPER_TIMEOUTS[step] : null);
  useTimeout(useTimeoutCallback, step === 5 ? STEPPER_TIMEOUTS[step] : null);
  useTimeout(useTimeoutCallback, step === 6 ? STEPPER_TIMEOUTS[step] : null);
  // Poll for one more
  useTimeout(useTimeoutCallback, step === 7 ? 1000 : null);

  useTimeout(useTimeoutCallback, step === 8 ? FINISHED_TIMEOUT : null);
  //*INFO: Poll one more if API hasn't finished. This is a just in case, if this does happen we
  // should instead bump the times in other steps
  useTimeout(useTimeoutCallback, step === 9 ? 1000 : null);
  //*INFO: Poll one more if API hasn't finished. This is a just in case, if this does happen we
  // should instead bump the times in other steps
  useTimeout(useTimeoutCallback, step === 10 ? 1000 : null);

  if (step <= 5) {
    return (
      <Stepper
        // additionalDetailsText={SME_CAPTION.YOU_MAY_LEAVE}
        additionalDetailsText={" "}
        isDense={platform === MAILCLIENT.SALESLOFT}
        step={step}
        steps={STEPS}
      />
    );
  }

  if (step <= 7) {
    return <FadeStepper steps={STEPS} />;
  }

  return <Finished />;
};

interface Props {
  children: React.ReactNode;
}

export const Overlay = ({ children }: Props) => {
  return (
    <StyledOverlay>
      {children}
      <LoadingContainer className="lv-loading-states-background">
        <Loading />
      </LoadingContainer>
      <Loader />
    </StyledOverlay>
  );
};
