import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";

export enum APIResponseStatus {
  // HTTP
  success = "success",
  error = "error",
  // redux initial state
  idle = "idle",
  // websockets
  initiated = "initiated",
  streaming = "streaming",
}

export interface ErrorResponse {
  error: string;
  message: string;
  status: APIResponseStatus.error;
}

export type SuccessReponse<T> = T & {
  status: APIResponseStatus.success;
};

export type UseMutationBaseQuery = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
>;
