import { ComponentProps, useCallback, useState } from "react";
import { Tooltip } from "@lavender-ai/lav-components";
import { ComponentVariant } from "@lavender-ai/lav-components/dist/esm/types/src/types";

import { ExtensionButton, ExtensionButtonMode } from "~src/component/Atoms";

import { useLvIdentifier, useTimeout } from "~src/customHooks";
import { BUTTON_TEXT } from "~src/strings";
import { copyToClip } from "~src/utils/dom";
import { LavenderIcon } from "~src/component/typings";
import { useAppSelector } from "~src/redux";
import { SegmentEventNames, VIEW_STATE_TO_SEGMENT_LOCATION, segmentEvent } from "~src/utils";

const { COPY } = BUTTON_TEXT;

interface Props
  extends Pick<ComponentProps<typeof ExtensionButton>, "size" | "loading"> {
  copyText: string;
  title?: string;
  variant?: ComponentVariant;
}

export const CopyButton = ({
  copyText,
  size = "small",
  title = "Copied!",
  variant = "secondary",
  ...buttonChipProps
}: Props) => {
  const lvIdentifier = useLvIdentifier();
  const view = useAppSelector(({ mainPanel }) => mainPanel[lvIdentifier].view);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const copyAction = useCallback(() => {
    void (async () => {
      await copyToClip(copyText);
      setTooltipOpen(true);
      segmentEvent(
        SegmentEventNames.CopyButtonClicked,
        VIEW_STATE_TO_SEGMENT_LOCATION[view]
      );
    })();
  }, [copyText, view]);

  useTimeout(
    () => {
      setTooltipOpen(false);
    },
    tooltipOpen ? 1000 : null
  );

  return (
    <Tooltip
      hideArrow
      title={title}
      open={tooltipOpen}
      getPopupContainer={(trigger: HTMLElement) => {
        return trigger;
      }}
    >
      <ExtensionButton
        disabled={copyText.length === 0}
        size={size}
        onClick={copyAction}
        mode={ExtensionButtonMode.chip}
        prefix={LavenderIcon.IconCopy}
        text={COPY}
        variant={variant}
        {...buttonChipProps}
      />
    </Tooltip>
  );
};
