import styled from "styled-components";

export const QuickActionsRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--radius-md, 8px);
  cursor: pointer;

  &:hover {
    background-color: var(--color-neutral-purple-50, #f6f8fc);
  }
`;

export const QuickActionsRowDetails = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
`;
