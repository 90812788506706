import { SELECTABLE_PLANS } from "~src/contexts";

export const PLAN_FEATURES: Record<SELECTABLE_PLANS | "Teams10+", string[]> = {
  Starter: [
    "Unlimited emails",
    "Unlimited personalization",
    "Unlimited feature usage",
    "Unlimited Al recommendations",
    "Mobile optimization",
    "Support via chat",
    "Lavender Anywhere",
    "Multi-inbox support",
    "Individual coaching analytics",
    "Gmail + Outlook 365.",
  ],
  Pro: [
    "Unlimited emails",
    "Unlimited personalization",
    "Unlimited feature usage",
    "Unlimited Al recommendations",
    "Mobile optimization",
    "Support via chat",
    "Lavender Anywhere",
    "Multi-inbox support",
    "Individual coaching analytics",
    "Gmail + Outlook 365.",
    "Unlimited everything",
    "Priority Support",
    "Integrations",
  ],
  Teams: [
    "Everything in Starter & Pro",
    "Unlimited everything",
    "Integrations",
    "Priority support",
    "Team functionalities",
  ],
  "Teams10+": [
    "Dedicated CSM",
    "1-hour initial coaching call",
    "Annual coaching ($1,500 value, additional sessions available)",
    "First access to new features",
  ],
};
