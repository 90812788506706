import Lottie from "react-lottie";

import MenuSuccessJSON from "./menu_success.json";

export const MenuSuccess = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => (
  <Lottie
    width={width}
    height={height}
    options={{
      loop: false,
      autoplay: true,
      animationData: MenuSuccessJSON,
    }}
  />
);
