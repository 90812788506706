import { createRoot } from "react-dom/client";
import { MainLoopComponent } from "..";

export const renderMainLoopComponent = (): void => {
  const lvRoot = Object.assign(document.createElement("div"), {
    id: "lv-root",
  });
  document.body.append(lvRoot);
  const reactRoot = createRoot(lvRoot);
  window.addEventListener("unload", () => {
    reactRoot.unmount();
  });

  reactRoot.render(<MainLoopComponent />);
};
