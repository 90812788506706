export const CONFIRM_INFO_FORM = {
  FALLBACK_ERROR: "Error saving info",
  HEADER: "Confirm Info",
  SUBMIT: "Save & Confirm",
  SUBMITTING: "Saving...",
};
export const UPDATE_PROFILE_FORM = {
  FALLBACK_ERROR: "Error saving info",
  HEADER: "Update Profile",
  SUBMIT: "Save Profile Info",
  SUBMITTING: "Saving...",
};
export const VALUE_PROP_FORM = {
  INPUTS: {
    WHY_IS_IT_IMPORTANT: (companyName: string) =>
      `Why is it important to ${companyName}?`,
  },
  FALLBACK_ERROR: "Error saving value props",
};
