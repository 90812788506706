export const Loading = () => {
  return (
    <svg
      fill="none"
      viewBox="0 0 375 618"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMin slice"
    >
      <rect width="375" height="618" fill="url(#a)" />
      <g filter="url(#g)" opacity=".9">
        <path
          d="m44.216 444.47c0.1103 1.332 2.2726 3.163 3.6038 3.053-1.3312 0.11-3.1627 2.272-3.0524 3.604-0.1103-1.332-2.2726-3.163-3.6039-3.053 1.3313-0.11 3.1628-2.272 3.0525-3.604z"
          fill="var(--color-pear-300)"
        />
      </g>
      <path
        d="m112.93 57.052c0.167 2.0147 3.439 4.7864 5.454 4.6195-2.015 0.1669-4.786 3.4393-4.62 5.454-0.166-2.0147-3.439-4.7864-5.454-4.6195 2.015-0.1669 4.787-3.4393 4.62-5.454z"
        fill="var(--color-lavender-200)"
        opacity=".9"
      />
      <path
        d="m112.93 473.05c0.167 2.014 3.439 4.786 5.454 4.619-2.015 0.167-4.786 3.439-4.62 5.454-0.166-2.015-3.439-4.786-5.454-4.62 2.015-0.166 4.787-3.439 4.62-5.453z"
        fill="var(--color-marigold-300)"
        opacity=".9"
      />
      <path
        d="m311.04 450.42c0.167 2.014 3.439 4.786 5.454 4.619-2.015 0.167-4.787 3.44-4.62 5.454-0.167-2.014-3.439-4.786-5.454-4.619 2.015-0.167 4.787-3.44 4.62-5.454z"
        fill="var(--color-lavender-200)"
        opacity=".9"
      />
      <path
        d="m272.83 33.309c0.064 0.7735 1.32 1.8376 2.094 1.7735-0.774 0.0641-1.838 1.3204-1.774 2.0939-0.064-0.7735-1.32-1.8376-2.094-1.7735 0.774-0.0641 1.838-1.3204 1.774-2.0939z"
        fill="var(--color-marigold-300)"
        opacity=".9"
      />
      <path
        d="m318.93 138.16c0.064 0.774 1.32 1.838 2.094 1.774-0.774 0.064-1.838 1.32-1.774 2.094-0.064-0.774-1.32-1.838-2.094-1.774 0.774-0.064 1.838-1.32 1.774-2.094z"
        fill="var(--color-marigold-300)"
        opacity=".9"
      />
      <g filter="url(#f)" opacity=".9">
        <path
          d="m336.83 49.309c0.064 0.7735 1.32 1.8376 2.094 1.7735-0.774 0.0641-1.838 1.3204-1.774 2.0939-0.064-0.7735-1.32-1.8376-2.094-1.7735 0.774-0.0641 1.838-1.3204 1.774-2.0939z"
          fill="var(--color-base-White)"
        />
      </g>
      <g filter="url(#e)" opacity=".9">
        <path
          d="m229.93 49.16c0.064 0.7735 1.32 1.8376 2.094 1.7736-0.774 0.064-1.838 1.3204-1.774 2.0939-0.064-0.7735-1.32-1.8376-2.094-1.7736 0.774-0.064 1.838-1.3204 1.774-2.0939z"
          fill="var(--color-base-White)"
        />
      </g>
      <g filter="url(#d)" opacity=".9">
        <path
          d="m49.934 124.16c0.0641 0.774 1.3205 1.838 2.094 1.774-0.7735 0.064-1.8377 1.32-1.7736 2.094-0.0641-0.774-1.3204-1.838-2.0939-1.774 0.7735-0.064 1.8376-1.32 1.7735-2.094z"
          fill="var(--color-base-White)"
        />
      </g>
      <g filter="url(#c)" opacity=".9">
        <path
          d="m48.83 489.31c0.0641 0.773 1.3204 1.837 2.0939 1.773-0.7735 0.064-1.8376 1.321-1.7735 2.094-0.0641-0.773-1.3205-1.838-2.094-1.774 0.7735-0.064 1.8377-1.32 1.7736-2.093z"
          fill="var(--color-base-White)"
        />
      </g>
      <path
        d="m304.04 84.417c0.167 2.0147 3.439 4.7864 5.454 4.6195-2.015 0.1669-4.787 3.4393-4.62 5.454-0.167-2.0147-3.439-4.7864-5.454-4.6195 2.015-0.1669 4.787-3.4393 4.62-5.454z"
        fill="var(--color-marigold-300)"
        opacity=".9"
      />
      <g filter="url(#b)" opacity=".9">
        <path
          d="m81.328 23.276c0.1103 1.3312 2.2726 3.1627 3.6039 3.0524-1.3313 0.1103-3.1627 2.2726-3.0525 3.6038-0.1102-1.3312-2.2725-3.1627-3.6038-3.0524 1.3313-0.1103 3.1627-2.2726 3.0524-3.6038z"
          fill="var(--color-pear-300)"
        />
      </g>
      <defs>
        <filter
          id="g"
          x="39.164"
          y="442.47"
          width="10.656"
          height="10.656"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_10209_91294"
            stdDeviation="1"
          />
        </filter>
        <filter
          id="f"
          x="333.06"
          y="47.309"
          width="7.8676"
          height="7.8672"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_10209_91294"
            stdDeviation="1"
          />
        </filter>
        <filter
          id="e"
          x="226.16"
          y="47.16"
          width="7.8676"
          height="7.8672"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_10209_91294"
            stdDeviation="1"
          />
        </filter>
        <filter
          id="d"
          x="46.16"
          y="122.16"
          width="7.8674"
          height="7.8672"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_10209_91294"
            stdDeviation="1"
          />
        </filter>
        <filter
          id="c"
          x="45.056"
          y="487.31"
          width="7.8674"
          height="7.8672"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_10209_91294"
            stdDeviation="1"
          />
        </filter>
        <filter
          id="b"
          x="76.276"
          y="21.276"
          width="10.656"
          height="10.656"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_10209_91294"
            stdDeviation="1"
          />
        </filter>
        <linearGradient
          id="a"
          x1="187.5"
          x2="474.47"
          y1="2.3476e-6"
          y2="619.32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3D0DA1" offset="0" />
          <stop stopColor="var(--color-lavender-500)" offset=".465" />
          <stop stopColor="#A770ED" offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
