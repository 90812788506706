export const STORY_ROOT = "Application";

export enum StoryBackground {
  dark = "dark",
  panel = "panel",
  primary = "primary",
  light = "light",
}

export enum StoryBackgroundColor {
  dark = "#202125",
  panel = "#F6F8FC",
  primary = "var(--color-lavender-500)",
  light = "var(--color-base-White)",
}

export enum StoryLayout {
  centered = "centered",
  fullscreen = "fullscreen", // Show the story as-is, without padding
  none = "none",
  padded = "padded", // default
}

export enum DesignCategory {
  Atoms = "Atoms",
  Molecules = "Molecules",
  Organisms = "Organisms",
  Layout = "Layout",
  Utilities = "Utilities",
}

export enum StoryCategory {
  Loading = "Loading",
  Panels = "Panels",
}
