export const CTA = {
  MOST_RECENT: "Most recent",
  PRIVATE: "Private",
};

export const PANEL = {
  ALL_NOTES: "All Notes",
  NO_NOTES: "No notes found.",
};

export const POPOVER = {
  NOTES_ARE_PRIVATE: "Notes are private by default",
  IN_THE_FUTURE: "In the future, you'll be able to share publicly in your CRM.",
};
