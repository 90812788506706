import {
  PanelBlock,
  PanelBlockVariant,
  PanelBlockHeader,
  PanelListContainer,
} from "~src/layout/MainPanel/PanelBlock";

import { usePersonalization } from "~src/customHooks";

import { PERSONALIZATION_HEADER } from "~src/strings";

import { WorkHistoryList } from "./WorkHistoryList";
import { NoDataDisplay } from "~src/component/Molecules";

const { EXPERIENCE } = PERSONALIZATION_HEADER;

export const WorkHistory = () => {
  const {
    currentPersonalization: {
      profile: { loading, workHistory },
    },
  } = usePersonalization();

  const isEmpty = workHistory.length === 0;

  return (
    <PanelBlock loading={loading} variant={PanelBlockVariant.list}>
      {!loading && isEmpty ? (
        <NoDataDisplay mainText="We couldn't find any Work History." />
      ) : (
        <>
          <PanelBlockHeader headerText={EXPERIENCE} loading={loading} />
          <PanelListContainer>
            <WorkHistoryList loading={loading} workHistory={workHistory} />
          </PanelListContainer>
        </>
      )}
    </PanelBlock>
  );
};
