import { GongCall } from "~src/redux/typings";

import { useCRM, usePersonalization } from "./";

export const useGongData = (): GongCall[] => {
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = usePersonalization();
  const { crms } = useCRM();
  const { gong_data: gongData } = crms[email] || {};

  if (!gongData?.length) {
    return [];
  }

  return gongData;
};
