import { Person } from "../../redux/typings";

export interface HumanticParmams {
  chromeId: chromeId;
  email: emailAddress;
}

export interface HumanticResponse {
  data: HumanticResponseData;
  status: HumanticStatus;
}

export enum HumanticStatus {
  FOUND = "FOUND",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_FOUND = "NOT_FOUND",
}

export enum SorterSegment {
  motivator = "motivator",
  mover = "mover",
  collaborator = "collaborator",
  thinker = "thinker",
}

interface HumanticResponseData {
  id: string;
  status: string;
  message: string;
  email: string;
  progress: HumanticStatus;
  results: HumanticResponseDataResults;
  metadata: {
    status: HumanticStatus.FOUND | "";
    analysis_status: "COMPLETE" | "";
    confidence: {
      score: number;
      level: string;
    };
    twitter_analysis_status: string;
    linkedin_analysis_status: "COMPLETED" | "";
    status_code: number;
  };
  usage_stats: object;
  sorter_segment: Person["sorter_segment"];
}

interface HumanticResponseDataResults {
  first_name: string;
  last_name: string;
  demographics: object;
  display_name: string;
  user_name: string | null;
  user_id: string;
  user_profile_image: string;
  user_description: string | null;
  interests: object;
  interests_array: null;
  languages: EmptyObject;
  languages_arr: null;
  education: object[] | null;
  education_arr: object[] | null;
  work_history: object;
  work_history_arr: object[] | null;
  related_entities: object;
  location: string;
  timezone: string;
  mood: object;
  tech_usage: object;
  persona: PersonaCategorizedResponse;
  personality_analysis: PersonalityAnalysisResponse;
  social_interactions: object;
  social_profiles: object | null;
  photos: object;
  websites: object;
}

// INFO: Ocean and Disc
export interface PersonalityAnalysisResponse {
  disc_assessment: DiscAssessmentCategorizedResponse;
  ocean_assessment: OceanAssessmentCategorizedResponse;
  sentiment: SentimentCategorizedResponse; // I think unused
  summary: SummaryCategorizedResponse; // I think unused
}

interface PersonaCategorizedResponse {
  hiring: PersonaResponse;
  sales: PersonaResponse; // using this one
}

interface PersonaResponse {
  interests: [];
  email_personalization: {
    advice: object;
    definitions: object;
    examples: object;
  };
  communication_advice: CommunicationAdviceResponse;
  behavioural_factors: object;
  conversation_starters: [];
}

export interface CommunicationAdviceResponse {
  _type: string[];
  description: string[] | null; // description list
  adjectives: string[] | null; // key traits list
  personalized_email_subject: string;
  personalized_email: string;
  what_to_avoid: string[] | null; // dont's list
  what_to_say: string[] | null; // do's list
  key_traits: {
    "Risk Appetite": string;
    "Ability To Say No": string;
    Speed: string;
    "Decision Drivers": string;
  };
}

export interface DiscAssessmentCategorizedResponse {
  calculativeness: DiscAssessmentResponse;
  dominance: DiscAssessmentResponse;
  influence: DiscAssessmentResponse;
  steadiness: DiscAssessmentResponse;
}

export interface OceanAssessmentCategorizedResponse {
  agreeableness: OceanAssessmentResponse;
  openness: OceanAssessmentResponse;
  extraversion: OceanAssessmentResponse;
  emotional_stability: OceanAssessmentResponse;
  conscientiousness: OceanAssessmentResponse;
}

interface AssessmentResponse {
  score: number;
}

export interface DiscAssessmentResponse extends AssessmentResponse {
  level: "low" | "medium" | "high";
}

interface OceanAssessmentResponse extends AssessmentResponse {
  level: string;
}

interface SentimentCategorizedResponse {
  positive_sentiment: number;
  negative_sentiment: number;
  neutral_sentiment: number;
}

interface SummaryCategorizedResponse {
  disc: SummaryResponse;
  ocean: SummaryResponse;
}

interface SummaryResponse {
  description: string[];
  label: string[];
}
