import { store } from "~src/redux/store";
import { getBodyElement } from "~src/utils";

export const getBodyContainer = (emailContainer: HTMLElement) => {
  const {
    bodyIframe,
    bodySelector = "",
    iframeChildSelector,
  } = store.getState().config.settings?.selectors ?? {};

  return getBodyElement(
    bodyIframe,
    bodySelector,
    iframeChildSelector,
    emailContainer
  );
};
