import { useEffect, useRef } from "react";
import styled from "styled-components";

import LavenderIcon from "./images/LavenderIcon.svg";
import LavenderIconHover from "./images/LavenderIconHover.svg";
import { useDraggable, useLvIdentifier } from "~src/customHooks";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { lavenderAnywhereAction, mainPanelAction } from "../../redux";

export const LavenderButton = () => {
  const dispatch = useAppDispatch();
  const lvIdentifier = useLvIdentifier();
  const isOpen = useAppSelector((state) => state.lavenderAnywhere.isOpen);
  const onClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(lavenderAnywhereAction.toggle());
    dispatch(mainPanelAction.setView({ lvIdentifier, view: "profile" }));
    dispatch(mainPanelAction.toggle(lvIdentifier));
  };

  const draggableRef = useRef<HTMLDivElement>(null);
  const { initDraggable, removeDraggable } = useDraggable(draggableRef);

  useEffect(() => {
    if (draggableRef.current) {
      initDraggable();
    }

    return () => {
      removeDraggable();
    };
  }, [initDraggable, removeDraggable]);

  return (
    <LavenderAnywhereWrapper ref={draggableRef}>
      <CTAButton
        className={`${isOpen ? "open" : ""} lv-anywhere-CTA`}
        onClick={onClick}
      >
        <div className="lv-anywhere-icon" />
        <div className="lv-anywhere-icon-hover" />
      </CTAButton>
    </LavenderAnywhereWrapper>
  );
};

const LavenderAnywhereWrapper = styled.div`
  position: absolute;
  top: calc(50dvh - 32px);
  left: -36px !important;
`;

const CTAButton = styled.a`
  height: 62px;
  z-index: 10000;
  transition: right var(--lv-animation-speed) linear;
  &:hover {
    cursor: pointer;
  }
  &:active {
  }
  & .lv-anywhere-icon-hover {
    display: none;
  }
  &:hover .lv-anywhere-icon-hover {
    display: block;
  }
  &:hover .lv-anywhere-icon {
    display: none;
  }
  .lv-anywhere-icon {
    background: url(${LavenderIcon}) no-repeat;
    height: 36px;
    width: 36px;
  }
  .lv-anywhere-icon-hover {
    background: url(${LavenderIconHover}) no-repeat;
    height: 60px;
    width: 36px;
  }
`;
