import { networkRequest, URLS } from "~src/api";
import { EmailAnalysis, SystemData, WritingData } from "~src/redux/typings";

export const postEmailAnalysis = async (
  lvIdentifier: string,
  systemData: SystemData,
  writingData: WritingData
) => {
  console.debug("postEmailAnalysis", lvIdentifier, systemData, writingData);
  const data = await networkRequest.post<EmailAnalysis>(URLS.emailAnalysis, {
    ...systemData,
    ...writingData,
  });
  return { ...data, lvIdentifier };
};
