import { styled } from "styled-components";

import { ResultsList } from "./ResultsList";
import { ResultsHeader } from "./ResultsHeader";

export const SearchModalResults = () => (
  <SearchModalResultsWrapper>
    <SearchModalResultsContainer>
      <ResultsHeader />
      <ResultsList />
    </SearchModalResultsContainer>
  </SearchModalResultsWrapper>
);

const SearchModalResultsWrapper = styled.div`
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  max-height: 450px;
  overflow: scroll;
`;

const SearchModalResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
