///<reference path="./settingsCarousel.d.ts" />
import { createSlice } from "@reduxjs/toolkit";

const initialState: SettingsCarouselState = {};

const settingsCarouselSlice = createSlice({
  name: "settingsCarousel",
  initialState,
  reducers: {
    setSlide: (
      state,
      {
        payload: { lvIdentifier, slide },
      }: { payload: { lvIdentifier: lvIdentifier; slide: settingSlides } }
    ) => {
      return {
        ...state,
        [lvIdentifier]: {
          ...state[lvIdentifier],
          slide,
        },
      };
    },
    initialize: (
      state,
      { payload: lvIdentifier }: { payload: lvIdentifier }
    ) => {
      return {
        ...state,
        [lvIdentifier]: {
          slide: "userSettings",
        },
      };
    },
  },
});

export const settingsCarouselAction = settingsCarouselSlice.actions;
export const settingsCarouselReducer = settingsCarouselSlice.reducer;
