import { store } from "~src/redux/store";
import { configActions } from "../redux";
import { getElementValue } from ".";

export const detectEmailAddress = (platform: string | undefined): string => {
  const state = store.getState();
  if (state.config.emailAddress) {
    return state.config.emailAddress;
  }
  const { selectors } = state.config.settings ?? {};
  const { emailAddressSelector, emailAddressAttrName = "" } = selectors ?? {};
  if (!emailAddressSelector) {
    return "";
  }
  if (platform === "outlookNative" || platform === "outlook") {
    return getOutlookNativeEmailAddress(emailAddressSelector);
  }

  const element = document.querySelector(emailAddressSelector);
  const rawEmailAddress = getElementValue(
    element as HTMLElement,
    emailAddressAttrName
  );
  const emailRe = /\b\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,13})+\b/g;

  // INFO : Decode the email address before matching, Outlook Web will encode email in a link
  const emailAddressMatchList =
    decodeURIComponent(rawEmailAddress).match(emailRe);

  if (emailAddressMatchList !== null) {
    store.dispatch(configActions.setEmailAddress(emailAddressMatchList[0]));
    return emailAddressMatchList[0];
  }
  return "";
};
const getOutlookNativeEmailAddress = (emailAddressSelector: string): string => {
  // INFO: We create this element from the Lavender Outlook Native repo under public/index.html
  const customDomElement = document.querySelector(emailAddressSelector);
  return customDomElement?.textContent || "";
};
