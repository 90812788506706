import { useEffect, useMemo, useRef, useState } from "react";
import { Flex } from "antd";
import { Tooltip } from "@lavender-ai/lav-components";

import {
  RecipientMenuGroup,
  StartMyEmailMenuButton,
  MobileDeviceMenu,
} from "~src/component/Organisms";
import { MIN_EMAIL_BODY_WORD_COUNT } from "~src/constants";
import { useLvIdentifier, usePersonalization } from "~src/customHooks";
import {
  fetchEmailAnalysis,
  selectEmailAnalysis,
  selectEmailData,
  selectFeatures,
  selectPlatform,
  store,
  useMainPanelView,
  usePanelIsOpen,
} from "~src/redux";
import { useAppDispatch, useAppSelector } from "~src/redux/hooks";
import { EmailAnalysisStage, NetworkStatus } from "~src/redux/typings";
import { getRoutedItems } from "~src/utils";

import {
  getBodyLength,
  getSystemsData,
  getWritingData,
} from "../EmailCoachPanel/utils";
import { OutlookMenuDropdown } from "./OutlookMenuDropdown";
import {
  getStringFromEmailBody,
  usePostFinalAnalysis,
  useShowPanel,
} from "./utils";
import {
  LogoButton,
  EmailCoachButton,
  ChatGPTButton,
  GifsButton,
  FrameworksButton,
  SettingsButton,
  AnalyticsToggle,
} from "./components";
import { EmailBadge, FlexMenuItem } from "./Menu.styled";

export const Menu = () => {
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const isOpen = usePanelIsOpen();
  const { sme_router: hasSmeRoutingFF } = useAppSelector(selectFeatures);
  const menuRef = useRef(null);
  const view = useMainPanelView();
  const {
    currentPersonalization: { cart },
  } = usePersonalization();
  const showPanel = useShowPanel();
  const emailCoachData = useAppSelector(selectEmailAnalysis(lvIdentifier));
  const { settings, status } = useAppSelector(({ userAccount }) => userAccount);
  const postFinalAnalysis = usePostFinalAnalysis();
  const [hasShownLogin, setHasShownLogin] = useState(false);

  const emailData = useAppSelector(selectEmailData(lvIdentifier));
  const hasBodyPaused = emailData?.hasBodyPaused ?? false;
  const body = emailData?.body ?? "";
  const hasSubjectPaused = emailData?.hasSubjectPaused ?? false;
  const subject = emailData?.subject ?? "";
  const stageOverwrite = emailData?.stageOverwrite ?? "";
  const scoringModel = emailData?.scoringModelOverwrite ?? false;

  // INFO: we need to the get the text from the html body without the hightlighted HTML tags and styles to compare with
  // the previous body
  const stringBody = getStringFromEmailBody(body);
  const bodyRef = useRef("");
  const subjectRef = useRef("");
  const stageOverwriteRef = useRef("");
  const scoringModelRef = useRef("");
  const writingData = getWritingData(lvIdentifier);
  const bodyLength = getBodyLength(writingData.body);
  const platform = useAppSelector(selectPlatform);

  useEffect(() => {
    // gmail replies fix. Resize triggers rebuilding
    if (platform === "gmail") {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 300);
    }
    // only fire once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (platform === "lavenderAnywhereIframe") {
      if (window.email_data) {
        showPanel(undefined, "emailCoach");
      } else {
        showPanel(undefined, "startMyEmail");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // INFO: check if the body or subject has changed
    if (emailCoachData?.status === NetworkStatus.loading) return;
    if (
      ((bodyRef.current !== stringBody && hasBodyPaused) ||
        (hasSubjectPaused && subjectRef.current !== subject) ||
        scoringModelRef.current !== scoringModel ||
        (stageOverwriteRef.current as EmailAnalysisStage) !== stageOverwrite) &&
      isOpen
    ) {
      // INFO: Due to async nature of Gmail loading a draft email on the screen, the wait time can sometimes be long
      // enough to be considered a pause in typing so we need to check if the body is long enough to be send to the
      // backend for analysis
      if (bodyLength >= MIN_EMAIL_BODY_WORD_COUNT) {
        void dispatch(
          fetchEmailAnalysis({
            lvIdentifier,
            systemData: getSystemsData(lvIdentifier),
            writingData: getWritingData(lvIdentifier),
          })
        );
      }
      // INFO: update the bodyRef with the new body and subjectRef with new subject to avoid infinite loop
      bodyRef.current = getStringFromEmailBody(body) || "";
      subjectRef.current = subject || "";
      stageOverwriteRef.current = stageOverwrite ?? "";
      // @ts-expect-error - needs fix
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      scoringModelRef.current = scoringModel || false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasBodyPaused,
    hasSubjectPaused,
    isOpen,
    stringBody,
    lvIdentifier,
    dispatch,
    writingData,
    bodyLength,
    emailCoachData,
    stageOverwrite,
    scoringModel,
  ]);

  // add listener to send button to do a final analysis on email send
  useEffect(() => {
    const state = store.getState();
    const sendButtonSelector = state.config.settings?.selectors?.sendButton;
    if (!sendButtonSelector) {
      return;
    }

    const sendButtons = document.querySelectorAll(sendButtonSelector);
    if (sendButtons.length < 1) {
      return;
    }

    sendButtons.forEach((sendButton) => {
      sendButton.addEventListener("click", postFinalAnalysis);
    });

    return () => {
      sendButtons.forEach((sendButton) => {
        sendButton.removeEventListener("click", postFinalAnalysis);
      });
    };
  }, [postFinalAnalysis]);

  useEffect(() => {
    if (!hasShownLogin && status === NetworkStatus.idle && !settings.user) {
      setHasShownLogin(true);
      showPanel(undefined, "settings");
    }
    // fire only once on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const smeCount = useMemo(() => {
    return !hasSmeRoutingFF || view !== "profile"
      ? 0
      : getRoutedItems(cart)?.length ?? 0;
  }, [cart, hasSmeRoutingFF, view]);

  return (
    <div className="lv-menu" ref={menuRef}>
      <Flex
        vertical={true}
        justify="space-between"
        align="center"
        style={{
          height: "96%",
          maxWidth: "calc(var(--lv-menu-size) - 21px)",
          margin: "10px",
        }}
      >
        <section>
          <LogoButton />
          <hr className="lv-menu-divider" />
          <FlexMenuItem>
            <EmailBadge count={smeCount}>
              <Tooltip
                placement="left"
                title={smeCount > 0 ? "Added to Start My Email" : ""}
                open={smeCount > 0}
              >
                <StartMyEmailMenuButton />
              </Tooltip>
            </EmailBadge>
          </FlexMenuItem>
          <EmailCoachButton />
          <RecipientMenuGroup />
          <ChatGPTButton />
          {platform === "outlookNative" && <MobileDeviceMenu />}
        </section>
        {platform === "outlookNative" ? (
          <OutlookMenuDropdown />
        ) : (
          <section>
            <MobileDeviceMenu />
            <FrameworksButton />
            <GifsButton />
            <hr className="lv-menu-divider" />
            <SettingsButton />
            <AnalyticsToggle />
          </section>
        )}
      </Flex>
    </div>
  );
};
