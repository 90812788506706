import { useEffect } from "react";
import { Flex } from "antd";
import { styled } from "styled-components";

import { Overlay } from "~src/component/Organisms/StartMyEmail/Overlay/Overlay";
import { PanelLayout } from "~src/layout/MainPanel/Panel/PanelLayout/PanelLayout";

import { PersonalizationPanelContainer } from "~src/component/Organisms";

import { Header } from "./Header/Header";
import {
  useCarouselContext,
  ModalType,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import { usePersonalization, useStartMyEmail } from "~src/customHooks";

export const StyledPanelLayout = styled(PanelLayout)`
  ${PersonalizationPanelContainer} {
    padding: 0 20px;
    text-align: left;
  }
`;

export const OverlayModal = () => {
  const {
    data: { openedBy },
    modalType,
    reset,
  } = useCarouselContext();
  const { resetCartContext } = usePersonalization();
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = useStartMyEmail();
  useEffect(() => {
    if (modalType === ModalType.Overlay && (!openedBy || openedBy !== email)) {
      reset();
      resetCartContext();
    }
  }, [openedBy, email, modalType, reset, resetCartContext]);
  return (
    <Overlay>
      <StyledPanelLayout rootClassName="lv-overlay-modal-box" isFullHeight>
        <Flex justify="space-between" vertical>
          <PersonalizationPanelContainer align="center" gap={12} vertical>
            <Header />
          </PersonalizationPanelContainer>
        </Flex>
      </StyledPanelLayout>
    </Overlay>
  );
};
