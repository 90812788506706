import { styled } from "styled-components";
import { Empty, Flex, Space } from "antd";
import { Avatar, Icon, Text } from "@lavender-ai/lav-components";
import { usePersonalization } from "~src/customHooks";
import { prettyDate } from "~src/utils";
import {
  RecentActivityEmailAnalysis,
  RecentActivityIndex,
} from "~src/api/typings";
import { LavenderIcon } from "~src/component/typings";
import { SecondaryText } from "~src/component/Styled";
import { NoDataDisplay } from "~src/component/Molecules";

interface EmailRowProps {
  data: RecentActivityEmailAnalysis;
}

const EmailRow = ({ data }: EmailRowProps) => {
  const {
    currentPersonalization: { profile },
  } = usePersonalization();
  return (
    <Flex style={{ padding: 12 }} gap={12}>
      <Avatar
        color="lavender"
        icon={
          <Icon
            color="lavender"
            size="extra-small"
            variant={LavenderIcon.IconMailForward}
          />
        }
        size="extra-small"
        type="icon"
        style={{
          background: "var(--color-lavender-100)",
          color: "var(--color-lavender-600, #4C2CB2)",
        }}
      />
      <Flex vertical gap={10}>
        <Text size={"body2"}>Email sent to {profile.name}</Text>
        <Space direction={"horizontal"} size={4}>
          <Text size={"body1"}>
            {data?.time_sent ? prettyDate(data.time_sent) : ""}
          </Text>
          <SecondaryText $light size={"body1"}>
            •
          </SecondaryText>
          <SecondaryText $light size={"body1"}>
            {data?.total_opens ?? 0} opens
          </SecondaryText>
          <SecondaryText $light size={"body1"}>
            •
          </SecondaryText>
          <SecondaryText $light size={"body1"}>
            {data?.reply_count ?? 0} replies
          </SecondaryText>
        </Space>
      </Flex>
    </Flex>
  );
};

const BarDown = styled.div`
  position: absolute;
  width: 2px;
  background: var(--color-neutral-blue-200, #e6e9f0);
  bottom: -8px;
  left: 23px;
  height: calc(100% - 32px);
`;

interface CalendarRowProps {
  data: any;
}

const CalendarRow = ({ data }: CalendarRowProps) => {
  const {
    currentPersonalization: { profile },
  } = usePersonalization();

  return (
    <Flex style={{ padding: 12 }} gap={12}>
      <Avatar
        style={{
          background: "#C8F0F0",
          color: "#258989",
        }}
        icon={
          <Icon size="extra-small" variant={LavenderIcon.IconCalendarCheck} />
        }
        size="extra-small"
      />
      <Flex gap={10} vertical>
        <Text size={"body2"}>Meeting with {profile.name}</Text>
        <Space direction={"horizontal"} size={4}>
          <SecondaryText $light size={"body1"}>
            {data.start ? prettyDate(data.start) : ""}
          </SecondaryText>
          <SecondaryText $light size={"body1"}>
            •
          </SecondaryText>
          <SecondaryText $light size={"body1"}>
            {data?.attendees?.length} Attendees
          </SecondaryText>
        </Space>
      </Flex>
    </Flex>
  );
};

export const RecentActivity = () => {
  const {
    currentPersonalization: { recent_activity },
  } = usePersonalization();

  const isEmpty = !recent_activity?.indexes?.length;

  return (
    <Space
      size={0}
      style={{
        background: "white",
        borderRadius: "var(--radius-lrg, 16px)",
        textAlign: "left",
        width: "-webkit-fill-available",
      }}
      direction={"vertical"}
    >
      {isEmpty ? (
        <NoDataDisplay mainText="We couldn't find any Recent Activity." />
      ) : (
        <>
          <div style={{ padding: "20px 16px" }}>
            <Text size={"subtext3"}>Recent Activity</Text>
          </div>
          {recent_activity?.indexes?.length ? null : (
            <Empty
              description={"No results"}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
          {recent_activity?.indexes?.map(
            (index: RecentActivityIndex, i: number) => {
              if (index.Type === "email_analysis") {
                return (
                  <div style={{ position: "relative" }} key={i}>
                    {i < (recent_activity?.indexes?.length || 0) - 1 ? (
                      <BarDown />
                    ) : null}
                    <EmailRow
                      key={i}
                      data={recent_activity.email_analysis[index.Id]}
                    />
                  </div>
                );
              }
              if (index.Type === "calendar_event") {
                return (
                  <div style={{ position: "relative" }} key={i}>
                    {i < (recent_activity?.indexes?.length || 0) - 1 ? (
                      <BarDown />
                    ) : null}
                    <CalendarRow
                      key={i}
                      data={recent_activity.calendar_event[index.Id]}
                    />
                  </div>
                );
              }
              return null;
            }
          )}
        </>
      )}
    </Space>
  );
};
