import { Flex } from "antd";
import { Text, TextProps } from "@lavender-ai/lav-components";
import { LoadableText } from "~src/component/Utilities/Loading/LoadableText";
import { StyledListHeader } from "~src/component/Organisms/Personalization/Shared";
import { SecondaryText } from "~src/component/Styled";
import styled from "styled-components";

interface Props {
  headerIcon?: React.ReactNode;
  headerSize?: TextProps["size"];
  headerText: string;
  loading?: boolean;
  subHeaderText?: string;
  amount?: number;
}

export const PanelBlockHeader = ({
  headerIcon = null,
  headerSize = "subtext3",
  headerText,
  loading = false,
  subHeaderText,
  amount = 0,
}: Props) => {
  if (subHeaderText) {
    let subHeaderHeight = "10.5px";
    if (headerSize === "subtext4") {
      subHeaderHeight = "8px";
    }
    return (
      <Flex
        className="lv-panel-list-header"
        vertical
        gap="middle"
        style={{ padding: "24px 20px 12px 20px", textAlign: "left" }}
      >
        <Text color="dark" size={headerSize}>
          <LoadableText height="17.5px" loading={loading} text={headerText} />
        </Text>
        <SecondaryText size="subtext1">
          <LoadableText
            height={subHeaderHeight}
            loading={loading}
            text={subHeaderText}
          />
        </SecondaryText>
      </Flex>
    );
  }
  return (
    <StyledListHeader
      className="lv-panel-list-header"
      color="dark"
      size={headerSize}
    >
      <Flex align="center" gap="small">
        {headerIcon}
        <LoadableText height={"17.5px"} loading={loading} text={headerText} />
        {amount > 0 && <AmountCount>{amount}</AmountCount>}
      </Flex>
    </StyledListHeader>
  );
};

const AmountCount = styled(Flex)`
  display: flex;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  padding: 0 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-lrg, 16px);
  background: var(--color-lavender-50, #ece2f9);
  margin-left: 8px;
  color: var(--color-primary-lavender, #6a2be9);
  font-family: "Bricolage Grotesque";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: -0.03px;
`;
