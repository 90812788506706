/// <reference path="./localStorage.d.ts"/>
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const INIT_LOCAL_STORAGE_STATE: LocalStorageState = {
  ignoreList: [],
  restartExtension: false,
};

export const localStorageSlice = createSlice({
  name: "localStorage",
  initialState: INIT_LOCAL_STORAGE_STATE,
  reducers: {
    ignoreItem(state, action: PayloadAction<string>) {
      const ignoreString = action.payload;
      if (!state.ignoreList.includes(ignoreString)) {
        state.ignoreList.push(ignoreString);
      }
    },
    unIgnoreItem(state, action: PayloadAction<string>) {
      const ignoreString = action.payload;
      if (state.ignoreList.includes(ignoreString)) {
        state.ignoreList = state.ignoreList.filter(
          (item) => item !== ignoreString
        );
      }
    },
    clearData(state) {
      state.ignoreList = [];
    },
    setRestartExtension: (state, { payload: restartExtension }) => {
      return { ...state, restartExtension };
    },
    setLavenderAnywhere: (state, { payload: lavenderAnywhere }) => {
      return { ...state, lavenderAnywhere };
    },
    setSpellingGrammar: (state, { payload: spellingGrammar }) => {
      return { ...state, spellingGrammar };
    },
    setToolTips: (state, { payload: tooltips }) => {
      return { ...state, tooltips };
    },
    setPerformanceMode: (state, { payload: performanceMode }) => {
      return { ...state, performanceMode };
    },
  },
});

export const localStorageActions = localStorageSlice.actions;
export const localStorageReducer = localStorageSlice.reducer;
