import { FC } from "react";
import styled from "styled-components";

import { useMobileEditor } from "~src/contexts";
import { imgSources } from "./images";
import { Icon } from "@lavender-ai/lav-components";
import {
  SegmentEventLocations,
  SegmentEventNames,
  segmentEvent,
} from "~src/utils";

const DragHandleDiv = styled.div`
  width: 37px;
  height: 25px;
  transform: scale(0.8);
  background-position: center;
  cursor: move;
  background-image: url(${imgSources.dragHandle});
  margin-left: -19px;
`;

const PhoneDragHandleContainer = styled.div`
  display: flex;
  width: 147px;
  height: 25px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--radius-lg) var(--radius-lg) 0 0;
  top: -4%;
  left: 25%;
  background: var(--color-primary-lavender);
  padding: 0 11px;
  box-sizing: border-box;
`;

const WatchDragHandleContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 10;
  top: 29px;
  left: 51px;
  width: 98px;
  height: 25px;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--radius-lg) var(--radius-lg) 0 0;
  background: var(--color-primary-lavender);
  padding: 0 11px;
  box-sizing: border-box;
`;

export const DragHandle: FC<{ type: "watch" | "phone" }> = ({ type }) => {
  const {
    hideMobileEditor,
    enableDarkMode,
    disableDarkMode,
    isDarkMode,
    enableMobileDevice,
    enableWatchDevice,
  } = useMobileEditor();

  const DragHandleContainer =
    type === "phone" ? PhoneDragHandleContainer : WatchDragHandleContainer;

  return (
    <DragHandleContainer>
      <div
        style={{ display: "flex", justifyContent: "space-between", width: 37 }}
      >
        {type === "phone" && (
          <Icon
            variant={isDarkMode ? "IconSun" : "IconMoonFilled"}
            size="mini"
            color="white"
            onClick={() => {
              if (isDarkMode) {
                segmentEvent(
                  SegmentEventNames.DarkModeClicked,
                  SegmentEventLocations.MobileEditor,
                  { action: "disabled" }
                );
                disableDarkMode();
              } else {
                segmentEvent(
                  SegmentEventNames.DarkModeClicked,
                  SegmentEventLocations.MobileEditor,
                  { action: "enabled" }
                );
                enableDarkMode();
              }
            }}
            style={{ cursor: "pointer" }}
          />
        )}

        <Icon
          variant={type === "watch" ? "IconPhone" : "IconDeviceWatch"}
          size="mini"
          color="white"
          onClick={() => {
            if (type === "watch") {
              segmentEvent(
                SegmentEventNames.MobilePhoneClicked,
                SegmentEventLocations.MobileEditor
              );
              enableMobileDevice();
            } else {
              segmentEvent(
                SegmentEventNames.AppleWatchClicked,
                SegmentEventLocations.MobileEditor
              );
              enableWatchDevice();
            }
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
      <DragHandleDiv
        onMouseDown={() => {
          segmentEvent(
            SegmentEventNames.MobileEditorDragged,
            SegmentEventLocations.MobileEditor
          );
        }}
      />
      <Icon
        variant="IconX"
        size="mini"
        color="white"
        onClick={() => {
          segmentEvent(
            SegmentEventNames.MobileEditorClicked,
            SegmentEventLocations.MobileEditor,
            { action: "close" }
          );
          hideMobileEditor();
        }}
        style={{ cursor: "pointer" }}
      />
    </DragHandleContainer>
  );
};
