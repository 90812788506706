import React, { FC, useEffect, useState } from "react";
import { Flex, Spin } from "antd";
import styled from "styled-components";

import {
  Icon,
  Text,
  Tooltip,
  Animation,
  Button,
} from "@lavender-ai/lav-components";

import { useLvIdentifier } from "~src/customHooks";
import {
  readTheRoomEmailSummaryAction,
  openEmailTrackingAction,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";
import { EmailBodyError, MAILCLIENT } from "~src/constants";
import { LavenderIcon } from "~src/component/typings";
import { NetworkStatus, ReadTheRoomAnalysis } from "~src/redux/typings";

import { TopBar } from ".";
import { scrapeEmailData } from "./scrapeEmailData";

import errorEmail from "~src/assets/images/envelope-error.svg";

export const EmailSummary = ({ container }: { container: HTMLElement }) => {
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const [clicked, setClicked] = useState(false);

  const toggleOpenClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(readTheRoomEmailSummaryAction.toggle({ lvIdentifier }));
    if (!clicked) {
      refreshEmailSummaryData();
      setClicked(true);
    }
  };
  //INFO: control the active icon look
  const isOpen = useAppSelector(
    (state) => state.readTheRoomEmailSummary[lvIdentifier].isOpen
  );
  const chromeId = useAppSelector((state) => state.config.chromeId);

  const refreshEmailSummaryData = () => {
    scrapeEmailData(container, chromeId, lvIdentifier).catch((err) => {
      console.error("ERROR: EMAIL Threads, unable to get data", err);
      dispatch(
        readTheRoomEmailSummaryAction.setAnalyticsErrorMessage({
          lvIdentifier,
          error: "Unable to read the room at this time",
        })
      );
    });
  };
  const settings = useAppSelector((state) => state.config.settings);

  // INFO: close open email count if open
  useEffect(() => {
    if (
      isOpen &&
      lvIdentifier !== undefined &&
      settings?.platform !== MAILCLIENT.OUTLOOK_NATIVE
    ) {
      dispatch(openEmailTrackingAction.close({ lvIdentifier }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lvIdentifier, isOpen]);

  useEffect(() => {
    if (settings?.platform === MAILCLIENT.OUTLOOK_NATIVE) {
      dispatch(readTheRoomEmailSummaryAction.open({ lvIdentifier }));
      if (!clicked) {
        refreshEmailSummaryData();
        setClicked(true);
      }
    }
  }, []);

  return (
    <>
      <span
        onClick={toggleOpenClose}
        className={`lv-email-thread-icon-hit-box ${
          isOpen ? "lv-email-thread-icon-active" : "lv-email-thread-icon-hover"
        }`}
      >
        <Tooltip placement="bottom" title="Read the room" hideArrow>
          <Icon color="lavender" size="small" variant="IconNotes" />
        </Tooltip>
      </span>
      {/* INFO: this is the popup that shows up when you click on the icon, to prevent the tool tip from showing we need it outside of the workaround span*/}
      <EmailSummaryAnalytics
        toggleOpenClose={toggleOpenClose}
        refreshEmailSummaryData={refreshEmailSummaryData}
      />
    </>
  );
};

const EmailSummaryAnalytics: FC<{
  toggleOpenClose: (e: React.MouseEvent) => void;
  refreshEmailSummaryData: () => void;
}> = ({ toggleOpenClose, refreshEmailSummaryData }) => {
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();

  const emailSummary = useAppSelector((state) =>
    state.readTheRoomEmailSummary[lvIdentifier]?.summary.replace(/\n/g, "")
  );
  const emailAnalytics = useAppSelector(
    (state) => state.readTheRoomEmailSummary[lvIdentifier]?.emailAnalysis
  );
  const emailAnalyticsError = useAppSelector(
    (state) => state.readTheRoomEmailSummary[lvIdentifier]?.analyticsError
  );
  const analyticsNetworkResponse: NetworkStatus = useAppSelector(
    (state) => state.readTheRoomEmailSummary[lvIdentifier]?.analyticsStatus
  );
  const isOpen = useAppSelector(
    (state) => state.readTheRoomEmailSummary[lvIdentifier].isOpen
  );

  if (
    analyticsNetworkResponse === NetworkStatus.success &&
    !validEmailAnalyticsObject(emailAnalytics)
  ) {
    dispatch(
      readTheRoomEmailSummaryAction.setAnalyticsErrorMessage({
        lvIdentifier,
        error: "Unable to read the room at this time",
      })
    );
  }

  const closePopup = (e: Event) => {
    e.stopPropagation();
    dispatch(readTheRoomEmailSummaryAction.close({ lvIdentifier }));
  };

  // INFO: create rows data for analytics
  let rows = [];
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (emailAnalytics && !emailAnalyticsError) {
    const rowsData = [
      {
        label: "Sentiment",
        value: getSentiment(emailAnalytics as ReadTheRoomAnalysis),
      },
      {
        label: "Tone",
        value: getTopTone(emailAnalytics as ReadTheRoomAnalysis, true),
      },
      {
        label: "Formality",
        value: getFormality(emailAnalytics as ReadTheRoomAnalysis, true),
      },
      {
        label: "Email Length",
        value: getBodyLength(emailAnalytics as ReadTheRoomAnalysis),
      },
    ];
    rows = rowsData.map(
      (row, index) =>
        createRow(row.label, row.value, index, lvIdentifier) as never
    );
  }

  return (
    <EmailSummaryAnalyticsWrapper
      className={`lv-email-thread-popup-${
        isOpen ? "open" : "close"
      } lv-email-summary-analytics-container`}
      vertical={true}
      justify="center"
      align="center"
    >
      <Spin
        spinning={showLoading(
          emailAnalytics as ReadTheRoomAnalysis,
          emailAnalyticsError
        )}
        indicator={
          <Animation
            className="lv-email-summary-animation"
            variant="brand-loading"
            style={{
              position: "relative",
              left: "0px",
              top: "30%",
              height: "80px",
              width: "80px",
            }}
          />
        }
      >
        <TopBar title="Read The Room" onClose={closePopup} />
        <MainBody
          vertical={false}
          justify="space-around"
          align="flex-start"
          className="lv-email-summary-analytics-main"
          hasErrors={!!emailAnalyticsError}
        >
          <RowContainer vertical={true}>{rows}</RowContainer>
          <Flex
            vertical={true}
            className="lv-email-summary-analytics-summarySection"
          >
            <Flex vertical={false}>
              {!emailAnalyticsError && (
                <>
                  <StyledIcon
                    className="lv-thread-summary-icon"
                    variant={LavenderIcon.IconSparkles}
                    color="lavender"
                    size="mini"
                  />
                  <Text color="lavender" size="body2">
                    Summary
                  </Text>
                </>
              )}
            </Flex>
            <span className="lv-thread-email-summary">
              {hasValidEmailAnalytics(emailAnalytics, emailAnalyticsError) &&
                emailSummary && (
                  <Text size="body2" color="black">
                    <span className="lv-textWrap">{emailSummary}</span>
                  </Text>
                )}
              {emailAnalyticsError && (
                <ErrorComponent
                  emailAnalyticsError={emailAnalyticsError}
                  EmailBodyError={EmailBodyError}
                  toggleOpenClose={toggleOpenClose}
                  refreshEmailSummaryDate={refreshEmailSummaryData}
                />
              )}
            </span>
          </Flex>
        </MainBody>
        {!emailAnalyticsError && (
          <div className="lv-email-summary-analytics-response">
            <section>
              {hasValidEmailAnalytics(emailAnalytics, emailAnalyticsError) ? (
                <RTRResponse
                  emailAnalytics={emailAnalytics as ReadTheRoomAnalysis}
                />
              ) : (
                <span>&nbsp;</span>
              )}
            </section>
          </div>
        )}
      </Spin>
    </EmailSummaryAnalyticsWrapper>
  );
};
const RefreshButton = styled(Button)`
  width: 28%;
  margin: 15px auto 8px;
`;

const ErrorComponent = ({
  emailAnalyticsError,
  EmailBodyError,
  toggleOpenClose,
  refreshEmailSummaryDate,
}: {
  emailAnalyticsError: string;
  EmailBodyError: string;
  toggleOpenClose: (e: React.MouseEvent) => void;
  refreshEmailSummaryDate: () => void;
}) => {
  const showRefreshButton = emailAnalyticsError !== EmailBodyError;

  return (
    <Flex vertical={true} justify="center">
      <div style={{ margin: "auto" }}>
        <EnvelopeErrorImg
          src={errorEmail}
          alt="Error"
          style={{ width: "62px" }}
        />
      </div>
      <Text size="subtext3" style={{ margin: "12px auto 0" }}>
        Oops we ran into a problem
      </Text>
      <Text size="body1" style={{ textAlign: "center", margin: "auto" }}>
        <span className="lv-textWrap">
          {` ${
            emailAnalyticsError === EmailBodyError
              ? EmailBodyError
              : "There was issue loading email summary. Try refreshing your page."
          }`}
        </span>
      </Text>
      {showRefreshButton && (
        <RefreshButton
          htmlType="button"
          onClick={refreshEmailSummaryDate}
          prefix="IconRefresh"
          radius="xl"
          size="medium"
          state="initial"
          variant="default"
        >
          Refresh
        </RefreshButton>
      )}
      {!showRefreshButton && (
        <RefreshButton
          htmlType="button"
          onClick={toggleOpenClose}
          radius="xl"
          size="medium"
          state="initial"
          variant="default"
        >
          Close
        </RefreshButton>
      )}
    </Flex>
  );
};

const showLoading = (
  emailAnalytics: ReadTheRoomAnalysis,
  emailAnalyticsError: string
) => {
  if (emailAnalyticsError) {
    return false;
  }
  if (emailAnalytics === undefined || Object.keys(emailAnalytics).length <= 0) {
    return true;
  }
  return !validEmailAnalyticsObject(emailAnalytics);
};
const validEmailAnalyticsObject = (
  emailAnalytics:
    | ReadTheRoomAnalysis
    | Record<string, unknown>
    | Record<string, []>
    | Record<string, number>
) => {
  if (Object.keys(emailAnalytics).length <= 0) {
    return false;
  }
  return true;
};
const hasValidEmailAnalytics = (
  emailAnalytics:
    | ReadTheRoomAnalysis
    | Record<string, unknown>
    | Record<string, []>
    | Record<string, number>,
  emailAnalyticsError: string
) => {
  if (emailAnalyticsError) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!emailAnalytics) {
    return false;
  }
  return validEmailAnalyticsObject(emailAnalytics);
};
const RTRResponse: FC<{ emailAnalytics: ReadTheRoomAnalysis }> = ({
  emailAnalytics,
}) => {
  const Response = styled.div`
    & svg + span,
    & svg + span span {
      display: inline !important;
    }
  `;
  const Info = styled(Icon)`
    vertical-align: bottom;
    margin-right: 5px;
  `;
  return (
    <Response>
      <Info
        color="lavender"
        size="small"
        variant={LavenderIcon.IconInfoSquareRoundedFilled}
      />

      <Text color="black" size="body2">
        The response is&nbsp;
        <Text color="black" size="subtext1" style={{ fontWeight: 900 }}>
          {getFormality(emailAnalytics, false)}
        </Text>
        {confidenceSentence(emailAnalytics)}.&nbsp;
        {makeSureSentence(emailAnalytics)}
      </Text>
    </Response>
  );
};
const makeSureSentence = (emailAnalytics: ReadTheRoomAnalysis) => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!emailAnalytics?.top_tones || emailAnalytics.top_tones.length === 0) {
    return "";
  }
  return (
    <>
      Make sure your tone is&nbsp;
      <Text color="black" size="subtext1" style={{ fontWeight: 900 }}>
        {getTopTone(emailAnalytics, false)}
      </Text>
    </>
  );
};
const confidenceSentence = (emailAnalytics: ReadTheRoomAnalysis) => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!emailAnalytics.top_tones || emailAnalytics?.top_tones.length === 0) {
    return "";
  }
  if (emailAnalytics.top_tones.includes("unsure")) {
    return (
      <span>
        &nbsp;but also{" "}
        <Text color="black" size="subtext1" style={{ fontWeight: 900 }}>
          unsure
        </Text>
      </span>
    );
  }
  if (emailAnalytics.top_tones.includes("confident")) {
    return (
      <span>
        &nbsp;but also{" "}
        <Text color="black" size="subtext1" style={{ fontWeight: 900 }}>
          confident
        </Text>
      </span>
    );
  }
  return "";
};
const makePrettyString = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const getSentiment = (
  emailAnalytics: ReadTheRoomAnalysis | Record<string, string>
) => {
  // INFO: if sentiment is not available, use top tone
  if (!emailAnalytics?.sentiment) {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (emailAnalytics?.top_tones && emailAnalytics.top_tones.length > 0) {
      return makePrettyString(emailAnalytics.top_tones[0]);
    }
    // INFO: if top tone is not available, use neutral
    return "Neutral";
  }
  return makePrettyString(emailAnalytics.sentiment);
};

const getTopTone = (
  emailAnalytics: ReadTheRoomAnalysis | Record<string, []>,
  shouldUppercase: boolean
) => {
  // INFO: if top tone is not available, use neutral
  if (
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    !emailAnalytics?.top_tones ||
    emailAnalytics.top_tones.length === 0 ||
    typeof emailAnalytics.top_tones[0] !== "string"
  ) {
    return "Neutral";
  }

  if (emailAnalytics.top_tones.length > 0) {
    const prettyValue = makePrettyString(emailAnalytics.top_tones[0]);
    if (shouldUppercase) {
      return prettyValue;
    }
    return prettyValue.toLocaleLowerCase();
  }
  return "";
};
const getBodyLength = (
  emailAnalytics: ReadTheRoomAnalysis | Record<string, number>
) => {
  if (!emailAnalytics?.body_length) {
    return "";
  }
  if (emailAnalytics.body_length <= 40) {
    return "Short";
  }
  if (emailAnalytics.body_length >= 250) {
    return "Long";
  }
  return "Medium";
};
const getFormality = (
  emailAnalytics: ReadTheRoomAnalysis,
  shouldUppercase: boolean
) => {
  // INFO: if formality is not available, use neutral
  if (!emailAnalytics.formality) {
    if (shouldUppercase) {
      return "Neutral";
    }
    return "neutral";
  }
  if (emailAnalytics.formality <= 35) {
    if (shouldUppercase) {
      return "Casual";
    }
    return "casual";
  }
  if (emailAnalytics.formality > 65) {
    if (shouldUppercase) {
      return "Formal";
    }
    return "formal";
  }
  if (shouldUppercase) {
    return "Neutral";
  }
  return "neutral";
};
const createRow = (
  label: string,
  value: string | undefined,
  index,
  lvIdentifier
) => {
  return (
    <Flex
      key={`${lvIdentifier}-lv-analytics-row-${index}`}
      vertical={false}
      justify="space-between"
      className="lv-email-summary-analytics-row"
    >
      <Text size="body2">{label}</Text>
      <Text color="black" size="body2">
        {value ?? ""}
      </Text>
    </Flex>
  );
};
interface MainBodyProps {
  hasErrors?: boolean;
}
const StyledIcon = styled(Icon)`
  margin-right: 10px;
  position: relative;
  bottom: 5px;
`;
const RowContainer = styled(Flex)`
  padding: 8px 24px 8px 16px;
  max-width: 198px;
  width: 198px;
  min-width: 198px;
  background-color: var(--lv-thread-background-color);
  border: solid 0 var(--lv-panel-background-color);
  border-right-width: 1px;
`;
const EmailSummaryAnalyticsWrapper = styled(Flex)`
  width: 578px;
  min-width: 578px;
  max-width: 578px;
  border: solid var(--lv-border-color) 1px;
  border-radius: 16px;
  position: absolute;
  top: 58px;
  right: 0;
  z-index: 10;
  background-color: var(--lv-panel-background-color);
  box-shadow: 0px 2px 9px 2px var(--lv-border-color);
`;
const MainBody = styled(Flex).withConfig({
  shouldForwardProp: (prop) => !["hasErrors"].includes(prop),
})<MainBodyProps>`
  width: 100%;
  background-color: var(--lv-thread-background-color);
  min-height: 190px;
  text-align: left;
  ${(props) =>
    props.hasErrors &&
    `
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  `}
`;
const EnvelopeErrorImg = styled.img`
  rotate: -3deg;
`;
