import styled from "styled-components";

interface StyledListItemProps {
  key?: number;
}

export const StyledListItem = styled.div<StyledListItemProps>`
  display: flex;
  gap: 12px;
  padding: 16px 12px;
  text-align: left;
`;
