import { Col, Divider, Flex, Row, Space } from "antd";
import { useState } from "react";
import { styled } from "styled-components";

import {
  Avatar,
  Button,
  Dropdown,
  Icon,
  Text,
} from "@lavender-ai/lav-components";

import { getTimeDifferenceString } from "../../../../../../utils/datetime";
import { LavenderIcon } from "~src/component/typings";
import { usePersonalization } from "~src/customHooks";
import { LinkedInPost as ILinkedinPost } from "~src/redux/typings";

const ShowMoreLessButton = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
`;

const MoreLessButton = styled(Button)`
  width: 100%;
  margin: 10px 0;
`;

const DropDownContainer = styled.div`
  position: relative;
  #lav-dropdown-popup-menu {
    right: 0;
  }
  z-index: 10;
`;

const MainPostImage = styled.img<{ $fullBorder: boolean }>`
  width: 100%;
  height: 148px;
  object-fit: cover;
  border-radius: ${(props) => (props.$fullBorder ? "8px" : "8px 8px 0 0")};
`;

interface LinkedinPostProps {
  post: ILinkedinPost;
}

function splitCamelCase(str: string) {
  return str?.replace(/([A-Z])/g, " $1").trim();
}

export const LinkedinPost = ({ post }: LinkedinPostProps) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(true);
  };

  const handleCollapse = () => {
    setShowMore(false);
  };
  let postContent = post.post_content;
  let originalPostContent = post.original_post_content;

  let needSpace = true;
  if (!postContent) {
    postContent = "";
    needSpace = false;
  }
  if (!originalPostContent) {
    originalPostContent = "";
  }

  if (postContent && !originalPostContent) {
    originalPostContent = postContent;
    postContent = "";
    needSpace = false;
  }

  let timeEdited = false;
  let originalTimeEdited = false;

  if (!(post.time === undefined || post.time === null)) {
    if (post.time.includes("Edited")) {
      timeEdited = true;
    }
  }
  if (
    !(post.original_post_time === undefined || post.original_post_time === null)
  ) {
    if (post.original_post_time.includes("Edited")) {
      originalTimeEdited = true;
    }
  }

  const linesToShow = showMore
    ? postContent.split("\n")
    : postContent.split("\n").slice(0, 3);

  const linesToShowOriginal = showMore
    ? originalPostContent.split("\n")
    : originalPostContent.split("\n").slice(0, 3);

  const isTruncated = !showMore && originalPostContent.split("\n").length > 3;

  const ShowMoreLess = (
    <ShowMoreLessButton>
      {!showMore && post?.post_content?.split("\n").length > 3 && (
        <MoreLessButton
          size="medium"
          variant="secondary"
          fullWidth={true}
          onClick={handleShowMore}
        >
          Read More
        </MoreLessButton>
      )}
      {showMore && (
        <MoreLessButton
          size="medium"
          variant="secondary"
          fullWidth={true}
          onClick={handleCollapse}
        >
          Show Less
        </MoreLessButton>
      )}
    </ShowMoreLessButton>
  );
  const {
    currentPersonalization: { profile },
  } = usePersonalization();

  // todo: if has original post show different data
  return (
    <div>
      <Space
        size={20}
        direction={"vertical"}
        style={{ width: "100%", padding: "20px 4px" }}
      >
        {post.reposted && (
          <>
            <Flex justify={"flex-start"} gap={4} align={"baseline"}>
              <Text size={"subtext1"}>{profile.name}</Text>
              <Text size={"body1"}>reposted this</Text>
            </Flex>
            <Divider style={{ margin: "-8px 0 0 0" }} />
          </>
        )}
        {post.shared && (
          <Flex style={{ alignItems: "center" }}>
            <Avatar
              size={"small"}
              src={post.author_image}
              style={{ marginRight: "8px" }}
            />
            <Text size="subtext1">
              {splitCamelCase(post.author ? post.author : "")} •{" "}
              {splitCamelCase(getTimeDifferenceString(post.time_real))}{" "}
              {timeEdited && "Edited"}
            </Text>
          </Flex>
        )}
        {needSpace && (
          <Space direction={"vertical"} size={8}>
            {linesToShow.map((p, idx) => (
              <Text key={idx} size={"body2"}>
                {p}
              </Text>
            ))}
          </Space>
        )}
        <Flex justify={"space-between"} style={{ alignItems: "center" }}>
          <Flex style={{ alignItems: "center" }}>
            <Avatar
              size={"small"}
              src={
                post.original_author_image
                  ? post.original_author_image
                  : post.author_image
              }
              style={{ marginRight: "8px" }}
            />
            <Text size={"subtext1"}>
              {splitCamelCase(
                post.original_author ? post.original_author : post.author
              )}{" "}
              •{" "}
              {post.shared ? (
                <>
                  {splitCamelCase(
                    getTimeDifferenceString(post.original_post_time_real)
                  )}{" "}
                  {originalTimeEdited && "Edited"}
                </>
              ) : (
                <>
                  {splitCamelCase(getTimeDifferenceString(post.time_real))}{" "}
                  {timeEdited && "Edited"}
                </>
              )}
            </Text>
          </Flex>
          <DropDownContainer>
            <Dropdown
              menuProps={{
                items: [
                  {
                    children: (
                      <Flex align={"center"} gap={8}>
                        <Icon
                          size="small"
                          variant={LavenderIcon.IconBrandLinkedin}
                        />
                        <Text size="body1">Read on LinkedIn</Text>
                      </Flex>
                    ),
                    key: "1",
                    onClick: () => window.open(post.link, "_blank"),
                  },
                ],
                style: { right: 0, width: 150 },
              }}
              arrow={false}
              size="small"
              variant="default"
              placement={"topRight"}
            >
              <Icon size="mini" variant="IconDots" />
            </Dropdown>
          </DropDownContainer>
        </Flex>
        {post.images?.length > 0 && (
          <div>
            <Row gutter={8}>
              {" "}
              <Col span={24}>
                <MainPostImage
                  $fullBorder={post.images?.length === 1}
                  src={post.images[0]}
                  alt="main post image"
                />
              </Col>
            </Row>
            {post.images.length > 1 && (
              <Row gutter={8}>
                {" "}
                {post.images
                  .slice(1, Math.min(post.images.length, 4))
                  .map((img, index) => (
                    <Col
                      span={8}
                      key={index}
                      style={{ position: "relative", textAlign: "center" }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "95px",
                          borderRadius:
                            index === 0
                              ? "0 0 0 8px"
                              : index === 1
                                ? "0 0 0 0"
                                : index === 2
                                  ? "0 0 8px 0"
                                  : "0px",
                          objectFit: "cover",
                        }}
                        src={img}
                        alt={`additional post image ${index + 1}`}
                      />
                      {index === 2 && post.images.length > 4 && (
                        <div
                          style={{
                            position: "absolute",
                            width: "26px",
                            height: "16px",
                            left: "calc(50% - 26px/2 - 0.17px)",
                            top: "calc(50% - 16px/2 + 0.5px)",
                            fontFamily: "Bricolage Grotesque",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "24px",
                            lineHeight: "28px",
                            color: "#FFFFFF",
                            letterSpacing: "-0.15px",
                          }}
                        >
                          +{post.images.length - 4}
                        </div>
                      )}
                    </Col>
                  ))}
              </Row>
            )}
          </div>
        )}

        <Space direction={"vertical"} size={8}>
          {linesToShowOriginal.map((p, idx, arr) => {
            const isLast = idx === arr.length - 1;
            return (
              <Text key={idx} size={"body2"}>
                {p}
                {isLast && isTruncated && "..."}
              </Text>
            );
          })}
        </Space>
        <Space direction={"vertical"} size={12} style={{ width: "100%" }}>
          {(Number(post.reaction) > 0 || Number(post.comment) > 0) && (
            <Flex vertical={false} style={{ alignItems: "center" }}>
              <Icon
                size="mini"
                variant="IconThumbUp"
                style={{ marginRight: "4px" }}
              />
              <Text size={"subtext1"} style={{ color: "#7F848F" }}>
                {post.reaction} reactions • {post.comment} comments
              </Text>
            </Flex>
          )}
          {ShowMoreLess}
        </Space>
      </Space>
    </div>
  );
};
