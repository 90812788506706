import {
  APIResponseStatus,
  StartMyEmailCart,
  StartMyEmailCartItem,
} from "~src/api/typings";

import { CustomCategories, ValuePropsState } from "../typings";

export const setValuePropsHelper = (
  state: ValuePropsState,
  email: emailAddress,
  generated: StartMyEmailCart,
  status: APIResponseStatus,
  isUserSettingValuePropGenerationOn: boolean
) => {
  const key = isUserSettingValuePropGenerationOn
    ? "valueProps"
    : "userValueProps";
  const prevValueProps = state[key][email];
  if (prevValueProps === undefined) {
    state[key][email] = {
      status,
      error: "",
      generated,
    };
    return;
  }

  state[key][email].status = status;
  state[key][email].error = "";

  for (const section in generated) {
    if (prevValueProps.generated[section] === undefined) {
      state[key][email].generated[section] = generated[section];
      continue;
    }

    Object.values<StartMyEmailCartItem>(generated[section]).forEach((item) => {
      state[key][email].generated[section][item.id] = item;
    });
  }
};

export const setCustomValuePropsHelper = (
  state: ValuePropsState,
  email: emailAddress,
  customCategories: CustomCategories | undefined
) => {
  state.customValueProps[email] = customCategories;
};
