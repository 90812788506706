import { Flex, Form } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { css, styled } from "styled-components";

import { Text } from "@lavender-ai/lav-components";

import { FeatureFlagEnum, RankedItem } from "~src/api/typings";
import {
  IconFrame,
  ItemCollapseHandle,
  LoadMoreButton,
} from "~src/component/Atoms";
import {
  PanelBlockContainer,
  StyledPanelBlockContainer,
} from "~src/component/Organisms/Personalization/Shared";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
  LoadableText,
} from "~src/component/Utilities";
import {
  useLoadMore,
  useRouteToCartModal,
  useStartMyEmail,
  useValuePropGeneration,
  useFeatureFlag,
  useRankedFeedApi,
  useRankedFeedApiDependencies,
  useCRM,
} from "~src/customHooks";
import {
  PanelBlock,
  PanelBlockVariant,
  PanelListContainer,
  PanelListItem,
  StyledPanelListItem,
} from "~src/layout/MainPanel/PanelBlock";
import { CustomField, CustomFieldForm } from "~src/component/Organisms/Forms";
import { getComponentByType } from "~src/component/Organisms/Modals/Shared";
import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import {
  EnhancedFlex,
  PanelBackgroundFlex,
  SecondaryText,
} from "~src/component/Styled";
import { LavenderIcon, PropsForStyling } from "~src/component/typings";
import { RANKED_FEED_TYPES } from "~src/constants";
import { useAppDispatch, valuePropsActions } from "~src/redux";
import {
  CustomCategories,
  EmailAnalysisStage as Stage,
} from "~src/redux/typings";
import {
  CART_HEADER,
  SME_CAPTION,
  SME_COLLAPSE,
  SME_CTA,
  SME_SUBHEADER,
  SME_TOOLTIP,
} from "~src/strings";
import {
  extractFirstName,
  hasItems,
  icebreakerCount,
  segmentEvent,
  SegmentEventNames,
  SegmentEventLocations,
  getAllRankfedFeedItems,
} from "~src/utils";

import { FullWidthFlex } from "../../Styled/FullWidthFlex";
import { StartMyEmailCTA } from "../../StartMyEmailCTA/StartMyEmailCTA";
import { Header } from "./Header/Header";
import { PersonalizationAssistant } from "./PersonalizationAssistant";
import {
  CustomContextItemCollapse,
  GongContextItemCollapse,
} from "~src/component/Molecules";

const IceBreakersPanelFlex = styled(Flex)<
  PropsForStyling<{ isLoading: boolean }>
>`
  height: 100%;
  ${StyledPanelBlockContainer} {
    ${({ $isLoading: isLoading }) =>
      isLoading &&
      css`
        width: var(--lv-expanded-panel-size);
        padding: 0 20px;
      `}
  }
  &:has(.lv-icebreakers-cta-tooltip.ant-tooltip-open) {
    .lv-icebreakers-highlightable {
      border: 1px solid var(--color-lavender-200);
      box-shadow: 0px 0px 0px 3px var(--color-lavender-50);
    }
  }

  &:not(:has(.lv-icebreakers-cta-tooltip.ant-tooltip-open)) {
    .lv-icebreakers-highlightable {
      border: 1px solid transparent;
    }
  }

  .lv-generate-emails-cta-container {
    border-top: 1px solid var(--color-neutral-purple-100);
    padding: 16px 0;
    padding-bottom: 0;
    position: sticky;
    bottom: 0;
    // INFO: this is to stop the component from shrinking when the panel is collapsed
    width: var(--lv-expanded-panel-size);
    z-index: 1071;
  }

  .toggle-tooltip--root& {
    min-width: 275px;
  }

  ${FullWidthFlex} {
    padding: 0 20px 16px 20px;

    .lv-panel-list-container {
      padding: 0;
      .ant-skeleton {
        padding: 16px 0;
        .ant-skeleton-paragraph {
          margin: 0;
        }
        .ant-skeleton-header {
          padding-top: 0;
        }
      }
    }
  }

  ${StyledPanelListItem} {
    padding: 0;
  }

  div:has(> .lv-load-more-button) {
    margin: 16px 0 0 0 !important;
  }
`;

export const IceBreakers = () => {
  const [gongToggleOn, setGongToggleOn] = useState(true);
  const [stage, setStage] = useState(Stage.cold);
  const customItemCollapseRef = useRef<ItemCollapseHandle>(null);

  const dispatch = useAppDispatch();
  const {
    currentPersonalization: {
      cart,
      profile: { email, name },
    },
  } = useStartMyEmail();
  const {
    events: { items: eventItems },
  } = cart;

  const { crms } = useCRM();
  const { loading: isGongDataLoading } = crms[email] || {};

  const { areDependenciesLoading } = useRankedFeedApiDependencies(email);

  const {
    data: { alert, cta, rankedFeed },
    isError,
    isLoading,
  } = useRankedFeedApi(email, areDependenciesLoading);

  const { next } = useCarouselContext();
  const { handleRouteToCartModal } = useRouteToCartModal();
  const { isValuePropGenerationOn } = useValuePropGeneration();
  const isRankedFeedLoading = areDependenciesLoading || isLoading || !!alert;
  const displayName = extractFirstName(name || "") || email || "";
  const hasSmeRouting = useFeatureFlag(FeatureFlagEnum.sme_router);
  const hasSmeReply = useFeatureFlag(FeatureFlagEnum.sme_reply);
  const cartCount = icebreakerCount(cart);
  const [form] = Form.useForm<CustomCategories>();
  const categories = Form.useWatch("categories", form);

  const allItems = getAllRankfedFeedItems(rankedFeed);
  const { currentItems, loadMore, hasMore } = useLoadMore(allItems);

  const currentItemsByType = useMemo(() => {
    return RANKED_FEED_TYPES.reduce<Record<string, RankedItem[]>>(
      (acc, type) => {
        acc[type] = currentItems.filter((item) => item.type === type);
        return acc;
      },
      // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
      {} as Record<string, RankedItem[]>
    );
  }, [currentItems]);

  const renderItems = useMemo(() => {
    return Object.entries(currentItemsByType)
      .map(([type, items]) => {
        return !isRankedFeedLoading && items.length > 0
          ? getComponentByType(type, items)
          : null;
      })
      .filter((item) => item !== null);
  }, [currentItemsByType, isRankedFeedLoading]);

  const submitForm = useCallback(
    (categories: CustomCategories) => {
      dispatch(
        valuePropsActions.setCurrentCustomValueProps({
          email,
          categories,
        })
      );
      handleRouteToCartModal(categories);
    },
    [dispatch, email, handleRouteToCartModal]
  );

  const onCTAClick = useCallback(() => {
    segmentEvent(
      SegmentEventNames.GenerateValuePropsClicked,
      SegmentEventLocations.StartMyEmail
    );
    form.submit();
  }, [form]);

  useEffect(() => {
    if (!hasSmeRouting) {
      return;
    }
    const smeCount = eventItems.filter((item) => item.showCart === true).length;
    if (smeCount > 0) {
      if (cta) {
        next(ModalType.ProfileForm, {
          openedBy: email,
          cta,
        });
      } else {
        next(ModalType.Cart, {
          openedBy: email,
          phase: CART_HEADER.VALUE_PROPS,
        });
      }
    }
  }, [next, email, hasSmeRouting, cta, eventItems]);

  const customContextItemCollapseChildren = (
    <EnhancedFlex gap={12} vertical $margin="-12px 0 0 0">
      <SecondaryText size="body1" $light>
        {SME_COLLAPSE.TRY_ADDING}
      </SecondaryText>
      <CustomFieldForm form={form} submitForm={submitForm}>
        <CustomField />
      </CustomFieldForm>
    </EnhancedFlex>
  );

  const mainCustomContext = categories?.custom?.main;

  useEffect(() => {
    if (
      stage !== Stage.cold ||
      (!isRankedFeedLoading && !hasItems(rankedFeed)) ||
      mainCustomContext
    ) {
      customItemCollapseRef.current?.open();
    }
  }, [mainCustomContext, isRankedFeedLoading, rankedFeed, stage]);

  return (
    <IceBreakersPanelFlex
      justify={isError ? undefined : "space-between"}
      vertical
      $isLoading={isRankedFeedLoading}
    >
      <Header displayName={displayName} stage={stage} setStage={setStage} />
      <PanelBlockContainer gap="8px">
        {!cta && !isError && stage === Stage.reply && hasSmeReply && (
          <GongContextItemCollapse
            gongToggleOn={gongToggleOn}
            onToggle={setGongToggleOn}
          />
        )}
        {!cta && !isError && (
          <CustomContextItemCollapse
            className="lv-icebreakers-highlightable"
            isCollapsible={!isRankedFeedLoading}
            isInitialCollapsed={!isRankedFeedLoading && !hasItems(rankedFeed)}
            isLoading={isRankedFeedLoading}
            ref={customItemCollapseRef}
          >
            {customContextItemCollapseChildren}
          </CustomContextItemCollapse>
        )}
        {stage === Stage.cold &&
          (isRankedFeedLoading || hasItems(rankedFeed)) && (
            <PanelBlock
              className="lv-icebreakers-highlightable"
              variant={PanelBlockVariant.list}
            >
              <EnhancedFlex gap="12px" $padding="20px 16px">
                <IconFrame
                  color="lavender"
                  isLoading={isRankedFeedLoading}
                  variant={LavenderIcon.IconSparkles}
                />
                <Flex vertical gap="12px">
                  <Text color="dark" size="subtext3">
                    <LoadableText
                      height="10.5px"
                      loading={isRankedFeedLoading}
                      text={SME_SUBHEADER.ICEBREAKERS}
                    />
                  </Text>
                  <SecondaryText size="body1" $light>
                    <LoadableText
                      height="8px"
                      loading={isRankedFeedLoading}
                      text={SME_CAPTION.SELECTED(cartCount)}
                    />
                  </SecondaryText>
                </Flex>
              </EnhancedFlex>
              <FullWidthFlex vertical>
                {isRankedFeedLoading ? <Loading /> : renderItems}
                {hasMore && (
                  <LoadMoreButton
                    buttonText={SME_CTA.MORE_SUGGESTIONS}
                    onClick={loadMore}
                  />
                )}
              </FullWidthFlex>
            </PanelBlock>
          )}
        <PersonalizationAssistant displayName={displayName} />
      </PanelBlockContainer>
      {!isRankedFeedLoading && !isError && (
        <PanelBackgroundFlex className="lv-generate-emails-cta-container">
          <StartMyEmailCTA
            count={
              stage === Stage.cold && hasItems(rankedFeed)
                ? cartCount
                : undefined
            }
            isDisabled={
              (cartCount === 0 && !mainCustomContext) || isGongDataLoading
            }
            isToggleDisabled={cartCount === 0}
            onClick={onCTAClick}
            showValuePropGenerationToggle={
              stage === Stage.cold && hasItems(rankedFeed)
                ? isValuePropGenerationOn
                : false
            }
            text={SME_CTA.GENERATE_EMAILS}
            tooltipText={
              stage === Stage.cold && hasItems(rankedFeed)
                ? SME_TOOLTIP.SELECT_ICEBREAKERS
                : SME_TOOLTIP.ADD_CUSTOM_CONTEXT
            }
          />
        </PanelBackgroundFlex>
      )}
    </IceBreakersPanelFlex>
  );
};

const Loading = () => {
  return (
    <PanelListContainer>
      <PanelListItem>
        <CustomSkeleton variant={CustomSkeletonVariant.checkboxListItem} />
      </PanelListItem>
      <PanelListItem>
        <CustomSkeleton variant={CustomSkeletonVariant.checkboxListItem} />
      </PanelListItem>
      <PanelListItem>
        <CustomSkeleton variant={CustomSkeletonVariant.checkboxListItem} />
      </PanelListItem>
    </PanelListContainer>
  );
};
