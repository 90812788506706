import { useCallback, useMemo } from "react";

import {
  usePersonalization,
  useNews,
  useTechStacks,
  useGongData,
} from "~src/customHooks";
import { useAppSelector, useGetPodcastsQuery } from "~src/redux";

import { GlobalSearchItem } from "./types";
import {
  convertNewsToSearchItems,
  convertInvestmentsToSearchItems,
  convertTechStackToSearchItems,
  convertJobsToSearchItems,
  convertGongDataToSearchItems,
  convertPodcastsToSearchItems,
} from "./utils";

const processInChunks = (
  items: GlobalSearchItem[],
  searchTerm: string,
  callback: (results: GlobalSearchItem[]) => void
) => {
  let index = 0;
  const results: GlobalSearchItem[] = [];
  if (!searchTerm.trim()) return results;

  const processChunk = (deadline: IdleDeadline) => {
    while (index < items.length && deadline.timeRemaining() > 0) {
      const item = items[index];
      if (
        item.title.toLowerCase().includes(searchTerm) ||
        item.searchableTerms.some((term) =>
          term.toLowerCase().includes(searchTerm)
        )
      ) {
        results.push(item);
      }
      index++;
    }

    if (index < items.length) {
      requestIdleCallback(processChunk);
    } else {
      callback(results);
    }
  };

  requestIdleCallback(processChunk);
};

export const useGlobalSearch = (
  setResults: (arg: GlobalSearchItem[]) => void
) => {
  const {
    profile: { email },
    company: {
      investments,
      jobs: { jobs },
      logo,
    },
  } = usePersonalization().currentPersonalization;
  const { news } = useNews();
  const { feed } = news[email] || {};
  const { techStacks } = useTechStacks();
  const { techAggregate } = techStacks[email] || {};
  const gongCalls = useGongData();
  const { chromeId } = useAppSelector(({ config }) => config);
  const podcastQueryParams = useMemo(
    () => ({
      email,
      chromeId,
    }),
    [email, chromeId]
  );

  const { data: podcasts } = useGetPodcastsQuery(podcastQueryParams, {
    skip: !email,
  });

  const gongSearchItems = useMemo(
    () => convertGongDataToSearchItems(gongCalls),
    [gongCalls]
  );
  const podcastSearchItems = useMemo(
    () => convertPodcastsToSearchItems(podcasts || []),
    [podcasts]
  );
  const newsSearchItems = useMemo(
    () => convertNewsToSearchItems(feed || [], logo),
    [feed, logo]
  );
  const investmentSearchItems = useMemo(
    () => convertInvestmentsToSearchItems(investments),
    [investments]
  );
  const techStackSearchItems = useMemo(
    () => convertTechStackToSearchItems(techAggregate || []),
    [techAggregate]
  );
  const jobSearchItems = useMemo(
    () => convertJobsToSearchItems(jobs || []),
    [jobs]
  );

  const items = useMemo(
    () => [
      ...gongSearchItems,
      ...podcastSearchItems,
      ...newsSearchItems,
      ...investmentSearchItems,
      ...techStackSearchItems,
      ...jobSearchItems,
    ],
    [
      gongSearchItems,
      investmentSearchItems,
      jobSearchItems,
      newsSearchItems,
      podcastSearchItems,
      techStackSearchItems,
    ]
  );

  return useCallback(
    (searchTerm: string) => {
      if (!searchTerm) {
        setResults([]);
        return;
      }

      processInChunks(items, searchTerm.toLowerCase(), setResults);
    },
    [items, setResults]
  );
};
