import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { persistor, store } from "~src/redux/store";
import { mainPanelAction } from "~src/redux";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { LvIdentifierContext, AuthenticationContext } from "~src/customHooks";
import {
  isAuthenticated,
  createEmailId,
  startAuthenticatedSession,
} from "~src/utils";
import { ConfigProvider } from "antd";
import { theme } from "~src/theme";
import { CreatePanel } from "../MainPanel/CreatePanel";
import { LavenderButton } from "./LavenderButton";
import { MainPanelWrapper } from "./MainPanelWrapper";
import styled from "styled-components";

export const CreateLavenderAnywhere = () => {
  // INFO: this will create a div to hold the react app and styled to put it in the top right of the page
  const reactApp = document.createElement("div");
  reactApp.style.position = "fixed";
  reactApp.style.top = "0";
  reactApp.style.right = "0";
  reactApp.style.zIndex = "9999";
  reactApp.style.height = "100vh";
  reactApp.style.display = "flex";
  reactApp.style.transition = "1s ease-in-out width";
  reactApp.classList.add("lv-anywhere-root");

  const root = createRoot(reactApp);
  root.render(<RootComponent />);

  window.addEventListener("unload", () => {
    root.unmount();
  });

  // INFO: this will add the react app to the body of the page
  const bodyDom = document.querySelector("body");
  if (bodyDom) {
    bodyDom.prepend(reactApp);
  }
};

const RootComponent = () => {
  // INFO: setup the redux store for the main panel, and email data store
  const lvIdentifier = createEmailId();
  const userHasLogin = isAuthenticated();
  const state = store.getState();

  // INFO: setup main panel redux store
  store.dispatch(mainPanelAction.initialize(lvIdentifier));

  const composeContainerMetadata =
    state.config.settings?.selectors?.composeContainers || [];
  const composeContainerSelectors = composeContainerMetadata
    ?.map(({ container }) => container.containerSelector)
    .join(", ");
  const selectors = state.config.settings?.selectors;

  // INFO: we only want to render Lavender Anywhere once when page loads
  useEffect(() => {
    let foundlavenderAnywhereContainer = false;
    let foundlavenderAnywhereEmailContainer = false;
    // INFO: Create a new mutation observer since the dom elements are being added asynchronously after render
    const observer = new MutationObserver((mutationsList, observer) => {
      // Look through all mutations that just occured
      for (const mutation of mutationsList) {
        // If the addedNodes property has one or more nodes
        if (mutation.addedNodes.length) {
          const mainPanelContainer = document.querySelector(
            composeContainerSelectors
          );
          const emailContainer = document.querySelector(
            ".lv-anywhere-email-container"
          );

          // INFO: create main panel
          if (!foundlavenderAnywhereContainer && mainPanelContainer !== null) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            CreatePanel(
              mainPanelContainer as HTMLElement,
              composeContainerMetadata[0],
              lvIdentifier
            );
            foundlavenderAnywhereContainer = true;
          }

          // INFO: start listening to email body for changes
          if (
            !foundlavenderAnywhereEmailContainer &&
            emailContainer !== null &&
            selectors
          ) {
            void startAuthenticatedSession(
              lvIdentifier,
              emailContainer as HTMLElement,
              selectors,
              false,
              ""
            );
            foundlavenderAnywhereEmailContainer = true;
          }

          // INFO: when both the main panel and email body are found, disconnect the observer
          if (
            foundlavenderAnywhereContainer &&
            foundlavenderAnywhereEmailContainer
          ) {
            observer.disconnect();
            return;
          }
        }
      }
    });

    // Start observing the document with the configured parameters
    observer.observe(document.body, { childList: true, subtree: true });

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LvIdentifierContext.Provider value={lvIdentifier}>
          <AuthenticationContext.Provider value={isAuthenticated()}>
            <ConfigProvider theme={theme}>
              {userHasLogin && (
                <Container>
                  <LavenderButton />
                  <MainPanelWrapper />
                </Container>
              )}
            </ConfigProvider>
          </AuthenticationContext.Provider>
        </LvIdentifierContext.Provider>
      </PersistGate>
    </Provider>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
