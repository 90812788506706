import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { isFetchBaseQueryError, isLavenderAPIError } from "~src/typeGuards";

interface LogMessage {
  identifier: keyof typeof ErrorIdentifiers;
  message: string;
  stack: string | undefined;
  additionalData: Record<string, unknown>;
}

export const ErrorIdentifiers = Object.freeze({
  API_ERROR: "API_ERROR",
  SNACKBAR_API_ERROR: "SNACKBAR_API_ERROR",
  WS_JSON_PARSE_ERROR: "WS_JSON_PARSE_ERROR",
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
});

export const parseAndLogError = (
  error: unknown,
  identifier: keyof typeof ErrorIdentifiers,
  additionalData: Record<string, unknown> = {}
): LogMessage | null => {
  if (error instanceof Error) {
    return getLogMessage(
      identifier,
      error.message,
      error.stack,
      additionalData
    );
  } else if (isFetchBaseQueryError(error)) {
    return getLogMessage(
      identifier,
      messageFromBaseQueryError(error),
      "No stack trace",
      additionalData
    );
  }
  return null;
};

const getLogMessage = (
  identifier: keyof typeof ErrorIdentifiers,
  message: string,
  stack: string | undefined,
  additionalData: Record<string, unknown>
): LogMessage => {
  const logMessage: LogMessage = {
    identifier,
    message,
    stack,
    additionalData,
  };
  if (message !== "canceled") {
    console.error("Error Log:", JSON.stringify(logMessage, null, 2));
  }
  return logMessage;
};

const messageFromBaseQueryError = (error: FetchBaseQueryError): string => {
  const processErrorData = (data: unknown): string => {
    if (typeof data === "string") {
      return data;
    }
    if (typeof data === "object") {
      try {
        return JSON.stringify(data);
      } catch (error) {}
    }
    return "";
  };

  if (isLavenderAPIError(error.data)) {
    return error.data.message;
  }

  for (const key of ["data", "error"]) {
    if (key in error && error[key]) {
      const result = processErrorData(error[key]);
      if (result) {
        return result;
      }
    }
  }

  return "An unexpected error occurred";
};
