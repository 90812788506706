import { useCallback, useEffect, useRef, useState } from "react";
import { Flex, Form, message } from "antd";

import {
  useLvIdentifier,
  useStartMyEmail,
  useStartMyEmailGeneration,
  useValuePropGeneration,
} from "~src/customHooks";

import { StartMyEmailCTA } from "../../../StartMyEmail/StartMyEmailCTA/StartMyEmailCTA";

import { StartMyEmailPayload } from "~src/api/typings";
import { PanelAlert } from "~src/component/Atoms";
import {
  PersonalizationPanelContainer,
  ValuePropForm,
  ValuePropFormFieldType,
} from "~src/component/Organisms";
import { CustomField } from "~src/component/Organisms/Forms";
import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import { Instructions } from "~src/component/Organisms/StartMyEmail/Views";
import { EnhancedFlex, PanelBackgroundFlex } from "~src/component/Styled";
import {
  useStartMyEmailMutationContext,
  useStartMyEmailWebsocketContext,
} from "~src/contexts";
import { ErrorIdentifiers, parseAndLogError } from "~src/logger";
import {
  selectChromeId,
  useAppDispatch,
  useAppSelector,
  valuePropsActions,
} from "~src/redux";
import { EmailAnalysisStage as Stage } from "~src/redux/typings";
import { CART_ALERT, CART_HEADER, SME_CTA, SNACKBAR_TEXT } from "~src/strings";
import {
  getGenerateValuePropsPayload,
  getUpdatedStartMyEmailCart,
  segmentEvent,
  SegmentEventNames,
  SegmentEventLocations,
  getUpsertValuePropsPayload,
  validateAllFieldsHaveValue,
} from "~src/utils";

import { StyledStartMyEmailModalLayout } from "../../Shared/Styled";
import { Cart } from "../../Shared/Cart/Cart";
import { Header } from "./Header/Header";

export const CartModal = () => {
  const dispatch = useAppDispatch();
  const chromeId = useAppSelector(selectChromeId);
  const {
    data: { from, rankedFeedCount },
    next,
  } = useCarouselContext();
  const lvIdentifier = useLvIdentifier();
  const {
    currentPersonalization: {
      profile: { email },
      cart,
    },
    isWebsocketsOn,
  } = useStartMyEmail();
  const { isStartMyEmail2On } = useStartMyEmailGeneration();
  const { startMyEmailMutation } = useStartMyEmailMutationContext();
  const { resetLastStartMyEmailMessage } = useStartMyEmailWebsocketContext();

  const { isUserValuePropGenerationOn, updateValueProps } =
    useValuePropGeneration();

  const [form] = Form.useForm<ValuePropFormFieldType>();
  const categories = Form.useWatch("categories", form);
  const { custom: _, ...validatableCategories } = categories ?? {};
  const needsInput =
    categories !== undefined
      ? !validateAllFieldsHaveValue(validatableCategories)
      : true;
  const snackbarContainerRef = useRef<HTMLDivElement>(null);
  const [messageApi, contextHolder] = message.useMessage({
    getContainer: () => snackbarContainerRef.current as HTMLElement,
    top: 12,
    maxCount: 1,
  });
  const [isSaving, setIsSaving] = useState(false);

  const showSnackbar = useCallback(
    async (message: string, type: "success" | "error") => {
      await messageApi[type]({
        content: message,
        duration: 2,
        style: {
          border: "1px var(--color-neutral-purple-200)",
          borderRadius: 12,
          position: "relative",
        },
      });
    },
    [messageApi]
  );

  useEffect(() => {
    if (isUserValuePropGenerationOn && from === ModalType.Overlay) {
      void showSnackbar(SNACKBAR_TEXT.VALUE_PROPS_GENERATED, "success");
    }
  }, [from, isUserValuePropGenerationOn, messageApi, showSnackbar]);

  const submitForm = useCallback(
    async ({ categories }: ValuePropFormFieldType) => {
      const { custom, ...other } = categories;
      dispatch(
        valuePropsActions.setCurrentCustomValueProps({
          email,
          categories: {
            categories: {
              custom,
            },
          },
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { categories: touchedCategories } = form.getFieldsValue(
        true,
        (field) => !field.name.includes("custom") && field.touched
      );
      if (touchedCategories) {
        segmentEvent(
          SegmentEventNames.ContextAdded,
          SegmentEventLocations.StartMyEmail
        );
        setIsSaving(true);
        const errorMsg = await updateValueProps(
          getUpdatedStartMyEmailCart(cart!, other, isUserValuePropGenerationOn),
          getUpsertValuePropsPayload(
            cart!,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            touchedCategories,
            isUserValuePropGenerationOn
          ),
          email
        );
        if (errorMsg) {
          void showSnackbar(errorMsg, "error");
          setIsSaving(false);
          return;
        }

        await showSnackbar(SNACKBAR_TEXT.INFO_SAVED, "success");
      }

      try {
        const startMyEmailPayload: StartMyEmailPayload = {
          //TODO: code debt. Too many different types of "cart"
          cart: getGenerateValuePropsPayload(cart!, other),
          custom,
          chromeId,
          sessionId: lvIdentifier,
          stage: Stage.cold,
          stream: isWebsocketsOn,
          email,
          n: isStartMyEmail2On ? 2 : 1,
        };
        resetLastStartMyEmailMessage();
        void startMyEmailMutation(startMyEmailPayload);
        next(ModalType.GeneratingEmails, {
          rankedFeedCount,
          openedBy: email,
          phase: CART_HEADER.GENERATING,
        });
      } catch (e) {
        parseAndLogError(e, ErrorIdentifiers.API_ERROR, {
          request: "startMyEmail",
        });
      }
    },
    [
      cart,
      chromeId,
      dispatch,
      email,
      form,
      isStartMyEmail2On,
      isUserValuePropGenerationOn,
      isWebsocketsOn,
      lvIdentifier,
      next,
      rankedFeedCount,
      resetLastStartMyEmailMessage,
      showSnackbar,
      startMyEmailMutation,
      updateValueProps,
    ]
  );

  const onCartFormSubmit = useCallback(() => {
    form.submit();
    segmentEvent(
      SegmentEventNames.GenerateEmailsClicked,
      SegmentEventLocations.StartMyEmail
    );
  }, [form]);

  return (
    <>
      {contextHolder}
      <Header snackbarRef={snackbarContainerRef} />
      <StyledStartMyEmailModalLayout
        rootClassName="lv-cart-modal-box"
        isFullHeight
      >
        <Flex justify="space-between" vertical>
          <PersonalizationPanelContainer align="center" gap={12} vertical>
            {!isUserValuePropGenerationOn && (
              <PanelAlert message={CART_ALERT.ADDING_CONTEXT} type="info" />
            )}
            <ValuePropForm
              form={form}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              submitForm={submitForm}
            >
              <Flex vertical gap={8}>
                <Instructions isOptional>
                  <EnhancedFlex gap={12} vertical $isFullWidth>
                    <CustomField isDisabled={isSaving} />
                  </EnhancedFlex>
                </Instructions>
                <Cart hasFormItem />
              </Flex>
            </ValuePropForm>
          </PersonalizationPanelContainer>
        </Flex>
        <PanelBackgroundFlex className="lv-generate-emails-cta-container">
          <StartMyEmailCTA
            isDisabled={
              isSaving ||
              (rankedFeedCount > 0 ? needsInput : !categories?.custom?.main)
            }
            onClick={onCartFormSubmit}
            text={SME_CTA.GENERATE_EMAILS}
          />
        </PanelBackgroundFlex>
      </StyledStartMyEmailModalLayout>
    </>
  );
};
