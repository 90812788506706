import { Logo } from "@lavender-ai/lav-components";
import styled from "styled-components";

interface Props {
  size: number;
}

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LavenderLogo = ({ size }: Props) => {
  return (
    <LogoWrapper>
      <Logo height={size} variant="appIconRound" />
    </LogoWrapper>
  );
};
