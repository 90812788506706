import { useCallback } from "react";

import { Button } from "@lavender-ai/lav-components";

import { LavenderIcon } from "~src/component/typings";
import { BOOST_SCORE_BUTTON_CLASSNAME } from "~src/constants";
import { Recommendations } from "~src/redux/typings";
import { SUGGESTIONS } from "~src/strings";

const { FIX_IT_ALL } = SUGGESTIONS;

interface Props {
  content: string | undefined;
  isFullWidth?: boolean;
  recs: Recommendations;
  onClick: (text: string, content: string, recs: Recommendations) => void;
}

export const BoostScoreButton = ({
  content,
  isFullWidth = false,
  recs,
  onClick,
}: Props) => {
  const clickHandler = useCallback(() => {
    content && onClick(FIX_IT_ALL, content, recs);
  }, [content, onClick, recs]);

  return (
    <Button
      className={BOOST_SCORE_BUTTON_CLASSNAME}
      prefix={LavenderIcon.IconWand}
      prefixColor="lavender"
      onClick={clickHandler}
      fullWidth={isFullWidth}
    >
      {FIX_IT_ALL}
    </Button>
  );
};
