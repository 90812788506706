import { FC, useState } from "react";
import { Avatar, Icon } from "@lavender-ai/lav-components";
import { Flex } from "antd";
import { LavenderIcon } from "../../../../../typings";
import { styled } from "styled-components";
import {
  ModalType,
  useCarouselContext,
} from "../../../CarouselContextProvider";
import { GongCall } from "../../../../../../redux/typings";

const ComponentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 16px 20px 16px 12px;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  &:hover {
    cursor: pointer;
    border-radius: var(--radius-md, 8px);
    background: var(--lv-panel-background-color);
  }
`;

const CallTitle = styled.div`
  color: var(--color-neutral-purple-900, #18253d);
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.1px;
`;

const TeamName = styled.div`
  color: var(--color-neutral-purple-900, #18253d);
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.25px;
`;

const CallDate = styled.div`
  color: var(--color-neutral-purple-600, #6d727d);
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: -0.25px;
`;

const Arrow = styled(Icon)`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const CallDivider = styled.div`
  width: calc(100% - 32px);
  margin: 0 20px 0 12px;
  height: 1px;
  background: var(--color-neutral-purple-100, #e6e9f0);
`;

interface CallRowProps {
  key: number;
  call: GongCall;
}

interface Data {
  call: GongCall;
}

export const CallRow: FC<CallRowProps> = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const { next } = useCarouselContext();
  const { title, participantsText, callTimeFormatted, durationFormatted } =
    props.call;

  return (
    <>
      <ComponentContainer
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={() => {
          next(ModalType.GongCallPanel, { call: props.call } as Data);
        }}
      >
        <Avatar
          color="lavender"
          size="extra-small"
          type="icon"
          icon={
            <Icon
              color="lavender"
              size="extra-small"
              variant={LavenderIcon.IconPhone}
            />
          }
        />
        <Flex vertical={true}>
          <CallTitle>{title}</CallTitle>
          <TeamName>{participantsText}</TeamName>
          <CallDate>
            {callTimeFormatted} • {durationFormatted}
          </CallDate>
        </Flex>
        {isHovered && (
          <Arrow
            color="dark"
            size="mini"
            variant={LavenderIcon.IconChevronRight}
          />
        )}
      </ComponentContainer>
      {!isHovered && <CallDivider />}
    </>
  );
};
