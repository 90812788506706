import { useCallback } from "react";

import { StartMyEmailPayload } from "~src/api/typings";
import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import {
  useStartMyEmailMutationContext,
  useStartMyEmailWebsocketContext,
  useValuePropsMutationContext,
} from "~src/contexts";
import { parseAndLogError, ErrorIdentifiers } from "~src/logger";
import { selectChromeId, useAppSelector } from "~src/redux";
import {
  CustomCategories,
  EmailAnalysisStage as Stage,
} from "~src/redux/typings";
import { CART_HEADER } from "~src/strings";
import {
  getGenerateValuePropsPayload,
  needsRefreshValueProps,
} from "~src/utils";

import {
  useLvIdentifier,
  useStartMyEmail,
  useStartMyEmailGeneration,
  useValuePropGeneration,
} from "./";

/**
 * When routing to the "Value Props" view, this will
 * 1. Cancel any ongoing value prop generation
 * 2. If has icebreakers:
 * If in value prop generation mode:
 *   - Start the value prop generation if the feature flag and toggle is on
 *   - Navigate to the loading states view while the value prop analysis is ongoing
 *   - Navigate to the "Value Props" view on completion
 *  Else:
 *   - Navigate to the "Value Props" view immediately and display any available userValue props
 * 3. If no icebreakers: Navigate to the "Generating Emails" view directly
 */

export const useRouteToCartModal = () => {
  const { next } = useCarouselContext();
  const lvIdentifier = useLvIdentifier();
  const {
    currentPersonalization: {
      cart,
      profile: { email },
    },
    isWebsocketsOn,
  } = useStartMyEmail();
  const { isStartMyEmail2On } = useStartMyEmailGeneration();
  const { startMyEmailMutation } = useStartMyEmailMutationContext();
  const { resetLastStartMyEmailMessage } = useStartMyEmailWebsocketContext();
  const { currentGeneration, isUserValuePropGenerationOn } =
    useValuePropGeneration();
  const { generateValuePropsMutation } = useValuePropsMutationContext();

  const chromeId = useAppSelector(selectChromeId);

  const handleStartMyEmail = useCallback(
    (custom: CustomCategories["categories"]["custom"], stage: Stage) => {
      try {
        const startMyEmailPayload: StartMyEmailPayload = {
          cart: { sections: {} },
          custom,
          chromeId,
          sessionId: lvIdentifier,
          stage,
          stream: isWebsocketsOn,
          email,
          n: isStartMyEmail2On ? 2 : 1,
        };
        resetLastStartMyEmailMessage();
        void startMyEmailMutation(startMyEmailPayload);
        next(ModalType.GeneratingEmails, {
          rankedFeedCount: 0,
          openedBy: email,
          phase: CART_HEADER.GENERATING,
        });
      } catch (e) {
        parseAndLogError(e, ErrorIdentifiers.API_ERROR, {
          request: "startMyEmail",
        });
      }
    },
    [
      chromeId,
      email,
      isStartMyEmail2On,
      isWebsocketsOn,
      lvIdentifier,
      next,
      resetLastStartMyEmailMessage,
      startMyEmailMutation,
    ]
  );

  const handleRouteToCartModal = useCallback(
    (customValueProps?: CustomCategories, stage: Stage = Stage.cold) => {
      if (cart === undefined) {
        if (customValueProps?.categories?.custom?.main) {
          handleStartMyEmail(customValueProps.categories.custom, stage);
        }
        return;
      }

      const smeCart = getGenerateValuePropsPayload(cart);
      const rankedFeedCount = Object.keys(smeCart.sections).length;
      const needsRefresh = isUserValuePropGenerationOn
        ? needsRefreshValueProps(currentGeneration, smeCart)
        : false;

      if (needsRefresh) {
        try {
          generateValuePropsMutation(smeCart, email);
        } catch (e) {
          parseAndLogError(e, ErrorIdentifiers.API_ERROR, {
            request: "generateValueProps",
          });
          return;
          // TODO: next(ErrorModalType, { error: e })
        }
        next(ModalType.Overlay, {
          rankedFeedCount,
          openedBy: email,
          phase: CART_HEADER.VALUE_PROPS,
        });
      } else if (rankedFeedCount > 0) {
        next(ModalType.Cart, {
          rankedFeedCount,
          openedBy: email,
          phase: CART_HEADER.VALUE_PROPS,
        });
      }
    },
    [
      cart,
      isUserValuePropGenerationOn,
      currentGeneration,
      next,
      email,
      generateValuePropsMutation,
      handleStartMyEmail,
    ]
  );

  return { handleRouteToCartModal };
};
