import { URLS, networkRequest } from "~src/api";
import { store } from "~src/redux/store";
import { isDev } from "~src/utils";

import {
  SegmentEventDescriptions,
  SegmentEventLocations,
  SegmentEventNames,
} from "./constants";

export const segmentEvent = (
  name: SegmentEventNames,
  location: SegmentEventLocations,
  extraData?: Record<string, string | number | boolean>
) => {
  const { chromeId } = store.getState().config;
  if (isDev || !chromeId) {
    return;
  }

  void networkRequest.post(URLS.segmentEvent, {
    ...extraData,
    chromeId,
    description: SegmentEventDescriptions[name],
    location,
    name,
  });
};
