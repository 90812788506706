import { HubspotData, SalesforceData } from "~src/api/typings";

import { GongCall } from "./";

export interface ICRM {
  hasGongAccess: boolean;
  hasHubspotAccess: boolean;
  hasSalesforceAccess: boolean;
  gong_data: GongCall[];
  hubspot_data: HubspotData | undefined;
  salesforce_data: SalesforceData | undefined;
  loading: boolean;
}

export type RecipientCRMMap = Record<emailAddress, ICRM | undefined>;

export interface CRMData {
  toAddresses: emailAddress[];
  crmData: RecipientCRMMap;
}
