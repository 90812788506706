import { useMemo } from "react";
import { Empty, Flex } from "antd";
import { styled } from "styled-components";

import { Text } from "@lavender-ai/lav-components";

import { LoadMoreButton } from "~src/component/Atoms";
import { NoDataDisplay, SearchInput } from "~src/component/Molecules";
import { LavenderIcon } from "~src/component/typings";
import { useLoadMore, usePersonalization, useSearch } from "~src/customHooks";
import {
  PanelBlock,
  PanelBlockVariant,
  PanelBlockHeader,
} from "~src/layout/MainPanel/PanelBlock";
import { BUTTON_TEXT, PERSONALIZATION_HEADER } from "~src/strings";

import { makeCountConfig } from "./helpers";
import { InvestmentList } from "./InvestmentList";

const { FUNDING } = PERSONALIZATION_HEADER;

const StyledFlex = styled(Flex)`
  div {
    width: 100%;
  }
`;

export const Funding = (): JSX.Element => {
  const {
    currentPersonalization: {
      company: { investments },
      profile: { loading },
    },
  } = usePersonalization();
  const empty = investments.length === 0;

  // Add series and amount to investors object, as it's the only path that search filtering is ran on
  const investmentsWithFieldsMappedForSearch = useMemo(
    () =>
      investments.map((investment) => ({
        ...investment,
        investors:
          investment.investors?.map((investor) => ({
            ...investor,
            series: investment.series,
            valueAsString: investment.amount.value + "",
          })) ?? null,
      })),
    [investments]
  );

  const { searchTerm, filteredItems, handleSearch } = useSearch(
    investmentsWithFieldsMappedForSearch,
    ["name", "series", "valueAsString"],
    "investors"
  );
  const { currentItems, loadMore, loadMoreCount, hasMore } =
    useLoadMore(filteredItems);
  const countConfig = makeCountConfig(filteredItems.length);

  return empty && !loading ? (
    <NoDataDisplay mainText="We couldn't find any Funding." />
  ) : (
    <PanelBlock loading={loading} variant={PanelBlockVariant.list}>
      <PanelBlockHeader
        headerSize="subtext3"
        headerText={FUNDING}
        loading={loading}
      />
      <Flex vertical gap="middle">
        <SearchInput
          countConfig={countConfig}
          handleSearch={handleSearch}
          loading={loading}
          placeholder="Search funding..."
          searchTerm={searchTerm}
        />
      </Flex>
      {!loading && filteredItems.length === 0 && (
        <div style={{ width: "100%", padding: "16px 0" }}>
          <Flex justify="center">
            <Empty
              description={
                <Text
                  size="body1"
                  style={{
                    color: "var(--ant-color-text-quaternary)",
                    margin: "0 auto",
                  }}
                >
                  No results
                </Text>
              }
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          </Flex>
        </div>
      )}
      <div
        aria-labelledby={FUNDING}
        className="lv-panel-list-container"
        role="list"
      >
        <InvestmentList investments={currentItems} isLoading={loading} />
      </div>
      {hasMore && !loading && (
        <StyledFlex className="lv-panel-list-load-more-button-container">
          <LoadMoreButton
            buttonText={BUTTON_TEXT.SEE_MORE(loadMoreCount)}
            onClick={loadMore}
            prefix={LavenderIcon.IconPlus}
          />
        </StyledFlex>
      )}
    </PanelBlock>
  );
};
