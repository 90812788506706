import { useState } from "react";
import { Divider, Flex, Form } from "antd";
import { styled } from "styled-components";

import { TextArea, Button, Text, Icon } from "@lavender-ai/lav-components";

import { usePersonalization } from "~src/customHooks";

import { setNotes } from "~src/api";
import { SingleNote } from "~src/api/typings";
import { PERSONALIZATION_CTA, PERSONALIZATION_HEADER } from "~src/strings";
import { useAppSelector } from "~src/redux/hooks";
import { handleTextAreaEnterKeyDown } from "~src/utils";

import { NavigationWrapper } from "../Views/NavigationWrapper";
import { LavenderIcon } from "../../../typings";

const { POST } = PERSONALIZATION_CTA;
const { NOTES, ALL_NOTES } = PERSONALIZATION_HEADER;

const NotePanel = styled(Flex)`
  background: var(--color-base-White);
  border-radius: 16px;
  padding-bottom: 8px;
  margin: 0 20px 16px;
`;

const NoteHeader = styled(Flex)`
  width: 100%;
  padding: 20px 16px;
  border-bottom: 1px solid var(--lv-panel-background-color);
`;

const NoteListHeader = styled(Flex)`
  width: 100%;
  padding: 20px 16px;
`;

const SubmitButtonContainer = styled(Flex)`
  padding: 8px 8px 0 8px;
`;

const NumberCircle = styled(Flex)<{ num: number }>`
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.num >= 10 ? "1.3rem" : "1rem")};
  height: ${(props) => (props.num >= 10 ? "1.3rem" : "1rem")};
  border-radius: 50%;
  border: 1px solid grey;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const NoteContainer = styled(Flex)`
  padding: 8px 12px 0;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--color-base-White);
  transition: background-color 0.3s ease;
  margin: 0 8px;

  &:hover {
    border: 1px solid var(--color-neutral-purple-100, #e9ebf2);
    background: var(--lv-panel-background-color);
    > ${StyledDivider} {
      border-color: var(--lv-panel-background-color);
    }
  }
`;

const NoNoteFoundBox = styled(Flex)`
  margin: 14px;
  padding: 10px;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--color-neutral-purple-100, #e9ebf2);
  background: var(--lv-panel-background-color);
`;

const CircleforIcon = styled(Flex)<{ diameter: number }>`
  align-items: center;
  justify-content: center;
  width: ${(props) => props.diameter + "rem"};
  height: ${(props) => props.diameter + "rem"};
  border-radius: 50%;
  background: gainsboro;
  margin-right: 6px;
`;

const NoteText = styled(Flex)`
  margin: 16px 0;
  text-align: left;
`;

const AddedNoteWrapper = styled(Flex)`
  position: sticky;
  justify-content: center;
  align-items: center;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 8px;
  padding: 12px;
  align-items: center;
  width: 175px;
  border-radius: var(--radius-md-lrg, 12px);
  border: 1px solid var(--color-neutral-purple-200, #e1e0e4);
  background: var(--color-base-White, #fff);
  box-shadow:
    0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;

const timeAgo = (pastTimeIso: string) => {
  const currentDate: Date = new Date();
  const pastDate: Date = new Date(pastTimeIso);

  const differenceInMs: number = currentDate.getTime() - pastDate.getTime();

  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  const differenceInHours = Math.floor(
    (differenceInMs / (1000 * 60 * 60)) % 24
  );
  const differenceInMinutes = Math.floor((differenceInMs / (1000 * 60)) % 60);

  let result = `${differenceInDays} days, ${differenceInHours} hours`;

  if (
    differenceInDays === 0 &&
    differenceInHours === 0 &&
    differenceInMinutes < 1
  ) {
    result = `Just Now`;
  } else if (differenceInDays === 0 && differenceInHours === 0) {
    result = `${differenceInMinutes} minutes ago`;
  } else if (differenceInDays === 0) {
    result = `${differenceInHours} hours, ${differenceInMinutes} minutes ago`;
  }

  return result;
};

const NumberInCircle = ({ num }: { num: number }) => (
  <NumberCircle num={num}>
    <Text size={"subtext1"}>{num}</Text>
  </NumberCircle>
);

const Note = ({
  note,
  deleteNote,
  index,
  length,
}: {
  note: SingleNote;
  deleteNote;
  index: number;
  length: number;
}) => (
  <NoteContainer vertical>
    <Flex justify="space-between" align="center">
      <Text size={"body1"} color={"dark"}>
        Posted {timeAgo(note.Created)}
      </Text>
      <Button
        icon="IconTrash"
        size={"small"}
        onClick={() => {
          deleteNote(note);
        }}
      />
    </Flex>

    <NoteText>
      <Text size={"body2"} color={"black"}>
        {note.Text}
      </Text>
    </NoteText>
    {index + 1 < length && <StyledDivider />}
  </NoteContainer>
);

export const NotesModal = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const {
    currentPersonalization,
    currentPersonalization: {
      profile: { name, email },
      notes: personalizationNotes,
    },
    setPersonalizationContext,
  } = usePersonalization();
  // const { profile } = currentPersonalization;
  const [notes, setNotesState] = useState<SingleNote[]>(
    personalizationNotes ?? []
  );

  const [showDiv, setShowDiv] = useState<boolean>(false);
  const [popupText, setPopupText] = useState<string>("");

  const [form] = Form.useForm();
  const chromeId = useAppSelector((state) => state.config.chromeId);

  const addNote = () => {
    const currentDate = new Date();
    let newNotes = notes;
    newNotes = [
      { Created: currentDate.toISOString(), Text: inputValue },
      ...notes,
    ];
    setNotesState(newNotes);
    setInputValue("");
    setNotes(email, chromeId, newNotes);
    const updatedPersonalization = {
      ...currentPersonalization,
      note: newNotes,
    };
    setPersonalizationContext(updatedPersonalization);
    setPopupText("Note Added");
    setShowDiv(true);
    setTimeout(() => {
      setShowDiv(false);
    }, 3000);
  };

  const deleteNote = (note) => {
    const newNotes = notes.filter((n) => n.Created !== note.Created);
    setNotesState(newNotes);
    setInputValue("");
    setNotes(email, chromeId, newNotes);
    const updatedPersonalization = {
      ...currentPersonalization,
      note: newNotes,
    };
    setPersonalizationContext(updatedPersonalization);
    setPopupText("Note Deleted");
    setShowDiv(true);
    setTimeout(() => {
      setShowDiv(false);
    }, 3000);
  };

  const notePopup = (
    <AddedNoteWrapper>
      <Icon
        color="positive"
        variant={LavenderIcon.IconCircleCheckFilled}
        size="small"
        style={{ marginRight: "8px" }}
      />
      {popupText}
    </AddedNoteWrapper>
  );

  return (
    <NavigationWrapper>
      <br />
      <div>
        <NotePanel vertical>
          <NoteHeader
            className="lav-cta"
            justify="space-between"
            align="center"
          >
            <Text size="subtext3">{NOTES}</Text>
          </NoteHeader>
          <Form autoComplete="off" form={form} style={{ width: "100%" }}>
            <Form.Item name="note">
              <TextArea
                onPressEnter={(e) => {
                  handleTextAreaEnterKeyDown(e, form.submit);
                }}
                autoSize={{ minRows: 6, maxRows: 6 }}
                placeholder={`Write a note about ${name}...`}
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                style={{
                  minHeight: "184px",
                  padding: "14px 4px 12px 12px",
                }}
                variant="plain"
              />
            </Form.Item>
            <Form.Item noStyle label={POST}>
              <SubmitButtonContainer justify="flex-end">
                <Button
                  state={
                    !(form.getFieldValue("note") as string)?.length
                      ? "disabled"
                      : "initial"
                  }
                  htmlType="submit"
                  variant="primary"
                  onClick={() => {
                    addNote();
                  }}
                >
                  {POST}
                </Button>
              </SubmitButtonContainer>
            </Form.Item>
          </Form>
        </NotePanel>
        <NotePanel vertical>
          <NoteListHeader
            className="lav-cta"
            justify="flex-start"
            align="center"
          >
            <Text size="subtext3">{ALL_NOTES}</Text>
            &nbsp;&nbsp;
            <NumberInCircle num={notes?.length || 0}></NumberInCircle>
          </NoteListHeader>
          {notes?.length > 0 ? (
            notes?.map((note, index) => (
              <div key={index}>
                <Note
                  note={note}
                  deleteNote={deleteNote}
                  index={index}
                  length={notes.length}
                />
              </div>
            ))
          ) : (
            <NoNoteFoundBox align="center">
              <CircleforIcon diameter={2}>
                <Icon variant="IconNotesOff" color="dark" />
              </CircleforIcon>
              <Text size="subtext2">You haven’t added any notes yet.</Text>
            </NoNoteFoundBox>
          )}
          {showDiv && notePopup}
        </NotePanel>
      </div>
    </NavigationWrapper>
  );
};
