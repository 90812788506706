import { styled } from "styled-components";

import { useStreakInfo } from "./useStreakInfo";
import { Confetti } from "./Confetti";

export const StreakBadge = () => {
  const { streakBadge, allWorkweekDaysTrue } = useStreakInfo();

  return (
    <Container>
      <img src={streakBadge} alt="Streak Badge" width={191} height={217} />
      {allWorkweekDaysTrue && <Confetti />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin: 48px auto 8px;
  width: 189px;
  height: 189px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  #tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
`;
