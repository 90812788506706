import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FrameworkContext } from "./FrameworkContext";
import { CarouselRef } from "antd/lib/carousel";
import { ListFrameworks } from "./ListFrameworks";
import { SelectedFramework } from "./SelectedFramework";
import { SlideContainer, SlideChild } from "~src/component/Atoms";
import { emailAction, useAppDispatch } from "~src/redux";
import { useLvIdentifier } from "~src/customHooks";

export const FrameworksPanel = () => {
  const dispatch = useAppDispatch();
  const lvIdentifier = useLvIdentifier();
  const carouselRef = useRef<CarouselRef>(null);
  const [frameworkCategory, setFrameworkCategory] = useState<unknown>({});
  const [view, setView] = useState("home");

  useEffect(() => {
    dispatch(emailAction.tagFrameworksUsed({ id: lvIdentifier }));
  }, [dispatch, lvIdentifier]);

  const next = useCallback((f: any) => {
    setFrameworkCategory(f);
    setView("selected");
  }, []);
  const prev = useCallback(() => {
    carouselRef?.current?.prev();
    setView("home");
  }, []);
  const carouselContextValue = useMemo(
    () => ({ next, prev, frameworkCategory }),
    [next, prev, frameworkCategory]
  );
  return (
    <FrameworkContext.Provider value={carouselContextValue}>
      <SlideContainer>
        <SlideChild stage={view === "home" ? "active" : "previous"} noSlide>
          <ListFrameworks />
        </SlideChild>
        <SlideChild stage={view === "selected" ? "active" : "next"}>
          <SelectedFramework />
        </SlideChild>
      </SlideContainer>
    </FrameworkContext.Provider>
  );
};
