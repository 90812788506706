export const CLEARBIT_LOGO_URL = "https://logo.clearbit.com/";

//*URLs we want to ignore - clean up backend and replace these when possible

// ignore if social media url is exact match
export const FB_BASE_URL = "https://facebook.com";
export const TWITTER_BASE_URL = "https://twitter.com";

const LINKEDIN_IMG_DOMAIN = "licdn.com";
// static defaults - but not ours
export const DEFAULT_APOLLO_AVATAR_URL = `https://static.${LINKEDIN_IMG_DOMAIN}`;
export const DEFAULT_APOLLO_LOGO_URL = `https://static-exp1.${LINKEDIN_IMG_DOMAIN}`;
// paywalled, but only sometimes - replace when possible
const _LINKEDIN_IMG_URL = `https://media.${LINKEDIN_IMG_DOMAIN}`;
// bad data, legacy, bad url - remove
export const DIFFBOT_IMG_DOMAIN = "kg.diffbot.com";
