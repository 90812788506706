import { Flex } from "antd";
import styled from "styled-components";

export const CategorySection = styled(Flex)`
  .lv-card {
    border-radius: 16px;
    border: 0;
    .ant-card-body {
      padding: 0;
    }
  }

  .lv-icon-frame {
    margin-right: 12px;
    position: relative;
  }

  &.category-danger {
    .lv-card {
      border: 1px solid var(--color-tart-200);
      box-shadow: 0px 0px 0px 3px var(--color-tart-50);
    }
    .lv-icon-frame {
      background-color: var(--color-tart-50) !important;
    }
  }

  position: relative;

  .lv-fix-all-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;
