import { css } from "styled-components";

export const BORDER_BOTTOM_RIGHT_MIXIN = css`
  border-bottom: none;
  border-radius: var(--radius-md, 8px) var(--radius-md, 8px)
    var(--radius-lrg, 16px) var(--radius-md, 8px);
`;

export const BORDER_BOTTOM_LEFT_MIXIN = css`
  border-bottom: none;
  border-radius: var(--radius-md, 8px) var(--radius-md, 8px)
    var(--radius-md, 8px) var(--radius-lrg, 16px);
`;

export const BORDER_TOP_LEFT_MIXIN = css`
  border-radius: var(--radius-lrg, 16px) var(--radius-md, 8px)
    var(--radius-md, 8px) var(--radius-md, 8px);
`;

export const BORDER_TOP_RIGHT_MIXIN = css`
  border-radius: var(--radius-md, 8px) var(--radius-lrg, 16px)
    var(--radius-md, 8px) var(--radius-md, 8px);
`;

export const CENTERED_SVG_MIXIN = css`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
`;

export const LINE_CLAMP_MIXIN = (lines = 1) => css`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  overflow: hidden;
`;

export const PANEL_HIGHLIGHT_MIXIN = css`
  border: 1px solid var(--color-lavender-200);
  box-shadow: 0px 0px 0px 3px var(--color-lavender-50);
`;

export const SECONDARY_ICON_MIXIN = css`
  stroke: var(--color-neutral-blue-600);
`;

export const VISUALLY_HIDDEN_MIXIN = css`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
