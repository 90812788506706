import { styled, keyframes } from "styled-components";
import { IconButton } from "@lavender-ai/lav-components";
import { useCallback, useEffect, useState } from "react";

import { useAppSelector, selectFeatures } from "~src/redux";
import { useLvIdentifier } from "~src/customHooks";

import { useShareToLinkedInCB } from "./useShareToLinkedinCB";

export const ShareButton = () => {
  const lvIdentifier = useLvIdentifier();
  const shareToLinkedInCB = useShareToLinkedInCB();
  const { show_linkedin_share_button: showLinkedinShareButtonFF } =
    useAppSelector(selectFeatures);
  const score = useAppSelector(
    ({ emailAnalysis }) => emailAnalysis[lvIdentifier].analysis.dynamic_ceq
  );

  const [buttonState, setButtonState] = useState<
    "initial" | "loading" | "disabled"
  >("initial");

  // Allow generating new image every time score changes
  useEffect(() => {
    setButtonState("initial");
  }, [score]);

  const onClick = useCallback(() => {
    if (buttonState === "initial") {
      setButtonState("loading");
      void shareToLinkedInCB().then(() => {
        setButtonState("disabled");
      });
    }
  }, [buttonState, shareToLinkedInCB]);

  return (
    showLinkedinShareButtonFF && (
      <StyledShareButton
        icon="IconShare2"
        onClick={onClick}
        state={buttonState}
        {...(buttonState !== "initial" && { style: { animation: "unset" } })}
      />
    )
  );
};

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(138, 43, 226, 0.5);
  }
  50% {
    box-shadow: 0 0 0 5px rgba(138, 43, 226, 0.5);
    }
    100% {
      box-shadow: 0 0 0 0px rgba(138, 43, 226, 0.5);
    }
`;

const StyledShareButton = styled(IconButton)`
  box-shadow: 0px 0px 0px 0px var(--color-lavender-100, #dbc5f6);
  border: 1px solid var(--color-lavender-300, #bb96ef);
  animation: ${pulse} 4s infinite;
`;
