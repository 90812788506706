import { Divider, Skeleton, Space } from "antd";

import { Text } from "@lavender-ai/lav-components";

import { usePersonalization } from "~src/customHooks";
import { IconAndText } from "~src/component/Atoms";
import { Socials } from "~src/component/Molecules";

import { StyledColumn, StyledSummaryInfoBlock } from "../../Shared/Styled";
import { PERSONALIZATION_PANEL } from "~src/strings";
import { getTotalInvestmentsString } from "~src/utils";
import { TitleCaseTag } from "~src/component/Styled";
import { LavenderIcon } from "~src/component/typings";

const { NOT_FOUND } = PERSONALIZATION_PANEL;

export const CompanySummaryBlock = () => {
  const {
    currentPersonalization: {
      company: {
        bio,
        name: companyName,
        employeesRange,
        location,
        raised,
        social_medias,
        industries,
        investments,
      },
      profile: { loading },
    },
  } = usePersonalization();

  const investmentStr = getTotalInvestmentsString(investments, raised);

  return (
    <>
      <StyledColumn
        align="flex-start"
        gap="middle"
        justify="center"
        style={{ padding: "0px 16px 0 16px" }}
        vertical
      >
        <Skeleton active loading={loading} paragraph={{ rows: 0 }}>
          <Text size={"subtext4"} style={{ padding: "20px 0 4px 0" }}>
            About {companyName}
          </Text>
        </Skeleton>
        <Socials {...social_medias} loading={loading} />
        <StyledSummaryInfoBlock>
          <Skeleton active={true} loading={loading} paragraph={{ rows: 3 }}>
            <IconAndText
              icon={LavenderIcon.IconMapPin}
              text={location || NOT_FOUND}
            />
            <IconAndText
              icon={LavenderIcon.IconUsersGroup}
              text={employeesRange || NOT_FOUND}
            />
            <IconAndText
              icon={LavenderIcon.IconReportMoney}
              text={investmentStr || NOT_FOUND}
            />
          </Skeleton>
        </StyledSummaryInfoBlock>
        <Space direction="vertical">
          {industries.map((industry, idx) => (
            <TitleCaseTag key={idx} size="small">
              {industry}
            </TitleCaseTag>
          ))}
        </Space>
      </StyledColumn>
      <div style={{ padding: "0px 16px 24px 16px", width: "100%" }}>
        <Divider style={{ margin: "16px 0" }} />
        <StyledColumn align="flex-start" gap="middle" justify="center" vertical>
          <Text>{bio}</Text>
        </StyledColumn>
      </div>
    </>
  );
};
