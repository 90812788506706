const imageSrc = {
  toolbar: {
    profile: `${process.env.BACKEND_API}/public/img/icons/profile-lookup-icon.svg`,
    analyze: `${process.env.BACKEND_API}/public/img/icons/email%20coach.svg`,
    mobile: `${process.env.SSR_URL}/_next/static/media/icon.70570a5b.svg`,
    gif: `${process.env.BACKEND_API}/public/img/icons/gifs-icon.svg`,
    generateEmail: `${process.env.BACKEND_API}/public/img/icons/bullet-points-icon.svg`,
    close: `${process.env.BACKEND_API}/public/img/icons/bullet-points-icon.svg`,
    leftArrow: `${process.env.BACKEND_API}/public/img/icons/leftArrow.svg`,
    rightArrow: `${process.env.BACKEND_API}/public/img/icons/rightArrow.svg`,
    dashboard: `${process.env.BACKEND_API}/public/img/icons/dashboard.svg`,
    account: `${process.env.BACKEND_API}/public/img/icons/account.svg`,
  },
  account: `${process.env.BACKEND_API}/public/img/poc/Account.png`,
  alwaysOnBackground: `${process.env.BACKEND_API}/public/img/poc/alwaysOnBackground.png`,
  chatGPT: `${process.env.BACKEND_API}/public/img/poc/ChatGPT.png`,
  emailCoach: `${process.env.BACKEND_API}/public/img/poc/EmailCoach.png`,
  emailGenerator: `${process.env.BACKEND_API}/public/img/poc/EmailGenerator.png`,
  imageFinder: `${process.env.BACKEND_API}/public/img/poc/ImageFinder.png`,
  mobilePreview: `${process.env.BACKEND_API}/public/img/poc/MobilePreview.png`,
  profile: `${process.env.BACKEND_API}/public/img/poc/Profile.png`,
  mainPanel: "https://sorter.s3.amazonaws.com/Info-sidebar.png",
  menu: "https://sorter.s3.amazonaws.com/nav-top.png",
};

// INFO : string message constants, importing existing logic from the extension
const CRMUserFound = "CRM user found";
const CRMUserNotUpgrade = "No team accounts with crm connected";
const UserNewMailBox = "New mailbox detected";
const UserFound = "User found for mailbox";

// INFO: minimal length of email body to be considered valid for email analysis and summary
const MIN_EMAIL_BODY_WORD_COUNT = 15;
const EmailBodyError = "Email body is too short";

// INFO: constants to match backend values
const LAVENDER_ANYWHERE = "lavenderAnywhere";
const DEFAULT_CONTAINER_CLASS = "lv-comp-container";
const LAVENDER_ANYWHERE_URL = `${process.env.BACKEND_API}/website-demo`;
const OUTLOOK_NATIVE = "outlookNative";

// INFO: mobile and watch device sizes to control the position of the device
// we use these values several places in the code, so it's better to have them in one place
const MOBILE_DEVICE_WIDTH = 290;
const MOBILE_DEVICE_HEIGHT = 605;
const WATCH_DEVICE_WIDTH = 200;
const WATCH_DEVICE_HEIGHT = 339;
const MOBILE_DEVICE = "mobile";
const WATCH_DEVICE = "watch";

export {
  imageSrc,
  CRMUserFound,
  CRMUserNotUpgrade,
  UserNewMailBox,
  UserFound,
  MIN_EMAIL_BODY_WORD_COUNT,
  EmailBodyError,
  LAVENDER_ANYWHERE,
  DEFAULT_CONTAINER_CLASS,
  LAVENDER_ANYWHERE_URL,
  OUTLOOK_NATIVE,
  MOBILE_DEVICE_WIDTH,
  MOBILE_DEVICE_HEIGHT,
  WATCH_DEVICE_WIDTH,
  WATCH_DEVICE_HEIGHT,
  MOBILE_DEVICE,
  WATCH_DEVICE,
};
