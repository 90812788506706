import { FC } from "react";
import styled from "styled-components";
import { Flex } from "antd";
import { useAppSelector } from "../../../redux/hooks";
import { SettingsWrapper } from "./SettingsWrapper";
import { BILLING_PLANS } from "~src/constants";
import {
  AddedPaymentMethod,
  BillingDescription,
  BillingHistory,
  PaymentMethod,
  PlanInfo,
  StripeIframe,
  TrialDaysRemaining,
  UpgradePayment,
} from "~src/component/Organisms/Billing/";

const PlanWrapper = styled.div`
  box-sizing: content-box;
  background: #ffffff;
  padding: 16px 16px 20px;
  margin: 12px 20px;
  border-radius: var(--radius-lg);
  color: var(--color-neutral-purple-900, #140c22);
  font-family: "Bricolage Grotesque";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 150% */
  letter-spacing: -0.1px;
`;

const CurrentPlanTitle = styled.div`
  color: var(--color-lavender-400, #957ee0);
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: -0.25px;
`;

export const BillingPanel: FC = () => {
  const user = useAppSelector((state) => state.userAccount.settings.user);
  const hasCard = user?.has_stripe;

  return (
    <SettingsWrapper name="Billing">
      <PlanWrapper>
        <AddedPaymentMethod />
        <Flex>
          <CurrentPlanTitle>Current Plan</CurrentPlanTitle>
        </Flex>
        <Flex justify="space-between" style={{ paddingTop: "12px" }}>
          <PlanInfo />
        </Flex>
        <Flex>
          {user?.billing_plan === BILLING_PLANS.Trial && <TrialDaysRemaining />}
        </Flex>
        <Flex>
          <BillingDescription />
        </Flex>
        {user?.billing_plan !== BILLING_PLANS.Teams && (
          <Flex>{hasCard ? <UpgradePayment /> : <StripeIframe />}</Flex>
        )}
      </PlanWrapper>
      <PaymentMethod />
      <BillingHistory />
    </SettingsWrapper>
  );
};
