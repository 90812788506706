import { FC } from "react";

import { FRONTEND_URLS } from "~src/constants";
import { useUpgradePlanModalContext } from "~src/contexts";
import { selectChromeId } from "~src/redux";
import { useAppSelector } from "~src/redux/hooks";

export const StripeIframe: FC = () => {
  const chromeId = useAppSelector(selectChromeId);
  const { selectedPlan } = useUpgradePlanModalContext();

  return (
    <iframe
      src={`${FRONTEND_URLS.dashboard}/extension-stripe?chromeId=${chromeId}&selectedPlan=${JSON.stringify(selectedPlan)}`}
      style={{
        width: "100%",
        height: "100%",
        border: "none",
      }}
      referrerPolicy="no-referrer"
    />
  );
};
