import { createAppSelector } from "../selectors";
import { RootState } from "../store";

const selectValueProps = (state: RootState) => state.valueProps.valueProps;
const selectUserValueProps = (state: RootState) =>
  state.valueProps.userValueProps;
const selectRecipient = (_state: RootState, email: emailAddress) => email;

export const selectValuePropsGenerationByRecipient = createAppSelector(
  [selectValueProps, selectRecipient],
  (valueProps, recipient) => {
    return valueProps !== undefined ? valueProps[recipient]?.generated : null;
  }
);
export const selectUserValuePropsGenerationByRecipient = createAppSelector(
  [selectUserValueProps, selectRecipient],
  (userValueProps, recipient) => {
    return userValueProps !== undefined
      ? userValueProps[recipient]?.generated
      : null;
  }
);
export const selectValuePropsStatusByRecipient = createAppSelector(
  [selectValueProps, selectRecipient],
  (valueProps, recipient) => {
    return valueProps[recipient]?.status;
  }
);
