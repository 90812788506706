import { styled } from "styled-components";
import { useMemo } from "react";
import { Flex } from "antd";
import { Icon, Text } from "@lavender-ai/lav-components";

import { useLvIdentifier } from "~src/customHooks";
import {
  selectEmailAnalysis,
  selectEmailData,
  useAppSelector,
} from "~src/redux";
import { useScoreColorGroup } from "../utils";

export const ScoreDropdownContent = () => {
  const lvIdentifier = useLvIdentifier();
  const { isSuccess } = useScoreColorGroup();
  const { scoringModelOverwrite, stageOverwrite } = useAppSelector(
    selectEmailData(lvIdentifier)
  );
  const { stage, scoringModel } = useAppSelector(
    selectEmailAnalysis(lvIdentifier)
  );

  const DropdownText = useMemo(() => {
    return `${(stageOverwrite ?? stage).replaceAll("_", " ")} & ${scoringModelOverwrite || scoringModel}`;
  }, [stage, scoringModel, stageOverwrite, scoringModelOverwrite]);

  return (
    <StyledDropdownContainer $dark={isSuccess}>
      <StyledScoreText size="subtext1" color={isSuccess ? "white" : "dark"}>
        {DropdownText}
      </StyledScoreText>
      <Icon
        color={isSuccess ? "white" : "dark"}
        variant="IconChevronDown"
        size="mini"
      />
    </StyledDropdownContainer>
  );
};

const StyledDropdownContainer = styled.div<{ $dark: boolean }>`
  display: flex;
  width: 148px;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  margin-right: 0;
  justify-content: space-between;
  height: 24px;
  padding: var(--size-6, 6px) var(--size-6, 6px) var(--size-6, 6px) 4px;
  align-items: center;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  ${({ $dark }) =>
    $dark
      ? `background: rgba(255, 255, 255, 0.1);`
      : `border: 1px solid var(--color-neutral-purple-200, #E1E0E4);`}
`;

const StyledScoreText = styled(Text)`
  padding: 0;
  width: 113px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  text-transform: capitalize;
`;
