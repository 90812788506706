import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { getAISuggestion } from "../../../../api/aiSuggestion";
import { AISuggestion, Recommendation } from "../../../../redux/typings";
import { selectedRecommendationAction } from "../../../../redux";
import { store } from "~src/redux/store";
import { getPromptName } from "~src/utils";

interface HighlightContextType {
  selected: Recommendation | undefined;
  suggestion: AISuggestion | undefined;
  setHighlighted: (
    recommendation: Recommendation | undefined,
    chromeId?: string,
    lvIdentifier?: string
  ) => void;
  regenerate: (recommendation: Recommendation, chromeId: string) => void;
}

const HighlightContext = createContext<HighlightContextType>({
  selected: undefined,
  suggestion: undefined,
  setHighlighted: () => {},
  regenerate: () => {},
});

export const HighlightContextProvider: FC<PropsWithChildren> = (props) => {
  const clearHighlighted = () => {
    setState({
      ...state,
      selected: undefined,
      suggestion: undefined,
    });
    store.dispatch(
      selectedRecommendationAction.setSelectedRecommendation({
        // @ts-expect-error - needs fix
        lvIdentifier: undefined,
        // @ts-expect-error - needs fix
        selectedRecommendation: undefined,
      })
    );
  };

  const regenerate = async (
    recommendation: Recommendation,
    chromeId: string
  ) => {
    const promptActual = getPromptName(recommendation);
    setState({
      ...state,
      selected: recommendation,
      suggestion: undefined,
    });
    const response = await getAISuggestion(
      chromeId,
      recommendation.text,
      recommendation,
      true,
      promptActual
    );
    setState({
      ...state,
      selected: recommendation,
      suggestion: response?.data,
    });
  };

  const setHighlighted = async (
    recommendation: Recommendation | undefined,
    chromeId?: chromeId,
    lvIdentifier?: lvIdentifier
  ) => {
    if (!recommendation || !chromeId || !lvIdentifier) {
      clearHighlighted();
      return;
    }
    setState({
      ...state,
      selected: recommendation,
      suggestion: undefined,
    });
    store.dispatch(
      selectedRecommendationAction.setSelectedRecommendation({
        lvIdentifier,
        selectedRecommendation: recommendation,
      })
    );
    if (!recommendation.text) {
      return;
    }
    const promptActual = getPromptName(recommendation);
    const response = await getAISuggestion(
      chromeId,
      recommendation.text,
      recommendation,
      true,
      promptActual
    );

    setState({
      ...state,
      selected: recommendation,
      suggestion: response?.data,
    });
  };

  const initState = {
    selected: undefined,
    suggestion: undefined,
    setHighlighted,
    regenerate,
  };

  const [state, setState] = useState<HighlightContextType>(initState);

  return (
    <HighlightContext.Provider value={state}>
      {props.children}
    </HighlightContext.Provider>
  );
};

export const useHighlight = () => {
  return useContext(HighlightContext);
};
