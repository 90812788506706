import { Icon, IconProps, Tag, Text } from "@lavender-ai/lav-components";
import {
  QuickActionsRowContainer,
  QuickActionsRowDetails,
} from "./QuickActionsRow.styled";
import { Avatar } from "antd";

interface Props {
  title: string;
  icon: IconProps["variant"];
  hasNewTag?: boolean;
  onClick: () => void;
}

export const SearchModalQuickActionsRow = ({
  icon,
  title,
  hasNewTag,
  onClick,
}: Props) => (
  <QuickActionsRowContainer onClick={onClick}>
    <QuickActionsRowDetails>
      <Avatar
        size="small"
        icon={
          <Icon
            variant={icon}
            size="extra-small"
            style={{ alignSelf: "center" }}
            color="lavender"
          />
        }
        style={{
          display: "flex",
          justifyContent: "center",
          borderRadius: "var(--radius-md)",
          background: "var(--color-lavender-100)",
        }}
      />
      <Text size="body2" color="dark">
        {title}
      </Text>
      {hasNewTag && (
        <Tag color="pear" style={{ borderRadius: 43, marginLeft: "auto" }}>
          New
        </Tag>
      )}
    </QuickActionsRowDetails>
  </QuickActionsRowContainer>
);
