import {
  Avatar,
  AvatarProps,
  Icon,
  IconProps,
  Tag,
  TagProps,
} from "@lavender-ai/lav-components";

import { createStyledIconFrame } from "~src/component/Atoms/IconFrame/CreateStyledIconFrame";

const StyledIconFrame = createStyledIconFrame(Avatar);
const StyledTagIconFrame = createStyledIconFrame(Tag);

interface CommonProps {
  color?: AvatarProps["color"] | TagProps["color"];
  isTag?: boolean;
  iconSize?: IconProps["size"];
  isLoading?: boolean;
  variant: IconProps["variant"];
}

interface AvatarContainerProps extends CommonProps {
  avatarShape?: string;
  avatarSize?: string;
}

interface TagContainerProps extends CommonProps {
  text?: string;
}

type Props = AvatarContainerProps | TagContainerProps;

function isTagContainerProps(props: Props): props is TagContainerProps {
  return !!props.isTag;
}

export const IconFrame = (props: Props) => {
  const { isLoading, variant } = props;

  if (isTagContainerProps(props)) {
    const { color = "outline", iconSize = "mini", text } = props;
    return (
      <StyledTagIconFrame
        className="lv-icon-frame"
        color={color}
        $isLoading={isLoading}
      >
        <Icon size={iconSize} variant={variant} />
        {text && <div className="lv-icon-frame-text">{text}</div>}
      </StyledTagIconFrame>
    );
  }

  const {
    color = "neutral-100",
    avatarShape = "square",
    avatarSize = "extra-small",
    iconSize = "extra-small",
  } = props;

  return (
    <StyledIconFrame
      className="lv-icon-frame"
      color={color}
      shape={avatarShape}
      icon={<Icon size={iconSize} variant={variant} />}
      size={avatarSize}
      type="soft"
      $isLoading={isLoading}
    />
  );
};
