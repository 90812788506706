import { useUpgradePlanModalContext } from "~src/contexts";

import { Overlay, OverlayContainer } from "./UpgradePlanModal.styled";
import { SelectUpgradePlanModal } from "./SelectUpgradePlanModal";
import { UpgradePaymentModal } from "./UpgradePaymentModal";
import { useEffect } from "react";
import {
  refreshUserAccount,
  selectChromeId,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";

export const UpgradePlanModal = () => {
  const dispatch = useAppDispatch();
  const chromeId = useAppSelector(selectChromeId);
  const { closeModal, isModalOpen, selectedPlan } =
    useUpgradePlanModalContext();

  useEffect(() => {
    onmessage = (event) => {
      if (event.data === "lavender-upgrade-successful") {
        void dispatch(refreshUserAccount({ chromeId }));
        closeModal();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isModalOpen && (
      <OverlayContainer>
        <Overlay onClick={closeModal} />
        {selectedPlan ? <UpgradePaymentModal /> : <SelectUpgradePlanModal />}
      </OverlayContainer>
    )
  );
};
