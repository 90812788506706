import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface PanelTabContextType<T, U> {
  tab: T;
  setTab: (tab: T) => void;
  setSubTab: (tab: T) => void;
  setPanelTabContext: ({ tab, subTab }: PanelTab<T, U>) => void;
  subTab?: U;
}

export interface PanelTab<T, U> {
  tab: T;
  subTab?: U;
}

interface Props<T, U> {
  children: React.ReactNode;
  initialPanelContext: PanelTab<T, U>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PanelTabContext = createContext<PanelTabContextType<any, any> | null>(
  null
);

export function PanelTabContextProvider<T, U>({
  children,
  initialPanelContext,
}: Props<T, U>) {
  const [tab, setTab] = useState<T>(initialPanelContext.tab);
  const [subTab, setSubTab] = useState<U | undefined>(
    initialPanelContext.subTab
  );
  const setPanelTabContext = useCallback(
    ({ tab, subTab }: PanelTab<T, U>) => {
      setTab(tab);
      setSubTab(subTab);
    },
    [setTab, setSubTab]
  );

  const contextValue = useMemo(
    () => ({
      tab,
      setTab,
      setSubTab,
      setPanelTabContext,
      subTab,
    }),
    [setPanelTabContext, subTab, tab]
  );

  return (
    <PanelTabContext.Provider value={contextValue}>
      {children}
    </PanelTabContext.Provider>
  );
}

export function usePanelTabContext<T, U>() {
  const context = useContext(
    PanelTabContext as React.Context<PanelTabContextType<T, U> | null>
  );

  if (!context) {
    throw new Error(
      "usePanelTabContext must be used within a PanelTabProvider"
    );
  }

  return context;
}
