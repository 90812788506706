import React, { FC, PropsWithChildren } from "react";

// Receives multiple children, iterates over them like a switch{} to only return
// the first case that isn't null/undefined
export const ConditionalSwitch: FC<PropsWithChildren> = ({
  children: cases,
}) => <>{React.Children.toArray(cases).find(Boolean)}</>;

// Usage:
/* 
  <ConditionalSwitch>
    {condition1 && <ExclusiveComponent1 />}
    {condition2 && <ExclusiveComponent2 />}
    <>Optional default case at bottom</>
  </ConditionalSwitch>
*/
