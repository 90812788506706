import { type PanelTab } from "~src/contexts";

export enum PersonalizationTab {
  FEED = "Feed",
  PROFILE = "Profile",
  COMPANY = "Company",
}

export enum FeedSubTab {
  BLOGS_AND_PODCASTS = "Blogs & Podcasts",
  FUNDING = "Funding",
  SOCIAL_MEDIA = "Social Media",
  NEWS = "News",
  JOBS = "Jobs",
  TECH_STACK = "Tech Stack",
}

export enum FeedSubTabDropdown {
  ACTIVITY = "Activity",
  EXPERIENCE = "Experience",
  PERSONALITY = "Personality",
  GONG = "Gong",
}

export enum ProfileSubTab {
  ABOUT = "About",
  SOCIAL_FEED = "Social Feed",
  ACTIVITY = "Activity",
  EXPERIENCE = "Experience",
  PERSONALITY = "Personality",
  GONG = "Gong",
  SALESFORCE = "Salesforce",
  HUBSPOT = "HubSpot",
  PODCASTS = "Podcasts",
  CONNECT_CRM = "Connect CRM",
}

export enum CompanySubTab {
  ABOUT = "About",
  NEWS = "News",
  JOBS = "Jobs",
  TECH_STACK = "Tech Stack",
  FUNDING = "Funding",
  COMPANY_SOCIAL_FEED = "Posts",
  BLOGS = "Blogs",
}

export type PersonalizationSubTab =
  | ProfileSubTab
  | CompanySubTab
  | FeedSubTab
  | undefined;

export interface PersonalizationPageUIItem {
  className?: string;
  key: PersonalizationTab | ProfileSubTab;
  children: React.ReactNode;
  icon?: React.ReactElement;
  itemIcon?: React.ReactNode;
}

export type PersonalizationTabContext = Record<
  PersonalizationTab,
  PanelTab<PersonalizationTab, ProfileSubTab | CompanySubTab>
>;
