import { useEffect } from "react";
import { Flex } from "antd";

import { usePersonalization, useStartMyEmail } from "~src/customHooks";
import { PersonalizationPanelContainer } from "~src/component/Organisms";
import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";

import { StyledStartMyEmailModalLayout } from "../../Shared/Styled";

import { GeneratedEmails } from "./GeneratedEmails/GeneratedEmails";
import { Header } from "./Header/Header";

export const GeneratingEmailsModal = () => {
  const { resetCartContext } = usePersonalization();
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = useStartMyEmail();

  const {
    data: { openedBy },
    modalType,
    reset,
  } = useCarouselContext();
  useEffect(() => {
    if (
      modalType === ModalType.GeneratingEmails &&
      (!openedBy || openedBy !== email)
    ) {
      reset();
      resetCartContext();
    }
  }, [openedBy, email, modalType, reset, resetCartContext]);

  return (
    <StyledStartMyEmailModalLayout
      rootClassName="lv-generating-emails-modal-box"
      isFullHeight
    >
      <Flex justify="space-between" vertical>
        <PersonalizationPanelContainer align="center" gap={12} vertical>
          <Header />
          <GeneratedEmails />
        </PersonalizationPanelContainer>
      </Flex>
    </StyledStartMyEmailModalLayout>
  );
};
