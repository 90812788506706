import { FC } from "react";
import { Flex } from "antd";
import { styled } from "styled-components";

import { Button } from "@lavender-ai/lav-components";

import { useCarouselContext } from "../CarouselContextProvider";

import { LavenderIcon } from "../../../typings";
import { usePersonalization } from "~src/customHooks";

const Header = styled(Flex)`
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  justify-content: space-between;
  align-items: left;
  padding: 16px 20px;
  position: sticky;
  top: 0;
  background-color: var(--color-neutral-blue-50);
  z-index: 100;
`;

interface NavigationWrapperProps {
  children: React.ReactNode;
}

export const NavigationWrapper: FC<NavigationWrapperProps> = ({ children }) => {
  const { prev, prevModal } = useCarouselContext();
  const { resetCartContext } = usePersonalization();

  const backClick = () => {
    resetCartContext();
    prev(prevModal);
  };

  return (
    <>
      <Header>
        <Button
          htmlType="button"
          onClick={backClick}
          prefix={LavenderIcon.IconChevronLeft}
          prefixColor="dark"
        >
          Back
        </Button>
      </Header>
      {children}
    </>
  );
};
