import {
  HIGH_SCORE_MESSAGES,
  MIDDLE_SCORE_MESSAGES,
  LOW_SCORE_MESSSAGES,
  BANNERBEAR_HIGH_SCORE_TEMPLATE_ID,
  BANNERBEAR_MIDDLE_SCORE_TEMPLATE_ID,
  BANNERBEAR_LOW_SCORE_TEMPLATE_ID,
} from "./constants";

const getRandomMessage = (messages: string[]) => {
  return messages[Math.floor(Math.random() * messages.length)];
};

export const getMessageForScore = (score: number) => {
  if (score >= 90) {
    return getRandomMessage(HIGH_SCORE_MESSAGES);
  } else if (score >= 70) {
    return getRandomMessage(MIDDLE_SCORE_MESSAGES);
  } else {
    return getRandomMessage(LOW_SCORE_MESSSAGES);
  }
};

export const getTemplateId = (score: number) => {
  if (score >= 90) {
    return BANNERBEAR_HIGH_SCORE_TEMPLATE_ID;
  } else if (score >= 70) {
    return BANNERBEAR_MIDDLE_SCORE_TEMPLATE_ID;
  } else {
    return BANNERBEAR_LOW_SCORE_TEMPLATE_ID;
  }
};
