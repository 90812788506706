/// <reference path="lavenderAnywhere.d.ts"/>
import { createSlice } from "@reduxjs/toolkit";

const initialState: lavenderAnywhereState = {
  isOpen: false,
};

const lavenderAnywhereSlice = createSlice({
  name: "lavenderAnywhere",
  initialState,
  reducers: {
    open: (state: lavenderAnywhereState): lavenderAnywhereState => {
      return {
        ...state,
        isOpen: true,
      };
    },
    close: (state: lavenderAnywhereState): lavenderAnywhereState => {
      return {
        ...state,
        isOpen: false,
      };
    },
    toggle: (state: lavenderAnywhereState): lavenderAnywhereState => {
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    },
  },
});

export const lavenderAnywhereReducer = lavenderAnywhereSlice.reducer;
export const lavenderAnywhereAction = lavenderAnywhereSlice.actions;
