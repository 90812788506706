import { Flex } from "antd";
import { sanitize } from "dompurify";
import { styled } from "styled-components";

import { Text } from "@lavender-ai/lav-components";

import { UserDataAvatar } from "~src/component/Molecules";

import { extractAvatarFallback } from "~src/utils";
import { Message } from "~src/component/typings";
import { StyledMessageContainer, StyledMessageTextContainer } from "../styles";

const StyledUserMessageText = styled(StyledMessageTextContainer)`
  border: 1px solid var(--color-lavender-100);
  background: var(--color-lavender-100);
  white-space: pre-wrap;
`;

interface UserMessageProps extends Message {
  avatar: string | undefined;
  name: string;
}

export const UserMessage = ({ avatar, content, name }: UserMessageProps) => {
  return (
    <Flex gap="small" align="baseline">
      <div>
        <UserDataAvatar src={avatar} fallback={extractAvatarFallback(name)} />
      </div>
      <StyledMessageContainer>
        {content && (
          <StyledUserMessageText align="flex-start" gap={16} vertical>
            <Text
              color="dark"
              size="body2"
              dangerouslySetInnerHTML={{
                __html: sanitize(content),
              }}
            />
          </StyledUserMessageText>
        )}
      </StyledMessageContainer>
    </Flex>
  );
};
