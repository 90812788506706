export const checkForAndRemoveDuplicatePanes = (lavComposeClass: string) => {
  const containersWithPanels = [
    ...document.querySelectorAll(`.${lavComposeClass}`),
  ].filter((x) =>
    [...x.children].some((e) => e.classList.contains("lv-panel-root"))
  );

  // for each container with a panel, remove any duplicate panels
  containersWithPanels.forEach((container) => {
    const panels = [...container.children].filter((e) =>
      e.classList.contains("lv-panel-root")
    );
    if (panels.length > 1) {
      panels.forEach((panel, index) => {
        if (index > 0) {
          console.debug("removing dupe");
          panel.remove();
        }
      });
    }
  });
};
