import { HEADERS, networkRequest, URLS } from "~src/api";

import * as openChatApi from "./typings/openChat";

export const openChat = async (
  chromeId: chromeId
): Promise<openChatApi.SuccessResponse> => {
  const { data } = await networkRequest.post<openChatApi.SuccessResponse>(
    URLS.openChat,
    {
      chrome_id: chromeId,
    },
    {
      headers: {
        Accept: HEADERS.applicationJson,
      },
    }
  );

  return data;
};
