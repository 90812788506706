import { XOR } from "ts-xor";

import { ErrorResponse } from "./api";

export const isError = (response: object): response is ErrorResponse =>
  "error" in response;

export const isAskChatResponse = (
  response: Omit<Response, "status">
): response is AskChatSuccessResponse => response.content !== undefined;

export interface AskChatPayload {
  // The unique ID assigned by Open AI for the conversation
  chatThreadId: string;
  chromeId: chromeId;
  messages: ChatMessage[];
  // Whether its an auto suggestion trigger or not
  autoSuggestion?: boolean;
  location?: string;
  searchWeb?: boolean;
  timezone?: string | undefined;
}

export interface AskChatSuccessResponse {
  content: string;
  query?: string;
}

export interface AskChatEditEmailSuccessResponse {
  email: string;
  subject: string;
}

export type SuccessResponse = XOR<
  AskChatSuccessResponse,
  AskChatEditEmailSuccessResponse
> & {
  status: string;
};

export type Response = XOR<SuccessResponse, ErrorResponse>;

export interface ChatMessage {
  role: ChatRole;
  content?: string;
  query?: string;
}

export enum ChatRole {
  User = "user",
  Assistant = "assistant",
}
