import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useGlobalSearch } from "./useGlobalSearch";
import { GlobalSearchItem } from "./types";

interface IGlobalSearchContext {
  searchTerm: string;
  debouncedSearchTerm: string;
  isModalOpen: boolean;
  isSearching: boolean;
  openModal: () => void;
  closeModal: () => void;
  results: GlobalSearchItem[];
  handleSearch: (searchTerm: string) => void;
}

const GlobalSearchContext = createContext<IGlobalSearchContext>({
  searchTerm: "",
  debouncedSearchTerm: "",
  isModalOpen: false,
  isSearching: false,
  openModal: () => {},
  closeModal: () => {},
  results: [],
  handleSearch: () => {},
});

export const useGlobalSearchContext = () => {
  const context = useContext(GlobalSearchContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalSearch must be used within a GlobalSearchContextProvider"
    );
  }
  return context;
};

export const GlobalSearchContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [results, setResults] = useState<GlobalSearchItem[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const search = useGlobalSearch((results) => {
    setResults(results);
    setIsSearching(false);
    setDebouncedSearchTerm(searchTerm);
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm === debouncedSearchTerm) return;
      setIsSearching(true);
      search(searchTerm.trim());
    }, 200);

    return clearTimeout.bind(null, handler);
  }, [debouncedSearchTerm, search, searchTerm]);

  const contextValue = useMemo<IGlobalSearchContext>(
    () => ({
      searchTerm,
      results,
      isModalOpen,
      isSearching,
      openModal: setIsModalOpen.bind(null, true),
      closeModal: setIsModalOpen.bind(null, false),
      setIsModalOpen,
      handleSearch: setSearchTerm,
      debouncedSearchTerm,
    }),
    [searchTerm, results, isModalOpen, debouncedSearchTerm, isSearching]
  );

  return (
    <GlobalSearchContext.Provider value={contextValue}>
      {children}
    </GlobalSearchContext.Provider>
  );
};
