import { store } from "~src/redux/store";
import { CRMUserNotUpgrade } from "../constants";

// INFO: This function is used to check if a feature is paywalled or not
// currently we only have teams crm user as paywalled feature
// but we can expand this function to check for other paywalled future features and logic
export const isFeaturePayWalled = (feature: feature): boolean => {
  const state = store.getState();
  const isTeam = state.config.settings?.isTeam;
  const accountStatus = state.config.accountStatus;

  //INFO: for CRM user like Salesloft, Outreach, hubspot etc
  if (isTeam && accountStatus === CRMUserNotUpgrade) {
    if (
      feature === "Panel" ||
      feature === "UsingCRM" ||
      feature === "HighlightText"
    ) {
      return true;
    }
  }
  return false;
};
//INFO: add additional features here
type feature = Panel | UsingCRM | HighlightText;
type Panel = "Panel";
type UsingCRM = "UsingCRM";
type HighlightText = "HighlightText";
