import { styled } from "styled-components";

export const StyledCarouselWrapper = styled.div`
  width: inherit;
  height: inherit;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const StyledSlideContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const StyledSlideChild = styled.div`
  &.lv-slide-default {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    left: 100%;
    top: 0;
    background: #f6f8fcff;
    z-index: 3;
    transition: left 400ms cubic-bezier(0.25, 1, 0.25, 1);

    &.lv-slide-in {
      left: 0;
      top: 0;
      z-index: 2;
      box-shadow:
        0px 2px 4px 0px rgba(0, 0, 0, 0.08),
        0px 8px 20px -16px rgba(0, 0, 0, 0.08);
    }

    &.lv-slide-out {
      left: -40%;
      top: 0;
      z-index: 1;
      box-shadow:
        0px 2px 4px 0px rgba(0, 0, 0, 0.08),
        0px 8px 20px -16px rgba(0, 0, 0, 0.08);
    }

    &.top {
      z-index: 2;
    }

    &.bottom {
      z-index: 1;
    }

    &.no-slide {
      transition: none;
    }
  }
`;
