import { InternalAxiosRequestConfig } from "axios";
import { HEADERS } from "./constants";

// Automatically append Content-Type header to all Post requests
export const appendContentTypeHeadersToPost = (
  config: InternalAxiosRequestConfig<unknown>
) => {
  if (
    config.method === "post" &&
    config.headers["Content-Type"] === undefined
  ) {
    config.headers["Content-Type"] = HEADERS.formUrlEncodeded;
  }
  return config;
};

// Check if a GET request contains chrome_id, chromeId and block for security reasons
export const blockGetRequestWithChromeID = (
  config: InternalAxiosRequestConfig<unknown>
) => {
  if (config.method === "get") {
    const paramKeys = Object.keys(
      (config.params || {}) as Record<string, unknown>
    );
    const dataKeys = Object.keys(
      (config.data || {}) as Record<string, unknown>
    );
    if (
      doesContainChromeId(paramKeys) ||
      doesContainChromeId(dataKeys) ||
      doesContainChromeId(config.url ?? "")
    ) {
      throw new Error(
        `GET request to ${config.url} contains chrome_id or chromeId`
      );
    }
  }

  return config;
};

const doesContainChromeId = (test: string | string[]) => {
  if (Array.isArray(test)) {
    test = test.join("");
  }

  return (
    test.includes("chrome_id") ||
    test.includes("chrome_ids") ||
    test.includes("chromeId") ||
    test.includes("chromeIds")
  );
};
