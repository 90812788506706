export interface HubspotData {
  personal: HubspotPersonal;
  company: HubspotCompany;
  notes: HubspotNote[];
}

export interface HubspotPersonal {
  name?: string;
  email?: string;
  phone?: string;
  stage?: string;
}

export interface HubspotCompany {
  name?: string;
  domain?: string;
  stage?: string;
}

export interface HubspotNote {
  body?: string;
  timestamp?: string;
}

export interface HubspotResponse {
  properties?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    lifecyclestage?: string;
  };
  company?: HubspotCompanyResponse;
  notes?: HubspotNoteResponse[];
}

export interface HubspotNoteResponse {
  properties?: {
    hs_note_body?: string;
    hs_timestamp?: string;
  };
}

export interface HubspotCompanyResponse {
  properties: {
    name?: string;
    domain?: string;
    lifecyclestage?: string;
  };
}
