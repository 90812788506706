export const MAILCLIENT = {
  APOLLO: "apollo",
  GMAIL: "gmail",
  GROOVE: "groove",
  HUBSPOT: "hubspot",
  OUTREACH: "outreach",
  OUTLOOK: "outlook",
  OUTLOOK_NATIVE: "outlookNative",
  SALESLOFT: "salesloft",
  HYBRID_FINANCIAL: "hybridFinancial",
  LINKEDINSALESNAV: "linkedinSalesNav",
  LINKEDIN: "linkedin",
  GONG: "gong",
  REPLYIO: "replyio",
  FRONT: "front",
  PIPEDRIVE: "pipedrive",
};
