import { isDuplicateRenderCycle } from "./utils";
import {
  flagExtensionIsInstalledToDashboard,
  renderMainLoopComponent,
  setupHotReload,
  setupLavenderAnywhereListener,
  setupParticlesEngine,
} from "./initializers";

// Extension entry point, immediately invoked
const init = () => {
  if (isDuplicateRenderCycle()) return;

  renderMainLoopComponent();
  flagExtensionIsInstalledToDashboard();
  setupHotReload();
  setupLavenderAnywhereListener();
  setupParticlesEngine();
};

init();
