export interface GetFeatureFlagResponse {
  status: "success";
  result?: FeatureFlags;
}

export type FeatureFlags = Record<FeatureFlagEnum, boolean>;

export enum FeatureFlagEnum {
  effect_on_score = "effect_on_score",
  fix_all = "fix_all",
  google_web_search_tool = "google_web_search_tool",
  metric_engine = "metric engine",
  scoringOverwrite = "scoringOverwrite",
  sme_2 = "sme_2",
  sme_reply = "sme_reply",
  sme_router = "sme_router",
  sme_value_props_generation = "sme_value_props_generation",
  websockets = "websockets",
  suggest_boost_non_a_emails = "suggest_boost_non_a_emails",
  show_linkedin_share_button = "show_linkedin_share_button",
  in_app_plan_upgrade_modal = "in_app_plan_upgrade_modal",
  streaks = "streaks",
}
