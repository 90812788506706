import { memo, useState } from "react";
import { Image, Skeleton } from "antd";
import { styled } from "styled-components";

import {
  Avatar,
  AvatarProps,
  Icon,
  Tooltip,
} from "@lavender-ai/lav-components";

import { LavenderIcon, PropsForStyling } from "~src/component/typings";
import { numericSize } from "~src/component/utils";
import { store } from "~src/redux";

export const USER_ALT = "Profile Pic";
const COMPANY_ALT = "Company Logo";

interface StyledAvatarProps {
  isLight: boolean;
}

const StyledAvatar = styled(Avatar)<PropsForStyling<StyledAvatarProps>>`
  &:not(.lav-components-avatar-src-undefined):has(img)&& {
    background: var(--color-base-White) !important;
  }
  &:has(.lv-avatar-fallback):not(:has(img)) {
    background: ${({ $isLight }) =>
      $isLight
        ? "var(--color-neutral-purple-100) !important"
        : "var(--color-neutral-purple-200) !important"};
  }
`;

interface Props {
  badge?: AvatarProps["badge"];
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  src?: string;
  fallbackSrc?: string;
  fallback?: LetterFallback | string;
  shape?: "circle" | "square";
  size?: AvatarProps["size"];
  loading?: boolean;
  style?: React.CSSProperties;
  entity?: UserDataEntity;
  isLight?: boolean;
  tooltip?: string;
  usePlaceholderFallback?: boolean;
}

export enum UserDataEntity {
  company = "company",
  user = "user",
}

enum LetterFallback {
  user = "U",
  company = "",
}

const getIcon = (
  entity: UserDataEntity,
  role: "img" | "button",
  size: AvatarProps["size"],
  isLight?: boolean
) => {
  switch (entity) {
    case UserDataEntity.company:
      return (
        <Icon
          aria-label={COMPANY_ALT}
          className="lv-avatar-fallback"
          role={role}
          {...(size === "extra-small" && { size })}
          style={{
            color: isLight
              ? "var(--color-neutral-purple-500)"
              : "var(--color-neutral-purple-900)",
          }}
          variant={LavenderIcon.IconBuilding}
        />
      );
    case UserDataEntity.user:
      return (
        <Icon
          aria-label={USER_ALT}
          className="lv-avatar-fallback"
          role={role}
          {...(size === "extra-small" && { size })}
          style={{
            color: isLight
              ? "var(--color-neutral-purple-500)"
              : "var(--color-neutral-purple-900)",
          }}
          variant={LavenderIcon.IconUserQuestion}
        />
      );
  }
};

const UserDataAvatarInternal = ({
  badge,
  entity = UserDataEntity.user,
  fallback,
  fallbackSrc,
  onClick,
  src,
  size = "small",
  shape = "circle",
  isLight = false,
  loading = false,
  style = {},
  tooltip,
  usePlaceholderFallback = false,
}: Props) => {
  const [showImage, setShowImage] = useState(true);

  if (loading) {
    return (
      <Skeleton.Avatar shape={shape} size={numericSize(size)} style={style} />
    );
  }

  const alt = entity === UserDataEntity.user ? USER_ALT : COMPANY_ALT;
  const role = onClick ? "button" : "img";
  const tabIndex = role === "button" ? 0 : undefined;
  let fallbackIcon;

  if (fallbackSrc) {
    fallbackIcon = (
      <Image
        alt={alt}
        className="lv-avatar-fallback"
        role={role}
        width={size}
        height={size}
        onError={() => {
          setShowImage(false);
        }}
        preview={false}
        src={fallbackSrc}
        tabIndex={tabIndex}
      />
    );
  } else if (usePlaceholderFallback) {
    fallbackIcon = getIcon(entity, role, size, isLight);
  }

  if (src && showImage) {
    return (
      <Tooltip hideArrow placement="right" title={tooltip}>
        <StyledAvatar
          alt={alt}
          badge={badge}
          className="ant-avatar-image"
          {...(fallbackIcon && { icon: fallbackIcon })}
          src={src}
          shape={shape}
          size={size}
          style={style}
          onClick={onClick}
        >
          {fallback}
        </StyledAvatar>
      </Tooltip>
    );
  }
  const state = store.getState();
  const platform = state.config.settings?.platform;
  return (
    <Tooltip
      hideArrow
      placement={platform === "outlookNative" ? "bottom" : "right"}
      title={tooltip}
    >
      {usePlaceholderFallback ? (
        <StyledAvatar
          alt={alt}
          badge={badge}
          icon={getIcon(entity, role, size, isLight)}
          shape={shape}
          size={size}
          style={style}
          onClick={onClick}
        />
      ) : (
        <Avatar
          alt={alt}
          badge={badge}
          shape={shape}
          size={size}
          style={style}
          onClick={onClick}
        >
          {fallback || LetterFallback[entity]}
        </Avatar>
      )}
    </Tooltip>
  );
};

export const UserDataAvatar = memo(UserDataAvatarInternal);
