import { networkRequest, URLS } from "~src/api";

interface GifResponseType {
  gifs: Gif[];
  total_count: number;
}

export const getGifs = async (
  searchQ: string,
  offset: number = 0,
  limit: number = 20
) => {
  const params = new URLSearchParams();
  params.append("searchQ", searchQ);
  params.append("offset", (offset * limit + 1).toString());
  params.append("limit", limit.toString());
  const request = { params };

  return await networkRequest.get<GifResponseType>(URLS.gifs, request);
};

export const getTrendingGifs = async (
  offset: number = 0,
  limit: number = 20
) => {
  const params = new URLSearchParams();
  params.append("offset", (offset * limit).toString());
  params.append("limit", limit.toString());
  const request = { params };

  return await networkRequest.get<GifResponseType>(URLS.trendingGifs, request);
};
