import { URLS, networkRequest } from ".";
import {
  GetFeatureFlagResponse,
  FeatureFlags,
  FeatureFlagEnum,
} from "./typings/featureFlag";

const parseFlags = (resp: GetFeatureFlagResponse) => {
  if (resp.status === "success" && resp.result) {
    const flags: Partial<FeatureFlags> = {};
    for (const ff in resp.result) {
      if (resp.result[ff]) {
        flags[ff] = true;
      }
    }
    return flags as FeatureFlags;
  } else {
    throw new Error("Failed to fetch feature flags");
  }
};

export const getFeatureFlag = async (
  chromeId: chromeId,
  success: (resp: GetFeatureFlagResponse) => FeatureFlags = parseFlags
) => {
  const { data } = await networkRequest.post<GetFeatureFlagResponse>(
    URLS.featureFlag,
    {
      chrome_id: chromeId,
      names: Object.values(FeatureFlagEnum),
    }
  );
  return success(data);
};
