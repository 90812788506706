export const setupLavenderAnywhereListener = () => {
  window.addEventListener(
    "message",
    (event: {
      data: { lavender_anywhere_event?: unknown; time?: unknown };
    }) => {
      if (event.data?.lavender_anywhere_event && event.data?.time) {
        // If the event's time is within 10 seconds of the current time, save the data
        if (
          new Date().getTime() - new Date(String(event.data.time)).getTime() <
          10 * 1000
        ) {
          window.email_data = event.data;
        }
      }
    }
  );
};
