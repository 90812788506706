import { Root } from "react-dom/client";
import { BillingPlansPrices } from "./billingPlans";
import { NetworkStatus } from "./networkStatus";

export enum InsertType {
  Copy = "copy",
  Insert = "insert",
}

export interface ConfigApiResponse {
  billingPlans: BillingPlansPrices;
  lavender_css: string;
  platform: string;
  emailThreads_css: string;
  platformDisplayName: string;
  insertType: InsertType;
  isTeam: boolean;
  platform_css: string;
  selectors: selectors;
  timing: timing;
  status: string;
  pixelURL: string;
  linkTags: string[];
  component_css?: string;
  streakThreshold: number;
}
export interface selectors {
  composeContainers: ComposeContainerMetadata[];
  bodyIframe: string;
  iframeChildSelector?: string;
  bodySelector: string;
  bodyAttrName: string;
  bodyCSSLavClass: string;
  subjectSelector: string;
  subjectBoxSelector: string;
  subjectAttrName: string;
  subjectCSSLavClass: string;
  addressToSelector: string;
  addressToHiddenInputSelector: string;
  addressToDropdownSelector: string;
  addressToAttrName: string;
  addressToNestedElem?: string;
  addressToHoverSelector?: string;
  addressToCSSLavClass: string;
  taskChangeSelectors?: string;
  closeButton: string;
  sendButton: string;
  newEmailButton?: string;
  emailAddressSelector: string;
  emailAddressAttrName: string;
  emailAddressCSSLavClass: string;
  emailThreadContainer?: string;
  emailThreadChildOrder?: number;
  openEmailThreadSelector?: string;
  openEmailThreadParentSelector?: string;
  openEmailPixelSelector?: string;
  openEmailPixelSelectorParent?: string;
  openEmailPixelInsertionSelector?: string;
  emailThreadBodyContent?: string;
  emailThreadBodyContentFilterOutElements?: string;
  emailThreadReply?: string;
  emailThreadSubject?: string;
  emailThreadTimeSend?: string;
  emailThreadTimeSendField?: string;
  emailThreadRecipient?: string;
  emailThreadRecipientField?: string;
  emailThreadEmailBefore?: string;
  emailThreadInnerReplyEmail?: string;
  emailThreadInnerReplyEmailTimeSentPrefix?: string;
  emailThreadInnerReplyEmailBody?: string;
  emailThreadInnerReplyEmailBodyFilterOutElements?: string;
  emailThreadAuthorEmail?: string;
  emailThreadAuthorEmailField?: string;
  emailThreadAuthorName?: string;
  emailThreadAuthorNameField?: string;
  emailReplySelector?: string;
  snippetSelector?: string; // INFO: this is for salesloft and outreach
  authorEmailOverwriteSelector?: string; // INFO: this is for hybridFinancial
  panelChildOrder?: number; // INFO: this is for outlook native
}

export interface EmailThreadEmailsBeforeSelectors {
  emailThreadEmailBefore: string;
  emailThreadInnerReplyEmail: string;
  emailThreadInnerReplyEmailTimeSentPrefix: string;
  emailThreadInnerReplyEmailBody: string;
  emailThreadInnerReplyEmailBodyFilterOutElements?: string;
}

export interface HighlightSelectors {
  bodyIframe?: string;
  bodySelector: string;
}

export interface ComposeContainerMetadata {
  container: ContainerMetadata;
  info?: string;
  menu: MenuMetadata;
  header?: HeaderMetadata;
  footer?: FooterMetadata;
}
interface ContainerMetadata {
  containerInjectParent?: boolean;
  containerChildInject?: boolean;
  containerChildInjectPos?: number;
  containerSelector: string;
  containerCSSLavClass: string;
  containerChildCSSLavClass?: string;
  overwriteContainer?: boolean;
  isIframe?: boolean;
  iframeContainerSelector?: string;
}
export interface MenuMetadata {
  panelParents: string;
  menuContainerClass?: string;
  panelChildOrder: number;
  panelOpenToRight?: boolean;
}
interface HeaderMetadata {
  headerSelector: string;
  headerCSSLavClass: string;
  headerChildPos?: number;
}
interface FooterMetadata {
  footerSelector: string;
  footerCSSLavClass: string;
  footerChildPos: number;
}
interface timing {
  mainPollingInterval: number;
  mainPanelAnimationInterval: number;
  addressToInterval: number;
  subjectInterval: number;
  bodyInterval: number;
  subjectPauseWaitTime: number;
  bodyPauseWaitTime: number;
  iframeDelay: number;
}
export interface ConfigState {
  settings?: ConfigApiResponse;
  chromeId: chromeId;
  status: NetworkStatus;
  error: string;
  emailAddress: string;
  mailBox: GetMailBoxUserResponse;
  mainPollingInterval: number;
  reactRoot: Root | null;
  crmUser: GetCRMUserResponse;
  accountStatus: AccountStatus;
  billingPlans: BillingPlansPrices;
}

interface GetUserAccountResponse {
  chrome_id: chromeId;
  status: string;
}

type CRMUserFound = "CRM user found";
type CRMUserNotUpgrade = "No team accounts with crm connected";
type CRMResponse = CRMUserFound | CRMUserNotUpgrade;
export interface GetCRMUserResponse extends GetUserAccountResponse {
  message: CRMResponse;
}
type UserNewMailBox = "New mailbox detected";
type UserFound = "User found for mailbox";
export interface GetMailBoxUserResponse extends GetUserAccountResponse {
  message: UserNewMailBox | UserFound;
}

export type AccountStatus =
  | CRMUserFound
  | CRMUserNotUpgrade
  | UserNewMailBox
  | UserFound;
