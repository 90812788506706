import { useState } from "react";
import { Alert, Divider, Empty, Flex } from "antd";
import clsx from "clsx";
import { styled } from "styled-components";

import { Icon, Text, Tooltip } from "@lavender-ai/lav-components";

import {
  PanelBlock,
  PanelBlockVariant,
} from "~src/layout/MainPanel/PanelBlock";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
} from "~src/component/Utilities/Loading";
import { IconAndText } from "~src/component/Atoms";
import { usePersonalization, useVerification } from "~src/customHooks";
import {
  PersonalizationSubTabs,
  Socials,
  UserDataAvatar,
} from "~src/component/Molecules";

import { usePanelTabContext } from "~src/contexts";
import { PERSONALIZATION_PANEL } from "~src/strings";
import {
  StyledColumn,
  StyledSummaryInfoBlock,
} from "../Personalization/Shared/Styled";
import {
  LavenderIcon,
  type PersonalizationSubTab,
  PersonalizationTab,
  ProfileSubTab,
} from "~src/component/typings";
import { SecondaryText } from "~src/component/Styled";
import {
  getJobString,
  SegmentEventLocations,
  SegmentEventNames,
  segmentEvent,
} from "~src/utils";

const {
  NOT_FOUND,
  INVALID_RECIPIENT,
  UNABLE_TO_VERIFY,
  UNABLE_TO_VERIFY_DETAIL,
  VALID_RECIPIENT,
} = PERSONALIZATION_PANEL;

const StyledSummaryInfoContainer = styled.div`
  padding: 24px 20px 0 20px;
  width: 100%;

  .lav-components-avatar:has(.lv-avatar-fallback) {
    width: 48px;
    height: 48px;
  }
`;

const StyledAlertContainer = styled.div`
  padding: 16px 16px 0px;
  text-align: left;
  width: 100%;

  > div {
    border-radius: 12px;
  }
`;

const StyledNameText = styled(Text)`
  padding: 8px 0;
  color: var(--color-lavender-600) !important;
`;

const MoreInfoWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  cursor: pointer;
`;

const MoreInfoText = styled.div`
  //color: var(--color-neutral-purple-900, #0D1421);
  color: #858991;
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: -0.03px;
  text-decoration-line: underline;
`;

const MoreInfoSocialWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

const StyledDivider = styled(Divider)`
  margin: 8px 20px 0;
  min-width: 40px;
  width: calc(100% - 40px);
`;

export const ProfileSummary = () => {
  const { isInvalid, isUnverified, isValid } = useVerification();
  const {
    currentPersonalization: {
      profile: {
        avatar,
        bio,
        name,
        education,
        email,
        job,
        location,
        loading,
        social_medias = {},
        phone_numbers,
      },
    },
  } = usePersonalization();

  const { tab, subTab } = usePanelTabContext<
    PersonalizationTab,
    PersonalizationSubTab
  >();
  const isLoading = loading;
  const jobString = getJobString(job);

  const hasVerificationData =
    isUnverified(email) || isInvalid(email) || isValid(email);

  const USER_AVATAR_SIZE =
    hasVerificationData || !avatar ? "large" : "extra-large";
  const showVerificationAlert =
    hasVerificationData && tab === PersonalizationTab.FEED;

  const [isExpanded, setIsExpanded] = useState(false);

  const handleMoreInfoClick = () => {
    setIsExpanded(!isExpanded);
  };

  const infoBlockCollapsed = (
    <>
      <SecondaryText size="body1">
        {jobString !== "" ? jobString : NOT_FOUND}
      </SecondaryText>
      {phone_numbers?.map((phone) => {
        return (
          <Tooltip title={phone.type}>
            <IconAndText
              icon={LavenderIcon.IconPhone}
              text={phone.raw_number?.replaceAll(" ", "-") || ""}
            />
          </Tooltip>
        );
      })}
    </>
  );

  const infoBlockExpanded = (
    <StyledSummaryInfoBlock
      {...(tab !== PersonalizationTab.FEED && {
        style: { width: `calc(100% - 20px)` },
      })}
    >
      {phone_numbers?.map((phone) => {
        return (
          <Tooltip title={phone.type}>
            <IconAndText
              icon={LavenderIcon.IconPhone}
              text={phone.raw_number?.replaceAll(" ", "-") || ""}
            />
          </Tooltip>
        );
      })}
      <IconAndText
        icon={LavenderIcon.IconBriefcase}
        text={jobString !== "" ? jobString : NOT_FOUND}
      />
      <IconAndText
        icon={LavenderIcon.IconMapPin}
        text={location || NOT_FOUND}
        titleCase
      />
      <IconAndText
        icon={LavenderIcon.IconSchool}
        text={education || NOT_FOUND}
      />
      <IconAndText
        iconColor={
          isInvalid(email)
            ? "negative"
            : isUnverified(email)
              ? "warning"
              : "lavender"
        }
        icon={
          isInvalid(email)
            ? "IconAlertCircleFilled"
            : isUnverified(email)
              ? "IconAlertTriangleFilled"
              : "IconCircleCheckFilled"
        }
        text={email}
      />
    </StyledSummaryInfoBlock>
  );

  return (
    <PanelBlock variant={PanelBlockVariant.grid}>
      {showVerificationAlert && (
        <StyledAlertContainer>
          <Alert
            banner
            icon={
              <Icon
                color={
                  isInvalid(email)
                    ? "negative"
                    : isUnverified(email)
                      ? "warning"
                      : "positive"
                }
                size="small"
                variant={
                  isInvalid(email)
                    ? "IconAlertCircleFilled"
                    : isUnverified(email)
                      ? "IconAlertTriangleFilled"
                      : "IconCircleCheckFilled"
                }
              />
            }
            showIcon
            message={
              <Text>
                {isInvalid(email)
                  ? INVALID_RECIPIENT
                  : isUnverified(email)
                    ? UNABLE_TO_VERIFY
                    : VALID_RECIPIENT}
                {isUnverified(email) && (
                  <Tooltip
                    title={UNABLE_TO_VERIFY_DETAIL}
                    placement="bottom"
                    hideArrow={true}
                  >
                    {" "}
                    <span style={{ textDecoration: "underline" }}>Why?</span>
                  </Tooltip>
                )}
              </Text>
            }
            type={
              isInvalid(email)
                ? "error"
                : isUnverified(email)
                  ? "warning"
                  : "success"
            }
          />
        </StyledAlertContainer>
      )}
      <StyledSummaryInfoContainer
        className={clsx({
          "lav-panel-grid-container": true,
          "--unverified": !name,
        })}
      >
        <StyledColumn align="flex-start" gap="middle" justify="center" vertical>
          <CustomSkeleton
            loading={isLoading}
            variant={CustomSkeletonVariant.title}
          >
            <StyledNameText size="subtext4">{name || email}</StyledNameText>
            {isExpanded ? infoBlockExpanded : infoBlockCollapsed}
          </CustomSkeleton>
        </StyledColumn>
        <div>
          <UserDataAvatar
            src={avatar}
            size={USER_AVATAR_SIZE}
            isLight
            loading={isLoading}
            usePlaceholderFallback
          />
        </div>
      </StyledSummaryInfoContainer>

      <div style={{ marginTop: 12, width: "100%" }}>
        <MoreInfoSocialWrapper>
          <MoreInfoWrapper>
            <MoreInfoText
              onClick={() => {
                if (isExpanded) {
                  segmentEvent(
                    SegmentEventNames.LessInfoClicked,
                    SegmentEventLocations.PersonalizationAssistant
                  );
                } else {
                  segmentEvent(
                    SegmentEventNames.MoreInfoClicked,
                    SegmentEventLocations.PersonalizationAssistant
                  );
                }
                handleMoreInfoClick();
              }}
            >
              {isExpanded ? "Less info" : "More info"}
            </MoreInfoText>
            <Icon
              size="mini"
              variant={
                isExpanded
                  ? LavenderIcon.IconChevronUp
                  : LavenderIcon.IconChevronDown
              }
            />
          </MoreInfoWrapper>
          <Socials {...social_medias} loading={isLoading} />
        </MoreInfoSocialWrapper>
        <StyledDivider />
        <PersonalizationSubTabs category={"profile"} />
      </div>

      {tab === PersonalizationTab.PROFILE && subTab === ProfileSubTab.ABOUT && (
        <div style={{ padding: "0px 16px 24px 16px", width: "100%" }}>
          <Divider style={{ margin: "0 0 20px" }} />
          {bio ? (
            <StyledColumn
              align="flex-start"
              gap="middle"
              justify="center"
              vertical
            >
              <Text size="body2">{bio}</Text>
            </StyledColumn>
          ) : (
            <Flex justify="center">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <Text
                    size="body1"
                    style={{
                      color: "var(--ant-color-text-quaternary)",
                      margin: "0 auto",
                    }}
                  >
                    No bio
                  </Text>
                }
              />
            </Flex>
          )}
        </div>
      )}
    </PanelBlock>
  );
};
