import { useState } from "react";
import { Flex, Card } from "antd";
import { Text } from "@lavender-ai/lav-components";

import { EmailCoachView, useEmailCoachCarouselContext } from "~src/contexts";
import { useLvIdentifier } from "~src/customHooks";
import {
  highlightsAction,
  useAppDispatch,
  useAppSelector,
  selectEmailAnalysis,
} from "~src/redux";
import { IconFrame } from "~src/component/Atoms";

import { CategorySection } from "../../../Shared/Styled/CategorySection";
import { CategoryItem } from "./components";
import { CategoryHeader, CategoryItems, ShowMore } from "./Category.styled";
import { CATEGORY_INFO } from "./constants";
import {
  useClearHighlightsOnUnmount,
  useGetFilteredRecommendations,
} from "./utils";

export interface Props {
  type: "danger" | "warning";
}

export const Category = ({ type }: Props) => {
  const [visibleRecsCount, setVisibleRecsCount] = useState(5);
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const { view } = useEmailCoachCarouselContext();
  const {
    analysis: { dynamic_ceq: score },
  } = useAppSelector(selectEmailAnalysis(lvIdentifier));
  const { description, highlightClass, icon, title, color } =
    CATEGORY_INFO[type];
  const filteredRecommendations = useGetFilteredRecommendations(type);
  useClearHighlightsOnUnmount();

  return (
    !!filteredRecommendations.length && (
      <CategorySection
        className={`category-${type}`}
        vertical
        style={{ width: "100%" }}
      >
        <Card className="lv-card">
          <CategoryHeader className="lv-email-category-header">
            <IconFrame color={color} variant={icon} />
            <Flex className="lv-email-category-header-text-container" vertical>
              <Text color="black" size="subtext4">
                {title}
              </Text>
              <Text
                size="body1"
                style={{
                  marginTop: "16px",
                  color: "var(--color-neutral-purple-600, #858092)",
                }}
              >
                {description}
              </Text>
            </Flex>
          </CategoryHeader>
          <CategoryItems vertical>
            {filteredRecommendations.slice(0, visibleRecsCount).map((r) => (
              <CategoryItem
                key={`ci-${r.id}`}
                recommendation={r}
                score={score}
                type={type}
                toggleOn={() => {
                  if (r.text) {
                    let highlights = [r.text];
                    if (r.text.includes("|")) {
                      highlights = r.text.split("|");
                    }
                    dispatch(
                      highlightsAction.setHighlights({
                        lvIdentifier,
                        highlightClass,
                        highlights,
                        recommendationId: r.id,
                      })
                    );
                  }
                }}
                toggleOff={() => {
                  if (view !== EmailCoachView.home) return;
                  dispatch(
                    highlightsAction.setHighlights({
                      lvIdentifier,
                      highlights: [],
                    })
                  );
                }}
              />
            ))}
          </CategoryItems>
          {filteredRecommendations?.length > visibleRecsCount && (
            <ShowMore
              onClick={() => {
                setVisibleRecsCount(visibleRecsCount + 5);
              }}
            >
              Show{" "}
              {Math.min(5, filteredRecommendations.length - visibleRecsCount)}{" "}
              More
            </ShowMore>
          )}
        </Card>
      </CategorySection>
    )
  );
};
