export const isValidEmail = (email: string): boolean => {
  // Check maximum length
  if (email.length > 254) return false;

  const [localPart, domainPart] = email.split("@");

  // Check lengths for local and domain parts
  if (!localPart || localPart.length < 1 || localPart.length > 64) {
    return false;
  }
  if (!domainPart || domainPart.length < 3 || domainPart.length > 252) {
    return false;
  }

  // Regex to match most common email formats, including international domains.
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
