import { FC } from "react";
import { styled } from "styled-components";

import { store } from "~src/redux";
import { GongCall } from "~src/redux/typings";
import { GONG_HEADER } from "~src/strings";

const NextStepsFrame = styled.div`
  display: flex;
  padding: 8px 8px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  border-bottom: 1px solid var(--color-neutral-purple-100, #e6e9f0);
  background: var(--color-base-White, #fff);
`;

const Title = styled.div`
  align-self: stretch;
  color: var(--color-neutral-purple-900, #18253d);
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.1px;
`;

const Bullets = styled.div`
  align-self: stretch;
  color: var(--color-neutral-purple-900, #18253d);
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.25px;
`;

const CallBriefFrame = styled.div`
  display: flex;
  padding: 24px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
`;

const Text = styled.div`
  align-self: stretch;
  color: var(--color-neutral-purple-900, #18253d);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.25px;
`;

const NextStepsUl = styled.ul<{ platform: string }>`
  margin: ${(props) =>
    props.platform === "gongEngage" ? "0 0 0 0px" : "0 0 0 -22px"};
`;

interface Props {
  call: GongCall;
}

export const Highlights: FC<Props> = (props) => {
  const state = store.getState();
  const platform = state.config.settings?.platform;
  const { call } = props;
  return (
    <>
      <NextStepsFrame>
        <Title>{GONG_HEADER.NEXT_STEPS}</Title>
        <Bullets>
          <>
            {platform && (
              <NextStepsUl platform={platform}>
                {call.nextSteps.map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </NextStepsUl>
            )}
          </>
        </Bullets>
      </NextStepsFrame>
      <CallBriefFrame>
        <Title>{GONG_HEADER.CALL_BRIEF}</Title>
        <Text>{call.callBrief}</Text>
      </CallBriefFrame>
    </>
  );
};
