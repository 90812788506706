import { SlideChild, SlideContainer } from "~src/component/Atoms";
import { EmailCoachView, useEmailCoachCarouselContext } from "~src/contexts";

import { FixItAllChat } from "./";

interface Props {
  children: React.ReactNode;
}

export const EmailCoachCarousel = ({ children }: Props) => {
  const { view } = useEmailCoachCarouselContext();

  return (
    <SlideContainer>
      <SlideChild noSlide stage={view === EmailCoachView.home ? "active" : "previous"}>
        {children}
      </SlideChild>
      <SlideChild stage={view === EmailCoachView.fixItAll ? "active" : "next"}>
        <FixItAllChat />
      </SlideChild>
    </SlideContainer>
  );
};
