import { isAxiosError } from "axios";

import { networkRequest, URLS } from "~src/api";

import * as companyDataApi from "./typings/additionalCompanyData";
import {
  APIResponseStatus,
  type CompanyDataType,
  type ErrorResponse,
} from "./typings";

export const isError = (
  response: companyDataApi.Response
): response is ErrorResponse => "error" in response;

export const getAdditionalCompanyData = async (
  category: CompanyDataType,
  email: string,
  chromeId: chromeId,
  page = -1
): Promise<companyDataApi.Response> => {
  try {
    const { data } = await networkRequest.post<companyDataApi.Response>(
      URLS.additionalCompanyData,
      {
        page,
        category,
        email,
        chrome_id: chromeId,
      }
    );
    return data;
  } catch (error) {
    if (isAxiosError<ErrorResponse>(error) && error.response) {
      return error.response.data;
    } else {
      return {
        error: "500",
        status: APIResponseStatus.error,
        message: "An unknown error occurred",
      };
    }
  }
};
