import { useEffect, useState } from "react";
import { Flex, Card, Space } from "antd";

import { Button, Text } from "@lavender-ai/lav-components";

import { useAuthentication, useLvIdentifier } from "../../customHooks";
import {
  handlePanelAnimation,
  detectEmailAddress,
  removeExtensionElements,
  setupChromeStorageListenerForLogin,
} from "./utils";
import {
  mainPanelAction,
  localStorageActions,
  configActions,
} from "../../redux";
import { AppDispatch } from "../../redux/store";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FRONTEND_URLS } from "~src/constants";

export const Login = () => {
  const isAuthenticated = useAuthentication();
  const isTeam = useAppSelector((state) => state.config.settings?.isTeam);

  const lvIdentifier = useLvIdentifier();
  const isOpen = useAppSelector(
    (state) => state.mainPanel[lvIdentifier].isOpen
  );
  const platform = useAppSelector((state) => state.config.settings?.platform);
  const defaultClass = "lv-panel";
  const [classNames, changeClass] = useState(defaultClass);

  useEffect(() => {
    if (!isAuthenticated && !isTeam) {
      handlePanelAnimation(isOpen, changeClass, defaultClass);
    }
  }, [isOpen, isAuthenticated, isTeam]);

  const email = detectEmailAddress(platform);
  const emailMessage = " is not logged in on this device.";
  const defaultMessage = "You are not logged in on this device.";
  const boldFooterMessage = "Log in to activate Lavender for this inbox.";
  const dispatch = useAppDispatch();

  // INFO: for initial state don't do any open or close animation just show it closed
  return (
    <div className={classNames}>
      <Flex
        vertical={true}
        className="lv-fill-height"
        justify="space-between"
        style={{
          width: 375,
          background: "var(--lv-panel-background-color)",
        }}
      >
        <Space size={24} direction={"vertical"} style={{ margin: "65px 24px" }}>
          <Text color="black" size="subtext5">
            Welcome back!
          </Text>
          <Text size="body2">
            {email ? (
              <span>
                <b>{email}</b> {emailMessage}
              </span>
            ) : (
              defaultMessage
            )}
            &nbsp;
            <b>{boldFooterMessage}</b>
          </Text>
        </Space>
        <Card
          bordered={false}
          style={{
            boxShadow: "unset",
            height: "176px",
            background: "var(--lv-panel-background-color)",
          }}
          bodyStyle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            paddingBottom: "unset",
          }}
        >
          <Button
            fullWidth
            size="large"
            state="initial"
            variant="primary"
            onClick={openDashboardLink}
          >
            Login
          </Button>
          <Button
            fullWidth
            size="large"
            state="initial"
            variant="default"
            onClick={closePanel(lvIdentifier, dispatch)}
          >
            Ignore
          </Button>
          <Button
            fullWidth
            size="large"
            state="initial"
            variant="plain"
            onClick={addEmailToIgoreList(email, lvIdentifier, dispatch)}
          >
            Don&apos;t ask again
          </Button>
        </Card>
      </Flex>
    </div>
  );
};
const openDashboardLink = () => {
  setupChromeStorageListenerForLogin();
  window.open(FRONTEND_URLS.login, "_blank");
};
const closePanel = (lvIdentifier: lvIdentifier, dispatch: AppDispatch) => {
  return () => {
    dispatch(mainPanelAction.close(lvIdentifier));
  };
};
const addEmailToIgoreList = (
  email: string,
  lvIdentifier: lvIdentifier,
  dispatch
) => {
  return () => {
    dispatch(localStorageActions.ignoreItem(email));
    dispatch(mainPanelAction.close(lvIdentifier));
    removeExtensionElements();
    dispatch(configActions.clearMainPollingInterval());
  };
};
