import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  DEFAULT_COMPANY,
  DEFAULT_COMPANY_TWITTER,
  DEFAULT_PROFILE_TWITTER,
} from "~src/constants";
import { ITweets } from "~src/redux/typings";

interface Props {
  children: React.ReactNode;
}

export interface ITweetsContext {
  currentTweets: ITweets;
  resetTweetsContext: () => void;
  setTweetsContext: (newTweets: ITweets) => void;
}

const initialState: ITweets = {
  company_twitter: DEFAULT_COMPANY_TWITTER,
  profile_twitter: DEFAULT_PROFILE_TWITTER,
  company: DEFAULT_COMPANY,
  loading: false,
};

const initTweetsContext: ITweetsContext = {
  currentTweets: initialState,
  resetTweetsContext: () => {
    /* empty */
  },
  setTweetsContext: (_newTweets: ITweets) => {
    /* empty */
  },
};

const TweetsContext = createContext(initTweetsContext);

export const TweetsContextProvider = ({ children }: Props) => {
  const [tweets, setTweets] = useState<ITweets>(initialState);

  const resetTweetsContext = useCallback(() => {
    setTweets(initialState);
  }, []);

  const setTweetsContext = useCallback((newTweets: ITweets) => {
    setTweets(newTweets);
  }, []);

  const tweetsContext: ITweetsContext = useMemo(
    () => ({
      currentTweets: tweets,
      resetTweetsContext,
      setTweetsContext,
    }),
    [resetTweetsContext, setTweetsContext, tweets]
  );

  return (
    <TweetsContext.Provider value={tweetsContext}>
      {children}
    </TweetsContext.Provider>
  );
};

export const useTweetsContext = () => useContext(TweetsContext);
