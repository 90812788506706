import { Avatar, Icon, Text } from "@lavender-ai/lav-components";
import { Flex, Space } from "antd";
import {
  type Tweet as ITweet,
  TwitterUser,
} from "~src/api/typings/personalization";
import { SecondaryText } from "~src/component/Styled";
import { prettyDate } from "~src/utils";

interface TweetPostProps {
  tweet: ITweet;
  twitterUser: TwitterUser;
}

export const Tweet = ({ tweet, twitterUser }: TweetPostProps) => {
  return (
    <Space direction={"horizontal"} size={12} align={"start"}>
      <Avatar
        size="small"
        src={twitterUser.profile_banner_url}
        alt={twitterUser.name}
      />
      <Flex vertical={true} gap={12}>
        <Flex justify={"flex-start"} gap={6} align={"end"}>
          <Text size="body2">{twitterUser.name}</Text>
          <SecondaryText size="body1">@{twitterUser.screen_name}</SecondaryText>
          <SecondaryText size="body1">•</SecondaryText>
          <SecondaryText size="body1">
            {prettyDate(tweet.created_at || "", "short")}
          </SecondaryText>
        </Flex>
        <Text size="body1">{tweet.text}</Text>
        <Flex justify={"flex-start"} gap={24}>
          <Flex gap={2} align={"center"}>
            <Icon size="extra-small" variant="IconRepeat" />
            <SecondaryText size="body1">
              {tweet.retweet_count || 0}
            </SecondaryText>
          </Flex>
          <Flex gap={4} align={"center"}>
            <Icon size="extra-small" variant="IconHeart" />
            <SecondaryText size="body1">
              {tweet.favorite_count || 0}
            </SecondaryText>
          </Flex>
        </Flex>
      </Flex>
    </Space>
  );
};
