import { store } from "~src/redux/store";
import { fetchMailBoxUser } from "../redux";
import { authenticateEmailClientAccount } from "./authenticateEmailClientAccount";

export const checkExistingEmailClientAccount = async (
  userEmailAddress: emailAddress,
  chromeId: chromeId
): Promise<void> => {
  await store
    .dispatch(fetchMailBoxUser({ emailAddress: userEmailAddress, chromeId }))
    .unwrap()
    .then(authenticateEmailClientAccount);
};
