import { ButtonProps } from "@lavender-ai/lav-components";

import { BUTTON_TEXT } from "~src/strings";

import { LavenderIcon } from "../typings";
import { ExtensionButton } from ".";

interface Props {
  buttonText?: string;
  prefix?: LavenderIcon;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: ButtonProps["size"];
}

const { SEE_MORE } = BUTTON_TEXT;

export const LoadMoreButton = ({
  onClick,
  buttonText = SEE_MORE(),
  prefix,
  size = "medium",
}: Props) => {
  return (
    <div>
      <ExtensionButton
        className="lv-load-more-button"
        onClick={onClick}
        size={size}
        prefix={prefix}
        text={buttonText}
      />
    </div>
  );
};
