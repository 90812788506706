import { RankedIcebreaker } from "~src/api/typings";
import { UnassignedTriggerColorCount } from "~src/component/constants";
import {
  LavenderTextColorMap,
  UnassignedTriggerColorMap,
} from "~src/component/typings";
import { PersonalizationEvent, RankedFeedSectionMap } from "~src/redux/typings";

export const icebreakerCount = (
  rankedFeedOrCart: RankedFeedSectionMap | undefined
): number => {
  return rankedFeedOrCart === undefined
    ? 0
    : Object.keys(rankedFeedOrCart).reduce(
        (acc, key) => acc + (rankedFeedOrCart[key].items?.length || 0),
        0
      );
};

export const hasItems = (
  rankedFeedOrCart: RankedFeedSectionMap | undefined
): boolean => {
  return icebreakerCount(rankedFeedOrCart) > 0;
};

export const getRoutedItems = (
  rankedFeedOrCart: RankedFeedSectionMap | undefined
): PersonalizationEvent[] | undefined => {
  if (!rankedFeedOrCart) {
    return undefined;
  }

  return Object.values(rankedFeedOrCart)
    .flatMap((section) => section.items)
    .filter((item) => item.showCart);
};

export const hasRoutedItems = (
  rankedFeedOrCart: RankedFeedSectionMap | undefined
): boolean => {
  return (getRoutedItems(rankedFeedOrCart)?.length || 0) > 0;
};

export const getAllItems = (sections: RankedFeedSectionMap | undefined) => {
  return sections === undefined
    ? []
    : Object.values(sections).flatMap((section) => section.items);
};

export const getRankedFeedIcebreakersSection = (
  icebreakers: RankedIcebreaker[]
): PersonalizationEvent[] => {
  return icebreakers.reduce(
    (acc: PersonalizationEvent[], icebreaker: RankedIcebreaker) => {
      const triggers = icebreaker.item.entity.triggers || [];
      acc.push({
        triggers,
        score: 0,
        type: icebreaker.item.type,
        id: icebreaker.item.entity.id,
        formattedTitle: triggers[0]?.attributes?.title,
        title: triggers[0]?.attributes?.title,
        date: triggers[0]?.attributes?.date,
        source: "",
        summary: triggers[0]?.attributes?.description || "",
        url: triggers[0]?.attributes?.url,
        valueProp: icebreaker.valueProp,
      });
      return acc;
    },
    []
  );
};

export const groupRankedFeedItemsByType = (items: PersonalizationEvent[]) => {
  const groupedItems = items.reduce(
    (accumulator: Record<string, PersonalizationEvent[]>, item) => {
      const itemType = item.type;
      if (accumulator[itemType] === undefined) {
        accumulator[itemType] = [];
      }
      accumulator[itemType].push(item);
      return accumulator;
    },
    {}
  );

  return groupedItems;
};

export const triggerColorMap: Record<string, string> = {};
export const triggerColor = (str: string, idx: number) => {
  if (triggerColorMap[str]) {
    return triggerColorMap[str];
  }
  let color: string;
  switch (str) {
    case "competitive_product":
    case "complementary_product":
      color = LavenderTextColorMap[LavenderTextColorMap.peony];
      break;
    case "mutual_employer":
    case "role_resonance":
    case "seniority_resonance":
      color = LavenderTextColorMap[LavenderTextColorMap.pear];
      break;
    case "employee_leaves_or_retires":
    case "layoffs":
    case "new_openings":
    case "team_expansion":
    case "hiring_trend":
    case "new_employee_hired":
    case "employee_promotion":
    case "new_employment":
    case "promotion":
      color = LavenderTextColorMap[LavenderTextColorMap.sapphire];
      break;
    case "funding_or_raises_capital":
    case "investment":
    case "ipo":
      color = LavenderTextColorMap[LavenderTextColorMap.lavender];
      break;
    default:
      color = UnassignedTriggerColorMap[idx % UnassignedTriggerColorCount];
      break;
  }

  triggerColorMap[str] = color;

  return color;
};
