import { createContext } from "react";

interface FrameworkContextType {
  next: (f: any) => void;
  prev: () => void;
  data?: any;
  frameworkCategory: any;
}

export const FrameworkContext = createContext<FrameworkContextType>({
  data: {},
  next: (_f) => {},
  prev: () => {},
  frameworkCategory: {},
});
