export interface EmailVerificationResponse {
  status: string;
  EmailResults: EmailResult[];
  all_valid: boolean;
  html: string;
  data: null;
}

export enum EmailEvaluation {
  MAYBE = "maybe",
  VALID = "valid",
  INVALID = "invalid",
}

export interface EmailResult {
  Message: string;
  Valid: EmailEvaluation;
  Email: string;
  Index: number;
  PersonalityProfileStatus: string;
}
