import { useCallback, useRef } from "react";
import { SerializedError } from "@reduxjs/toolkit";
import {
  QueryStatus,
  MutationDefinition,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { UseMutation } from "node_modules/@reduxjs/toolkit/dist/query/react/buildHooks";

import { UseMutationBaseQuery } from "~src/api/typings";

interface UseSharedMutationWithAbortParams<T, U = any> {
  mutation: UseMutation<
    MutationDefinition<T, UseMutationBaseQuery, any, U, string>
  >;
  fixedCacheKey?: string;
}

interface UseSharedMutationWithAbortReturn<T, U = any> {
  data: U | (U & undefined) | undefined;
  error: FetchBaseQueryError | SerializedError | undefined;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isUninitialized: boolean;
  requestId: string | undefined;
  status: QueryStatus;
  startedTimeStamp: number | undefined;
  fulfilledTimeStamp?: number | undefined;
  startMutation: (
    mutationArgs: T
  ) => Promise<{ data: U } | { error: FetchBaseQueryError | SerializedError }>;
}

export const useSharedMutationWithAbort = <T, U = any>({
  mutation,
  fixedCacheKey,
}: UseSharedMutationWithAbortParams<T, U>): UseSharedMutationWithAbortReturn<
  T,
  U
> => {
  const [
    triggerMutation,
    {
      data,
      error,
      isError,
      isLoading,
      isSuccess,
      isUninitialized,
      requestId,
      status,
      startedTimeStamp,
      fulfilledTimeStamp,
    },
  ] = mutation({
    fixedCacheKey,
  });
  const abortControllerRef = useRef<AbortController | null>(null);
  const startMutation = useCallback(
    async (mutationArgs: T) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      return await triggerMutation({ ...mutationArgs, signal });
    },
    [triggerMutation]
  );

  return {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
    isUninitialized,
    requestId,
    status,
    startedTimeStamp,
    fulfilledTimeStamp,
    startMutation,
  };
};

/**
 * Custom hook that wraps a RTK Query mutation with an abort controller that allows you to
 * start an abortable mutation with the exported `startMutation` method.
 * It provides the convenience of cancelling the previous request if a new one is made.
 */
