import { BILLING_CYCLE, BILLING_PLANS } from "~src/constants";
import { SorterSegment, Trigger } from "~src/api/typings";

import { NetworkStatus } from "./networkStatus";

export interface UserAccountState {
  settings: UserAccount;
  status: NetworkStatus;
  error: string;
}

interface UserSettings {
  dismiss_salesloft_upgrade_cta: boolean;
  dismiss_outreach_notification: boolean;
  bullet_point_form_filled_out: boolean;
  horizontal_email_assistant: boolean;
  bullet_point_setting: string;
  progressive_onboarding: boolean;
  as_you_write: boolean;
  open_tracking: boolean;
  lavender_anywhere_top: number;
  lavender_everywhere: boolean;
  weekly_email: boolean;
  ignore_from_analytics: boolean;
  performance_mode: boolean;
  include_signature_analysis: boolean;
  outlook_sentence_copy: boolean;
  omit_from_team_lite: boolean;
  google_web_search_tool: boolean;
  temperature_slider?: number;
  value_prop_generation: boolean;
  hide_boost_non_a_email_suggestion: boolean;
}

interface AutoPersonalization {
  completed: string;
  last_used: string;
}

interface CompanyData {
  completed: string;
  last_used: string;
  times_shown: number;
}

interface EaGeneral {
  completed: string;
  last_used: string;
  times_shown: number;
}

interface ExtensionInit {
  completed: string;
  last_used: string;
  times_shown: number;
}

interface FullWalkthrough {
  completed: string;
  last_used: string;
  times_shown: number;
}

interface MobilePreview {
  completed: string;
  last_used: string;
  times_shown: number;
}

interface ProfileLookup {
  completed: string;
  last_used: string;
  times_shown: number;
}

interface Rtr {
  completed: string;
  last_used: string;
  times_shown: number;
}

interface SentenceTransformers {
  completed: string;
  last_used: string;
  times_shown: number;
}

interface Summarizer {
  completed: string;
  last_used: string;
  times_shown: number;
}

interface Onboarding {
  auto_personalization: AutoPersonalization;
  company_data: CompanyData;
  ea_general: EaGeneral;
  extension_init: ExtensionInit;
  full_walkthrough: FullWalkthrough;
  mobile_preview: MobilePreview;
  profile_lookup: ProfileLookup;
  rtr: Rtr;
  sentence_transformers: SentenceTransformers;
  summarizer: Summarizer;
}

export interface PersonBase {
  company: ProfileCompany;
  email: string;
  job: Job;
  timezone?: string;
  // fields below set in Dashboard Profile
  avatar?: string;
  bio?: string;
  location?: string;
}

// ProfileCompany is set in Dashboard Profile but also defined on external users
export interface ProfileCompany {
  category?: string;
  company_name?: string;
  company_bio?: string;
  url?: string;
}

export interface Job {
  company?: string;
  title?: string; // set in Dashboard Profile
}

export interface Person extends PersonBase {
  id: string;
  humantics_id: string;
  emails: string[];
  record_found: boolean;
  last_updated: string;
  apollo_id: string;
  exact_buyer_id: string;
  clearbit_id: string;
  work_history: WorkHistory[] | null;
  // fields below set in Dashboard Profile
  full_name?: string;
  education?: string[];
  school?: string;
  social_medias?: SocialMedia;
  phone_numbers?: PhoneNumber[];
  sorter_segment: SorterSegment | "";
  diffbot_id: string; // LEGACY
  // display_name?: string; // DEPRECATED
}

export interface SocialMedia {
  crunchbase?: string;
  facebook?: string;
  linkedin: string;
  twitter: string;
  website?: string;
}

export interface PhoneNumber {
  raw_number?: string;
  sanitized_number?: string;
  type?: string;
  position?: number;
  status?: string;
  dnc_status?: any;
  dnc_other_info?: any;
}

export interface WorkHistory {
  id: string;
  title: string;
  employer: string;
  location?: string;
  start: number;
  end: number;
  triggers?: Trigger[];
  formattedTitle?: string;
  is_current: boolean;
  apollo_id?: string;
  apollo_organization_id?: string;
  description?: string; //*no longer have this after leaving diffbot
  logo?: string; //*no longer have this after leaving diffbot
}

export interface User {
  id: string;
  uuid: string;
  email: string;
  created: string;
  is_premium: boolean;
  has_stripe: boolean;
  billing_plan: BILLING_PLANS;
  billing_cycle: BILLING_CYCLE;
  trial_upgrade?: {
    billing_plan: BILLING_PLANS;
    billing_cycle: BILLING_CYCLE;
  };
  cycle_start: string;
  signed_in_using: string;
  trial_end: string;
  referral_code: string;
  credits: number;
  email_credits: number;
  beta: boolean;
  delinquent: string;
  grammar_on: boolean;
  settings: UserSettings;
  bp_field: boolean;
  onboarding: Onboarding | EmptyObject;
  person: Person; // same data structure retrieved in /iframe/profile_v2: string; but for external users too
  google_subscription_active: boolean;
  outreach_subscription_active: boolean;
  salesloft_subscription_active: boolean;
  outlook_subscription_active: boolean;
  is_team: boolean;
  // fields below set in Dashboard Profile
  first_name: string;
  last_name: string;
  profile_picture: string;
  streak?: Streak;
}

export interface UserAccount {
  missing_fields: string[] | null;
  status: string;
  user?: User;
}

export interface ToggleUserSettingArgs {
  setting: keyof UserSettings;
  on: boolean;
}

export interface ToggleUserSettingData {
  key: keyof UserSettings;
  value: boolean;
}

export interface ProfileUser {
  avatar: string;
  displayName: string;
  email: string;
}

export interface DayStreak {
  day: string;
  emails_sent: number;
}

export interface Streak {
  emails_sent: number;
  longest_streak: number;
  streak: DayStreak[];
}
