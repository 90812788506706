import { useCallback, useMemo } from "react";

import { useLvIdentifier, useShouldDisplayPaywall } from ".";

import { useAppSelector } from "~src/redux";
import type { VerificationState } from "~src/redux/typings";

import { EmailEvaluation } from "../api/typings";
import { fallbackToEmptyArray } from "~src/utils";

interface IVerification {
  isSeen: (email: string) => boolean;
  isInvalid: (email: string) => boolean;
  isLoading: (email: string) => boolean;
  isUnverified: (email: string) => boolean;
  isValid: (email: string) => boolean;
  recipients: string[] | never[]; // Container specific
  recipientVerifications: VerificationState; // Container specific
  verifications: VerificationState; // Not container specific
}

export const useVerification = (): IVerification => {
  const lvIdentifier: lvIdentifier = useLvIdentifier();
  const recipients = useAppSelector(
    ({ emailData }) =>
      emailData[lvIdentifier]?.toAddress ??
      fallbackToEmptyArray(emailData[lvIdentifier]?.toAddress)
  );
  const verifications = useAppSelector((state) => state.verifications);
  const memoizedRecipients = useMemo(() => recipients, [recipients]);
  const memoizedVerifications = useMemo(() => verifications, [verifications]);
  const shouldDisplayPaywall = useShouldDisplayPaywall("profile");
  const memoizedRecipientVerifications = useMemo(() => {
    const localVerifications: VerificationState = {};

    if (recipients.length === 0 || shouldDisplayPaywall) {
      return localVerifications;
    }

    recipients.forEach((email: string) => {
      if (memoizedVerifications[email]) {
        localVerifications[email] = memoizedVerifications[email];
      }
    });

    return localVerifications;
  }, [recipients, memoizedVerifications, shouldDisplayPaywall]);

  const isLoading = useCallback(
    (email: string) => {
      return !!memoizedVerifications[email]?.loading;
    },
    [memoizedVerifications]
  );

  const isSeen = useCallback(
    (email: string) => {
      return !!memoizedVerifications[email]?.seen;
    },
    [memoizedVerifications]
  );

  const isValid = useCallback(
    (email: string) => {
      return memoizedVerifications[email]?.evaluation === EmailEvaluation.VALID;
    },
    [memoizedVerifications]
  );

  const isInvalid = useCallback(
    (email: string) => {
      return (
        memoizedVerifications[email]?.evaluation === EmailEvaluation.INVALID
      );
    },
    [memoizedVerifications]
  );

  const isUnverified = useCallback(
    (email: string) => {
      return memoizedVerifications[email]?.evaluation === EmailEvaluation.MAYBE;
    },
    [memoizedVerifications]
  );

  return {
    isSeen,
    isLoading,
    isInvalid,
    isUnverified,
    isValid,
    recipients: memoizedRecipients,
    recipientVerifications: memoizedRecipientVerifications,
    verifications: memoizedVerifications,
  };
};
