import { Root } from "react-dom/client";
import { store } from "~src/redux/store";
import { ComposeContainerMetadata } from "~src/redux/typings";
import { modifyApollo, modifyGroove } from "../platformModification";
import { emailAction } from "~src/redux";

export const customDOMModifications = (
  platform: string,
  emailContainer: HTMLElement,
  composeContainerMetadata: ComposeContainerMetadata,
  lvIdentifier: lvIdentifier,
  root: Root
) => {
  switch (platform) {
    case "apollo": {
      if (composeContainerMetadata.container.overwriteContainer) {
        modifyApollo(
          emailContainer,
          composeContainerMetadata.container.containerSelector
        );
      }
      break;
    }
    case "salesloft": {
      emailContainer
        .querySelector('button[aria-label="Minimize"]')
        ?.addEventListener("click", () => {
          store.dispatch(emailAction.deleteEmailData(lvIdentifier));
          [...emailContainer.children].forEach((e) => {
            if (e.classList.contains("lv-panel-root")) {
              e.remove();
            }
          });
          emailContainer.classList.remove("lv-comp-container");
          root.unmount();
          store.dispatch(emailAction.deleteEmailData(lvIdentifier));
        });
      break;
    }
    case "groove": {
      modifyGroove(
        emailContainer,
        composeContainerMetadata,
        lvIdentifier,
        root
      );
      break;
    }
  }
};
