import { store } from "~src/redux/store";
import { configActions, localStorageActions } from "../redux";
import { CRMUserFound, CRMUserNotUpgrade } from "../constants";

export const authenticateTeamsAccount = () => {
  const state = store.getState();
  const { message, chrome_id } = state.config.crmUser;
  const ignoreList = state.localStorage.ignoreList;
  const platform = state.config.settings?.platform;

  if (message === CRMUserFound) {
    store.dispatch(configActions.setChromeId(chrome_id));
    store.dispatch(configActions.setAccountStatus(CRMUserFound));
    if (platform) {
      if (ignoreList.includes(platform)) {
        store.dispatch(localStorageActions.unIgnoreItem(platform));
      }
    }
  } else if (message === CRMUserNotUpgrade) {
    store.dispatch(configActions.setAccountStatus(CRMUserNotUpgrade));
  }
};
