import { Flex, Form } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { css, styled } from "styled-components";

import { Text } from "@lavender-ai/lav-components";

import { FeatureFlagEnum } from "~src/api/typings";
import { IconFrame, LoadMoreButton } from "~src/component/Atoms";
import {
  PanelBlockContainer,
  StyledPanelBlockContainer,
} from "~src/component/Organisms/Personalization/Shared";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
  LoadableText,
} from "~src/component/Utilities";
import { GongContextItemCollapse } from "~src/component/Molecules";
import { CustomField, CustomFieldForm } from "~src/component/Organisms/Forms";
import { getComponentByType } from "~src/component/Organisms/Modals/Shared";
import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import {
  EnhancedFlex,
  PanelBackgroundFlex,
  SecondaryText,
} from "~src/component/Styled";
import { LavenderIcon, PropsForStyling } from "~src/component/typings";
import {
  useLoadMore,
  useRouteToCartModal,
  useStartMyEmail,
  useValuePropGeneration,
  useFeatureFlag,
  useRankedFeedApi,
  useRankedFeedApiDependencies,
  useCRM,
} from "~src/customHooks";
import {
  PanelBlock,
  PanelBlockVariant,
  PanelListContainer,
  PanelListItem,
  StyledPanelListItem,
} from "~src/layout/MainPanel/PanelBlock";
import { useAppDispatch, valuePropsActions } from "~src/redux";
import {
  CustomCategories,
  EmailAnalysisStage as Stage,
} from "~src/redux/typings";
import {
  CART_HEADER,
  SME_CAPTION,
  SME_CTA,
  SME_SUBHEADER,
  SME_TOOLTIP,
} from "~src/strings";
import {
  extractFirstName,
  hasItems,
  icebreakerCount,
  segmentEvent,
  SegmentEventNames,
  SegmentEventLocations,
  getAllItems,
  groupRankedFeedItemsByType,
  hasRoutedItems,
} from "~src/utils";

import { StartMyEmailCTA } from "../../StartMyEmailCTA/StartMyEmailCTA";
import { FullWidthFlex } from "../../Styled/FullWidthFlex";
import { Header } from "./Header/Header";
import { Instructions } from "./Instructions/Instructions";
import { PersonalizationAssistant } from "./PersonalizationAssistant";

const IceBreakersPanelFlex = styled(Flex)<
  PropsForStyling<{ hasFormCTA: boolean; isError: boolean; isLoading: boolean }>
>`
  height: 100%;
  ${StyledPanelBlockContainer} {
    ${({ $isLoading: isLoading }) =>
      isLoading &&
      css`
        width: var(--lv-expanded-panel-size);
        padding: 0 20px;
      `}
  }
  &:has(.lv-icebreakers-cta-tooltip.ant-tooltip-open) {
    .lv-icebreakers-highlightable {
      border: 1px solid var(--color-lavender-200);
      box-shadow: 0px 0px 0px 3px var(--color-lavender-50);
    }

    .lav-components-textarea {
      border: 1px solid var(--color-lavender-400);
      box-shadow: 0 0 0 2px var(--color-lavender-100) !important;
    }
  }

  &:not(:has(.lv-icebreakers-cta-tooltip.ant-tooltip-open)) {
    .lv-icebreakers-highlightable {
      border: 1px solid transparent;
    }
  }

  .lv-generate-emails-cta-container {
    border-top: 1px solid var(--color-neutral-purple-100);
    padding: 16px 0;
    padding-bottom: 0;
    position: sticky;
    bottom: 0;
    // INFO: this is to stop the component from shrinking when the panel is collapsed
    width: var(--lv-expanded-panel-size);
    z-index: 1071;
  }

  .toggle-tooltip--root& {
    min-width: 275px;
  }

  ${FullWidthFlex} {
    padding: 0 20px 16px 20px;

    .lv-panel-list-container {
      padding: 0;
      .ant-skeleton {
        padding: 16px 0;
        .ant-skeleton-paragraph {
          margin: 0;
        }
        .ant-skeleton-header {
          padding-top: 0;
        }
      }
    }
  }

  ${StyledPanelListItem} {
    padding: 0;
  }

  div:has(> .lv-load-more-button) {
    margin: 16px 0 0 0 !important;
  }

  .lv-icebreakers-instructions {
    margin-top: -60px;
  }

  .lv-icebreakers-personalization-assistant {
    ${({ $hasFormCTA: hasFormCTA }) =>
      hasFormCTA &&
      css`
        margin-top: -50px;
        z-index: 1;
      `}
    ${({ $isError: isError }) =>
      isError &&
      css`
        margin-top: -40px;
        z-index: 1;
      `}
  }
`;

export const IceBreakers = () => {
  const [gongToggleOn, setGongToggleOn] = useState(true);
  const [stage, setStage] = useState(Stage.cold);

  const dispatch = useAppDispatch();
  const {
    currentPersonalization: {
      cart,
      profile: { email, name },
    },
  } = useStartMyEmail();

  const { crms } = useCRM();
  const { loading: isGongDataLoading } = crms[email] || {};

  const { areDependenciesLoading } = useRankedFeedApiDependencies(email);
  const {
    data: { alert, cta, rankedFeed },
    isError,
    isLoading,
  } = useRankedFeedApi(email, areDependenciesLoading);

  const { next } = useCarouselContext();
  const { handleRouteToCartModal } = useRouteToCartModal();
  const { isValuePropGenerationOn } = useValuePropGeneration();
  const isRankedFeedLoading = areDependenciesLoading || isLoading || !!alert;
  const displayName = extractFirstName(name || "") || email || "";
  const hasSmeRouting = useFeatureFlag(FeatureFlagEnum.sme_router);
  const hasSmeReply = useFeatureFlag(FeatureFlagEnum.sme_reply);
  const cartCount = icebreakerCount(cart);
  const [form] = Form.useForm<CustomCategories>();
  const categories = Form.useWatch("categories", form);

  const allItems = getAllItems(rankedFeed);
  const { currentItems, loadMore, hasMore } = useLoadMore(allItems);

  const currentItemsByType = useMemo(
    () => groupRankedFeedItemsByType(currentItems),
    [currentItems]
  );

  const renderItems = useMemo(() => {
    if (isRankedFeedLoading) {
      return <Loading />;
    }

    return Object.entries(currentItemsByType)
      .map(([type, items]) => {
        return items.length > 0 ? getComponentByType(type, items) : null;
      })
      .filter((item) => item !== null);
  }, [currentItemsByType, isRankedFeedLoading]);

  const submitForm = useCallback(
    (categories: CustomCategories) => {
      dispatch(
        valuePropsActions.setCurrentCustomValueProps({
          email,
          categories,
        })
      );
      handleRouteToCartModal(categories);
    },
    [dispatch, email, handleRouteToCartModal]
  );

  const onCTAClick = useCallback(() => {
    segmentEvent(
      SegmentEventNames.GenerateValuePropsClicked,
      SegmentEventLocations.StartMyEmail
    );
    form.submit();
  }, [form]);

  useEffect(() => {
    if (!hasSmeRouting || !hasRoutedItems(rankedFeed)) {
      return;
    }
    if (cta) {
      next(ModalType.ProfileForm, {
        openedBy: email,
        cta,
      });
    } else {
      next(ModalType.Cart, {
        openedBy: email,
        phase: CART_HEADER.VALUE_PROPS,
      });
    }
  }, [next, email, hasSmeRouting, cta, rankedFeed]);

  const mainCustomContext = categories?.custom?.main;

  let tooltipText =
    stage === Stage.cold && hasItems(rankedFeed)
      ? SME_TOOLTIP.SELECT_ICEBREAKERS
      : SME_TOOLTIP.ADD_INSTRUCTIONS;

  if (isError || !!cta) {
    tooltipText = "";
  }

  return (
    <IceBreakersPanelFlex
      justify={isError ? undefined : "space-between"}
      vertical
      $hasFormCTA={!!cta}
      $isError={isError}
      $isLoading={isRankedFeedLoading}
    >
      <Header stage={stage} setStage={setStage} />
      <PanelBlockContainer gap="12px">
        {!cta && !isError && stage === Stage.reply && hasSmeReply && (
          <GongContextItemCollapse
            gongToggleOn={gongToggleOn}
            onToggle={setGongToggleOn}
          />
        )}
        {!cta && !isError && (
          <Instructions
            isLoading={isRankedFeedLoading}
            isOptional={hasItems(rankedFeed)}
          >
            <EnhancedFlex gap={12} vertical $isFullWidth>
              <CustomFieldForm form={form} submitForm={submitForm}>
                <CustomField isDisabled={isRankedFeedLoading} />
              </CustomFieldForm>
            </EnhancedFlex>
          </Instructions>
        )}
        {stage === Stage.cold &&
          (isRankedFeedLoading || hasItems(rankedFeed)) && (
            <PanelBlock
              className="lv-icebreakers-highlightable"
              variant={PanelBlockVariant.list}
            >
              <EnhancedFlex gap="12px" $padding="20px 16px">
                <IconFrame
                  color="lavender"
                  isLoading={isRankedFeedLoading}
                  variant={LavenderIcon.IconSparkles}
                />
                <Flex vertical gap="12px">
                  <Text color="dark" size="subtext3">
                    <LoadableText
                      height="10.5px"
                      loading={isRankedFeedLoading}
                      text={SME_SUBHEADER.ICEBREAKERS}
                    />
                  </Text>
                  <SecondaryText size="body1" $light>
                    <LoadableText
                      height="8px"
                      loading={isRankedFeedLoading}
                      text={SME_CAPTION.SELECTED(cartCount)}
                    />
                  </SecondaryText>
                </Flex>
              </EnhancedFlex>
              <FullWidthFlex vertical>
                {renderItems}
                {hasMore && (
                  <LoadMoreButton
                    buttonText={SME_CTA.MORE_SUGGESTIONS}
                    onClick={loadMore}
                  />
                )}
              </FullWidthFlex>
            </PanelBlock>
          )}
        <PersonalizationAssistant displayName={displayName} />
      </PanelBlockContainer>
      {!isRankedFeedLoading && !isError && (
        <PanelBackgroundFlex className="lv-generate-emails-cta-container">
          <StartMyEmailCTA
            count={
              stage === Stage.cold && hasItems(rankedFeed)
                ? cartCount
                : undefined
            }
            isDisabled={
              (cartCount === 0 && !mainCustomContext) || isGongDataLoading
            }
            isToggleDisabled={cartCount === 0}
            onClick={onCTAClick}
            showValuePropGenerationToggle={
              stage === Stage.cold && hasItems(rankedFeed)
                ? isValuePropGenerationOn
                : false
            }
            text={SME_CTA.GENERATE_EMAILS}
            tooltipText={tooltipText}
          />
        </PanelBackgroundFlex>
      )}
    </IceBreakersPanelFlex>
  );
};

const Loading = () => {
  return (
    <PanelListContainer>
      <PanelListItem>
        <CustomSkeleton variant={CustomSkeletonVariant.checkboxListItem} />
      </PanelListItem>
      <PanelListItem>
        <CustomSkeleton variant={CustomSkeletonVariant.checkboxListItem} />
      </PanelListItem>
      <PanelListItem>
        <CustomSkeleton variant={CustomSkeletonVariant.checkboxListItem} />
      </PanelListItem>
    </PanelListContainer>
  );
};
