import { useCallback } from "react";
import { Flex } from "antd";
import { css, styled } from "styled-components";

import { AvatarProps, IconButton, Text } from "@lavender-ai/lav-components";

import { usePersonalization } from "~src/customHooks";
import {
  UserDataAvatar,
  UserDataEntity,
  PersonalizationSubTabs,
} from "~src/component/Molecules";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
  StyledCustomSkeleton,
} from "~src/component/Utilities/Loading";

import {
  CompanySubTab,
  LavenderIcon,
  PersonalizationTab,
  PropsForStyling,
} from "~src/component/typings";
import { usePanelTabContext } from "~src/contexts";
import {
  CLEARBIT_LOGO_URL,
  INIT_PERSONALIZATION_TAB_CONTEXT,
} from "~src/constants";
import { PanelBlock } from "~src/layout/MainPanel/PanelBlock";
import { numericSize } from "~src/component/utils";

interface StyledCompanyBlockHeaderProps {
  dense?: boolean;
  hoverColor?: string;
  isLastItem?: boolean;
  loading: boolean;
  size?: AvatarProps["size"];
}

export const StyledCompanyBlockHeader = styled(Flex)<
  PropsForStyling<StyledCompanyBlockHeaderProps>
>`
  border-bottom: ${({ $isLastItem: isLastItem, $loading: loading }) =>
    !isLastItem && !loading && "1px solid var(--lv-panel-background-color)"};
  padding: ${({ $dense: dense }) => (dense ? "12px 16px" : "16px")};
  ${({ $hoverColor: hoverColor, $loading: loading }) =>
    hoverColor &&
    !loading &&
    `
    &:hover {
      border-radius: var(--radius-md);
      background: ${hoverColor};
      cursor: pointer;
    }
  `}
  text-align: left;
  width: 100%;
  word-break: break-word;

  span.ant-avatar.lav-components-avatar {
    flex-shrink: 0;
  }

  span.lav-components-text {
    flex-shrink: 1;
  }

  ${({ $size: size }) =>
    size === "small" &&
    css`
      ${StyledCustomSkeleton} {
        .ant-skeleton-header {
          padding-top: 0;
          padding-right: 12px;
          .ant-skeleton-avatar-sm {
            width: ${numericSize(size)}px;
            height: ${numericSize(size)}px;
            line-height: ${numericSize(size)}px;
          }
        }
        .ant-skeleton-title {
          height: 9px;
        }
      }
    `}
`;

const CompanyBlockHeader = () => {
  const {
    currentPersonalization: {
      company: { domain, name: companyName, logo },
      profile: { loading },
    },
  } = usePersonalization();
  const { setPanelTabContext } = usePanelTabContext();

  const goTo = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      setPanelTabContext(
        INIT_PERSONALIZATION_TAB_CONTEXT[PersonalizationTab.COMPANY]
      );
    },
    [setPanelTabContext]
  );

  return (
    <StyledCompanyBlockHeader
      align="center"
      gap="small"
      $dense
      $hoverColor="var(--color-base-White)"
      $loading={loading}
      onClick={loading ? undefined : goTo}
    >
      <CustomSkeleton
        loading={loading}
        variant={CustomSkeletonVariant.avatarTitle}
      >
        <UserDataAvatar
          size="extra-small"
          src={logo}
          entity={UserDataEntity.company}
          fallbackSrc={`${CLEARBIT_LOGO_URL}${domain}`}
          usePlaceholderFallback
        />
        {companyName && <Text size="subtext2">{companyName}</Text>}
        {!loading && (
          <IconButton
            className={(CompanySubTab.ABOUT, "button")}
            size="small"
            icon={LavenderIcon.IconChevronRight}
            variant="plain"
          />
        )}
      </CustomSkeleton>
    </StyledCompanyBlockHeader>
  );
};

export const Company = () => {
  return (
    <PanelBlock>
      <CompanyBlockHeader />
      <PersonalizationSubTabs category="company" />
    </PanelBlock>
  );
};
