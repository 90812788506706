import { css, styled } from "styled-components";
import { Tag, TagProps } from "@lavender-ai/lav-components";

import { SKELETON_GRADIENT_MIXIN } from "~src/component/Molecules/Message/styles";
import { LavenderIcon, PropsForStyling } from "~src/component/typings";

interface StyledLoadableTextProps {
  color?: string;
  loading?: boolean;
}

const StyledLoadableTag = styled(Tag)<PropsForStyling<StyledLoadableTextProps>>`
  & {
    ${({ $loading: loading }) =>
      loading &&
      css`
        background-color: transparent !important;
        border-color: transparent;
        border-radius: 15px;
        color: transparent;
        display: inline-block;
        pointer-events: none;
        ${SKELETON_GRADIENT_MIXIN}
      `};

    svg.lav-components-icon.lav-components-tag-prefix-icon > *,
    span {
        ${({ $loading: loading }) =>
          loading &&
          css`
            color: transparent !important;
          `}
  }
`;

interface Props {
  color?: TagProps["color"];
  prefix?: LavenderIcon;
  isLoading?: boolean;
  text: string;
}

export const LoadableTag = ({ color, prefix, isLoading, text }: Props) => (
  <StyledLoadableTag prefix={prefix} color={color} $loading={isLoading}>
    {text}
  </StyledLoadableTag>
);
