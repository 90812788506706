import { isValidEmail } from "./isValidEmail";

export const isValidEmailAddressList = (
  emailAddressList: string[]
): boolean => {
  // INFO: If the email address list is empty, it is valid to clear the To address field
  if (emailAddressList.length === 0) return true;

  for (const emailAddress of emailAddressList) {
    if (!isValidEmail(emailAddress)) {
      return false;
    }
  }

  return true;
};
