import { FC, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { Icon } from "@lavender-ai/lav-components";

import { useAppSelector } from "~src/redux/hooks";
import { LavenderIcon } from "~src/component/typings";

const AddedPaymentMethodWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  margin: auto;
  left: 0;
  right: 0;
  padding: 12px;
  align-items: center;
  border-radius: var(--radius-md-lrg, 12px);
  border: 1px solid var(--color-neutral-purple-200, #e1e0e4);
  background: var(--color-base-White, #fff);
  box-shadow:
    0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;

export const AddedPaymentMethod: FC = () => {
  const user = useAppSelector((state) => state.userAccount.settings.user);
  const hasCard = user?.has_stripe;
  const beginsWithCard = useRef(hasCard);

  const [showAddedPayment, setShowAddedPayment] = useState(false);

  useEffect(() => {
    // Skip the effect if card was already added
    if (hasCard && !beginsWithCard.current) {
      setShowAddedPayment(true);
      const timer = setTimeout(() => {
        setShowAddedPayment(false);
      }, 3000);
      return () => {
        clearTimeout(timer); // Clean up on unmount
      };
    }
  }, [hasCard]);

  if (!showAddedPayment) {
    return null;
  }

  return (
    <AddedPaymentMethodWrapper>
      <Icon
        color="positive"
        variant={LavenderIcon.IconCircleCheckFilled}
        size="small"
        style={{ marginRight: "8px" }}
      />
      Payment method added!
    </AddedPaymentMethodWrapper>
  );
};
