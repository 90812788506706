import { styled } from "styled-components";
import { IconButton } from "@lavender-ai/lav-components";

import EnvelopeSVG from "~src/assets/images/asset_envelope.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: scroll;
  max-height: 100%;
  top: 16px;
  left: 16px;
  border-radius: var(--radius-lrg, 16px);
  background: var(--color-base-White, #fff);
`;

export const LargePane = styled.div`
  display: flex;
  padding: 8px;
  max-width: 327px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: var(--radius-md-lrg, 12px);
  background: var(--color-base-White, #fff);
`;

export const Header = styled.div`
  justify-content: center;
  display: flex;
  height: 185px;
  align-self: stretch;
`;

export const HeaderGradient = styled.svg`
  width: 100%;
  height: 92px;
  flex-shrink: 0;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const HeaderContent = styled.div`
  position: absolute;
  width: 257px;
  height: 181px;
  flex-shrink: 0;
  top: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const EnvelopeImage = styled.div`
  width: 64px;
  margin-top: 16px;
  height: 64px;
  flex-shrink: 0;
  background-image: url(${EnvelopeSVG});
  background-size: cover;
`;

export const HeaderTextContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const Body = styled.div`
  display: flex;
  width: calc(100% - 16px);
  padding: 0px 16px 20px 16px;
  flex-direction: column;
  align-items: center;
  gap: var(--size-32, 32px);
`;

export const PlanPicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const PlanTermContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const PlanTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const PlanRow = styled.div`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--color-base-White, #fff);
  border: 1px solid var(--color-neutral-purple-100, #e9ebf2);
`;

export const PlanTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

export const PlanTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

export const PlanCostContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: var(--size-2, 2px);
`;

export const PlanDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const PlanDetailsTable = styled.div`
  display: flex;
  padding: 16px 12px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--radius-md-lrg, 12px);
  border: 1px solid var(--color-neutral-purple-100, #e9ebf2);
  margin-bottom: 65px;
`;

export const PlanDetailsRow = styled.div`
  display: flex;
  height: fit-content;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

export const CTAContainer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  width: calc(343px - 32px);
  padding: 12px 16px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-top: 1px solid var(--color-neutral-purple-100, #e9ebf2);
  background: var(--color-base-White, #fff);
`;

export const Teams10Features = styled.div`
  display: flex;
  align-items: start;
  padding-top: 16px;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
`;
