import { useCallback } from "react";
import { ConfigProvider, Flex } from "antd";
import { styled } from "styled-components";

import { Avatar, Text } from "@lavender-ai/lav-components";

import { FeatureFlagEnum } from "~src/api/typings";
import { ExtensionButton, SVGHeader } from "~src/component/Atoms";
import {
  StageDropdown,
  UserDataAvatar,
  UserDataEntity,
} from "~src/component/Molecules";
import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import {
  FullWidthFlex,
  SecondaryText,
  StyledDropdownContainer,
} from "~src/component/Styled";
import { PropsForStyling } from "~src/component/typings";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
} from "~src/component/Utilities/Loading";
import { CLEARBIT_LOGO_URL } from "~src/constants";
import {
  useStartMyEmail,
  useRankedFeedApi,
  useRankedFeedApiDependencies,
  useFeatureFlag,
} from "~src/customHooks";
import { EmailAnalysisStage as Stage } from "~src/redux/typings";
import { SME_CAPTION, SME_HEADER } from "~src/strings";
import { icebreakerCount } from "~src/utils";

interface HeaderTextProps {
  count: number;
  loading: boolean;
  onClick?: () => void;
}

const StyledHeaderTextWrapper = styled.div`
  button.ant-btn.lav-components-button-variant-primary {
    background-color: var(--color-lavender-400);
    border: var(--color-lavender-400);
  }
  button.ant-btn.lav-components-button-variant-primary:not(
      .ant-btn-disabled
    ):not(.lav-components-button-state-focus):not(
      .lav-components-button-state-pressed
    ):hover {
    background-color: var(--color-lavender-300) !important;
    border-color: var(--color-lavender-300) !important;
  }
  button.ant-btn.lav-components-button-variant-primary:not(
      .ant-btn-disabled
    ).lav-components-button-state-pressed {
    background-color: var(--color-lavender-500) !important;
    border: var(--color-lavender-500) !important;
  }

  .ant-skeleton-paragraph {
    margin-top: 16px !important;
    margin-bottom: 0px;
  }
`;

const HeaderText = ({
  count,
  loading,
  onClick,
}: HeaderTextProps) => {
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = useStartMyEmail();
  const { areDependenciesLoading } = useRankedFeedApiDependencies(email);
  const {
    data: { header, cta, caption },
    isError,
  } = useRankedFeedApi(email, areDependenciesLoading);
  const errorMessage = header || SME_CAPTION.WE_COULDNT_FIND;

  return (
    <StyledHeaderTextWrapper
      className="lv-icebreakers-header-text"
      data-testid="lv-icebreakers-header-text"
    >
      <ConfigProvider
        theme={{
          components: {
            Skeleton: {
              borderRadiusXS: 30,
              colorFillContent: "var(--color-lavender-400)",
              controlHeightXS: 10,
            },
          },
        }}
      >
        <CustomSkeleton
          loading={loading}
          variant={CustomSkeletonVariant.simpleListItem}
        >
          <Flex gap="middle" vertical>
            {count > 0 ? (
              <>
                <Text size="subtext5">
                  <span style={{ color: "var(--color-base-White)" }}>
                    {SME_HEADER.RELEVANT_ICEBREAKERS(count)}{" "}
                  </span>
                  <span style={{ color: "var(--color-lavender-300)" }}>
                    {SME_HEADER.FOUND_TO_KICKOFF}
                  </span>
                </Text>
              </>
            ) : (
              <>
                <Text
                  style={{ color: "var(--color-lavender-300)" }}
                  size="subtext5"
                >
                  {errorMessage}
                </Text>
                {(caption || isError) && (
                  <SecondaryText
                    style={{ color: "var(--color-base-White)" }}
                    size="body1"
                  >
                    {caption || SME_HEADER.USE_THE_PERSONALIZATION}
                  </SecondaryText>
                )}
                {cta && (
                  <ExtensionButton
                    onClick={onClick}
                    size="medium"
                    text={cta}
                    variant="primary"
                  />
                )}
              </>
            )}
          </Flex>
        </CustomSkeleton>
      </ConfigProvider>
    </StyledHeaderTextWrapper>
  );
};

const StyledHeaderWrapper = styled(Flex)`
  .lv-icebreakers-header-text {
    padding: 0 32px 28px 32px;
    text-align: left;
    word-break: break-word;
  }
`;

interface StyledHeaderProps {
  isLoading: boolean;
}

const StyledHeader = styled(Flex)<PropsForStyling<StyledHeaderProps>>`
  padding: 40px 32px 28px 32px;

  ${StyledDropdownContainer} {
    align-items: center;
    justify-content: center;
  }
`;

interface Props {
  children?: JSX.Element;
  stage: Stage;
  setStage: (stage: Stage) => void;
}

export const Header = ({ children, stage, setStage }: Props) => {
  const smeReplyOn = useFeatureFlag(FeatureFlagEnum.sme_reply);
  const {
    currentPersonalization: {
      company: { domain, logo },
      profile: { avatar, loading: profileLoading, email },
    },
  } = useStartMyEmail();

  const { areDependenciesLoading } = useRankedFeedApiDependencies(email);

  const {
    data: { alert, cta, rankedFeed },
    isLoading,
  } = useRankedFeedApi(email, areDependenciesLoading);
  const { next } = useCarouselContext();

  const isRankedFeedLoading = areDependenciesLoading || isLoading || !!alert;
  const count = icebreakerCount(rankedFeed);

  const onClick = useCallback(() => {
    next(ModalType.ProfileForm, {
      openedBy: email,
      cta,
    });
  }, [cta, email, next]);

  const onStageSelect = useCallback(
    ({ key }: { key: string }) => {
      setStage(Stage[key]);
    },
    [setStage]
  );

  return (
    <SVGHeader>
      <StyledHeaderWrapper gap={0} vertical>
        <StyledHeader align="center" gap="small">
          <FullWidthFlex>
            <ConfigProvider
              theme={{
                components: {
                  Skeleton: {
                    colorFillContent: "var(--color-lavender-700)",
                  },
                },
              }}
            >
              <Avatar.Group>
                <UserDataAvatar
                  loading={profileLoading}
                  size="large"
                  src={avatar}
                  usePlaceholderFallback
                />
                <UserDataAvatar
                  entity={UserDataEntity.company}
                  loading={profileLoading}
                  fallbackSrc={`${CLEARBIT_LOGO_URL}${domain}`}
                  size="large"
                  src={logo}
                  usePlaceholderFallback
                />
              </Avatar.Group>
            </ConfigProvider>
            {!cta && smeReplyOn && (
              <StageDropdown
                stage={stage}
                onSelect={onStageSelect}
                placement="bottomLeft"
                variant="plain"
              />
            )}
          </FullWidthFlex>
        </StyledHeader>
        {(cta || stage === Stage.cold) && (
          <HeaderText
            count={count}
            onClick={onClick}
            loading={isRankedFeedLoading}
          />
        )}
        {children}
      </StyledHeaderWrapper>
    </SVGHeader>
  );
};
