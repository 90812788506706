import { styled } from "styled-components";

import { PanelLayout } from "~src/layout/MainPanel/Panel/PanelLayout/PanelLayout";

import { PersonalizationPanelContainer } from "~src/component/Organisms";

export const StyledStartMyEmailModalLayout = styled(PanelLayout)`
  ${PersonalizationPanelContainer} {
    &:not(.lv-generate-emails-cta-container) {
      padding: 0 20px;
      text-align: left;
    }
  }

  .lv-generate-emails-cta-container {
    border-top: 1px solid var(--color-neutral-purple-100);
    padding: 16px 20px;
    position: sticky;
    bottom: 0;
    // INFO: this is to stop the component from shrinking when the panel is collapsed
    width: var(--lv-expanded-panel-size);
    z-index: 1071;
  }
`;
