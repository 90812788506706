import { FeatureFlagEnum } from "~src/api/typings/featureFlag";

import { useFeatureFlag, usePersonalization } from "./";

interface IUseStartMyEmail
  extends Required<
    Pick<ReturnType<typeof usePersonalization>, "currentPersonalization">
  > {
  isWebsocketsOn: boolean;
}

export const useStartMyEmail = (): IUseStartMyEmail => {
  const isWebsocketsOn = useFeatureFlag(FeatureFlagEnum.websockets);
  const { currentPersonalization } = usePersonalization();
  return {
    currentPersonalization,
    isWebsocketsOn,
  };
};

/**
 * Encapsulates shared logic for the whole panel. Exists solely for the purpose of easier refactors and deriving state
 * from other places in addition to start my email context.
 **/
