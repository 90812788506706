import { useCallback, useEffect, useState } from "react";
import { Button, Tooltip } from "@lavender-ai/lav-components";
import { BUTTON_TEXT } from "../../strings";
import { insertToTextfield } from "../../utils/dom";
import { LavenderIcon } from "../typings";
import { useDomNodes } from "~src/customHooks";

const { INSERT } = BUTTON_TEXT;

interface Props {
  textToInsert: string;
  recommendationId: string;
}

export const InsertButton = ({ textToInsert, recommendationId }: Props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { emailBody } = useDomNodes();

  const insertAction = useCallback(() => {
    if (emailBody instanceof HTMLElement) {
      insertToTextfield(emailBody, textToInsert, recommendationId);
      setTooltipOpen(true);
    }
  }, [textToInsert, recommendationId, emailBody]);

  useEffect(() => {
    if (tooltipOpen) {
      const timer = setTimeout(() => {
        setTooltipOpen(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [tooltipOpen]);

  return (
    <Tooltip
      hideArrow
      title="Inserted!"
      open={tooltipOpen}
      getPopupContainer={(trigger: HTMLElement) => trigger}
    >
      <Button
        prefix={LavenderIcon.IconPencil}
        size="small"
        variant="secondary"
        onClick={insertAction}
      >
        {INSERT}
      </Button>
    </Tooltip>
  );
};
