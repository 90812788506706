import { Flex } from "antd";
import { styled } from "styled-components";

import { StyledDropdownContainer } from "~src/component/Styled";

export const CoachHeader = styled(Flex)<{ $dark: boolean }>`
  display: flex;
  width: 335px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  background: ${({ $dark }) =>
    $dark
      ? `linear-gradient(
    137deg,
    #150a27 36.94%,
    #3a1479 63.75%,
    #4b189d 78.48%,
    #8713b1 95.86%
  )`
      : "white"};

  ${StyledDropdownContainer} {
    align-items: end;
    justify-content: flex-end;
  }
`;
