import { styled } from "styled-components";
import { DailyStreakCard } from "./DailyStreakCard";
import { StreakActivityCard } from "./StreakActivityCard";

export const StreakCards = () => {
  return (
    <Container>
      <DailyStreakCard />
      <StreakActivityCard />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 351px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 32px auto 0;
`;
