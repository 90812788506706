import { HEADERS, networkRequest, URLS } from "~src/api";
import { ReadTheRoomAnalysis } from "~src/redux/typings";
import { EmailSummaryPayload } from "./typings";
import { AxiosResponse } from "axios";

export const postReadTheRoom = async (
  chromeId: chromeId,
  body: string,
  subject: string,
  author_email: string,
  author_name: string,
  time_sent: number,
  recipients: string,
  emails_before: string
) => {
  return await networkRequest.post<{
    analysis: ReadTheRoomAnalysis | Record<string, unknown>;
    error: string;
  }>(URLS.readTheRoom, {
    chrome_id: chromeId,
    body,
    subject,
    author_email,
    author_name,
    time_sent,
    recipients,
    emails_before,
  });
};
export const postEmailSummary = async (chromeId: chromeId, body: string) => {
  return await networkRequest.post<
    string,
    AxiosResponse<string>,
    EmailSummaryPayload
  >(
    URLS.emailSummary,
    {
      chrome_id: chromeId,
      body,
    },
    {
      headers: {
        "Content-Type": HEADERS.applicationJson,
      },
      responseType: "stream",
    }
  );
};
