import { ComponentProps, Dispatch, SetStateAction } from "react";
import { Checkbox, Tag, Text } from "@lavender-ai/lav-components";

import { BILLING_CYCLE } from "~src/constants";
import { selectBillingPlans, useAppSelector } from "~src/redux";
import { SELECTABLE_PLANS } from "~src/contexts";

import {
  PlanRow as StyledPlanRow,
  PlanTitleContainer,
  PlanTitle,
  PlanCostContainer,
} from "./SelectUpgradePlanModal.styled";

interface Props {
  plan: SELECTABLE_PLANS;
  selectedPlan: SELECTABLE_PLANS | undefined;
  setSelectedPlan: Dispatch<SetStateAction<SELECTABLE_PLANS | undefined>>;
  selectedBillingCycle: BILLING_CYCLE;
}

const PLAN_TAGS: Record<
  SELECTABLE_PLANS,
  { text: string; color: ComponentProps<typeof Tag>["color"] }
> = {
  Starter: { color: "pear", text: "For individuals" },
  Pro: { color: "lavender", text: "Unlimited everything" },
  Teams: { color: "sapphire", text: "Benefits for 10+ seats" },
};

export const PlanRow = ({
  plan,
  setSelectedPlan,
  selectedPlan,
  selectedBillingCycle,
}: Props) => {
  const billingPlans = useAppSelector(selectBillingPlans);
  const isSelected = selectedPlan === plan;

  return (
    <StyledPlanRow
      onClick={() => {
        setSelectedPlan(isSelected ? undefined : plan);
      }}
      style={
        isSelected
          ? {
              border: "1px solid var(--color-primary-lavender, #6a2be9)",
              boxShadow: "0px 0px 0px 3px var(--color-lavender-50, #ece2f9)",
            }
          : undefined
      }
    >
      <PlanTitleContainer>
        <Checkbox checked={isSelected} size="small" radius="md" />
        <PlanTitle>
          <Text size="subtext3">{plan}</Text>
          <Tag color={PLAN_TAGS[plan].color} size="small">
            {PLAN_TAGS[plan].text}
          </Tag>
        </PlanTitle>
      </PlanTitleContainer>
      <PlanCostContainer>
        {plan === SELECTABLE_PLANS.Teams ? (
          <Text size="subtext3">Custom</Text>
        ) : (
          <>
            <Text size="subtext3">
              ${billingPlans[plan][selectedBillingCycle]}
            </Text>
            <Text
              size="body1"
              style={{ color: "var(--color-neutral-purple-600)" }}
            >
              {" "}
              /{selectedBillingCycle === BILLING_CYCLE.monthly ? "mo" : "yr"}.
            </Text>
          </>
        )}
      </PlanCostContainer>
    </StyledPlanRow>
  );
};
