import { Flex } from "antd";
import styled from "styled-components";

export const CategoryHeader = styled(Flex)`
  padding: 20px 16px;
  align-items: center;
`;
export const CoachTags = styled.div`
  padding: 0 16px;
  > span {
    margin-bottom: 8px;
    cursor: pointer;
    user-select: none;
  }
`;
export const CoachTagsDescription = styled(Flex)`
  padding: 16px 16px 24px 16px;
`;
