import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { snakeCase as snakeCaseKeys } from "change-case/keys";

import { APIEndpoints } from "~src/api";
import {
  APIResponseStatus,
  GenerateValuePropsPayload,
  UpsertValuePropsPayload,
  UpsertValuePropsResponse,
  ValuePropsResponse,
} from "~src/api/typings";
import { ErrorIdentifiers, parseAndLogError } from "~src/logger";

import { VALUE_PROP_FORM } from "~src/strings";
import { isLavenderAPIError } from "~src/typeGuards";

export const valuePropsApiSlice = createApi({
  reducerPath: "valuePropsApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.BACKEND_API}/api/` }),
  endpoints: (builder) => ({
    generateValueProps: builder.mutation<
      ValuePropsResponse,
      GenerateValuePropsPayload
    >({
      query: ({ signal, ...payload }) => {
        const transformRequestBody = (
          originalPayload: Omit<GenerateValuePropsPayload, "signal">
        ) => {
          return JSON.stringify(snakeCaseKeys(originalPayload));
        };

        const transformedPayload = transformRequestBody(payload);

        return {
          url: APIEndpoints.valueProps,
          method: "POST",
          body: transformedPayload,
          signal,
        };
      },
    }),
    upsertValueProps: builder.mutation<
      UpsertValuePropsResponse,
      UpsertValuePropsPayload
    >({
      query: (payload) => {
        const transformRequestBody = (
          originalPayload: UpsertValuePropsPayload
        ) => {
          return JSON.stringify(snakeCaseKeys(originalPayload));
        };

        const transformedPayload = transformRequestBody(payload);

        return {
          url: APIEndpoints.valueProps,
          method: "PUT",
          body: transformedPayload,
        };
      },
      transformErrorResponse: (
        response,
        meta,
        arg
      ): UpsertValuePropsResponse => {
        parseAndLogError(response, ErrorIdentifiers.API_ERROR, {
          email: arg.email,
          method: meta?.request.method,
          url: meta?.request.url,
        });
        return isLavenderAPIError(response?.data)
          ? response?.data
          : {
              message: VALUE_PROP_FORM.FALLBACK_ERROR,
              status: APIResponseStatus.error,
              error: "500",
            };
      },
    }),
  }),
});

export const { useGenerateValuePropsMutation, useUpsertValuePropsMutation } =
  valuePropsApiSlice;

export const valuePropsApiReducer = valuePropsApiSlice.reducer;
