import { PersonalizationSubTabs } from "~src/component/Molecules";
import { CompanySubTab, type PersonalizationTab } from "~src/component/typings";
import { usePanelTabContext } from "~src/contexts";
import { PanelBlock } from "~src/layout/MainPanel/PanelBlock";

import { PanelBlockContainer } from "../../Shared";
import { Funding } from "../../../Funding";
import { TechStack } from "../../../TechStack";
import { JobOpenings } from "./JobOpenings";
import { SocialFeed } from "./SocialFeed";
import { NewsAndEvents, CompanySummaryBlock } from ".";

export const PersonalizationCompany = () => {
  const { subTab } = usePanelTabContext<PersonalizationTab, CompanySubTab>();

  return (
    <PanelBlockContainer>
      <PanelBlock>
        <PersonalizationSubTabs category="company" />
      </PanelBlock>
      <PanelBlock>
        {subTab === CompanySubTab.ABOUT && <CompanySummaryBlock />}
        {subTab === CompanySubTab.NEWS && <NewsAndEvents />}
        {subTab === CompanySubTab.JOBS && <JobOpenings />}
        {subTab === CompanySubTab.TECH_STACK && <TechStack />}
        {subTab === CompanySubTab.FUNDING && <Funding />}
        {subTab === CompanySubTab.COMPANY_SOCIAL_FEED && (
          <SocialFeed source="company" />
        )}
      </PanelBlock>
    </PanelBlockContainer>
  );
};
