import { Button, Text, Tooltip } from "@lavender-ai/lav-components";

import { LavenderIcon } from "~src/component/typings";
import {
  selectEmailAnalysis,
  selectPlatform,
  useAppSelector,
  useMainPanelView,
  usePanelIsOpen,
} from "~src/redux";
import { FlexMenuItem, ScoreBadge } from "../Menu.styled";
import { useLvIdentifier } from "~src/customHooks";
import { NetworkStatus } from "~src/redux/typings";
import { useCallback } from "react";
import { MIN_EMAIL_BODY_WORD_COUNT } from "~src/constants";
import { getBodyLength, getWritingData } from "../../EmailCoachPanel/utils";
import { EmailCoachView, useEmailCoachCarouselContext } from "~src/contexts";
import { Flex } from "antd";
import { Spinner, EmailScore } from "~src/component/Lottie";
import {
  segmentEvent,
  SegmentEventNames,
  SegmentEventLocations,
} from "~src/utils";
import { useShowPanel } from "../utils";

export const EmailCoachButton = () => {
  const lvIdentifier = useLvIdentifier();
  const platform = useAppSelector(selectPlatform);
  const { settings } = useAppSelector(({ userAccount }) => userAccount);
  const emailCoachData = useAppSelector(selectEmailAnalysis(lvIdentifier));
  const writingData = getWritingData(lvIdentifier);
  const bodyLength = getBodyLength(writingData.body);
  const hasEnoughBody = bodyLength >= MIN_EMAIL_BODY_WORD_COUNT;
  const { view: emailCoachView, prev: prevEmailCoach } =
    useEmailCoachCarouselContext();
  const view = useMainPanelView();
  const showPanel = useShowPanel();
  const isOpen = usePanelIsOpen();

  const emailCoachBadgeClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (view === "emailCoach" && emailCoachView === EmailCoachView.fixItAll) {
        prevEmailCoach();
        segmentEvent(
          SegmentEventNames.EmailCoachClickedOpen,
          SegmentEventLocations.ExtensionMainMenu
        );
      } else {
        showPanel(e, "emailCoach");
      }
    },
    [emailCoachView, prevEmailCoach, showPanel, view]
  );

  return (
    <FlexMenuItem>
      <Tooltip
        hideArrow
        placement={platform === "outlookNative" ? "bottom" : "right"}
        title="Email Coach"
      >
        {(settings.user === undefined ||
          (emailCoachData?.status === NetworkStatus.idle && !hasEnoughBody) ||
          emailCoachData?.status === NetworkStatus.failed) && (
          <Button
            onClick={emailCoachBadgeClick}
            radius="md"
            size="large"
            state={
              view === "emailCoach" && isOpen === "open" ? "focus" : "initial"
            }
            icon={
              view === "emailCoach" &&
              isOpen === "open" &&
              platform !== "outlookNative"
                ? LavenderIcon.IconChevronsRight
                : LavenderIcon.IconBeaker
            }
            variant="plain"
          />
        )}
        {((emailCoachData?.status === NetworkStatus.idle && hasEnoughBody) ||
          emailCoachData?.status === NetworkStatus.loading) && (
          <Flex onClick={emailCoachBadgeClick}>
            <Spinner width={40} height={40} />
          </Flex>
        )}
        {emailCoachData?.status === NetworkStatus.success && (
          <ScoreBadge onClick={emailCoachBadgeClick}>
            <EmailScore
              width={40}
              height={40}
              score={emailCoachData?.analysis?.dynamic_ceq || 0}
            />
            <Text color="black" size="subtext3">
              {emailCoachData?.analysis?.dynamic_ceq < 50
                ? "!"
                : emailCoachData?.analysis?.dynamic_ceq}
            </Text>
          </ScoreBadge>
        )}
      </Tooltip>
    </FlexMenuItem>
  );
};
