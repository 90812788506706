import { RankedIcebreaker, RankedItem } from "~src/api/typings";
import { UnassignedTriggerColorCount } from "~src/component/constants";
import {
  LavenderTextColorMap,
  UnassignedTriggerColorMap,
} from "~src/component/typings";
import { RANKED_FEED_TYPES } from "~src/constants";
import { PersonalizationRankedData } from "~src/redux/typings";

export const icebreakerCount = (
  rankedFeedOrCart: PersonalizationRankedData
): number =>
  Object.keys(rankedFeedOrCart).reduce(
    (acc, key) => acc + (rankedFeedOrCart[key].items?.length || 0),
    0
  );

export const hasItems = (
  rankedFeedOrCart: PersonalizationRankedData
): boolean => {
  return icebreakerCount(rankedFeedOrCart) > 0;
};

export const triggerColorMap: Record<string, string> = {};
export const triggerColor = (str: string, idx: number) => {
  if (triggerColorMap[str]) {
    return triggerColorMap[str];
  }
  let color: string;
  switch (str) {
    case "competitive_product":
    case "complementary_product":
      color = LavenderTextColorMap[LavenderTextColorMap.peony];
      break;
    case "mutual_employer":
    case "role_resonance":
    case "seniority_resonance":
      color = LavenderTextColorMap[LavenderTextColorMap.pear];
      break;
    case "employee_leaves_or_retires":
    case "layoffs":
    case "new_openings":
    case "team_expansion":
    case "hiring_trend":
    case "new_employee_hired":
    case "employee_promotion":
    case "new_employment":
    case "promotion":
      color = LavenderTextColorMap[LavenderTextColorMap.sapphire];
      break;
    case "funding_or_raises_capital":
    case "investment":
    case "ipo":
      color = LavenderTextColorMap[LavenderTextColorMap.lavender];
      break;
    default:
      color = UnassignedTriggerColorMap[idx % UnassignedTriggerColorCount];
      break;
  }

  triggerColorMap[str] = color;

  return color;
};

export const getRankedFeedIcebreakersSection = (
  items: RankedIcebreaker[]
): RankedItem[] => {
  return items.reduce((acc: RankedItem[], item: RankedIcebreaker) => {
    const triggers = item.item.Entity.triggers || [];
    acc.push({
      triggers,
      score: 0,
      type: item.item.Type,
      id: item.item.Entity.id,
      formattedTitle: triggers[0]?.attributes?.title,
      title: triggers[0]?.attributes?.title,
      date: triggers[0]?.attributes?.date,
      source: "",
      summary: triggers[0]?.attributes?.description || "",
      url: triggers[0]?.attributes?.url,
    });
    return acc;
  }, []);
};
export const getAllRankfedFeedItems = (
  rankedFeed: PersonalizationRankedData
) => {
  return RANKED_FEED_TYPES.map((type) => rankedFeed[type].items).flat();
};
