import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import * as askChatApi from "~src/api/typings/askChat";

import { Chat, SetLoadingChatArgs, NetworkStatus } from "~src/redux/typings";
import { CHAT_GPT_ERRORS } from "~src/strings";
import { newChatMessage } from "~src/utils";

import { chat, run } from "./";

const { FALLBACK_ASK_CHAT } = CHAT_GPT_ERRORS;

export type ChatsState = Record<lvIdentifier, Chat | undefined>;

export const INIT_CHATS_STATE: ChatsState = {};

export const chatsSlice = createSlice({
  initialState: INIT_CHATS_STATE,
  name: "chats",
  reducers: {
    addAssistantMessage: (
      state,
      {
        payload: { content, query, lvIdentifier },
      }: PayloadAction<
        askChatApi.AskChatSuccessResponse & { lvIdentifier: lvIdentifier }
      >
    ) => {
      if (
        content &&
        state[lvIdentifier]?.messages &&
        state[lvIdentifier]?.status === NetworkStatus.loading
      ) {
        (state[lvIdentifier] as Chat).messages.push(
          newChatMessage(askChatApi.ChatRole.Assistant, content, query)
        );
      }
    },
    resetChat: (
      state,
      { payload: { lvIdentifier } }: PayloadAction<{ lvIdentifier: string }>
    ) => {
      state[lvIdentifier] = undefined;
    },
    setLoading: (
      state,
      { payload: { lvIdentifier } }: PayloadAction<SetLoadingChatArgs>
    ) => {
      if (state[lvIdentifier] !== undefined) {
        (state[lvIdentifier] as Chat).status = NetworkStatus.loading;
      }
    },
    finishLoading: (
      state,
      { payload: { lvIdentifier } }: PayloadAction<SetLoadingChatArgs>
    ) => {
      if (state[lvIdentifier] !== undefined) {
        (state[lvIdentifier] as Chat).status = NetworkStatus.idle;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(chat.fulfilled, (state, { payload, meta: { arg } }) => {
      if (!payload) {
        return;
      }
      const { chatThreadId, messages } = payload;
      state[arg.lvIdentifier] = {
        chatThreadId,
        status: NetworkStatus.loading,
        messages,
      };
    });
    builder.addCase(run.rejected, (state, { error, meta: { arg } }) => {
      (state[arg.lvIdentifier] as Chat).error =
        error.message || FALLBACK_ASK_CHAT;
      (state[arg.lvIdentifier] as Chat).status = NetworkStatus.failed;
    });
    builder.addCase(run.fulfilled, (state, { payload, meta: { arg } }) => {
      if (!payload) {
        return;
      }
      const { score, recs } = payload;
      if (
        score !== undefined &&
        state[arg.lvIdentifier]?.status === NetworkStatus.loading
      ) {
        (state[arg.lvIdentifier] as Chat).recs = recs;
        (state[arg.lvIdentifier] as Chat).score = score;
        (state[arg.lvIdentifier] as Chat).status = NetworkStatus.success;
      }
    });
  },
});

export const chatsReducer = chatsSlice.reducer;
export const chatsAction = chatsSlice.actions;
