import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  z-index: 9999;
  display: flex;
  width: 287px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: var(--radius-md-lrg, 12px);
  border: 1px solid var(--color-neutral-purple-200, #dee1e8);
  background: var(--color-base-White, #fff);
  box-shadow:
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
`;

export const TextContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  white-space: pre-wrap;
  align-self: stretch;
`;

export const TextPrefix = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const TextContainer = styled.div`
  display: flex;
  padding-top: var(--size-2, 2px);
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 12px;
  flex: 1 0 0;
`;

export const TextDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

export const CTAContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  row-gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`;

export const CTAButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
`;
