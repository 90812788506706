export const setupHotReload = () => {
  // only run the hot reload for development
  if (process.env.NODE_ENV === "dev" && process.env.LIVE_RELOAD !== "false") {
    // hot reload for development
    const ws = new WebSocket("wss://localhost:8080");
    ws.onmessage = (event) => {
      if (`${event.data}` === "reload") {
        chrome.runtime
          .sendMessage({ action: "reload", view: "coming-soon!" })
          .catch(console.error);
      }
    };
  }
};
