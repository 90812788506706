import { useState } from "react";
import { Image } from "antd";

import { Avatar, AvatarProps, Text } from "@lavender-ai/lav-components";

/**
 * Avatar that allows providing a fallback image. If both fail, it is not rendered
 */

interface Props {
  alt: string;
  fallback: string;
  src: string;
  fallbackLetter?: string; // Letter to show if image fails to load
  size?: AvatarProps["size"];
  style?: React.CSSProperties;
}

export const ImageFallbackAvatar = ({
  alt,
  fallback,
  fallbackLetter,
  src,
  size,
  style,
}: Props) => {
  const [showImage, setShowImage] = useState(true);

  if (!showImage) {
    if (fallbackLetter) {
      return (
        <Avatar color="lavender" size={size} style={style}>
          <Text>{fallbackLetter}</Text>
        </Avatar>
      );
    }
    return null;
  }

  return (
    <Avatar
      alt={alt}
      className="ant-avatar-image"
      icon={
        <Image
          alt={alt}
          width={size}
          height={size}
          onError={() => {
            setShowImage(false);
          }}
          preview={false}
          src={fallback}
        />
      }
      size={size}
      src={src}
      style={style}
    />
  );
};
