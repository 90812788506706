import { Text } from "@lavender-ai/lav-components";

import { useUpgradePlanModalContext } from "~src/contexts";
import { BILLING_CYCLE } from "~src/constants";
import { useAppSelector, selectBillingPlans } from "~src/redux";

import {
  CardInputContent,
  CloseButton,
  Container,
  Content,
  Header,
  HeaderContainer,
  HeaderCost,
  HeaderPlan,
} from "./UpgradePaymentModal.styled";
import { StripeIframe } from "../../Billing";

export const UpgradePaymentModal = () => {
  const billingPlans = useAppSelector(selectBillingPlans);
  const { closeModal, selectedPlan } = useUpgradePlanModalContext();

  if (!selectedPlan) return;

  const { cycle, plan } = selectedPlan;
  const cost = billingPlans[plan][cycle];

  return (
    <Container>
      <Content>
        <HeaderContainer>
          <Header>
            <Text size="body1" color="lavender">
              Upgrade Plan
            </Text>
            <HeaderPlan>
              <Text size="subtext4" style={{ flex: "1 0 0" }}>
                Lavender {selectedPlan.plan}
              </Text>
              <HeaderCost>
                <Text size="subtext3">${cost}</Text>
                <Text size="subtext3">
                  {" "}
                  <Text
                    size="body2"
                    style={{ color: "var(--color-neutral-purple-600)" }}
                  >
                    /{cycle === BILLING_CYCLE.monthly ? "mo" : "yr"}.
                  </Text>
                </Text>
              </HeaderCost>
            </HeaderPlan>
            <Text
              size="body1"
              style={{
                alignSelf: "stretch",
                color: "var(--color-neutral-purple-600)",
              }}
            >
              You&apos;ll be charged ${cost} to your selected payment method
              every {cycle === BILLING_CYCLE.monthly ? "month" : "year"}, unless
              cancelled.
            </Text>
          </Header>
        </HeaderContainer>
        <CardInputContent>
          <StripeIframe />
        </CardInputContent>
      </Content>
      <CloseButton
        icon="IconX"
        variant="secondary"
        size="small"
        onClick={closeModal}
      />
    </Container>
  );
};
