import { FC } from "react";
import { NextSteps } from "./Gong/NextSteps";
import { GongData } from "./Gong/GongData";
import { useGongData } from "~src/customHooks";
import { Empty, Flex } from "antd";
import styled from "styled-components";

export const Gong: FC = () => {
  const gongCalls = useGongData();
  return (
    <>
      {gongCalls.length ? (
        <>
          <NextSteps />
          <GongData />
        </>
      ) : (
        <StyledFlex justify={"center"}>
          <Empty
            description={"No results"}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </StyledFlex>
      )}
    </>
  );
};

const StyledFlex = styled(Flex)`
  width: 100%;
`;
