import { useEffect, useMemo, useState } from "react";
import { styled } from "styled-components";
import { Text } from "@lavender-ai/lav-components";

import { useGlobalSearchContext } from "~src/contexts";

export const ResultsHeader = () => {
  const { results, debouncedSearchTerm, searchTerm } = useGlobalSearchContext();

  const resultsString = useMemo(() => {
    switch (results.length) {
      case 0:
        return "No results";
      case 1:
        return "1 result";
      default:
        return `${results.length} results`;
    }
  }, [results.length]);

  return (
    <SearchModalResultsHeader>
      <Text size="body1" style={{ color: "var(--color-neutral-purple-500)" }}>
        {searchTerm !== debouncedSearchTerm ? (
          <ThreeDotsCycleComponent />
        ) : (
          resultsString
        )}
      </Text>
    </SearchModalResultsHeader>
  );
};

export const ThreeDotsCycleComponent = () => {
  const [dotStage, setDotStage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotStage((dotStage) => (dotStage + 1) % 5);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  switch (dotStage) {
    case 1:
      return ".";
    case 2:
      return "..";
    case 3:
      return "...";
    case 4:
      return "...";
    case 0:
    default:
      return "";
  }
};

const SearchModalResultsHeader = styled.div`
  display: flex;
  padding: 0px 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  height: 21px;
`;
