import {
  CompanySubTab,
  FeedSubTab,
  FeedSubTabDropdown,
  ProfileSubTab,
} from "../component/typings/personalization";

export const PANEL = {
  HOW_TO_PERSONALIZE: "How to Personalize",
  LEARN_ABOUT:
    "Learn about your recipients and personalize emails faster than ever.",
  LEAVING_THIS_PANEL_OPEN:
    "Leave this panel open: any information about your recipient will appear here.",
  NOT_FOUND: "Not found",
  OH_NO: "Oh no!",
  UNABLE_TO_VERIFY: "Unable to verify this email.",
  UNABLE_TO_VERIFY_DETAIL:
    "This email may be inaccurate, no longer active, or the server admin has blocked verification.",
  INVALID_RECIPIENT: "This domain reported the email does not exist.",
  VALID_RECIPIENT: "Email verified!",
};

export const CTA = {
  NOTES: "Notes",
  READ_MORE: "Read More",
  SEE_ALL_DIVISIONS: "Show more",
  HIDE_DIVISIONS: "Show less",
  POST: "Post",
};

export const HEADER = {
  BLOGS_AND_PODCASTS: "Blogs & Podcasts",
  GONG_DATA: "Gong Data",
  NEWS: "News",
  JOBS: "Jobs",
  TECH_STACK: "Tech Stack",
  PERSONALITY: "Personality",
  ACTIVITY: "Activity",
  EXPERIENCE: "Experience",
  HUBSPOT: "HubSpot",
  SALESFORCE: "Salesforce",
  CONVERSATION_STARTERS: "Conversation Starters",
  FUNDING: "Funding",
  NOTES: "Notes",
  ALL_NOTES: "All notes",
};

export const DROPDOWN = {
  ...FeedSubTabDropdown,
  FEED: "Feed",
  MORE: "More",
};

export const SUB_TAB = {
  ...FeedSubTab,
  ...ProfileSubTab,
  ...CompanySubTab,
};

export const SUB_TAB_TEXT = {
  DO_THIS: "Do this",
  DONT_DO_THIS: "Don't do this",
  KEY_TRAITS: "Key Traits",
  WHEN_COMMUNICATING: "When Communicating",
  OCEAN: "OCEAN",
  DISC: "DISC",
};

export const NO_DATA_MESSAGES = {
  USERS_LIKE_YOU: "Users like you find important info here:",
  TRY_ONE_OF_THESE_POPULAR_PAGES: "Try one of these popular pages instead:",
  YOU_MIGHT_FIND_THESE_HELPFUL: "You might find these helpful:",
  // Nick added - not from Figma
  CHECK_OUT_THESE_SECTIONS: "Check out these sections for more insights:",
  EXPLORE_THESE_OPTIONS: "Explore these options:",
};
