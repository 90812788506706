import { Flex } from "antd";
import { styled, css } from "styled-components";

import { NetworkStatus } from "../../../redux/typings";
import { PropsForStyling } from "../../typings";

export const SKELETON_LOADING_ANIMATION_MIXIN = css`
  animation-name: slim-skeleton-loading;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;

  /* copy of ant-skeleton-loading */
  @keyframes slim-skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

// INFO: gradient created by antd from setting Skeleton "colorFillContent" in theme.ts.
export const SKELETON_GRADIENT_MIXIN = css`
  background: linear-gradient(
    90deg,
    #e5e9f1 25%,
    rgba(0, 0, 0, 0.15) 37%,
    #e5e9f1 63%
  );
  background-size: 400% 100%;
  ${SKELETON_LOADING_ANIMATION_MIXIN};
`;

const ERROR_MESSAGE_TEXT_CONTAINER_MIXIN = css`
  background: var(--color-tart-50);
  border: 1px solid var(--color-tart-100);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
`;

const LOADING_MESSAGE_TEXT_CONTAINER_MIXIN = css`
  border: 1px solid var(--color-neutral-blue-100);
  background: var(--color-neutral-blue-100);
`;

const SUCCESS_MESSAGE_TEXT_CONTAINER_MIXIN = css`
  background: var(--color-base-White);
  border: 1px solid var(--color-base-White);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
`;

export const StyledMessageContainer = styled.div`
  max-width: calc(var(--lv-expanded-panel-size) - 75px);
  align-self: center;

  span {
    max-width: 100%;
    flex-shrink: 1;

    a {
      text-decoration-line: underline;
      color: var(--color-lavender-400);
      &:active {
        color: var(--color-lavender-400);
      }
      &:hover {
        color: var(--color-lavender-300);
      }
    }
  }
`;

export const StyledMessageTextContainer = styled(Flex)<
  PropsForStyling<{ status?: NetworkStatus; isFullWidth?: boolean }>
>`
  padding: 12px;
  border-radius: var(--radius-md);
  align-self: stretch;
  ${({ $isFullWidth: isFullWidth }) =>
    !isFullWidth &&
    css`
      width: fit-content;
    `}
  word-wrap: break-word;

  ${({ $status: status }) => {
    if (status === NetworkStatus.loading) {
      return css`
        ${LOADING_MESSAGE_TEXT_CONTAINER_MIXIN}
        .lav-particles-animation-variant-thinking {
          display: flex;
          width: 14px;

          svg {
            align-self: center;
            height: unset !important;
            scale: 2.2;
            width: unset !important;
          }
        }
      `;
    }
    if (status === NetworkStatus.failed) {
      return css`
        ${ERROR_MESSAGE_TEXT_CONTAINER_MIXIN}
      `;
    }
    return css`
      ${SUCCESS_MESSAGE_TEXT_CONTAINER_MIXIN}
    `;
  }};
`;
