export const unixToTimeStrDynamic = (
  i: number,
  year: "short" | "long" | "none" = "long"
) => {
  if (i <= 0) {
    return "";
  }

  let date = new Date(i * 1000); // Convert Unix timestamp to JavaScript Date
  while (date.getFullYear() < 1971) {
    i = i * 10;
    date = new Date(i * 1000);
  }
  while (date.getFullYear() > 2100) {
    i = i / 10;
    date = new Date(i * 1000);
  }

  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  let yr: "numeric" | "2-digit" | undefined;
  let day: "2-digit" | "numeric" = "2-digit";
  if (year !== "none") {
    day = "numeric";
    yr = year === "short" ? "2-digit" : "numeric";
  }

  // Use Intl.DateTimeFormat to format the date
  const options: Intl.DateTimeFormatOptions = {
    day,
    year: yr,
    month: "short",
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    utcDate
  );

  return year === "none"
    ? formattedDate.replace(/ \d{2}/, ($1) => `.${$1}`)
    : formattedDate;
};

export const prettyDate = (
  dateStr: string,
  year: "short" | "long" | "none" = "long"
) => {
  const date = new Date(dateStr);
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  let yr: "numeric" | "2-digit" | undefined;
  let day: "2-digit" | "numeric" = "2-digit";
  if (year !== "none") {
    day = "numeric";
    yr = year === "short" ? "2-digit" : "numeric";
  }
  const options: Intl.DateTimeFormatOptions = {
    year: yr,
    month: "short",
    day,
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    utcDate
  );

  return year === "none"
    ? formattedDate.replace(/ \d{2}/, ($1) => `.${$1}`)
    : formattedDate;
};

export const calcTimeUntil = (targetIsoDate: string): string => {
  const targetDate = new Date(targetIsoDate).getTime();
  const now = new Date().getTime();

  const diff = targetDate - now;
  if (diff < 0) {
    // Past the event
    return "";
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  if (days > 1) {
    return `${days} days`;
  }
  if (days === 1) {
    return `${days} day`;
  }
  if (hours > 1) {
    return `${hours} hours`;
  }
  if (hours === 1) {
    return `${hours} hour`;
  }
  return `${minutes} min`;
};
