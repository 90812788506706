import { Animation } from "@lavender-ai/lav-components";

import { ConditionalSwitch } from "~src/component/Utilities";

import {
  EmailCoachHeader,
  Category,
  NoEmailData,
  WhatsDoneRight,
} from "./components";
import { EmailCoachBody, EmailCoachLoading } from "./EmailCoach.styled";
import { useEmailCoachDisplayType, useTriggerCoachUsed } from "./utils";

export const EmailCoach = () => {
  const displayType = useEmailCoachDisplayType();
  useTriggerCoachUsed();

  return (
    <ConditionalSwitch>
      {displayType === "empty" && <NoEmailData />}
      {displayType === "loading" && (
        <EmailCoachLoading vertical={true} align="center" justify="center">
          <Animation
            className="lv-email-coach-loading-animation"
            variant="brand-loading"
          />
        </EmailCoachLoading>
      )}
      {displayType === "coach" && (
        <EmailCoachBody vertical={true} align="center" justify="space-between">
          <EmailCoachHeader />
          <Category type="danger" />
          <Category type="warning" />
          <WhatsDoneRight />
        </EmailCoachBody>
      )}
    </ConditionalSwitch>
  );
};
