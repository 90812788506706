import { FC } from "react";
import styled from "styled-components";
import { Flex } from "antd";
import { Avatar, Button, Icon } from "@lavender-ai/lav-components";
import { LavenderIcon } from "../../../../../typings";
import { GongCall } from "../../../../../../redux/typings";

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 20px 16px;
  padding: 20px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: var(--radius-lrg, 16px);
  background: var(--color-base-White, #fff);
`;

const CallInfo = styled.div`
  flex-direction: column;
  text-align: left;
`;

const CallTitle = styled.div`
  display: flex;
  color: var(--color-neutral-purple-900, #18253d);
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.1px;
  padding-bottom: 16px;
`;

const CallTime = styled.div`
  display: flex;
  color: var(--color-neutral-purple-600, #6d727d);
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
`;
interface Props {
  call: GongCall;
}

export const CallDetail: FC<Props> = (props) => {
  const { call } = props;
  return (
    <Frame>
      <Flex>
        <Avatar
          color="lavender"
          size="medium"
          type="icon"
          icon={
            <Icon
              color="lavender"
              size="medium"
              variant={LavenderIcon.IconPhone}
            />
          }
        />
      </Flex>
      <CallInfo>
        <CallTitle>{call.title}</CallTitle>
        <CallTime>
          {call.callTimeFormatted} • {call.durationFormatted}
        </CallTime>
      </CallInfo>
      <Flex>
        <Button
          variant="default"
          size="medium"
          prefix="IconGongLogo"
          onClick={() => window.open(call.url, "_blank")}
        >
          Open in Gong
        </Button>
      </Flex>
    </Frame>
  );
};
