import {
  PayloadAction,
  createAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import { emailVerification } from "../api";
import { EmailEvaluation } from "../api/typings";
import type {
  EmailVerificationData,
  SetLoadingArgs,
  VerificationState,
} from "./typings";
import { RootState, AppDispatch } from "./store";

export const INIT_VERIFICATIONS_STATE: VerificationState = {};

export const updateVerifications = createAction<VerificationState>(
  "verifications/updateVerifications"
);

const createVerificationAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();

export const verify = createVerificationAsyncThunk<
  Promise<void>,
  { toAddress: string[] }
>("verifications/verify", async ({ toAddress }, { dispatch, getState }) => {
  const {
    config: { chromeId },
    verifications,
  } = getState();
  const newVerifications: VerificationState = {};
  const newEmails: string[] = [];

  for (const email of toAddress) {
    if (!verifications[email]) {
      newEmails.push(email);
      newVerifications[email] = { loading: true };
    } else {
      newVerifications[email] = verifications[email];
    }
  }

  if (newEmails.length) {
    dispatch(updateVerifications(newVerifications));

    try {
      const { EmailResults } = await emailVerification(newEmails, chromeId);
      for (const result of EmailResults) {
        newVerifications[result.Email] = {
          loading: false,
          evaluation: result.Valid,
        };
      }
    } catch (e) {
      for (const newEmail of newEmails) {
        newVerifications[newEmail] = {
          loading: false,
          evaluation: EmailEvaluation.MAYBE,
        };
      }
    }

    dispatch(updateVerifications(newVerifications));
    // [INFO] IF we can ever do always on data, uncomment this line
    // await dispatch(personalizations({ toAddress }));
  }
});

export const verificationsSlice = createSlice({
  initialState: INIT_VERIFICATIONS_STATE,
  name: "verifications",
  reducers: {
    setSeen: (state, { payload: { email } }: PayloadAction<SetLoadingArgs>) => {
      if (state[email] !== undefined) {
        (state[email] as EmailVerificationData).seen = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateVerifications, (state, action) => {
      Object.assign(state, action.payload);
    });
  },
});

export const verificationsReducer = verificationsSlice.reducer;
export const verificationsAction = verificationsSlice.actions;
