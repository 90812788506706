import styled from "styled-components";

import { Text } from "@lavender-ai/lav-components";

import { PropsForStyling } from "../typings";

export const CenteredText = styled(Text)`
  display: block;
  && {
    margin: 0 auto;
  }
`;

export const DateTriggerDivider = styled(Text)`
  width: 1px;
  height: 11px;
  background: #d9d9d9;
`;

interface SecondaryTextProps {
  light?: boolean;
}

export const SecondaryText = styled(Text)<PropsForStyling<SecondaryTextProps>>`
  .lav-components-text-color-black.lav-components-text-underline-undefined.lav-components-text& {
    color: ${({ $light }) =>
      $light
        ? "var(--color-neutral-purple-600)"
        : "var(--color-neutral-blue-600)"};
  }

  .lav-components-text-color-lavender.lav-components-text-underline-undefined.lav-components-text& {
    color: var(--color-lavender-100);
  }

  .lav-components-text-color-black.lav-components-text-size-subtext1.lav-components-text-underline-undefined.lav-components-text& {
    color: var(--color-neutral-blue-500);
  }
`;
