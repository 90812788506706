import { URLS, networkRequest } from "~src/api";

interface CheckForLavenderAnywhereUserResponse {
  chrome_id?: string;
}

export const checkForLavenderAnywhereUser = async (chromeIds: string) => {
  try {
    const response =
      await networkRequest.post<CheckForLavenderAnywhereUserResponse>(
        URLS.checkForLavenderAnywhereUser,
        {
          chrome_ids: chromeIds,
        }
      );
    console.debug(response);
    return response.data?.chrome_id || "";
  } catch (error) {
    console.error(error);
  }

  return "";
};
