import {
  NetworkStatus,
  RecipientTweetsMap,
  TweetsData,
} from "~src/redux/typings";
import {
  createFeedAsyncThunk,
  parseTweetResponse as parseTweetsResponse,
} from "../helpers";
import { REFRESH_INTERVAL } from "~src/constants";
import { newPendingTweets, newRejectedTweets } from "~src/utils";
import { updateTweets } from "../actions";
import { getTweets } from "~src/api";

export const loadTweets = createFeedAsyncThunk<
  TweetsData,
  { toAddresses: string[] }
>(
  "feed/tweets",
  async ({ toAddresses }, { dispatch, getState }): Promise<TweetsData> => {
    const {
      config: { chromeId },
      feed: { lastUpdated, tweets: tweetsData },
      userAccount: {
        settings: { user },
      },
    } = getState();

    if (!chromeId || user === undefined) {
      return { toAddresses, tweetsData: {} };
    }

    if (!toAddresses.length) {
      return { toAddresses, tweetsData };
    }

    const shouldRefetch =
      !lastUpdated || Date.now() - lastUpdated > REFRESH_INTERVAL.FEED;

    const tweetsDataFetchEmails = toAddresses.filter(
      (email) => !tweetsData[email] || shouldRefetch
    );

    const pendingTweetsData = tweetsDataFetchEmails.reduce<RecipientTweetsMap>(
      (emails, email) => {
        emails[email] = newPendingTweets();
        return emails;
      },
      {}
    );

    dispatch(
      updateTweets({
        toAddresses,
        tweetsData: pendingTweetsData,
      })
    );

    const resolvedTweets = await Promise.all(
      tweetsDataFetchEmails.map(async (email) => {
        try {
          const tweetsDataResponse = await getTweets(email, chromeId);
          return {
            status: NetworkStatus.success,
            email,
            data: parseTweetsResponse(tweetsDataResponse),
          };
        } catch {
          return {
            status: NetworkStatus.failed,
            email,
            data: newRejectedTweets(),
          };
        }
      })
    );

    const parsedFullfilledTweets = resolvedTweets.reduce<RecipientTweetsMap>(
      (acc, { email, data }) => {
        acc[email] = data;
        return acc;
      },
      {}
    );

    dispatch(
      updateTweets({
        toAddresses,
        tweetsData: parsedFullfilledTweets,
      })
    );

    return { toAddresses, tweetsData: parsedFullfilledTweets };
  }
);
