import { Flex } from "antd";

import { Steps } from "~src/component/Organisms/NoRecipients";
import {
  IconVariant,
  SVGHeader,
} from "~src/component/Atoms/SVGHeader/SVGHeader";

import { LavenderIcon } from "~src/component/typings";
import { SME_HEADER, SME_SUBHEADER } from "~src/strings";

export const NoRecipients = () => {
  return (
    <Flex align="center" gap="middle" vertical>
      <SVGHeader iconVariant={IconVariant.CelebratoryShadow} />
      <Flex
        align="flex-start"
        gap={32}
        style={{
          alignSelf: "stretch",
          background: "var(--lv-panel-background-color)",
          padding: "0px 40px",
        }}
        vertical
      >
        <Steps
          header={SME_HEADER.PERSONALIZATION_HAS_NEVER_BEEN}
          subheader={SME_SUBHEADER.HOW_TO_USE}
          steps={[
            {
              icon: LavenderIcon.IconAt,
              text: `<b>Add your recipient&apos;s email</b> address to get started.`,
            },
            {
              icon: LavenderIcon.IconPointer,
              text: `<b>Next</b>, we&apos;ll search our data to find the most relevant
              info about your recipient.`,
            },
            {
              icon: LavenderIcon.IconSparkles,
              text: `<b>Select some Icebreakers</b> and we&apos;ll quickly generate
              your first draft!`,
            },
          ]}
        />
      </Flex>
    </Flex>
  );
};
