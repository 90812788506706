import { networkRequest, URLS } from "~src/api";

import { isDarkMode } from "../utils";
import type { PersonalizationResponse } from "./typings";

export const getPersonalization = async (
  email: emailAddress,
  chromeId: chromeId,
  forceRefetch: boolean = false
): Promise<PersonalizationResponse> => {
  const { data } = await networkRequest.post<PersonalizationResponse>(
    URLS.profile,
    {
      dark_mode: isDarkMode(),
      email,
      chrome_id: chromeId,
    },
    {
      fetchOptions: {
        forceRefetch,
      },
    }
  );

  return data;
};
