import styled from "styled-components";

import { Icon } from "@lavender-ai/lav-components";

import { SECONDARY_ICON_MIXIN } from "./mixin";

export const SecondaryIcon = styled(Icon)`
  .lav-components-icon-color-dark.lav-components-icon& {
    ${SECONDARY_ICON_MIXIN}
  }
`;
