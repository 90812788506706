import { Flex } from "antd";
import { styled } from "styled-components";
import { Text } from "@lavender-ai/lav-components";
import EmptyImage from "~src/assets/images/empty.svg";

interface EmptyProps {
  title: string;
  description?: string;
  buttons?: React.ReactNode[];
}

export const Empty = ({ title, description, buttons }: EmptyProps) => {
  return (
    <EmptyContainer align="center" vertical>
      <EmptyImg src={EmptyImage} alt="" />
      <Text size="subtext3">{title}</Text>
      {buttons && buttons.length > 0 && (
        <>
          <Bar />
          <MoreText size="subtext1">{description}</MoreText>
          <EmptyActions>{buttons}</EmptyActions>
        </>
      )}
    </EmptyContainer>
  );
};

const EmptyContainer = styled(Flex)`
  margin: 24px auto;
`;

const Bar = styled.div`
  background: var(--color-neutral-purple-100, #e9ebf2);
  height: 1px;
  width: 100%;
  content: "";
  clear: both;
  margin: 20px 0;
`;

const EmptyImg = styled.img`
  width: 61px;
  height: 66px;
  transform: rotate(-3deg);
  margin-bottom: 24px;
`;

const MoreText = styled(Text)`
  color: var(--color-primary-lavender, #6a2be9);
  font-weight: 600;
  margin-bottom: 16px;
`;

const EmptyActions = styled.div`
  button {
    margin: 0px 4px 8px 4px;
    display: inline-flex !important;
  }
`;
