import { PersonalizationTechAggregateData } from "~src/redux/typings";

import { TechStackItem } from "./TechStackItem";
import { ComponentBrandColor } from "@lavender-ai/lav-components/dist/esm/types/src/types";

interface TechStackListProps {
  aggregate: PersonalizationTechAggregateData[];
}

export const TechStackList = ({ aggregate }: TechStackListProps) => {
  const colors: Array<
    | "gray"
    | "dark"
    | "outline"
    | "lavender"
    | "pear"
    | "sapphire"
    | "marigold"
    | "peony"
    | "tart"
    | undefined
  > = ["lavender", "pear", "marigold", "sapphire"];
  return (
    <div
      className="lv-panel-list-container __wide __simple"
      style={{ minHeight: "400px" }}
    >
      {aggregate.map(({ category, tech }, index) => (
        <TechStackItem
          key={category}
          category={category}
          tech={tech}
          color={colors[index % 4]}
        />
      ))}
    </div>
  );
};
