import { StartMyEmailWSResponse } from "~src/api/typings";

export interface StartMyEmailState {
  connections: RecipientConnectionsMap;
}

type RecipientConnectionsMap = Record<
  lvIdentifier,
  StartMyEmailWSResponse | undefined
>;

export interface SetLastJsonMessagePayload {
  sessionId: lvIdentifier;
  message: StartMyEmailWSResponse;
}

export interface ResetLastJsonMessagePayload {
  sessionId: lvIdentifier;
}
