import styled from "styled-components";

import { PropsForStyling } from "~src/component/typings";

interface StyledStartMyEmailModalItemProps {
  padding?: string | number;
}
export const StyledStartMyEmailModalItem = styled.div<
  PropsForStyling<StyledStartMyEmailModalItemProps>
>`
  background: var(--color-base-White);
  border-radius: var(--radius-md-lrg);
  ${({ $padding: padding }) => padding !== undefined && `padding: ${padding}`};
  width: 100%;
`;
