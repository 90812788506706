import { networkRequest, URLS } from "~src/api";
import { TweetsResponse } from "./typings";

export const getTweets = async (
  email: string,
  chromeId: chromeId
): Promise<TweetsResponse> => {
  const { data } = await networkRequest.post<TweetsResponse>(URLS.tweets, {
    email,
    chrome_id: chromeId,
  });

  return data;
};
