import { useCallback, useMemo } from "react";
import { Divider, Empty, Flex } from "antd";

import { Text } from "@lavender-ai/lav-components";

import {
  PanelBlock,
  PanelBlockVariant,
  PanelBlockHeader,
  PanelListContainer,
  PanelListItem,
} from "~src/layout/MainPanel/PanelBlock";
import { LoadMoreButton } from "~src/component/Atoms";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
} from "~src/component/Utilities/Loading";
import { EventPanel } from "../../../Events";

import { LavenderIcon } from "~src/component/typings";
import { useAppSelector, useGetPodcastsQuery } from "~src/redux";
import { Result } from "~src/redux/typings";
import { unixToTimeStrDynamic } from "~src/utils";

import { usePersonalization, useLoadMore, useSearch } from "~src/customHooks";
import { BUTTON_TEXT } from "~src/strings";
import { NoDataDisplay, SearchInput } from "~src/component/Molecules";
import { ModalType, useCarouselContext } from "../../CarouselContextProvider";

const PODCAST_SEARCH_PATHS = [
  "title_original",
  "publisher_original",
  "description_original",
];

const DEFAULT_PODCASTS = [];

export const Podcasts = () => {
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = usePersonalization();
  // const email = recipients[0];
  const { chromeId } = useAppSelector(({ config }) => config);

  const podcastQueryParams = useMemo(
    () => ({
      email,
      chromeId,
    }),
    [email, chromeId]
  );

  const { data, isLoading, isError, isSuccess } = useGetPodcastsQuery(
    podcastQueryParams,
    { skip: !email }
  );

  const empty = isError || (isSuccess && data.length === 0);

  const { isDisabled, searchTerm, filteredItems, handleSearch } = useSearch(
    data ?? DEFAULT_PODCASTS,
    PODCAST_SEARCH_PATHS
  );
  const countConfig = makeCountConfig(filteredItems.length);
  const { currentItems, loadMore, loadMoreCount, hasMore } =
    useLoadMore(filteredItems);

  if (isLoading) {
    return (
      <PanelBlock loading={isLoading} variant={PanelBlockVariant.list}>
        <PanelBlockHeader headerText="Podcasts" loading={isLoading} />
        <SearchInput
          countConfig={countConfig}
          disabled={isDisabled}
          handleSearch={handleSearch}
          loading={isLoading}
          placeholder="Search podcasts..."
          searchTerm={searchTerm}
        />
        <PanelListContainer>
          <PanelListItem>
            <CustomSkeleton variant={CustomSkeletonVariant.avatarListItem} />
          </PanelListItem>
          <PanelListItem>
            <CustomSkeleton variant={CustomSkeletonVariant.avatarListItem} />
          </PanelListItem>
        </PanelListContainer>
      </PanelBlock>
    );
  }

  return (
    <PanelBlock variant={PanelBlockVariant.list}>
      {empty ? (
        <NoDataDisplay mainText="We couldn't find any Podcasts." />
      ) : (
        <>
          <PanelBlockHeader headerText="Podcasts" />
          <SearchInput
            countConfig={countConfig}
            disabled={isDisabled}
            handleSearch={handleSearch}
            placeholder="Search podcasts..."
            searchTerm={searchTerm}
          />
          {filteredItems.length === 0 && (
            <div style={{ width: "100%", padding: "16px 0" }}>
              <Flex justify="center">
                <Empty
                  description={
                    <Text
                      size="body1"
                      style={{
                        color: "var(--ant-color-text-quaternary)",
                        margin: "0 auto",
                      }}
                    >
                      No results
                    </Text>
                  }
                  image={Empty.PRESENTED_IMAGE_DEFAULT}
                />
              </Flex>
            </div>
          )}
          <PanelListContainer>
            <PodcastList results={currentItems} />
          </PanelListContainer>
          {hasMore && (
            <div className="lv-panel-list-load-more-button-container">
              <LoadMoreButton
                buttonText={BUTTON_TEXT.SEE_MORE(loadMoreCount)}
                onClick={loadMore}
                prefix={LavenderIcon.IconPlus}
              />
            </div>
          )}
        </>
      )}
    </PanelBlock>
  );
};

interface Props {
  results: Result[];
}

const PodcastList = ({ results }: Props) => {
  const { next } = useCarouselContext();

  const handleClick = useCallback(
    (result: Result) => {
      return () => {
        next(ModalType.PodcastDetails, result);
      };
    },
    [next]
  );

  return <PodcastResults onClick={handleClick} podcastResults={results} />;
};

interface PodcastResultsProps {
  onClick?: (result: Result) => () => void;
  podcastResults: Result[];
}

const PodcastResults = ({ onClick, podcastResults }: PodcastResultsProps) => {
  return (
    <div>
      {podcastResults.map((result: Result, i) => {
        const prettyDate = unixToTimeStrDynamic(result.pub_date_ms);
        return (
          <div className="lv-panel-list-item-wrapper" key={result.id ?? i}>
            <EventPanel
              avatar={{
                src: result.podcast.thumbnail,
              }}
              topLine={{ enable: false }}
              primaryLine={{ enable: true, title: result.title_original }}
              tertiaryLine={{
                enable: true,
                text: `${result.podcast.publisher_original} • ${prettyDate} • ${prettySecondsToMinutesHours(result.audio_length_sec)}`,
              }}
              data={{
                id: result.id ?? i,
                dense: true,
                hover: true,
                showTimestamp: true,
                timestampLocation: "top",
                timestamp: prettyDate,
              }}
              clickAction={onClick?.(result)}
            ></EventPanel>
            <Divider style={{ margin: 0 }} />
          </div>
        );
      })}
    </div>
  );
};

const prettySecondsToMinutesHours = (seconds: number) => {
  const totalMinutes = Math.floor(seconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  if (hours < 1) {
    return `${minutes}m`;
  }
  return `${hours}h ${minutes}m`;
};

const makeCountConfig = (count: number) => ({
  count,
  formatter: (count: number) =>
    `${count} ${count === 1 ? "podcast" : "podcasts"}`,
});
