import {
  isDarkMode,
  getTextEditor,
  getSippetFlag as getSnippetFlag,
  getAuthorEmailOverwrite,
  isEmailReplyOrFollowup,
  getEmailsBefore,
  getEmailThreadEmailBeforeSelectors,
} from "../../utils";
import { store } from "~src/redux/store";
import { EmailBefore, SystemData, WritingData } from "~src/redux/typings";
import { htmlToText } from "~src/utils";

export const getWritingData = (lvIdentifier: lvIdentifier): WritingData => {
  const state = store.getState();
  const emailData = state.emailData[lvIdentifier];
  const body = emailData?.body || "";
  const subject = emailData?.subject || "";
  const writingStartTime = emailData?.writingStartTime || 0;
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const toAddress = emailData?.toAddress || [];
  const author_email = state.config.emailAddress;
  const parser = new DOMParser();
  const emailDocument = parser.parseFromString(body, "text/html");
  const emailHTML = emailDocument.body;
  return {
    snippet_flag: getSnippetFlag(),
    reply_flag: isEmailReplyOrFollowup(emailData),
    author_email,
    subject,
    stage_overwrite: emailData?.stageOverwrite || "",
    scoring_model_overwrite: emailData?.scoringModelOverwrite || "",
    //INFO: this is where you can send the html body to the backend
    // cc @Casey
    // body,
    //disable the below line to stop sending string boy to the backend, attached functions can be deleted too
    body,
    recipients: JSON.stringify(toAddress),
    author_email_overwrite: getAuthorEmailOverwrite(emailHTML),
    writing_started: writingStartTime,
    writing_time: Date.now() - writingStartTime,
    emails_before: JSON.stringify(getEmailsBeforeArray(emailHTML)),
    coach_used: emailData?.isCoachUsed || false,
    chat_gpt_used: emailData?.isChatGPTUsed || false,
    gifs_used: emailData?.isGifsUsed || false,
    frameworks_used: emailData?.isFrameworksUsed || false,
    personalization_assistant_used:
      emailData?.isPersonalizationAssistantUsed || false,
    start_my_email_used: emailData?.isStartMyEmailUsed || false,
    include_in_analytics: emailData?.includeInAnalytics || false,
    // mobile_optimized: isMobileOptimized(body),
  };
};

// INFO: get the nested threaded emails before
const getEmailsBeforeArray = (emailHTML: HTMLElement): EmailBefore[] => {
  const {
    config: { settings },
  } = store.getState();
  if (!settings) {
    return [];
  }

  const selectors = getEmailThreadEmailBeforeSelectors(settings);
  if (!selectors) {
    return [];
  }

  return getEmailsBefore(
    emailHTML,
    selectors.emailThreadEmailBefore,
    selectors.emailThreadInnerReplyEmail,
    selectors.emailThreadInnerReplyEmailTimeSentPrefix,
    selectors.emailThreadInnerReplyEmailBody,
    selectors.emailThreadInnerReplyEmailBodyFilterOutElements ?? ""
  );
};

export const getBodyLength = (bodyHtml: string): number => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(bodyHtml, "text/html");

  const selectors = [
    ".gmail_signature",
    ".sl-signature",
    ".outreach-signature",
    "div#Signature",
    'div[data-smartmail="gmail_signature"]',
    ".gmail_signature_prefix",
  ];

  selectors.forEach((selector) => {
    const elements = doc.querySelectorAll(selector);
    elements.forEach((element) => {
      element.remove();
    });
  });

  const plainTxtBody = htmlToText(doc.body.innerHTML);
  return plainTxtBody.match(/\b(\w+)\b/g)?.length || 0;
};

export const getSystemsData = (lvIdentifier: lvIdentifier): SystemData => {
  const platform = store.getState().config.settings?.platform || "";
  const chromeId = store.getState().config.chromeId;
  return {
    chrome_id: chromeId,
    email_id: lvIdentifier,
    dark_mode: isDarkMode() ? "dark" : "light",
    horizontal: false, // INFO: legacy field always false
    mailclient: platform,
    text_editor: getTextEditor(),
    template_flag: false, // INFO: legacy field always false
    grammar_nw: false, // INFO: legacy field always false
  };
};

//INFO: everything below is from Legacy email extension

export const replaceSubjectText = (
  parentDiv: HTMLElement | null,
  newSubject: string
) => {
  const subjectSelector =
    store.getState().config.settings?.selectors?.subjectSelector;
  if (subjectSelector) {
    const subjectField =
      parentDiv?.querySelector<HTMLTextAreaElement>(subjectSelector);
    const subjectBoxField = parentDiv?.querySelector<HTMLInputElement>(
      "[name='subjectbox']"
    );
    if (subjectBoxField && subjectField) {
      subjectBoxField.value = newSubject;
      subjectField.value = newSubject;
    }
  }
};

export const replaceBodyText = (
  parentDiv: HTMLElement | null,
  newBody: string
) => {
  const bodySelector =
    store.getState().config.settings?.selectors?.bodySelector;
  if (bodySelector) {
    const emailBody = parentDiv?.querySelector(bodySelector);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (emailBody) {
      emailBody.innerHTML = newBody;
    }
  }
};
