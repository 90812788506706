import { useCallback, useEffect, useRef } from "react";
import { Flex } from "antd";
import { styled } from "styled-components";

import { Text } from "@lavender-ai/lav-components";

import { ItemCollapse, ItemCollapseHandle, UlList } from "~src/component/Atoms";
import {
  EnhancedFlex,
  SecondaryText,
  StyledDivider,
} from "~src/component/Styled";
import { LavenderIcon } from "~src/component/typings";
import { LoadableTag } from "~src/component/Utilities";
import { useCRM, useStartMyEmail } from "~src/customHooks";
import { GONG_HEADER, SME_COLLAPSE } from "~src/strings";

import { Toggle } from "../../";

interface Props {
  gongToggleOn: boolean;
  onToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledEnhancedFlex = styled(EnhancedFlex)`
  .ant-collapse-header {
    padding: 16px !important;
  }
`;

export const GongContextItemCollapse = ({ gongToggleOn, onToggle }: Props) => {
  const gongItemCollapseRef = useRef<ItemCollapseHandle>(null);
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = useStartMyEmail();
  const { crms } = useCRM();
  const { gong_data: gongCalls, loading: isLoading } = crms[email] || {};

  useEffect(() => {
    if (!isLoading && gongCalls?.[0] !== undefined) {
      gongItemCollapseRef.current?.open();
    }
  }, [gongCalls, isLoading]);

  const onChange = useCallback(
    (checked: boolean) => {
      onToggle(checked);
    },
    [onToggle]
  );

  if (gongCalls?.[0] === undefined && !isLoading) return null;

  const item = {
    key: "1",
    label: (
      <StyledEnhancedFlex align={isLoading ? "flex-end" : "center"} gap={12}>
        <LoadableTag
          color="lavender"
          prefix={LavenderIcon.IconGongLogo}
          isLoading={isLoading}
          text={SME_COLLAPSE.FROM_GONG}
        />
        <Toggle checked={gongToggleOn} onChange={onChange} />
      </StyledEnhancedFlex>
    ),
    children:
      gongCalls?.[0] === undefined ? null : (
        <EnhancedFlex gap="large" vertical $margin="-12px 0 0 0">
          <SecondaryText size="body1" $light>
            {SME_COLLAPSE.CONTEXT_FROM_YOUR_PREVIOUS_CALL}
          </SecondaryText>
          <Flex gap="small" vertical>
            <Flex vertical>
              <Text color="dark" size="subtext2">
                {GONG_HEADER.NEXT_STEPS}
              </Text>
              <UlList dense items={gongCalls[0].nextSteps} />
            </Flex>
            <StyledDivider />
            <Flex vertical>
              <EnhancedFlex $margin="20px 0 18px 0">
                <Text color="dark" size="subtext2">
                  {GONG_HEADER.CALL_BRIEF}
                </Text>
              </EnhancedFlex>
              <Text>{gongCalls[0].callBrief}</Text>
            </Flex>
          </Flex>
        </EnhancedFlex>
      ),
    forceRender: true,
  };

  return (
    <ItemCollapse
      className="lv-icebreakers-highlightable"
      collapsible={!isLoading}
      item={item}
      size="large"
      ref={gongItemCollapseRef}
    />
  );
};
