import { Flex, Layout } from "antd";
import styled from "styled-components";

import { Avatar, Icon } from "@lavender-ai/lav-components";

import { UserDataAvatar } from "~src/component/Molecules";
import { Steps } from "~src/component/Organisms/NoRecipients";

import { LavenderIcon } from "~src/component/typings";
import { useAppSelector } from "~src/redux";
import { EMAIL_COACH_HEADER, EMAIL_COACH_SUB_HEADER } from "~src/strings";
import { extractUserDataForProfile } from "~src/utils";

const { CRAFT_BETTER_EMAILS } = EMAIL_COACH_HEADER;
const { HOW_IT_WORKS } = EMAIL_COACH_SUB_HEADER;

const StyledNoEmailDataContent = styled(Flex)`
  padding-top: 48px;
  min-width: var(--lv-expanded-panel-size);
  .ant-badge.lav-components-badge {
    height: 90px;
  }
`;

export const NoEmailData = () => {
  const user = useAppSelector(
    ({
      userAccount: {
        settings: { user },
      },
    }) => user
  );
  const { avatar } = extractUserDataForProfile(user);
  // optional chaining to avoid error in Storybook
  const fallbackSrc = chrome.runtime?.getURL?.("images/static/Avatar.png");
  const src = avatar || fallbackSrc;
  return (
    <Layout
      hasSider={false}
      rootClassName="lv-email-coach-box"
      style={{
        background: "var(--lv-panel-background-color)",
        textAlign: "center",
        width: "var(--lv-expanded-panel-size)",
      }}
    >
      <StyledNoEmailDataContent align="center" gap="large" vertical>
        <UserDataAvatar
          badge={{
            count: (
              <Avatar
                shape="square"
                style={{
                  border: "3px solid var(--color-base-White)",
                  borderRadius: "13.715px",
                  boxShadow:
                    "2.572px 3.429px 0px 0px var(--color-neutral-blue-100)",
                  transform: "rotate(6.022deg)",
                  position: "relative",
                  left: "45px",
                  top: "-35px",
                  width: "44px",
                  height: "44px",
                }}
                icon={
                  <Icon
                    color="white"
                    size="medium"
                    variant={LavenderIcon.IconBeaker}
                  />
                }
              />
            ),
          }}
          src={src}
          {...(src !== fallbackSrc && { fallbackSrc })}
          shape="square"
          size="small"
          style={{
            borderRadius: "16px",
            height: "72px",
            transform: "rotate(-3deg)",
            width: "72px",
          }}
          usePlaceholderFallback
        />
        <Flex
          align="flex-start"
          gap={32}
          style={{
            alignSelf: "stretch",
            background: "var(--lv-panel-background-color)",
            padding: "0px 40px",
            alignItems: "center",
          }}
          vertical
        >
          <Steps
            header={CRAFT_BETTER_EMAILS}
            subheader={HOW_IT_WORKS}
            steps={[
              {
                icon: LavenderIcon.IconMailBolt,
                text: "Write an email or use AI to generate one using <b>Start My Email.</b>",
              },
              {
                icon: LavenderIcon.IconAward,
                text: "Your email must contain at least <b>15 words</b> for scoring to begin.",
              },
              {
                icon: LavenderIcon.IconSparkles,
                text: "Your Email Coach will then give realtime help for how to <b>improve your email!</b>",
              },
            ]}
          />
        </Flex>
      </StyledNoEmailDataContent>
    </Layout>
  );
};
