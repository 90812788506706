import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { REFRESH_INTERVAL } from "../../constants";
import {
  type RecipientPersonalizationMap,
  NetworkStatus,
  RecipientCRMMap,
  RecipientNewsMap,
  RecipientTweetsMap,
} from "../typings";
import {
  updateCRMs,
  updateNews,
  updatePersonalizations,
  updateTechStacks,
  updateTweets,
} from "./actions";
import { addCompanyJobs, loadProfile, loadTweets } from "./thunks";
import { RecipientTechStackMap } from "../typings/techStack";
import { UpdateSettingsPayload } from "~src/api/typings";
import { isPersonalization } from "~src/typeGuards";

export interface FeedState {
  lastCleared: number | null;
  lastUpdated: number | null;
  personalizations: RecipientPersonalizationMap;
  crms: RecipientCRMMap;
  news: RecipientNewsMap;
  tweets: RecipientTweetsMap;
  techStacks: RecipientTechStackMap;
  status: NetworkStatus;
  error: string;
}

export const FEED_SLICE_NAME = "feed";
export const INIT_FEED_STATE: FeedState = {
  lastCleared: null,
  lastUpdated: null,
  status: NetworkStatus.idle,
  personalizations: {},
  crms: {},
  news: {},
  tweets: {},
  techStacks: {},
  error: "",
};

export const feedSlice = createSlice({
  initialState: INIT_FEED_STATE,
  name: FEED_SLICE_NAME,
  reducers: {
    updateProfileSettings: (
      state,
      { payload }: PayloadAction<UpdateSettingsPayload>
    ) => {
      const { email, companyName, companyDescription, companyUrl, position } =
        payload;
      if (isPersonalization(state.personalizations[email])) {
        state.personalizations[email]!.profile.company = {
          company_name: companyName,
          company_bio: companyDescription,
          url: companyUrl,
        };
        state.personalizations[email]!.profile.job = {
          company: companyName,
          title: position,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCompanyJobs.pending, (state, { meta: { arg } }) => {
        if (state.personalizations[arg.email]?.company !== undefined) {
          state.personalizations[arg.email]!.company.jobs.loading = true;
        }
      })
      .addCase(loadProfile.pending, (state, _action) => {
        state.status = NetworkStatus.loading;
      })
      .addCase(loadTweets.pending, (state, _action) => {
        state.status = NetworkStatus.loading;
      })
      .addCase(addCompanyJobs.rejected, (state, { meta: { arg } }) => {
        if (state.personalizations[arg.email]?.company !== undefined) {
          state.personalizations[arg.email]!.company.jobs.loading = false;
        }
      })
      .addCase(updatePersonalizations, (state, { payload }) => {
        state.status = NetworkStatus.success;
        const {
          toAddresses: toAddress,
          personalizations: newPersonalizations,
        } = payload;
        const shouldRefresh = !(
          state.lastUpdated &&
          Date.now() - state.lastUpdated < REFRESH_INTERVAL.FEED
        );
        if (shouldRefresh) {
          state.personalizations = toAddress.reduce(
            (newObj: RecipientPersonalizationMap, email) => {
              if (newPersonalizations[email]?.profile.email) {
                newObj[email] = newPersonalizations[email];
              }
              return newObj;
            },
            {}
          );

          state.lastUpdated = Date.now();
        } else {
          toAddress.forEach((email: string) => {
            if (newPersonalizations[email]?.profile.email) {
              state.personalizations[email] = newPersonalizations[email];
            }
          });
        }
      })
      .addCase(updateCRMs, (state, { payload }) => {
        const { toAddresses: toAddress, crmData: newCRMData } = payload;
        toAddress.forEach((email: string) => {
          if (newCRMData[email]) {
            state.crms[email] = newCRMData[email];
          }
        });
      })
      .addCase(updateNews, (state, { payload }) => {
        const { toAddresses: toAddress, newsData: newNewsData } = payload;
        toAddress.forEach((email: string) => {
          if (newNewsData[email]) {
            state.news[email] = newNewsData[email];
          }
        });
      })
      .addCase(updateTweets, (state, { payload }) => {
        state.status = NetworkStatus.success;
        const { toAddresses: toAddress, tweetsData: newTweetsData } = payload;
        const shouldRefresh = !(
          state.lastUpdated &&
          Date.now() - state.lastUpdated < REFRESH_INTERVAL.FEED
        );
        if (shouldRefresh) {
          state.tweets = toAddress.reduce(
            (newObj: RecipientTweetsMap, email) => {
              if (newTweetsData[email]) {
                newObj[email] = newTweetsData[email];
              }
              return newObj;
            },
            {}
          );

          state.lastUpdated = Date.now();
        } else {
          toAddress.forEach((email: string) => {
            if (newTweetsData[email]) {
              state.tweets[email] = newTweetsData[email];
            }
          });
        }
      })
      .addCase(updateTechStacks, (state, { payload }) => {
        const { toAddresses: toAddress, techStackData: newTechStackData } =
          payload;
        toAddress.forEach((email: string) => {
          if (newTechStackData[email]) {
            state.techStacks[email] = newTechStackData[email];
          }
        });
      })
      .addCase(
        addCompanyJobs.fulfilled,
        (state, { payload, meta: { arg } }) => {
          const { aggregate, jobs = [] } = payload;
          const personalization = state.personalizations[arg.email];

          if (personalization?.company !== undefined) {
            const {
              company: {
                jobs: { jobs: companyJobs = [] },
              },
            } = personalization;
            if (jobs?.length) {
              state.personalizations[arg.email]!.company.jobs = {
                loading: false,
                aggregate,
                jobs: [...companyJobs, ...jobs],
              };
            } else {
              state.personalizations[arg.email]!.company.jobs.loading = false;
              state.personalizations[arg.email]!.company.jobs.jobs = [];
            }
          }
        }
      );
  },
});

export const feedReducer = feedSlice.reducer;
export const feedActions = feedSlice.actions;
