import {
  APIResponseStatus,
  StartMyEmailCart,
  ValuePropWSResponse,
} from "~src/api/typings";

export interface ValuePropsState {
  connections: RecipientConnectionsMap;
  customValueProps: RecipientCustomValuePropsMap;
  userValueProps: RecipientValuePropsMap;
  valueProps: RecipientValuePropsMap;
}

export type RecipientValuePropsMap = Record<emailAddress, IValueProps>;
export type RecipientCustomValuePropsMap = Record<
  emailAddress,
  CustomCategories | undefined
>;
type RecipientConnectionsMap = Record<
  lvIdentifier,
  ValuePropWSResponse | undefined
>;

export interface SetLastValuePropsJsonMessagePayload {
  sessionId: lvIdentifier;
  message: ValuePropWSResponse;
}

export interface ResetLastValuePropsJsonMessagePayload {
  sessionId: lvIdentifier;
}

export interface IValueProps {
  generated: StartMyEmailCart;
  status: APIResponseStatus;
  error: string;
}

export interface SetRecipientValuePropsPayload {
  email: emailAddress;
  generated: StartMyEmailCart;
  status: APIResponseStatus;
  type: "value_prop_generation" | "value_prop_analysis";
}

export interface SetCustomValuePropsPayload {
  email: emailAddress;
  categories: CustomCategories | undefined;
}

export interface CustomCategories {
  categories: {
    custom: {
      main: string;
    };
  };
}
