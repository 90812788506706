import { Text } from "@lavender-ai/lav-components";
import { styled } from "styled-components";
import { Flex } from "antd";

import { useScoreColorGroup } from "../utils";
import { ShareButton } from ".";

export const TitleSection = () => {
  const { isSuccess } = useScoreColorGroup();

  return (
    <CoachTitle>
      <Text color={isSuccess ? "white" : "black"} size="subtext2">
        Lavender Email Coach
      </Text>
      <ShareButton />
    </CoachTitle>
  );
};

const CoachTitle = styled(Flex)`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 12px var(--size-24, 24px) var(--size-24, 24px);
  justify-content: space-between;
  align-items: center;
`;
