import React from "react";
import { useAppDispatch } from "../../redux/hooks";
import { useLvIdentifier } from "../../customHooks";
import { LavenderIcon } from "../../component/typings";
import { styled } from "styled-components";
import { Text, Button, Icon, IconProps } from "@lavender-ai/lav-components";
import { Flex } from "antd";
import headerImage from "./images/outlook-header.svg";
import { mainPanelAction } from "../../redux";

// INFO: Landing page for Outlook Native and Lavender Anywhere.
export const LandingPage = () => {
  const dispatch = useAppDispatch();
  const lvIdentifier = useLvIdentifier();
  const openStartMyEmail = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(mainPanelAction.open(lvIdentifier));
    dispatch(mainPanelAction.setView({ lvIdentifier, view: "startMyEmail" }));
  };
  const openProfileLookup = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(mainPanelAction.open(lvIdentifier));
    dispatch(mainPanelAction.setView({ lvIdentifier, view: "profile" }));
  };
  const openEmailCoach = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(mainPanelAction.open(lvIdentifier));
    dispatch(mainPanelAction.setView({ lvIdentifier, view: "emailCoach" }));
  };
  const openChatGPT = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(mainPanelAction.open(lvIdentifier));
    dispatch(mainPanelAction.setView({ lvIdentifier, view: "chatGPT" }));
  };
  const openFrameworks = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(mainPanelAction.open(lvIdentifier));
    dispatch(mainPanelAction.setView({ lvIdentifier, view: "frameworks" }));
  };
  const openMobilePreview = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(mainPanelAction.open(lvIdentifier));
    dispatch(mainPanelAction.setView({ lvIdentifier, view: "mobilePreview" }));
  };
  const openGif = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(mainPanelAction.open(lvIdentifier));
    dispatch(mainPanelAction.setView({ lvIdentifier, view: "gif" }));
  };
  const openSettings = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(mainPanelAction.open(lvIdentifier));
    dispatch(mainPanelAction.setView({ lvIdentifier, view: "settings" }));
  };
  return (
    <FlexBody vertical={true} align="center" className={"landing-page"}>
      <Header />
      <FlexTitle vertical={true} align="center">
        <br />
        <Text size="subtext4">Welcome to Lavender!</Text>
        <br />
        <Text size="body1">
          Write better emails faster & get more replies in less time.
        </Text>
      </FlexTitle>
      <ButtonContainer
        vertical={true}
        gap="small"
        align="center"
        justify="space-between"
        className="smallPadding"
      >
        <ButtonRow icon={LavenderIcon.IconMail} onClick={openStartMyEmail}>
          Start My Email
        </ButtonRow>
        <ButtonRow
          icon={LavenderIcon.IconUserSearch}
          onClick={openProfileLookup}
        >
          Personalization Assistant
        </ButtonRow>
        <ButtonRow icon={LavenderIcon.IconBeaker} onClick={openEmailCoach}>
          Email Coach
        </ButtonRow>
        <ButtonRow icon={LavenderIcon.IconBrandOpenAI} onClick={openChatGPT}>
          Chat GPT
        </ButtonRow>
      </ButtonContainer>
      <ButtonContainer justify="flex-start" wrap="wrap" gap="small">
        <Button
          htmlType="button"
          onClick={openFrameworks}
          prefix="IconCube"
          radius="xl"
          size="medium"
          state="initial"
          variant="default"
        >
          Frameworks
        </Button>
        <Button
          htmlType="button"
          onClick={openMobilePreview}
          prefix="IconDeviceMobileMessage"
          radius="xl"
          size="medium"
          state="initial"
          variant="default"
        >
          Mobile Editor
        </Button>
        <Button
          htmlType="button"
          onClick={openGif}
          prefix="IconGif"
          radius="xl"
          size="medium"
          state="initial"
          variant="default"
        >
          GIFs
        </Button>
        <Button
          htmlType="button"
          onClick={openSettings}
          prefix="IconSettings2"
          radius="xl"
          size="medium"
          state="initial"
          variant="default"
        >
          Settings
        </Button>
      </ButtonContainer>
    </FlexBody>
  );
};

interface ButtonRowProps {
  icon?: IconProps["variant"];
  onClick: (e: React.MouseEvent) => void;
  children: React.ReactNode;
}

const ButtonRow = ({
  icon = "IconMailBolt",
  onClick,
  children,
}: ButtonRowProps) => {
  const RowFlex = styled(Flex)`
    max-width: 400px;
    width: 100%;
    padding: 10px 12px;
    & .lv-landing-page-chevron {
      visibility: hidden;
    }
    &:hover {
      background-color: var(--lv-panel-background-color);
      border-radius: 8px;
      cursor: pointer;
      .lv-landing-page-chevron {
        visibility: visible;
      }
    }
  `;
  return (
    <RowFlex
      vertical={false}
      onClick={onClick}
      justify="space-between"
      align="center"
    >
      <Flex vertical={false} justify="flex-start" align="center" gap="middle">
        <Button
          htmlType="button"
          icon={icon}
          iconColor="black"
          radius="md"
          size="small"
          state="initial"
          variant="secondary"
        />
        {children}
      </Flex>

      <Icon
        className="lv-landing-page-chevron"
        color="black"
        size="extra-small"
        variant="IconChevronRight"
      />
    </RowFlex>
  );
};

const ButtonContainer = styled(Flex)`
  margin: 20px 20px 0 20px;
  &.smallPadding {
    margin: 20px 10px 0 10px;
  }
  width: calc(100% - 40px);
  box-sizing: border-box;
`;
const FlexBody = styled(Flex)`
  width: 100%;
  background: white;
`;
const FlexTitle = styled(Flex)`
  padding: 0 65px 20px 65px;
  text-align: center;
`;
const Header = styled.div`
  background-image: url(${headerImage});
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: calc(100% - 40px);
  margin-top: 20px;
  min-height: 111px;
  height: 18dvh;
  @media (min-width: 500px) {
    height: 20dvh;
  }
  @media (min-width: 600px) {
    height: 33dvh;
  }
`;
