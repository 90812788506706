import { Empty } from "antd";
import React from "react";

import {
  PanelBlock,
  PanelBlockVariant,
  PanelBlockHeader,
} from "~src/layout/MainPanel/PanelBlock";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
} from "../../../Utilities/Loading";

import { StyledListHeader, StyledListItem } from "./Styled";

interface Props {
  children: React.ReactNode;
  count: number;
  loading?: boolean;
  subHeader?: string;
  header: string;
  amount?: number;
}

export const SubTabListContainer = ({
  children,
  count,
  loading = false,
  subHeader,
  header,
  amount,
}: Props) => {
  if (loading) {
    return (
      <PanelBlock variant={PanelBlockVariant.list} loading>
        <StyledListHeader>
          <CustomSkeleton variant={CustomSkeletonVariant.title} />
        </StyledListHeader>
        <StyledListItem>
          <CustomSkeleton variant={CustomSkeletonVariant.simpleListItem} />
        </StyledListItem>
        <StyledListItem>
          <CustomSkeleton variant={CustomSkeletonVariant.simpleListItem} />
        </StyledListItem>
      </PanelBlock>
    );
  }

  return (
    <PanelBlock variant={PanelBlockVariant.list} loading={loading}>
      <PanelBlockHeader
        headerText={header}
        loading={loading}
        subHeaderText={subHeader}
        amount={amount}
      />
      {count === 0 ? (
        <div style={{ margin: "0 auto" }}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      ) : (
        children
      )}
    </PanelBlock>
  );
};
