import { FC } from "react";
import styled from "styled-components";

import { useAppSelector } from "~src/redux/hooks";
import { BILLING_PLANS } from "~src/constants";
import { ConditionalSwitch } from "~src/component/Utilities";

const BillingTrialDescription = styled.div`
  padding: 16px 0px 0px;
  color: var(--color-neutral-blue-600, #6d727d);
  leading-trim: both;

  text-edge: cap;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: -0.25px;
`;

export const BillingDescription: FC = () => {
  const billingPlans = useAppSelector(({ config }) => config.billingPlans);
  const {
    // @ts-expect-error - needs fix
    billing_plan: billingPlan,
    // @ts-expect-error - needs fix
    billing_cycle: billingCycle,
    // @ts-expect-error - needs fix
    has_stripe: hasStripe,
    // @ts-expect-error - needs fix
    trial_upgrade: trialUpgrade,
  } = useAppSelector((state) => state.userAccount.settings.user);
  const isTrialNoCardSaved = Boolean(
    billingPlan === BILLING_PLANS.Trial &&
      !(hasStripe && trialUpgrade?.billing_plan != null)
  );
  const isTrialHasSavedCard = Boolean(
    billingPlan === BILLING_PLANS.Trial &&
      hasStripe &&
      trialUpgrade?.billing_plan
  );
  const isBasicPlan = billingPlan === BILLING_PLANS.Basic;

  return (
    <BillingTrialDescription>
      <ConditionalSwitch>
        {isTrialNoCardSaved && (
          <>
            Extend trial by 2 weeks by adding payment method. You&apos;ll be
            auto enrolled to the Lavender Starter plan once trial ends.
          </>
        )}
        {isTrialHasSavedCard && (
          <>
            Trial extended an extra 2 weeks! Enjoy your extra time. You&apos;ll
            be auto-enrolled to Lavender {trialUpgrade?.billing_plan} after
            trial end date.
          </>
        )}
        {isBasicPlan && (
          <>Upgrade to Starter plan! Get unlimited access for $29/month.</>
        )}
        <>
          You&apos;ll be billed $$
          {billingPlans[billingPlan][billingCycle]} for this plan every $
          {billingCycle}, unless cancelled.
        </>
      </ConditionalSwitch>
    </BillingTrialDescription>
  );
};
