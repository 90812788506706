import styled from "styled-components";
import { Avatar, Text } from "@lavender-ai/lav-components";
import { Flex } from "antd";

interface SectionItems {
  title: string;
  description: string;
  visible: boolean;
}

interface SectionProps {
  icon: JSX.Element;
  title: string;
  items: SectionItems[];
}

export const Section = ({ icon, title, items }: SectionProps) => {
  return (
    <SectionContainer vertical={true}>
      <Flex gap={8} align="center">
        <SectionAvatar icon={icon} shape="circle" size="extra-small" />
        <Text color="black" size="subtext2">
          {title}
        </Text>
      </Flex>
      <SectionList gap={8} align="center">
        <ListBar />
        <Flex vertical={true} gap={12}>
          {items
            .filter((item) => item.visible)
            .map((item, i) => (
              <Flex key={`crm-item-${i}`} gap={6}>
                <Text color="black" size="subtext2">
                  {item.title}:
                </Text>
                <Text color="black" size="body2">
                  {item.description}
                </Text>
              </Flex>
            ))}
        </Flex>
      </SectionList>
    </SectionContainer>
  );
};

const SectionContainer = styled(Flex)`
  padding: 0 20px;
  margin-bottom: 20px;
`;

const SectionAvatar = styled(Avatar)`
  border-radius: var(--radius-md, 8px) !important;
  background: var(--color-neutral-purple-100, #e9ebf2) !important;
`;

const SectionList = styled(Flex)`
  margin-top: 12px;
  position: relative;
  padding-left: 32px;
  span {
    text-transform: capitalize;
  }
`;

const ListBar = styled.div`
  display: block;
  width: 2px;
  height: 100%;
  border-radius: 7px;
  background: var(--color-neutral-purple-200, #dee1e8);
  content: "";
  clear: both;
  position: absolute;
  left: 11px;
  top: 0;
`;
