import { useEffect, useState } from "react";
import { Flex } from "antd";

import { ChatGPTPanel } from "./ChatGPTPanel";
import { EmailCoachPanel } from "./EmailCoachPanel/EmailCoachPanel";
import { GifPanel } from "./GifPanel";
import { PersonalizationPanel } from "./PersonalizationPanel";
import { StartMyEmailPanel } from "./StartMyEmailPanel";

import { SlideContainer } from "~src/component/Atoms";
import {
  useAuthentication,
  useLvIdentifier,
  usePersonalization,
} from "~src/customHooks";
import { useAppDispatch, useAppSelector, usePanelIsOpen } from "~src/redux";
import { FrameworksPanel } from "./FrameworksPanel";
import { SettingsCarousel, IntercomPanel } from "./SettingsCarousel";
import { isFeaturePayWalled } from "~src/utils";
import { fixFooterWidth } from "~src/platform/gmail";
import {
  addCompanyJobs,
  loadCRM,
  loadNews,
  loadProfile,
  loadTechStack,
  loadTweets,
} from "~src/redux/feed";
import { UpgradePlanModal } from "~src/component/Organisms";
import { UpgradePanel } from "./SettingsCarousel/UpgradePanel";
import { StreakPanel } from "./StreakPanel";
import { handlePanelAnimation } from "./utils";

export const Panel = () => {
  const {
    currentPersonalization: {
      profile: { email: currentEmail = "" },
    },
  } = usePersonalization();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAuthentication();
  const lvIdentifier = useLvIdentifier();
  const showBackGround = useAppSelector(
    (state) => state.mainPanel[lvIdentifier].showBackGround
  );
  const isOpen = usePanelIsOpen();
  const defaultClass = "lv-panel";
  const [classNames, changeClass] = useState(defaultClass);

  useEffect(() => {
    if (currentEmail.length < 1) {
      return;
    }

    async function run() {
      await dispatch(loadProfile({ toAddresses: [currentEmail] }));
      await Promise.all([
        dispatch(loadCRM({ toAddresses: [currentEmail] })),
        dispatch(loadNews({ toAddresses: [currentEmail] })),
        dispatch(loadTweets({ toAddresses: [currentEmail] })),
        dispatch(loadTechStack({ toAddresses: [currentEmail] })),
        dispatch(addCompanyJobs({ email: currentEmail })),
      ]);
    }

    void run();
  }, [currentEmail, dispatch]);

  useEffect(() => {
    handlePanelAnimation(isOpen, changeClass, defaultClass);
    setTimeout(fixFooterWidth, 420);
  }, [isOpen]);

  const view = useAppSelector((state) => state.mainPanel[lvIdentifier].view);

  if (!isAuthenticated) {
    return null;
  }
  const isPayWalled = isFeaturePayWalled("Panel");
  if (isPayWalled) {
    return null;
  }

  return (
    <div className={classNames}>
      <Flex
        className={`lv-fill-height ${
          showBackGround ? "lv-panel-background" : ""
        }`}
      >
        <SlideContainer>
          {view === "settings" && <SettingsCarousel />}
          {view === "startMyEmail" && <StartMyEmailPanel />}
          {view === "profile" && <PersonalizationPanel />}
          {view === "frameworks" && <FrameworksPanel />}
          {view === "chatGPT" && <ChatGPTPanel />}
          {view === "gif" && <GifPanel />}
          {view === "frameworks" && <FrameworksPanel />}
          {view === "intercom" && <IntercomPanel />}
          {view === "emailCoach" && <EmailCoachPanel />}
          {view === "upgrade" && <UpgradePanel />}
          {view === "streak" && <StreakPanel />}
          <UpgradePlanModal />
        </SlideContainer>
      </Flex>
    </div>
  );
};
