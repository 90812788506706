import { FC } from "react";
import { Flex, Popover } from "antd";
import { styled } from "styled-components";

import { Icon, Text } from "@lavender-ai/lav-components";

import { SecondaryText } from "~src/component/Styled";
import { PERSONALIZATION_PANEL } from "~src/strings";
import { useVerification } from "~src/customHooks";

const { INVALID_RECIPIENT, UNABLE_TO_VERIFY } = PERSONALIZATION_PANEL;

const TextWrapper = styled(Flex)`
  text-align: left;
  gap: 12px;
  padding-left: 8px;
`;

interface PopupContentProps {
  email: string;
}

const PopupContent: FC<PopupContentProps> = ({ email }) => {
  const { isInvalid, isUnverified } = useVerification();

  return (
    <Flex>
      {isInvalid(email) && (
        <Icon variant="IconAlertCircleFilled" color="negative" />
      )}
      {isUnverified(email) && (
        <Icon variant="IconAlertTriangleFilled" color="warning" />
      )}
      <TextWrapper vertical={true} style={{ maxWidth: 248 }}>
        <Text color="dark" size="subtext2">
          {isInvalid(email) ? INVALID_RECIPIENT : UNABLE_TO_VERIFY}
        </Text>
        <SecondaryText $light size="body2">
          {email}
        </SecondaryText>
      </TextWrapper>
    </Flex>
  );
};

interface UnverifiedPopupProps {
  children: React.ReactNode;
  email: string;
}

export const UnverifiedPopup: FC<UnverifiedPopupProps> = ({
  email,
  children,
}) => {
  const { isInvalid, isUnverified } = useVerification();
  const isVisible = isUnverified(email) || isInvalid(email);

  return isVisible ? (
    <Popover
      arrow={false}
      trigger={"hover"}
      content={<PopupContent email={email} />}
      placement="right"
    >
      {children}
    </Popover>
  ) : (
    children
  );
};
