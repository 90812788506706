import {
  createContext,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
  Dispatch,
} from "react";

import { BILLING_CYCLE, BILLING_PLANS } from "~src/constants";
import { isDev } from "~src/utils";

export enum SELECTABLE_PLANS {
  Starter = BILLING_PLANS.Starter,
  Pro = BILLING_PLANS.Pro,
  Teams = BILLING_PLANS.Teams,
}

type SelectedPlanDetails =
  | {
      plan: SELECTABLE_PLANS;
      cycle: BILLING_CYCLE;
    }
  | undefined;

interface IUpgradePlanContext {
  selectedPlan: SelectedPlanDetails;
  setSelectedPlan: Dispatch<SetStateAction<SelectedPlanDetails>>;
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const UpgradePlanContext = createContext<IUpgradePlanContext>({
  selectedPlan: undefined,
  setSelectedPlan: () => {},
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
});

export const useUpgradePlanModalContext = () => {
  const context = useContext(UpgradePlanContext);
  if (context === undefined) {
    throw new Error(
      "useUpgradePlanContext must be used within a UpgradePlanModalContextProvider"
    );
  }
  return context;
};

export const UpgradePlanModalContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [isModalOpen, setIsModalOpen] =
    useState<IUpgradePlanContext["isModalOpen"]>(false);
  const [selectedPlan, setSelectedPlan] =
    useState<IUpgradePlanContext["selectedPlan"]>();

  const contextValue = useMemo<IUpgradePlanContext>(
    () => ({
      selectedPlan,
      setSelectedPlan,
      isModalOpen,
      openModal: setIsModalOpen.bind(null, true),
      closeModal: () => {
        setSelectedPlan(undefined);
        setIsModalOpen(false);
      },
    }),
    [isModalOpen, selectedPlan]
  );

  return (
    <UpgradePlanContext.Provider value={contextValue}>
      {children}
    </UpgradePlanContext.Provider>
  );
};
