import { HubspotResponse } from "./hubspot";
import { SalesforceResponse } from "./salesforce";

export interface CRMResponse {
  has_gong_access: boolean;
  has_hubspot_access: boolean;
  has_salesforce_access: boolean;
  gong_data: GongResponseItem[] | null;
  hubspot_data: HubspotResponse | null;
  salesforce_data: SalesforceResponse[];
}

export interface GongResponseItem {
  id?: string;
  metaData: {
    id: string;
    scheduled: string;
    title: string;
    url: string;
    started: string;
    duration: number;
    primaryUserId: string;
    direction: string;
    system: string;
    scope: string;
    media: string;
    language: string;
    workspaceId: string;
    meetingUrl: string;
  };
  content: {
    brief?: string;
    highlights?: Array<object>;
    trackers?: object;
    topics?: Array<{ name: string; duration?: number }>;
    pointsOfInterest?: object;
    outline?: CallOutlineItem[];
  };
  collaboration: Collaboration;
  interaction?: object;
  media?: object;
  // TODO: remove checks below we can verify all calls have parties, current api responses do not
  parties?: Party[] | undefined;
  nextSteps?: Step[] | undefined;
  lavender_user?: string;
}

export interface Collaboration {
  publicComments: PublicComments[] | null;
}

export interface Party {
  id?: string;
  name: string;
  speakerId?: string;
  affiliation?: string;
  methods?: string[];
}

export interface Step {
  text: string;
}

export interface CallOutlineItemText {
  startTime?: number;
  text: string;
}

export interface CallOutlineItem {
  duration: number;
  section: string;
  startTime?: number;
  items: CallOutlineItemText[];
}

interface PublicComments {
  id: string;
  audioStartTime: number;
  audioEndTime: number;
  commenterUserId: string;
  comment: string;
  posted: string;
  inReplyTo: any;
  duringCall: boolean;
}
