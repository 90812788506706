import styled from "styled-components";

import { FullWidthFlex as GlobalFullWidthFlex } from "~src/component/Styled";

export const FullWidthFlex = styled(GlobalFullWidthFlex)`
  div:has(> .lv-load-more-button) {
    margin-bottom: 16px !important;
    margin-top: 4px !important;
  }
`;
