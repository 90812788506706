import { Flex } from "antd";
import { styled } from "styled-components";

export const StyledColumn = styled(Flex)`
  width: 100%;
  word-wrap: break-word;
  text-align: left;

  span {
    max-width: 100%;
    flex-shrink: 1;
  }
`;
