import { AvatarProps } from "@lavender-ai/lav-components";
import { LavenderIcon } from "~src/component/typings";

interface CategoryInfoType {
  color?: AvatarProps["color"];
  title: string;
  description: string;
  icon: LavenderIcon;
  thresholdMax: number;
  thresholdMin: number;
  highlightClass: string;
}

interface CategoryInfo {
  danger: CategoryInfoType;
  warning: CategoryInfoType;
}

export const CATEGORY_INFO: CategoryInfo = {
  danger: {
    color: "tart",
    title: "Necessary Fixes",
    description: "Has the highest impact on your score",
    icon: LavenderIcon.IconAlertTriangleFilled,
    thresholdMax: 99999,
    thresholdMin: 2,
    highlightClass: "lv-highlight",
  },
  warning: {
    color: "marigold",
    title: "Suggestions",
    description: "Less impact but are great to keep in mind!",
    icon: LavenderIcon.IconBulb,
    thresholdMax: 2,
    thresholdMin: 0.1,
    highlightClass: "lv-highlight-yellow",
  },
};
