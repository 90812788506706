import { FC } from "react";
import styled from "styled-components";
import { GongCall } from "../../../../../../redux/typings";
import {
  formatDate,
  formatSecondsToMinutes,
} from "../../../../../../utils/datetime";

const CommentsFrame = styled.div`
  display: flex;
  padding: 8px 8px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  border-bottom: 1px solid var(--color-neutral-purple-100, #e6e9f0);
  background: var(--color-base-White, #fff);
`;

const Title = styled.div`
  align-self: stretch;
  color: var(--color-neutral-purple-900, #0d1421);
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.1px;
`;

const Text = styled.div`
  align-self: stretch;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.25px;
`;

const PreText = styled.div`
  display: flex;
  padding: 16px 12px 4px;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  flex: none;
  order: 0;
  flex-grow: 0;
  align-self: stretch;
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.0025em;
  color: #b3b6bd;
`;

interface Props {
  call: GongCall;
}

export const Comments: FC<Props> = (props) => {
  const { call } = props;

  // Check if call.collaboration or call.collaboration.publicComments are null or undefined
  if (!call.collaboration?.publicComments) {
    return <PreText>No Comments</PreText>;
  }

  return (
    <>
      {call.collaboration.publicComments.length > 0 ? (
        <>
          <PreText>{`${call.collaboration.publicComments.length} ${call.collaboration.publicComments.length === 1 ? "comment" : "comments"}`}</PreText>
          {call.collaboration.publicComments.map((comment, index) => (
            <CommentsFrame key={index}>
              <div>
                {" "}
                <Title>{`At ${formatSecondsToMinutes(comment.audioStartTime)} • Posted ${formatDate(comment.posted)}`}</Title>
                <Text style={{ color: "#7F848F" }}>{comment.comment}</Text>
              </div>
            </CommentsFrame>
          ))}
        </>
      ) : (
        <PreText>No Comments</PreText>
      )}
    </>
  );
};
