import { Flex } from "antd";
import styled from "styled-components";

export const StyledCard = styled(Flex)`
  border-radius: var(--radius-md);
  background: var(--color-base-White);
  border: 1px solid var(--lv-panel-background-color);
  &:hover {
    border: 1px solid #d5cbf2;
  }
`;
