import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ValuePropsState,
  SetRecipientValuePropsPayload,
  SetCustomValuePropsPayload,
} from "../typings";
import { valuePropsApiSlice } from "../valuePropsApi";

import { setCustomValuePropsHelper, setValuePropsHelper } from "./helpers";

export const INIT_VALUE_PROPS_STATE: ValuePropsState = {
  connections: {},
  customValueProps: {},
  userValueProps: {},
  valueProps: {},
};

export const valuePropsSlice = createSlice({
  name: "valueProps",
  initialState: INIT_VALUE_PROPS_STATE,
  reducers: {
    setCurrentValueProps(
      state,
      action: PayloadAction<SetRecipientValuePropsPayload>
    ) {
      const { generated, email, status } = action.payload;
      if (generated === undefined) {
        return;
      }
      setValuePropsHelper(state, email, generated, status, true);
    },
    setCurrentUserValueProps(
      state,
      action: PayloadAction<SetRecipientValuePropsPayload>
    ) {
      const { generated, email, status } = action.payload;
      if (generated === undefined) {
        return;
      }
      setValuePropsHelper(state, email, generated, status, false);
    },
    setCurrentCustomValueProps(
      state,
      action: PayloadAction<SetCustomValuePropsPayload>
    ) {
      const { categories, email } = action.payload;
      setCustomValuePropsHelper(state, email, categories);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      valuePropsApiSlice.endpoints.generateValueProps.matchFulfilled,
      (state, { payload, meta }) => {
        const { generated, status } = payload;
        const { email } = meta.arg.originalArgs;
        setValuePropsHelper(state, email, generated.sections, status, true);
      }
    );
  },
});

export const valuePropsReducer = valuePropsSlice.reducer;
export const valuePropsActions = valuePropsSlice.actions;
