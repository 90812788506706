export const HEADER = {
  CUSTOM_CONTEXT: "Custom Context",
  VALUE_PROPS: "Value Props",
  EMAILS_GENERATED: (n: number) =>
    `${n} ${n === 1 ? "Email" : "Emails"} Generated`,
  GENERATING: "Generating emails...",
  SLASH_SELECTED: (n: number) => `/ ${n} selected`,
};

export const ALERT = {
  ADDING_CONTEXT:
    "Adding context will help instruct our AI to write better, more personalized emails.",
  SELECT_AT_LEAST_ONE: "Select at least 1 to continue.",
};
