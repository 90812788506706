export interface SalesforceData {
  lead: SalesforceLead;
  contact: SalesforceContact;
  company: SalesforceCompany;
}

export interface SalesforceLead {
  name?: string;
  email?: string;
  phone?: string;
  stage?: string;
}

export interface SalesforceContact {
  name?: string;
  email?: string;
  phone?: string;
}

export interface SalesforceCompany {
  name?: string;
  domain?: string;
  phone?: string;
  industry?: string;
}

export interface SalesforceResponse {
  contact_records?: {
    FirstName?: string;
    LastName?: string;
    Email?: string;
    Phone?: string;
  };
  lead_record?: {
    FirstName?: string;
    LastName?: string;
    Email?: string;
    Phone?: string;
    Status?: string;
  };
  account_record?: {
    Name?: string;
    Website?: string;
    Phone?: string;
    Industry?: string;
  };
}
