import { EventItem } from "~src/api/typings";
import { INews, PersonalizationEvent } from "~src/redux/typings";

const dateFromEventEntity = (item: EventItem) =>
  item.Entity.attributes.additionalData?.date;
const formattedTitleFromEventEntity = (item: EventItem) =>
  item.Entity.attributes.formattedTitle || item.Entity.attributes.title;
const sourceFromEventEntity = (item: EventItem) =>
  item.Entity.attributes.additionalData?.articleSource;
const summaryFromEventEntity = (item: EventItem) =>
  item.Entity.attributes.additionalData?.articleSentence || "";

export const getNews = (feed: EventItem[]): PersonalizationEvent[] =>
  feed.reduce((acc: PersonalizationEvent[], item: EventItem) => {
    acc.push({
      triggers: [],
      score: 0,
      type: "events",
      id: item.Entity.id,
      formattedTitle: formattedTitleFromEventEntity(item),
      title: item.Entity.attributes.title,
      date: dateFromEventEntity(item),
      source: sourceFromEventEntity(item),
      summary: summaryFromEventEntity(item),
      url: item.Entity.attributes?.url,
    });
    return acc;
  }, []);

export const newPendingNews = (): INews => ({
  feed: [],
  loading: true,
});

export const newRejectedNews = (): INews => ({
  feed: [],
  loading: false,
});
