import { isAxiosError } from "axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sanitize } from "dompurify";

import { editEmail } from "~src/api";
import { EditEmailPayload } from "~src/api/typings";
import {
  DEFAULT_BOOST_SCORE_SUGGESTION,
  AI_GEN_CONTENT_FALLBACK_ERR,
} from "~src/constants";
import { parseAndLogError, ErrorIdentifiers } from "~src/logger";
import { EMPTY_ARRAY } from "~src/utils";

import {
  BoostScoreState,
  BoostScoreSuggestionData,
  BoostScoreSuggestionPayload,
  NetworkStatus,
} from "./typings";
import { AppDispatch, RootState } from "./store";
import { createAppSelector } from "./selectors";

const initialState: BoostScoreState = {} as BoostScoreState;

export const getBoostScoreSuggestion = createAsyncThunk<
  BoostScoreSuggestionData | undefined,
  BoostScoreSuggestionPayload,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  "boostScore/getBoostScoreSuggestion",
  async (
    {
      controller,
      lvIdentifier,
      chromeId,
      body,
      subject,
      recommendations,
      promptName,
      stage,
    },
    { dispatch }
  ) => {
    const signal = controller.signal;
    const payload: EditEmailPayload = {
      body: sanitize(body, { USE_PROFILES: { html: true } })
        .replace(/<br>/g, "\n")
        .replace(/<br\/>/g, "\n"),
      chromeId,
      subject,
      recs:
        recommendations !== undefined && recommendations !== null
          ? [recommendations[0]]
          : undefined,
      promptName,
      stage,
    };
    dispatch(
      boostScoreSlice.actions.setPendingBoostScoreSession({
        abort: () => {
          void dispatch(abortBoostScoreSession({ controller, lvIdentifier }));
        },
        controller,
        lvIdentifier,
        chromeId,
        body,
        subject,
        recommendations,
        promptName,
        stage,
      })
    );
    try {
      const data = await editEmail(payload, signal);
      return {
        lvIdentifier,
        data,
      };
    } catch (e) {
      const parsedErr = parseAndLogError(e, ErrorIdentifiers.API_ERROR, {
        request: "boostScore/getBoostScoreSuggestion",
      });
      if (parsedErr?.message === "canceled") {
        return undefined;
      }
      if (isAxiosError(e)) {
        throw new Error(
          e.response?.data.message ?? AI_GEN_CONTENT_FALLBACK_ERR
        );
      } else {
        throw new Error(AI_GEN_CONTENT_FALLBACK_ERR);
      }
    }
  }
);

export const abortBoostScoreSession = createAsyncThunk<
  null,
  { controller: AbortController; lvIdentifier: string },
  { dispatch: AppDispatch; state: RootState }
>(
  "boostScore/abortBoostScoreSession",
  ({ controller, lvIdentifier }, { dispatch }) => {
    controller.abort();
    dispatch(boostScoreSlice.actions.resetBoostScoreSession({ lvIdentifier }));
    return null;
  }
);

const boostScoreSlice = createSlice({
  name: "boostScore",
  initialState,
  reducers: {
    resetBoostScoreSession(
      state,
      { payload }: PayloadAction<{ lvIdentifier: string }>
    ) {
      const { lvIdentifier } = payload;
      state[lvIdentifier] = {
        recommendations: EMPTY_ARRAY,
        abort: () => {},
        boostScoreError: "",
        boostScoreSuggestion: DEFAULT_BOOST_SCORE_SUGGESTION,
        boostScoreStatus: NetworkStatus.idle,
        promptName: "",
        stage: "",
      };
    },
    setPendingBoostScoreSession(
      state,
      {
        payload,
      }: PayloadAction<BoostScoreSuggestionPayload & { abort: () => void }>
    ) {
      const { abort, lvIdentifier, recommendations, promptName, stage } =
        payload;
      state[lvIdentifier] = {
        recommendations,
        abort,
        boostScoreError: "",
        boostScoreSuggestion: DEFAULT_BOOST_SCORE_SUGGESTION,
        boostScoreStatus: NetworkStatus.loading,
        promptName,
        stage,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getBoostScoreSuggestion.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }
        const {
          lvIdentifier,
          data: { bypassed_ai, email = "", subject = "" },
        } = payload;
        state[lvIdentifier].boostScoreSuggestion = {
          bypassed_ai,
          email,
          subject,
        };
        state[lvIdentifier].boostScoreError = "";
        state[lvIdentifier].abort = () => {};
        state[lvIdentifier].boostScoreStatus = NetworkStatus.success;
      })
      .addCase(getBoostScoreSuggestion.rejected, (state, { error, meta }) => {
        const { lvIdentifier } = meta.arg;
        state[lvIdentifier].abort = () => {};
        state[lvIdentifier].boostScoreSuggestion =
          DEFAULT_BOOST_SCORE_SUGGESTION;
        state[lvIdentifier].boostScoreStatus = NetworkStatus.failed;
        state[lvIdentifier].boostScoreError =
          error.message ?? AI_GEN_CONTENT_FALLBACK_ERR;
      });
  },
});

export const boostScoreReducer = boostScoreSlice.reducer;

const selectBoostScore = (state: RootState) => state.boostScore;
const selectSessionId = (_state: RootState, sessionId: lvIdentifier) =>
  sessionId;
export const selectBoostScoreAbortBySessionId = createAppSelector(
  [selectBoostScore, selectSessionId],
  (boostScore, sessionId) => boostScore[sessionId]?.abort ?? (() => {})
);
export const selectBoostScoreRecommendationsBySessionId = createAppSelector(
  [selectBoostScore, selectSessionId],
  (boostScore, sessionId) =>
    boostScore[sessionId]?.recommendations ?? EMPTY_ARRAY
);
export const selectBoostScorePromptNameBySessionId = createAppSelector(
  [selectBoostScore, selectSessionId],
  (boostScore, sessionId) => boostScore[sessionId]?.promptName ?? ""
);
export const selectBoostScoreStatusBySessionId = createAppSelector(
  [selectBoostScore, selectSessionId],
  (boostScore, sessionId) =>
    boostScore[sessionId]?.boostScoreStatus ?? NetworkStatus.idle
);
export const selectBoostScoreErrorBySessionId = createAppSelector(
  [selectBoostScore, selectSessionId],
  (boostScore, sessionId) =>
    boostScore[sessionId]?.boostScoreError ?? AI_GEN_CONTENT_FALLBACK_ERR
);
export const selectBoostScoreSuggestionBySessionId = createAppSelector(
  [selectBoostScore, selectSessionId],
  (boostScore, sessionId) =>
    boostScore[sessionId]?.boostScoreSuggestion ??
    DEFAULT_BOOST_SCORE_SUGGESTION
);
