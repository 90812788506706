import { XOR } from "ts-xor";

import { UserAccount } from "../../redux/typings";

export const isError = (
  response: UserAccountResponse
): response is UserAccountErrorResponse =>
  ("status" in response && response.status === "error") || "error" in response;

export type UserAccountResponse = XOR<
  UserAccountSuccessResponse,
  UserAccountErrorResponse
>;

export interface UserAccountSuccessResponse extends UserAccount {}

export interface UserAccountErrorResponse {
  message: string;
  status: "error";
}
