import { useLvIdentifier } from ".";

import { extractUserDataForChat, newChat } from "~src/utils";
import { useAppSelector, selectFeatures } from "~src/redux";
import { Chat, ChatTool, ChatUser } from "~src/redux/typings";

interface IUseChat {
  chat: Chat;
  initialized: boolean;
  tools: {
    [ChatTool.GoogleWebSearch]: boolean;
  };
  user: ChatUser;
}

export const useChat = (): IUseChat => {
  const lvIdentifier = useLvIdentifier();
  const user = useAppSelector(
    ({
      userAccount: {
        settings: { user },
      },
    }) => user
  );

  const flags = useAppSelector(selectFeatures);
  const tools = {
    [ChatTool.GoogleWebSearch]: !!flags[ChatTool.GoogleWebSearch],
  };
  const chat = useAppSelector((state) => state.chats[lvIdentifier]);

  return {
    chat: chat || newChat(),
    initialized: !!chat,
    tools,
    user: extractUserDataForChat(user),
  };
};
