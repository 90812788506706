import React from "react";

interface CarouselContextType {
  next: (slide: string) => void;
  prev: () => void;
  //INFO - add when more than just extension settings sub page
}

// Initial values are provided as a fallback.
// Replace them with your own initial values or throw an error if there's no provider
export const CarouselContext = React.createContext<CarouselContextType>({
  next: () => {},
  prev: () => {},
});
