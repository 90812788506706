import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { snakeCase as snakeCaseKeys } from "change-case/keys";

import { APIEndpoints } from "~src/api";
import {
  UpdateSettingsResponse,
  UpdateSettingsPayload,
  APIResponseStatus,
} from "~src/api/typings";
import { ErrorIdentifiers, parseAndLogError } from "~src/logger";
import { CONFIRM_INFO_FORM } from "~src/strings";
import { isLavenderAPIError } from "~src/typeGuards";
import { feedActions } from "./feed";

export const profileApiSlice = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.BACKEND_API}/api/` }),
  endpoints: (builder) => ({
    updateProfileSettings: builder.mutation<
      UpdateSettingsResponse,
      UpdateSettingsPayload
    >({
      query: (payload) => {
        const transformRequestBody = (
          originalPayload: UpdateSettingsPayload
        ) => {
          return snakeCaseKeys(originalPayload);
        };

        const transformedPayload = transformRequestBody(payload);

        return {
          url: APIEndpoints.profileSettings,
          method: "POST",
          body: transformedPayload,
        };
      },
      transformErrorResponse: (response, meta, arg): UpdateSettingsResponse => {
        parseAndLogError(response, ErrorIdentifiers.API_ERROR, {
          email: arg.email,
          method: meta?.request.method,
          url: meta?.request.url,
        });
        return isLavenderAPIError(response?.data)
          ? response?.data
          : {
              message: CONFIRM_INFO_FORM.FALLBACK_ERROR,
              status: APIResponseStatus.error,
              error: "500",
            };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(feedActions.updateProfileSettings(arg));
      },
    }),
  }),
});

export const profileApiReducer = profileApiSlice.reducer;
export const { useUpdateProfileSettingsMutation } = profileApiSlice;
