{
  "FontSize": [
    "header3",
    "header2",
    "header1",
    "subtext5",
    "subtext4",
    "subtext3",
    "subtext2",
    "subtext1",
    "body3",
    "body2",
    "body1"
  ],
  "CustomIconsType": [
    "IconBeaker",
    "IconFilter",
    "IconFilterCircle",
    "IconGongLogo",
    "IconHubspotLogo",
    "IconLavenderLogo",
    "IconMailReply",
    "IconSalesforce",
    "IconUnion"
  ],
  "ComponentState": [
    "initial",
    "focus",
    "disabled",
    "loading",
    "error"
  ],
  "ComponentVariant": [
    "default",
    "primary",
    "secondary",
    "plain",
    "danger"
  ],
  "ComponentSize": [
    "small",
    "medium",
    "large"
  ],
  "ComponentBrandColor": [
    "lavender",
    "black",
    "white",
    "dark",
    "positive",
    "negative",
    "warning",
    "pear",
    "sapphire",
    "marigold",
    "peony",
    "tart",
    "surf"
  ],
  "ComponentRadius": [
    "none",
    "sm",
    "md",
    "lg",
    "xl",
    "2xl"
  ],
  "Position": [
    "top-right",
    "top-left",
    "bottom-right",
    "bottom-left"
  ]
}