import { styled } from "styled-components";
import { Dropdown, Flex } from "antd";

import { Tag, Tooltip } from "@lavender-ai/lav-components";

import { StageDropdown } from "~src/component/Molecules";
import { useLvIdentifier } from "~src/customHooks";
import {
  selectEmailAnalysis,
  selectEmailData,
  selectFeatures,
  useAppSelector,
} from "~src/redux";
import { EmailAnalysisStage as Stage, NetworkStatus } from "~src/redux/typings";
import {
  segmentEvent,
  SegmentEventLocations,
  SegmentEventNames,
} from "~src/utils";
import { ScoreDropdown } from "~src/component/Molecules/Dropdown/ScoreDropdown/ScoreDropdown";

import { useReadTimeDuration } from "../../../utils";
import { ScoreDropdownContent } from ".";
import {
  useScoreColorGroup,
  useTrackWritingTime,
  useUpdateStageCB,
} from "../utils";

export const TimerBar = () => {
  const lvIdentifier = useLvIdentifier();
  const { stage, status } = useAppSelector(selectEmailAnalysis(lvIdentifier));
  const { stageOverwrite, writingStartTime } = useAppSelector(
    selectEmailData(lvIdentifier)
  );
  const { isOver5Minutes, writingDuration } =
    useTrackWritingTime(writingStartTime);
  const readingTime = useReadTimeDuration();
  const updateStage = useUpdateStageCB();
  const { scoringOverwrite: scoringOverwriteFeature } =
    useAppSelector(selectFeatures);
  const { isSuccess } = useScoreColorGroup();

  const writingTagColor = isOver5Minutes
    ? "var(--color-tart-500)"
    : isSuccess
      ? "white"
      : "var(--color-neutral-purple-900)";

  const writingTagBGColor = isOver5Minutes
    ? "rgb(250, 218, 214)"
    : isSuccess
      ? "rgba(255, 255, 255, 0.1)"
      : "white";

  return (
    <TimerBarBox>
      <TimersBox>
        <Tooltip hideArrow placement="top" title="Writing Time">
          <span
            onMouseEnter={() => {
              segmentEvent(
                SegmentEventNames.WritingTimeHovered,
                SegmentEventLocations.EmailCoach
              );
            }}
          >
            <Tag
              className={isOver5Minutes ? "lv-tag-red-prefix" : ""}
              color={isSuccess ? undefined : "outline"}
              style={{
                color: writingTagColor,
                background: writingTagBGColor,
                display: "flex",
                width: 70,
                height: 24,
                padding: "0px 4px",
                alignItems: "center",
                borderRadius: 8,
              }}
              prefix="IconWriting"
            >
              {writingDuration}
            </Tag>
          </span>
        </Tooltip>

        <Tooltip hideArrow placement="top" title="Reading Time">
          <span
            onMouseEnter={() => {
              segmentEvent(
                SegmentEventNames.ReadingTimeHovered,
                SegmentEventLocations.EmailCoach
              );
            }}
          >
            <Tag
              color={isSuccess ? undefined : "outline"}
              prefix="IconEye"
              style={{
                color: isSuccess ? "white" : "var(--color-neutral-purple-900)",
                background: isSuccess ? "rgba(255, 255, 255, 0.1)" : "white",
                display: "flex",
                height: 24,
                padding: "0px 4px",
                alignItems: "center",
                borderRadius: 8,
              }}
            >
              {readingTime}
            </Tag>
          </span>
        </Tooltip>
      </TimersBox>
      <DropdownBox>
        {scoringOverwriteFeature ? (
          <Dropdown trigger={["click"]} overlay={<ScoreDropdown />}>
            <div>
              <ScoreDropdownContent />
            </div>
          </Dropdown>
        ) : (
          <StageDropdown
            isDisabled={
              stageOverwrite !== undefined &&
              stage !== stageOverwrite &&
              status === NetworkStatus.loading
            }
            placement="bottomRight"
            stage={stage}
            onSelect={({ key }) => {
              updateStage(key as Stage);
            }}
          />
        )}
      </DropdownBox>
    </TimerBarBox>
  );
};

const TimerBarBox = styled(Flex)`
  display: flex;
  padding: var(--size-32, 32px) 16px 12px 20px;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  .lv-tag-red-prefix svg {
    stroke: var(--color-tart-500);
    border: none;
  }
`;

const TimersBox = styled.div`
  display: flex;
  align-items: flex-start;
`;

const DropdownBox = styled.div`
  display: flex;
`;
