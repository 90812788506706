import {
  EditorModeButton,
  ScoreSection,
  TimerBar,
  TitleSection,
  TonesAndBoostButton,
} from "./components";
import { useClearAnalysisStageOnFail, useScoreColorGroup } from "./utils";
import { CoachHeader } from "./EmailCoachHeader.styled";

export const EmailCoachHeader = () => {
  const { isSuccess } = useScoreColorGroup();
  useClearAnalysisStageOnFail();

  return (
    <CoachHeader $dark={isSuccess}>
      <TitleSection />
      <ScoreSection />
      <TimerBar />
      <EditorModeButton />
      <TonesAndBoostButton />
    </CoachHeader>
  );
};
