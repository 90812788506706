import { useEffect, useState } from "react";
import { useLvIdentifier, useAuthentication } from "../../customHooks";
import {
  handlePanelAnimation,
  removeExtensionElements,
  setupChromeStorageListenerForLogin,
  isFeaturePayWalled,
} from "./utils";
import { Button, Text } from "@lavender-ai/lav-components";
import {
  mainPanelAction,
  localStorageActions,
  configActions,
} from "../../redux";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Flex, Card, Space } from "antd";
import { FRONTEND_URLS } from "~src/constants";

export const LoginTeamsAccount = () => {
  //INFO: don't render this view if you are not on a CRM teams webpage
  const isTeam = useAppSelector((state) => state.config.settings?.isTeam);
  const isPayWalled = isFeaturePayWalled("UsingCRM");
  const isAuthenticated = useAuthentication();
  const lvIdentifier = useLvIdentifier();
  const isOpen = useAppSelector(
    (state) => state.mainPanel[lvIdentifier].isOpen
  );
  const defaultClass = "lv-panel";
  const [classNames, changeClass] = useState(defaultClass);

  useEffect(() => {
    handlePanelAnimation(isOpen, changeClass, defaultClass);
  }, [isOpen]);

  const platform = useAppSelector((state) => state.config.settings?.platform);
  const platformDisplayName = useAppSelector(
    (state) => state.config.settings?.platformDisplayName
  );

  // INFO: for click events use useDispatch from react-redux
  const dispatch = useAppDispatch();

  // INFO: Don't render this view if you are already login with a chromeId and you already paid for this feature.
  if (!isTeam || (isAuthenticated && !isPayWalled)) {
    return null;
  }

  // INFO: for initial state don't do any open or close animation just show it closed
  return (
    <div className={classNames}>
      <Flex
        vertical={true}
        className="lv-fill-height"
        justify="space-between"
        style={{
          width: 375,
          background: "var(--lv-panel-background-color)",
        }}
      >
        <Space size={24} direction={"vertical"} style={{ margin: "65px 24px" }}>
          <Text color="black" size="subtext5">
            Upgrade your account to access {platformDisplayName}.
          </Text>
          <Text size="body2">
            Get the most out of {platformDisplayName} by upgrading to Pro or
            Teams, to integrate Lavender seamlessly into your{" "}
            {platformDisplayName} workflow.
          </Text>
        </Space>
        <Card
          bordered={false}
          style={{
            boxShadow: "unset",
            height: "191px",
            background: "var(--lv-panel-background-color)",
          }}
          bodyStyle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            paddingBottom: "unset",
            textAlign: "center",
          }}
        >
          <Button
            variant="primary"
            size="large"
            onClick={openDashboardTeamsIntegrationLink}
          >
            Upgrade account
          </Button>
          <Button
            variant="default"
            size="large"
            onClick={() => {
              if (platform) {
                addPlatformToIgoreList(platform, lvIdentifier, dispatch);
              }
            }}
          >
            Don&apos;t ask again
          </Button>
          <Text size="subtext1">Already have Lavender Pro or Teams?</Text>
          <a onClick={openDashboardLoginLink}>
            <Text color="lavender" size="subtext1">
              Login to your account
            </Text>
          </a>
        </Card>
      </Flex>
    </div>
  );
};
const openDashboardTeamsIntegrationLink = () => {
  setupChromeStorageListenerForLogin();
  window.open(FRONTEND_URLS.teamsIntegration, "_blank");
};
const openDashboardLoginLink = () => {
  setupChromeStorageListenerForLogin();
  window.open(FRONTEND_URLS.login, "_blank");
};
const addPlatformToIgoreList = (
  platform: string,
  lvIdentifier: lvIdentifier,
  // eslint-disable-next-line @typescript-eslint/ban-types
  dispatch: Function
) => {
  return () => {
    dispatch(localStorageActions.ignoreItem(platform));
    dispatch(mainPanelAction.close(lvIdentifier));
    removeExtensionElements();
    dispatch(configActions.clearMainPollingInterval());
  };
};
