import { FlexProps } from "antd";
import { styled } from "styled-components";

import { PanelBackgroundFlex } from "~src/component/Styled";

export const StyledPanelBlockContainer = styled(PanelBackgroundFlex)`
  align-self: stretch;
  border-radius: 0;
  padding: 0px 20px 8px 20px;
`;

interface Props extends Pick<FlexProps, "gap"> {
  children: React.ReactNode;
}

export const PanelBlockContainer = ({ children, gap = "middle" }: Props) => (
  <StyledPanelBlockContainer
    align="flex-start"
    className="lv-panel-block-container"
    gap={gap}
    vertical
  >
    {children}
  </StyledPanelBlockContainer>
);
