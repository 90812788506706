// INFO: this file abstracts the logic for the main panel.tsx animation due to
// Jest having issues with importing this line
// import { useSelector } from "react-redux/es/hooks/useSelector";
// on panel.tsx, this became an drag on issue that is not related to this change which maybe address
// at a different ticket if it blocks future unit test development

import { store } from "../../redux/store";
// INFO: to avoid double util imports on the same file
export * from "../../utils";
export { getEmailsBefore } from "../Threads/components/scrapeEmailData";

export const handlePanelAnimation = (
  isOpen: string,
  changeClass: React.Dispatch<React.SetStateAction<string>>,
  defaultClass: string
) => {
  const state = store.getState();
  const { mainPanelAnimationInterval } = state.config.settings?.timing ?? {};
  if (isOpen !== "initial") {
    if (isOpen === "open") {
      changeClass(`${defaultClass} lv-open-animation`);
    } else if (isOpen === "closed") {
      changeClass(`${defaultClass} lv-close-animation`);
      // INFO: after animation remove the close animation class for Gmail full screen switching back and forth
      setTimeout(() => {
        changeClass(defaultClass);
      }, mainPanelAnimationInterval);
    }
  }
};
