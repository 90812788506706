import styled from "styled-components";

export { CloseButton } from "../SelectUpgradePlanModal/SelectUpgradePlanModal.styled";

export const Container = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  width: 343px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: var(--radius-lrg, 16px);
  background: var(--color-base-White, #fff);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const HeaderContainer = styled.div`
  display: flex;
  padding: var(--size-28, 28px) var(--size-24, 24px) 0px var(--size-24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const HeaderPlan = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  justify-content: space-between;
`;

export const HeaderCost = styled.div`
  display: flex;
  align-items: center;
`;

export const CardInputContent = styled.div`
  display: flex;
  padding: 20px var(--size-24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  height: 203px;
  background: var(--color-neutral-purple-50, #f6f8fc);
  box-sizing: border-box;
  border-bottom-left-radius: var(--radius-lrg, 16px);
  border-bottom-right-radius: var(--radius-lrg, 16px);
`;
