import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Collapse, CollapseProps } from "antd";
import { styled } from "styled-components";

import { IconButton } from "@lavender-ai/lav-components";

import { LavenderIcon, PropsForStyling } from "~src/component/typings";

const panelStyle: React.CSSProperties = {
  background: "var(--color-base-White)",
  borderRadius: "16px",
  paddingTop: "4px",
  paddingBottom: "4px",
  textAlign: "left",
  width: "100%",
};

interface StyledCollapseProps {
  collapsible: boolean;
}

const StyledCollapse = styled(Collapse)<PropsForStyling<StyledCollapseProps>>`
  ${({ $collapsible: collapsible }) => !collapsible && "cursor: not-allowed;"}
`;

interface Props
  extends Pick<CollapseProps, "className" | "expandIcon" | "size"> {
  collapsible?: boolean;
  initialCollapsed?: boolean;
  item: ArrayElement<NonUndefined<CollapseProps["items"]>>;
}

export interface ItemCollapseHandle {
  disable: () => void;
  open: () => void;
  close: () => void;
}

export const ItemCollapse = forwardRef<ItemCollapseHandle, Props>(
  (
    { className, collapsible = true, initialCollapsed, size, item, expandIcon },
    ref
  ) => {
    const expandIconComponent = ({
      isActive,
      collapsible,
    }: {
      collapsible?: string | undefined;
      isActive?: boolean | undefined;
    }) => (
      <CollapseIconButton
        isActive={isActive}
        collapsible={collapsible}
        ref={ref}
      />
    );
    return (
      <StyledCollapse
        bordered={false}
        className={className}
        collapsible={!collapsible ? "icon" : undefined}
        $collapsible={collapsible}
        defaultActiveKey={
          initialCollapsed && item.key !== undefined && collapsible
            ? item.key.toString()
            : undefined
        }
        expandIcon={expandIcon ?? expandIconComponent}
        expandIconPosition="end"
        ghost
        items={[item]}
        size={size}
        style={panelStyle}
      />
    );
  }
);

ItemCollapse.displayName = "ItemCollapse";

interface CollapseIconButtonProps {
  isActive: boolean | undefined;
  collapsible: string | undefined;
}

const CollapseIconButton = forwardRef<
  ItemCollapseHandle,
  CollapseIconButtonProps
>(({ isActive, collapsible }, ref) => {
  const internalRef = useRef<HTMLButtonElement>(null);
  const [isDisabled, setIsDisabled] = useState(collapsible === "icon");

  useImperativeHandle(ref, () => ({
    ...ref,
    close: () => {
      if (isActive) {
        internalRef.current?.click();
      }
    },
    disable: () => {
      setIsDisabled(true);
    },
    open: () => {
      if (!isDisabled && !isActive) {
        internalRef.current?.click();
      }
    },
  }));

  useEffect(() => {
    collapsible !== "icon" && setIsDisabled(false);
  }, [isActive, collapsible]);
  return isActive ? (
    <IconButton
      icon={LavenderIcon.IconChevronUp}
      ref={internalRef}
      size="small"
      state={isDisabled ? "disabled" : undefined}
    />
  ) : (
    <IconButton
      icon={LavenderIcon.IconChevronDown}
      ref={internalRef}
      size="small"
      state={isDisabled ? "disabled" : undefined}
    />
  );
});

CollapseIconButton.displayName = "CollapseIconButton";
