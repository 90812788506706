import { CompanyInvestment } from "../api/typings";
import { cleanNum } from "./price";

export const getTotalInvestmentsString = (
  investments: CompanyInvestment[],
  raised: number
) => {
  const investment = investments[0];
  let investmentString = "";
  if (investment === undefined || (!investment.series && !raised)) {
    return investmentString;
  }
  if (investment.series) {
    investmentString += investment.series;
  }

  if (raised) {
    investmentString += investment.series
      ? ` - ${cleanNum(raised)}`
      : cleanNum(raised);
    return investmentString;
  }

  const calculatedRaised = calculateRaised(investments);

  if (calculatedRaised) {
    investmentString += investment.series
      ? ` - ${cleanNum(calculatedRaised)}`
      : `${cleanNum(calculatedRaised)}`;
  }

  return investmentString;
};

export const calculateRaised = (investments: CompanyInvestment[]) =>
  investments.reduce((acc, investment) => {
    if (investment.amount.value) {
      acc += investment.amount.value;
    }
    return acc;
  }, 0);

export const extractInvestors = (investments: CompanyInvestment[]) => {
  const investorsMap = new Map<string, string>();

  investments.forEach((investment) => {
    investment.investors?.forEach((investor) => {
      if (!investorsMap.has(investor.name)) {
        investorsMap.set(investor.name, investor.summary);
      }
    });
  });

  return investorsMap;
};

export const findCommonInvestors = (
  investorsMap1: Map<string, string>,
  investorsMap2: Map<string, string>
) => {
  const commonInvestors: { name: string; summary: string }[] = [];

  investorsMap1.forEach((summary, investorName) => {
    if (
      investorsMap2.has(investorName) &&
      !["Undisclosed", "Other"].includes(investorName)
    ) {
      commonInvestors.push({
        name: investorName,
        summary,
      });
    }
  });

  return commonInvestors;
};
