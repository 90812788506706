import { SUGGESTIONS } from "../strings";

export const AUTOSEND_SUGGESTIONS = [
  SUGGESTIONS.ASK_A_QUESTION,
  SUGGESTIONS.ASK_FEWER_QUESTIONS,
  SUGGESTIONS.USE_SHORTER_PARAGRAPHS,
  SUGGESTIONS.USE_FEWER_WORDS,
  SUGGESTIONS.SHORTEN_THE_EMAIL,
  SUGGESTIONS.REMOVE_OR_SHORTEN_SENTENCES,
];
