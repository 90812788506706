import React, { useEffect, useState, type ReactNode, forwardRef } from "react";
import { Flex } from "antd";
import { useLvIdentifier } from "../../customHooks";
import { handlePanelAnimation } from "./utils";
import { mainPanelAction, useAppDispatch } from "../../redux";
import { useAppSelector } from "../../redux/hooks";

interface Props {
  children: ReactNode;
  closeOnlySlidingPanel?: boolean;
  style?: React.CSSProperties;
}

type Ref = HTMLDivElement;

export const SlidingPanel = forwardRef<Ref, Props>(
  ({ children, closeOnlySlidingPanel = false, style }, ref) => {
    const lvIdentifier = useLvIdentifier();
    const isOpen = useAppSelector(
      (state) => state.mainPanel[lvIdentifier].isOpen
    );
    const defaultClass = "lv-view";
    const [classNames, changeClass] = useState(defaultClass);

    useEffect(() => {
      handlePanelAnimation(isOpen, changeClass, defaultClass);
      // INFO: closeOnlySlidingPanel is used to close the sliding panel without closing the main panel
      // this is for external click events form other components
      if (closeOnlySlidingPanel) {
        changeClass("lv-view lv-close-animation");
      }
    }, [isOpen, closeOnlySlidingPanel]);
    const mainPanelAnimationInterval = useAppSelector(
      (state) => state.config.settings?.timing?.mainPanelAnimationInterval
    );
    const dispatch = useAppDispatch();
    // INFO: preload the backeround image
    if (isOpen === "open") {
      setTimeout(() => {
        dispatch(
          mainPanelAction.setBackGround({ lvIdentifier, showBackGround: true })
        );
      }, mainPanelAnimationInterval);
    }
    return (
      <Flex
        className={classNames}
        ref={ref}
        vertical={true}
        justify="space-between"
        align="center"
        style={{
          minHeight: "100%",
          backgroundColor: "#F6F8FC",
          ...style,
        }}
      >
        {children}
      </Flex>
    );
  }
);

SlidingPanel.displayName = "SlidingPanel";
