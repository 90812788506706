import Lottie from "react-lottie";

import MenuSuccessJSON from "./menu_success.json";
import MenuWarningJSON from "./menu_warning.json";
import MenuDangerJSON from "./menu_danger.json";

export const EmailScore = ({
  width,
  height,
  score,
}: {
  width: number;
  height: number;
  score: number;
}) => {
  let animationData = MenuSuccessJSON;
  if (score < 90) animationData = MenuWarningJSON;
  if (score < 70) animationData = MenuDangerJSON;
  return (
    <Lottie
      width={width}
      height={height}
      options={{
        loop: false,
        autoplay: true,
        animationData,
      }}
    />
  );
};
