import { store } from "~src/redux/store";
import { emailAnalysisAction } from "../redux";
import Mark from "mark.js";
import { getBodyElement } from "../utils";

export const setupHighlighter = (
  emailContainer: HTMLElement,
  locationSelector: string,
  lvIdentifier: lvIdentifier,
  bodyIframe?: string,
  iframeChildSelector?: string
) => {
  // INFO: we pass the email container and not the email body
  // because the email body is appended asynchronously from Gmail
  const emailBodyPollingInterval = setInterval(() => {
    const subscriber = getBodyContainer(emailContainer, bodyIframe);
    const emailBody = getBodyElement(
      bodyIframe,
      locationSelector,
      iframeChildSelector,
      emailContainer
    );

    if (emailBody !== null && subscriber !== null) {
      if (bodyIframe) {
        const state = store.getState();
        const lavender_css = state.config?.settings?.lavender_css || "";
        const style = document.createElement("style");
        style.textContent = lavender_css;
        emailBody.ownerDocument.head.append(style);
      }

      const mutationObserver = getMutationObserver(
        emailContainer,
        locationSelector,
        lvIdentifier
      );

      if (emailBody instanceof HTMLElement) {
        subscriber.addEventListener(
          "copy",
          onCopyCutEvent(mutationObserver, emailBody)
        );
        subscriber.addEventListener(
          "cut",
          onCopyCutEvent(mutationObserver, emailBody)
        );
      }
      subscriber.addEventListener(
        "paste",
        onPasteEvent(subscriber, locationSelector, lvIdentifier)
      );
      subscriber.addEventListener("input", () => {
        const markInstance = new Mark(subscriber);
        markInstance.unmark();
      });
      //mutationObserver.observe(emailBody, { subtree: true, childList: true });
      clearInterval(emailBodyPollingInterval);

      // INFO: setup removing highlight on copy/cut/paste
      shouldUnMarkText(subscriber);

      // step 1 : get the highlighted email body text from the Redux store
      // TODO: When the api is ready, we will need to get the highlighted email body text from the api
      // INFO: because we need the access to the emailBody element we can't create this a an middleware
      let prevHighlights: string[] | null = null;
      store.subscribe(() => {
        const state = store.getState();
        const highlights = state.highlights[lvIdentifier]?.highlights;
        if (
          highlights !== undefined &&
          JSON.stringify(highlights) !== JSON.stringify(prevHighlights)
        ) {
          if (highlights.length === 0) {
            unMarkText(subscriber);
          } else {
            parseForHighlight(subscriber, locationSelector, lvIdentifier);
          }
          prevHighlights = highlights;
        }

        //NOTE: I think this is no longer needed?
        const hasUpdates = state.emailAnalysis[lvIdentifier].hasUpdates;
        if (hasUpdates) {
          store.dispatch(emailAnalysisAction.finishedUpdating(lvIdentifier));
        }
      });
    }
  }, 500);
};
const unMarkText = (container: HTMLElement) => {
  const markInstance = new Mark(container);
  markInstance.unmark();
};

const getBodyContainer = (
  emailContainer: HTMLElement,
  bodyIframe: string | undefined,
  iframeChildSelector?: string
): HTMLElement | null => {
  if (!bodyIframe) {
    return emailContainer;
  }
  const element = getBodyElement(
    bodyIframe,
    "body",
    iframeChildSelector,
    emailContainer
  );
  return element;
};
const parseForHighlight = (
  emailContainer: HTMLElement,
  locationSelector: string,
  lvIdentifier: lvIdentifier
) => {
  const state = store.getState();
  const highlights = state.highlights[lvIdentifier]?.highlights;
  const highlightClass: string =
    state.highlights[lvIdentifier]?.highlightClass || "lv-highlight";
  const recommendationId =
    state.highlights[lvIdentifier]?.recommendationId || "lv-general-id";
  const body = state.emailData[lvIdentifier]?.body;
  // const highlightList =
  //   state.emailAnalysis[lvIdentifier]?.highlights.ToneSentences;

  // INFO : A draft email body text will be empty on page load and appended asynchronously from Gmail
  // We use the the stored email body text to check if the email body text is on the DOM.
  if (body) {
    // step 2: call mark js to highlight the email body text
    let emailBodyTextElement: HTMLElement | null = null;
    // // INFO: for Iframe, copy/cut/paste events don't bubble up pass the body tag
    if (locationSelector === "body") {
      emailBodyTextElement = emailContainer;
    } else {
      emailBodyTextElement = emailContainer.querySelector(locationSelector);
    }

    // INFO: if the email body text is not on the DOM, we don't do anything
    if (emailBodyTextElement === null) {
      return;
    }

    const markInstance = new Mark(emailBodyTextElement);
    //if is iterable
    // @ts-expect-error needs fix
    if (highlights instanceof Array<string>) {
      highlights.forEach((sentence: string) => {
        // step 3: append the highlighted email body text back to the DOM
        markInstance.mark(sentence, {
          element: "span",
          className: highlightClass,
          separateWordSearch: false,
          acrossElements: true,
          diacritics: false,
          synonyms: {
            " ,": ",",
            " .": ".",
            " ?": "?",
            " !": "!",
          },
          each: function (node) {
            node.id = recommendationId;
          },
        });
      });
    }
  }
};
const shouldUnMarkText = (bodyElement) => {
  const state = store.getState();
  const sendButton = state.config.settings?.selectors?.sendButton;
  const closeButton = state.config.settings?.selectors?.closeButton;
  const sendButtonList = document.querySelectorAll(
    `${closeButton},${sendButton}`
  );
  sendButtonList.forEach((sendButton) => {
    sendButton.addEventListener("click", () => {
      /* intentional logging */
      console.debug("sendButton clicked");
      if (bodyElement) {
        const markInstance = new Mark(bodyElement);
        markInstance.unmark();
      }
    });
  });
};

// INFO: In Gmail, because the Paste event fires after the new Node is appended to the DOM and does not contain the dom that was pasted
// the only way to clean up the pasted text is to use a MutationObserver
const getMutationObserver = (
  emailContainer: HTMLElement,
  locationSelector: string,
  lvIdentifier: lvIdentifier
) =>
  new MutationObserver((mutations, mutationObserver) => {
    mutations.forEach((mutation) => {
      if (mutation.type === "childList") {
        const text = mutation.addedNodes[0]?.textContent;
        // INFO : Gmail will convert our purple underline to black when copying and pasting make it a permanent style of the user's email
        // in where we are not able to use unmark to remove it. This will sanitize the copied text.
        if (
          text === copyText &&
          // INFO: addedNodes first element is always the node that has the converted Style by gmail
          // even when copying multiple lines of text of different groups of HTMLElements
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          (mutation.addedNodes[0] as HTMLElement).style
        ) {
          (mutation.addedNodes[0] as HTMLElement).style.color = "";
          (mutation.addedNodes[0] as HTMLElement).style.textDecorationLine = "";
          (mutation.addedNodes[0] as HTMLElement).style.backgroundColor = "";
        }
        // INFO: always parse the email body for highlights when the email body is updated do deal with this edgecase
        // In where Gmail "mutations[n].target.innerText" has added prefix to each copy "\n"
        parseForHighlight(emailContainer, locationSelector, lvIdentifier);
        mutationObserver.disconnect();
      }
    });
  });
// INFO : It may be overkill to create a new redux store for this one variable.
// If we need more variables in the future, we can refactor this to a new redux store
let copyText = "";

const onCopyCutEvent =
  (mutationObserver: MutationObserver, emailBody: HTMLElement) => (event) => {
    mutationObserver.observe(emailBody, { subtree: true, childList: true });
    copyText = event.target.innerText;
  };
const onPasteEvent =
  (
    emailContainer: HTMLElement,
    locationSelector: string,
    lvIdentifier: lvIdentifier
  ) =>
  () => {
    parseForHighlight(emailContainer, locationSelector, lvIdentifier);
  };
