import { Avatar, Icon } from "@lavender-ai/lav-components";

import { LavenderIcon } from "~src/component/typings";
import { NetworkStatus } from "~src/redux/typings";

interface Props {
  size?: "small" | "large" | "medium";
  status?: NetworkStatus;
}

export const AssistantAvatar = ({ size = "medium", status }: Props) => {
  return (
    <Avatar
      {...(status === NetworkStatus.failed && {
        badge: {
          count: (
            <Avatar
              color="white"
              icon={
                <Icon
                  color="negative"
                  variant={LavenderIcon.IconCircleXFilled}
                  size="extra-small"
                />
              }
              size="extra-small"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          ),
        },
      })}
      icon={
        <Icon
          color="white"
          size={
            (typeof size === "string" && ["medium", "small"].includes(size)) ||
            (typeof size === "number" && size < 32)
              ? "small"
              : "medium"
          }
          variant={LavenderIcon.IconBrandOpenAI}
        />
      }
      size={size}
      style={{
        background: "var(--color-neutral-purple-800)",
      }}
    />
  );
};
