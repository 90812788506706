import { useContext } from "react";
import { Collapse, Space } from "antd";
import { styled } from "styled-components";

import { Avatar, Icon, Text } from "@lavender-ai/lav-components";

import { PointerCursorSpace } from "~src/component/Styled";

import { FrameworkContext } from "./FrameworkContext";

// INFO: The top of the Panel with the back button
const ModalHeader = () => {
  const { prev, frameworkCategory } = useContext(FrameworkContext);
  return (
    <PointerCursorSpace
      className="lv-cta"
      align="center"
      style={{ width: "100%", padding: "16px 20px" }}
      onClick={prev}
      size={12}
    >
      <Avatar
        icon={<Icon size="extra-small" variant="IconChevronLeft" />}
        size="small"
        shape="circle"
        color="neutral-200"
      />
      <Text color="black" size="subtext3">
        {frameworkCategory.name}
      </Text>
    </PointerCursorSpace>
  );
};

const StyledCollapse = styled(Collapse)`
  background-color: #ffffff;
  & .ant-collapse {
    border-radius: var(--radius-md, 8px);
  }
  // INFO: card header style
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 8px;
    flex-direction: row-reverse;
    align-items: stretch !important;
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--lv-panel-background-color);
    background: var(--lv-panel-background-color);
  }
  // INFO: when the card is expanded
  &.ant-collapse
    > .ant-collapse-item.ant-collapse-item-active
    > .ant-collapse-header {
    background: var(--color-base-White, #fff);
  }
  // INFO: chevron style
  & .ant-collapse-arrow svg {
    transform: rotate(90deg);
  }
  // INFO: to override the antd chevron style attribute when the card is active
  & .ant-collapse-item.ant-collapse-item-active .ant-collapse-arrow svg {
    transform: rotate(270deg) !important;
  }
  &.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 12px 12px 16px 12px;
  }
`;
// INFO: the main body of the panel
export const SelectedFramework = () => {
  const { frameworkCategory } = useContext(FrameworkContext);
  return (
    <div style={{ width: 375 }}>
      <ModalHeader />
      {/* // INFO: render each card for each framework */}
      {frameworkCategory.frameworks?.map((framework: any, idx: number) => {
        return (
          <Space
            key={idx}
            size={16}
            style={{
              background: "white",
              margin: "0 20px 16px 20px",
              borderRadius: "var(--radius-lrg, 16px)",
              padding: "20px 16px 12px 16px ",
              textAlign: "left",
              width: "-webkit-fill-available",
            }}
            direction={"vertical"}
          >
            <Space>
              {/* INFO: Header of the Card */}
              <Avatar
                icon={
                  <Icon
                    size={"extra-small"}
                    variant={frameworkCategory.icon}
                    color={frameworkCategory.iconColor}
                  />
                }
                size="extra-small"
                shape={"square"}
                style={{
                  background: frameworkCategory.iconBackground,
                }}
              />
              <Text size={"subtext2"}>{framework.name}</Text>
            </Space>
            {/* INFO: usecase section */}
            <Space
              size={4}
              style={{
                padding: "10px 12px",
                borderRadius: "var(--radius-md, 8px)",
                background: "var(--lv-panel-background-color)",
                width: "-webkit-fill-available",
              }}
            >
              <Text size="subtext1" style={{ whiteSpace: "nowrap" }}>
                Use case:
              </Text>
              <Text size={"body1"}>{framework.useCaseTags}</Text>
            </Space>
            {/* INFO: list of each Frame work items */}
            <Space
              direction={"vertical"}
              size={20}
              style={{ width: "100%", marginTop: 12 }}
            >
              {framework.steps.map((step: string, idx: number) => {
                return (
                  <Space key={idx} size={16} align={"start"}>
                    <Avatar
                      icon={
                        <Text size="body1" color={frameworkCategory.iconColor}>
                          {idx + 1}
                        </Text>
                      }
                      size="extra-small"
                      shape={"square"}
                      style={{
                        background: frameworkCategory.iconBackground,
                      }}
                    />
                    <Text size={"body2"} style={{ margin: "0" }}>
                      {step}
                    </Text>
                  </Space>
                );
              })}
              <StyledCollapse
                bordered={true}
                items={[
                  {
                    label: <b>Example</b>,
                    children: (
                      <Space size={12} direction={"vertical"}>
                        {framework.example
                          .split("\n")
                          .map((paragraph, idx: number) => {
                            return (
                              <Text key={`example-${idx}`} size={"body2"}>
                                {paragraph}
                              </Text>
                            );
                          })}
                      </Space>
                    ),
                  },
                ]}
              />
              <StyledCollapse
                bordered={true}
                items={[
                  {
                    label: <b>Why It Works</b>,
                    children: (
                      <Space size={24} direction={"vertical"}>
                        {framework.whyItWorks
                          .split("\n")
                          .map((paragraph, idx: number) => {
                            return (
                              <Text key={`why-it-works-${idx}`} size={"body2"}>
                                {paragraph}
                              </Text>
                            );
                          })}
                      </Space>
                    ),
                  },
                ]}
              />
            </Space>
          </Space>
        );
      })}
    </div>
  );
};
