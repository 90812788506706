import Lottie from "react-lottie";

import SpinnerJSON from "./spinner.json";

export const Spinner = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => (
  <Lottie
    width={width}
    height={height}
    isClickToPauseDisabled={true}
    options={{
      loop: true,
      autoplay: true,
      animationData: SpinnerJSON,
    }}
  />
);
