import { BILLING_CYCLE, BILLING_PLANS } from "~src/constants";

export interface BillingPlansPrices {
  [BILLING_PLANS.Basic]: {
    [BILLING_CYCLE.monthly]: number;
    [BILLING_CYCLE.annual]: number;
  };
  [BILLING_PLANS.Trial]: {
    [BILLING_CYCLE.monthly]: number;
    [BILLING_CYCLE.annual]: number;
  };
  [BILLING_PLANS.Starter]: {
    [BILLING_CYCLE.monthly]: number;
    [BILLING_CYCLE.annual]: number;
  };
  [BILLING_PLANS.Pro]: {
    [BILLING_CYCLE.monthly]: number;
    [BILLING_CYCLE.annual]: number;
  };
  [BILLING_PLANS.Teams]: {
    [BILLING_CYCLE.monthly]: number;
    [BILLING_CYCLE.annual]: number;
  };
}
