import { FC } from "react";
import { Flex } from "antd";
import { Text } from "@lavender-ai/lav-components";
import styled from "styled-components";

import { Spinner } from "~src/component/Utilities/Loading";

import { SecondaryText } from "~src/component/Styled";

const FlexWrapper = styled(Flex)`
  padding: 40px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 16px;
`;

export const BuildingPersonalityProfile: FC = () => {
  return (
    <FlexWrapper>
      <Text size="subtext3">Analyzing Personality Profile</Text>
      <Spinner />
      <SecondaryText $light size="body1">
        Hang tight! Pulling in relevant data.
      </SecondaryText>
    </FlexWrapper>
  );
};
