export const HIGH_SCORE_MESSAGES = [
  "Blasting off with a stellar email score!",
  "Taking emails to new heights with a sky-high score!",
  "Fueling up and rocketing to the top with that score!",
  "Launched a high-scoring email straight to the stars!",
  "Ready for takeoff—this email score is out of this world!",
];
export const MIDDLE_SCORE_MESSAGES = [
  "Almost there! Keep refining, and you'll reach the stars!",
  "A solid start, but there's room for lift-off!",
  "Getting closer to the launchpad—fine-tune and try again!",
  "Not quite orbit-ready, but you're on the right path!",
  "Steady progress! A few tweaks and you'll be soaring high!",
];
export const LOW_SCORE_MESSSAGES = [
  "Grounded for now, but every setback is a setup for a comeback!",
  "Houston, we have a problem. Time to regroup and relaunch!",
  "Stuck on the launchpad? Let's rework and prepare for takeoff!",
  "Not the flight we hoped for, but there's always a second chance!",
  "Mission delayed, but not canceled. Let's get back to the drawing board!",
];
export const BANNERBEAR_API_KEY = "bb_pr_fd65e07d6a6d11945b8cd85628ebe4";
export const BANNERBEAR_API_URL = "https://sync.api.bannerbear.com/v2/images";
export const LINKEDIN_SHARE_URL =
  "https://www.linkedin.com/sharing/share-offsite";
export const BANNERBEAR_LOW_SCORE_TEMPLATE_ID = "kY4Qv7D8dyVEDB0qmP";
export const BANNERBEAR_MIDDLE_SCORE_TEMPLATE_ID = "vz9ByYbNapOrDRGXrw";
export const BANNERBEAR_HIGH_SCORE_TEMPLATE_ID = "Powdyxbd0vR6blYBAg";
