import { WorkHistory } from "~src/redux/typings";

import { APIResponseStatus, ErrorResponse } from "./api";

export interface RankedFeedParams {
  email: emailAddress;
  chromeId: chromeId;
}

export interface RankedFeedEmptyInfo {
  alert?: string;
  header?: string;
  caption?: string;
  cta?: string;
}

export type RankedFeedResponse = RankedFeedSuccessResponse | ErrorResponse;

export interface RankedFeedSuccessResponse extends RankedFeedEmptyInfo {
  rankedIcebreakersFeed?: RankedIcebreakersFeed;
  status: APIResponseStatus;
}

export type RankedFeedExperience = RankedFeedItem<WorkHistory>;

interface IcebreakerEntity {
  id: string;
  triggers: Trigger[];
  type: string; // technology or ""
}

export interface ValueProp {
  email: string;
  itemId: string;
  metadata: Metadata;
  userValue?: string;
  value?: string;
}

interface Metadata {
  trigger: string;
  type: string;
  title: string;
  description: string;
  formatted_title: string;
}

export interface RankedIcebreaker {
  item: RankedFeedItem<WorkHistory | IcebreakerEntity>;
  triggers: Trigger[];
  valueProp?: ValueProp;
}

export interface RankedIcebreakers {
  items: RankedIcebreaker[];
  totalScore: number;
}

export type RankedIcebreakersFeed = Record<string, RankedIcebreakers>;

export interface Trigger {
  name: string;
  formattedName: string;
  type: string;
  attributes: TriggerAttributes;
  additionalData?: any;
  score: number;
}

interface TriggerAttributes {
  title: string;
  description?: string;
  date?: string;
  url?: string;
}

interface RankedFeedItem<T> {
  score: number;
  type: string;
  entity: T;
}
