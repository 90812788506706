import { DEFAULT_CONTAINER_CLASS } from "~src/constants";
import { store } from "~src/redux";

const ONBOARDING_FLAG = `lv-up-onboarding`;

export const setupUserpilotOnboarding = async () => {
  const onboardingParamValue = Number.parseInt(
    new URLSearchParams(window.location.search).get(ONBOARDING_FLAG) || "0"
  );

  const tenMinutesAgo = new Date(Date.now() - 10 * 60 * 1000).getTime();

  // if timestamp is over 10 minutes old, avoid flow (assume a cached recently used link instead of intentional onboarding)
  if (isNaN(onboardingParamValue) || tenMinutesAgo > onboardingParamValue) {
    return;
  }

  await composeWindowPrefill();

  setTimeout(() => {
    // Timeout is necessary to prevent UP from unloading when the draft message is prefilled and the page URL params change
    window.userpilot?.trigger("IVZt-epo7o");
  }, 500);

  async function composeWindowPrefill() {
    const selectors = store.getState().config.settings?.selectors;

    const {
      composeContainers = [],
      addressToHiddenInputSelector = "",
      addressToDropdownSelector = "",
      newEmailButton = "",
    } = selectors || {};

    const composeContainerSelectors = composeContainers
      ?.map(({ container }) => container.containerSelector)
      .join(", ");

    (document.querySelector(newEmailButton) as HTMLButtonElement)?.click();

    await waitForElement(DEFAULT_CONTAINER_CLASS);

    const container = document.querySelector(composeContainerSelectors);

    if (container) {
      const recipientElement = container.querySelector(
        addressToHiddenInputSelector
      );

      if (recipientElement) {
        (recipientElement as HTMLInputElement).value = "team@lavender.ai";
        recipientElement.dispatchEvent(new Event("click"));
        (
          document.querySelector(addressToDropdownSelector) as HTMLButtonElement
        )?.click();
      }
    }
  }
};

const waitForElement = async (className: string) => {
  for (let i = 0; i < 10; i++) {
    if (document.getElementsByClassName(className).length) {
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 second
  }
};
