import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { MainPanelState, viewState } from "./typings";
import { useLvIdentifier } from "~src/customHooks";
import { useAppSelector } from "./hooks";

export const INITIAL_MAIN_PANEL_STATE: MainPanelState = {};

export const mainPanelSlice = createSlice({
  name: "mainPanel",
  initialState: INITIAL_MAIN_PANEL_STATE,
  reducers: {
    open: (state, { payload: lvIdentifier }: PayloadAction<lvIdentifier>) => {
      return {
        ...state,
        [lvIdentifier]: {
          ...state[lvIdentifier],
          isOpen: "open",
          showBackGround: false,
          settingsAnimation: "initial",
        },
      };
    },
    close: (state, { payload: lvIdentifier }: PayloadAction<lvIdentifier>) => {
      return {
        ...state,
        [lvIdentifier]: {
          ...state[lvIdentifier],
          isOpen: "closed",
          showBackGround: false,
          settingsAnimation: "closed",
        },
      };
    },
    toggle: (state, { payload: lvIdentifier }: PayloadAction<lvIdentifier>) => {
      // INFO: After the first time, the state is either "open" or "closed".
      const isOpen = state[lvIdentifier]?.isOpen === "open" ? "closed" : "open";
      // INFO: If the panel is open, we don't want to show the background.
      // eslint-disable-next-line no-unneeded-ternary
      const showBackGround = isOpen === "open" ? false : true;
      return {
        ...state,
        [lvIdentifier]: {
          ...state[lvIdentifier],
          isOpen,
          showBackGround,
          settingsAnimation: isOpen,
        },
      };
    },
    setView: (
      state,
      {
        payload: { lvIdentifier, view },
      }: PayloadAction<{ lvIdentifier: lvIdentifier; view: viewState }>
    ) => {
      return {
        ...state,
        [lvIdentifier]: {
          ...state[lvIdentifier],
          view,
        },
      };
    },
    setBackGround: (
      state,
      {
        payload: { lvIdentifier, showBackGround },
      }: PayloadAction<{ lvIdentifier: lvIdentifier; showBackGround: boolean }>
    ) => {
      return {
        ...state,
        [lvIdentifier]: {
          ...state[lvIdentifier],
          showBackGround,
        },
      };
    },
    initialize: (
      state,
      { payload: lvIdentifier }: PayloadAction<lvIdentifier>
    ) => {
      // INFO: for the first render, we don't want to animate the panel.
      return {
        ...state,
        [lvIdentifier]: {
          isOpen: "initial",
          view: "default",
          showBackGround: false,
          settingsAnimation: "initial",
          extensionSettingsAnimation: "initial",
        },
      };
    },
  },
});

export const mainPanelReducer = mainPanelSlice.reducer;
export const mainPanelAction = mainPanelSlice.actions;
export const usePanelIsOpen = () => {
  const lvIdentifier = useLvIdentifier();
  return useAppSelector(({ mainPanel }) => mainPanel[lvIdentifier]?.isOpen);
};
export const useMainPanelView = () => {
  const lvIdentifier = useLvIdentifier();
  return useAppSelector(({ mainPanel }) => mainPanel[lvIdentifier]?.view);
};
