import { Recommendation } from "./emailAnalysis";
import { NetworkStatus } from "./networkStatus";

export type SelectedRecommendationState = Record<
  lvIdentifier,
  {
    selectedRecommendation: Recommendation;
    aiSuggestion: AISuggestion;
    fetchAISuggestionStatus: NetworkStatus;
    fetchAISuggestionError: string;
  }
>;

export interface FetchAISuggestionPayload {
  lvIdentifier: lvIdentifier;
  chromeId: chromeId;
  body: string;
  recommendation: Recommendation;
  ignoreCache: boolean;
  promptName: string;
}

export interface AISuggestion {
  Recommendation: string;
  RecommendationObject: RecommendationObject;
}

export type RecommendationObject = Array<{
  RecType: string;
  NewSentence: string;
}>;
