import { useCallback } from "react";
import { MIN_EMAIL_BODY_WORD_COUNT } from "~src/constants";
import { useUpgradePlanModalContext } from "~src/contexts";
import { useLvIdentifier } from "~src/customHooks";
import {
  fetchEmailAnalysis,
  mainPanelAction,
  selectChromeId,
  selectFeatures,
  selectPlatform,
  useAppDispatch,
  useAppSelector,
  useIncrementStreakMutation,
  useMainPanelView,
  usePanelIsOpen,
} from "~src/redux";
import { viewState } from "~src/redux/typings";
import {
  segmentEvent,
  SegmentEventLocations,
  SegmentEventNames,
} from "~src/utils";
import {
  getBodyLength,
  getSystemsData,
  getWritingData,
} from "../EmailCoachPanel/utils";
import { networkRequest, URLS } from "~src/api";

export const useShowPanel = () => {
  const { closeModal: closeUpgradeModal } = useUpgradePlanModalContext();
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const isOpen = usePanelIsOpen();
  const view = useMainPanelView();
  const platform = useAppSelector(selectPlatform);

  return useCallback(
    (e: React.MouseEvent<HTMLElement> | undefined, newView: viewState) => {
      closeUpgradeModal();
      e?.stopPropagation();
      dispatch(mainPanelAction.setView({ lvIdentifier, view: newView }));
      if (isOpen !== "open") {
        dispatch(mainPanelAction.open(lvIdentifier));
      } else if (view === newView) {
        dispatch(mainPanelAction.close(lvIdentifier));
      }

      const VIEW_STATE_TO_SEGMENT_NAME: Record<
        Exclude<viewState, "default" | "intercom">,
        SegmentEventNames
      > = {
        emailCoach:
          view === newView
            ? SegmentEventNames.EmailCoachClickedClosed
            : SegmentEventNames.EmailCoachClickedOpen,
        chatGPT: SegmentEventNames.ChatGPTClicked,
        settings: SegmentEventNames.SettingsClicked,
        frameworks: SegmentEventNames.FrameworksClicked,
        gif: SegmentEventNames.GifsClicked,
        startMyEmail: SegmentEventNames.StartMyEmailClicked,
        mobilePreview: SegmentEventNames.MobilePhoneClicked,
        profile: SegmentEventNames.ProfileClicked,
        upgrade: SegmentEventNames.SettingsClicked,
        streak: SegmentEventNames.StreakClicked,
      };

      segmentEvent(
        VIEW_STATE_TO_SEGMENT_NAME[
          newView as Exclude<viewState, "default" | "intercom">
        ],
        SegmentEventLocations.ExtensionMainMenu,
        {
          action: view === newView ? "close" : "open",
        }
      );
      //gmail replies fix. Resize triggers rebuilding.
      if (platform === "gmail") {
        window.dispatchEvent(new Event("resize"));
      }
    },
    [closeUpgradeModal, dispatch, lvIdentifier, isOpen, view, platform]
  );
};

export const getStringFromEmailBody = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.body.textContent;
};

export const usePostFinalAnalysis = () => {
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const chromeId = useAppSelector(selectChromeId);
  const { streaks: streaksFF } = useAppSelector(selectFeatures);
  const [incrementStreak] = useIncrementStreakMutation();

  return useCallback(() => {
    const writingData = getWritingData(lvIdentifier);
    const bodyLength = getBodyLength(writingData.body);
    // we only want to check if the body is long enough for analysis
    // we do not need to check if the body has changed, as it most likely hasn't since the last analysis
    // the user is clicking `send` and most likely hasn't changed text
    if (bodyLength < MIN_EMAIL_BODY_WORD_COUNT) {
      return;
    }

    void dispatch(
      fetchEmailAnalysis({
        lvIdentifier,
        systemData: getSystemsData(lvIdentifier),
        writingData,
      })
    );

    // increment streak
    if (streaksFF) {
      void incrementStreak({
        chromeId,
        day: new Date().toISOString().split("T")[0],
      });
    }

    // mark email as sent via pixel
    void networkRequest.post(URLS.pixelSent2, { pixel_id: lvIdentifier });
  }, [chromeId, dispatch, incrementStreak, lvIdentifier, streaksFF]);
};
