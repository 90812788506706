import { useState, useEffect } from "react";
import styled from "styled-components";

import {
  CelebratoryShadow,
  CELEBRATORY_SHADOW_DEFAULTS,
} from "./Icon/CelebratoryShadow";

const CURVE_HEIGHT = 175;

const StyledSVGHeader = styled.div`
  display: inline-block;
  overflow: hidden;
  padding-bottom: 16px;
  position: relative;
  vertical-align: middle;
  width: 100%;

  svg.lv-svg-content {
    display: inline-block;
    left: 0;
    top: 0;
  }

  svg.lv-icon-celebratory-shadow {
    position: absolute;
    top: 16px;
    transform: translate(-50%, 0%);
    left: 50%;
  }
`;

interface Props {
  iconVariant?: IconVariant;
}

export enum IconVariant {
  CelebratoryShadow = "CelebratoryShadow",
}

interface SVGHeaderIconProps
  extends Omit<React.SVGProps<SVGSVGElement>, "width" | "height"> {
  width: number;
  height: number;
}

const SVGHeaderIcon = ({
  iconVariant,
  width,
  height,
}: SVGHeaderIconProps & { iconVariant: IconVariant }) => {
  switch (iconVariant) {
    case IconVariant.CelebratoryShadow:
      return (
        <CelebratoryShadow
          className="lv-icon-celebratory-shadow"
          height={height}
          width={width}
        />
      );
    default:
      return null;
  }
};

const getDefaultSVGHeaderIconProps = (
  iconVariant: Props["iconVariant"]
): SVGHeaderIconProps => {
  switch (iconVariant) {
    case IconVariant.CelebratoryShadow:
      return CELEBRATORY_SHADOW_DEFAULTS;
    default:
      return {
        width: 0,
        height: 0,
      };
  }
};

export const SVGHeader = ({ iconVariant }: Props) => {
  const [panelSize, setPanelSize] = useState(0);

  useEffect(() => {
    const size = getComputedStyle(document.documentElement)
      .getPropertyValue("--lv-expanded-panel-size")
      .trim();
    setPanelSize(parseInt(size, 10));
  }, []);

  const { width: iconWidth, height: iconHeight } =
    iconVariant !== undefined
      ? getDefaultSVGHeaderIconProps(iconVariant)
      : { width: undefined, height: undefined };
  const viewBox = `0 0 ${panelSize} ${CURVE_HEIGHT}`;

  return (
    <StyledSVGHeader>
      <svg
        aria-hidden="true"
        className="lv-svg-content"
        fill="none"
        role="img"
        preserveAspectRatio="xMinYMin meet"
        height={CURVE_HEIGHT}
        width={panelSize}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m0 0h375v140.5s-270-195.5-188-41.5-187 32-187 32v-131z"
          fill="url(#a)"
        />
        <defs>
          <linearGradient
            id="a"
            x1="187.5"
            x2="204.78"
            y1="5.2542e-7"
            y2="166.58"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3D0DA1" offset="0" />
            <stop stopColor={"var(--color-lavender-500)"} offset=".465" />
            <stop stopColor="#A770ED" offset="1" />
          </linearGradient>
        </defs>
      </svg>
      {iconVariant !== undefined &&
        iconWidth !== undefined &&
        iconHeight !== undefined &&
        SVGHeaderIcon({ iconVariant, width: iconWidth, height: iconHeight })}
    </StyledSVGHeader>
  );
};
