import { MutationDefinition } from "@reduxjs/toolkit/query/react";

import {
  APIResponseStatus,
  ErrorResponse,
  SuccessReponse,
  UseMutationBaseQuery,
} from "./api";
import { StartMyEmailCart, StartMyEmailCartPayload } from "./startMyEmail";
import { ValueProp } from "./rankedFeed";

export interface CartItem extends Partial<ValueProp> {
  id: string;
  description?: string;
  formatted_title?: string;
  type: string;
  title: string;
  trigger: string;
}

export interface UpsertValuePropsPayload {
  chromeId: chromeId;
  email: emailAddress;
  cart: CartItem[];
}
export type UpsertValuePropsResponse = ErrorResponse | undefined;

export interface GenerateValuePropsPayload {
  chromeId: chromeId;
  email: emailAddress;
  cart: StartMyEmailCartPayload;
  signal?: AbortSignal;
  sessionId: lvIdentifier;
  stream?: boolean;
}

export type UseGenerateValuePropsMutationDefinition = MutationDefinition<
  GenerateValuePropsPayload,
  UseMutationBaseQuery,
  never,
  ValuePropsResponse,
  "api"
>;
export type ValuePropsResponse = SuccessReponse<{
  generated: GeneratedValueProps;
}>;

interface GeneratedValueProps {
  sections: {
    StartMyEmailCart;
  };
}

export interface ValuePropWSResponse {
  generated: StartMyEmailCart;
  status: APIResponseStatus;
  type: "value_prop_generation" | "value_prop_analysis";
}
