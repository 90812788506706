import { createAsyncThunk } from "@reduxjs/toolkit";

import { openChat } from "~src/api";
import { AppDispatch, RootState } from "~src/redux";
import {
  InitializeChatData,
  InitializeChatArgs,
  NetworkStatus,
} from "~src/redux/typings";
import { newChatMessage } from "~src/utils";

import * as askChatApi from "~src/api/typings/askChat";

const createTypedAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();

const chat = createTypedAsyncThunk<
  InitializeChatData | undefined,
  InitializeChatArgs
>(
  "chat/chat",
  async (
    { lvIdentifier, question },
    { getState }
  ): Promise<InitializeChatData | undefined> => {
    const {
      chats,
      config: { chromeId },
    } = getState();
    const existingChat = chats[lvIdentifier];
    const message = newChatMessage(askChatApi.ChatRole.User, question);
    if (existingChat) {
      const { chatThreadId, status } = existingChat;
      if (status === NetworkStatus.loading || !chatThreadId) {
        return;
      }
      return { chatThreadId, messages: [...existingChat.messages, message] };
    } else {
      const { chat_thread_id } = await openChat(chromeId);
      return { chatThreadId: chat_thread_id, messages: [message] };
    }
  }
);

export { chat };
