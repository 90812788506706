import { FC } from "react";
import styled from "styled-components";
import { Avatar, Button, Icon, IconProps } from "@lavender-ai/lav-components";

import { FRONTEND_URLS } from "~src/constants";

const Frame = styled.div`
  display: flex;
  padding: var(--size-32, 32px) 20px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: var(--radius-lrg, 16px);
  background: var(--color-base-White, #fff);
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
`;

const UpgradeText = styled.div`
  color: var(--color-neutral-purple-900, #18253d);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Bricolage Grotesque";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.1px;
  padding-bottom: 20px;
`;

const Subtext = styled.div`
  color: var(--color-neutral-purple-600, #6d727d);
  text-align: center;
  width: 100%;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: -0.25px;
  padding: 22px 0 20px;
`;

const StyledLink = styled.a`
  color: var(--color-primary-lavender, #6a2be9);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-decoration-line: underline;
`;

const billingURL = `${FRONTEND_URLS.dashboard}/app/settings/billing`;

interface Props {
  crmName: "Gong" | "Salesforce" | "HubSpot";
}

const CRMTypeToIcon: Record<Props["crmName"], IconProps["variant"]> = {
  Gong: "IconGongLogo",
  Salesforce: "IconSalesforce",
  HubSpot: "IconHubspotLogo",
};

export const CRMPaywall: FC<Props> = ({ crmName }) => (
  <Frame>
    <AvatarWrapper>
      <Avatar
        color="lavender"
        size="large"
        icon={
          <Icon color="white" size="large" variant={CRMTypeToIcon[crmName]} />
        }
      />
    </AvatarWrapper>
    <UpgradeText>
      Upgrade your account to access your {crmName} data
    </UpgradeText>
    <Button
      fullWidth={false}
      size="medium"
      variant="primary"
      onClick={() => window.open(billingURL, "_blank")}
    >
      Upgrade Account
    </Button>
    <Subtext>
      Already have Lavender Pro or Teams?
      <br />
      <StyledLink href={billingURL} target="_blank" rel="noopener noreferrer">
        Login to your account.
      </StyledLink>
    </Subtext>
  </Frame>
);
