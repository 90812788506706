import { DEFAULT_CONTAINER_CLASS } from "~src/constants";
import { ComposeContainerMetadata } from "~src/redux/typings";

export const addClassesToPanel = (
  emailContainer: HTMLElement,
  composeContainerMetadata: ComposeContainerMetadata
) => {
  const container = composeContainerMetadata?.container;
  const containerClass =
    container?.containerCSSLavClass ?? DEFAULT_CONTAINER_CLASS;
  if (container?.containerInjectParent) {
    emailContainer.parentElement?.classList.add(containerClass);
  }
  if (
    container?.containerChildInject &&
    container.containerChildInjectPos &&
    container.containerChildCSSLavClass
  ) {
    emailContainer.children[container.containerChildInjectPos].classList.add(
      container.containerChildCSSLavClass
    );
  }
  emailContainer.classList?.add(containerClass);

  const header = composeContainerMetadata?.header;
  let headerElem: HTMLElement | null = null;
  if (header?.headerSelector) {
    headerElem = emailContainer.querySelector(header.headerSelector);
  }
  if (!headerElem && header?.headerChildPos !== undefined) {
    headerElem = emailContainer.childNodes[
      header.headerChildPos
    ] as HTMLElement;
  }
  const headerClass =
    header?.headerCSSLavClass ?? `${DEFAULT_CONTAINER_CLASS}-header`;
  if (headerElem) {
    headerElem.classList.add(headerClass);
  }

  const footer = composeContainerMetadata?.footer;
  let footerElem: HTMLElement | null = null;
  if (footer?.footerSelector) {
    footerElem = emailContainer.querySelector(footer.footerSelector);
  }
  if (!footerElem && footer?.footerChildPos !== undefined) {
    footerElem = emailContainer.childNodes[
      footer.footerChildPos
    ] as HTMLElement;
  }
  const footerClass =
    footer?.footerCSSLavClass ?? `${DEFAULT_CONTAINER_CLASS}-footer`;
  if (footerElem) {
    footerElem.classList.add(footerClass);
  }
};
