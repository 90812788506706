import { FC, useEffect, useState } from "react";

export const MobileDynamicTime: FC = () => {
  const [time, setTime] = useState("");

  // Update displayed time every second
  useEffect(() => {
    setTime(new Date().toLocaleTimeString().split(":").slice(0, 2).join(":"));

    const interval = setInterval(() => {
      setTime(new Date().toLocaleTimeString().split(":").slice(0, 2).join(":"));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [setTime]);

  return (
    <div
      style={{
        position: "absolute",
        top: 14,
        left: 44,
        fontSize: 11,
        fontFamily: "Inter",
        fontWeight: 600,
      }}
    >
      {time}
    </div>
  );
};
