import type React from "react";

export interface Props {
  children: React.ReactElement;
  condition: boolean;
  wrapper: (children: React.ReactElement) => React.ReactElement;
}

export const ConditionalWrapper = ({ children, condition, wrapper }: Props) =>
  condition ? wrapper(children) : children;
