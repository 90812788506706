import { Divider, Flex } from "antd";
import { capitalCase } from "change-case";
import { styled } from "styled-components";

import { CLEARBIT_LOGO_URL } from "~src/constants";
import { BUTTON_TEXT } from "~src/strings";
import { Tech } from "~src/redux/typings";
import { SecondaryText } from "~src/component/Styled";
import { LoadMoreButton } from "~src/component/Atoms";
import { LavenderIcon } from "~src/component/typings";
import { useLoadMore } from "~src/customHooks";
import { PanelListItem } from "~src/layout/MainPanel/PanelBlock";

import { EventPanel } from "../Events";
import { useCarouselContext } from "../Personalization/CarouselContextProvider";
import { Tag } from "@lavender-ai/lav-components";
import { ComponentBrandColor } from "@lavender-ai/lav-components/dist/esm/types/src/types";

interface TechStackItemProps {
  category: string;
  tech: Tech[];
  color:
    | "gray"
    | "dark"
    | "outline"
    | "lavender"
    | "pear"
    | "sapphire"
    | "marigold"
    | "peony"
    | "tart"
    | undefined;
}

export const TechStackItem = ({
  category,
  tech,
  color,
}: TechStackItemProps) => {
  const { next } = useCarouselContext();
  const techListCounter = useLoadMore(tech);

  return (
    <PanelListItem>
      <CategoryTitle>
        <SecondaryText color={"dark"} size="subtext3">
          {capitalCase(category)}
        </SecondaryText>
      </CategoryTitle>
      <Flex wrap={"wrap"} gap={8} style={{ marginTop: 16 }}>
        {techListCounter?.currentItems.map((techObj, idx) => (
          <Tag
            color={color} //tag.color should be ComponentBrandColor
            key={idx}
            onClick={() => next("Tech Stack", techObj)}
            style={{ cursor: "pointer" }}
          >
            {techObj.title}
          </Tag>
        ))}
      </Flex>
      <Divider />
    </PanelListItem>
  );
};

const CategoryTitle = styled.div`
  margin-bottom: 6px;
  padding: 0px 4px;
`;
