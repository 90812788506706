export const gmail = (): void => {
  setTimeout(() => {
    removePanelWithComposeContainerWatcher();
  }, 1000);
};

export const adjustContainer = (event) => {
  if (!event) {
    return;
  }
  const emailContainer = document.querySelector<HTMLElement>(".Am.aiL");
  if (emailContainer) {
    const ecFirstChild = emailContainer.firstChild as HTMLElement | null;

    if (ecFirstChild) {
      ecFirstChild.click();
      ecFirstChild.focus();
    }
  }

  const footerSelector = document.querySelector<HTMLElement>(".aDj.ahe");
  if (footerSelector && footerSelector.style.position === "absolute") {
    footerSelector.style.top = "0px";
    footerSelector.style.bottom = "auto";
  }
};

const removePanelWithComposeContainerWatcher = (): void => {
  const panelContainer = document.querySelector(".Tm.aeJ .aeF");
  if (panelContainer) {
    panelContainer.addEventListener("scroll", adjustContainer);
    window.addEventListener("resize", adjustContainer);
    adjustContainer(panelContainer);
  }
};

const relDiff = (a, b) => {
  return 100 * Math.abs((a - b) / ((a + b) / 2));
};

export const fixFooterWidth = () => {
  const footerSelector = document.querySelector<HTMLElement>(".aDj.ahe");
  const panelSelector = document.querySelector<HTMLElement>(".I5");

  const footerWidth = footerSelector?.offsetWidth;
  const panelWidth = panelSelector?.offsetWidth;

  if (footerWidth && panelWidth && relDiff(footerWidth, panelWidth) > 5) {
    const diff = footerWidth - panelWidth;
    const footerAttr = footerSelector.getBoundingClientRect();
    footerSelector.style.width = `${panelWidth - 2}px`;
    footerSelector.style.left = `${footerAttr.left + diff + 2}px`;
  }
};
