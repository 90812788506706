import { store } from "~src/redux/store";

export const addStyleTag = (css: string, name = "style") => {
  const style = document.createElement("style");
  style.textContent = css;
  style.dataset.lv = name;
  document.head.append(style);
};

export const addIframeStyleTag = (
  name = "style",
  iframeSelector: string,
  css: string
) => {
  // INFO: Iframe is sometimes added via async. So we need to wait for it to be added to the DOM.
  const { iframeDelay } = store.getState().config.settings?.timing ?? {};
  const iframeInterval = setInterval(() => {
    const iframe =
      top?.document.querySelector<HTMLIFrameElement>(iframeSelector);

    if (iframe) {
      const iframeHead =
        iframe?.contentDocument?.head ||
        iframe?.contentWindow?.document?.head ||
        null;

      if (iframeHead) {
        const iframeStyle = document.createElement("style");
        iframeStyle.textContent = css;
        iframeStyle.dataset.lv = name;
        iframeHead.append(iframeStyle);
      }
      clearInterval(iframeInterval);
    }
  }, iframeDelay);
};

export const injectCustomLinkTags = (hrefList: string[]): void => {
  hrefList.forEach((href) => {
    const htmlLinkTag = createLinkTag(href);
    document.head.appendChild(htmlLinkTag);
  });
};
export const injectCustomIframeLinkTags = (
  iframeSelector: string,
  hrefList: string[]
) => {
  // INFO: Iframe is sometimes added via async. So we need to wait for it to be added to the DOM.
  const { iframeDelay } = store.getState().config.settings?.timing ?? {};
  const iframeInterval = setInterval(() => {
    const iframe =
      top?.document.querySelector<HTMLIFrameElement>(iframeSelector);

    if (iframe) {
      const iframeHead =
        iframe?.contentDocument?.head ||
        iframe?.contentWindow?.document?.head ||
        null;

      if (iframeHead) {
        hrefList.forEach((href) => {
          const htmlLinkTag = createLinkTag(href);
          iframeHead.append(htmlLinkTag);
        });
      }
      clearInterval(iframeInterval);
    }
  }, iframeDelay);
};

const createLinkTag = (href: string) => {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = href;
  return link;
};
