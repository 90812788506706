import styled from "styled-components";
import { Skeleton, type SkeletonProps } from "antd";

import { PropsForStyling } from "../../../typings";

export enum CustomSkeletonVariant {
  avatar = "avatar",
  avatarTitle = "avatarTitle",
  menuItem = "menuItem",
  avatarListItem = "avatarListItem",
  checkboxListItem = "checkboxListItem",
  simpleListItem = "simpleListItem",
  subTitle = "subTitle",
  title = "title",
}

type SkeletonAvatarShape = "circle" | "square";
// maps to Component Library "extra-small" | "small" | "medium"
type SkeletonAvatarSize = number | "small" | "default" | "large" | undefined;

export const StyledCustomSkeleton = styled(Skeleton)<
  SkeletonProps & PropsForStyling<Props>
>`
  .ant-skeleton-header {
    padding-top: ${({ $variant: variant }) =>
      variant === CustomSkeletonVariant.menuItem ? 0 : "8px"};
    padding-right: ${({ avatar, paragraph, title }) =>
      avatar !== undefined && paragraph === false && title === false
        ? 0
        : "8px"};

    .ant-skeleton-avatar {
      border-radius: ${({ avatar }) =>
        avatar !== undefined &&
        typeof avatar !== "boolean" &&
        avatar.shape !== "circle" &&
        "8px"};
    }
  }

  .ant-skeleton-content {
    .ant-skeleton-title {
      border-radius: ${({ $variant: variant }) => {
        if (variant === CustomSkeletonVariant.subTitle) {
          return "15px";
        }
        return variant === CustomSkeletonVariant.title && "25px";
      }};
      height: ${({ $variant: variant }) => {
        if (variant === CustomSkeletonVariant.subTitle) {
          return "10.5px";
        }
        return variant === CustomSkeletonVariant.title && "17.5px";
      }};
      margin-bottom: 0;
      margin-top: ${({ avatar, title }) =>
        avatar === false && title !== false && 0};
    }
    .ant-skeleton-paragraph {
      margin-top: ${({ avatar, paragraph, $variant: variant }) => {
        if (variant === CustomSkeletonVariant.menuItem) {
          return "4px";
        }
        if (variant === CustomSkeletonVariant.checkboxListItem) {
          return "8px";
        }
        if (avatar === false && paragraph !== false) {
          return 0;
        }
      }};
      margin-bottom: ${({ $variant: variant }) =>
        variant === CustomSkeletonVariant.menuItem && 0};
    }
  }
`;

interface Props
  extends Pick<SkeletonProps, "active" | "children" | "loading" | "className"> {
  variant?: CustomSkeletonVariant;
  titleWidth?: string;
}

export const CustomSkeleton = ({
  active = true,
  variant,
  children,
  loading,
  titleWidth,
  ...HTMLAttributes
}: Props) => {
  let paragraph: SkeletonProps["paragraph"] = true;
  let size: SkeletonAvatarSize = "small";
  let shape: SkeletonAvatarShape = "circle";
  let title: SkeletonProps["title"] = true;
  if (variant === CustomSkeletonVariant.title) {
    title = { width: "200px" };
    paragraph = false;
  }
  if (variant === CustomSkeletonVariant.subTitle) {
    title = { width: "100px" };
    paragraph = false;
  }
  if (variant === CustomSkeletonVariant.avatar) {
    shape = "square";
    paragraph = false;
    title = false;
  }
  if (variant === CustomSkeletonVariant.menuItem) {
    paragraph = { width: "100%", rows: 1 };
    title = false;
    shape = "square";
  }
  if (variant === CustomSkeletonVariant.avatarListItem) {
    paragraph = { width: "216px", rows: 2 };
    title = false;
    shape = "circle";
    size = "default";
  }
  if (variant === CustomSkeletonVariant.checkboxListItem) {
    paragraph = { width: "216px", rows: 2 };
    title = false;
    shape = "circle";
    size = 20;
  }
  if (variant === CustomSkeletonVariant.simpleListItem) {
    paragraph = { width: "260px", rows: 2 };
    title = true;
  }
  if (variant === CustomSkeletonVariant.avatarTitle) {
    paragraph = false;
    title = {
      width: titleWidth !== undefined ? titleWidth : "70px",
    };
  }
  const avatar: SkeletonProps["avatar"] =
    variant !== undefined &&
    [
      CustomSkeletonVariant.subTitle,
      CustomSkeletonVariant.title,
      CustomSkeletonVariant.simpleListItem,
    ].includes(variant)
      ? false
      : { size, shape };

  return (
    <StyledCustomSkeleton
      active={active}
      loading={loading}
      paragraph={paragraph}
      avatar={avatar}
      title={title}
      $variant={variant}
      {...HTMLAttributes}
    >
      {children}
    </StyledCustomSkeleton>
  );
};
