import { store } from "~src/redux/store";
import {
  emailAction,
  emailAnalysisAction,
  fetchUserAccount,
  settingsCarouselAction,
} from "../redux";
import { HighlightSelectors, NetworkStatus } from "../redux/typings";
import { getUserEmailData, setupHighlighter } from "../utils";

export const startAuthenticatedSession = async (
  lvIdentifier: lvIdentifier,
  emailContainer: HTMLElement,
  selectors: HighlightSelectors,
  containerIsIframe: boolean = false,
  iframeContainerSelector: string = ""
) => {
  void store.dispatch(emailAction.initialize({ lvIdentifier }));

  if (containerIsIframe) {
    const content = emailContainer.querySelector(iframeContainerSelector) as HTMLIFrameElement;
    const iframeContents = content.contentDocument?.body as HTMLElement;
    getUserEmailData(iframeContents, lvIdentifier);
  } else {
    getUserEmailData(emailContainer, lvIdentifier);
  }

  store.dispatch(emailAnalysisAction.initialize(lvIdentifier));
  setupHighlighter(emailContainer, selectors.bodySelector, lvIdentifier, selectors.bodyIframe);

  const {
    config: { chromeId },
    userAccount: { status },
  } = store.getState();

  if (![NetworkStatus.success, NetworkStatus.loading].includes(status)) {
    await store
      .dispatch(fetchUserAccount({ chromeId }))
      .unwrap()
      .then(() => {
        const { status, settings } = store.getState().userAccount;
        if (!settings.user) return;

        if (status === NetworkStatus.success) {
          store.dispatch(settingsCarouselAction.initialize(lvIdentifier));
        }
      });
  }
};
