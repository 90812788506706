import { Flex } from "antd";

import { Animation, Text } from "@lavender-ai/lav-components";

import { IconAndText } from "~src/component/Atoms";

import { NetworkStatus } from "~src/redux/typings";
import { getEmailAndSubjectFromContentString } from "~src/utils";
import { LavenderIcon, Message } from "~src/component/typings";
import { StyledMessageContainer, StyledMessageTextContainer } from "../styles";

interface Props extends Message {
  avatar?: React.ReactNode;
  actions?: React.ReactNode[];
  status?: NetworkStatus;
  query?: string;
}

export const AssistantMessage = ({
  avatar,
  actions,
  content,
  query,
  status,
}: Props) => {
  const parsedContent =
    content && content !== "..."
      ? getEmailAndSubjectFromContentString(content)
      : "";
  return (
    <Flex gap="small">
      {avatar !== undefined && avatar !== null && <div>{avatar}</div>}
      <StyledMessageContainer>
        <Flex gap="small" vertical>
          {query && (
            <StyledMessageTextContainer
              align="flex-start"
              gap={16}
              vertical
              $status={NetworkStatus.loading}
            >
              <IconAndText
                iconColor="dark"
                icon={LavenderIcon.IconWorldSearch}
                iconSize="mini"
                text={[
                  { text: "Searched: " },
                  { text: `"${query}"`, bold: true },
                ]}
                textColor="dark"
              />
            </StyledMessageTextContainer>
          )}
          {content === "..." && (
            <StyledMessageTextContainer
              align="flex-start"
              justify="center"
              gap={16}
              vertical
              $status={NetworkStatus.loading}
            >
              <Animation style={{ height: "6px" }} variant="thinking" />
            </StyledMessageTextContainer>
          )}
          {content && content !== "..." && (
            <StyledMessageTextContainer
              align="flex-start"
              gap={16}
              vertical
              $status={status}
            >
              <Text
                color="dark"
                dangerouslySetInnerHTML={{
                  __html: parsedContent,
                }}
                size="body2"
              />
              {(status === undefined ||
                ![NetworkStatus.loading, NetworkStatus.failed].includes(
                  status
                )) && <Flex gap={4}>{actions}</Flex>}
            </StyledMessageTextContainer>
          )}
        </Flex>
      </StyledMessageContainer>
    </Flex>
  );
};
