import { getPersonalization } from "../../../api";
import { REFRESH_INTERVAL } from "../../../constants/api";
import {
  newPendingPersonalization,
  newRejectedPersonalization,
} from "../../../utils";
import {
  PersonalizationData,
  RecipientPersonalizationMap,
  NetworkStatus,
  LoadPersonalizationsPayload,
} from "../../typings";
import { updatePersonalizations } from "../actions";
import { createFeedAsyncThunk, parsePersonalizationResponse } from "../helpers";

export const loadProfile = createFeedAsyncThunk<
  PersonalizationData,
  LoadPersonalizationsPayload
>(
  "feed/personalizations",
  async (
    { toAddresses, forceRefetch },
    { dispatch, getState }
  ): Promise<PersonalizationData> => {
    const {
      config: { chromeId },
      feed: { lastUpdated, personalizations: personalizationData },
      userAccount: {
        settings: { user },
      },
    } = getState();

    if (!chromeId || user === undefined) {
      return { toAddresses, personalizations: {} };
    }

    const shouldRefetch =
      forceRefetch ||
      !lastUpdated ||
      Date.now() - lastUpdated > REFRESH_INTERVAL.FEED;

    if (!toAddresses.length) {
      return { toAddresses, personalizations: personalizationData };
    }

    const personalizationFetchEmails = toAddresses.filter(
      (email) => !personalizationData[email]?.profile.email || shouldRefetch
    );

    const pendingPersonalizationsData =
      personalizationFetchEmails.reduce<RecipientPersonalizationMap>(
        (emails, email) => {
          emails[email] = newPendingPersonalization(email);
          return emails;
        },
        {}
      );

    dispatch(
      updatePersonalizations({
        toAddresses,
        personalizations: pendingPersonalizationsData,
      })
    );

    const resolvedPersonalizations = await Promise.all(
      personalizationFetchEmails.map(async (email) => {
        try {
          const personalizationResponse = await getPersonalization(
            email,
            chromeId,
            forceRefetch
          );
          return {
            status: NetworkStatus.success,
            email,
            data: parsePersonalizationResponse(personalizationResponse, email),
          };
        } catch {
          return {
            status: NetworkStatus.failed,
            email,
            data: newRejectedPersonalization(email),
          };
        }
      })
    );

    const parsedFulfilledPersonalizations =
      resolvedPersonalizations.reduce<RecipientPersonalizationMap>(
        (acc, { email, data }) => {
          acc[email] = data;
          return acc;
        },
        {}
      );
    dispatch(
      updatePersonalizations({
        toAddresses,
        personalizations: parsedFulfilledPersonalizations,
      })
    );

    return {
      toAddresses,
      personalizations: parsedFulfilledPersonalizations,
    };
  }
);
