import { Flex } from "antd";
import { styled } from "styled-components";

export const CategoryHeader = styled(Flex)`
  padding: 20px 16px;

  .lv-email-category-header-text-container {
    margin-top: 2px;
  }
`;
export const CategoryItems = styled(Flex)`
  padding: 0 16px 20px 16px;
`;
export const ShowMore = styled(Flex)`
  padding: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  border: 0;
  background: var(--lv-panel-background-color);
  cursor: pointer;
  margin: -12px 16px 16px 16px;
  &:hover {
    background: var(--color-neutral-purple-100, #ececee);
  }
`;
