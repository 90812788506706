import { styled } from "styled-components";

import { Button, Tooltip } from "@lavender-ai/lav-components";

import { LavenderIcon } from "~src/component/typings/icon";
import { useMobileEditor } from "~src/contexts";
import { PropsForStyling } from "~src/component/typings";
import {
  SegmentEventLocations,
  SegmentEventNames,
  segmentEvent,
} from "~src/utils";
import { FC } from "react";
import { store } from "~src/redux";

const VerticalIconContainer = styled.div<
  PropsForStyling<{ isExpanded: boolean }>
>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  transition: height 0.3s ease;
  border-radius: ${(props) => (props.$isExpanded ? "20px" : "0px")};
  padding-top: 5px;
  height: ${(props) => (props.$isExpanded ? "120px" : "40px")};
  background: ${(props) => (props.$isExpanded ? "#eae5f9" : "white")};
`;

const HiddenButtonContainer = styled.div`
  flex: 1;
`;

export const MobileDeviceMenu: FC = () => {
  const {
    showMobileEditor,
    hideMobileEditor,
    isMobileEditorOpen,
    enableDarkMode,
    disableDarkMode,
    isDarkMode,
    enableMobileDevice,
    enableWatchDevice,
    isMobileDevice,
  } = useMobileEditor();
  const state = store.getState();
  const platform = state.config.settings?.platform;

  const mobilePreviewIcon = (
    <HiddenButtonContainer>
      {isMobileEditorOpen ? (
        <Button
          onClick={() => {
            segmentEvent(
              SegmentEventNames.MobileEditorClicked,
              SegmentEventLocations.ExtensionMainMenu,
              { action: "close" }
            );
            hideMobileEditor();
          }}
          radius="xl"
          size="medium"
          icon={LavenderIcon.IconX}
          variant="primary"
          iconColor="white"
        />
      ) : (
        <Tooltip
          hideArrow
          placement={platform === "outlookNative" ? "bottom" : "right"}
          title="Mobile Editor"
        >
          <Button
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              segmentEvent(
                SegmentEventNames.MobileEditorClicked,
                SegmentEventLocations.ExtensionMainMenu,
                { action: "open" }
              );
              showMobileEditor(e);
            }}
            radius={isMobileEditorOpen ? "xl" : "md"}
            size="large"
            icon={LavenderIcon.IconMobilePreview}
            variant="plain"
          />
        </Tooltip>
      )}
    </HiddenButtonContainer>
  );

  const darkModeIcon = (
    <HiddenButtonContainer>
      {isMobileDevice && isDarkMode && (
        <Button
          onClick={() => {
            segmentEvent(
              SegmentEventNames.DarkModeClicked,
              SegmentEventLocations.ExtensionMainMenu,
              { action: "disabled" }
            );
            disableDarkMode();
          }}
          radius="xl"
          size="medium"
          icon={LavenderIcon.IconSun}
          variant="default"
          iconColor="lavender"
        />
      )}
      {isMobileDevice && !isDarkMode && (
        <Button
          onClick={() => {
            segmentEvent(
              SegmentEventNames.DarkModeClicked,
              SegmentEventLocations.ExtensionMainMenu,
              { action: "enabled" }
            );
            enableDarkMode();
          }}
          radius="xl"
          size="medium"
          icon={LavenderIcon.IconMoonFilled}
          variant="default"
          iconColor="lavender"
        />
      )}
      {!isMobileDevice && isDarkMode && (
        <Button
          radius="xl"
          size="medium"
          icon={LavenderIcon.IconSun}
          variant="default"
          iconColor="lavender"
          state="disabled"
        />
      )}
      {!isMobileDevice && !isDarkMode && (
        <Button
          radius="xl"
          size="medium"
          icon={LavenderIcon.IconMoonFilled}
          variant="default"
          iconColor="lavender"
          state="disabled"
        />
      )}
    </HiddenButtonContainer>
  );

  const watchIcon = (
    <HiddenButtonContainer>
      {isMobileDevice ? (
        <Button
          onClick={() => {
            segmentEvent(
              SegmentEventNames.AppleWatchClicked,
              SegmentEventLocations.ExtensionMainMenu
            );
            enableWatchDevice();
          }}
          radius="xl"
          size="medium"
          icon={LavenderIcon.IconDeviceWatch}
          variant="default"
          iconColor="lavender"
        />
      ) : (
        <Button
          onClick={() => {
            segmentEvent(
              SegmentEventNames.MobilePhoneClicked,
              SegmentEventLocations.ExtensionMainMenu
            );
            enableMobileDevice();
          }}
          radius="xl"
          size="medium"
          icon={LavenderIcon.IconDeviceMobile}
          variant="default"
          iconColor="lavender"
        />
      )}
    </HiddenButtonContainer>
  );

  return (
    <VerticalIconContainer
      $isExpanded={isMobileEditorOpen}
      className={"mobile-editor-menu-overwrite-css"}
    >
      {isMobileEditorOpen && platform !== "outlookNative" && (
        <>
          {darkModeIcon}
          {watchIcon}
        </>
      )}
      {mobilePreviewIcon}
    </VerticalIconContainer>
  );
};
