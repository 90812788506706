import { TextProps } from "@lavender-ai/lav-components";

export const cleanResponseText = (text: string, preserveNewlines = false) => {
  if (!text) return "";

  text = text.replace(/^[\s\n<br>]+/, "");
  text = decodeHtmlEscaping(text);

  if (preserveNewlines) {
    return text;
  } else {
    return text.replace(/\n/g, "<br/>");
  }
};

export const isHtml = (str: string) => {
  return /<\/?[a-z][\s\S]*>/i.test(str);
};

export const decodeHtmlEscaping = (str: string) => {
  return str
    .replace(/&amp;/g, "&")
    .replace(/&apos;/g, "'")
    .replace(/&#39;/g, "'")
    .replace(/&quot;/g, '"')
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<");
};

export const getTextClassName = (
  color: TextProps["color"],
  size: TextProps["size"]
) =>
  `lav-components-text-color-${color} lav-components-text-size-${size} lav-components-text-underline-undefined lav-components-text`;
