import { usePersonalization, useSearch, useLoadMore } from "~src/customHooks";
import { PanelListContainer } from "~src/layout/MainPanel/PanelBlock";
import { LoadMoreButton } from "~src/component/Atoms";
import { BUTTON_TEXT } from "~src/strings";
import { LavenderIcon } from "~src/component/typings";

import { JobList } from "./JobList";

export const JobResults = () => {
  const {
    currentPersonalization: {
      company: {
        jobs: { jobs = [], loading: jobsLoading },
      },
      profile: { loading },
    },
  } = usePersonalization();

  const { filteredItems } = useSearch(jobs, ["title"]);

  const isLoading = jobsLoading || loading;
  const { currentItems, loadMore, hasMore, loadMoreCount } =
    useLoadMore(filteredItems);

  return (
    <>
      <PanelListContainer>
        <JobList jobs={currentItems} loading={isLoading} />
      </PanelListContainer>
      {hasMore && !isLoading && (
        <div className="lv-panel-list-load-more-button-container">
          <LoadMoreButton
            buttonText={BUTTON_TEXT.SEE_MORE(loadMoreCount)}
            onClick={loadMore}
            prefix={LavenderIcon.IconPlus}
          />
        </div>
      )}
    </>
  );
};
