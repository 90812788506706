import { INewsContext, useNewsContext } from "~src/contexts";
import { useAppSelector } from "~src/redux";
import { RecipientNewsMap } from "~src/redux/typings";

interface IUseNews extends Omit<INewsContext, "currentNews"> {
  news: RecipientNewsMap;
}

export const useNews = (): IUseNews => {
  const { news } = useAppSelector(({ feed }) => feed);

  const { resetNewsContext, setNewsContext } = useNewsContext();

  return {
    news,
    resetNewsContext,
    setNewsContext,
  };
};
