import { DetailedHTMLProps, HTMLAttributes, RefObject } from "react";

export const CSS_PREFIX = "lav-components-mobile";

export interface MobileDeviceProps
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    "className"
  > {
  fixedIconsRef?: RefObject<HTMLDivElement>;
  className?: string;
  variant?: "mobile" | "watch";
  darkMode?: boolean;
  firstName?: string;
  lastName?: string;
  subjectText?: string;
  emailBody?: string;
  editMailboxSubject: (newSubject: string) => void;
  editMailBody: (newBody: string) => void;
  startX: number;
  startY: number;
}
