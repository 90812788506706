import styled from "styled-components";

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.56;
  background: var(--color-neutral-purple-900, #0d1421);
  width: 100%;
  height: 100%;
`;

export const OverlayContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2;
  overflow: hidden;
  backdrop-filter: blur(6px);
  height: 100%;
  top: 0;
  left: 0;
`;
