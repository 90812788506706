import { styled } from "styled-components";

import { PanelLayout } from "~src/layout/MainPanel/Panel/PanelLayout/PanelLayout";

export const StyledPanelFormLayout = styled(PanelLayout)`
  height: inherit;
  > div {
    height: inherit;
  }
  .ant-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
    width: 100%;
  }
`;
