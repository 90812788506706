import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAISuggestion } from "../api/aiSuggestion";
import {
  FetchAISuggestionPayload,
  NetworkStatus,
  Recommendation,
  SelectedRecommendationState,
} from "./typings";

const initialState: SelectedRecommendationState =
  {} as SelectedRecommendationState;
export const fetchAISuggestion = createAsyncThunk<
  any,
  FetchAISuggestionPayload
>(
  "selectedRecommendation/fetchAISuggestion",
  async ({ lvIdentifier, chromeId, body, recommendation, ignoreCache }) => {
    const response = await getAISuggestion(
      chromeId,
      body,
      recommendation,
      ignoreCache
    );
    console.debug(response.data);
    return {
      lvIdentifier,
      data: response.data,
      error: response.data.message,
    };
  }
);

const selectedRecommendationSlice = createSlice({
  name: "selectedRecommendation",
  initialState,
  reducers: {
    setSelectedRecommendation: (
      state,
      {
        payload: { lvIdentifier, selectedRecommendation },
      }: {
        payload: {
          lvIdentifier: lvIdentifier;
          selectedRecommendation: Recommendation;
        };
      }
    ) => {
      return {
        ...state,
        [lvIdentifier]: {
          ...state[lvIdentifier],
          selectedRecommendation,
        },
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAISuggestion.pending, (state, action) => {
        console.debug("pending");
        state[action.meta.arg.lvIdentifier].fetchAISuggestionStatus =
          NetworkStatus.loading;
      })
      .addCase(fetchAISuggestion.fulfilled, (state, action) => {
        console.debug("fulfilled");
        console.debug(action.payload.data);
        state[action.payload.lvIdentifier].aiSuggestion = action.payload.data;
        state[action.payload.lvIdentifier].fetchAISuggestionStatus =
          NetworkStatus.success;
      })
      .addCase(fetchAISuggestion.rejected, (state, action) => {
        console.debug("rejected");
        state[action.meta.arg.lvIdentifier].fetchAISuggestionStatus =
          NetworkStatus.failed;
        state[action.meta.arg.lvIdentifier].fetchAISuggestionError =
          action.error.message ?? "";
      });
  },
});

export const selectedRecommendationAction = selectedRecommendationSlice.actions;
export const selectedRecommendationReducer =
  selectedRecommendationSlice.reducer;
