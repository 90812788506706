import { networkRequest, URLS } from "~src/api";

import { SettingKey, ToggleUserSettingSuccessResponse } from "./typings";

/**
 * Toggles a user setting on or off. All toggles should use this method.
 */
export const toggleUserSetting = async (
  chromeId: chromeId,
  setting: SettingKey,
  on: boolean
): Promise<ToggleUserSettingSuccessResponse> => {
  const { data } = await networkRequest.post<ToggleUserSettingSuccessResponse>(
    URLS.toggleSetting,
    {
      on,
      setting,
      chrome_id: chromeId,
    }
  );

  return data;
};
