import { styled } from "styled-components";

const StyledSVGHeader = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 100%;

  svg.lv-svg-content {
    display: inline-block;
    left: 0;
    top: 0;
  }

  .lv-svg-overlap-content {
    position: absolute;
    top: 0;
  }
`;

interface Props {
  children: React.ReactNode;
}

export const Header = ({ children }: Props) => {
  return (
    <StyledSVGHeader>
      <svg
        aria-hidden="true"
        className="lv-svg-content"
        fill="none"
        role="img"
        width="375"
        height="273"
        viewBox="0 0 375 273"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0H375V273C375 273 293.5 243.662 187 243.662C80.5 243.662 0 273 0 273V0Z"
          fill="url(#rectangle)"
        />
        <path
          d="M22.8091 27.34C22.9355 28.9815 25.4154 31.2399 26.9421 31.104C25.4154 31.2399 23.315 33.9063 23.4414 35.5479C23.315 33.9063 20.8351 31.6479 19.3084 31.7839C20.8351 31.6479 22.9355 28.9815 22.8091 27.34Z"
          fill="var(--color-pear-300)"
          filter="url(#blurFilter)"
          opacity="0.9"
        />
        <path
          opacity="0.9"
          d="M101.617 62.5861C101.809 65.0704 105.562 68.4882 107.872 68.2824C105.562 68.4882 102.383 72.5234 102.574 75.0078C102.383 72.5234 98.6299 69.1056 96.3193 69.3114C98.6299 69.1056 101.809 65.0704 101.617 62.5861Z"
          fill="var(--color-lavender-200)"
        />
        <path
          opacity="0.9"
          d="M284.996 33.3083C285.07 34.2621 286.51 35.5743 287.397 35.4953C286.51 35.5743 285.29 37.1235 285.363 38.0773C285.29 37.1235 283.849 35.8114 282.962 35.8904C283.849 35.8114 285.07 34.2621 284.996 33.3083Z"
          fill="var(--color-marigold-300)"
        />
        <path
          d="M358.394 53.0378C358.468 53.9916 359.909 55.3038 360.796 55.2248C359.909 55.3038 358.688 56.853 358.762 57.8068C358.688 56.853 357.248 55.5408 356.36 55.6199C357.248 55.5408 358.468 53.9916 358.394 53.0378Z"
          fill="var(--color-base-White)"
          filter="url(#blurFilter)"
          opacity="0.9"
        />
        <path
          d="M28.1005 82.6323C28.174 83.5861 29.6149 84.8983 30.5019 84.8193C29.6149 84.8983 28.3945 86.4475 28.4679 87.4013C28.3945 86.4475 26.9536 85.1353 26.0665 85.2143C26.9536 85.1353 28.174 83.5861 28.1005 82.6323Z"
          fill="var(--color-base-White)"
          filter="url(#blurFilter)"
          opacity="0.9"
        />
        <path
          opacity="0.9"
          d="M320.785 96.331C320.969 98.7126 324.71 101.976 327.02 101.771C324.71 101.976 321.519 105.858 321.703 108.239C321.519 105.858 317.778 102.594 315.468 102.8C317.778 102.594 320.969 98.7126 320.785 96.331Z"
          fill="var(--color-base-White)"
        />
        <defs>
          <filter
            id="blurFilter"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feGaussianBlur stdDeviation="1.25751" />
          </filter>
          <linearGradient
            id="rectangle"
            x1="187.5"
            y1="1.09781e-06"
            x2="260.312"
            y2="336.023"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="var(--color-lavender-800)" />
            <stop offset="0.301026" stopColor="#3D0DA1" />
            <stop offset="0.503614" stopColor="var(--color-lavender-500)" />
            <stop offset="0.844759" stopColor="#A770ED" />
          </linearGradient>
        </defs>
      </svg>
      <div className="lv-svg-overlap-content">{children}</div>
    </StyledSVGHeader>
  );
};
