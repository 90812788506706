import { useRef, useEffect } from "react";

import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect";

export const useTimeout = (
  callback: () => void,
  delay: number | null
): void => {
  const savedCallback = useRef(callback);

  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    const id = setTimeout(() => {
      savedCallback.current();
    }, delay);

    return () => {
      clearTimeout(id);
    };
  }, [delay]);
};
