import { createContext, useContext, useMemo } from "react";
import {
  FetchBaseQueryError,
  MutationActionCreatorResult,
  QueryStatus,
} from "@reduxjs/toolkit/query";

import {
  useSharedMutationWithAbort,
  usePersonalization,
} from "~src/customHooks";

import {
  StartMyEmailData,
  StartMyEmailPayload,
  UseStartMyEmailMutationDefinition,
} from "~src/api/typings";
import { useStartMyEmailMutation } from "~src/redux";
import { SerializedError } from "@reduxjs/toolkit";
import { isFetchBaseQueryError, isLavenderAPIError } from "~src/typeGuards";
import { SME_ERROR } from "~src/strings";

interface Props {
  children: React.ReactNode;
}

export interface IStartMyEmailMutationContext {
  generatedEmailsData: StartMyEmailData | string[] | undefined;
  generatedEmailsError: string | undefined;
  generatedEmailsStatus: QueryStatus;
  startMyEmailMutation: (
    mutationArgs: Omit<StartMyEmailPayload, "signal">
  ) => Promise<
    | { error: FetchBaseQueryError | SerializedError }
    | { data: StartMyEmailData | string[] }
  >;
}

const initStartMyEmailMutationContext: IStartMyEmailMutationContext = {
  generatedEmailsData: undefined,
  generatedEmailsError: undefined,
  generatedEmailsStatus: QueryStatus.uninitialized,
  startMyEmailMutation: async () => {
    return await ({} as MutationActionCreatorResult<UseStartMyEmailMutationDefinition>);
  },
};

const StartMyEmailMutationContext = createContext(
  initStartMyEmailMutationContext
);

export const StartMyEmailMutationContextProvider = ({ children }: Props) => {
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = usePersonalization();
  //*INFO: call this as high up as possible because of ref usage
  //Cancel mutation if user navigates away and then back and the mutation is still running
  const {
    startMutation: startMyEmailMutation,
    data: generatedEmailsData,
    error: generatedEmailsError,
    status: generatedEmailsStatus,
  } = useSharedMutationWithAbort<
    Omit<StartMyEmailPayload, "signal">,
    StartMyEmailData | string[]
  >({
    mutation: useStartMyEmailMutation,
    fixedCacheKey: email,
  });

  const errorMsg =
    generatedEmailsError &&
    isFetchBaseQueryError(generatedEmailsError) &&
    isLavenderAPIError(generatedEmailsError.data)
      ? generatedEmailsError.data.message
      : SME_ERROR.SORRY;

  const startMyEmailMutationContext: IStartMyEmailMutationContext = useMemo(
    () => ({
      generatedEmailsStatus,
      generatedEmailsData,
      generatedEmailsError: errorMsg,
      startMyEmailMutation,
    }),
    [generatedEmailsStatus, generatedEmailsData, errorMsg, startMyEmailMutation]
  );
  return (
    <StartMyEmailMutationContext.Provider value={startMyEmailMutationContext}>
      {children}
    </StartMyEmailMutationContext.Provider>
  );
};

export const useStartMyEmailMutationContext = () => {
  const context = useContext(StartMyEmailMutationContext);
  if (context === undefined) {
    throw new Error(
      "useStartMyEmailMutationContext must be used within a StartMyEmailMutationContextProvider"
    );
  }
  return context;
};
