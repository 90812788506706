import { TwitterUser } from "~src/api/typings";
import { PersonalizationCompanyData } from "./personalization";

export interface ITweets {
  company_twitter: TwitterUser;
  profile_twitter: TwitterUser;
  company: PersonalizationCompanyData;
  loading: boolean;
}

export type RecipientTweetsMap = Record<emailAddress, ITweets | undefined>;

export interface TweetsData {
  toAddresses: emailAddress[];
  tweetsData: RecipientTweetsMap;
}
