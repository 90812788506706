import { Empty, Flex } from "antd";

import { Text } from "@lavender-ai/lav-components";

export const EmptyResults = () => (
  <div style={{ width: "100%", padding: "20px 0" }}>
    <Flex justify="center">
      <Empty
        description={
          <Text
            size="body1"
            style={{
              color: "var(--ant-color-text-quaternary)",
              margin: "0 auto",
            }}
          >
            No results
          </Text>
        }
        image={Empty.PRESENTED_IMAGE_DEFAULT}
      />
    </Flex>
  </div>
);
