import { CarouselRef } from "antd/es/carousel";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { Recommendation } from "~src/redux/typings";

interface IEmailCoachContext {
  data: EmailCoachCarouselContextData;
  view: EmailCoachView;
  next: (page: EmailCoachView, data?: EmailCoachCarouselContextData) => void;
  prev: () => void;
}

interface EmailCoachCarouselContextData {
  fixItRec?: Recommendation; // specific email transformer
}

export enum EmailCoachView {
  home = "home",
  fixItAll = "fixItAll",
}

const DATA = {};
const initialContext: IEmailCoachContext = {
  data: DATA,
  view: EmailCoachView.home,
  next: () => {},
  prev: () => {},
};

const EmailCoachCarouselContext =
  createContext<IEmailCoachContext>(initialContext);

export const EmailCoachCarouselContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [view, setView] = useState(initialContext.view);
  const [data, setData] = useState<EmailCoachCarouselContextData>(
    initialContext.data
  );

  const carouselRef = useRef<CarouselRef>(null);
  const prev = useCallback(() => {
    carouselRef?.current?.prev();
    setView(initialContext.view);
    setData(initialContext.data);
  }, []);
  const next = useCallback(
    (page: EmailCoachView, data?: EmailCoachCarouselContextData) => {
      carouselRef.current?.next();
      setView(page);
      setData(data || initialContext.data);
    },
    []
  );

  const initialState = useMemo(
    () => ({
      data,
      view,
      next,
      prev,
    }),
    [data, next, prev, view]
  );

  return (
    <EmailCoachCarouselContext.Provider value={initialState}>
      {children}
    </EmailCoachCarouselContext.Provider>
  );
};

export const useEmailCoachCarouselContext = () => {
  const context = useContext(EmailCoachCarouselContext);
  if (context === undefined) {
    throw new Error(
      "useEmailCoachCarouselContext must be used within a EmailCoachCarouselContext"
    );
  }
  return context;
};
