type SubmitFunction = () => void;

export const handleTextAreaEnterKeyDown = (
  event: React.KeyboardEvent<HTMLTextAreaElement>,
  formSubmitFunction: SubmitFunction
): void => {
  if (!event.shiftKey) {
    event.preventDefault();
    formSubmitFunction();
  }
};
