import { useContext } from "react";
import { styled } from "styled-components";
import { Flex, Card, Space } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import { Button, IconProps, Switch, Text } from "@lavender-ai/lav-components";

import { toggleSetting } from "~src/redux";
import type { AppDispatch } from "~src/redux/store";
import { useAppDispatch, useAppSelector } from "~src/redux/hooks";
import { CarouselContext } from ".";
import { StyledCard } from "./Styled";

export const ExtensionSettings = () => {
  const { prev } = useContext(CarouselContext);

  return (
    <Flex
      vertical
      style={{
        minWidth: "100%",
        backgroundColor: "#F6F8FC",
      }}
      justify="space-around"
      align="start"
    >
      <Flex
        className="lv-cta"
        justify="flex-start"
        align="center"
        style={{
          width: "100%",
          height: "64px",
          paddingLeft: "20px",
          boxSizing: "border-box",
        }}
        onClick={prev}
        vertical={false}
      >
        <LeftOutlined
          width="16px"
          height="16px"
          style={{
            padding: "8px",
            borderRadius: "14px",
            backgroundColor: "#E6E9F0",
            height: "14px",
            marginRight: "10px",
          }}
        />
        <Text color="black" size="subtext2">
          Email & Extension
        </Text>
      </Flex>
      <OptionList />
    </Flex>
  );
};

const OptionList = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.userAccount.settings.user);
  if (!user) return null;

  const {
    lavender_everywhere,
    open_tracking,
    weekly_email,
    performance_mode,
    ignore_from_analytics,
  } = user.settings;

  return (
    <Space
      className="lv-settings-extension-options-list"
      direction={"vertical"}
      style={{ height: "100%", padding: "0px 20px 12px 20px" }}
      size={8}
    >
      <SettingsSwitchCard
        title="Lavender Anywhere"
        body="Use Lavender on top of the tools & sites you use every day like Salesforce, LinkedIn, Google Docs and more."
        onClick={onToggleClick(dispatch, "lavender_everywhere")}
        checked={lavender_everywhere}
        prefixIcon="IconLavenderLogo"
      />
      <SettingsSwitchCard
        title="Track When Emails Are Read"
        body="Track opens by automatically inserting a tracking pixel into emails you send with the Lavender extension."
        onClick={onToggleClick(dispatch, "open_tracking")}
        checked={open_tracking}
        prefixIcon="IconChartDots"
      />
      <SettingsSwitchCard
        title="Weekly Email"
        body="Receive a weekly email summarizing your email activity & analytics."
        onClick={onToggleClick(dispatch, "weekly_email")}
        checked={weekly_email}
        prefixIcon="IconMailExclamation"
      />
      <SettingsSwitchCard
        title="Performance Mode"
        body="We’ll use less resources such as animations to lighten your experience if you’re using an older device."
        onClick={onToggleClick(dispatch, "performance_mode")}
        checked={performance_mode}
        prefixIcon="IconBolt"
      />
      <SettingsSwitchCard
        title="Ignore My Emails in Analytics"
        body="Ignore my email analysis in personal and team analytics."
        onClick={onToggleClick(dispatch, "ignore_from_analytics")}
        checked={ignore_from_analytics}
        prefixIcon="IconEarOff"
      />
    </Space>
  );
};

const onToggleClick = (dispatch: AppDispatch, setting) => (value: string) => {
  dispatch(toggleSetting({ setting, on: Boolean(value) }));
};

const StyledSettingsSwitchCard = styled(StyledCard)`
  padding: 16px 8px 20px 16px;
  width: 290px;
`;

const SettingsSwitchCard = ({
  title,
  body,
  onClick,
  checked,
  prefixIcon,
}: {
  title: string;
  body: string;
  onClick: (value: any) => void;
  checked: boolean;
  prefixIcon: IconProps["variant"];
}) => {
  return (
    <StyledSettingsSwitchCard className="lv-settings-card">
      <Button
        htmlType="button"
        icon={prefixIcon}
        radius="md"
        size="medium"
        state="initial"
        variant="secondary"
      />
      <Card
        title={
          <Text color="black" size="subtext2" style={{ margin: "unset" }}>
            {title}
          </Text>
        }
        extra={<Switch onClick={onClick} checked={checked} size="small" />}
        style={{ border: "none", width: "100%" }}
        headStyle={{
          borderBottom: "none",
          padding: "0 0 0 16px",
          minHeight: "unset",
          height: "10px",
          width: "98%",
        }}
        bodyStyle={{
          marginTop: "12px",
          padding: "0 0 0 16px",
          fontFamily: "Inter",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "18px",
          letterSpacing: "-0.25px",
          textAlign: "left",
          width: "calc(100% - 36px)",
        }}
      >
        <Text size="body1">{body}</Text>
      </Card>
    </StyledSettingsSwitchCard>
  );
};
