const bumps = "bumps";
const buzzwords = "buzzwords";
const cliches = "cliches";
const competitors = "competitors";
const cTAs = "ctas";
const generalValueProps = "general_value_props";
const gifts = "gifts";
const insinceres = "insinceres";
const slangs = "slangs";
const spams = "spams";
const triggers = "triggers";
const typesOfMeetings = "types_of_meetings";
const weakIntros = "weak_intros";

const CONTENT_FIELDS = [
  bumps,
  buzzwords,
  cliches,
  competitors,
  cTAs,
  generalValueProps,
  gifts,
  insinceres,
  slangs,
  spams,
  triggers,
  typesOfMeetings,
  weakIntros,
];

export function isContentField(field: string): boolean {
  return CONTENT_FIELDS.includes(field);
}
