import { Checkbox, CheckboxProps } from "@lavender-ai/lav-components";

export enum ExtensionCheckboxVariant {
  default = "default",
  compact = "compact",
}

interface Props {
  isChecked: boolean;
  isDisabled: boolean;
  onChange: CheckboxProps["onChange"];
  variant?: ExtensionCheckboxVariant;
}

export const ExtensionCheckbox = ({
  isChecked,
  isDisabled,
  onChange,
  variant = ExtensionCheckboxVariant.default,
}: Props) => {
  return (
    <Checkbox
      checked={isChecked}
      disabled={isDisabled}
      onChange={onChange}
      radius={variant === ExtensionCheckboxVariant.default ? "xl" : "sm"}
      size={variant === ExtensionCheckboxVariant.default ? "large" : "small"}
      style={{ alignSelf: "start", lineHeight: 0 }}
    />
  );
};
