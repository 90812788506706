import { CRMContextProvider } from "./CRMContextProvider";
import { NewsContextProvider } from "./NewsContextProvider";
import { PersonalizationContextProvider } from "./PersonalizationContextProvider";
import { TechStackContextProvider } from "./TechStackContextProvider";
import { TweetsContextProvider } from "./TweetsContextProvider";

interface Props {
  children: React.ReactNode;
}

export const ProfileDataContextProvider = ({ children }: Props) => {
  return (
    <PersonalizationContextProvider>
      <CRMContextProvider>
        <TweetsContextProvider>
          <TechStackContextProvider>
            <NewsContextProvider>{children}</NewsContextProvider>
          </TechStackContextProvider>
        </TweetsContextProvider>
      </CRMContextProvider>
    </PersonalizationContextProvider>
  );
};
