import { styled, css } from "styled-components";
import { Alert, ConfigProvider, Flex } from "antd";
import { useMemo } from "react";

import { Icon, Text } from "@lavender-ai/lav-components";

import { theme } from "~src/theme";

import { LavenderIcon, PropsForStyling } from "../../typings";
import { IconFrame } from "..";

interface StyledAlertContainerProps {
  variant?: PanelAlertVariant;
}

const StyledAlertContainer = styled.div<
  PropsForStyling<StyledAlertContainerProps>
>`
  text-align: left;
  width: 100%;

  .lv-panel-alert-plain-icon {
    align-self: start;
  }

  > div {
    border-radius: 12px;
  }

  .ant-alert-message {
    padding: 2px 0;
  }

  ${({ $variant: variant }) =>
    variant === PanelAlertVariant.summary &&
    css`
      .ant-alert {
        align-items: baseline;
        gap: 8px;
      }
      .ant-alert-content {
        align-self: start;
      }
    `}
`;

export enum PanelAlertVariant {
  plain = "plain",
  summary = "summary",
}

interface Props {
  message: string;
  textColor?: "dark" | "black";
  textSize?: "body1" | "body2";
  type?: "info" | "error";
  children?: React.ReactNode;
  variant?: PanelAlertVariant;
}

export const PanelAlert = ({
  message,
  type = "info",
  children = null,
  textColor = "dark",
  textSize = "body1",
  variant = PanelAlertVariant.plain,
}: Props) => {
  let icon: React.ReactNode;
  if (variant === PanelAlertVariant.summary && type === "info") {
    icon = <IconFrame color="lavender" variant={LavenderIcon.IconSparkles} />;
  } else {
    icon = (
      <Icon
        color="white"
        className="lv-panel-alert-plain-icon"
        size="small"
        variant={
          type === "error"
            ? LavenderIcon.IconCircleXFilled
            : LavenderIcon.IconInfoSquareRoundedFilled
        }
      />
    );
  }
  const themeConfig = useMemo(
    () => ({
      ...theme,
      components: {
        ...theme.components,
        Alert: {
          ...theme.components.Alert,
          borderRadiusLG:
            variant === PanelAlertVariant.summary
              ? 8
              : theme.components.Alert.borderRadiusLG,
          colorInfo:
            variant === PanelAlertVariant.summary
              ? "var(--color-lavender-400)"
              : theme.components.Alert.colorInfo,
          colorInfoBorder:
            variant === PanelAlertVariant.summary
              ? "var(--color-neutral-purple-100)"
              : theme.components.Alert.colorInfoBorder,
          colorInfoBg:
            variant === PanelAlertVariant.summary
              ? "var(--lv-panel-background-color)"
              : theme.components.Alert.colorInfoBg,
          defaultPadding:
            variant === PanelAlertVariant.summary
              ? 12
              : theme.components.Alert.defaultPadding,
        },
      },
    }),
    [variant]
  );
  return (
    <ConfigProvider theme={themeConfig}>
      <StyledAlertContainer
        $variant={type === "info" ? variant : PanelAlertVariant.plain}
      >
        <Alert
          banner
          icon={icon}
          showIcon
          message={
            <Flex gap="middle" vertical>
              <Text color={textColor} size={textSize}>
                {message}
              </Text>
              {children}
            </Flex>
          }
          type={type}
        />
      </StyledAlertContainer>
    </ConfigProvider>
  );
};
