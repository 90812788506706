import { useEffect, useRef, useState } from "react";

import { useHover } from "usehooks-ts";

import { useTimeout } from "./useTimeout";

interface IUseTooltip {
  isHover: boolean;
  hoverRef: React.MutableRefObject<HTMLElement | null>;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function useTooltip(
  initialOpen: boolean = false,
  timeout: number = 1000,
  triggers: ("hover" | "click")[] = ["hover"]
): IUseTooltip {
  const [open, setOpen] = useState(initialOpen);

  const hoverRef = useRef<HTMLElement | null>(null);
  const isHover = useHover(hoverRef);
  useTimeout(
    () => {
      setOpen(false);
    },
    open &&
      triggers.includes("click") &&
      !(triggers.includes("hover") && isHover)
      ? timeout
      : null
  );

  useEffect(() => {
    if (triggers.includes("hover")) {
      setOpen(isHover);
    }
  }, [open, triggers, isHover]);

  return {
    isHover,
    hoverRef,
    open,
    setOpen,
  };
}
