import { createContext, useCallback, useContext, useMemo } from "react";
import { QueryStatus } from "@reduxjs/toolkit/query";

import {
  useLvIdentifier,
  usePersonalization,
  useSharedMutationWithAbort,
} from "~src/customHooks";

import {
  GenerateValuePropsPayload,
  ValuePropsResponse,
  StartMyEmailCartPayload,
} from "~src/api/typings";
import {
  selectChromeId,
  useAppSelector,
  useGenerateValuePropsMutation,
} from "~src/redux";

interface Props {
  children: React.ReactNode;
}

export interface IValuePropMutationContext {
  generatedValuePropsData: ValuePropsResponse | undefined;
  generatedValuePropsStatus: QueryStatus;
  // Will there be a websocket stream for this mutation?
  isGenerateValuePropsWebsocketsOn: boolean;
  generateValuePropsMutation: (
    cart: StartMyEmailCartPayload,
    email: emailAddress
  ) => void;
}

const initValuePropMutationContext: IValuePropMutationContext = {
  generatedValuePropsData: undefined,
  generatedValuePropsStatus: QueryStatus.uninitialized,
  // Should check the "websockets" flag, but disabling value prop streaming for performance reasons
  isGenerateValuePropsWebsocketsOn: false,
  generateValuePropsMutation: () => {
    /* empty */
  },
};

const ValuePropMutationContext = createContext(initValuePropMutationContext);

export const ValuePropsMutationContextProvider = ({ children }: Props) => {
  const chromeId = useAppSelector(selectChromeId);
  const lvIdentifier = useLvIdentifier();
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = usePersonalization();

  const {
    startMutation,
    data: generatedValuePropsData,
    status: generatedValuePropsStatus,
  } = useSharedMutationWithAbort<
    Omit<GenerateValuePropsPayload, "signal">,
    ValuePropsResponse
  >({
    mutation: useGenerateValuePropsMutation,
    fixedCacheKey: email,
  });

  const generateValuePropsMutation = useCallback(
    (cart: StartMyEmailCartPayload, recipient: emailAddress) => {
      startMutation({
        chromeId,
        email: recipient,
        sessionId: lvIdentifier,
        stream: false,
        cart,
      });
    },
    [startMutation, chromeId, lvIdentifier]
  );

  const valuePropsMutationContext: IValuePropMutationContext = useMemo(
    () => ({
      generatedValuePropsStatus,
      generatedValuePropsData,
      generateValuePropsMutation,
      isGenerateValuePropsWebsocketsOn: false,
    }),
    [
      generatedValuePropsStatus,
      generatedValuePropsData,
      generateValuePropsMutation,
    ]
  );

  return (
    <ValuePropMutationContext.Provider value={valuePropsMutationContext}>
      {children}
    </ValuePropMutationContext.Provider>
  );
};

/**
 * //*INFO: call this as high up as possible because of ref usage. Cancel mutation if user navigates away and then back
 * and the mutation is still running
 *
 * - Encapsulates an abortable mutation for generating value props, its status and return value. On the backend, this also
 * initiates websocket streaming if its on. The value provided here should be the same as the value provided by the last
 * websocket message.
 **/

export const useValuePropsMutationContext = () => {
  const context = useContext(ValuePropMutationContext);

  if (context === undefined) {
    throw new Error(
      "useValuePropsMutationContext must be used within a ValuePropsMutationContextProvider"
    );
  }

  return context;
};
