import { XOR } from "ts-xor";

import { ErrorResponse } from "./api";

export interface SuccessResponse {
  data: AdditionalCompanyCategorized;
  jobs_aggregate: JobsAggregateData | null;
  success: string;
}

//INFO: ErrorResppnse if email is a bad domain - shouldn't happen since this request is after email verification
export type Response = SuccessResponse | ErrorResponse;

export interface AdditionalCompanyCategorized {
  jobs: AdditionalCompanyJobs;
}

interface BaseItem {
  id: string;
}

export interface AdditionalCompanyJobItem extends BaseItem {
  attributes: JobEntityAttribute;
  type: "job_opening";
}

export type FeedEntityAttribute = XOR<
  TechEntityAttribute,
  EventEntityAttribute
>;

export interface EventEntityAttribute extends BaseEntityAttribute {
  additionalData?: {
    articleAuthor?: string; // we don't get these from YouAPI, need crawling
    articleBody?: string; // we don't get these from YouAPI, need crawling
    articlePublishedAt: string;
    articleSentence: string;
    articleSource: string;
    date: string;
  };
  formattedTitle: string;
}

interface TechEntityAttribute extends BaseEntityAttribute {
  categories: string[];
  domain: string;
  first_seen_at: string;
  last_seen_at: string;
  description?: string;
}

export interface BaseEntityAttribute {
  title: string;
  url: string;
}

export interface JobEntityAttribute extends BaseEntityAttribute {
  additional_data: {
    job_title_seniority: JobTitleSeniority;
    tags?: string[];
    location_data: {
      country: string;
      city: string;
      state: string;
      fuzzy_match: boolean;
    };
  };
  categories: string[];
  description: string;
  first_seen_at: string;
  last_seen_at: string;
  location: string;
}

export enum JobTitleSeniority {
  Owner = "owner",
  Founder = "founder",
  CLevel = "c_level",
  Partner = "partner",
  President = "president",
  Director = "director",
  Manager = "manager",
  NonManager = "non_manager",
  Head = "head",
  VicePresident = "vice_president",
}

export interface JobsAggregateData {
  engineering: JobsAggregateDataAttribute;
  finance: JobsAggregateDataAttribute;
  human_resources: JobsAggregateDataAttribute;
  design: JobsAggregateDataAttribute;
  marketing: JobsAggregateDataAttribute;
  operations: JobsAggregateDataAttribute;
  sales: JobsAggregateDataAttribute;
  support: JobsAggregateDataAttribute;
}

interface AdditionalCompanyBase {
  domain?: string;
  last_updated: string;
}

interface AdditionalCompanyJobs extends AdditionalCompanyBase {
  data: AdditionalCompanyJobItem[] | null;
  meta: AdditionalCompanyMetaBase & { total_jobs_count: number };
}

interface AdditionalCompanyMetaBase {
  count: number;
  message?: string;
  message_type?: string;
}

// INFO: https://docs.predictleads.com/#job-opening-seniority
export interface JobsAggregateDataAttribute {
  [JobTitleSeniority.Manager]?: number;
  [JobTitleSeniority.NonManager]?: number;
  [JobTitleSeniority.Head]?: number;
  [JobTitleSeniority.VicePresident]?: number;
  [JobTitleSeniority.Owner]?: number;
  [JobTitleSeniority.Founder]?: number;
  [JobTitleSeniority.CLevel]?: number;
  [JobTitleSeniority.Partner]?: number;
  [JobTitleSeniority.Director]?: number;
  [JobTitleSeniority.President]?: number;
}
