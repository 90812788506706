import { useCallback, useState } from "react";
import { Flex } from "antd";

import { IconButton, Text } from "@lavender-ai/lav-components";

import {
  usePersonalization,
  useStartMyEmailGeneration,
  useTimeout,
} from "~src/customHooks";

import {
  ModalType,
  useCarouselContext,
} from "~src/component/Organisms/Personalization/CarouselContextProvider";
import { GENERATING_EMAILS_BACK_DISABLED_TIMEOUT } from "~src/constants";
import { CART_HEADER } from "~src/strings";
import { LavenderIcon } from "~src/component/typings";

import { StyledCartHeader } from "../../../Shared/Styled";

export const Header = () => {
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = usePersonalization();

  const {
    data: { rankedFeedCount },
    prev,
  } = useCarouselContext();
  const {
    generationCount,
    isEmailGenerationRejected,
    isEmailGenerationSuccess,
  } = useStartMyEmailGeneration();

  const [disabled, setDisabled] = useState(true);

  const onBack = useCallback(() => {
    prev({
      prevType: rankedFeedCount === 0 ? ModalType.Empty : ModalType.Cart,
      prevData:
        rankedFeedCount === 0
          ? {}
          : {
              openedBy: email,
              phase: CART_HEADER.VALUE_PROPS,
              rankedFeedCount,
            },
    });
  }, [email, prev, rankedFeedCount]);

  //Enabled timeout after 10 seconds to avoid rapid clicking but in case start my email got stuck in a pending state
  useTimeout(
    () => {
      setDisabled(false);
    },
    disabled ? GENERATING_EMAILS_BACK_DISABLED_TIMEOUT : null
  );

  const emailsGeneratedMsg = CART_HEADER.EMAILS_GENERATED(generationCount);

  return (
    <StyledCartHeader align="flex-start" gap={4} vertical>
      <Flex
        align="center"
        className="lv-cart-header"
        justify="flex-start"
        gap={12}
      >
        <IconButton
          state={
            isEmailGenerationSuccess || isEmailGenerationRejected
              ? "initial"
              : "disabled"
          }
          icon={LavenderIcon.IconChevronLeft}
          variant="secondary"
          onClick={onBack}
        />
        <Text color="dark" size="subtext3">
          {isEmailGenerationSuccess
            ? emailsGeneratedMsg
            : CART_HEADER.GENERATING}
        </Text>
      </Flex>
    </StyledCartHeader>
  );
};
