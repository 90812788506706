import styled from "styled-components";

export const SearchModal = styled.div`
  display: flex;
  position: absolute;
  top: 16px;
  left: 8px;
  width: calc(100% - 16px);
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: var(--radius-md-lrg, 12px);
  background: var(--color-base-White, #fff);
  z-index: 2;
`;

export const SearchModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.56;
  background: var(--color-neutral-purple-900, #0d1421);
  width: 100%;
  height: 100%;
`;

export const SearchModalOverlayContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2;
  height: 100%;
  top: 0;
  left: 0;
`;
