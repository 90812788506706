import { Empty } from "antd";
import { styled } from "styled-components";

import { Text } from "@lavender-ai/lav-components";

import { usePersonalization, useSearch, useTechStacks } from "~src/customHooks";
import { PERSONALIZATION_HEADER } from "~src/strings";
import { SecondaryText } from "~src/component/Styled";
import { NoDataDisplay, SearchInput } from "~src/component/Molecules";

import { SubTabListContainer } from "../Personalization/Shared";
import { TechStackList } from "./TechStackList";

const { TECH_STACK } = PERSONALIZATION_HEADER;

export const TechStack = () => {
  const {
    currentPersonalization: {
      profile: { email, loading: profileLoading },
    },
  } = usePersonalization();
  const { techStacks } = useTechStacks();
  const { techAggregate: aggregate, loading } = techStacks[email] || {};
  const techLoading = !techStacks[email] || profileLoading || loading;
  const empty = !techLoading && aggregate?.length === 0;

  const { isDisabled, searchTerm, filteredItems, handleSearch } = useSearch(
    aggregate ?? [],
    ["title"],
    "tech"
  );

  const totalTechCount = filteredItems.reduce(
    (acc, curr) => acc + curr.tech.length,
    0
  );

  return empty ? (
    <NoDataDisplay mainText="We couldn't find this Tech Stack." />
  ) : (
    <SubTabListContainer count={1} loading={techLoading} header={TECH_STACK}>
      <SearchInput
        disabled={isDisabled}
        handleSearch={handleSearch}
        placeholder="Search tech stack..."
        searchTerm={searchTerm}
      />
      {!techLoading && filteredItems.length === 0 && searchTerm && (
        <div style={{ margin: "0 auto", padding: "20px 0" }}>
          <Empty
            description={
              <Text
                size="body1"
                style={{
                  color: "var(--ant-color-text-quaternary)",
                  margin: "0 auto",
                }}
              >
                No results
              </Text>
            }
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        </div>
      )}
      {filteredItems?.length > 0 && (
        <>
          <InfoBar>
            <SecondaryText size="subtext1">
              {totalTechCount} technologies
            </SecondaryText>
          </InfoBar>
          <TechStackList aggregate={filteredItems} />
        </>
      )}
    </SubTabListContainer>
  );
};

const InfoBar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px 24px 36px;
`;
