export const snakeCaseToTitleCase = (s: string) =>
  s
    .replace(/_./g, (match) => ` ${match[1].toUpperCase()}`)
    .replace(/\b\w/g, (match) => match.toUpperCase());

export const titleCaseToSnakeCase = (s: string) =>
  s.replace(/\s+/g, "_").toLowerCase();

export const capitalizeFirstLetter = (str: string) => {
  if (str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const trimStringWithEllipsis = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + "...";
};

// convert bytes to KB/MB/GB
export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
