import { styled } from "styled-components";
import { Avatar, Button, Icon, Text } from "@lavender-ai/lav-components";

import HubspotLogoSVG from "~src/assets/images/HubspotLogo.svg";
import SalesforceLogoSVG from "~src/assets/images/SalesforceLogo.svg";
import { BILLING_PLANS, FRONTEND_URLS } from "~src/constants";
import { useAppSelector } from "~src/redux";
import { useMemo } from "react";

const ConnectCRMFrame = styled.div`
  display: flex;
  padding: var(--size-32, 32px) var(--size-24, 24px);
  flex-direction: column;
  align-items: center;
  gap: var(--size-24, 24px);
  align-self: stretch;
  border-radius: var(--radius-lrg, 16px);
  background: var(--color-base-White, #fff);
`;

const TitleFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

const ButtonsFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const UpgradeButtonFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-10, 10px);
  align-self: stretch;
`;

const UpgradeText = styled.div`
  flex: 1 0 0;
`;

const ContentCard = styled.div`
  display: flex;
  padding: 0px 12px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-bottom: 1px solid var(--color-neutral-purple-50);
`;

const ButtonInnerContainer = styled.div`
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
`;

enum SIGN_IN_COPY {
  CONNECT_YOUR_CRM = "Connect your CRM",
  UPGRADE_ACCOUNT = "Upgrade your account to Connect your CRM",
  SEAMLESSLY_PULL_IN_INFO = "Seamlessly pull-in important customer info and use it to craft emails & research!",
  UPGRADE = "Upgrade Account",
  ALREADY_HAVE_LAVENDER_PRO_OR_TEAMS = "Already have Lavender Pro or Teams?",
  LOGIN = "Login to Your Account",
}

export const CRMSignIn = () => {
  const billingPlan = useAppSelector(
    ({ userAccount }) =>
      userAccount.settings.user?.billing_plan ?? BILLING_PLANS.Basic
  );

  const shouldDisplayPaywall = useMemo(
    () => ![BILLING_PLANS.Teams, BILLING_PLANS.Pro].includes(billingPlan),
    [billingPlan]
  );

  return (
    <ConnectCRMFrame>
      <TitleFrame>
        <Avatar
          style={{ color: "var(--color-pear-200)" }}
          size="large"
          color="pear"
          icon={
            <Icon
              style={{ color: "var(--color-pear-700)" }}
              size="large"
              variant="IconApps"
            />
          }
        />
      </TitleFrame>
      <CopyContainer>
        <Text size="subtext4" color="dark">
          {shouldDisplayPaywall
            ? SIGN_IN_COPY.UPGRADE_ACCOUNT
            : SIGN_IN_COPY.CONNECT_YOUR_CRM}
        </Text>
        {!shouldDisplayPaywall && (
          <Text
            size="body1"
            style={{ color: "var(--color-neutral-purple-600)" }}
          >
            {SIGN_IN_COPY.SEAMLESSLY_PULL_IN_INFO}
          </Text>
        )}
      </CopyContainer>
      {shouldDisplayPaywall ? (
        <UpgradeButtonFrame>
          <Button variant="primary" size="medium">
            {SIGN_IN_COPY.UPGRADE}
          </Button>
          <UpgradeText>
            <Text
              size="body1"
              style={{ color: "var(--color-neutral-purple-600)", marginTop: 8 }}
            >
              {SIGN_IN_COPY.ALREADY_HAVE_LAVENDER_PRO_OR_TEAMS}
            </Text>
            <Text
              size="body1"
              style={{
                color: "var(--color-primary-lavender)",
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "bold",
                justifyContent: "center",
                margin: "4px auto 0",
              }}
              onClick={() =>
                window.open(FRONTEND_URLS.teamsIntegration, "_blank")
              }
            >
              {SIGN_IN_COPY.LOGIN}
            </Text>
          </UpgradeText>
        </UpgradeButtonFrame>
      ) : (
        <ButtonsFrame>
          <ButtonRow name="HubSpot" />
          <ButtonRow name="Salesforce" />
        </ButtonsFrame>
      )}
    </ConnectCRMFrame>
  );
};

const ButtonRow = ({ name }: { name: "HubSpot" | "Salesforce" }) => (
  <ContentCard>
    <ButtonInnerContainer>
      <Avatar
        type="logo"
        size="small"
        src={name === "HubSpot" ? HubspotLogoSVG : SalesforceLogoSVG}
      />
      <Text size="subtext2" color="dark">
        {name}
      </Text>
      <Button
        style={{ marginLeft: "auto" }}
        prefix="IconPlus"
        size="small"
        onClick={() => window.open(FRONTEND_URLS.teamsIntegration, "_blank")}
      >
        Connect
      </Button>
    </ButtonInnerContainer>
  </ContentCard>
);
