import styled, { css } from "styled-components";

import { SKELETON_GRADIENT_MIXIN } from "~src/component/Molecules/Message/styles";
import { PropsForStyling } from "~src/component/typings";

interface StyledLoadableTextProps {
  color?: string;
  height?: string;
  loading?: boolean;
}

const StyledLoadableText = styled.span<
  PropsForStyling<StyledLoadableTextProps>
>`
  & {
    color: ${({ $color: color, $loading: loading }) =>
      loading ? "transparent" : color};
    ${({ $height: height, $loading: loading }) =>
      loading &&
      css`
        border-color: transparent;
        border-radius: 15px;
        display: inline-block;
        height: ${height};
        pointer-events: none;
        ${SKELETON_GRADIENT_MIXIN}
      `};
  }
`;

interface Props {
  color?: string;
  height?: string;
  loading?: boolean;
  text: string;
}

export const LoadableText = ({ color, height, loading, text }: Props) => (
  <StyledLoadableText $color={color} $height={height} $loading={loading}>
    {text}
  </StyledLoadableText>
);
