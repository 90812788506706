export enum LavenderIcon {
  IconActivity = "IconActivity",
  IconAlertTriangleFilled = "IconAlertTriangleFilled",
  IconArticle = "IconArticle",
  IconArrowUpRight = "IconArrowUpRight",
  IconAt = "IconAt",
  IconAward = "IconAward",
  IconBeaker = "IconBeaker",
  IconBrandCrunchbase = "IconBrandCrunchbase",
  IconBrandGoogleFilled = "IconBrandGoogleFilled",
  IconBrandLinkedin = "IconBrandLinkedin",
  IconBrandOpenAI = "IconBrandOpenai",
  IconBrandTwitter = "IconBrandTwitter",
  IconBriefcase = "IconBriefcase",
  IconBulb = "IconBulb",
  IconBuilding = "IconBuilding",
  IconBuildingBroadcastTower = "IconBuildingBroadcastTower",
  IconBuildingSkyscraper = "IconBuildingSkyscraper",
  IconCalendarCheck = "IconCalendarCheck",
  IconCheck = "IconCheck",
  IconChevronDown = "IconChevronDown",
  IconChevronLeft = "IconChevronLeft",
  IconChevronRight = "IconChevronRight",
  IconChevronsRight = "IconChevronsRight",
  IconChevronUp = "IconChevronUp",
  IconCircleCheckFilled = "IconCircleCheckFilled",
  IconCircleXFilled = "IconCircleXFilled",
  IconDeviceWatch = "IconDeviceWatch",
  IconDeviceMobile = "IconDeviceMobile",
  IconCopy = "IconCopy",
  IconDots = "IconDots",
  IconGif = "IconGif",
  IconGongLogo = "IconGongLogo",
  IconHubspotLogo = "IconHubspotLogo",
  IconInfoSquareRoundedFilled = "IconInfoSquareRoundedFilled",
  IconLink = "IconLink",
  IconList = "IconList",
  IconMail = "IconMail",
  IconMailBolt = "IconMailBolt",
  IconMailDown = "IconMailDown",
  IconMailForward = "IconMailForward",
  IconMailUp = "IconMailUp",
  IconMapPin = "IconMapPin",
  IconMinus = "IconMinus",
  IconMobilePreview = "IconDeviceMobileMessage",
  IconMoonFilled = "IconMoonFilled",
  IconMoodSmile = "IconMoodSmile",
  IconNews = "IconNews",
  IconNotesOff = "IconNotesOff",
  IconPencil = "IconPencil",
  IconPhone = "IconPhone",
  IconPlus = "IconPlus",
  IconPointer = "IconPointer",
  IconRefresh = "IconRefresh",
  IconReportMoney = "IconReportMoney",
  IconSalesforce = "IconSalesforce",
  IconSchool = "IconSchool",
  IconSearch = "IconSearch",
  IconSettings = "IconSettings",
  IconShoppingBagMinus = "IconShoppingBagMinus",
  IconShoppingBagPlus = "IconShoppingBagPlus",
  IconSparkles = "IconSparkles",
  IconStack = "IconStack",
  IconStars = "IconStars",
  IconSun = "IconSun",
  IconUser = "IconUser",
  IconUsers = "IconUsers",
  IconUsersGroup = "IconUsersGroup",
  IconUserQuestion = "IconUserQuestion",
  IconUserSearch = "IconUserSearch",
  IconWand = "IconWand",
  IconWorld = "IconWorld",
  IconWorldDollar = "IconWorldDollar",
  IconWorldSearch = "IconWorldSearch",
  IconX = "IconX",
}
