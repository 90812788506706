import { memo, useCallback } from "react";
import { Flex } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { styled } from "styled-components";

import { Text, TextProps } from "@lavender-ai/lav-components";

import {
  ExtensionCheckbox,
  ExtensionCheckboxVariant,
} from "~src/component/Atoms";
import { ImageFallbackAvatar } from "~src/component/Molecules";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
} from "~src/component/Utilities/Loading";
import { PanelListItem } from "~src/layout/MainPanel/PanelBlock";

import { DateTriggerDivider, SecondaryText } from "~src/component/Styled";
import { MAX_ICEBREAKERS } from "~src/constants";
import { usePersonalization } from "~src/customHooks";
import { PersonalizationEvent, WorkHistory } from "~src/redux/typings";
import { isRankedFeedExperience } from "~src/typeGuards";
import {
  getJobsDatesString,
  getJobDatesString,
  icebreakerCount,
  segmentEvent,
  SegmentEventLocations,
  SegmentEventNames,
  triggerColor,
  prettyDate,
} from "~src/utils";

const VerticalLine = styled.div`
  width: 2px;
  height: 24px;
`;

const Timeline = styled(Flex)`
  ${SecondaryText} {
    &.lav-components-text-color-black.lav-components-text-underline-undefined.lav-components-text {
      color: var(--color-neutral-blue-300);
    }
  }

  ${VerticalLine} {
    background-color: var(--color-neutral-blue-300);
  }
`;

interface Props {
  loading?: boolean;
  showCartAction?: boolean;
  workHistory: WorkHistory[];
}

export const WorkHistoryList = ({
  loading,
  showCartAction,
  workHistory,
}: Props) => {
  const {
    addItemToCartContext,
    currentPersonalization: { cart: cartContext },
    removeItemFromCartContext,
  } = usePersonalization();

  const count = icebreakerCount(cartContext);

  const onIcebreakerSelect = useCallback(
    (e: CheckboxChangeEvent, item: PersonalizationEvent) => {
      e.stopPropagation();
      const { checked } = e.target;
      if (checked) {
        addItemToCartContext(item);
      } else {
        removeItemFromCartContext(item);
      }
      segmentEvent(
        SegmentEventNames.IcebreakerClicked,
        SegmentEventLocations.StartMyEmail
      );
    },
    [removeItemFromCartContext, addItemToCartContext]
  );

  if (loading && !showCartAction) {
    return (
      <>
        <PanelListItem>
          <CustomSkeleton variant={CustomSkeletonVariant.avatarListItem} />
        </PanelListItem>
        <PanelListItem>
          <CustomSkeleton variant={CustomSkeletonVariant.avatarListItem} />
        </PanelListItem>
      </>
    );
  }

  const groupedWorkHistory: Record<string, WorkHistory[]> = {};
  for (let i = 0; i < workHistory.length; i++) {
    if (!showCartAction) {
      if (groupedWorkHistory[workHistory[i]?.employer] === undefined) {
        groupedWorkHistory[workHistory[i].employer] = [workHistory[i]];
      }
      for (
        let j = i + 1;
        workHistory[i]?.employer === workHistory[j]?.employer;
        j++
      ) {
        groupedWorkHistory[workHistory[i].employer].push(workHistory[j]);
        i = j;
      }
      if (
        groupedWorkHistory[workHistory[workHistory.length - 1]?.employer] ===
        undefined
      ) {
        groupedWorkHistory[workHistory[workHistory.length - 1]?.employer] = [
          workHistory[workHistory.length - 1],
        ];
      }
    } else {
      groupedWorkHistory[i] = [workHistory[i]];
    }
  }

  const workHistoryArr = Object.values(groupedWorkHistory)
    .flat()
    .sort((a, b) => {
      const dateA = new Date(a.start);
      const dateB = new Date(b.start);

      return dateB.getTime() - dateA.getTime();
    });

  let arr: [string, WorkHistory[]][] = [];
  if (showCartAction) {
    arr = workHistoryArr.map((whItem) => {
      return [whItem.employer, [whItem]];
    });
  } else {
    const sortedGroupedWorkHistory = workHistoryArr.reduce((acc, curr) => {
      if (!acc[curr.employer]) {
        acc[curr.employer] = [];
      }
      acc[curr.employer].push(curr);
      return acc;
    }, {});
    arr = Object.entries(sortedGroupedWorkHistory);
  }

  return (
    <>
      {arr.map(([employer, workHistoryItems], i) => {
        const experience = workHistoryItems[0];
        const jobDates =
          workHistoryItems.length > 1
            ? getJobsDatesString(workHistoryItems)
            : getJobDatesString(experience);
        const isInCart = !!cartContext?.experience?.items.some(
          (item) => item.id === experience.id
        );
        const trigger =
          experience.triggers !== undefined && experience.triggers.length > 0
            ? experience.triggers[0]
            : undefined;
        const start = trigger?.attributes.date;
        const formattedDate = start ? prettyDate(start) : 0;

        return (
          <div className="lv-panel-list-item-wrapper" key={experience.id}>
            <PanelListItem dense>
              <div className="lv-panel-list-item-inner-container">
                {showCartAction && isRankedFeedExperience(experience) && (
                  <>
                    <ExtensionCheckbox
                      isChecked={isInCart}
                      isDisabled={!isInCart && count >= MAX_ICEBREAKERS}
                      onChange={(e) => {
                        onIcebreakerSelect(e, experience);
                      }}
                      variant={ExtensionCheckboxVariant.default}
                    />
                    <Flex gap={showCartAction ? 16 : 12} vertical>
                      <div>
                        <Text color="dark" size="subtext2">
                          {experience.formattedTitle || experience.title || ""}
                        </Text>
                      </div>
                      <Flex align="center" gap={6}>
                        {formattedDate !== 0 && (
                          <SecondaryText size="body1">
                            {formattedDate}
                          </SecondaryText>
                        )}
                        {formattedDate !== 0 && (
                          <DateTriggerDivider size="body1" />
                        )}
                        <Text
                          color={
                            triggerColor(
                              trigger?.name || "",
                              i
                            ) as TextProps["color"]
                          }
                          size="body1"
                        >
                          {trigger?.formattedName}
                        </Text>
                      </Flex>
                    </Flex>
                  </>
                )}
                {!showCartAction && (
                  <>
                    <div>
                      <ImageFallbackAvatar
                        src={experience.logo || ""}
                        alt="job-avatar"
                        size="small"
                        fallback={employer || ""}
                        fallbackLetter={employer[0]}
                      />
                      <Flex
                        align="center"
                        gap={8}
                        style={{ paddingTop: "16px" }}
                        vertical
                      >
                        <PromotionTimeLine
                          workHistoryItems={workHistoryItems}
                        />
                      </Flex>
                    </div>
                    <Flex gap={12} vertical>
                      <div>
                        <Text color="dark" size="subtext2">
                          {workHistoryItems.length > 1
                            ? experience.employer
                            : experience.title}
                        </Text>
                      </div>
                      {workHistoryItems.length === 1 && (
                        <Text color="dark" size="body1">
                          {experience.employer}
                        </Text>
                      )}
                      <Flex align="baseline" gap="small">
                        <SecondaryText $light size="body1">
                          {jobDates}
                        </SecondaryText>
                      </Flex>
                      <PromotionItem workHistoryItems={workHistoryItems} />
                    </Flex>
                  </>
                )}
              </div>
            </PanelListItem>
          </div>
        );
      })}
    </>
  );
};

interface PromotionProps {
  workHistoryItems: WorkHistory[];
}

const PromotionTimeLine = memo(function PromotionTimeLine({
  workHistoryItems,
}: PromotionProps) {
  if (workHistoryItems.length <= 1) {
    return null;
  }

  return workHistoryItems.map((wh, index, array) => (
    <Timeline
      align="center"
      gap={8}
      key={`promotion-timeline-${wh.id}-${index}`}
      vertical
    >
      <SecondaryText> &#x2022; </SecondaryText>
      {index !== array.length - 1 && <VerticalLine />}
    </Timeline>
  ));
});

const PromotionItem = memo(function PromotionItem({
  workHistoryItems,
}: PromotionProps) {
  return workHistoryItems.length > 1
    ? workHistoryItems.map((wh, i) => {
        return (
          <Flex
            key={`promotion-item-${wh.id}-${i}-2`}
            gap={12}
            vertical
            style={{ paddingTop: "8px" }}
          >
            <Text color="dark" size="body1">
              {wh.title}
            </Text>
            <SecondaryText $light size="body1">
              {getJobDatesString(wh)}
            </SecondaryText>
          </Flex>
        );
      })
    : null;
});
