import {
  Selector,
  createSelector,
  createSelectorCreator,
  weakMapMemoize,
} from "@reduxjs/toolkit";
import { unstable_autotrackMemoize, UnknownMemoizer } from "reselect";

import { RootState } from "./store";

export const createAppSelector: TypedCreateSelector<RootState> = createSelector;
export type TypedCreateSelector<
  State,
  MemoizeFunction extends UnknownMemoizer = typeof weakMapMemoize,
  ArgsMemoizeFunction extends UnknownMemoizer = typeof weakMapMemoize,
> = <
  InputSelectors extends readonly Selector<State>[],
  Result,
  OverrideMemoizeFunction extends UnknownMemoizer = MemoizeFunction,
  OverrideArgsMemoizeFunction extends UnknownMemoizer = ArgsMemoizeFunction,
>(
  ...createSelectorArgs: Parameters<
    typeof createSelector<
      InputSelectors,
      Result,
      OverrideMemoizeFunction,
      OverrideArgsMemoizeFunction
    >
  >
) => ReturnType<
  typeof createSelector<
    InputSelectors,
    Result,
    OverrideMemoizeFunction,
    OverrideArgsMemoizeFunction
  >
>;

// Good for testing
export const createSelectorAutotrack = createSelectorCreator({
  memoize: unstable_autotrackMemoize,
});
