import React, { useContext } from "react";
import { Flex } from "antd";
import { Text } from "@lavender-ai/lav-components";
import { LeftOutlined } from "@ant-design/icons";
import { CarouselContext } from ".";

interface Props {
  name: string;
  children: React.ReactNode;
}

export const SettingsWrapper = ({ name, children }: Props) => {
  const { prev } = useContext(CarouselContext);

  return (
    <Flex
      vertical={true}
      style={{
        width: "100%",
        backgroundColor: "#F6F8FC",
      }}
      justify="space-around"
      align="start"
    >
      <Flex
        className="lv-cta"
        justify="flex-start"
        align="center"
        style={{
          width: "100%",
          height: "64px",
          paddingLeft: "20px",
          boxSizing: "border-box",
        }}
        onClick={prev}
        vertical={false}
      >
        <LeftOutlined
          width="16px"
          height="16px"
          style={{
            padding: "8px",
            borderRadius: "14px",
            backgroundColor: "#E6E9F0",
            height: "14px",
            marginRight: "10px",
          }}
        />
        <Text color="black" size="subtext2">
          {name}
        </Text>
      </Flex>
      <Flex
        justify="flex-start"
        align="left"
        vertical={true}
        style={{
          width: "375px",
          padding: "0px",
        }}
      >
        {children}
      </Flex>
    </Flex>
  );
};
