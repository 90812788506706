import { useCallback, useEffect, useMemo, useState } from "react";
import { Flex } from "antd";
import { sanitize } from "dompurify";
import { styled } from "styled-components";

import { IconButton, Text } from "@lavender-ai/lav-components";

import {
  ExtensionButton,
  ExtensionButtonMode,
  LavenderLogo,
} from "~src/component/Atoms";
import {
  AssistantMessage,
  CopyButton,
  UserMessage,
} from "~src/component/Molecules";
import { LavenderIcon } from "~src/component/typings";
import { useEmailCoachCarouselContext } from "~src/contexts";
import { useLvIdentifier, usePrevious } from "~src/customHooks";
import {
  getBoostScoreSuggestion,
  selectChromeId,
  selectBoostScoreErrorBySessionId,
  selectBoostScorePromptNameBySessionId,
  selectBoostScoreRecommendationsBySessionId,
  selectBoostScoreStatusBySessionId,
  selectBoostScoreSuggestionBySessionId,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";
import { NetworkStatus } from "~src/redux/typings";
import {
  BUTTON_TEXT,
  EMAIL_COACH_MESSAGE,
  EMAIL_COACH_RECOMMENDATIONS,
  SUGGESTIONS,
} from "~src/strings";
import {
  extractUserDataForProfile,
  getEmailTransformerChatRecText,
  isSubjectRecommendation,
} from "~src/utils";
import {
  SegmentEventLocations,
  SegmentEventNames,
  segmentEvent,
} from "~src/utils/segmentEvent";

import { getWritingData } from "../../utils";

const { FIX_IT_ALL } = SUGGESTIONS;

export const FixItAllChat = () => {
  const dispatch = useAppDispatch();
  const {
    data: { fixItRec },
  } = useEmailCoachCarouselContext();
  const { field: fixItRecField, rectype: fixItRecType } = fixItRec || {};

  const lvIdentifier = useLvIdentifier();
  const recommendations = useAppSelector((state) =>
    selectBoostScoreRecommendationsBySessionId(state, lvIdentifier)
  );
  const promptName = useAppSelector((state) =>
    selectBoostScorePromptNameBySessionId(state, lvIdentifier)
  );
  const boostScoreError = useAppSelector((state) =>
    selectBoostScoreErrorBySessionId(state, lvIdentifier)
  );
  const boostScoreStatus = useAppSelector((state) =>
    selectBoostScoreStatusBySessionId(state, lvIdentifier)
  );
  const {
    bypassed_ai: fixItAllBypassedAI,
    subject: fixItAllSubject,
    email: fixItAllEmail,
  } = useAppSelector((state) =>
    selectBoostScoreSuggestionBySessionId(state, lvIdentifier)
  );
  const user = useAppSelector(
    ({
      userAccount: {
        settings: { user },
      },
    }) => user
  );

  const chromeId = useAppSelector(selectChromeId);
  const { prev } = useEmailCoachCarouselContext();

  const suggestion = useMemo(() => {
    if (boostScoreStatus === NetworkStatus.failed) {
      return {
        email: boostScoreError,
        subject: "",
      };
    }
    if (isSubjectRecommendation(fixItRec)) {
      return {
        email: "",
        subject: sanitize(fixItAllSubject.replace(/\n/g, "<br>")),
      };
    }
    return {
      email: sanitize(fixItAllEmail.replace(/\n/g, "<br>")),
      subject: sanitize(fixItAllSubject.replace(/\n/g, "<br>")),
    };
  }, [
    boostScoreError,
    boostScoreStatus,
    fixItAllEmail,
    fixItAllSubject,
    fixItRec,
  ]);

  const analysisData = useAppSelector(
    (state) => state.emailAnalysis[lvIdentifier]
  );
  const writingData = getWritingData(lvIdentifier);
  const body = writingData.body;
  const subject = writingData.subject;
  const stage = analysisData.stage;

  const prevBody = usePrevious(body);
  const prevSubject = usePrevious(subject);
  const [rephraseDisabled, setRephraseDisabled] = useState(false);

  useEffect(() => {
    if (prevSubject !== subject || prevBody !== body) {
      if (!fixItRec?.prompt_name) {
        setRephraseDisabled(true);
        return;
      }
      if (isSubjectRecommendation(fixItRec)) {
        if (prevSubject !== subject) {
          setRephraseDisabled(true);
        }
      } else if (prevBody !== body) {
        setRephraseDisabled(true);
      }
    }
  }, [body, prevBody, prevSubject, fixItRec, rephraseDisabled, subject]);

  //INFO: rephrase is currently for the current email, not the fix it all suggestion. Basically just re-runs fix it all
  const rephrase = useCallback(() => {
    const controller = new AbortController();
    void dispatch(
      getBoostScoreSuggestion({
        controller,
        lvIdentifier,
        chromeId,
        body,
        subject,
        recommendations,
        promptName,
        stage,
      })
    );
  }, [
    dispatch,
    lvIdentifier,
    chromeId,
    body,
    subject,
    recommendations,
    promptName,
    stage,
  ]);

  const onBack = useCallback(() => {
    segmentEvent(
      SegmentEventNames.BackButtonClicked,
      SegmentEventLocations.EmailCoach
    );
    prev();
  }, [prev]);

  const { avatar, displayName, email } = extractUserDataForProfile(user);

  let parsedSuggestionContent = suggestion?.subject
    ? `Subject: ${suggestion?.subject}${suggestion?.email ? "\n" : ""}`
    : "";
  parsedSuggestionContent += suggestion?.email ?? "";
  //INFO: don't add "Subject: " to the copy text if there is no body
  const parsedCopySuggestionContent = isSubjectRecommendation(fixItRec)
    ? suggestion?.subject
    : parsedSuggestionContent;

  const actions = useMemo(() => {
    const localActions: React.ReactNode[] | undefined = [];
    // TODO: there is a bug here for SPAM Detected: bc of the mark.js in the body. Also you can't rephrase Subject Case
    // and Punctuation. In these cases rephrase is always disabled so we just hide the button
    if (
      !fixItAllBypassedAI &&
      fixItRecField !== "spam" &&
      !isSubjectRecommendation(fixItRec) // TODO: need to implement chat history to try rephrase for subject, otherwise just comes back with the same suggestion most of the time
    ) {
      localActions.push(
        <ExtensionButton
          disabled={rephraseDisabled}
          key="rephrase"
          mode={ExtensionButtonMode.chip}
          onClick={rephrase}
          prefix={LavenderIcon.IconWand}
          size="small"
          text={BUTTON_TEXT.REPHRASE}
          variant="secondary"
        />
      );
    }
    localActions.push(
      <CopyButton key="copy" copyText={parsedCopySuggestionContent} />
    );
    return localActions;
  }, [
    fixItAllBypassedAI,
    fixItRec,
    fixItRecField,
    parsedCopySuggestionContent,
    rephrase,
    rephraseDisabled,
  ]);

  if (recommendations[0] === undefined) {
    return null;
  }

  return (
    <Flex vertical>
      <ChatContainer align="center">
        <BackButton onClick={onBack} icon={LavenderIcon.IconChevronLeft} />
        <Text color="black" size="subtext3">
          {promptName ? EMAIL_COACH_RECOMMENDATIONS.SUGGESTION : FIX_IT_ALL}
        </Text>
        {promptName && fixItRecType && (
          <SubText size="body2">/ {fixItRecType}</SubText>
        )}
      </ChatContainer>
      <ChatContainer vertical gap={24}>
        <UserMessage
          avatar={avatar}
          content={
            promptName
              ? getEmailTransformerChatRecText(fixItRec)
              : EMAIL_COACH_MESSAGE.BOOST_SCORE
          }
          name={displayName || email}
        />
        {boostScoreStatus === NetworkStatus.loading && (
          <Flex gap="small">
            <div>
              <LavenderLogo size={32} />
            </div>
            <AssistantMessage content="..." status={NetworkStatus.loading} />
          </Flex>
        )}
        {boostScoreStatus === NetworkStatus.success && (
          <AssistantMessage
            actions={actions}
            avatar={<LavenderLogo size={32} />}
            content={parsedSuggestionContent}
            status={NetworkStatus.success}
          />
        )}
        {boostScoreStatus === NetworkStatus.failed && (
          <AssistantMessage
            avatar={<LavenderLogo size={32} />}
            content={boostScoreError}
            status={NetworkStatus.failed}
          />
        )}
      </ChatContainer>
    </Flex>
  );
};

const SubText = styled(Text)`
  color: var(--color-neutral-purple-600, #6d727d) !important;
  margin-left: 4px !important;
`;
const BackButton = styled(IconButton)`
  margin-right: 12px;
`;
const ChatContainer = styled(Flex)`
  padding: 16px 20px;
`;
