import { Flex } from "antd";
import { styled } from "styled-components";

import { Text } from "@lavender-ai/lav-components";

import { IconFrame } from "~src/component/Atoms";
import { EnhancedFlex, SecondaryText } from "~src/component/Styled";
import { LavenderIcon } from "~src/component/typings";
import {
  CustomSkeleton,
  CustomSkeletonVariant,
  LoadableBadge,
  LoadableText,
} from "~src/component/Utilities";
import { SME_BADGE, SME_CAPTION, SME_SUBHEADER } from "~src/strings";

const StyledInstructionsWrapper = styled(Flex)`
  background:
    linear-gradient(var(--color-base-White), var(--color-base-White))
      padding-box,
    linear-gradient(to right, #62efc2, #fbcb48, #fd92dc, #fefdff, #88effd)
      border-box;
  border: 3px solid transparent;
  border-radius: 20px;
  overflow: hidden;
  padding: 16px;
  text-align: left;
  z-index: 1;

  .lv-instructions-subtitle-skeleton {
    margin-top: -14px;
  }
`;

interface Props {
  children: JSX.Element;
  isLoading?: boolean;
  isOptional?: boolean;
}

export const Instructions = ({ children, isLoading, isOptional }: Props) => {
  return (
    <StyledInstructionsWrapper
      align="flex-start"
      className="lv-icebreakers-instructions"
      gap={12}
      vertical
    >
      <EnhancedFlex justify="space-between" $isFullWidth>
        <Flex align={isLoading ? "flex-end" : "center"} gap={12}>
          <IconFrame
            color="pear"
            variant={LavenderIcon.IconWand}
            isLoading={isLoading}
          />
          <Text color="dark" size="subtext3">
            <LoadableText
              height="8px"
              loading={isLoading}
              text={SME_SUBHEADER.INSTRUCTIONS}
            />
          </Text>
        </Flex>
        {isOptional && (
          <LoadableBadge
            isLoading={isLoading}
            variant="dark"
            style="soft"
            text={SME_BADGE.OPTIONAL}
          />
        )}
      </EnhancedFlex>
      {children}
      <SecondaryText size="body1" $light>
        <LoadableText
          height="10.5px"
          loading={isLoading}
          text={SME_CAPTION.CUSTOM_TOPICS}
        />
        {isLoading && (
          <CustomSkeleton
            className="lv-instructions-subtitle-skeleton"
            loading={isLoading}
            variant={CustomSkeletonVariant.subTitle}
          />
        )}
      </SecondaryText>
    </StyledInstructionsWrapper>
  );
};
