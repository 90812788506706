import { createAction } from "@reduxjs/toolkit";

import {
  CRMData,
  NewsData,
  TweetsData,
  type PersonalizationData,
} from "../typings";
import { TechStackData } from "../typings/techStack";

export const updatePersonalizations = createAction<PersonalizationData>(
  "feed/updatePersonalizations"
);

export const updateCRMs = createAction<CRMData>("feed/updateCRMs");

export const updateNews = createAction<NewsData>("feed/updateNews");

export const updateTweets = createAction<TweetsData>("feed/updateTweets");

export const updateTechStacks = createAction<TechStackData>(
  "feed/updateTechStacks"
);
