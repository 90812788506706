import Lottie from "react-lottie";

const animationData = {
  v: "5.7.5",
  fr: 100,
  ip: 0,
  op: 260,
  w: 100,
  h: 100,
  nm: "Comp 1",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: "",
      sr: 1,
      ks: {
        p: { a: 0, k: [50, 50], ix: 2 },
        a: { a: 0, k: [0, 0], ix: 2 },
        s: { a: 0, k: [200, 200], ix: 2 },
        r: { a: 0, k: 0, ix: 2 },
        o: { a: 0, k: 100, ix: 2 },
      },
      ao: 0,
      ip: 0,
      op: 261,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "spinner",
      sr: 1,
      ks: {
        p: { a: 0, k: [0, 0], ix: 2 },
        a: { a: 0, k: [0, 0], ix: 2 },
        s: { a: 0, k: [100, 100], ix: 2 },
        r: { a: 0, k: 0, ix: 2 },
        o: { a: 0, k: 100, ix: 2 },
      },
      ao: 0,
      parent: 1,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "el",
              d: 1,
              s: { a: 0, k: [28, 28], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 2 },
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [
                  0.41568627450980394, 0.16862745098039217, 0.9137254901960784,
                ],
                ix: 2,
              },
              o: { a: 0, k: 100, ix: 2 },
              w: { a: 0, k: 4, ix: 2 },
              lc: 2,
              lj: 2,
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    t: 0,
                    s: [0],
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    t: 65,
                    s: [15],
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    t: 131,
                    s: [0],
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    t: 196,
                    s: [15],
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    t: 262,
                    s: [0],
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
                ix: 2,
              },
              e: {
                a: 1,
                k: [
                  {
                    t: 0,
                    s: [20],
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    t: 65,
                    s: [16],
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    t: 131,
                    s: [20],
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    t: 196,
                    s: [16],
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    t: 262,
                    s: [20],
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
                ix: 2,
              },
              o: {
                a: 1,
                k: [
                  {
                    t: 0,
                    s: [0],
                    i: { x: [0.58], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    t: 65,
                    s: [360],
                    i: { x: [1], y: [1] },
                    o: { x: [0.42], y: [0] },
                  },
                  {
                    t: 131,
                    s: [720],
                    i: { x: [0.58], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    t: 196,
                    s: [1080],
                    i: { x: [1], y: [1] },
                    o: { x: [0.42], y: [0] },
                  },
                  {
                    t: 262,
                    s: [1440],
                    i: { x: [0.58], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
                ix: 2,
              },
              m: 1,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 2 },
              s: { a: 0, k: [100, 100], ix: 2 },
              r: { a: 0, k: 0, ix: 2 },
              o: { a: 0, k: 100, ix: 2 },
              sk: { a: 0, k: 0, ix: 2 },
              sa: { a: 0, k: 0, ix: 2 },
            },
          ],
        },
      ],
      ip: 0,
      op: 261,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "bg",
      sr: 1,
      ks: {
        p: { a: 0, k: [0, 0], ix: 2 },
        a: { a: 0, k: [0, 0], ix: 2 },
        s: { a: 0, k: [100, 100], ix: 2 },
        r: { a: 0, k: 0, ix: 2 },
        o: { a: 0, k: 100, ix: 2 },
      },
      ao: 0,
      parent: 1,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "el",
              d: 1,
              s: { a: 0, k: [28, 28], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 2 },
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.8588235294117647, 0.7725490196078432, 0.9647058823529412],
                ix: 2,
              },
              o: { a: 0, k: 100, ix: 2 },
              w: { a: 0, k: 4, ix: 2 },
              lc: 2,
              lj: 2,
            },
            {
              ty: "tm",
              s: { a: 0, k: 0, ix: 2 },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 2 },
              m: 1,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 2 },
              s: { a: 0, k: [100, 100], ix: 2 },
              r: { a: 0, k: 0, ix: 2 },
              o: { a: 0, k: 100, ix: 2 },
              sk: { a: 0, k: 0, ix: 2 },
              sa: { a: 0, k: 0, ix: 2 },
            },
          ],
        },
      ],
      ip: 0,
      op: 261,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

export const DEFAULT_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface Props {
  size?: number;
  opts?: unknown;
}
export const Spinner = ({ size = 86, opts = DEFAULT_OPTIONS }: Props) => (
  <Lottie ariaRole="alert" options={opts} height={size} width={size} />
);
