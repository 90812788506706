import { useState } from "react";
import { Flex, Card } from "antd";
import { Text, Tag } from "@lavender-ai/lav-components";

import { IconFrame } from "~src/component/Atoms";
import { LavenderIcon } from "~src/component/typings";
import { Recommendation } from "~src/redux/typings";
import { selectEmailAnalysis, useAppSelector } from "~src/redux";
import { useLvIdentifier } from "~src/customHooks";

import { CategorySection } from "../../../Shared/Styled/CategorySection";
import {
  CategoryHeader,
  CoachTags,
  CoachTagsDescription,
} from "./WhatsDoneRight.styled";

export const WhatsDoneRight = () => {
  const lvIdentifier = useLvIdentifier();
  const { recommendations } = useAppSelector(selectEmailAnalysis(lvIdentifier));
  const [selected, setSelected] = useState<Recommendation>();
  const filtered = (recommendations || []).filter((r) => r.effectonscore < 0.1);

  return (
    !!filtered.length && (
      <CategorySection
        className={`lv-email-category category-success`}
        vertical
        style={{ width: "100%", marginBottom: "12px" }}
      >
        <Card className="lv-card">
          <CategoryHeader>
            <IconFrame color="pear" variant={LavenderIcon.IconCheck} />
            <Flex vertical>
              <Text color="black" size="subtext4">
                What you&apos;ve done right
              </Text>
            </Flex>
          </CategoryHeader>
          <CoachTags>
            {filtered.map((f) => (
              <Tag
                onClick={() => {
                  setSelected(f);
                }}
                color={selected?.id === f.id ? "dark" : "gray"}
                variant="base"
                key={`f-${f.id}`}
              >
                {f.rectype}
              </Tag>
            ))}
          </CoachTags>
          {selected && (
            <CoachTagsDescription vertical={true}>
              <Text
                size="body2"
                style={{
                  color: "var(--color-neutral-purple-900, #140C22)",
                }}
                dangerouslySetInnerHTML={{
                  __html: "🎉 &nbsp;" + selected?.recommendation,
                }}
              />
            </CoachTagsDescription>
          )}
        </Card>
      </CategorySection>
    )
  );
};
