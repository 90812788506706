import { Flex, Space } from "antd";
import { styled } from "styled-components";

import { Icon, Text } from "@lavender-ai/lav-components";

import { LavenderLogo } from "~src/component/Atoms/LavenderLogo";
import { LavenderIcon } from "~src/component/typings";

const StyledTopBar = styled(Flex)`
  padding: 12px 16px 12px 16px;
  border: solid 0 var(--lv-border-color);
  border-bottom-width: 1px;
  background-color: var(--lv-panel-background-color);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  width: 100%;
  box-sizing: border-box;
`;

interface TopBarProps {
  title: string;
  onClose: (e: any) => void;
}

export const TopBar: React.FC<TopBarProps> = ({
  onClose,
  title,
}: TopBarProps) => {
  return (
    <StyledTopBar
      vertical={false}
      justify="space-between"
      align="flex-start"
      className="lv-email-summary-analytics-header"
    >
      <Space size={8}>
        <LavenderLogo size={24} />
        <Text color="black" size="subtext2">
          {title}
        </Text>
      </Space>
      <span onClick={onClose}>
        <Icon color="lavender" size="small" variant={LavenderIcon.IconX} />
      </span>
    </StyledTopBar>
  );
};
