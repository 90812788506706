import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "./store";
import {
  ResetLastJsonMessagePayload,
  SetLastJsonMessagePayload,
  StartMyEmailState,
} from "./typings";

export const INIT_START_MY_EMAIL_STATE: StartMyEmailState = {
  connections: {},
};

// TODO: separate websocket handling for value props vs. start my email
export const startMyEmailSlice = createSlice({
  name: "startMyEmail",
  initialState: INIT_START_MY_EMAIL_STATE,
  reducers: {
    setLastStartMyEmailJsonMessage: (
      state,
      action: PayloadAction<SetLastJsonMessagePayload>
    ) => {
      const { sessionId, message } = action.payload;
      state.connections[sessionId] = message;
    },
    resetLastStartMyEmailJsonMessage: (
      state,
      action: PayloadAction<ResetLastJsonMessagePayload>
    ) => {
      const { sessionId } = action.payload;
      state.connections[sessionId] = undefined;
    },
  },
});

export const startMyEmailActions = startMyEmailSlice.actions;
export const startMyEmailReducer = startMyEmailSlice.reducer;

const selectConnections = (state: RootState) => state.startMyEmail.connections;
const selectSessionId = (_state: RootState, sessionId: lvIdentifier) =>
  sessionId;

export const selectLastStartMyEmailMessageBySessionId = createSelector(
  [selectConnections, selectSessionId],
  (connections, sessionId) => connections[sessionId]
);
