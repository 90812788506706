import { Flex } from "antd";
import { useCallback } from "react";
import { styled } from "styled-components";

import { Avatar, Icon, Text } from "@lavender-ai/lav-components";

import {
  CustomSkeleton,
  CustomSkeletonVariant,
} from "~src/component/Utilities/Loading";
import {
  PanelBlock,
  PanelBlockVariant,
} from "~src/layout/MainPanel/PanelBlock";
import {
  segmentEvent,
  SegmentEventNames,
  SegmentEventLocations,
} from "~src/utils";
import { UserDataAvatar, UserDataEntity } from "~src/component/Molecules";

import {
  CLEARBIT_LOGO_URL,
  INIT_PERSONALIZATION_TAB_CONTEXT,
} from "~src/constants";
import { usePanelTabContext } from "~src/contexts";
import { useLvIdentifier, useStartMyEmail } from "~src/customHooks";
import { mainPanelAction, useAppDispatch } from "~src/redux";
import { SME_SUBHEADER, SME_CAPTION, SME_SUB_TAB } from "~src/strings";
import { SecondaryText, SecondaryIconButton } from "~src/component/Styled";
import { PersonalizationTab, LavenderIcon } from "~src/component/typings";
import { StyledCompanyBlockHeader } from "../../../Personalization/Views/Feed/Company";

const StyledSubtext3Skeleton = styled(CustomSkeleton)`
  .ant-skeleton-content {
    .ant-skeleton-title {
      height: 11px;
    }
  }
`;

const FullWidthFlex = styled(Flex)`
  width: 100%;

  &:hover {
    ${SecondaryIconButton} {
      .lav-components-icon-color-dark.lav-components-icon {
        stroke: var(--color-neutral-purple-900);
      }
    }
  }
`;

interface Props {
  displayName: string;
}

export const PersonalizationAssistant = ({ displayName }: Props) => {
  const lvIdentifier = useLvIdentifier();
  const dispatch = useAppDispatch();
  const { setPanelTabContext } = usePanelTabContext();
  const {
    currentPersonalization: {
      company: { domain, logo, name: companyName },
      profile: { avatar, email, name, loading },
    },
  } = useStartMyEmail();

  const goTo = useCallback(
    (tab: PersonalizationTab) => {
      if (loading) return;
      setPanelTabContext(INIT_PERSONALIZATION_TAB_CONTEXT[tab]);
      dispatch(mainPanelAction.setView({ lvIdentifier, view: "profile" }));
    },
    [dispatch, loading, lvIdentifier, setPanelTabContext]
  );

  return (
    <PanelBlock
      className="lv-icebreakers-personalization-assistant"
      variant={PanelBlockVariant.list}
      loading
    >
      <Flex
        vertical
        gap={12}
        style={{ padding: "20px 24px 12px", textAlign: "left" }}
      >
        <StyledSubtext3Skeleton
          variant={CustomSkeletonVariant.title}
          loading={loading}
        >
          <Text color="dark" size="subtext3">
            {SME_SUBHEADER.PERSONALIZATION_ASSISTANT}
          </Text>
        </StyledSubtext3Skeleton>
        <CustomSkeleton
          variant={CustomSkeletonVariant.subTitle}
          loading={loading}
        >
          <SecondaryText $light size="body1">
            {SME_CAPTION.LEARN_MORE(displayName, companyName)}
          </SecondaryText>
        </CustomSkeleton>
      </Flex>
      <div className="lv-panel-list-container">
        <StyledCompanyBlockHeader
          align="center"
          gap={0}
          $hoverColor="var(--lv-panel-background-color)"
          $loading={loading}
          $size="small"
          onClick={(e) => {
            e.stopPropagation();
            goTo(PersonalizationTab.FEED);
            segmentEvent(
              SegmentEventNames.FeedClicked,
              SegmentEventLocations.StartMyEmail
            );
          }}
        >
          <CustomSkeleton
            loading={loading}
            variant={CustomSkeletonVariant.avatarTitle}
          >
            <FullWidthFlex justify="space-between" align="center">
              <Flex align="center" gap={12}>
                <Avatar
                  icon={<Icon variant={LavenderIcon.IconList} size="small" />}
                  color="neutral-100"
                  shape="circle"
                  size="small"
                />
                <Text color="dark" size="subtext2">
                  {SME_SUB_TAB.FEED}
                </Text>
              </Flex>
              <SecondaryIconButton
                size="small"
                icon={LavenderIcon.IconChevronRight}
                variant="plain"
                onClick={(e) => {
                  e.stopPropagation();
                  goTo(PersonalizationTab.FEED);
                  segmentEvent(
                    SegmentEventNames.FeedClicked,
                    SegmentEventLocations.StartMyEmail
                  );
                }}
              />
            </FullWidthFlex>
          </CustomSkeleton>
        </StyledCompanyBlockHeader>
        <StyledCompanyBlockHeader
          align="center"
          gap={0}
          $hoverColor="var(--lv-panel-background-color)"
          $loading={loading}
          $size="small"
          onClick={(e) => {
            e.stopPropagation();
            goTo(PersonalizationTab.PROFILE);
            segmentEvent(
              SegmentEventNames.ProfileClicked,
              SegmentEventLocations.StartMyEmail
            );
          }}
        >
          <CustomSkeleton
            loading={loading}
            variant={CustomSkeletonVariant.avatarTitle}
          >
            <FullWidthFlex justify="space-between" align="center">
              <Flex align="center" gap={12}>
                <UserDataAvatar
                  src={avatar}
                  size="small"
                  usePlaceholderFallback
                />
                <Text color="dark" size="subtext2">
                  {name || email}
                </Text>
              </Flex>
              <SecondaryIconButton
                size="small"
                icon={LavenderIcon.IconChevronRight}
                variant="plain"
                onClick={(e) => {
                  e.stopPropagation();
                  goTo(PersonalizationTab.PROFILE);
                  segmentEvent(
                    SegmentEventNames.ProfileClicked,
                    SegmentEventLocations.StartMyEmail
                  );
                }}
              />
            </FullWidthFlex>
          </CustomSkeleton>
        </StyledCompanyBlockHeader>
        {companyName && (
          <StyledCompanyBlockHeader
            align="center"
            gap={0}
            $hoverColor="var(--lv-panel-background-color)"
            $isLastItem
            $loading={loading}
            $size="small"
            onClick={(e) => {
              e.stopPropagation();
              goTo(PersonalizationTab.COMPANY);
              segmentEvent(
                SegmentEventNames.CompanyClicked,
                SegmentEventLocations.StartMyEmail
              );
            }}
          >
            <CustomSkeleton
              loading={loading}
              variant={CustomSkeletonVariant.avatarTitle}
            >
              <FullWidthFlex justify="space-between" align="center">
                <Flex align="center" gap={12}>
                  <UserDataAvatar
                    src={logo}
                    size="small"
                    fallbackSrc={`${CLEARBIT_LOGO_URL}${domain}`}
                    entity={UserDataEntity.company}
                    usePlaceholderFallback
                  />
                  <Text color="dark" size="subtext2">
                    {companyName}
                  </Text>
                </Flex>
                <SecondaryIconButton
                  size="small"
                  icon={LavenderIcon.IconChevronRight}
                  variant="plain"
                  onClick={(e) => {
                    e.stopPropagation();
                    goTo(PersonalizationTab.COMPANY);
                    segmentEvent(
                      SegmentEventNames.CompanyClicked,
                      SegmentEventLocations.StartMyEmail
                    );
                  }}
                />
              </FullWidthFlex>
            </CustomSkeleton>
          </StyledCompanyBlockHeader>
        )}
      </div>
    </PanelBlock>
  );
};
