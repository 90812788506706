import { useCallback } from "react";

import { Button } from "@lavender-ai/lav-components";
import { LavenderIcon } from "../typings";

interface Props {
  effectOnScore: number;
  rec: string;
  onClick: (text: string) => void;
}

export const RecScoreButton = ({ effectOnScore, onClick, rec }: Props) => {
  const clickHandler = useCallback(() => {
    onClick(rec);
  }, [onClick, rec]);

  return (
    <Button
      prefix={LavenderIcon.IconPlus}
      size="small"
      variant="secondary"
      onClick={clickHandler}
    >
      {effectOnScore}
    </Button>
  );
};
