import { styled } from "styled-components";

import { StyledCompanyBlockHeader } from "~src/component/Organisms/Personalization/Views/Feed/Company";

export const StyledCartHeader = styled(StyledCompanyBlockHeader)`
  padding: 0;

  .lv-cart-header {
    padding: 16px 0;

    .lv-cart-header-secondary-text {
      margin-left: -8px;
    }
  }
`;

export const StyledStickyCartHeader = styled(StyledCompanyBlockHeader)`
  background: var(--lv-panel-background-color);
  padding: 16px 20px;
  border-bottom: 1px solid var(--color-neutral-purple-100);
  position: sticky;
  top: 0;
  width: var(--lv-expanded-panel-size);
  z-index: 1071;

  .ant-message {
    position: absolute;
  }

  .lv-cart-header-secondary-text {
    margin-left: -8px;
  }
`;
