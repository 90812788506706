import { MutationDefinition } from "@reduxjs/toolkit/query/react";

import {
  APIResponseStatus,
  ErrorResponse,
  SuccessReponse,
  UseMutationBaseQuery,
} from "./api";
import { StartMyEmailCart, StartMyEmailCartPayload } from "./startMyEmail";

export interface GetValuePropsPayload {
  chromeId: chromeId;
  email: emailAddress;
}
export type GetValuePropsResponse = SuccessReponse<{
  valueProps: CartItem[];
}>;

export interface CartItem {
  id: string;
  description?: string;
  formatted_title?: string;
  type: string;
  title: string;
  trigger: string;
  user_value?: string;
  value?: string;
}

export interface UpsertValuePropsPayload extends GetValuePropsPayload {
  cart: CartItem[];
}
export type UpsertValuePropsResponse = ErrorResponse | undefined;

export interface GenerateValuePropsPayload extends GetValuePropsPayload {
  cart: StartMyEmailCartPayload;
  signal?: AbortSignal;
  sessionId: lvIdentifier;
  stream?: boolean;
}

export type UseGenerateValuePropsMutationDefinition = MutationDefinition<
  GenerateValuePropsPayload,
  UseMutationBaseQuery,
  never,
  ValuePropsResponse,
  "api"
>;
export type ValuePropsResponse = SuccessReponse<{
  generated: StartMyEmailCart;
}>;

export interface ValuePropWSResponse {
  generated: StartMyEmailCart;
  status: APIResponseStatus;
  type: "value_prop_generation" | "value_prop_analysis";
}
