import { Button, MenuSearch } from "@lavender-ai/lav-components";

import { useGlobalSearchContext } from "~src/contexts";

interface Props {
  closeModal: () => void;
}

export const MenuSearchWithToggleCloseButton = ({ closeModal }: Props) => {
  const { searchTerm, handleSearch } = useGlobalSearchContext();

  return (
    <>
      <MenuSearch
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
        style={{ marginTop: -8, paddingTop: 8, borderRadius: "12px 12px 0 0" }}
        autoFocus={true}
      />
      <Button
        icon="IconX"
        variant="secondary"
        onClick={closeModal}
        style={{
          position: "absolute",
          right: 8,
          top: 4,
          zIndex: 2,
        }}
      />
    </>
  );
};
