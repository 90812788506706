import {
  createContext,
  ReactNode,
  MutableRefObject,
  useContext,
  useRef,
} from "react";
import { SlidingPanel } from "../layout/MainPanel/SlidingPanel";

interface Props {
  children: ReactNode;
}

const ScrollContext =
  createContext<MutableRefObject<HTMLDivElement | null> | null>(null);

export const ScrollContextWrapper = ({ children }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <SlidingPanel ref={ref}>
      <ScrollContext.Provider value={ref}>{children}</ScrollContext.Provider>
    </SlidingPanel>
  );
};

ScrollContextWrapper.displayName = "ScrollContextWrapper";

export const useScrollContext = () => useContext(ScrollContext);
