import type { EmailEvaluation } from "../../api/typings";

export type VerificationState = Record<
  emailAddress,
  EmailVerificationData | undefined
>;
export interface EmailVerificationData {
  seen?: boolean;
  loading: boolean;
  evaluation?: EmailEvaluation;
}
