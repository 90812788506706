import { AnalyzeChatSuccessResponse } from "~src/api/typings";
import { ChatMessage } from "~src/api/typings/askChat";

import { Recommendations } from "./emailAnalysis";
import { NetworkStatus } from "./networkStatus";

export interface Chat {
  chatThreadId?: string;
  messages: ChatMessage[];
  status: NetworkStatus;
  error?: string;
  score?: number;
  recs?: Recommendations;
}

export interface SetLoadingChatArgs {
  lvIdentifier: lvIdentifier;
}

export enum ChatTool {
  GoogleWebSearch = "google_web_search_tool",
}

export interface InitializeChatArgs {
  lvIdentifier: lvIdentifier;
  question: string;
}

export interface InitializeChatData {
  messages: ChatMessage[];
  chatThreadId: string;
}

export interface RunChatArgs {
  content?: string;
  recs?: Recommendations;
  lvIdentifier: lvIdentifier;
  autoSuggestion?: boolean;
  question: string;
  location?: string;
  timezone?: string;
  searchWeb?: boolean;
}

export type RunChatData = Required<
  Pick<AnalyzeChatSuccessResponse, "recs" | "score">
>;

export interface ChatUser {
  avatar: string;
  displayName: string;
  email: string;
  location: string;
  settings: {
    [ChatTool.GoogleWebSearch]: boolean;
  };
  timezone: string;
}
