import { store } from "~src/redux/store";
import { configActions } from "../redux";

export const isAuthenticated = (): boolean => {
  const state = store.getState();
  if (state.config.chromeId) {
    return true;
  }
  //INFO: Outlook Add-in has different way to check if user is authenticated,
  // we need to check if chromeId is stored in local storage.
  const chromeId = window.localStorage.getItem("lv-chrome-id");
  if (chromeId) {
    store.dispatch(configActions.setChromeId(chromeId));
    return true;
  }
  return false;
};
