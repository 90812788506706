import { TONE_CONVERT } from "~src/constants";
import { NetworkStatus } from "./networkStatus";

export type EmailAnalysisState = Record<lvIdentifier, EmailAnalysis>;
// Root level
export interface EmailAnalysis {
  analysis: Analysis;
  highlights: Highlights;
  recommendations: Recommendations;
  hasUpdates: boolean;
  scoringModel: "personalized" | "best practices";
  status: NetworkStatus;
  stage: EmailAnalysisStage;
  hasPersonalizedScore: boolean;
}

export enum EmailAnalysisStage {
  cold = "cold",
  followUp = "follow_up",
  reply = "reply",
}
export interface RequiredAnalysisData {
  system: SystemData;
  writing: WritingData;
}
export interface SystemData {
  chrome_id: chromeId;
  email_id: lvIdentifier;
  dark_mode: string;
  horizontal: boolean;
  mailclient: string;
  text_editor: boolean; // this is really a flag to check you are on outreach website

  template_flag: boolean;
  grammar_nw: boolean;
}
export interface WritingData {
  snippet_flag: boolean;
  reply_flag: boolean;
  author_email: string;
  subject: string;
  stage_overwrite: string;
  scoring_model_overwrite: string;
  body: string;
  recipients: string;
  author_email_overwrite: string;
  writing_started: number;
  writing_time: number;
  emails_before: string;
  coach_used: boolean;
  chat_gpt_used: boolean;
  gifs_used: boolean;
  frameworks_used: boolean;
  personalization_assistant_used: boolean;
  start_my_email_used: boolean;
  include_in_analytics: boolean;
}
// Analysis level
export interface Analysis {
  body_length: number;
  ceq: number; //deprecated - not used in UI
  dynamic_ceq: number;
  clarity: number;
  cliches_arr?: string[];
  deidentified_body?: string;
  first_word: string;
  formality: number;
  grade: string;
  hedge_words?: number;
  question_count?: number; // defined if there is a question
  reading_time: number;
  sentence_count: number;
  sentiment: string;
  subject: Subject;
  tones: Tones;
  tone_sentences: ToneSentences;
  top_tones: (keyof typeof TONE_CONVERT)[];
  writing_time: number;
  you_count: number;
  long_sentences: number;
  mobile_optimized?: boolean; // defined if mobile_optimized flag is passed with true
  adverbs?: number;
  i_we_you_ratio: number;
  i_count: number;
  spam_arr?: string[];
  deidentified_subject?: string;
  subject_exclamation_count?: number; // defined if there is an exclamation in subject
  body_exclamation_count?: number; // defined if there is an exclamation in body
}

interface Subject {
  has_punctuation?: boolean;
  title_case?: boolean;
  needs_work?: boolean;
  word_count: number;
}

interface Tones {
  analytical: number;
  angry: number;
  confident: number;
  friendly: number;
  happy: number;
  sad: number;
  scared: number;
  unsure: number;
}

type ToneSentences = Record<keyof Tones, number>;

// Highlights level
export interface Highlights {
  LongSentences: string[] | null;
  HardWords: null;
  LongWords: null;
  UncommonWords: null;
  SpamWords: SpamWord[] | null;
  FormalitySentences: Record<string, FormalitySentence[]>;
  ToneSentences: ToneSentence[];
  HedgeWords: string[] | null;
  Adverbs: Adverb[] | null;
  Cliches: Cliche[] | null;
}

interface Cliche {
  Text: string;
  Suggestion: string;
}

interface SpamWord {
  Text: string;
  Weight: number;
}

interface Adverb {
  Text: string;
  Index: number;
}

interface FormalitySentence {
  text: string;
  value: number;
}

export interface ToneSentence {
  text: string;
  tones: Tone[] | null;
}

interface Tone {
  score: number;
  tone_id: string;
  tone_name: string;
}

// Recommendations
export interface Recommendation {
  current_value: number;
  effectonscore: number;
  field: string;
  gpt_rec: string;
  id?: string;
  ideal_str?: string;
  ideal: number;
  linkedbtn: string;
  longer_gpt_rec?: string;
  lower_bound: number;
  prompt_name?: string;
  recommendation: string;
  rectype: string;
  text: string; // text that is highlighted. Defaults to ""
  upper_bound: number;
}

// Recommendations type
export type Recommendations = Recommendation[] | null;
