import { SorterSegment } from "../api/typings";
import type {
  DiscAssessmentResponse,
  DiscAssessmentCategorizedResponse,
  OceanAssessmentCategorizedResponse,
  PersonalityAnalysisResponse,
} from "../api/typings";
import {
  DiscAssessmentCategorizedData,
  OceanAssessmentCategorizedData,
} from "../redux/typings";
import { capitalizeFirstLetter } from "./strings";

export const getSorterSegmentRec = (segment: SorterSegment) => {
  switch (segment) {
    case SorterSegment.mover:
      return "Decision making likely starts with the process";
    case SorterSegment.motivator:
      return "Decision making likely starts with the big picture";
    case SorterSegment.collaborator:
      return "Decision making likely starts with other people";
    case SorterSegment.thinker:
      return "Decision making starts in the details";
    default:
      return "";
  }
};

export const getDiscAssessmentStr = ({
  dominance,
  influence,
  steadiness,
  calculativeness,
}: DiscAssessmentCategorizedData) => {
  return `${dominance}, ${influence}, ${steadiness}, ${calculativeness}`;
};

export const getOceanAssessmentStr = ({
  openness,
  conscientiousness,
  extraversion,
  agreeableness,
  emotional_stability,
}: OceanAssessmentCategorizedData) => {
  return `${openness}, ${conscientiousness}, ${extraversion}, ${agreeableness}, ${emotional_stability}`;
};

const extractDiscAssessmentStr = (
  assessment: DiscAssessmentResponse,
  key: string
): string => {
  const { level } = assessment;
  return `${capitalizeFirstLetter(level)} ${capitalizeFirstLetter(key)}`;
};

const extractDiscAssessment = (disc: DiscAssessmentCategorizedResponse) => {
  return {
    calculativeness: extractDiscAssessmentStr(
      disc.calculativeness,
      "calculativeness"
    ),
    dominance: extractDiscAssessmentStr(disc.dominance, "dominance"),
    influence: extractDiscAssessmentStr(disc.influence, "influence"),
    steadiness: extractDiscAssessmentStr(disc.steadiness, "steadiness"),
  };
};

const extractOceanAssessment = (ocean: OceanAssessmentCategorizedResponse) => {
  return {
    agreeableness: ocean.agreeableness.level,
    openness: ocean.openness.level,
    extraversion: ocean.extraversion.level,
    emotional_stability: ocean.emotional_stability.level,
    conscientiousness: ocean.conscientiousness.level,
  };
};

export const extractPersonalityAnalysis = (
  analysis: PersonalityAnalysisResponse
) => {
  return {
    disc_assessment: extractDiscAssessment(analysis.disc_assessment),
    ocean_assessment: extractOceanAssessment(analysis.ocean_assessment),
  };
};
