import { FC, useState } from "react";
import { styled } from "styled-components";

import { Search, Icon, Tabs } from "@lavender-ai/lav-components";

import { SubTabListContainer } from "../../Shared";
import {
  SalesforceLead,
  SalesforceContact,
  SalesforceCompany,
  SalesforceOpportunity,
} from "~src/api/typings";
import { PERSONALIZATION_HEADER } from "~src/strings";
import { Section } from "./components";
import { shouldShow, fieldExists, dataExists } from "./utils";
import { Empty } from "~src/component/Molecules/Empty/Empty";
import { useCRM, usePersonalization } from "~src/customHooks";

interface Props {
  lead: SalesforceLead | undefined;
  contact: SalesforceContact | undefined;
  company: SalesforceCompany | undefined;
  opportunity: SalesforceOpportunity | undefined;
  searchText?: string;
}

const Details: FC<Props> = ({
  lead,
  contact,
  company,
  opportunity,
  searchText,
}) => {
  const searchLead = shouldShow(JSON.stringify(lead), searchText);
  const searchContact = shouldShow(JSON.stringify(contact), searchText);
  const searchCompany = shouldShow(JSON.stringify(company), searchText);
  const searchOpportunity = shouldShow(JSON.stringify(opportunity), searchText);

  const foundSomething = searchLead || searchContact || searchCompany;

  return !foundSomething ? (
    <Empty title="Nothing found for that search term." />
  ) : (
    <SectionDetails>
      {lead !== undefined && fieldExists(lead) && searchLead && (
        <Section
          icon={<Icon variant="IconUser" size="extra-small" />}
          title="Lead"
          items={[
            {
              title: "Name",
              description: lead?.name || "",
              visible: shouldShow(lead?.name, searchText),
            },
            {
              title: "Email",
              description: lead?.email || "",
              visible: shouldShow(lead?.email, searchText),
            },
            {
              title: "Phone" || "",
              description: lead?.phone || "",
              visible: shouldShow(lead?.phone, searchText),
            },
            {
              title: "Stage",
              description: lead?.stage || "",
              visible: shouldShow(lead?.stage, searchText),
            },
          ]}
        />
      )}
      {contact !== undefined && fieldExists(contact) && searchContact && (
        <Section
          icon={<Icon variant="IconUser" size="extra-small" />}
          title="Contact"
          items={[
            {
              title: "Name",
              description: contact?.name || "",
              visible: shouldShow(contact?.name, searchText),
            },
            {
              title: "Email",
              description: contact?.email || "",
              visible: shouldShow(contact?.email, searchText),
            },
            {
              title: "Phone",
              description: contact?.phone || "",
              visible: shouldShow(contact?.phone, searchText),
            },
          ]}
        />
      )}
      {company !== undefined && fieldExists(company) && searchCompany && (
        <Section
          icon={<Icon variant="IconBuilding" size="extra-small" />}
          title="Account"
          items={[
            {
              title: "Name",
              description: company?.name || "",
              visible: shouldShow(company?.name, searchText),
            },
            {
              title: "Domain",
              description: company?.domain || "",
              visible: shouldShow(company?.domain, searchText),
            },
            {
              title: "Phone",
              description: company?.phone || "",
              visible: shouldShow(company?.phone, searchText),
            },
            {
              title: "Industry",
              description: company?.industry || "",
              visible: shouldShow(company?.industry, searchText),
            },
          ]}
        />
      )}
      {opportunity !== undefined &&
        fieldExists(opportunity) &&
        searchOpportunity && (
          <Section
            icon={<Icon variant="IconCash" size="extra-small" />}
            title="Opportunity"
            items={[
              {
                title: "Name",
                description: opportunity?.Name + "" || "",
                visible: shouldShow(opportunity?.Name + "", searchText),
              },
              {
                title: "Amount",
                description: opportunity?.Amount + "" || "",
                visible: shouldShow(opportunity?.Amount + "", searchText),
              },
              {
                title: "Close Date",
                description: opportunity?.CloseDate || "",
                visible: shouldShow(opportunity?.CloseDate, searchText),
              },
              {
                title: "Stage",
                description: opportunity?.StageName || "",
                visible: shouldShow(opportunity?.StageName, searchText),
              },
              {
                title: "Next Step",
                description: opportunity?.NextStep || "",
                visible: shouldShow(opportunity?.NextStep, searchText),
              },
            ]}
          />
        )}
      {/*TODO: add opps*/}
    </SectionDetails>
  );
};

export const SalesforceCRM: FC = () => {
  const [searchText, setSearchText] = useState("");
  const {
    currentPersonalization: {
      profile: { email },
    },
  } = usePersonalization();
  const { crms } = useCRM();
  const { salesforce_data } = crms[email] || {};

  const sfDataExists = dataExists(salesforce_data);

  return (
    <CRMContainer>
      <SubTabListContainer count={1} header={PERSONALIZATION_HEADER.SALESFORCE}>
        {!sfDataExists ? (
          <Empty title="We couldn’t find any Salesforce Data." />
        ) : (
          <>
            <Search
              placeholder="Search data..."
              size="medium"
              state="initial"
              style={{ marginBottom: 20 }}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <SectionTabs
              items={[
                {
                  children: (
                    <Details
                      lead={salesforce_data?.lead}
                      contact={salesforce_data?.contact}
                      company={salesforce_data?.company}
                      opportunity={salesforce_data?.opportunity}
                      searchText={searchText}
                    />
                  ),
                  key: "1",
                  label: "Details",
                },
              ]}
              size="small"
              variant="underlined"
            />
          </>
        )}
      </SubTabListContainer>
      {/*TODO: add notes tabs*/}
    </CRMContainer>
  );
};

const CRMContainer = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;

const SectionTabs = styled(Tabs)`
  padding: 0 16px;
  width: 100%;
  > div {
    border-bottom: 1px solid var(--color-neutral-purple-100, #e9ebf2);
    &:last-child {
      border-bottom: 0;
    }
  }
`;

const SectionDetails = styled.div``;
