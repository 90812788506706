import { createContext, useContext } from "react";

interface DomNodes {
  panelParent: HTMLElement | null;
  emailBody: HTMLElement | null;
  subject: HTMLElement | null;
}
// Create a contexttyps
export const DomNodesContext = createContext<DomNodes>({
  panelParent: null,
  emailBody: null,
  subject: null,
});

// Create a custom hook for easy access to lvIdentifier
export const useDomNodes = () => {
  return useContext(DomNodesContext);
};
