import { ICRMContext, useCRMContext } from "~src/contexts";
import { RecipientCRMMap } from "~src/redux/typings";
import { useAppSelector } from "~src/redux";

interface IUseCRM extends Omit<ICRMContext, "currentCRM"> {
  crms: RecipientCRMMap;
}

export const useCRM = (): IUseCRM => {
  const { crms } = useAppSelector(({ feed }) => feed);

  const { resetCRMContext, setCRMContext } = useCRMContext();

  return {
    crms,
    resetCRMContext,
    setCRMContext,
  };
};
