import { IcebreakersData } from "~src/redux/typings";
import { SME_LOADING_STEPS } from "~src/strings";

export const DEFAULT_CUSTOM = {
  categories: {
    custom: { main: "" },
  },
};
// Icebreakers
export const DEFAULT_ICEBREAKERS_DATA: IcebreakersData = {
  alert: "",
  caption: "",
  cta: "",
  header: "",
  rankedFeed: {},
};

//Timeout after 15 seconds to avoid rapid clicking but in case start my email got stuck in a pending state
export const GENERATING_EMAILS_BACK_DISABLED_TIMEOUT = 15000;

// Loading States - 25s total + buffer 3s of polling (see Overlay.tsx for more info)

// Spinner - 4s total
export const SPINNER_TIMEOUTS = {
  1: 1000,
  2: 3000,
};

// Stepper - 19s total
export const STEPPER_TIMEOUTS = {
  0: 2000, // Stepper displayed with no steps started yet and wizard is frowning
  1: 3000,
  2: 3000,
  3: 3000,
  4: 3000,
  5: 3000, // Stepper displayed with all steps done and wizard is celebratory
  6: 2000, // Fade out stepper
};
export const STEPS = [
  SME_LOADING_STEPS.IDENTIFYING_COMPANY_GOALS,
  SME_LOADING_STEPS.VALIDATING,
  SME_LOADING_STEPS.IDENTIFYING_ROADBLOCKS,
  SME_LOADING_STEPS.CONNECTING,
];
export const FINISHED_TIMEOUT = 2000;
