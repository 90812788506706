import { store } from "~src/redux/store";
import { configActions, localStorageActions } from "../redux";
import { UserNewMailBox, UserFound } from "../constants";
import { removeExtensionElements, isValidEmail } from "../utils";

export const authenticateEmailClientAccount = () => {
  const state = store.getState();
  const { message, chrome_id } = state.config.mailBox;
  const ignoreList = state.localStorage.ignoreList;
  const email = state.config.emailAddress;

  if (!isValidEmail(email)) {
    return;
  }

  switch (message) {
    // INFO: Valid user, this status is for successful authentication
    case UserNewMailBox:
      store.dispatch(configActions.setAccountStatus(UserNewMailBox));
      console.debug("NEW MAILBOX DETECTED", email);
      // INFO: If email is on the ignore list then remove the extension and stop the main polling
      // this use case is only when the user has a valid chromeId from a different user email address profile
      if (ignoreList.includes(email)) {
        // 1. remove extension elements
        removeExtensionElements();
        // 2. stop main polling
        store.dispatch(configActions.clearMainPollingInterval());
      }
      break;
    // INFO: this use case is when the user has unignore themselves from the dashboard
    case UserFound:
      store.dispatch(configActions.setChromeId(chrome_id));
      store.dispatch(configActions.setAccountStatus(UserFound));
      console.debug("User found");
      // INFO: chrome-ext/lavender/utilities/MailboxEmail.js function CheckForMailboxUser line 227
      if (ignoreList.includes(email)) {
        store.dispatch(localStorageActions.unIgnoreItem(email));
      }
      break;
    default:
      break;
  }
};
