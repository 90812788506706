import { store } from "~src/redux/store";
import {
  addStyleTag,
  addIframeStyleTag,
  injectCustomLinkTags,
  injectCustomIframeLinkTags,
} from "./addStyleTag";

export const injectCustomCSS = (): void => {
  const state = store.getState();
  const platform_css = state.config.settings?.platform_css;
  const lavender_css = state.config.settings?.lavender_css;
  const component_css = state.config.settings?.component_css;
  const emailThreads_css = state.config.settings?.emailThreads_css;
  const linkTags = state.config.settings?.linkTags;

  if (platform_css) {
    addStyleTag(platform_css, "platform_css");
  }
  if (lavender_css) {
    addStyleTag(lavender_css, "lavender_css");
  }
  if (component_css) {
    addStyleTag(component_css, "component_css");
  }
  if (emailThreads_css) {
    addStyleTag(emailThreads_css, "emailThread_css");
  }
  if (linkTags) {
    injectCustomLinkTags(linkTags);
  }
};

export const injectIframeCSS = (iframeSelector: string): void => {
  const state = store.getState();
  const platform_css = state.config.settings?.platform_css;
  const lavender_css = state.config.settings?.lavender_css;
  const component_css = state.config.settings?.component_css;
  const emailThreads_css = state.config.settings?.emailThreads_css;
  const linkTags = state.config.settings?.linkTags;
  if (platform_css) {
    addIframeStyleTag("platform_css", iframeSelector, platform_css);
  }
  if (lavender_css) {
    addIframeStyleTag("lavender_css", iframeSelector, lavender_css);
  }
  if (component_css) {
    addIframeStyleTag("component_css", iframeSelector, component_css);
  }
  if (emailThreads_css) {
    addIframeStyleTag("emailThreads_css", iframeSelector, emailThreads_css);
  }
  if (linkTags) {
    injectCustomIframeLinkTags(iframeSelector, linkTags);
  }
};
