import { Flex } from "antd";
import { styled } from "styled-components";

import { Animation, Text } from "@lavender-ai/lav-components";

import { CopyButton } from "~src/component/Molecules";

import { NetworkStatus } from "~src/redux/typings";

import { APIResponseStatus } from "../../../../api/typings";
import { Message } from "../../../typings";
import { StyledMessageContainer, StyledMessageTextContainer } from "../styles";

import {
  getEmailAndSubjectFromContentString,
} from "~src/utils";

const StyledCopyButton = styled(CopyButton)`
  .ant-tooltip {
    left: 0 !important;
  }
`;

interface Props extends Message {
  avatar?: React.ReactNode;
  status?: APIResponseStatus;
}

export const StreamMessage = ({ avatar, content = "|", status }: Props) => {
  const parsedContent =
    content && content !== "|"
      ? getEmailAndSubjectFromContentString(content)
      : "";
  return (
    <Flex gap="small" align="baseline">
      {avatar !== undefined && avatar !== null && <div>{avatar}</div>}
      <StyledMessageContainer>
        <Flex gap="small" vertical>
          {content === "|" ? (
            <StyledMessageTextContainer
              align="flex-start"
              justify="center"
              gap={16}
              vertical
              $status={NetworkStatus.loading}
            >
              <Animation style={{ height: "8px" }} variant="thinking" />
            </StyledMessageTextContainer>
          ) : (
            <StyledMessageTextContainer
              align="flex-start"
              gap={16}
              vertical
              $status={
                status === APIResponseStatus.error
                  ? NetworkStatus.failed
                  : NetworkStatus.success
              }
            >
              <Text
                color="dark"
                dangerouslySetInnerHTML={{
                  __html:
                    status === APIResponseStatus.streaming
                      ? parsedContent + "|"
                      : parsedContent,
                }}
                size="body2"
              />
              {status === APIResponseStatus.success && (
                <StyledCopyButton copyText={content} />
              )}
            </StyledMessageTextContainer>
          )}
        </Flex>
      </StyledMessageContainer>
    </Flex>
  );
};
