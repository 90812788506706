import { createRoot } from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from "antd";
import { Menu } from "../Menu";
import { Panel } from "../Panel";
import { Login } from "../Login";
import { ConditionalSwitch } from "~src/component/Utilities";
import { LoginTeamsAccount } from "../LoginTeamsAccount";
import { persistor, store } from "~src/redux/store";
import { Provider } from "react-redux";
import {
  LvIdentifierContext,
  AuthenticationContext,
  DomNodesContext,
} from "~src/customHooks";
import {
  isAuthenticated,
  isFeaturePayWalled,
  setupTrackingPixelListener,
} from "~src/utils";
import { theme } from "~src/theme";
import { LandingPage } from "../LandingPage";
import { ComposeContainerMetadata } from "~src/redux/typings";
import {
  MobileEditorContextProvider,
  EmailCoachCarouselContextProvider,
  ProfileDataContextProvider,
  UpgradePlanModalContextProvider,
  StreakNotificationContextProvider,
} from "~src/contexts";
import { getBodyContainer } from "./getBodyContainer";
import { checkForAndRemoveDuplicatePanes } from "./checkForAndRemoveDuplicatePanes";
import { customDOMModifications } from "./customDOMModifications";
import { BoostScorePopup } from "../BoostScorePopup";

export const CreatePanel = (
  emailContainer: HTMLElement,
  composeContainerMetadata: ComposeContainerMetadata,
  lvIdentifier: lvIdentifier
) => {
  const state = store.getState();
  const {
    bodyCSSLavClass = "",
    subjectSelector = "",
    subjectCSSLavClass = "",
    addressToSelector = "",
    addressToCSSLavClass = "",
    iframeChildSelector = "",
  } = state.config.settings?.selectors ?? {};
  let emailBody;
  let subject;
  let addressTo;

  if (composeContainerMetadata.container.isIframe) {
    const content = emailContainer.querySelector(
      composeContainerMetadata.container.iframeContainerSelector ?? ""
    ) as HTMLIFrameElement;
    const iframeContents = content.contentDocument?.body as HTMLElement;
    emailBody = (
      iframeContents.querySelector(iframeChildSelector) as HTMLIFrameElement
    ).contentDocument?.body;
    subject = iframeContents.querySelector(subjectSelector);
    addressTo = iframeContents.querySelector(addressToSelector);
  } else {
    emailBody = getBodyContainer(emailContainer);
    subject = emailContainer.querySelector(subjectSelector);
    addressTo = emailContainer.querySelector(addressToSelector);
  }

  const reactApp = document.createElement("div");
  reactApp.classList.add("lv-panel-root");
  const root = createRoot(reactApp);
  window.addEventListener("unload", () => {
    root.unmount();
  });
  // INFO: handle showing Login components for email client and teams
  const userHasLogin = isAuthenticated();
  const isTeam = state.config.settings?.isTeam;
  const isPayWalled = isFeaturePayWalled("UsingCRM");

  // INFO: determine which platform the user is on to determine if the landing page should be shown
  const platform = state.config.settings?.platform || "";
  const shouldShowLandingPage =
    platform === "outlookNative" || platform === "lavenderEverywhere";
  // INFO: add the lavender css class to the email body, subject, and addressTo
  if (bodyCSSLavClass) {
    emailBody?.classList.add(bodyCSSLavClass);
  }
  if (subjectCSSLavClass) {
    subject?.classList.add(subjectCSSLavClass);
  }
  if (addressToCSSLavClass) {
    addressTo?.classList.add(addressToCSSLavClass);
  }
  // INFO: setup Pixel tracking
  const open_tracking =
    state.userAccount?.settings?.user?.settings?.open_tracking || false;
  if (open_tracking) {
    setupTrackingPixelListener(emailContainer, lvIdentifier);
  }
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DomNodesContext.Provider
          value={{
            panelParent: emailContainer,
            emailBody,
            subject: subject as HTMLElement,
          }}
        >
          <LvIdentifierContext.Provider value={lvIdentifier}>
            <AuthenticationContext.Provider value={userHasLogin}>
              <ConfigProvider theme={theme}>
                {
                  // INFO: if user is logged in or is on a teams webpage don't show the login component
                  // Fixes console errors about rending Login component when it is not needed
                }
                <ConditionalSwitch>
                  {!userHasLogin && !isTeam && <Login />}
                  {!userHasLogin && isTeam && <LoginTeamsAccount />}
                  {
                    // INFO: if user is logged, trys to run it on teams webpage (salesloft, outreach etc...) but has not paid for UsingCRM feature show login that has a message to pay for the feature
                    userHasLogin && isTeam && isPayWalled && (
                      <LoginTeamsAccount />
                    )
                  }
                </ConditionalSwitch>
                <ProfileDataContextProvider>
                  <MobileEditorContextProvider
                    composeContainerSelector={
                      composeContainerMetadata.container.containerSelector
                    }
                  >
                    <EmailCoachCarouselContextProvider>
                      <UpgradePlanModalContextProvider>
                        <StreakNotificationContextProvider>
                          {composeContainerMetadata.menu.panelOpenToRight ? (
                            <>
                              <Menu />
                              <Panel />
                            </>
                          ) : (
                            <>
                              <Panel />
                              <Menu />
                            </>
                          )}
                          {shouldShowLandingPage && userHasLogin && (
                            <LandingPage />
                          )}
                          <BoostScorePopup />
                        </StreakNotificationContextProvider>
                      </UpgradePlanModalContextProvider>
                    </EmailCoachCarouselContextProvider>
                  </MobileEditorContextProvider>
                </ProfileDataContextProvider>
              </ConfigProvider>
            </AuthenticationContext.Provider>
          </LvIdentifierContext.Provider>
        </DomNodesContext.Provider>
      </PersistGate>
    </Provider>
  );
  //INFO: Race condition, if the polling runs again and it gets double added
  if (emailContainer.querySelector(".lv-menu")) {
    return;
  }

  let menuAnchor: HTMLElement | null = null;
  if (emailContainer.matches(composeContainerMetadata?.menu?.panelParents)) {
    menuAnchor = emailContainer;
  } else {
    menuAnchor = emailContainer.querySelector(
      composeContainerMetadata?.menu?.panelParents
    );
  }
  const panelChildOrder = composeContainerMetadata?.menu?.panelChildOrder;

  customDOMModifications(
    platform,
    emailContainer,
    composeContainerMetadata,
    lvIdentifier,
    root
  );

  if (menuAnchor !== null) {
    if (panelChildOrder >= 0) {
      const targetElement = menuAnchor.children[panelChildOrder];
      if (targetElement !== null) {
        menuAnchor.insertBefore(reactApp, targetElement);
      } else {
        menuAnchor.prepend(reactApp);
      }
    } else {
      menuAnchor.appendChild(reactApp);
    }
  }
  checkForAndRemoveDuplicatePanes(
    composeContainerMetadata.container.containerCSSLavClass
  );

  //gmail replies fix. Resize triggers rebuilding.
  if (platform === "gmail") {
    window.dispatchEvent(new Event("resize"));
  }
  return root;
};
