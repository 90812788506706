import { ConfigApiResponse, EmailThreadEmailsBeforeSelectors } from "~src/redux/typings";

// Enhanced selector function to check all necessary fields
export const getEmailThreadEmailBeforeSelectors = (
  settings: ConfigApiResponse
): EmailThreadEmailsBeforeSelectors | undefined => {
  const {
    emailThreadEmailBefore,
    emailThreadInnerReplyEmail,
    emailThreadInnerReplyEmailBody,
    emailThreadInnerReplyEmailTimeSentPrefix,
    emailThreadInnerReplyEmailBodyFilterOutElements,
  } = settings.selectors;

  if (
    emailThreadEmailBefore &&
    emailThreadInnerReplyEmail &&
    emailThreadInnerReplyEmailBody &&
    emailThreadInnerReplyEmailTimeSentPrefix
  ) {
    return {
      emailThreadEmailBefore,
      emailThreadInnerReplyEmail,
      emailThreadInnerReplyEmailTimeSentPrefix,
      emailThreadInnerReplyEmailBody,
      emailThreadInnerReplyEmailBodyFilterOutElements,
    };
  }
  return undefined;
};
