import { DEFAULT_COMPANY } from "~src/constants";
import { ITweets } from "~src/redux/typings";

export const newPendingTweets = (): ITweets => ({
  company_twitter: {},
  profile_twitter: {},
  company: DEFAULT_COMPANY,
  loading: true,
});

export const newRejectedTweets = (): ITweets => ({
  company_twitter: {},
  profile_twitter: {},
  company: DEFAULT_COMPANY,
  loading: false,
});
