import useWebSocket, { Options } from "react-use-websocket";

import { useFeatureFlag, useLvIdentifier } from ".";

import { WS_URL } from "~src/constants";
import { ErrorIdentifiers, parseAndLogError } from "~src/logger";
import {
  featuresAction,
  selectChromeId,
  useAppDispatch,
  useAppSelector,
} from "~src/redux";
import { FeatureFlagEnum } from "~src/api/typings";

// Opens a websocket connection to the server in a shared context

interface IUseWebsocketConnection<T> {
  // lastJsonMessage
  // - is null when the websocket is first connected
  // - is an empty object when there is a (I think parsing) error - need to check just saw this in docs
  // - has no emails when is a StartMyEmailWSResponse and status is initiated
  lastJsonMessage: T;
}

export const useWebsocketConnection = <T>(
  filter?: Options["filter"],
  onClose?: (closeEvent: CloseEvent) => void,
  onMessage?: (data: T) => void
): IUseWebsocketConnection<T> => {
  const dispatch = useAppDispatch();
  const lvIdentifier = useLvIdentifier();
  const chromeId = useAppSelector(selectChromeId);
  const websocketsOn = useFeatureFlag(FeatureFlagEnum.websockets);
  const { lastJsonMessage } = useWebSocket<T>(
    WS_URL,
    {
      queryParams: { chrome_id: chromeId, session_id: lvIdentifier },
      filter,
      share: true,
      // A close event happens every time the backend is updated during development
      shouldReconnect: (closeEvent) => {
        console.debug("WEBSOCKET RECONNECTING", closeEvent);
        return true;
      },
      reconnectAttempts: 10,
      reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
      onReconnectStop: (numAttempted: number) => {
        console.debug("WEBSOCKET RECONNECT STOPPED", numAttempted);
        dispatch(featuresAction.setFeatureOff(FeatureFlagEnum.websockets));
      },
      onClose: (closeEvent) => {
        console.debug("WEBSOCKET CLOSED", closeEvent);
        onClose && onClose(closeEvent);
      },
      onError: (event: Event) => {
        console.debug("WEBSOCKET ERROR", event);
      },
      onOpen: (event: Event) => {
        console.debug("WEBSOCKET OPENED", event);
      },
      onMessage: (event: MessageEvent<string>) => {
        // console.debug("Received message:", event);
        try {
          const data = JSON.parse(event.data);
          onMessage ? onMessage(data) : data;
        } catch (error) {
          parseAndLogError(error, ErrorIdentifiers.WS_JSON_PARSE_ERROR, {
            eventData: event.data,
          });
          return {};
        }
      },
    },
    websocketsOn
  );

  return { lastJsonMessage };
};
