import { PersonalizationHeader } from "./PersonalizationHeader";

import { GlobalSearchContextProvider, usePanelTabContext } from "~src/contexts";
import {
  PersonalizationTab,
  type PersonalizationSubTab,
} from "../../typings/personalization";
import { CarouselContextProvider } from "./CarouselContextProvider";
import {
  PersonalizationFeed,
  PersonalizationProfile,
  PersonalizationCompany,
} from "./Views";
import { PersonalizationPanelContainer } from "./PersonalizationPanelContainer";

export const PersonalizationContent = () => {
  const { tab } = usePanelTabContext<
    PersonalizationTab,
    PersonalizationSubTab
  >();

  return (
    <PersonalizationPanelContainer align="center" vertical>
      <GlobalSearchContextProvider>
        <CarouselContextProvider>
          <PersonalizationHeader />
          {tab === PersonalizationTab.FEED && <PersonalizationFeed />}
          {tab === PersonalizationTab.PROFILE && <PersonalizationProfile />}
          {tab === PersonalizationTab.COMPANY && <PersonalizationCompany />}
        </CarouselContextProvider>
      </GlobalSearchContextProvider>
    </PersonalizationPanelContainer>
  );
};
