import { Draft, current } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { APIEndpoints } from "~src/api";
import {
  APIResponseStatus,
  RankedFeedParams,
  RankedFeedSuccessResponse,
  StartMyEmailCart,
} from "~src/api/typings";
import { REFRESH_INTERVAL } from "~src/constants";
import { ErrorIdentifiers, parseAndLogError } from "~src/logger";
import { icebreakerCount } from "~src/utils";

import { parseRankedFeed } from "./feed/helpers";
import { IcebreakersData, PersonalizationEvent } from "./typings";
import { valuePropsActions } from "./";

export const rankedFeedApiSlice = createApi({
  reducerPath: "rankedFeedApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.BACKEND_API}/api/` }),
  endpoints: (builder) => ({
    getRankedFeed: builder.query<IcebreakersData, RankedFeedParams>({
      query: ({ email, chromeId }) => ({
        url: APIEndpoints.rankedFeed,
        params: { email, chrome_id: chromeId },
      }),
      transformErrorResponse: (response, _meta, arg) => {
        parseAndLogError(response, ErrorIdentifiers.API_ERROR, {
          email: arg.email,
          request: APIEndpoints.rankedFeed,
        });
        return {
          alert: "",
          caption: "",
          cta: "",
          header: "",
        };
      },
      transformResponse: (response: RankedFeedSuccessResponse, _meta) => ({
        alert: response?.alert || "",
        caption: response?.caption || "",
        cta: response?.cta || "",
        header: response?.header || "",
        rankedFeed:
          response?.rankedIcebreakersFeed !== undefined
            ? parseRankedFeed(response.rankedIcebreakersFeed)
            : undefined,
      }),
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, dispatch, updateCachedData }
      ) {
        const {
          data: { rankedFeed },
        } = await cacheDataLoaded;
        if (icebreakerCount(rankedFeed) === 0) {
          return;
        }
        const categories: StartMyEmailCart = {};
        const userCategories: StartMyEmailCart = {};

        updateCachedData((draft) => {
          if (draft.rankedFeed === undefined) {
            return;
          }

          Object.keys(draft.rankedFeed).forEach((type) => {
            if (categories[type] === undefined) {
              categories[type] = {};
            }
            if (userCategories[type] === undefined) {
              userCategories[type] = {};
            }

            draft.rankedFeed![type].items.forEach(
              (item: Draft<PersonalizationEvent>) => {
                const unproxyItem = current(item);
                const valueProp = unproxyItem.valueProp;

                if (valueProp) {
                  const valuePropWithId = { ...valueProp, id: unproxyItem.id };

                  categories[type][unproxyItem.id] = valuePropWithId;
                  userCategories[type][unproxyItem.id] = valuePropWithId;
                  item.value = valueProp.value || "";
                  item.userValue = valueProp.userValue || "";
                }
              }
            );
          });
        });
        dispatch(
          valuePropsActions.setCurrentValueProps({
            generated: categories,
            status: APIResponseStatus.success,
            type: "value_prop_generation",
            email: arg.email,
          })
        );
        dispatch(
          valuePropsActions.setCurrentUserValueProps({
            generated: userCategories,
            status: APIResponseStatus.success,
            type: "value_prop_generation",
            email: arg.email,
          })
        );
      },
      keepUnusedDataFor: REFRESH_INTERVAL.FEED,
    }),
  }),
});

export const {
  endpoints: { getRankedFeed },
  reducer: rankedFeedApiReducer,
  useGetRankedFeedQuery,
} = rankedFeedApiSlice;
