import { store, configActions, fetchCRMUser } from "../redux";
import {
  checkExistingEmailClientAccount,
  detectEmailAddress,
} from "~src/utils/index";
import { checkForLavenderAnywhereUser } from "~src/utils/checkForLavenderAnywhereUser";
import { FRONTEND_URLS } from "~src/constants";

const API_FLAG = `[id^='lv-api-key']`;
const STORE_API_KEY = `lv-chrome-id`;

export const getChromeId = async (
  userExperience: ExtensionExperience | undefined,
  platform: string | undefined
): Promise<chromeId | undefined> => {
  let chromeIds: string | undefined;
  // INFO: if dashboard, always try to load any new chromeId
  if (window.location.origin === FRONTEND_URLS.dashboard) {
    chromeIds = initializeChromeId();
  } else {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const chromeId = urlParams.get("chrome_id");
      if (chromeId) {
        return chromeId;
      }
    }
    //INFO: if you are on chrome browser check the chrome storage for the chromeId
    if (chrome?.storage?.local?.get !== undefined) {
      const obj: Record<string, string> | undefined =
        await chrome.storage?.local?.get(STORE_API_KEY);
      chromeIds = obj?.[STORE_API_KEY] ? obj[STORE_API_KEY] : "";
    }
    if (!chromeIds) {
      // INFO: you are on Outlook Native via Safari or Edge
      chromeIds = localStorage.getItem(STORE_API_KEY) || undefined;
    }
    if (!chromeIds) {
      chromeIds = initializeChromeId();
    }
  }

  // Check list of chromeIds to get chromeId for mailbox
  switch (userExperience) {
    case "crmTeams": {
      if (!platform) return;
      await store
        .dispatch(fetchCRMUser({ platform, chromeId: chromeIds }))
        .unwrap();
      const state = store.getState();
      const { chrome_id } = state.config.crmUser;
      return chrome_id;
    }
    case "mailClient": {
      let userEmailAddress = "";
      for (let i = 0; i < 50; i++) {
        // give up after 25 seconds
        userEmailAddress = detectEmailAddress(platform);
        if (userEmailAddress) {
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
      store.dispatch(configActions.setEmailAddress(userEmailAddress));
      // CODE QUALITY: this is duplicated from: src/mainPanel.ts:46
      // INFO: This is where we set chrome id in the store.
      // It's based on the user's email address or "CRM User"
      // INFO: Outlook Native has a POP UP SSO for login so we don't need to check this if user is on outlookNative
      if (
        window.location.origin !== process.env.OUTLOOK_URL &&
        userEmailAddress
      ) {
        await checkExistingEmailClientAccount(userEmailAddress, chromeIds);
        return store.getState().config.chromeId;
      }
      return chromeIds;
    }
    case "lavenderAnywhere":
    case "lavenderAnywhereIframe":
      // check if any of the chrome ids have a user with LA setting on
      return await checkForLavenderAnywhereUser(chromeIds);
    default:
      return chromeIds;
  }
};

// INFO when a user first install the extension we need to grab the Id
export const initializeChromeId = (): string => {
  if (!window.top) {
    return "";
  }
  if (window.location.origin !== FRONTEND_URLS.outlook) {
    return waitForElement();
  }
  // INFO: New Outlook Native have some very touchy security features that prevent us from accessing the top window/document object.
  // even if we do a try catch it will throw an error for even trying to access the top window/document.
  return waitForElementForOutlook();
};

const waitForElement = () => {
  const elementId =
    window?.top?.document.querySelector(API_FLAG)?.textContent || "";
  // only save the chrome ID if it comes from the matching environment's dashboard
  // (don't overwrite a prod key when visiting localhost or vice versa)
  if (elementId && window.origin === FRONTEND_URLS.dashboard) {
    // INFO: if element is found save and return the element
    saveChromeId(elementId)
      .then(() => {
        console.debug("chromeId saved");
      })
      .catch(() => {
        console.error("Error: chromeId was not saved");
      });
    document.body.appendChild(
      Object.assign(document.createElement("div"), {
        id: "lv-api-key-set-flag",
      })
    );
    return elementId;
  } else if (window.origin === FRONTEND_URLS.dashboard) {
    // INFO: if dashboard has not finished loading wait for it to load
    const waitInterval = setInterval(() => {
      const elementId =
        window?.top?.document.querySelector(API_FLAG)?.textContent || "";
      if (elementId) {
        clearInterval(waitInterval);
        saveChromeId(elementId)
          .then(() => {
            console.debug("chromeId saved");
          })
          .catch(() => {
            console.error("Error: chromeId was not saved");
          });
      }
    }, 100);
  }
  return "";
};

// INFO: non dry code due to the fact that we can't access the top window/document object with new Outlook Native UI due to security features
const waitForElementForOutlook = (): string => {
  const elementId = document.querySelector(API_FLAG)?.textContent || "";
  // INFO: if element is found return the element
  if (elementId) {
    document.body.appendChild(
      Object.assign(document.createElement("div"), {
        id: "lv-api-key-set-flag",
      })
    );
    return elementId;
  } else {
    // INFO: if dashboard has not finished loading wait for it to load
    const waitInterval = setInterval(() => {
      const elementId = document.querySelector(API_FLAG)?.textContent || "";
      if (elementId) {
        clearInterval(waitInterval);
        saveChromeId(elementId)
          .then(() => {
            console.debug("chromeId saved");
          })
          .catch(() => {
            console.error("Error: chromeId was not saved");
          });
      }
    }, 100);
  }
  return "";
};

const saveChromeId = async (elementId: string) => {
  if (elementId.length > 10) {
    if (chrome !== undefined) {
      await chrome.storage?.local?.set({ [STORE_API_KEY]: elementId });
    } else {
      localStorage.setItem(STORE_API_KEY, elementId);
    }
    store.dispatch(configActions.setChromeId(elementId));
    document.body.appendChild(
      Object.assign(document.createElement("div"), {
        id: "lv-api-key-set-flag",
      })
    );
  }
};
