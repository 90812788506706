import {
  APIResponseStatus,
  CartItem,
  StartMyEmailCart,
  StartMyEmailCartItem,
  StartMyEmailCartItemId,
  StartMyEmailCartPayload,
  StartMyEmailData,
  StartMyEmailSection,
} from "~src/api/typings";
import { PersonalizationEvent, RankedFeedSectionMap } from "~src/redux/typings";
import { isObject } from "~src/typeGuards";

export const getGenerateValuePropsPayload = (
  cart: RankedFeedSectionMap,
  categories?: StartMyEmailCart
): StartMyEmailCartPayload => {
  const payload: StartMyEmailCartPayload = { sections: {} };
  Object.keys(cart).forEach((type) => {
    const items = cart[type].items;
    if (items.length) {
      payload.sections[type] = mapItemsToPayload(items, type, categories);
    }
  });
  return payload;
};

export const getUpdatedStartMyEmailCart = (
  cart: RankedFeedSectionMap,
  categories: StartMyEmailCart,
  isUserValuePropGenerationOn: boolean
): StartMyEmailCart => {
  const smeCart = {};
  Object.keys(cart).forEach((type) => {
    const items = cart[type].items;
    if (items.length) {
      const section = items.reduce<StartMyEmailSection>((acc, item) => {
        const trigger = item.triggers.length > 0 ? item.triggers[0] : undefined;
        acc[item.id] = {
          description: item.summary ? item.summary : undefined,
          id: item.id,
          formatted_title: item.formattedTitle,
          [isUserValuePropGenerationOn ? "value" : "userValue"]:
            categories[type]?.[trigger?.name || ""][item.id] || "",
          title: item.title,
          trigger: trigger?.name || "",
        };
        return acc;
      }, {});
      smeCart[type] = section;
    }
  });
  return smeCart;
};

export const generatedEmailCount = (
  generatedEmailsData: StartMyEmailData | undefined
): number => {
  return generatedEmailsData?.result.emails.length || 0;
};

export const getUpsertValuePropsPayload = (
  cart: RankedFeedSectionMap,
  categories: StartMyEmailCart,
  isUserValuePropGenerationOn: boolean
): CartItem[] => {
  const itemsToUpdate: CartItem[] = [];
  Object.keys(cart).forEach((type) => {
    const items = cart[type].items;
    const category = categories[type];
    if (items.length && category !== undefined) {
      items.forEach((item) => {
        const trigger = item.triggers.length > 0 ? item.triggers[0] : undefined;
        if (trigger) {
          const value: string | undefined = category[trigger?.name]?.[item.id];
          if (value) {
            itemsToUpdate.push({
              description: item.summary ? item.summary : undefined,
              formatted_title: item.formattedTitle,
              title: item.title,
              id: item.id,
              trigger: trigger?.name,
              type,
              [isUserValuePropGenerationOn ? "value" : "userValue"]: value,
            });
          }
        }
      });
    }
  });
  return itemsToUpdate;
};

export const normalizeCart = (
  generatingEmailsData: StartMyEmailCart | undefined
) => {
  const normalizedData = initNormalizedCart();
  if (!generatingEmailsData || Object.keys(generatingEmailsData).length === 0) {
    return normalizedData;
  }

  for (const [type, section] of Object.entries(generatingEmailsData)) {
    if (section !== undefined && typeof section === "object") {
      processItems(normalizedData, section, type);
    }
  }

  return normalizedData;
};

export const validateAllFieldsHaveValue = <T extends Record<string, unknown>>(
  obj: T | undefined
): boolean => {
  if (!obj) {
    return false;
  }
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (isObject(value)) {
        if (!validateAllFieldsHaveValue(value)) {
          return false;
        }
      } else {
        if (value === "" || value === null || value === undefined) {
          return false;
        }
      }
    }
  }
  return true;
};

export const initStreamedPayload = (generationCount: number) => {
  return Array.from<string>({ length: generationCount }).map((_, i) => ({
    content: undefined,
    key: i,
    status: APIResponseStatus.initiated,
  }));
};

export const isItemInCart = (
  cart: RankedFeedSectionMap | undefined,
  item: PersonalizationEvent
) => {
  return !!cart?.[item.type]?.items.some((cartItem) => cartItem.id === item.id);
};

const mapItemsToPayload = (
  items: PersonalizationEvent[],
  type: string,
  categories?: StartMyEmailCart
): StartMyEmailCartItem[] => {
  return items.map((item: PersonalizationEvent) => {
    const trigger = item.triggers.length > 0 ? item.triggers[0] : undefined;
    const payloadItem = {
      description: item.summary ? item.summary : undefined,
      id: item.id,
      formatted_title: item.formattedTitle,
      title: item.title,
      trigger: trigger?.name || "",
      value: categories?.[type]?.[trigger?.name || ""]?.[item.id] || "",
    };
    return payloadItem;
  });
};

const generateEmailId = (parentId: StartMyEmailCartItemId, index: number) => {
  return `${parentId}_email_${index}`;
};

const normalizeEmails = (
  normalizedData: StartMyEmailData,
  emails: string[],
  parentId: StartMyEmailCartItemId,
  parentType: string
) => {
  return emails.map((email, index) => {
    const emailId = generateEmailId(parentId, index);
    normalizedData.cart.emails[emailId] = {
      id: emailId,
      content: email,
      parentId,
      parentType,
    };
    const foundIndex = normalizedData.result.emails.indexOf(emailId);
    if (foundIndex === -1) {
      normalizedData.result.emails.push(emailId);
    } else {
      normalizedData.result.emails[foundIndex] = emailId;
    }
    return emailId;
  });
};

const initNormalizedCart = (): StartMyEmailData => {
  return {
    cart: {
      emails: {},
    },
    result: {
      emails: [],
    },
  };
};

const processItems = (
  normalizedData: StartMyEmailData,
  items: StartMyEmailSection,
  type: string
) => {
  normalizedData.cart[type] = normalizedData.cart[type] ?? {};
  normalizedData.result[type] = normalizedData.result[type] ?? [];

  for (const [id, item] of Object.entries(items)) {
    if (item.emails?.length) {
      const emailIds = normalizeEmails(normalizedData, item.emails, id, type);

      normalizedData.cart[type][id] = {
        ...item,
        emails: emailIds,
      };

      if (!normalizedData.result[type].includes(id)) {
        normalizedData.result[type].push(id);
      }
    }
  }
};
