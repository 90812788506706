import { Flex } from "antd";
import { styled } from "styled-components";
import { BadgeWrapper } from "@lavender-ai/lav-components";
import { CENTERED_SVG_MIXIN } from "~src/component/Styled";

export const FlexMenuItem = styled(Flex)`
  height: 40px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  cursor: pointer;
`;

export const EmailBadge = styled(BadgeWrapper)`
  clear: both;
  .ant-badge-count {
    transform: translate(15%, -15%) !important;
    background: var(--color-pear-300, #caf344);
    span {
      color: var(--color-primary-lavender, #6a2be9);
      font-weight: 600;
    }
  }
`;

export const ScoreBadge = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
  > span {
    ${CENTERED_SVG_MIXIN}
  }
`;
