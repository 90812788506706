import { Flex, FlexProps } from "antd";
import DOMPurify from "dompurify";

import { Icon, IconProps, Text, TextProps } from "@lavender-ai/lav-components";
import { SecondaryIcon, SecondaryText } from "../../Styled";
import { styled } from "styled-components";

const StyledFlex = styled(Flex)<{ $titleCase?: boolean }>`
  max-width: 100%;
  .lav-components-icon {
    flex-shrink: 0;
  }

  .lav-components-text {
    ${({ $titleCase }) => $titleCase && `text-transform: capitalize;`}
  }
`;

const textString = (text: Props["text"]): string => {
  if (Array.isArray(text)) {
    const html = text.map(({ text: textContent, bold }) =>
      bold ? `<b>${textContent}</b>` : textContent
    );
    return html.join("");
  }
  return text;
};

interface TextItem {
  text: string;
  bold?: boolean;
}

interface Props {
  align?: FlexProps["align"];
  gap?: FlexProps["gap"];
  icon: IconProps["variant"];
  iconColor?: IconProps["color"];
  iconSize?: IconProps["size"];
  loading?: boolean;
  text: string | TextItem[];
  textColor?: TextProps["color"];
  textSize?: TextProps["size"];
  titleCase?: boolean;
}

export const IconAndText = ({
  align = "center",
  gap = "small",
  icon,
  iconColor,
  iconSize = "extra-small",
  text,
  textColor,
  textSize = "body1",
  titleCase,
}: Props) => (
  <StyledFlex align={align} gap={gap} $titleCase={titleCase}>
    {iconColor ? (
      <Icon color={iconColor} variant={icon} size={iconSize} />
    ) : (
      <SecondaryIcon size={iconSize} variant={icon} />
    )}
    {textColor ? (
      <Text
        color={textColor}
        size={textSize}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(textString(text)),
        }}
      />
    ) : (
      <SecondaryText
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(textString(text)),
        }}
        size={textSize}
      />
    )}
  </StyledFlex>
);
