import { useState, useEffect } from "react";
import { store, configActions, fetchConfig } from "~src/redux";
import { getChromeId } from "~src/utils";

export const useMainLoopState = () => {
  const [chromeId, setChromeId] = useState<string>();
  const [isFetchingChromeId, setIsFetchingChromeId] = useState<
    "isFetching" | "hasFetched"
  >();
  const [userExperience, setUserExperience] = useState<ExtensionExperience>();
  const [isFetchingUserExperience, setIsFetchingUserExperience] =
    useState(false);
  const [hasFetchedUserExperience, setHasFetchedUserExperience] =
    useState(false);

  const { platform } = store.getState().config.settings ?? {};

  // Get chromeId effect
  useEffect(() => {
    // Get chromeId if it hasn't been fetched yet
    if (
      !chromeId &&
      isFetchingChromeId === undefined &&
      hasFetchedUserExperience
    ) {
      setIsFetchingChromeId("isFetching");
      void getChromeId(userExperience, platform).then((id) => {
        setChromeId(id);
        store.dispatch(configActions.setChromeId(id));
        setIsFetchingChromeId("hasFetched");
      });
    }
  }, [
    chromeId,
    setChromeId,
    isFetchingChromeId,
    setIsFetchingChromeId,
    userExperience,
    platform,
    isFetchingUserExperience,
    hasFetchedUserExperience,
  ]);

  // Determine extension experience effect
  useEffect(() => {
    void (async () => {
      if (!userExperience && !isFetchingUserExperience) {
        setIsFetchingUserExperience(true);
        // INFO : Fetch config and store it in redux
        try {
          await store.dispatch(fetchConfig()).unwrap();
        } catch (error) {
          // 403 doesn't need to be handled, will set fetched true so API key can be scraped
          setHasFetchedUserExperience(true);
          return;
        }
        const state = store.getState();
        const { isTeam, platform } = state.config.settings ?? {};

        if (isTeam) {
          setUserExperience("crmTeams");
        } else if (platform === "lavenderAnywhere") {
          setUserExperience("lavenderAnywhere");
        } else if (platform === "lavenderAnywhereIframe") {
          setUserExperience("lavenderAnywhereIframe");
        } else {
          setUserExperience("mailClient");
        }
        setHasFetchedUserExperience(true);
      }
    })();
    // Run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    chromeId,
    userExperience,
    isFetchingChromeId,
  };
};
