import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { RecentActivity } from "~src/api/typings";
import {
  DEFAULT_COMPANY,
  DEFAULT_PROFILE_COMPANY,
  DEFAULT_RECENT_ACTIVITY,
  INIT_PERSONALIZATION_TAB,
  INIT_PERSONALIZATION_TAB_CONTEXT,
} from "~src/constants";
import type {
  IPersonalization,
  PersonalizationEvent,
  PersonalizationProfileData,
} from "~src/redux/typings";
import { hasItems, isItemInCart } from "~src/utils";

import { PanelTabContextProvider } from "./";

interface Props {
  children: React.ReactNode;
}

export interface IPersonalizationContext {
  addItemToCartContext: (item: PersonalizationEvent) => void;
  removeItemFromCartContext: (item: PersonalizationEvent) => void;
  resetCartContext: () => void;
  currentPersonalization: IPersonalization;
  resetPersonalizationContext: () => void;
  setPersonalizationContext: (newPersonalization: IPersonalization) => void;
}

const initialState: IPersonalization = {
  company: DEFAULT_COMPANY,
  profile: {
    avatar: "",
    bio: "",
    company: DEFAULT_PROFILE_COMPANY,
    education: "",
    job: {
      company: "",
      title: "",
    },
    name: "",
    location: "",
    timezone: "",
    workHistory: [],
  } as unknown as PersonalizationProfileData,
  recent_activity: DEFAULT_RECENT_ACTIVITY as RecentActivity,
  notes: [],
};

const initPersonalizationContext: IPersonalizationContext = {
  addItemToCartContext: (_item: PersonalizationEvent) => {
    /* empty */
  },
  removeItemFromCartContext: (_item: PersonalizationEvent) => {
    /* empty */
  },
  resetCartContext: () => {
    /* empty */
  },
  resetPersonalizationContext: () => {
    /* empty */
  },
  currentPersonalization: initialState,
  setPersonalizationContext: (_newPersonalization: IPersonalization) => {
    /* empty */
  },
};

const PersonalizationContext = createContext(initPersonalizationContext);

export const PersonalizationContextProvider = ({ children }: Props) => {
  const [personalization, setPersonalization] =
    useState<IPersonalization>(initialState);
  const setPersonalizationContext = useCallback(
    (newPersonalization: IPersonalization) => {
      setPersonalization(newPersonalization);
    },
    []
  );
  const addItemToCartContext = useCallback(
    (item: PersonalizationEvent) => {
      if (!isItemInCart(personalization.cart, item)) {
        setPersonalization({
          ...personalization,
          cart: {
            ...(personalization.cart ?? {}),
            [item.type]: {
              totalScore:
                (personalization.cart?.[item.type]?.totalScore || 0) +
                item.score,
              items: [
                ...(personalization.cart?.[item.type]?.items ?? []),
                item,
              ],
            },
          },
        });
      }
    },
    [personalization]
  );
  const removeItemFromCartContext = useCallback(
    (item: PersonalizationEvent) => {
      const cartItemType = personalization.cart?.[item.type];
      if (cartItemType) {
        const itemIndex = cartItemType.items.findIndex(
          (rankedItem) => rankedItem.id === item.id
        );

        if (itemIndex !== -1) {
          const newItems = [...cartItemType.items];
          newItems.splice(itemIndex, 1);
          const newTotalScore = Math.max(
            0,
            (cartItemType.totalScore || 0) - item.score
          );
          let newCart: typeof personalization.cart;
          if (newItems.length > 0) {
            newCart = {
              ...personalization.cart,
              [item.type]: {
                totalScore: newTotalScore,
                items: newItems,
              },
            };
          } else {
            const { [item.type]: _, ...restCart } = personalization.cart ?? {};
            newCart = hasItems(restCart) ? restCart : undefined;
          }
          setPersonalization({
            ...personalization,
            cart: newCart,
          });
        }
      }
    },
    [personalization]
  );

  const resetCartContext = useCallback(() => {
    setPersonalization({
      ...personalization,
      cart: undefined,
    });
  }, [personalization]);
  const resetPersonalizationContext = useCallback(() => {
    setPersonalization(initialState);
  }, []);
  const personalizationContext: IPersonalizationContext = useMemo(
    () => ({
      addItemToCartContext,
      removeItemFromCartContext,
      resetCartContext,
      setPersonalizationContext,
      resetPersonalizationContext,
      currentPersonalization: personalization,
    }),
    [
      addItemToCartContext,
      personalization,
      removeItemFromCartContext,
      resetCartContext,
      resetPersonalizationContext,
      setPersonalizationContext,
    ]
  );
  return (
    <PersonalizationContext.Provider value={personalizationContext}>
      <PanelTabContextProvider
        initialPanelContext={
          INIT_PERSONALIZATION_TAB_CONTEXT[INIT_PERSONALIZATION_TAB]
        }
      >
        {children}
      </PanelTabContextProvider>
    </PersonalizationContext.Provider>
  );
};

export const usePersonalizationContext = () =>
  useContext(PersonalizationContext);
