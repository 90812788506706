import { Flex, Space } from "antd";

import { LinkButton } from "../Atoms";
import { LavenderIcon } from "../typings/icon";

interface SocialsProps {
  linkedin?: string;
  twitter?: string;
  crunchbase?: string;
  website?: string;
  loading?: boolean;
}

export const Socials = ({ loading, ...socials }: SocialsProps) => {
  const { linkedin, twitter, crunchbase, website } = socials;
  return (
    <Space direction={"vertical"} size={8}>
      <Flex gap="4px">
        <LinkButton
          href={website}
          variant={LavenderIcon.IconWorld}
          loading={loading}
        />
        <LinkButton
          href={linkedin}
          variant={LavenderIcon.IconBrandLinkedin}
          loading={loading}
        />
        <LinkButton
          href={twitter}
          variant={LavenderIcon.IconBrandTwitter}
          loading={loading}
        />
        <LinkButton
          href={crunchbase}
          variant={LavenderIcon.IconBrandCrunchbase}
          loading={loading}
        />
      </Flex>
    </Space>
  );
};
