import {
  CallOutlineItem,
  Collaboration,
  GongResponseItem,
  Party,
  Step,
} from "~src/api/typings";
import { ICRM } from "~src/redux/typings";
import { formatDate, formatDuration } from "./datetime";

export const newPendingCRM = (): ICRM => ({
  hasGongAccess: false,
  hasHubspotAccess: false,
  hasSalesforceAccess: false,
  gong_data: [],
  hubspot_data: undefined,
  salesforce_data: undefined,
  loading: true,
});

export const newRejectedCRM = (): ICRM => ({
  hasGongAccess: false,
  hasHubspotAccess: false,
  hasSalesforceAccess: false,
  gong_data: [],
  hubspot_data: undefined,
  salesforce_data: undefined,
  loading: false,
});

export const parseGongData = (gongData: GongResponseItem[] | null) => {
  if (!gongData) {
    return [];
  }

  return gongData.map((callObj: GongResponseItem) => {
    const id: string = callObj.metaData.id;
    const title: string = callObj.metaData.title;
    const url: string = callObj.metaData.url;
    const callTime: string = callObj.metaData.started;
    const callTimeFormatted: string = formatDate(callTime);
    const duration: number = callObj.metaData.duration;
    const durationFormatted: string = formatDuration(duration);
    const callBrief: string = callObj.content.brief || "";
    const outline: CallOutlineItem[] | undefined = callObj.content.outline;
    // TODO: remove checks below we can verify all calls have parties, current api responses do not
    const participants: string[] = callObj.parties
      ? callObj.parties.map((party: Party) => party.name)
      : [];
    const participantsText: string = numberOfCallParticipants(participants);
    const nextSteps: string[] = callObj.nextSteps
      ? callObj.nextSteps.map((step: Step) => step.text)
      : [];

    const collaboration: Collaboration = callObj.collaboration;

    return {
      id,
      title,
      url,
      callTime,
      callTimeFormatted,
      participants,
      participantsText,
      duration,
      durationFormatted,
      nextSteps,
      callBrief,
      outline,
      collaboration,
    };
  });
};

const numberOfCallParticipants = (participants: string[]): string => {
  if (!participants.length) {
    return "No Call Participants";
  } else if (participants.length === 1) {
    return participants[0];
  } else {
    return `${participants[0]} +${participants.length - 1}`;
  }
};
