import styled from "styled-components";

import {
  Avatar,
  AvatarProps,
  Dropdown,
  MenuProps,
  MenuItemProps,
} from "@lavender-ai/lav-components";

const StyledAvatarDropdown = styled(Dropdown)`
  button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown.lav-components-button-icon-undefined& {
    background-color: unset !important;
    border: none !important;
    box-shadow: none !important;

    &:hover,
    &:focus {
      background-color: unset !important;
      border: none !important;
      box-shadow: none !important;
    }

    &:hover:not(:focus) {
      span.ant-avatar.lav-components-avatar {
        background: var(--color-neutral-blue-300);
      }
    }
  }
  /* .lav-components-dropdown-container-menuOpen-true {
    span.ant-avatar.lav-components-avatar {
      background: var(--color-lavender-50);
      border: 1px solid var(--color-neutral-purple-300);
      box-shadow: 0px 0px 0px 2px #7253D5;
    }
  } */
`;

interface AvatarDropdownProps {
  onSelect: MenuProps["onSelect"];
  items: MenuItemProps[];
  selected: string;
  content: Required<Pick<AvatarProps, "children">>["children"];
}
export const AvatarDropdown = ({
  content,
  onSelect,
  items,
  selected,
}: AvatarDropdownProps) => {
  return (
    <StyledAvatarDropdown
      arrow={false}
      menuProps={{
        selectedKeys: [selected],
        selectable: true,
        onSelect,
        items,
        style: {
          zIndex: 1000,
          position: "relative",
          textAlign: "left",
          maxHeight: "264px",
          minWidth: "max-content",
        },
      }}
      variant="secondary"
    >
      <Avatar color="neutral-100" size="small">
        {content}
      </Avatar>
    </StyledAvatarDropdown>
  );
};
