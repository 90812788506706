// INFO: This removes any elements of the extension from the main window document.
export const removeExtensionElements = (): void => {
  // Select all elements you want to remove
  const elementsToRemove = window?.top?.document.querySelectorAll(
    ".lv-panel-root, head style[data-lv='style']"
  );
  if (!elementsToRemove) {
    return;
  }
  // Convert the NodeList to an array and iterate through it
  Array.from(elementsToRemove).forEach((element) => {
    element.remove();
  });

  // TODO: for other features that are not in the panel, we need to remove them here. TBD for now.
  // Threads will be a feature in the future
};
